import { signObject } from "crypto/CryptoUtils";
import { ICryptographicInfo } from "crypto/interface/ICryptographicInfo";
import { detect } from "detect-browser";
import CryptoService from "services/CryptoService";
import InstanceService from "services/InstanceService";
import { inMs, timeSpanToString } from "@utils/DateUtils";

export const browserNames: { [name: string]: string | undefined } = {
    edge: 'Edge Legacy',
    'edge-ios': 'Edge iOS',
    samsung: 'Samsung Internet Browser',
    silk: 'Amazon Silk',
    miui: 'MIUI Browser',
    beaker: 'Beaker Browser',
    'edge-chromium': 'Edge Chromium',
    'chromium-webview': 'Chromium WebView',
    chrome: 'Chrome',
    phantomjs: 'PhantomJS',
    firefox: 'Firefox',
    'opera-mini': 'Opera Mini',
    opera: 'Opera',
    ie: 'Internet Explorer',
    android: 'Android',
    safari: 'Safari'
}

export const operationSystems: { [name: string]: string | undefined } = {
    'Windows 10': 'Windows 10 / Windows 11'
}

export const getBrowserAndOs = () => {
    const browser = detect();
    const browserName = (browser?.name && browserNames[browser.name]) ?? browser?.name;
    const browserOs = (browser?.os && operationSystems[browser.os]) ?? browser?.os;
    return {
        browser,
        browserName,
        browserOs
    }
}

export const detectBrowserType = () => {
    const anyWindow = window as any;
    const ua = navigator.userAgent;
    return {
        isAndroid: /Android/.test(ua),
        isCordova: !!anyWindow.cordova,
        isEdgeLegacy: /Edge/.test(ua),
        isFirefox: /Firefox/.test(ua),
        isChrome: /Google Inc/.test(navigator.vendor),
        chromeVersion: ua.match(/Chrome\/([\d\.]+)/)?.[1],
        isChromeIOS: /CriOS/.test(ua),
        isChromiumBased: !!anyWindow.chrome && !/Edge/.test(ua),
        isIE: /Trident/.test(ua),
        isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
        isOpera: /OPR/.test(ua),
        isSafari: /Safari/.test(ua) && !/Chrome/.test(ua),
    }
}

type DetectBrowserTypeResult = ReturnType<typeof detectBrowserType>;

const checkBrowserPrivate = (browserType: DetectBrowserTypeResult, isVersion: (actualVersion: string) => boolean): boolean => {
    if (browserType.isIE) {
        return false;
    }
    if (browserType.isChromiumBased) {
        return compareVersions('45', browserType.chromeVersion);
    }
    if (browserType.isEdgeLegacy) {
        return isVersion('17');
    }
    if (browserType.isFirefox) {
        return isVersion('44');
    }
    if (browserType.isSafari) {
        return isVersion('11.1');
    }
    return false;
}

const checkBrowserPublic = (browserType: DetectBrowserTypeResult, isVersion: (actualVersion: string) => boolean): boolean => {
    if (browserType.isIE) {
        return isVersion('11');
    }
    if (browserType.isChromiumBased) {
        return compareVersions('45', browserType.chromeVersion);
    }
    if (browserType.isEdgeLegacy) {
        return isVersion('17');
    }
    if (browserType.isFirefox) {
        return isVersion('44');
    }
    if (browserType.isSafari) {
        return isVersion('9');
    }
    if (browserType.isAndroid) {
        return true;
    }
    if (browserType.isIOS) {
        return true;
    }
    return false;
}

export const checkBrowser = () => {
    const browserInfo = detect();
    const isVersion = (required: string) => compareVersions(required, browserInfo?.version ?? '0');
    const browserType = detectBrowserType();
    return {
        privateResult: checkBrowserPrivate(browserType, isVersion) ? TestResult.ok : TestResult.failed,
        publicResult: checkBrowserPublic(browserType, isVersion) ? TestResult.ok : TestResult.failed
    }
}

export const checkCookies = () => navigator.cookieEnabled ? TestResult.ok : TestResult.failed;

const compareVersions = (requiredVersion?: string, actualVersion?: string): boolean => {
    const requiredVersions = (requiredVersion ?? '').split('.');
    const actualVersions = (actualVersion ?? '').split('.');
    for (let i = 0, len = requiredVersions.length; i < len; i++) {
        const actualVersion = Number(actualVersions[i] || 0);
        const requiredVersion = Number(requiredVersions[i] || 0);
        if (actualVersion > requiredVersion) {
            return true;
        }
        if (actualVersion < requiredVersion) {
            return false;
        }
    }
    return true;
}

export type ServiceWorkerResult = {
    result: TestResult;
    messageLocKey?: string;
}

export const checkServiceWorker = (): ServiceWorkerResult => {
    const result: ServiceWorkerResult = {
        result: (navigator && ('serviceWorker' in navigator)) ? TestResult.ok : TestResult.failed
    };
    const browser = detect();
    if (result.result != TestResult.ok && browser?.name == 'firefox' && compareVersions('44', browser.version)) {
        result.messageLocKey = 'NEN-747096';
    }
    return result;
}

export type MultiwebTestResult = TestResult;

export const checkMultiwebTests = () => new Promise<MultiwebTestResult>((resolve) => {
    if (!document.addEventListener || !window.Promise || !checkCookies()) {
        resolve(TestResult.failed);
        return;
    }
    requirejs(['static/js/TescoSW.Compatibility.js'], () => {
        let result = TestResult.ok;
        if (self) {
            if (typeof self.WebAssembly !== "object")
                result = TestResult.failed; // Chrome 57, Edge 16, Safari 11, FF 52, Opera 44 
            //if (Intl.Collator('en').resolvedOptions().caseFirst === void 0) return false; // FF 55 -  Vlastnost nize neni potreba, jen potrebujeme vyspecifikovat verzi FF55, ktera ma chybu v indexedDB volane pres webWorker
            else if (typeof self.AbortController !== "function" && !(window as any).TescoSW.Compatibility.isBlink())
                result = TestResult.failed; // Edge 16, Safari 11.1, FF 57
            else if (!(window as any).TescoSW.Compatibility?.checkUICompatibility()) {
                result = TestResult.failed; // Edge 16, Safari 11.1, FF 57
            }
        } else {
            result = TestResult.failed;
        }
        resolve(result);
    }, () => resolve(TestResult.nonCheckable));
});

export enum TestResult {
    ok = 'ok',
    notEvaluated = 'notEvaluated',
    nonCheckable = 'nonCheckable',
    failed = 'failed',
    partially = 'partially',
    evaluating = 'evaluating'
}

type TestResultValues<T> = {
    [key in TestResult]?: T;
} & {
    nullValue?: T;
    defaultValue: T;
}

export const testResultSwitch = <T>(result: TestResult | null | undefined, values: TestResultValues<T>): T => {
    let value: T | undefined = values.nullValue;
    if (result != null) {
        value = values[result];
    }
    return value === undefined ? values.defaultValue : value;
}

export const getBrowserInfo = () => {
    var navigatorName = navigator.appName, ua = navigator.userAgent, tem;
    let match = ua.match(/(opera|chrome|safari|firefox|msie|trident|edge)\/?\s*(\.?\d+(\.\d+)*)/i);
    if (match && (tem = ua.match(/version\/([\.\d]+)/i)) != null) match[2] = tem[1];
    match = match ? [match[1], match[2]] : [navigatorName, navigator.appVersion, '-?'];
    return match;
}

export const getBrowser = () => {
    var M = getBrowserInfo();
    return M[0];
}

export const getBrowserVersion = () => {
    var M = getBrowserInfo();
    return M[1];
}

export const getOSNT = () => {
    var myNT = window.navigator.userAgent.match(/NT ([d+(\.\d{1,2})]*);/);
    if (myNT != null)
        return myNT[1];

    return false;
}

export const getIEVersion = () => {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

export const popupWindowCheck = () => {
    const features = 'resizable=no,scrollbars=no,height=1,width=1';
    const popupWondow = window.open('/', "_blank", features);
    if (!popupWondow || popupWondow == null || typeof (popupWondow) == 'undefined')
        return TestResult.failed;
    popupWondow.close();
    return TestResult.ok;
}

export const getAverageValueFromArray = (myArray: []) => {
    var avrg = 0;
    for (var i = 0, ii = myArray.length; i < ii; i++)
        avrg += myArray[i];

    avrg = avrg / myArray.length;
    return avrg;
}

const isResolutionSupported = (supportedWidth: number, supportedHeight: number) => {
    return (screen.width >= supportedWidth && screen.height >= supportedHeight);
}

const resolutionCheckBase = (supportedWidth: number, supportedHeight: number) => {
    const isSupported = isResolutionSupported(supportedWidth, supportedHeight);
    return {
        result: isSupported ? TestResult.ok : TestResult.failed,
        resolutionText: `${supportedWidth} x ${supportedHeight}`
    };
}

export type ResolutionTestResult = {
    privateResult: TestResult;
    resolutionPrivateText: string;
    publicResult: TestResult;
    resolutionPublicText: string;
}

export const getActualResolution = () => `${screen.width} x ${screen.height}`;

export const checkMinimalResulution = (): ResolutionTestResult => {
    const publicTest = resolutionCheckBase(360, 360);
    const privateTest = resolutionCheckBase(1280, 720);
    return {
        privateResult: privateTest.result,
        publicResult: publicTest.result,
        resolutionPrivateText: privateTest.resolutionText,
        resolutionPublicText: publicTest.resolutionText
    }
}

export const checkRecommendedResulution = (): ResolutionTestResult => {
    const publicTest = resolutionCheckBase(1920, 1080);
    const privateTest = resolutionCheckBase(1920, 1080);
    return {
        privateResult: privateTest.result,
        publicResult: publicTest.result,
        resolutionPrivateText: privateTest.resolutionText,
        resolutionPublicText: publicTest.resolutionText
    }
}

export type DateTimeDifferenceResult = {
    result: TestResult;
    timeSpan: string;
}

export const getDateTimeDifferenceResult = (sysDateTimeString: string): DateTimeDifferenceResult => {
    const clientDateTime = new Date();
    const sysDateTime = new Date(sysDateTimeString);
    const diffInMs = clientDateTime.getTime() - sysDateTime.getTime();
    return {
        result: diffInMs > (inMs.minute * 5) ? TestResult.partially : TestResult.ok,
        timeSpan: timeSpanToString(diffInMs)
    }
}

export type IndexedDBTestResult = {
    result: TestResult;
    messageLocKey?: string;
}

const createIndexedDB = (dbName: string, onUpgradeNeeded: (db: IDBDatabase) => void) => new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open(dbName);
    request.onupgradeneeded = () => onUpgradeNeeded(request.result);
    request.onsuccess = () => resolve(request.result);
    request.onerror = error => reject(error);
});

const deleteIndexedDB = (dbName: string) => new Promise<void>((resolve, reject) => {
    const request = indexedDB.deleteDatabase(dbName);
    request.onsuccess = () => resolve();
    request.onerror = error => reject(error);
});

const setIDBValue = (objectStore: IDBObjectStore, key: string, value: string) => new Promise<void>((resolve, reject) => {
    const request = objectStore.put(value, key);
    request.onsuccess = () => resolve();
    request.onerror = error => reject(error);
});

const getIDBValue = (objectStore: IDBObjectStore, key: string) => new Promise<string>((resolve, reject) => {
    const request = objectStore.get(key);
    request.onsuccess = () => resolve(request.result);
    request.onerror = error => reject(error);
});

export const indexedDBTest = async (): Promise<IndexedDBTestResult> => {
    if (!indexedDB) {
        return {
            result: TestResult.failed
        };
    }

    const dbName = 'db-test';
    const objectStoreName = 'test';
    const testKey = 'testKey';
    const testValue = 'testValue';

    try {
        const db = await createIndexedDB(dbName, (db) => {
            const objStore = db.createObjectStore(objectStoreName);
            objStore.add(testValue, 1);
        });

        const transaction = db.transaction([objectStoreName], 'readwrite');
        const objectStore = transaction.objectStore(objectStoreName);
        await setIDBValue(objectStore, testKey, testValue);

        const valueInDb = await getIDBValue(objectStore, testKey);
        transaction.abort();
        return {
            result: valueInDb == testValue ? TestResult.ok : TestResult.failed
        };
    } catch (error) {
        console.error(error);
        let messageLocKey = undefined;
        const browser = detect();
        if (browser?.name == 'firefox' && compareVersions('44', browser.version)) {
            messageLocKey = 'NEN-747096';
        }
        return {
            result: TestResult.failed,
            messageLocKey
        };
    } finally {
        deleteIndexedDB(dbName);
    }
}

export type SignTestResult = {
    signatureInfo?: ICryptographicInfo;
    result: TestResult;
}

type SignTestProps = {
    instanceService: InstanceService;
    cryptoService: CryptoService;
    language?: string;
};

export const signTest = async ({ instanceService, cryptoService, language }: SignTestProps): Promise<SignTestResult> => {
    try {
        const textForSign = 'Testovací data';
        const instanceID = await instanceService.createObject({
            objectName: 'Test_podpis_LW',
            data: {
                'DummyData': textForSign
            }
        });
        if (!instanceID) {
            return { result: TestResult.nonCheckable };
        }
        const signed = await signObject({ instanceID, cryptoService, language });
        if (!signed) {
            return { result: TestResult.failed };
        }
        const signatureInfo = await cryptoService.getCryptographicInfo({ instanceID });
        return {
            signatureInfo,
            result: TestResult.ok
        };
    } catch {
        return { result: TestResult.nonCheckable };
    }
}
