import React from "react";
import { useRouteMatch } from "react-router";
import { DetailFrame } from "@components/DetailFrame/DetailFrame";
import { urlConstants } from "@constants/urlConstants";
import { DetailUverejneniAttrMap, IDetailUverejneniModel } from "@models/IDetailUverejneniModel";
import { UrlParamObject } from "@utils/QueryHook";
import GroupBox from "@components/GroupBox";
import { DataLabelTyped } from "@components/DataLabel";
import { useDetailDescription } from "@utils/DescriptionHook";
import { columnWidths, DependentListFrame, ListFrameColumns } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";

const DataLabel = DataLabelTyped<IDetailUverejneniModel>();

const detailDetailUverLoadkey = 'detail-uverejneni';

const DetailDetailUver: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const id = (match?.params[urlConstants.detailDetailUver.detailIdParam] || '');

    return (
        <DetailFrame
            condition='ID=@0'
            conditionParams={[id]}
            attrMapping={DetailUverejneniAttrMap}
            loadKey={detailDetailUverLoadkey}
        >
            <DetailUver />
        </DetailFrame>
    );
}
const DetailUver: React.FC = () => {
    useDetailDescription<IDetailUverejneniModel>([
        {
            lockey: 'NEN-812750'
        },
        {
            lockey: 'NEN-812506',
            attrName: 'uredniNazev'
        }
    ]);
    return (
        <>
            <GroupBox lockey="NEN-812507" showIf="Info_uver.Detail in ['VYS','UCH','SUB'] or (Info_uver.Uverejneni='PZ' and Info_uver.Uver_pis_zprava=true and Subdod<>null) or (Info_uver.Detail='SON' and Pod<>null)">
                <DataLabel lockey="NEN-812506" attrName='uredniNazev' fillGrid/>
                <DataLabel lockey="NEN-812511" attrName='ico' />
                <DataLabel lockey="NEN-812512" attrName='dic' showIf='DIC<>null' />
                <DataLabel lockey="NEN-812615" attrName='pravFormaNazev' showIf='PF<>null' />
                <DataLabel lockey="NEN-812517" attrName='obec' />
                <DataLabel lockey="NEN-812518" attrName='castObce' showIf='Cast_obce<>null' />
                <DataLabel lockey="NEN-812523" attrName='ulice' showIf='Ulice<>null' />
                <DataLabel lockey="NEN-812524" attrName='cisloPop' showIf='Cislo_popisne<>null' />
                <DataLabel lockey="NEN-812525" attrName='cisloOrient' showIf='Cislo_orientacni<>null' />
                <DataLabel lockey="NEN-812526" attrName='psc' showIf='PSC<>null' />
                <DataLabel lockey="NEN-812845" attrName='statNazev' showIf='Stat_uver<>null' />
                <DataLabel lockey="NEN-825712" attrName='nazevDodPoddod' showIf='Nazev_dod_poddod<>null' />
            </GroupBox>
            <GroupBox lockey="NEN-812842" showIf="not(Info_uver.ZP.Druh_ZR.Kod in['ZP27','ZP28']) and Info_uver.Uverejneni in ['VYS','UCH'] and Osoba_stret = null">
                <DataLabel lockey="NEN-812610" attrName='datumUzavreni' showIf="Info_uver.Detail = 'VYS'" />
                <DataLabel lockey="NEN-812663" attrName='datumVyber' showIf="Info_uver.Detail = 'SON'" />
                <DataLabel lockey="NEN-812606" attrName='cisloSmlouva' showIf="Info_uver.Detail = 'VYS'" />
                <DataLabel lockey="NEN-825067" attrName='zaklPopisPlneni' showIf="Zakl_popis_plneni<>null and Info_uver.Detail = 'VYS'" fillGrid rawText />
                <DataLabel lockey="NEN-812632" attrName='smluvniCenaBez' showIf="Info_uver.Detail = 'VYS'" />
                <DataLabel lockey="NEN-812633" attrName='smluvniCenaS' showIf="Info_uver.Detail = 'VYS'" />
                <DataLabel lockey="NEN-812873" attrName='smluvniCenaBezAkt' showIf="Sml_cena_bez_DPH_akt<>null and Info_uver.Uverejneni = 'VYS'" />
                <DataLabel lockey="NEN-812872" attrName='smluvniCenaSAkt' showIf="Sml_cena_s_DPH_akt<>null and Info_uver.Uverejneni = 'VYS'" />
                <DataLabel lockey="NEN-812664" attrName='soutezCenaBez' showIf="Info_uver.Detail = 'SON'" />
                <DataLabel lockey="NEN-812850" attrName='nabCenaS' showIf="Nabidkova_cena<>null and Info_uver.Uverejneni = 'UCH'" />
                <DataLabel lockey="NEN-812851" attrName='nabCenaBez' showIf="Nab_cena_bez_DPH<>null and Info_uver.Uverejneni = 'UCH'" />
                <DataLabel lockey="NEN-812607" attrName='menaUverKod' showIf="Mena_uver<>null" />
                <DataLabel lockey="NEN-812773" attrName='emailAdresa' showIf="Email_adresa<>null and Info_uver.Uverejneni = 'VYS'" />
                <DataLabel lockey="NEN-824859" attrName='uzavrenaSmlouva' showIf="Uzavrena_smlouva<>null and Info_uver.Uverejneni = 'UCH'" />
                <DataLabel lockey="NEN-812844" attrName='sdruzDod' showIf="Info_uver.Uverejneni = 'VYS'" />
                <DataLabel lockey="NEN-825562" attrName='sdruzDod' showIf="Info_uver.Uverejneni = 'UCH'" />
                <DataLabel lockey="NEN-812753" attrName='icoVed' showIf='Sdruzeni_dodavatelu=true' />
                <DataLabel lockey="NEN-812754" attrName='nazevVed' showIf='Sdruzeni_dodavatelu=true' />
                <DataLabel lockey="NEN-812886" attrName='oduvodKomunProstredky' showIf="Oduv_komunikace<>null" fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-824184" showIf="Info_uver.Uverejneni = 'UCH' and (Vylouceni=true or Vyrazena_pro_MNNC=true or Odstoupeni=true)">
                <DataLabel lockey="NEN-824186" attrName='vylouc' showIf="Vylouceni=true" />
                <DataLabel lockey="NEN-812834" attrName='mnnc' showIf="Vyrazena_pro_MNNC=true" />
                <DataLabel lockey="NEN-812836" attrName='odstoupeni' />
                <DataLabel lockey="NEN-824191" attrName='vyloucDuvod' fillGrid rawText />
                <DataLabel lockey="NEN-812835" attrName='mnncDuvod' showIf="Vyrazena_pro_MNNC=true and Vylouceni<>true and Odstoupeni<>true" fillGrid rawText />
                <DataLabel lockey="NEN-812837" attrName='odstoupeniDuvod' showIf="Odstoupeni=true and Vylouceni<>true and Vyrazena_pro_MNNC<>true" fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-824212" fillContent showIf="Sdruzeni_dodavatelu=true and Info_uver.Uverejneni in ['VYS','UCH']">
                <ListFrameSdruzeniDod />
            </GroupBox>
            <GroupBox lockey="NEN-812843" showIf="Info_uver.Uverejneni in ['DODINFO']">
                <DataLabel lockey="NEN-812728" attrName='textDotaz' fillGrid rawText/>
                <DataLabel lockey="NEN-812733" attrName='textVysvetleni' showIf="not(Info_uver.ZP.Druh_ZR.Kod in['ZP25','ZP26'])" fillGrid rawText/>
                <DataLabel lockey="NEN-812772" attrName='textVysvetleni' showIf="Info_uver.ZP.Druh_ZR.Kod in['ZP25','ZP26']" fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-807152" showIf="Info_uver.Uverejneni in ['UDAJNAB']">
                <DataLabel lockey="NEN-807151" attrName='textVysvetleni' fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-812884" showIf="Info_uver.Uverejneni in ['PZ'] and Osoba_stret=null and Subdod=null and Pod=null">
                <DataLabel lockey="NEN-821548" attrName='oduvodVyberuDod' showIf="Oduv_vyberu_dodavatele<>null" fillGrid rawText />
                <DataLabel lockey="NEN-812887" attrName='oduvodPouziti' showIf="Oduv_JRBU_ZR<>null and Info_uver.ZP.Druh_ZR.Kod = 'ZP33'" fillGrid rawText />
                <DataLabel lockey="NEN-824910" attrName='oduvodPouzitiJRSU' showIf="Oduv_JRSU<>null" fillGrid rawText />
                <DataLabel lockey="NEN-812885" attrName='oduvodPouzitiJRBU' showIf="Oduv_JRBU<>null" fillGrid rawText />
                <DataLabel lockey="NEN-812886" attrName='oduvodKomunProstredky' showIf="Oduv_komunikace<>null" fillGrid rawText />
                <DataLabel lockey="NEN-812888" attrName='oduvodNerozdeleni' showIf="Oduv_nadlimit_casti<>null" fillGrid rawText />
                <DataLabel lockey="NEN-812889" attrName='oduvodStanoveniPozadav' showIf="Oduv_obratu<>null" fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-807229" showIf="(Info_uver.Uverejneni in ['PZ'] or Info_uver.Detail='SON') and Osoba_stret<>null">
                <DataLabel lockey="NEN-812890" attrName='nazev' showIf="Nazev<>null" fillGrid rawText />
                <DataLabel lockey="NEN-812883" attrName='prijataOpatreni' showIf="Prijata_opatreni<>null" fillGrid rawText />
            </GroupBox>
        </>
    );
}
const SdruzDodModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'ico',
        locKey: 'NEN-812665',
        width: columnWidths.ico
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    },
    {
        attrName: 'statKod',
        locKey: 'NEN-812527',
        width: columnWidths.stat
    }
];

const ListFrameSdruzeniDod: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailDetailUverLoadkey + '-detail-sdruz'}
            condition="Vedouci=@0 and Uverejnen=true"
            conditionParams={'id'}
            orderBy='uredniNazev'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detucasdruz', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            columns={SdruzDodModelColumns} />
    );
}

export default DetailDetailUver;