import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { IGetDataRowsParams } from "@services/DataRowsService";
import { AppDispatch, ApplicationState, ApplicationStateSelector } from "store";
import { getDataRowsService, useTaskManager } from "./nodeContextStore";
import { getErrorStoreActions } from './errorStore';
import { AttrMapping, IBasicData } from '@utils/AttrMapping';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createFilterCondition, FulltextAttr, joinConditionsByAnd } from '@utils/FilterCondition';
import { PaginatorHistoryState, parseActualPage } from '@components/Pagination';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useUrlParams, useUrlParamsExtended } from '@utils/QueryHook';
import { MaybeArray, toArray, uniqueArray } from '@utils/ArrayHelper';
import { FilterParamsNames } from '@constants/urlConstants';
import { OrderBy, createOrderBy, orderByStringToObject } from '@utils/OrderByUtils';
import { OnSortClickEventHandler, TableColumn } from "@components/Table";
import { AttrModel } from '@components/ListFrame';


// Declare an interface of the store's state.

export const name = 'collectionStore';

export type CollectionStoreState = {
    collections: {
        [key: string]: CollectionType
    };
}

export type StoreCollectionInfo = {
    onPage?: number;
    paramNames?: FilterParamsNames;
}

export type CollectionType = StoreCollectionInfo & {
    isFetching: boolean;
    isFetchedOnce: boolean;
    collection: IBasicData[]
    count?: number;
    isLoadMore?: boolean;
    isTooGeneralQuery?: boolean;
}

// Create the slice.
export const slice = createSlice({
    name,
    initialState: {
        collections: {}
    } as CollectionStoreState,
    reducers: {
        setFetching: (state, action: PayloadAction<{ key: string, isFetching: boolean, isLoadMore: boolean } & StoreCollectionInfo>) => {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    [action.payload.key]: {
                        ...state.collections[action.payload.key],
                        ...action.payload
                    }
                }
            };
        },
        setFetchedOnce: (state, action: PayloadAction<{ key: string, isFetchedOnce: boolean }>) => {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    [action.payload.key]: {
                        ...state.collections[action.payload.key],
                        isFetchedOnce: action.payload.isFetchedOnce
                    }
                }
            };
        },
        updateData: (state, { payload: { key, collection, count, isTooGeneralQuery } }: PayloadAction<{ key: string, collection: IBasicData[], count: number, isTooGeneralQuery?: boolean }>) => {
            return {
                ...state,
                collections: {
                    ...state.collections,
                    [key]: {
                        ...state.collections[key],
                        collection,
                        count,
                        isTooGeneralQuery,
                        isFetchedOnce: true
                    }
                }
            };
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getCollectionStoreState = (state: ApplicationState) => state[name];
export const getAllCollections = createSelector([getCollectionStoreState],
    (collectionsStore) => collectionsStore?.collections
);
export const getCollectionInfo = createSelector(
    getAllCollections,
    (_: unknown, key: string) => key,
    (collections, key) => collections[key]
);

// Define action creators.
export const actionCreators = {
    clear: (key: string) =>
        async (dispatch: AppDispatch, getState: ApplicationStateSelector) => {
            const actions = slice.actions;
            dispatch(actions.updateData({ key, collection: [], count: 0 }));
            dispatch(actions.setFetching({ key, isFetching: false, isLoadMore: false }));
            dispatch(actions.setFetchedOnce({ key, isFetchedOnce: false }));
        },
    loadData: (key: string, { onPage, paramNames, ...dataRowsParams }: IGetDataRowsParams & StoreCollectionInfo) =>
        async (dispatch: AppDispatch, getState: ApplicationStateSelector) => {
            const actions = slice.actions;
            dispatch(actions.setFetching({ key, isFetching: true, isLoadMore: dataRowsParams.isLoadMore || false, onPage, paramNames }));

            const service = getDataRowsService(getState());
            const result = await service.loadDataWithCount(dataRowsParams);

            if (!result.hasErrors) {
                dispatch(actions.updateData({ key, ...result.value }));
            } else {
                dispatch(actions.updateData({
                    key,
                    collection: [],
                    count: 0,
                    isTooGeneralQuery: result.errors?.some(error => typeof error == 'object' && error?.code == 'TOO_GENERAL_QUERY'),
                }));
                dispatch(getErrorStoreActions().push(result.errors));
            }

            dispatch(actions.setFetching({ key, isFetching: false, isLoadMore: false }));

            return result;
        }
}


type useCollectiomManagerCommonParams<CType> = {
    attrMapping: AttrMapping;
    attributes: string[];
    key: string;
    condition?: string;
    onPage?: number;
    paramNames?: FilterParamsNames;
    orderBy?: MaybeArray<OrderBy>;
}

export type useManualCollectionManagerParams<CType> = useCollectiomManagerCommonParams<CType> & {
    conditionParams?: any[];
    fetchCount?: number;
    startIndex?: number;
}

export type useCollectionManagerParams<CType> = useCollectiomManagerCommonParams<CType> & {
    onPage: number | undefined;
    fulltextAttributes?: FulltextAttr[];
    paramNames: FilterParamsNames;
}

export type useDependantCollectionManagerParams<CType> = useCollectiomManagerCommonParams<CType> & {
    conditionParams: any[]
    onPage?: number;
    fulltextAttributes?: FulltextAttr[];
    paramNames?: FilterParamsNames;
}

export const useManualCollectionManager = <CType>({
    attrMapping,
    attributes,
    condition,
    conditionParams,
    orderBy,
    onPage,
    paramNames,
    fetchCount,
    startIndex,
    key
}: useManualCollectionManagerParams<CType>) => {
    const dispatch: AppDispatch = useDispatch();
    const collectionInfo = useSelector((state: ApplicationState) => getCollectionInfo(state, key));

    const newOrder = orderBy ? createOrderBy(orderBy, attrMapping) : undefined
    const loadData = useCallback((isLoadMore?: boolean, controller?: AbortController) => dispatch(
        actionCreators.loadData(key, {
            className: attrMapping.objectName,
            attributes: attrMapping.getDataRowAttributes(...uniqueArray(['id'], attributes)),
            condition: condition,
            conditionParams: conditionParams,
            count: fetchCount,
            onPage,
            paramNames,
            isLoadMore: isLoadMore,
            startIndex: startIndex,
            orderBy: newOrder,
            abortSignal: controller?.signal
        })
    ), [dispatch, startIndex, condition, conditionParams, newOrder, startIndex, fetchCount]);

    const reloadCollection = useCallback(() => loadData(false), [loadData]);

    return { reloadCollection, collectionInfo, loadData, orderBy: newOrder };
}

export const useDependentCollectionManager = <CType>({
    attrMapping,
    attributes,
    condition,
    conditionParams,
    orderBy,
    key,
    onPage,
    paramNames,
    fulltextAttributes
}: useDependantCollectionManagerParams<CType>) => {
    const [isFirst, setIsFirst] = useState(true);
    const dispatch: AppDispatch = useDispatch();
    let fetchCount: number | undefined;
    let startIndex: number | undefined;
    let historyIsLoadMore: boolean | undefined;
    if (paramNames && onPage) {
        const { filterParams } = useUrlParams({ paramNames });
        const pageRange = parseActualPage(filterParams?.page);
        const pagesCount = Math.abs(pageRange[1] - pageRange[0]) + 1;
        const fetchCountParam = Number(filterParams?.onpage) || onPage;
        const actualPage = Math.min(...pageRange);
        historyIsLoadMore = useHistory<PaginatorHistoryState>().location.state?.isLoadMore;
        fetchCount = pagesCount * fetchCountParam;
        startIndex = (actualPage - 1) * fetchCountParam;
        const filterCondition = createFilterCondition({
            urlParams: filterParams,
            attrMapping: attrMapping,
            fulltextParam: 'query',
            fulltextAttributes
        });
        if (conditionParams && filterCondition.conditionParams) {
            filterCondition.condition = filterCondition.condition
                .match(/(@\d)/g)
                ?.sort((a, b) => Number(b[1]) - Number(a[1]))
                .reduce((acc, curr) => acc.replace(new RegExp(curr, 'g'), '@' + (Number(curr[1]) + conditionParams.length)),
                    filterCondition.condition)
                ?? filterCondition.condition;
        }
        condition = joinConditionsByAnd(condition, filterCondition.condition);
        conditionParams = conditionParams.concat(filterCondition.conditionParams);
    }

    const conditionParamsMemo = useMemo(() => [...conditionParams], [conditionParams.join('-')]);
    const {
        collectionInfo,
        reloadCollection,
        loadData,
        orderBy: newOrder
    } = useManualCollectionManager({
        key,
        attrMapping: attrMapping,
        condition: condition,
        conditionParams: conditionParamsMemo,
        attributes: attributes,
        onPage,
        paramNames,
        orderBy: orderBy,
        fetchCount: fetchCount,
        startIndex: startIndex
    });

    const taskManager = useTaskManager();

    useEffect(() => {
        return () => {
            dispatch(actionCreators.clear(key));
        }
    }, [dispatch, key]);

    useEffect(() => {
        const controller = new AbortController()
        if (taskManager.isTaskCompleted(key)) {
            taskManager.removeTaskFromCompleted(key);
        } else if (!isFirst && conditionParamsMemo.every(p => p != undefined)) {
            loadData(historyIsLoadMore, controller);
        } else {
            setIsFirst(false);
        }

        return () => {
            controller?.abort();
        }
    }, [fetchCount, condition, startIndex, conditionParamsMemo, newOrder?.join('-')]);

    if (conditionParamsMemo.every(p => p != undefined))
        taskManager.add(key, loadData);

    return { reloadCollection, collectionInfo, fetchCount, loadData, condition, conditionParams };
}

export const useCollectionManager = <CType>({
    key,
    attrMapping,
    onPage,
    fulltextAttributes,
    attributes,
    condition,
    paramNames,
    orderBy
}: useCollectionManagerParams<CType>) => {
    const dispatch: AppDispatch = useDispatch();
    const { match, filterParams } = useUrlParams({ paramNames });
    const pageRange = parseActualPage(filterParams?.page);
    const pagesCount = Math.abs(pageRange[1] - pageRange[0]) + 1;
    const fetchCountParam = Number(filterParams?.onpage) || onPage;
    const actualPage = Math.min(...pageRange);
    const { fetchCount, startIndex } = fetchCountParam ?
        { fetchCount: pagesCount * fetchCountParam, startIndex: (actualPage - 1) * fetchCountParam } :
        { fetchCount: undefined, startIndex: undefined }

    const taskManager = useTaskManager();
    const historyIsLoadMore = useHistory<PaginatorHistoryState>().location.state?.isLoadMore;
    const filterCondition = createFilterCondition({
        urlParams: filterParams,
        attrMapping: attrMapping,
        fulltextParam: 'query',
        fulltextAttributes
    });

    condition = joinConditionsByAnd(filterCondition.condition, condition);

    const {
        collectionInfo,
        reloadCollection,
        loadData,
        orderBy: newOrder
    } = useManualCollectionManager({
        key,
        attrMapping: attrMapping,
        condition: condition,
        conditionParams: filterCondition.conditionParams,
        attributes,
        onPage,
        paramNames,
        fetchCount: fetchCount,
        startIndex: startIndex,
        orderBy: orderBy,
    });

    useEffect(() => {
        return () => {
            dispatch(actionCreators.clear(key));
        }
    }, [dispatch, key]);

    useEffect(() => {
        const controller = new AbortController();
        if (taskManager.isTaskCompleted(key)) {
            taskManager.removeTaskFromCompleted(key);
        } else {
            loadData(historyIsLoadMore, controller);
        }

        return () => {
            controller?.abort();
        }
    }, [fetchCount, condition, startIndex, filterCondition.condition, filterCondition.conditionParams.join('-'), newOrder?.join('-')]);

    taskManager.add(key, loadData);

    return { reloadCollection, collectionInfo, fetchCount, match, condition, conditionParams: filterCondition.conditionParams };
}

export type UseSortUrlParams = {
    paramNames?: FilterParamsNames;
    orderBy?: MaybeArray<OrderBy>;
    columns: TableColumn<AttrModel<AttrMapping>>[];
};

export const useSortUrl = ({ paramNames, orderBy, columns }: UseSortUrlParams) => {
    paramNames ??= { filterParamName: '', routeParamName: '' };

    const [defaultOrderBy, setDefaultOrderBy] = useState(toArray(orderBy, true)
        .map(orderByPart => orderByStringToObject(orderByPart ?? ''))
    );

    const {
        updateFilterUrl,
        filterParams
    } = useUrlParamsExtended({ paramNames });

    const columnsSet = useMemo(() =>
        new Set(columns
            .filter(col => !col.hidden)
            .map(col => `sort-${col.attrName}`)),
    [columns]);

    const defaultFilterParams: any = useMemo(() => {
        return {
            ...Object.fromEntries(defaultOrderBy
                .map(obj => [`sort-${obj.column}`, obj.desc ? 'desc' : 'asc'] as const)),
            page: undefined
        }
    }, [defaultOrderBy]);

    const actualFilterParams = useRef(
        Object.fromEntries(Object.entries(defaultFilterParams).filter(([k]) => columnsSet.has(k)))
    );

    const orderByToObjects = Object.entries(filterParams ?? {})
        .filter(([k]) => k.includes('sort-'))
        .map(([k, v]) => Object.fromEntries([['desc', v == 'desc'], ['column', k.replace('sort-', '')]]));

    const addColumnToUrl = (column: string) => {
        if (!column) {
            return;
        }

        setDefaultOrderBy([]);

        const name = `sort-${column}`;
        let value = '';

        switch (filterParams?.[name] ?? (filterParams || defaultFilterParams?.[name])) {
            case 'none':
                value = 'asc';
                break;
            case 'asc':
                value = 'desc';
                break;
            case 'desc':
                value = 'none';
                break;
            default:
                value = 'asc';
        }

        actualFilterParams.current = Object.assign(
            Object.fromEntries(Object.entries(actualFilterParams.current).filter(([k]) => columnsSet.has(k) || filterParams?.[k])),
            filterParams,
            { [name]: value }
        );

        updateFilterUrl({ update: actualFilterParams.current });
    }

    return {
        addColumnToUrl,
        orderBy: orderByToObjects.length == 0 ? defaultOrderBy : orderByToObjects
    };
}

export type UseListFrameBase = UseSortUrlParams & {
    isSortable?: boolean;
    mapping: AttrMapping<any>;
};

export const useListFrameBase = (params: UseListFrameBase) => {
    const { mapping, isSortable, ...sortUrlParams } = params;
    const { addColumnToUrl, orderBy: newOrderBy } = useSortUrl(sortUrlParams);

    const filterHiddenColumns = useMemo<TableColumn<AttrModel<AttrMapping>>[]>(() => {
        return sortUrlParams.columns.filter(c => !c.hidden);
    }, [sortUrlParams.columns]);

    const onSortClickCallback = useCallback<OnSortClickEventHandler<typeof mapping>>((event) => {
        return isSortable !== false
            ? addColumnToUrl(event.column.attrName.toString())
            : undefined
    }, [isSortable, addColumnToUrl]);

    return { orderBy: newOrderBy, filterHiddenColumns, onSortClickCallback };
}

export type UseCollectionPaginatorProps = {
    collectionKey: string;
}

export const useCollectionPaginator = ({ collectionKey }: UseCollectionPaginatorProps) => {
    return useSelector((state: ApplicationState) => getCollectionInfo(state, collectionKey));
}
