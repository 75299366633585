import { FC } from "react"
import { useAppSettings } from "@store/nodeContextStore";
import LargeWidget from "@components/Widgets/LargeWidget";
import { useDescription } from "@utils/DescriptionHook";
import { useLocalization } from "@store/localizationStore";

const ProvozniRad: FC = () => {
    const { t } = useLocalization();
    const { getAllWidgetUrl } = useAppSettings();
    useDescription(t('NEN-789451'));

    return <LargeWidget src={getAllWidgetUrl('provozniRad')} />
};

ProvozniRad.displayName = 'ProvozniRad';

export default ProvozniRad;