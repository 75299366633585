import React, { createContext, FC, useContext } from "react";
import { PropsWithChildren } from "react";
import { useDetailObject, UseDetailObjectParams, UseDetailObjectReturnType } from "@store/detailObjectStore";
import { IData } from "@utils/AttrMapping";
import { Page404 } from "@pages/error/Page404";

type DetailFramePropsType<T extends IData> = PropsWithChildren<UseDetailObjectParams<T>>;

const DetailFrameContext = createContext<UseDetailObjectReturnType<any>>({
    getAttr: (attrName) => ({ value: null, isFetching: false }),
    evaluateCondition: (condition) => undefined
});

export const DetailFrame: FC<DetailFramePropsType<any>> = <T extends IData>({
    children,
    attrMapping,
    condition,
    conditionParams,
    loadKey }: DetailFramePropsType<T>) => {

    const { getAttr, evaluateCondition } = useDetailObject<T>({
        condition,
        conditionParams,
        attrMapping,
        loadKey,
    });    
    const id = getAttr('id');
    if (!id.isFetching && !id.value) {
        return (
            <Page404 />
        );
    }

    return (
        <DetailFrameContext.Provider value={{getAttr, evaluateCondition}}>
            {children}
        </DetailFrameContext.Provider>
    );
}

export const useDetailFrameContext = <T extends IData = any>() => useContext(DetailFrameContext) as UseDetailObjectReturnType<T>;

export default React.memo(DetailFrame);