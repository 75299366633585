import React from "react";
import { useRouteMatch } from "react-router";
import { DetailFrame } from "@components/DetailFrame/DetailFrame";
import { urlConstants } from "@constants/urlConstants";
import { UrlParamObject } from "@utils/QueryHook";
import GroupBox from "@components/GroupBox";
import { downloadButtonOnly } from "@components/Table/Buttons/DownloadAllToolbarButton";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { useStaticObject } from "@utils/ReactUtils";
import { DetailUverejneniAttrMap, IDetailUverejneniModel } from "@models/IDetailUverejneniModel";
import { columnWidths, DependentListFrameTyped, ListFrameColumns } from "@components/ListFrame";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { IInfoUverejneniModel, InfoUverejneniAttrMap } from "../../models/IInfoUVerejneniModel";
import { detailLinks } from "@constants/detailLinks";

const DependentListFrame = DependentListFrameTyped<IInfoUverejneniModel>();

const detailDodInfoLoadkey = 'detail-vysvetleni';

const DetailDodInfo: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const id = (match?.params[urlConstants.detailDodInfo.detailIdParam] || '');

    return (
        <DetailFrame
            condition='ID=@0'
            conditionParams={[id]}
            attrMapping={InfoUverejneniAttrMap}
            loadKey={detailDodInfoLoadkey}>
            <DetailVys />
        </DetailFrame>
    );
}
const DetailVys: React.FC = () => {
    return (
        <>
            <GroupBox lockey="NEN-812823" fillContent>
                <ListFrameZpravaVysvetleni />
            </GroupBox>
            <GroupBox lockey="NEN-812706" fillContent>
                <ListFramePriloha />
            </GroupBox>
        </>
    );
}

const vysvetleniModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'textVysvetleni',
        locKey: 'NEN-812733'
    }
];

const ListFrameZpravaVysvetleni: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailDodInfoLoadkey + '-detail-vysvetleni'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'dvy', routeParamName: urlConstants.detailDodInfo.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={vysvetleniModelColumns} />
    );
}
const prilohaModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];

const ListFramePriloha: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailDodInfoLoadkey + 'priloha'}
            condition="Info_uver=@0 and Info_uver.Uverejneni = 'DODINFO' and Uverejnen = true"
            conditionParams={'id'}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'dip', routeParamName: urlConstants.detailDodInfo.urlRouteParam })}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            columns={prilohaModelColumns}
            extraButtons={downloadButtonOnly} />
    );
}
export default DetailDodInfo;
