import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { History } from "history";
import { getUserCookieSettingsFromCookie } from '@store/userCookieSettingsStore';
import { NodeContextState } from '@store/nodeContextStore';
import { setCookie } from '@utils/CookiesUtils';

var appInsights: ApplicationInsights|null;
const reactPlugin = new ReactPlugin();

const telemetryInitializer = (envelope: ITelemetryItem) => {
    var basetype = envelope.baseType?.toLowerCase();
    if (basetype === "pageviewperformancedata") {
        return false;
    }
    else if (basetype === "remotedependencydata") {
        if (envelope?.baseData?.responseCode == 200) { // posíláme jen ty požadavky které dopadnou chybou
            return false;
        }
    }
    return true;
};

const useApplicationInsights = (history: History, nodeContext: NodeContextState, instrumentationKey: string | undefined) => {
    if (!instrumentationKey) {
        return;
    }
    const userCookieSettings = getUserCookieSettingsFromCookie(nodeContext.http);

    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: history }
            },
            disableCookiesUsage: true, //defaultně nepovolujeme cookies
            isStorageUseDisabled: true,
        }
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer(telemetryInitializer);

    if (userCookieSettings.settings.statistics) {
        enableApplicationInsightsCookies();
    }
    appInsights.trackPageView();
}

const enableApplicationInsightsCookies = () => {
    if (appInsights) {
        appInsights.config.disableCookiesUsage = false;
        appInsights.config.isStorageUseDisabled = false;
        appInsights.getCookieMgr().setEnabled(true);
    }
}

const disableApplicationInsightsCookies = () => {
    if (appInsights) {
        appInsights.config.disableCookiesUsage = true;
        appInsights.config.isStorageUseDisabled = true;
        appInsights.clearAuthenticatedUserContext();
        appInsights.getCookieMgr().setEnabled(false);
    }
    setCookie("ai_session", "", -1, 'None');
    setCookie("ai_user", "", -1, 'None');
}

export { reactPlugin, appInsights, useApplicationInsights, enableApplicationInsightsCookies, disableApplicationInsightsCookies };