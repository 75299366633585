import { createContext, FC, FocusEventHandler, KeyboardEventHandler, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { setupCn } from '@utils/BemUtils';
import GovNavLink, { GovNavLinkProps } from '@components/GovLink/GovNavLink';
import { useLocalization } from '@store/localizationStore';
import Pictogram from '@components/Pictogram/Pictogram';
import { pageIds } from '@components/Accessibility/pageIds';

import './GovMenu.scss';

const cn = setupCn('gov-header');

type MenuTabIndexContext = {
    links?: Set<HTMLAnchorElement>;
    handleFocus?: FocusEventHandler;
}
const MenuTabIndexContext = createContext<MenuTabIndexContext>({});

export type GovMenuType = FC<PropsWithChildren<{
    links: JSX.Element[];
    isActive: boolean;
    onLinkClick: () => void;
}>> & { Link: typeof Link }


const GovMenu: GovMenuType = ({ links, isActive, onLinkClick }) => {
    const refs = useRef(new Set<HTMLAnchorElement>()).current;
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [initial, setInitial] = useState<boolean>(true);

    const handleFocus = useCallback<FocusEventHandler<HTMLAnchorElement>>(e => {
        const index = [...refs].filter(r => r.clientHeight).indexOf(e.target);
        if (index >= 0) {
            setTabIndex(index);
        } else {
            setTabIndex(0);
        }
    }, [refs, setTabIndex]);

    const keys = useMemo(() => [37, 38, 39, 40], [])

    const onKeyDown = useCallback<KeyboardEventHandler>(e => {
        const { keyCode } = e;
        const visibleRefs = [...refs].filter(r => r.clientHeight);
        if (keys?.includes(keyCode)) {
            e.preventDefault();
            if (keyCode === (isActive ? 40 : 39)) {
                setTabIndex(index => {
                    index ??= 0;
                    return (index + 1) >= visibleRefs.length ? 0 : (index + 1)
                });
            } else if (keyCode === (isActive ? 38 : 37)) {
                setTabIndex(index => {
                    index ??= 0;
                    return (index - 1) < 0 ? (visibleRefs.length - 1) : (index - 1)
                });
            }
            if (initial) {
                setInitial(false);
            }
        }
    }, [setTabIndex, refs, setInitial, initial, isActive, keys]);

    useEffect(() => {
        refs?.forEach(r => r.setAttribute('tabindex', '-1'));

        const visibleRefs = [...refs].filter(r => r.clientHeight);
        const current = visibleRefs[tabIndex] ?? visibleRefs[0];
        current?.setAttribute('tabindex', '0');
        if (!initial) {
            current?.focus();
        }
    }, [tabIndex, refs, initial]);

    return (
        <nav
            id={pageIds.menu}
            role='menubar'
            className={cn.with({ 'active': isActive })('__nav')}
            aria-orientation={isActive ? 'vertical' : 'horizontal'}>
            <div className={cn.with({ 'active': isActive })('__nav-logo')} aria-hidden='true'>
                <Pictogram />
            </div>
            <div className={cn(' __nav-holder')} onKeyDown={onKeyDown}>
                <MenuTabIndexContext.Provider value={{ links: refs, handleFocus: handleFocus }}>
                    {
                        links.map((link, i) =>
                            <div key={i} className={cn.with(link.props.className)('__link')} onClick={onLinkClick}>
                                {link}
                            </div>
                        )
                    }
                </MenuTabIndexContext.Provider>
            </div>
        </nav>
    );
}

const Link: React.FC<PropsWithChildren<GovNavLinkProps & { lockey: string }>> = ({ lockey, ...rest }) => {
    const { ts } = useLocalization();
    const { links, handleFocus } = useContext(MenuTabIndexContext);
    const linkHref = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        const item = linkHref.current;
        if (item) {
            links?.add(item);
        }

        return () => {
            if (item) {
                links?.delete(item)
            }
        };
    }, [links]);

    return <GovNavLink
        role='menuitem'
        isInversed
        isStandalone
        linkRef={linkHref}
        onFocus={handleFocus}
        size={'large'} {...rest}>
        {ts(lockey, { isPrimaryTheme: true })}
    </GovNavLink>
}

GovMenu.Link = Link;

export default GovMenu;