import { AttrMapping, IData } from "@utils/AttrMapping";

export type IProfilZadavateleModel = IData & {
    adresaProfilu: string;
    adresaProfiluMulti: string;
    castObce: string;
    cisloOrientacni: number;
    cisloPopisne: number;
    dalsiProfil: string;
    datumDeaktivace: Date;
    datumNarozeni: Date;
    datumUverejneniVVZ: Date;
    datumVytvoreni: Date;
    dic: string;
    dominantniVliv: boolean;
    doplnekKNazvu: string;
    email: string;
    evidencniCisloVeVestniku: string;
    fax: string;
    ico: string;
    id: number;
    jinyIdentifikátor: string;
    kategorieZadavatele: string;
    multiprofil: boolean;
    nazevOrganizace: string;
    nazevProfilu: string;
    obec: string;
    obrana: boolean;
    pfNazev: string;
    profID: number;
    profilZadavatele: string;
    psc: string;
    relativniCinnost: string;
    relativniCinnostJinak: boolean;
    rodneCislo: string;
    stat: string;
    subjID: number;
    telefon1: string;
    telefon2: string;
    typSubjektu: string;
    ulice: string;
    www: string;
    nazevUctu: string;
    predcisliUctu: number;
    cisloUctu: string;
    kodBanky: string;
    iban: string;
    swift: string;
    platnyZruseny: boolean;
    neaktivniAktivni: boolean;
    nutsKod: string;
}

export const ProfilZadavateleAttrMap = new AttrMapping<IProfilZadavateleModel>('Profil_lw', [
    { clientName: 'adresaProfilu', serverName: 'Adresa_profil_NEN', type: 'string' },
    { clientName: 'adresaProfiluMulti', serverName: 'Prof.Adresa_profil_NEN', type: 'string' },
    { clientName: 'castObce', serverName: 'Subj.Cast_obce', type: 'string' },
    { clientName: 'cisloOrientacni', serverName: 'Subj.Cislo_orientacni', type: 'number' },
    { clientName: 'cisloPopisne', serverName: 'Subj.Cislo_popisne', type: 'number' },
    { clientName: 'dalsiProfil', serverName: 'Subj.Dalsi_profil_1', type: 'string' },
    { clientName: 'datumDeaktivace', serverName: 'Plati_do', type: 'dateTime' },
    { clientName: 'datumNarozeni', serverName: 'Subj.Datum_narozeni', type: 'dateTime' },
    { clientName: 'datumUverejneniVVZ', serverName: 'Datum_uver_VVZ', type: 'dateTime' },
    { clientName: 'datumVytvoreni', serverName: 'Plati_od', type: 'dateTime' },
    { clientName: 'dic', serverName: 'Subj.DIC', type: 'string' },
    { clientName: 'dominantniVliv', serverName: 'Subj.Dominantni_vliv', type: 'bool' },
    { clientName: 'doplnekKNazvu', serverName: 'Subj.Doplnek_k_nazvu', type: 'string' },
    { clientName: 'email', serverName: 'Subj.Email', type: 'string' },
    { clientName: 'evidencniCisloVeVestniku', serverName: 'Subj.Evid_c_ve_Vestniku', type: 'string' },
    { clientName: 'fax', serverName: 'Subj.Fax', type: 'string' },
    { clientName: 'ico', serverName: 'Subj.ICO', type: 'string' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'jinyIdentifikátor', serverName: 'Subj.Jiny_identifikator', type: 'string' },
    { clientName: 'kategorieZadavatele', serverName: 'Subj.Kategorie_zadavatele', type: 'enum', typeParam: 'Kategorie_zadavatele' },
    { clientName: 'multiprofil', serverName: 'Prof.Multiprofil', type: 'bool' },
    { clientName: 'nazevOrganizace', serverName: 'Subj.Nazev', type: 'string' },
    { clientName: 'nazevProfilu', serverName: 'Prof.Nazev', type: 'string' },
    { clientName: 'obec', serverName: 'Subj.Obec', type: 'string' },
    { clientName: 'obrana', serverName: 'Subj.Obrana', type: 'bool' },
    { clientName: 'pfNazev', serverName: 'Subj.PF.Nazev', type: 'string' },
    { clientName: 'profID', serverName: 'Prof', type: 'id' },
    { clientName: 'profilZadavatele', serverName: 'Subj.Profil_zadavatele', type: 'string' },
    { clientName: 'psc', serverName: 'Subj.PSC', type: 'string' },
    { clientName: 'relativniCinnost', serverName: 'Subj.Rel_cin', type: 'string' },
    { clientName: 'relativniCinnostJinak', serverName: 'Subj.Rel_cinnost_jinak', type: 'bool' },
    { clientName: 'rodneCislo', serverName: 'Subj.Rodne_cislo', type: 'string' },
    { clientName: 'stat', serverName: 'Subj.Stat_adresy.Kod', type: 'string' },
    { clientName: 'subjID', serverName: 'Subj', type: 'id' },
    { clientName: 'telefon1', serverName: 'Subj.Telefon_1', type: 'string' },
    { clientName: 'telefon2', serverName: 'Subj.Telefon_2', type: 'string' },
    { clientName: 'typSubjektu', serverName: 'Subj.Typ_subj', type: 'string' },
    { clientName: 'ulice', serverName: 'Subj.Ulice', type: 'string' },
    { clientName: 'www', serverName: 'Subj.WWW', type: 'string' },
    { clientName: 'nazevUctu', serverName: 'Subj.Nazev_uctu', type: 'string' },
    { clientName: 'predcisliUctu', serverName: 'Subj.Predcisli_uctu', type: 'number' },
    { clientName: 'cisloUctu', serverName: 'Subj.cislo_uctu', type: 'string' },
    { clientName: 'kodBanky', serverName: 'Subj.Banka_uctu.Kod', type: 'string' },
    { clientName: 'iban', serverName: 'Subj.IBAN', type: 'string' },
    { clientName: 'swift', serverName: 'Subj.Banka_uctu.SWIFT', type: 'string' },
    { clientName: 'platnyZruseny', serverName: 'Platny', type: 'bool' },
    { clientName: 'neaktivniAktivni', serverName: 'Subj.Neaktivni_profil', type: 'bool' },
    { clientName: 'nutsKod', serverName: 'Subj.vazba_NUTS.Kod', type: 'string' },
]);
