import React, { PropsWithChildren, ReactNode } from "react";
import { Classes, setupCn } from "@utils/BemUtils";
import './DataLabelComponent.scss';
import Note from "@components/Note/Note";

const cn = setupCn('gov-grid-tile');

export type DataLabelComponentProps = PropsWithChildren<{
    className?: Classes;
    captionTitle?: string;
    valueTitle?: string;
    caption: ReactNode;
    value?: ReactNode;
    fillGrid?: boolean;
    rawText?: boolean;
    notWrapValue?: boolean;
}>;

const DataLabelComponent: React.FC<DataLabelComponentProps> = ({
    className,
    caption,
    captionTitle,
    valueTitle,
    fillGrid,
    rawText,
    notWrapValue,
    children
}) => {    
    if (children == null) {
        return null;
    }

    valueTitle ??= ['string', 'number'].includes(typeof children) ? children?.toString() : undefined;

    const content = notWrapValue ?
        children :
        <Note title={valueTitle}>{children}</Note>;

    return (
        <div
            title={captionTitle}
            className={cn.with(className).main({
                '--fill-grid': fillGrid,
                '--raw-text': rawText
            })}>
            <h3 className='gov-title--delta'>{caption}</h3>
            {content}
        </div>
    );
};

export default React.memo(DataLabelComponent);
