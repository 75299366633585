import { AttrMapping, IData } from "@utils/AttrMapping";

export type IRelevantniCinnostSubjektuHist = IData & {
    druhRelevantniCinnosti: string;
    jinaCinnost: string;
}

export const RelevantniCinnostSubjektuHistAttrMap = new AttrMapping<IRelevantniCinnostSubjektuHist>('Rel_cinnost_sub_hist', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'druhRelevantniCinnosti', serverName: 'Rel_cinnost.Nazev', type: 'string' },
    { clientName: 'jinaCinnost', serverName: 'Jina_cinnost', type: 'string' },
]);
