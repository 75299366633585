import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WidgetItem, WidgetStoreState } from "@store/WidgetStore/widgetStore";


export const createWidgetItemsSlice = <TItem extends WidgetItem>(name: string) => createSlice({
    name,
    initialState: {
        items: [] as TItem[],
        errors: [] as string[]
    } as WidgetStoreState<TItem>,
    reducers: {
        setItems: (state, action: PayloadAction<TItem[]>) => {
            return {
                ...state,
                items: action.payload
            };
        },
        setErrors: (state, action: PayloadAction<string[]>) => {
            state.errors = action.payload;
        }
    }
});

