import React, { FC, PropsWithChildren } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";

const cn = setupCn('table-empty-message');

export type TableEmptyMessageProps = PropsWithChildren<{
    className?: Classes;
    isTooGeneralQuery?: boolean;
}>;

const TableEmptyMessage: FC<TableEmptyMessageProps> = ({
    className,
    isTooGeneralQuery,
}) => {
    const { ts } = useLocalization();
    return (
        <div className={cn.with(className)()}>
            {ts(isTooGeneralQuery ? 'NEN-821272' : 'NEN-747722')}
        </div>
    );
};

TableEmptyMessage.displayName = 'TableEmptyMessage';

export default React.memo(TableEmptyMessage);
