import { ReactNode } from "react";
import { UseLocalizationType } from "@store/localizationStore";
import { ICryptographicInfo, IEncryptionInfo } from "./interface/ICryptographicInfo";
import { CertificateUsageType, ICertificate, IDistinguishedName, ISignatureInfo, SignatureInfoType } from "./interface/ISignatureInfo";

/**
 * Enum validačních hlášek digitálního podpisu
 */
export enum SignatureValidationMsg {
    SignaturePolicyNotMet = "SignaturePolicyNotMet",
    AttributeEncrypted = "AttributeEncrypted",
    AllowUnknownSignature = "AllowUnknownSignature",
    EmptySignature = "Signature is empty/not generated",
    HashEqualError = "HashEqualError",
    IncorrectIssuer = "IncorrectIssuer",
    IncorrectKeyUsage = "IncorrectKeyUsage",
    IncorrectIssuerServerCertificate = "IncorrectIssuerServerCertificate",
    InvalidReferences = "References Invalid",
    InvalidSignatureType = "InvalidSignatureType",
    IsEncrypted = "IsEncrypted",
    NeedManualVerification = "NeedManualVerification",
    OnlyDocumentPartSigned = "OnlyDocumentPartSigned",
    OutOfDate = "OutOfDate",
    OutOfDateServerCertificate = "OutOfDateServerCertificate",
    Revocated = "Revocated",
    RevocatedServerCertificate = "RevocatedServerCertificate",
    ServerCertificateError = "ServerCertificateError",
    TimeStampError = "TimeStampError",
    UnknownSignature = "UnknownSignature",
    CantVerifySignature = "CantVerifySignature",
    TryLater = "TryLater",
    Error = "Error",
    Failed = "Failed",
    Empty = "Empty"
}

export enum SignatureValidationIntermediateMsg {
    CantVerifySignature,
    InvalidReferences,
    IncorrectIssuer,
    InvalidSignatureType,
    OutOfDate,
    Revocated,
    TryLater,
    TimeStampError,
    Error,
    SignaturePolicyNotMet
}

export enum UseCase {
    /** Bez bližšího určení  */
    None,
    /** Při podepisování */
    Signing
}

export enum SignatureCssClass {
    Signed = "signed",
    Unknown = "unknown",
    Warning = "warning"
}

export const isSignatureEmpty = (signatureInfo: ISignatureInfo): boolean => {
    return signatureInfo && signatureInfo.type === SignatureInfoType.empty;
}

export const isSigned = (signatureInfo: ISignatureInfo): boolean => {
    return signatureInfo && signatureInfo.type !== SignatureInfoType.empty;
}

export const isEncrypted = (info: ICryptographicInfo): boolean => {
    if (info && info.encryptionInfo && info.encryptionInfo.encrypted)
        return true;
    else
        return info && info.signatureInfo && (info.signatureInfo.validationMessage === SignatureValidationMsg.IsEncrypted || info.signatureInfo.validationMessage === SignatureValidationMsg.AttributeEncrypted || info.signatureInfo.validationMessage === SignatureValidationMsg.AllowUnknownSignature);
}

/*
    * Vrací hlášku k zadanému stavu podpisu 
    */
export const getSignatureValidationLocKey = (validationMessage: string, useCase: UseCase = UseCase.None): string | null => {
    switch (useCase) {
        case UseCase.None:
            switch (validationMessage) {
                case SignatureValidationMsg.SignaturePolicyNotMet: return "GUI-664969"; // Certifikát nesplňuje požadované vlastnosti
                case SignatureValidationMsg.AllowUnknownSignature: return "GUI-618548";// Neznámý formát podpisu.
                case SignatureValidationMsg.EmptySignature: return "GUI-389642";       // Digitální podpis je prázdný
                case SignatureValidationMsg.HashEqualError: return "GUI-393912";       // Aktuální stav záznamu neodpovídá podpisu
                case SignatureValidationMsg.IncorrectIssuer: return "GUI-582035";      // Chyba při kontrole certifikátu nesprávný vydavatel.
                case SignatureValidationMsg.IncorrectKeyUsage: return "GUI-582034";    // Chyba při kontrole certifikátu nesprávné použití klíče.
                case SignatureValidationMsg.IncorrectIssuerServerCertificate: return "GUI-614076"; // Chyba serverového certifikátu nepodporovaný vydavatel. Kontaktujte správce systému.
                case SignatureValidationMsg.InvalidReferences: return "GUI-393912";    // Aktuální stav záznamu neodpovídá podpisu
                case SignatureValidationMsg.InvalidSignatureType: return "GUI-568137"; // Neznámý typ podpisu
                case SignatureValidationMsg.AttributeEncrypted:
                case SignatureValidationMsg.IsEncrypted:
                    return "GUI-418020";    // Stav podpisu není možné ověřit data jsou šifrována.
                case SignatureValidationMsg.NeedManualVerification: return "GUI-582043"; // Chyba při kontrole certifikátu certifikát nebylo možné ověřit.
                case SignatureValidationMsg.OnlyDocumentPartSigned: return "GUI-568139"; // Pouze část dokumentu je podepsaná
                case SignatureValidationMsg.OutOfDate: return "GUI-582040";             // Chyba při kontrole certifikátu nesprávné datum platnosti.
                case SignatureValidationMsg.OutOfDateServerCertificate: return "GUI-614074"; // Chyba serverového certifikátu nesprávné datum platnosti. Kontaktujte správce systému.
                case SignatureValidationMsg.Revocated: return "GUI-582041";                 // Chyba při kontrole certifikátu certifikát byl odvolán.
                case SignatureValidationMsg.RevocatedServerCertificate: return "GUI-614075"; // Chyba serverového certifikátu certifikát byl odvolán. Kontaktujte správce systému.
                case SignatureValidationMsg.ServerCertificateError: return "GUI-614072";     // Chyba serverového certifikátu. Kontaktujte správce systému.
                case SignatureValidationMsg.TimeStampError: return "GUI-582042";             // Chyby při kontrole certifikátu nesprávné časové razítko.
                case SignatureValidationMsg.UnknownSignature: return "GUI-618548"; // Neznámý formát podpisu.
                case SignatureValidationMsg.CantVerifySignature: return "GUI-564883"; // Nepodařilo se ověřit platnost součásti podpisu.
                case SignatureValidationMsg.TryLater: return "GUI-658906";        // Aktuálně není možno certifikát použitý pro podpis ověřit. Ověření je třeba opakovat.
                case SignatureValidationMsg.Error: return "GUI-564883"; // Nepodařilo se ověřit platnost součásti podpisu.
                default:
                    break;
            }
            break;
        case UseCase.Signing:
            switch (validationMessage) {
                case SignatureValidationMsg.SignaturePolicyNotMet: return "GUI-664969"; // Certifikát nesplňuje požadované vlastnosti
                case SignatureValidationMsg.IncorrectIssuer: return "GUI-582035";      // Chyba při kontrole certifikátu nesprávný vydavatel.
                case SignatureValidationMsg.IncorrectKeyUsage: return "GUI-582034";    // Chyba při kontrole certifikátu nesprávné použití klíče.
                case SignatureValidationMsg.OutOfDate: return "GUI-582040";             // Chyba při kontrole certifikátu nesprávné datum platnosti.
                case SignatureValidationMsg.Revocated: return "GUI-582041";                 // Chyba při kontrole certifikátu certifikát byl odvolán.
                case SignatureValidationMsg.TimeStampError: return "GUI-582042";             // Chyby při kontrole certifikátu nesprávné časové razítko.
                case SignatureValidationMsg.CantVerifySignature: return "GUI-564883"; // Nepodařilo se ověřit platnost součásti podpisu.
                case SignatureValidationMsg.TryLater: return "GUI-658906";        // Aktuálně není možno certifikát použitý pro podpis ověřit. Ověření je třeba opakovat.
                case SignatureValidationMsg.Error: return "GUI-389142"; //  Při ověření certifikátu došlo k neočekávané chybě. Záznam nelze podepsat. Kontaktujte správce systému.
                case SignatureValidationMsg.Failed: return "GUI-389144"; //Zadaný certifikát není možné použít pro podpis.
                default:
                    break;
            }
    }
    return null;
}

export const getSignatureValidationText = (validationMessage: string | null | undefined, localization: UseLocalizationType): string | null => {
    const { t } = localization;
    const validationLocKey = validationMessage && getSignatureValidationLocKey(validationMessage);
    return validationLocKey ? t(validationLocKey) : validationMessage ?? null;
}

export const getSignatureStateTooltip = (signatureInfo: ISignatureInfo, localization: UseLocalizationType): string | null => {
    if (signatureInfo == null) {
        return null;
    }

    const { t } = localization;

    if (isInCautionTime(signatureInfo)) {
        const { locKey, locParams } = getCautionTimeLocKey(signatureInfo.cautionTime);
        return t(locKey, { locParams });
    }

    // null - prázdný, neznámý, šifrovaný podpis
    if (signatureInfo.valid == null) {
        if (signatureInfo.validationMessage?.length) {
            return getSignatureValidationText(signatureInfo.validationMessage, localization);
        }
        return t('GUI-389642'); // Digitální podpis je prázdný
    }

    if (signatureInfo.valid === true) {
        return t('GUI-389872'); // Digitální podpis je platný
    }

    switch (signatureInfo.validationMessage) {
        case null:
        case '':
            return t('GUI-389876'); // Chyba při kontrole digitálního podpisu
        case SignatureValidationMsg.Failed:
        case SignatureValidationMsg.Error:
        case SignatureValidationMsg.Empty:
            return null;
        default:
            return getSignatureValidationText(signatureInfo.validationMessage, localization);
    }
};

export const isInCautionTime = (signatureInfo: ISignatureInfo): signatureInfo is (ISignatureInfo & { cautionTime: string }) =>
    signatureInfo?.valid === true && signatureInfo.cautionTime && signatureInfo.cautionTime[0] != '-' || false;


export const getCautionWaitingTime = (cautionTime: string): string => {
    const [hours, minutes, seconds] = cautionTime.split(':').map(x => parseInt(x));
    if (hours > 0) {
        return `${hours}h`;
    }
    if (minutes > 0) {
        return `${minutes}min`;
    }
    return `${seconds}s`;
}

export const getCautionTimeLocKey = (cautionTime: string): { locKey: string, locParams: string[] } => {
    return {
        locKey: 'GUI-672298',
        locParams: [getCautionWaitingTime(cautionTime)],
    };
}

export const getSignatureState = (signatureInfo: ISignatureInfo, localization: UseLocalizationType): ReactNode | null => {
    if (signatureInfo == null) {
        return null;
    }

    const { ts } = localization;

    // null - prázdný, neznámý, šifrovaný podpis
    if (signatureInfo.valid == null) {
        return getSignatureValidationText(signatureInfo.validationMessage, localization);
    }

    if (isInCautionTime(signatureInfo)) {
        const { locKey, locParams } = getCautionTimeLocKey(signatureInfo.cautionTime);
        return ts(locKey, { locParams });
    }

    if (signatureInfo.valid === true) { // true - platný
        return ts("GUI-389872"); // Digitální podpis je platný
    }

    if (signatureInfo.validationMessage) {
        return getSignatureValidationText(signatureInfo.validationMessage, localization);
    }

    return <>{ts("GUI-389876")}{'\n'}{signatureInfo.validationMessage}</>; //'Chyba při kontrole digitálního podpisu
}

/**
 * Ze struktury názvu vybere požadovanou část a pokud jich je více, tak je spojí oddělovačem do jednoho řetězce
 * @param nameInfo Struktura s názvem
 * @param partKey Klíč názvu hledané části
 * @param separator Oddělovač použitý pro spojení více částí
 * @param defaultReturnValue Výchozí hodnota vrácená při neexistenci části
 */
export const formatDistinguishedNamePart = (nameInfo: IDistinguishedName, partKey: string, separator: string = ", ", defaultReturnValue: any = ""): string | null => {
    const parts = nameInfo[partKey];
    return parts ? parts.join(separator) : defaultReturnValue;
}

/**
 * Vytvoří text pro tooltip podpisu
 * @param signatureInfo Informace o podpisu
 * @param culture Kultura použitá pro formátování
 */
export const createSignatureCertificateTooltipText = (signatureInfo: ISignatureInfo, localization: UseLocalizationType): string | null => {
    if (signatureInfo?.certificate == null) {
        return null;
    }

    const { t } = localization;

    const parts: string[] = [];

    parts.push(new Date(signatureInfo.time.toString()).toLocaleString());

    const commonName = formatDistinguishedNamePart(signatureInfo.certificate.subject, "CN");
    if (commonName) {
        parts.push(commonName);
    }

    const email = formatDistinguishedNamePart(signatureInfo.certificate.subject, "E");
    if (email) {
        parts.push(`(${email})`);
    }
    return `${t("GUI-536877")}: ${parts.join(", ")}`; // Podepsáno
}

export const getCounterSignatureCountTooltip = (signatureInfo: ISignatureInfo, localization: UseLocalizationType): string | null => {
    if (signatureInfo == null || signatureInfo.certificate == null) {
        return null;
    }

    const { t } = localization;

    let counterSignaturesCount = signatureInfo.counterSignatureCount ?? 0;
    if (!counterSignaturesCount && signatureInfo.counterSignature?.length) {
        for (let signature of signatureInfo.counterSignature) {
            if (signature.type != SignatureInfoType.timeStamp)
                counterSignaturesCount++;
        }
    }

    return `${t("GUI-604239")}: ${counterSignaturesCount}`; //Počet kontrapodpisů
}

export const getEncryptionTooltipParts = (cryptoInfo: IEncryptionInfo): string[] => {
    if (!cryptoInfo || !cryptoInfo.encrypted) {
        return [];
    }

    const texts: (string | null)[] = [];
    if (cryptoInfo.time) {
        texts.push(cryptoInfo.time ? new Date(cryptoInfo.time.toString()).toLocaleDateString() + ", " + new Date(cryptoInfo.time.toString()).toLocaleTimeString() : "");

    }
    if (cryptoInfo.certificate?.subject) {
        texts.push(formatDistinguishedNamePart(cryptoInfo.certificate.subject, "CN"));
    }
    return texts.filter(t => t) as string[];
}

export const getSignatureUsages = (certificateInfo: ICertificate): string[] => {
    return (certificateInfo.certificateKeyUsage ?? []).map(usage => {
        switch (usage) {
            case CertificateUsageType.crlSign:
                return "GUI-488336"; // Podepisování CRL
            case CertificateUsageType.dataEncipherment:
                return "GUI-488340"; // Šifrování dat
            case CertificateUsageType.decipherOnly:
                return "GUI-488342"; // Dešifrování
            case CertificateUsageType.digitalSignature:
                return "GUI-488346"; // Digitální podpis
            case CertificateUsageType.encipherOnly:
                return "GUI-488348"; // Šifrování
            case CertificateUsageType.keyAgreement:
                return "GUI-488352"; // Dohoda o klíčích
            case CertificateUsageType.keyCertSign:
                return "GUI-488354"; // Podepisování certifikátu
            case CertificateUsageType.keyEncipherment:
                return "GUI-488356"; // Šifrování klíče
            //case CertificateUsageType.none: key = "GUI-630047"; break; // Žádné parametry použití klíče
            case CertificateUsageType.nonRepudiation: // Neodvolatelnost
                return "GUI-488358";
            default:
                return null;
        }
    }).filter(locKey => locKey) as string[];
}

export const hasSignatureWarning = (signatureInfo: ISignatureInfo, withCounterSignatures = true): boolean => {
    if (isInCautionTime(signatureInfo)) {
        return true;
    }

    if (withCounterSignatures && signatureInfo.counterSignature) {
        for (const cs of signatureInfo.counterSignature)
            if (hasSignatureWarning(cs))
                return true;
    }
    if (signatureInfo.valid == null && signatureInfo.validationMessage?.length && signatureInfo.validationMessage != SignatureValidationMsg.EmptySignature) {
        return true;
    }

    if (signatureInfo.valid === false && signatureInfo.validationMessage) {
        switch (signatureInfo.validationMessage) {
            case SignatureValidationMsg.Failed:
            case SignatureValidationMsg.Error:
            case SignatureValidationMsg.Empty:
                break;
            default:
            case null:
            case "":
                return true;
        }
    }

    return false;
}

export const getSignatureWarningClass = (signatureInfo: ISignatureInfo, withCounterSignatures = true): SignatureCssClass | null => {
    if (!hasSignatureWarning(signatureInfo, withCounterSignatures))
        return null;

    return signatureInfo.type === SignatureInfoType.unknown ? SignatureCssClass.Unknown : SignatureCssClass.Warning;
}

export const getCryptoInfoToolTip = (signatureInfo: ISignatureInfo, encInfo: IEncryptionInfo | null | undefined, localization: UseLocalizationType): string => {
    const textLines: (string | null)[] = [];
    const { t } = localization;
    if (encInfo?.encrypted) {
        const encTooltipParts = getEncryptionTooltipParts(encInfo);
        const encTooltip = `${t("GUI-536875")}: ${encTooltipParts}  `
        textLines.push(encTooltip);
    }
    if (!signatureInfo)
        textLines.push(t("GUI-389642")); // Digitální podpis je prázdný
    else {
        textLines.push(createSignatureCertificateTooltipText(signatureInfo, localization));
        textLines.push(getSignatureStateTooltip(signatureInfo, localization));
        textLines.push(getCounterSignatureCountTooltip(signatureInfo, localization));
    }

    return textLines.filter(l => l).join("\n"); //vyfiltruje null nebo undefined řádky a spojí je.
}

export const updateSignatureValidationMessage = (signatureInfo: ISignatureInfo) => {
    if (signatureInfo.type != SignatureInfoType.digitalSignature) {
        return;
    }
    
    if (signatureInfo.valid == false && !signatureInfo.validationMessage && signatureInfo.validationDetails) {
        signatureInfo.validationMessage = signatureInfo.validationDetails == 'HashFailure' ?
            SignatureValidationMsg.HashEqualError :
            signatureInfo.validationDetails;
    }
}
