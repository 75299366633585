import React, { createContext, FC, PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { useToggleState } from "@utils/StateUtils";
import { usePreventDefaultCallback, useStopPropagationCallback } from "@utils/EventUtils";
import { useFilterManagerContext } from "../FilterManager";
import { UrlParamObject } from "@utils/QueryHook";
import Accordion from "@components/Accordion";

const cn = setupCn('filter-manager-group');

export type FilterManagerGroupProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    header?: ReactNode;
    defaultVisible?: boolean;
    noTopBorder?: boolean;
}>;

export const FilterGroupContext = createContext<(filterName?: string) => void>(() => null);

const FilterManagerGroup: FC<FilterManagerGroupProps> = ({
    id,
    className,
    header,
    defaultVisible,
    noTopBorder,
    children
}) => {
    const {
        filterValues
    } = useFilterManagerContext();
    const {
        handleContentToggle,
        isContentHidden,
        registerFilterName
    } = useFilterGroupExpandToggle({ filterValues, defaultVisible });
    return (
        <Accordion.Page
            id={id}
            className={cn.with(className)()}
            header={header}
            noTopBorder={noTopBorder}
            isExpanded={!isContentHidden}
            onToggle={handleContentToggle}>
            <FilterGroupContext.Provider value={registerFilterName}>
                {children}
            </FilterGroupContext.Provider>
        </Accordion.Page>
    );
};

type UseFilterGroupExpandToggleProps = {
    filterValues: UrlParamObject;
    defaultVisible?: boolean;
};

export const useFilterGroupExpandToggle = ({ filterValues, defaultVisible }: UseFilterGroupExpandToggleProps) => {
    const [isContentHidden, toggleIsContentHidden, setIsContentHidden] = useToggleState(!defaultVisible);
    const canAutotoggle = useRef<boolean>(!defaultVisible);
    const [filterNames, setFilterNames] = useState<string[]>([]);
    const filterGroupContext = useContext(FilterGroupContext);

    useEffect(() => {
        if (!canAutotoggle.current) {
            return;
        }
        const isVisible = filterNames.some(a => filterValues[a] != null && filterValues[a] != '');
        setIsContentHidden(!isVisible);
    }, [filterNames]);

    const registerFilterName = useCallback((filterName?: string) => {
        filterGroupContext?.(filterName);
        setFilterNames(prevFilterNames => {
            if (filterName && !prevFilterNames.includes(filterName)) {
                return [...prevFilterNames, filterName];
            }
            return prevFilterNames;
        })
    }, [filterGroupContext]);

    const handleContentToggle = useStopPropagationCallback(
        usePreventDefaultCallback(() => {
            canAutotoggle.current = false;
            toggleIsContentHidden();
        }, []),
        []);

    return { isContentHidden, registerFilterName, handleContentToggle };
}

export const useFilterGroupRegister = (filterName?: string) => {
    const filterGroupContext = useContext(FilterGroupContext);
    useEffect(() => filterGroupContext(filterName), []);
}

export default React.memo(FilterManagerGroup);
