import { FC } from "react";
import { useLocalization } from "@store/localizationStore";
import ContentBlock from "@components/ContentBlock";

export const Page413: FC = () => {
    const { ts } = useLocalization();

    return (
        <ContentBlock>
            {ts('NEN-804841')}
        </ContentBlock>
    );
}