import { formatDistinguishedNamePart, getSignatureUsages as getSignatureUsagesLocKeys } from 'crypto/SignatureUtils';
import { ICertificate } from 'MultiWebClient/MWCore/TescoSW/Global/Base/Crypto/ISignatureInfo';
import React, { FC, PropsWithChildren } from 'react'
import { useLocalization } from '@store/localizationStore';
import { arrayInjectSeparator } from '@utils/ArrayHelper';
import { setupCn, Classes } from '@utils/BemUtils';
import { DataLabelComponent } from '@components/DataLabel';
import Grid from '@components/Grid';

const cn = setupCn('certificate-detail');

export type CertificateDetailProps = PropsWithChildren<{
    className?: Classes;
    certificate: ICertificate;
}>;

const CertificateDetail: FC<CertificateDetailProps> = ({
    className,
    certificate,
}) => {
    const localization = useLocalization();

    if (!certificate) {
        return null;
    }

    const { ts } = localization;
    const dateFrom = new Date(certificate.validFrom.toString()).toLocaleDateString();
    const dateTo = new Date(certificate.validTo.toString()).toLocaleDateString();
    const usages = getSignatureUsagesLocKeys(certificate);

    return (
        <Grid className={cn.with(className).main()} columns='2-mobile' isPlain bottomSpace='unset'>
            <DataLabelComponent caption={ts('GUI-629011')}>{arrayInjectSeparator(usages.map((locKey, i) => <React.Fragment key={i}>{ts(locKey)}</React.Fragment>), () => ', ')}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629012')}>{certificate.serialNumber}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629013')}>{formatDistinguishedNamePart(certificate.issuer, 'CN')}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629014')}>{certificate.signatureAlgorithm}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629015')}>{dateFrom + ' - ' + dateTo}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629016')}>{ts(certificate.hasPrivateKey ? 'GUI-146838' : 'GUI-146840')}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629017')}>{certificate.version.toString()}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629018')}>{certificate.publicKeyAlgorithm}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629019')}>{certificate.hashAlgorithm}</DataLabelComponent>
            <DataLabelComponent caption={ts('GUI-629020')}>{certificate.thumbprint}</DataLabelComponent>
        </Grid>
    );
};

CertificateDetail.displayName = 'CertificateDetail';

export default React.memo(CertificateDetail);
