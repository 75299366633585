import React, { useState, FC, PropsWithChildren, useCallback } from "react";
import { setupCn } from "@utils/BemUtils";
import Button from "@components/Button/Button";

const cn = setupCn('gov-alert');

export type AlertType = 'basic' | 'inversed' | 'warning' | 'error' | 'content';

export type AlertProps = PropsWithChildren<{
    type?: AlertType,
    closeButton?: boolean
}>;

const typeClasses: { [key in AlertType]: string | null } = {
    basic: '--basic',
    inversed: '--inversed',
    warning: '--warning',
    error: '--error',
    content: '--content'
}

export const Alert: FC<AlertProps> = ({ children, type, closeButton }) => {
    type ??= 'basic';
    const typeClass = typeClasses[type] ?? undefined;
    const [closed, setClosed] = useState(false)

    const closeFunc = useCallback(() => {
        setClosed(true);
    }, [setClosed]);

    if (closed) {
        return null;
    }
    return (
        <div className={cn.main(typeClass)}>
            <div className={cn("__content")}>
                <p>
                    {children}
                </p>
            </div>
            {
                closeButton ?
                    <Button hasIconOnly isFlat className={cn('__close')} onClick={closeFunc}>
                        <span className="gov-icon gov-icon--cross" />
                    </Button> : null
            }
        </div>
    );
}

export default React.memo(Alert);