import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";

const cn = setupCn('icon-label');

export type IconLabelType = 'none' | 'information' | 'warning' | 'error';

export type IconLabelProps = PropsWithChildren<{
    className?: Classes;
    withMargin?: boolean;
    type?: IconLabelType;
    icon?: string;
}>;

const icons: { [key in IconLabelType]: string | null } = {
    none: null,
    error: 'web-warning',
    information: 'ui-circle-info',
    warning: 'web-warning'
}

const IconLabel: FC<IconLabelProps> = ({
    className,
    withMargin,
    type,
    icon,
    children
}) => {
    type ??= 'none';
    const defaultIcon = icons[type] ?? undefined;
    icon ??= defaultIcon;
    return (
        <div className={cn.with(className).main({
            [`--${type}`]: !!type,
            '--with-margin': withMargin
        })}>
            <span className={cn.with('icon', icon)('__icon')} />
            {children}
        </div>
    );
};

IconLabel.displayName = 'IconLabel';

export default React.memo(IconLabel);
