import { AttrMapping, IData } from "@utils/AttrMapping";

export type ICinnostSubjektuModel = IData & {
    id: number;
    predmetCinnostiNazev: string;
    jinaCinnost: string;
}

export const CinnostSubjektuAttrMap = new AttrMapping<ICinnostSubjektuModel>('Cinnost_subj', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'predmetCinnostiNazev', serverName: 'Predmet_cinnosti.Nazev', type: 'string' },
    { clientName: 'jinaCinnost', serverName: 'Jina_cinnost', type: 'string' },
]);
