import { FalseValues } from "./TypeUtils";

export function flatArray<T>(arr: T[][]): T[] {
    return ([] as T[]).concat(...arr);
}

export type MaybeArray<T> = T | T[];

type DeepArrayType<T> = Array<T | DeepArrayType<T>>;
export type DeepArray<T> = T | DeepArrayType<T>;

export function flatArrayDeep<T>(arr: DeepArray<T>[]): T[] {
    return arr.reduce<T[]>((acc, curr) => acc.concat(Array.isArray(curr) ? flatArrayDeep(curr) : [curr]), []);
}

export const notNull = <TValue>(item: TValue | null | undefined): item is TValue => item != null;
export const isEmpty = (item?: unknown | null): item is null | undefined | '' => item == null || item == '';
export const notEmpty = (item?: unknown | null) => !isEmpty(item);
export const isEmptyOrWhitespace = (item?: unknown  | null) => isEmpty(item) || typeof item == 'string' && item.match(/^\s*$/);
export const notEmptyOrWhitespace = (item?: unknown  | null) => !isEmptyOrWhitespace(item);
export const truthy = <TValue>(item: TValue | FalseValues): item is TValue => !!item;

export function combineArrays<T1, T2, TRet = [T1, T2]>(arr1: T1[], arr2: T2[], action: (item1: T1, item2: T2) => TRet): TRet[] {
    return flatArray(arr1.map(item1 => arr2.map(item2 => action(item1, item2))));
}

export function toArray<T>(item: MaybeArray<T>, emptyIfNull?: boolean): T[] {
    if (item == null && emptyIfNull) {
        return [];
    }
    return Array.isArray(item) ? item : [item];
}

export function uniqueArray<T>(...arrays: T[][]): T[] {
    return [...new Set(flatArray(arrays))];
}

export function notEmptyItems<T>(array: T[]): T[] {
    return array.filter(notEmpty);
}

export const arrayInjectSeparator = <T, S>(arr: T[], separatorFactory: (index: number) => S): (T | S)[] => {
    const result: (T | S)[] = [];
    arr?.forEach((item, index) => {
        if (index != 0) {
            result.push(separatorFactory(index));
        }
        result.push(item);
    });
    return result;
}

export const toMap = <K, T>(getKey: (item: T) => K, arr: T[]): Map<K, T> => {
    return new Map(arr?.map(item => [getKey(item), item]));
};

export class ArrayHelper {
    public static splitByPredicate<T>(arr: T[], predicate: (item: T) => boolean): [T[], T[]] {
        return arr.reduce<[T[], T[]]>((acc, item) => {
            acc[predicate(item) ? 0 : 1].push(item);
            return acc;
        }, [[], []]);
    }
}

export const is = <T extends unknown>(item: T, expceted: T[]): boolean => expceted?.includes(item) ?? false;

export const transposeArray = <T extends unknown>(arr: T[], columns: number): T[] => {
    const result: T[] = [];
    const arrLength = arr.length;
    const rows = Math.ceil(arrLength / columns);
    let greaterColumns = arrLength % columns;
    if (greaterColumns == 0) {
        greaterColumns = columns
    }
    const columnsItems: T[][] = [];
    let i = 0;
    while (i < arrLength) {
        const itemsCount = rows - (greaterColumns > 0 ? 0 : 1);
        columnsItems.push(arr.slice(i, i + itemsCount));
        i += itemsCount;
        greaterColumns--;        
    }
    for (let i = 0; i < rows; i++)
    {
        columnsItems.forEach(column => {
            if (i < column.length) {
                result.push(column[i]);
            }
        })
    }
    return result;
}

export const iaddToArray = <T>(arr: T[], item: T) => arr.includes(item) ? arr : [...arr, item];
export const iremoveFromArray = <T>(arr: T[], itemToRemove: T) => arr.includes(itemToRemove) ? arr.filter(item => item != itemToRemove) : arr;
