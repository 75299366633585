import React, { FC, PropsWithChildren, useMemo } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import './Loader.scss';
import LoaderOverlay from "./LoaderOverlay";

const cn = setupCn('gov-loader');

export type LoaderProps = PropsWithChildren<{
    className?: Classes;
    isBlock?: boolean;
    isInversed?: boolean;
    withOverlay?: boolean;
}>;

const Loader: FC<LoaderProps> = ({
    className,
    isBlock,
    isInversed,
    withOverlay
}) => {
    const elements = useMemo(() => {
        const elementsCount = isBlock ? 8 : 3;
        return Array.from({ length: elementsCount }, (_, index) => <span key={index}>&nbsp;</span>);
    }, [isBlock]);

    const Wrapper = withOverlay ? LoaderOverlay : React.Fragment;

    return (
        <Wrapper>
            <div className={cn.with(className).main({
                '--block': isBlock,
                '--inversed': isInversed
            })}>
                {elements}
            </div>
        </Wrapper>
    );
};

Loader.displayName = 'Loader';

export default React.memo(Loader);
