import { AttrMapping, IData } from "@utils/AttrMapping";

export type IJinaInformaceSubjektuHist = IData & {
    poradi: number;
    popis: string;
    text: string;
}

export const JinaInformaceSubjektuHistAttrMap = new AttrMapping<IJinaInformaceSubjektuHist>('Jina_infor_subj_hist', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'poradi', serverName: 'Poradi', type: 'number' },
    { clientName: 'popis', serverName: 'Popis', type: 'string' },
    { clientName: 'text', serverName: 'Text_informace', type: 'text' },
]);
