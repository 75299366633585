/*
A function to represent a queue
Created by Stephen Morley - http://code.stephenmorley.org/ - and released under
the terms of the CC0 1.0 Universal legal code: http://creativecommons.org/publicdomain/zero/1.0/legalcode
*/

/**
 * Creates a new queue. A queue is a first-in-first-out (FIFO) data structure -
 * items are added to the end of the queue and removed from the front.
 */
export class Queue<T> {

    private _queue = new Array<T>();
    private _offset = 0;

    public [Symbol.iterator](): Iterator<T> {
        return this._iterator();
    }

    private *_iterator(): Iterator<T> {
        while (this._queue.length > 0) {
            const val = this.dequeue();
            if (val == null)
                break;
            yield val;
        }
    }

    /**
     * Returns the length of the queue.
     */
    public get length(): number {
        return this._queue.length - this._offset;
    }

    /**
     * Returns true if the queue is empty, and false otherwise.
     */
    public isEmpty(): boolean {
        return this._queue.length === 0;
    }

    /**
     * Enqueues the specified item to the end of the queue.
     * @param item the item to enqueue
     */
    public enqueue(item: T): void {
        this._queue.push(item);
    }

    /**
     * Dequeues an item from the front of the queue and returns it. If the queue is empty, the value null is returned.
     */
    public dequeue(): T | null {

        // if the queue is empty, return immediately
        if (this._queue.length === 0)
            return null;

        // store the item at the front of the queue
        const item = this._queue[this._offset];

        // increment the offset and remove the free space if necessary
        if (++this._offset * 2 >= this._queue.length) {
            this._queue = this._queue.slice(this._offset);
            this._offset = 0;
        }

        // return the dequeued item
        return item;
    }

    /**
     * Returns the item at the front of the queue (without dequeuing it). If the queue is empty then null is returned.
     */
    public peek(): T | null {
        return this._queue.length > 0 ? this._queue[this._offset] : null;
    }
}