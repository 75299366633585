import React, { FC, useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";
import { setupCn } from "@utils/BemUtils";
import ButtonBase, { ButtonBaseProps } from "./ButtonBase";

import './ButtonLink.scss';

const cn = setupCn('button-link');

export type ButtonLinkProps =
    ButtonBaseProps &
    Omit<LinkProps, 'className'> &
    {
        linkRef?: React.Ref<HTMLAnchorElement>;
        disabled?: boolean;
        isAnchor?: boolean;
        targetBlank?: boolean;
    };

const ButtonLink: FC<ButtonLinkProps> = ({ to, linkRef, isAnchor, targetBlank, className,...restProps }) => {
    const ButtonLinkElement: FC<any> = useCallback((props) => {
        return isAnchor
            ? <a href={to} target={targetBlank ? '_blank' : ''} ref={linkRef} {...props} />
            : <Link to={to} ref={linkRef} {...props} />
    }, [to, linkRef, isAnchor, targetBlank]);

    className = isAnchor ? cn.with(className).main('--anchor') : className;

    return (
        <ButtonBase
            element={ButtonLinkElement}
            className={className}
            {...restProps} />
    )
}

export default React.memo(ButtonLink);
