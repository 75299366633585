import { AttrMapping, IData } from "@utils/AttrMapping";

export type IEvidUverVVZModel = IData & {
    datumPotvrzeni: Date;
    datumUverejneni: Date;
    druhForm: string;
    evidCisloForm: string;
    histId: number;
    kodForm: string;
    odkaz: string;
    profId: number;
    typForm: string;
  
}



export const EvidUverVVZAttrMap = new AttrMapping<IEvidUverVVZModel>('VW_Evidence_VVZ', [
    { clientName: 'datumPotvrzeni', serverName: 'Datum_potvrzeni', type: 'dateTime' },
    { clientName: 'datumUverejneni', serverName: 'Datum_uver', type: 'dateTime' },
    { clientName: 'druhForm', serverName: 'Typ_form.Nazev', type: 'string' },
    { clientName: 'evidCisloForm', serverName: 'Evid_c_Form', type: 'string' },
    { clientName: 'histId', serverName: 'Info_uver.Historie', type: 'id' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'kodForm', serverName: 'Typ_form.Kod', type: 'string' },
    { clientName: 'odkaz', serverName: 'Odkaz', type: 'string' },
    { clientName: 'profId', serverName: 'Info_uver.Historie', type: 'id' },
    { clientName: 'typForm', serverName: 'Typ_formulare', type: 'string' },
]);
