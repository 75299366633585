import React, { useEffect, useRef } from "react"
import ResizableIframe from "@components/ResizableIframe/ResizableIframe";
import Loader from "@components/Loader";
import { useLoadState } from "@utils/StateUtils";
import { useScrollToTop } from "@utils/ReactUtils";
import RedirectToDetail from "@components/Widgets/RedirectToDetail";
import { combineRefs } from "@utils/CombineRefs";

const LargeWidget = React.forwardRef<HTMLIFrameElement, {
    src: string,
    withoutLoader?: boolean,
    baseDetailUrl?: string;
    detailUrl?: string;
    label?: string;
}>(({ src, withoutLoader, baseDetailUrl, detailUrl, label }, iframeRef) => {
    const innerRef = useRef<HTMLIFrameElement>(null);
    const { isLoaded, onLoad, setLoaded } = useLoadState();
    useEffect(() => { setLoaded(false); }, [src, setLoaded]);
    useScrollToTop();

    if (!src)
        return null;

    return (
        <>
            <RedirectToDetail
                baseDetailUrl={baseDetailUrl}
                detailUrl={detailUrl}
                iframeRef={innerRef} />
            {isLoaded || withoutLoader ? null : <Loader />}
            <ResizableIframe
                ref={combineRefs(innerRef, iframeRef)}
                label={label}
                visibility={isLoaded ? 'visible' : 'hidden'}
                onLoad={onLoad}
                src={src} />
        </>
    );
});

LargeWidget.displayName = 'LargeWidget';

export default LargeWidget;