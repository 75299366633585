import { urlConstants, useConstants } from "@constants/urlConstants";
import { useWidgetItems } from "@store/WidgetStore/widgetStore";
import { TrainingItem } from "@store/WidgetStore/trainingWidgetStore";
import React from "react";
import FlexBox from "@components/FlexBox/FlexBox";
import { useLocalizationText } from "@store/localizationStore";
import UniversalLink from "@components/UniversalLink/UniversalLink";

import './Training.scss'
import { setupCn } from "@utils/BemUtils";
import ButtonLink from "@components/Button/ButtonLink";

const cn = setupCn('trainings');

export type LinkTo = keyof Pick<typeof urlConstants, "skoleniVse" | "skoleniZzvz">
export type TrainingType = "Zzvz" | "Nen"

const TrainingWidget: React.FC<{ trainingsType: TrainingType, linkTo: LinkTo }> = ({ trainingsType, linkTo }): JSX.Element => {
    const isZzvzTraining = trainingsType == 'Zzvz';
    const { items } = useWidgetItems<TrainingItem>('trainingStore', isZzvzTraining ? "zzvz" : "dodavatel,zadavatel");
    const { getUrl } = useConstants();

    const t = useLocalizationText();

    const { path, label } = isZzvzTraining
        ? { path: getUrl('skoleniZzvzVse'), label: t("NEN-793788") }
        : { path: getUrl('skoleniVse'), label: t("NEN-786554") }

    return (
        <FlexBox direction='column' align='start' className={cn()}>
            {
                items.length > 0 ?
                    <>
                        {
                            items
                                .map(item =>
                                    <div className={cn("__training")} key={item.id}>
                                        <UniversalLink
                                            isExternal
                                            to={item.url}>
                                            {item.short_description}
                                        </UniversalLink>
                                        <p className={cn("__content")} >
                                            {item.u_training_date}
                                            <span className="bold-dot"> &#183; </span>
                                            {trimSeconds(item.u_training_time_from)} - {trimSeconds(item.u_training_time_to)}
                                            <span className="bold-dot"> &#183; </span>
                                            {item.u_training_address}
                                        </p>
                                    </div>
                                )
                        }
                        <ButtonLink
                            to={path}
                            isOutlined={true}
                            aria-label={label}>
                            {t("NEN-645393")}
                        </ButtonLink>
                    </>
                    : <p className={`gov-note ${cn("__empty-message")}`}>{t("NEN-818359")}</p>
            }
        </FlexBox>
    )
};

export const trimSeconds = (time: string): string =>
    time?.substring(0, time?.lastIndexOf(":")) ?? "00:00"

TrainingWidget.displayName = 'TrainingWidget';

export default TrainingWidget;