/**
* Stavy podpisu
*/
export enum DigitalSignatureState {
    notUsed = 0,
    empty = 1,
    valid = 2,
    invalid = 3,
    encrypted = 4,
    unknown = 5,
    allowUnknown = 6,
    indeterminate = 7
}

export interface ISignatureTemplate {
    className: string;
    identifier: string;
    attributes: string[];
    associatedCollections: Array<ISignatureTemplateAssociatedCollection>;
}

export interface ISignatureTemplateAssociatedCollection extends ISignatureTemplate {
    condition: string;
    conditionParams: string;
}

export enum SignatureInfoType {
    empty,
    digitalSignature,
    digitalMark,
    timeStamp,
    unknown
}

export enum CertificateUsageType {
    crlSign,
    dataEncipherment,
    decipherOnly,
    digitalSignature,
    encipherOnly,
    keyAgreement,
    keyCertSign,
    keyEncipherment,
    nonRepudiation
}

/**
 * Informace o certifikátu
 */
export interface ICertificate {
    subject: IDistinguishedName;
    validFrom: Date;
    validTo: Date;
    issuer: IDistinguishedName;
    version: number;
    hasPrivateKey: boolean;
    serialNumber: string;
    thumbprint: string;
    certificateKeyUsage: Array<CertificateUsageType>;
    signatureAlgorithm: string;
    publicKeyAlgorithm: string;
    publicKeySize: number;
    hashAlgorithm: string;
}

/**
 * Asociovaná kolekce informací získaných z položky certifikátu
 */
export interface IDistinguishedName {
    [key: string]: string[]
}

/**
 * Informace o podpisu
 */
export interface ISignatureInfo {
    valid: boolean | null;
    validationMessage?: string;
    certificate: ICertificate;
    time: Date;
    signatureInsideDocument?: boolean;
    type: SignatureInfoType;
    counterSignature?: Array<ISignatureInfo>;
    counterSignatureCount?: number;
    signedData?: string;
    cautionTime?: string;
    validationDetails?: string;
}