import Layout from '@components/Layout/Layout';
import Home from '@pages/home/Home';
import { useLocalizationText, UseLocalizationTextType } from '@store/localizationStore';
import DetailVerejneZakazky from '@pages/detailVerejneZakazky/DetailVerejneZakazky';
import { SwitchWith404 } from '@components/Routing/SwitchWith404';
import { urlConstants } from '@constants/urlConstants';
import VerejneZakazkyPages from '@pages/verejneZakazky/verejneZakazkyPages/VerejneZakazkyPages';
import { BreadcrumbRoute } from '@components/Breadcrumb/BreadcrumbRoute';
import CompatibilityTest from '@components/CompatibilityTest/CompatibilityTest';
import RozcestnikRegistrace from '@pages/registrace/rozcestnikRegistrace';
import ProhlaseniOPristupnosti from '@pages/prohlaseniOPristupnosti/prohlaseniOPristupnosti'
import ProfilyZadavateluPages from '@pages/profilyZadavatelu/profilyZadavateluPages/ProfilyZadavateluPages';
import DetailProfilZadavatele from '@pages/detailProfilZadavatele/DetailProfilZadavatele';
import DetailDetailUver from '@pages/detailDetailUver/DetailDetailUver';
import DetailInfoUver from '@pages/detailInfoUver/DetailInfoUver';
import DetailDodInfo from '@pages/detailDodInfo/DetailDodInfo';
import DetailDNSystem from '@pages/detailDNSystem/DetailDNSystem';
import RegistrySubjektuPages from '@pages/registry/registrySubjektuPages/RegistrySubjektuPages';
import DetailInformace from '@pages/detailInformace/DetailInformace';
import Prihlaseni from '@pages/prihlaseni/Prihlaseni';
import Informace from '@pages/informace/Informace';
import DetailSystemKvalifikace from '@pages/detailSystemKvalifikace/DetailSystemKvalifikace';
import DetailSubjektu from '@pages/registry/registrySubjektu/detailRegistru/DetailSubjektu';
import Design from '@pages/design/Design';
import { UseBreadcrumbTitle, useBreadcrumbTitle } from '@store/breadcrumbTitleStore';
import { Route, Switch } from 'react-router';
import LoginForm from '@components/LoginForm';
import DetailPolozkaPredm from '@pages/detailDetailPolozkaPredm/DetailDetailPolozkaPredm';
import verzeProOdstavku from '@pages/verzeProOdstavku/verzeProOdstavku'
import { useAppSettings, useHeartBeat } from '@store/nodeContextStore';
import Skoleni from '@pages/skoleni/Skoleni';
import Videa from '@pages/videa/Videa';
import ProvozniRad from '@pages/provozniRad/ProvozniRad';
import OsobniUdajeACookies from '@pages/osobniUdajeACookies/OsobniUdajeACookies';
import RegistraceZadavatel from '@pages/registrace/registraceZadavatel';
import RegistraceDodavatel from '@pages/registrace/registraceDodavatel';
import RegistraceSdruzeni from '@pages/registrace/registraceSdruzeni';
import DetailUverDokum from '@pages/detailUverDokum/DetailUverDokum';
import ObnoveniUctu from '@pages/obnoveniUctu/ObnoveniUctu';
import ZmenaHesla from '@pages/zmenaHesla/ZmenaHesla';
import SkoleniZzvz from '@pages/SkoleniZzvz/SkoleniZzvz';
import { Page413 } from '@pages/error/Page413';
import DetaillUdajePod from './pages/detailUdajePod/DetailUdajePod';
import DetailExtPodpis from './pages/detailExtPodpis/DetailExtPodpis';

export default () => {
    const t = useLocalizationText();
    const getTitle = useBreadcrumbTitle();
    const { getOutageVersion } = useAppSettings();
    const isOutageVersion = getOutageVersion().enabled;
    useHeartBeat();

    return (
        <Switch>
            <Route path={`*${urlConstants.loginFrame.path}`} component={LoginForm} />
            <Route path='/:language(en)?/*' render={() =>
                <Layout>
                    <SwitchWith404>
                        <BreadcrumbRoute exact path={urlConstants.home.path} brComponent={Home} hideBreadcrumbHome />
                        <BreadcrumbRoute exact path={urlConstants.skoleniZzvz.path} brComponent={Home} hideBreadcrumbHome />
                        <BreadcrumbRoute exact path={urlConstants.videa.path} brComponent={Home} hideBreadcrumbHome />
                        <BreadcrumbRoute exact path={urlConstants.prirucky.path} brComponent={Home} hideBreadcrumbHome />
                        <BreadcrumbRoute title={t("NEN-767365")} path={urlConstants.provozniRad.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={ProvozniRad} />
                                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailInformace.path} brComponent={DetailInformace} />
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-767461")} path={urlConstants.skoleniVse.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={Skoleni} />
                                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailInformace.path} brComponent={DetailInformace} />
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-793789")} path={urlConstants.skoleniZzvzVse.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={SkoleniZzvz} />
                                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailInformace.path} brComponent={DetailInformace} />
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-767964")} path={urlConstants.videaVse.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={Videa} />
                                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailInformace.path} brComponent={DetailInformace} />
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-767975")} path={urlConstants.provozniInformace.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={Informace} />
                                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailInformace.path} brComponent={DetailInformace} />
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-700614")} path={urlConstants.verejneZakazky.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={VerejneZakazkyPages} />
                                {VerejnaZakazkaDetailRoute(t, getTitle, props.match.url)}
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-700614")} path={urlConstants.registrDns.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={VerejneZakazkyPages} />
                                {DNSDetailRoute(t, getTitle, props.match.url)}
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-700614")} path={urlConstants.vestnik.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={VerejneZakazkyPages} />
                                {InfoUverDetailRoute(t, getTitle, props.match.url)}
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-700614")} path={urlConstants.registrSK.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={VerejneZakazkyPages} />
                                <BreadcrumbRoute title={getTitle.getSkTitle("NEN-812878")} path={urlConstants.registrSKDetail.pathWithPages} render={(props) =>
                                    <SwitchWith404>
                                        <BreadcrumbRoute exact path={urlConstants.registrSKDetail.pathWithPages} brComponent={DetailSystemKvalifikace} />
                                        {SubjektDetailRoute(t, getTitle, props.match.url)}
                                        {UverDokumDetailRoute(t, props.match.url)}
                                    </SwitchWith404>
                                } />
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-645406")} path={urlConstants.profilyZadavateluPlatne.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={ProfilyZadavateluPages} />
                                {ProfilZadavateleDetailRoute(t, getTitle, props.match.url)}
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-645406")} path={urlConstants.profilyZadavateluZneplatnene.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={ProfilyZadavateluPages} />
                                {ProfilZadavateleDetailRoute(t, getTitle, props.match.url)}
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-738419")} path={urlConstants.registrZadavatelu.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={RegistrySubjektuPages} />
                                {SubjektDetailRoute(t, getTitle, props.match.url)}
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute title={t("NEN-738419")} path={urlConstants.registrDodavatelu.path} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={props.match.path} brComponent={RegistrySubjektuPages} />
                                {SubjektDetailRoute(t, getTitle, props.match.url)}
                            </SwitchWith404>
                        } />
                        <BreadcrumbRoute path={urlConstants.rozcestnikRegistrace.path} title={t("NEN-767682")} render={(props) =>
                            <SwitchWith404>
                                <BreadcrumbRoute exact path={urlConstants.registraceZadavatel.path} brComponent={RegistraceZadavatel} title={t("NEN-768724")} />
                                <BreadcrumbRoute exact path={urlConstants.registraceDodavatel.path} brComponent={RegistraceDodavatel} title={t("NEN-768725")} />
                                <BreadcrumbRoute exact path={urlConstants.registraceSpolecneZadavani.path} brComponent={RegistraceSdruzeni} title={t("NEN-768726")} />
                                <BreadcrumbRoute exact path={props.match.path} brComponent={RozcestnikRegistrace} />
                            </SwitchWith404>} />
                        <BreadcrumbRoute exact title={t("NEN-744259")} path={urlConstants.compatibilityTest.path} brComponent={CompatibilityTest} />
                        <BreadcrumbRoute exact title={t("NEN-754428")} path={urlConstants.prohlaseniOPristupnosti.path} brComponent={ProhlaseniOPristupnosti} />
                        <BreadcrumbRoute exact title={t("NEN-749252")} path={urlConstants.prihlaseni.path} brComponent={isOutageVersion ? verzeProOdstavku : Prihlaseni} />
                        <BreadcrumbRoute exact title={t("NEN-764292")} path={urlConstants.design.path} brComponent={Design} />
                        <BreadcrumbRoute exact title={t("NEN-770159")} path={urlConstants.osobniUdajeACookies.path} brComponent={OsobniUdajeACookies} />
                        <BreadcrumbRoute exact title={t("NEN-665597")} path={urlConstants.obnoveniUctu.path} brComponent={ObnoveniUctu} />
                        <BreadcrumbRoute exact title={t("NEN-645284")} path={urlConstants.zmenaHesla.path} brComponent={ZmenaHesla} />
                        <BreadcrumbRoute title='413' exact path={urlConstants.page413.path} brComponent={Page413} />
                    </SwitchWith404>
                </Layout>
            } />
        </Switch>
    );
}

const InfoUverDetailRoute = (t: UseLocalizationTextType, getTitle: UseBreadcrumbTitle, location: string, key?: string) =>
    location.includes(urlConstants.detailInfoUver.url) ? null :
        <BreadcrumbRoute key={key} title={t("NEN-747236")} path={urlConstants.detailInfoUver.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute exact path={urlConstants.detailInfoUver.pathWithPages} brComponent={DetailInfoUver} />
                {SubjektDetailRoute(t, getTitle, props.match.url)}
                {VerejnaZakazkaDetailRoute(t, getTitle, props.match.url)}
                {UverDokumDetailRoute(t, props.match.url)}
                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailDetailUver.pathWithPages} brComponent={DetailDetailUver} />
            </SwitchWith404>
        } />

const VerejnaZakazkaDetailRoute = (t: UseLocalizationTextType, getTitle: UseBreadcrumbTitle, location: string) =>
    location.includes(urlConstants.verejneZakazkyDetail.url) ? null :
        <BreadcrumbRoute title={getTitle.getVZTitle("NEN-740359")} path={urlConstants.verejneZakazkyDetail.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute title={getTitle.getVZTitle("NEN-747865")} path={urlConstants.verejneZakazkyCastiDetail.pathWithPages} render={(props) =>
                    <SwitchWith404>
                        <BreadcrumbRoute exact path={urlConstants.verejneZakazkyCastiDetail.pathWithPages} brComponent={DetailVerejneZakazky} />
                        {VerejnaZakazkaDetailSubRoute(t, getTitle, props.match.url)}
                    </SwitchWith404>
                } />
                <BreadcrumbRoute exact path={urlConstants.verejneZakazkyDetail.pathWithPages} brComponent={DetailVerejneZakazky} />
                {VerejnaZakazkaDetailSubRoute(t, getTitle, props.match.url)}
            </SwitchWith404>
        } />

const VerejnaZakazkaDetailSubRoute = (t: UseLocalizationTextType, getTitle: UseBreadcrumbTitle, location: string) =>
    [
        SubjektDetailRoute(t, getTitle, location, "subjektRoute"),
        DNSDetailRoute(t, getTitle, location, "dnsRoute"),
        InfoUverDetailRoute(t, getTitle, location, "infoUverDetailRoute"),
        VysvetleniZDDetailRoute(t, location, "vysvZdRoute"),
        UverDokumDetailRoute(t, location, "uverDokumRoute"),
        <BreadcrumbRoute key="detailUdajePod" title={t("NEN-807152")} path={urlConstants.detailUdajePod.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute exact path={urlConstants.detailUdajePod.pathWithPages} brComponent={DetaillUdajePod} />
                {UverDokumDetailRoute(t, props.match.url)}
                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailDetailUver.pathWithPages} brComponent={DetailDetailUver} />
            </SwitchWith404>
        } />,
        <BreadcrumbRoute key="detailDetailUver" title={t("NEN-747575")} path={urlConstants.detailDetailUver.pathWithPages} brComponent={DetailDetailUver} />,
        <BreadcrumbRoute key="detailDetailPolozkaPredm" title={t("NEN-768487")} path={urlConstants.detailDetailPolozkaPredm.pathWithPages} brComponent={DetailPolozkaPredm} />
    ]

const ProfilZadavateleDetailRoute = (t: UseLocalizationTextType, getTitle: UseBreadcrumbTitle, location: string) =>
    location.includes(urlConstants.profilZadavateleDetail.url) ? null :
        <BreadcrumbRoute title={getTitle.getProfilTitle("NEN-744018")} path={urlConstants.profilZadavateleDetail.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute exact path={urlConstants.profilZadavateleDetail.pathWithPages} brComponent={DetailProfilZadavatele} />
                {VerejnaZakazkaDetailRoute(t, getTitle, props.match.url)}
                {DNSDetailRoute(t, getTitle, props.match.url)}
                {UverDokumDetailRoute(t, props.match.url)}
            </SwitchWith404>
        } />

const SubjektDetailRoute = (t: UseLocalizationTextType, getTitle: UseBreadcrumbTitle, location: string, key?: string) =>
    location.includes(urlConstants.subjektDetail.url) ? null :
        <BreadcrumbRoute key={key} title={getTitle.getSubjektTitle("NEN-605848")} path={urlConstants.subjektDetail.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute exact path={urlConstants.subjektDetail.pathWithPages} brComponent={DetailSubjektu} />
                {ProfilZadavateleDetailRoute(t, getTitle, props.match.url)}
            </SwitchWith404>
        } />

const DNSDetailRoute = (t: UseLocalizationTextType, getTitle: UseBreadcrumbTitle, location: string, key?: string) =>
    location.includes(urlConstants.registrDnsDetail.url) ? null :
        <BreadcrumbRoute key={key} title={getTitle.getDnsTitle("NEN-812702")} path={urlConstants.registrDnsDetail.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute exact path={urlConstants.registrDnsDetail.pathWithPages} brComponent={DetailDNSystem} />
                {SubjektDetailRoute(t, getTitle, props.match.url)}
                {VerejnaZakazkaDetailRoute(t, getTitle, props.match.url)}
                {VysvetleniZDDetailRoute(t, props.match.url)}
                {UverDokumDetailRoute(t, props.match.url)}
            </SwitchWith404>
        } />

const UverDokumDetailRoute = (t: UseLocalizationTextType, location: string, key?: string) =>
    location.includes(urlConstants.detailUverDokum.url) ? null :
        <BreadcrumbRoute key={key} title={t("NEN-812592")} path={urlConstants.detailUverDokum.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute exact path={urlConstants.detailUverDokum.pathWithPages} brComponent={DetailUverDokum} />
                <BreadcrumbRoute exact title={t("NEN-810857")} path={urlConstants.detailExtPodpis.pathWithPages} brComponent={DetailExtPodpis} />
            </SwitchWith404>
        } />

const VysvetleniZDDetailRoute = (t: UseLocalizationTextType, location: string, key?: string) =>
    location.includes(urlConstants.detailDodInfo.url) ? null :
        <BreadcrumbRoute key={key} title={t("NEN-812843")} path={urlConstants.detailDodInfo.pathWithPages} render={(props) =>
            <SwitchWith404>
                <BreadcrumbRoute title={t("NEN-747575")} path={urlConstants.detailDetailUver.pathWithPages} brComponent={DetailDetailUver} />
                <BreadcrumbRoute title={t("NEN-812592")} path={urlConstants.detailUverDokum.pathWithPages} brComponent={DetailUverDokum} />
                <BreadcrumbRoute title={t("NEN-812592")} path={urlConstants.detailExtPodpis.pathWithPages} brComponent={DetailExtPodpis} />
                <BreadcrumbRoute exact path={urlConstants.detailDodInfo.pathWithPages} brComponent={DetailDodInfo} />
            </SwitchWith404>
        } />