import { isEmpty, notEmpty } from "./ArrayHelper";

export const rtrimText = (text: string, trimText: string) => {
    while (text.endsWith(trimText)) {
        text = text.slice(0, text.length - trimText.length);
    }
    return text;
}

export const splitParam = (text?: string, separator?: string) => {
    if (text == null) {
        return [];
    }
    const separatorIndex = separator == null ? -1 : text.indexOf(separator);
    if (separatorIndex < 0) {
        return ['', text] as const;
    }
    return [text.slice(0, separatorIndex), text.slice(separatorIndex + (separator?.length ?? 0))] as const;
}

export const joinNonEmpty = (separator: string, ...segments: (string | null | undefined)[]) => segments.filter(notEmpty).join(separator);

export const prefixNonEmpty = (prefix: string, text: string) => isEmpty(text) ? '' : `${prefix}${text}`;
