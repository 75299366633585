import React from "react";
import { PropsWithChildren } from "react";
import ContentBlock from "@components/ContentBlock";
import Grid from "@components/Grid";
import { useDetailFrameContext } from "../DetailFrame";

const GroupBox: React.FC<PropsWithChildren<{
    lockey: string,
    fillContent?: boolean,
    showIf?: string | boolean
}>> = ({ lockey, fillContent, showIf, children }) => {
    const { evaluateCondition } = useDetailFrameContext();

    if (showIf === false)
        return null;

    if (typeof showIf === 'string' && evaluateCondition(showIf) === false)
        return null;

    return (
        <ContentBlock lockey={lockey}>
            <Grid isPlain columns={fillContent ? 1 : 3}>
                {children}
            </Grid>
        </ContentBlock>
    );
}

export default React.memo(GroupBox);