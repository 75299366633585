import React, { FC, useCallback, useMemo } from "react"
import { setupCn } from "@utils/BemUtils";
import WhispererInput, { WhispererInputClasses, WhispererInputProps, WhispererItem, WhispererItemHandler, WhispererItemSelectedHandler } from "@components/WhispererInput";
import { FormControlContext, FormControlContextType } from "@components/FormControl";
import MultiselectTags from "./MultiselectTags";
import "./Multiselect.scss";
import { renderIf } from "@utils/RenderUtils";

const cn = setupCn('gov-multiselect');

export type MultiselectProps = Omit<WhispererInputProps, 'classes'> & {
    onSelectedItemsChanged?: (selectedItems: WhispererItem[]) => void;
    selectedItems?: WhispererItem[];
};

export const multiselectClasses: WhispererInputClasses = {
    componentClass: cn(),
    whispererClass: cn('__options'),
    itemClass: cn('__option')
}

const Multiselect: FC<MultiselectProps> = ({
    onItemSelected,
    onSelectedItemsChanged,
    selectedItems,
    items,
    ...restProps
}) => {
    const { name, onChange } = restProps;

    const handleItemRemove = useCallback<WhispererItemHandler>(itemToRemove => {
        onSelectedItemsChanged?.(selectedItems?.filter(item => item.id != itemToRemove.id) ?? []);
    }, [onSelectedItemsChanged, selectedItems]);

    const selectedIds = useMemo(() => new Set(selectedItems?.map(item => item.id)),
        [selectedItems]);

    const handleItemSelected = useCallback<WhispererItemSelectedHandler>(itemToAdd => {
        if (itemToAdd && !selectedIds.has(itemToAdd.id)) {
            onSelectedItemsChanged?.([...selectedItems ?? [], itemToAdd]);
        }
        onItemSelected?.(itemToAdd);
        onChange?.(name, '');
    }, [onItemSelected, onSelectedItemsChanged, name, onChange, selectedItems, selectedIds]);

    const selectedItemsNode = useMemo(() => renderIf(selectedItems?.length,
        <MultiselectTags
            onRemove={handleItemRemove}
            items={selectedItems} />
    ), [handleItemRemove, selectedItems]);

    const formControlContext: FormControlContextType = useMemo(() => ({
        afterContainer: selectedItemsNode
    }), [selectedItemsNode]);

    const notSelectedItems = useMemo(() => items?.filter(item => !selectedIds.has(item.id)),
        [items, selectedIds]);

    return (
        <FormControlContext.Provider value={formControlContext}>
            <WhispererInput
                {...restProps}
                items={notSelectedItems}
                onItemSelected={handleItemSelected}
                classes={multiselectClasses} />
        </FormControlContext.Provider>
    );
};

Multiselect.displayName = 'Multiselect';

export default React.memo(Multiselect);
