import React, { FC, ReactNode } from "react";
import { useLocalization } from '@store/localizationStore';
import { setupCn } from "@utils/BemUtils";
import UniversalLink from "../UniversalLink/UniversalLink";

import './GridTile.scss';

const cn = setupCn("gov-grid-tile");
const cnPrev = setupCn("gov-preview-tile");

export type GridTileProps = {
    locKey: string;
    icon: ReactNode;
    to: string;
    linkText: string;
    isExternal?: boolean;
}

const GridTile: FC<GridTileProps> = ({
    locKey,
    icon,
    to,
    linkText,
    isExternal
}) => {
    const { ts } = useLocalization();
    return (
        <UniversalLink className={cn.main({ '--hide-external': isExternal })} to={to} isExternal={isExternal}>
            <div className={cnPrev()}>
                <div className={cnPrev("__icon")}>
                    {icon}
                </div>
                <h3 className={cnPrev("__headline")}>
                    {ts(locKey)}
                </h3>
                <p>{ts(linkText)}</p>
            </div>
        </UniversalLink>
    );
}

export default React.memo(GridTile);