import { getDetailIdLink, ListFrameDetailLinkFactory } from "@components/ListFrame";
import { urlConstants } from "@constants/urlConstants";
import { IDetailUverejneniModel } from "@models/IDetailUverejneniModel";
import { IDNSystemiModel } from "@models/IDNSystemModel";
import { IInfoUverejneniModel } from "@models/IInfoUVerejneniModel";
import { IPolozkaPredmetuHistModel } from "@models/IPolozkaPredmetuHistModel";
import { IProfilZadavateleModel } from "@models/IProfilZadavateleModel";
import { ISubjektModel } from "@models/ISubjektModel";
import { ISystemKvalifikaceModel } from "@models/ISystemKvalifikaceModel";
import { IUverejnenyDokumModel } from "@models/IUverejnenyDokumModel";
import { IZpModel } from "@models/IZpModelHist";
import { UrlParamObject } from "@utils/QueryHook";
import { match } from "react-router";

const getZPUrl = (kod: string | null, isCast?: boolean) => `${isCast ? urlConstants.verejneZakazkyCastiDetail.url : urlConstants.verejneZakazkyDetail.url}/${kod?.replace(/\//g, '-')}`

const getZpDetailUrl = (zpKod: string | null, match: match<UrlParamObject>, excludeLoop?: boolean, isCast?: boolean) => {
    const isFromVZ = excludeLoop && ((match?.url.includes(urlConstants.verejneZakazkyDetail.url)
        || match?.url.includes(urlConstants.verejneZakazkyCastiDetail.url)) ?? false);
    return `${isFromVZ ? urlConstants.verejneZakazky.url(match) : match?.url}/${getZPUrl(zpKod, isCast)}`;
};

const getDnsDetailUrl = (dnsId: number | null, match: match<UrlParamObject>, excludeLoop?: boolean) => {
    const isFromDNS = excludeLoop && (match?.url.includes(urlConstants.registrDnsDetail.url) ?? false);
    return `${(isFromDNS ? urlConstants.registrDns.url(match) : match?.url)}/${urlConstants.registrDnsDetail.url}/${dnsId}`;
};

const getSubjektDetailUrl = (subjId: number | null, match: match<UrlParamObject>, excludeLoop?: boolean) => {
    const isFromSubjekt = excludeLoop && (match?.url.includes(urlConstants.subjektDetail.url) ?? false);
    return `${(isFromSubjekt ? urlConstants.registrZadavatelu.url(match) : match?.url)}/${urlConstants.subjektDetail.url}/${subjId}`;
};

const getProfilDetailUrl = (profId: number | string | null, match: match<UrlParamObject>, excludeLoop?: boolean, replaceProfil?: boolean) => {
    const isFromProfil = excludeLoop && (match?.url.includes(urlConstants.profilZadavateleDetail.url) ?? false);
    return !replaceProfil
        ? `${(isFromProfil ? urlConstants.profilyZadavateluPlatne.url(match) : match?.url)}/${urlConstants.profilZadavateleDetail.url}/${profId}`
        : `${match?.url.substring(0, match?.url.lastIndexOf(urlConstants.profilZadavateleDetail.url) + urlConstants.profilZadavateleDetail.url.length)}/${profId}`
};

const getLfZpDetailLinkFactory = (excludeLoop?: boolean, isCast?: boolean): ListFrameDetailLinkFactory<IZpModel> =>
    ({ data, match, t }) => {
        return {
            url: getZpDetailUrl(data?.kod, match, excludeLoop, isCast),
            title: `${t('NEN-748840')} ${data.nazev}`
        };
    };

const getDnsDetailLinkFactory = (excludeLoop?: boolean): ListFrameDetailLinkFactory<IDNSystemiModel> =>
    ({ data, match, t }) => {
        return {
            url: getDnsDetailUrl(data?.id, match, excludeLoop),
            title: `${t('NEN-748840')} ${data.nazev}`
        };
    };

const getDetailInfoUverejneniLinkFactory = (excludeLoop?: boolean): ListFrameDetailLinkFactory<IInfoUverejneniModel> => 
    ({ data, match, t }) => {
        const isFromInfo = excludeLoop && (match?.url.includes(urlConstants.detailInfoUver.url) ?? false);
        return {
            url: `${(isFromInfo ? urlConstants.vestnik.url(match) : match?.url)}/${urlConstants.detailInfoUver.url}/${data.id}`,
            title: `${t('NEN-748840')} ${data.nazev} ${data.text}`
        };
    };

const getSkDetailLinkFactory = () =>
    getDetailIdLink<ISystemKvalifikaceModel>(urlConstants.registrSKDetail.url, (data, t) => `${t('NEN-748840')} ${data.skNazev}`);

const getDetailUverejneniLinkFactory = () =>
    getDetailIdLink<IDetailUverejneniModel>(urlConstants.detailDetailUver.url, (data, t) => `${t('NEN-748840')} ${data.textVysvetleni || data.uredniNazev}`);

const getUverDokumLinkFactory = () =>
    getDetailIdLink<IUverejnenyDokumModel>(urlConstants.detailUverDokum.url, (data, t) => `${t('NEN-748840')} ${data.nazev}`);

const getDetailExtPodpisLinkFactory = () =>
    getDetailIdLink<IUverejnenyDokumModel>(urlConstants.detailExtPodpis.url, (data, t) => `${t('NEN-748840')} ${data.nazev}`);

const getDetailPolozkaPredmetuLinkFactory = () =>
    getDetailIdLink<IPolozkaPredmetuHistModel>(urlConstants.detailDetailPolozkaPredm.url, (data, t) => `${t('NEN-748840')} ${data.nazevPolozky}`);

const getDetailDodInfoLinkFactory = () =>
    getDetailIdLink<IInfoUverejneniModel>(urlConstants.detailDodInfo.url, (data, t) => `${t('NEN-748840')} ${data.predmet}`);

const getDetailUdajePodaniLinkFactory = () =>
    getDetailIdLink<IInfoUverejneniModel>(urlConstants.detailUdajePod.url, (data, t) => `${t('NEN-748840')} ${data.predmet}`);

const getDetailSubjektLinkFactory = () =>
    getDetailIdLink<ISubjektModel>(urlConstants.subjektDetail.url, (data, t) => `${t('NEN-748840')} ${data.nazevOrganizace}`);

const getDetailDodInfoSonLinkFactory: ListFrameDetailLinkFactory<IDetailUverejneniModel> = ({ data, match, t }) => {
    return {
        url: `${match?.url}/${urlConstants.detailDodInfo.url}/${data.idInfoUver}`,
        title: `${t('NEN-748840')} ${data.predmetInfo}`
    };
};

const getProfilZadavateleDetailLinkFactory = (excludeLoop?: boolean, replaceProfil?: boolean): ListFrameDetailLinkFactory<IProfilZadavateleModel> => {
    const factory: ListFrameDetailLinkFactory<IProfilZadavateleModel> = ({ data, match, t }) => {
        return {
            url: getProfilDetailUrl(data.adresaProfilu?.split('/').pop() ?? '', match, excludeLoop, replaceProfil),
            title: `${t('NEN-748840')} ${data.nazevOrganizace}`
        };
    }
    factory.additionalAttriubtes = ['adresaProfilu'];
    return factory;
};

export const detailLinks = {
    getZpDetailUrl,
    getDnsDetailUrl,
    getSubjektDetailUrl,
    getProfilDetailUrl,
    getLfZpDetailLinkFactory,
    getDnsDetailLinkFactory,
    getDetailInfoUverejneniLinkFactory,
    getSkDetailLinkFactory,
    getDetailUverejneniLinkFactory,
    getUverDokumLinkFactory,
    getDetailPolozkaPredmetuLinkFactory,
    getDetailDodInfoLinkFactory,
    getDetailUdajePodaniLinkFactory,
    getDetailDodInfoSonLinkFactory,
    getProfilZadavateleDetailLinkFactory,
    getDetailSubjektLinkFactory,
    getDetailExtPodpisLinkFactory
};