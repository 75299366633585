import React, { FC } from "react"
import Container, { ContainerProps } from "./Container";

const Footer: React.FC = ({ ...props }) => <footer tabIndex={-1} {...props} />

const FooterContainer: FC<ContainerProps> = (props) => {
    return (
        <Container
            element={Footer}
            {...props} />
    );
};

export default React.memo(FooterContainer);