import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, ApplicationState, ApplicationStateSelector } from '.';
import { setCookie } from '@utils/CookiesUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { NodeContextHttp } from './nodeContextStore';
import { disableApplicationInsightsCookies, enableApplicationInsightsCookies } from '@utils/ApplicationInsights';

export const name = 'userCookieSettingsStore';

// Declare an interface of the store's state.
export type UserCookieSettingsState = {
    showModal: boolean;
    settings: UserCookieSettings;
};

type UserCookieSettings = {
    userSet: boolean;
    statistics: boolean;
    preferences: boolean;
}

export const defaultState = {
    showModal: false,
    settings: {
        userSet: false,
        preferences: false,
        statistics: false,
    }
} as UserCookieSettingsState;

// Create the slice.
export const slice = createSlice({
    name,
    initialState: defaultState,
    reducers: {
        setUserCookieSettings: (state, action: PayloadAction<UserCookieSettingsState>) => {
            return {
                ...state,
                ...action.payload
            };
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getUserCookieSettings = (state: ApplicationState) => state[name];

// Define action creators.
export const actionCreators = {
    setUserCookieSettings: (settings: UserCookieSettingsState) =>
        (dispatch: AppDispatch) => {
            dispatch(slice.actions.setUserCookieSettings(settings));
        },
    saveUserCookieSettngs: () =>
        (dispatch: AppDispatch, getState: ApplicationStateSelector) => {
            const userCookieSettings = getUserCookieSettings(getState());
            const newSettings: UserCookieSettingsState = {
                ...userCookieSettings,
                showModal: false,
                settings: {
                    ...userCookieSettings.settings,
                    userSet: true
                }
            };
            dispatch(slice.actions.setUserCookieSettings(newSettings));
            setCookie("UserCookiesSettings", encodeURIComponent(JSON.stringify(newSettings.settings)), 365);
            if (newSettings.settings.statistics) {
                enableApplicationInsightsCookies();
            } else {
                // Clear statistics cookies
                disableApplicationInsightsCookies();
            }
            
            if (!newSettings.settings.preferences) {
                setCookie("Language", "", -1);
            }
        }
};

export const getUserCookieSettingsFromCookie = (nodeContext: NodeContextHttp) => {
    const userCookieSettings = nodeContext.cookies.getCookie("UserCookiesSettings")
    if (userCookieSettings)
        return {
            ...defaultState,
            settings: JSON.parse(decodeURIComponent(userCookieSettings))
        } as UserCookieSettingsState;

    return defaultState;
}

//Hooks
export const useUserCookiesSetings = () => {
    const userCookieSettings = useSelector(getUserCookieSettings);
    const dispatch = useDispatch();

    const saveUserCookieSettings = useCallback(() => {
        dispatch(actionCreators.saveUserCookieSettngs());
    }, [dispatch]);

    const acceptAllCookies = useCallback(() => {
        dispatch(slice.actions.setUserCookieSettings({
            ...userCookieSettings,
            settings: {
                ...userCookieSettings.settings,
                preferences: true,
                statistics: true
            }
        }));
        saveUserCookieSettings();
    }, [dispatch, userCookieSettings, saveUserCookieSettings]);

    const rejectAllCookies = useCallback(() => {
        dispatch(slice.actions.setUserCookieSettings({
            ...userCookieSettings,
            settings: {
                ...userCookieSettings.settings,
                preferences: false,
                statistics: false
            }
        }));
        saveUserCookieSettings();
    }, [dispatch, userCookieSettings, saveUserCookieSettings]);

    const setCookiesSetting = useCallback((key: keyof Omit<UserCookieSettings, 'userSet'>, value: boolean) => {
        dispatch(slice.actions.setUserCookieSettings({
            ...userCookieSettings,
            settings: {
                ...userCookieSettings.settings,
                [key]: value
            }
        }));
    }, [dispatch, userCookieSettings]);

    const openModal = useCallback(() => {
        dispatch(slice.actions.setUserCookieSettings({
            ...userCookieSettings,
            showModal: true
        }));
    }, [dispatch, userCookieSettings]);

    const closeModal = useCallback(() => {
        dispatch(slice.actions.setUserCookieSettings({
            ...userCookieSettings,
            showModal: false
        }));
    }, [dispatch, userCookieSettings]);

    return { userCookieSettings, acceptAllCookies, rejectAllCookies, saveUserCookieSettings, setCookiesSetting, openModal, closeModal } as const;
}