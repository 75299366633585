export const isNode = typeof process === 'object' && process.versions && !!process.versions.node;

export class TaskManager {
    private _nodeTaskMap = new Map<string, Promise<unknown>>();
    private _promises: Promise<unknown>[] = [];

    public add(key: string, action: () => Promise<unknown>): void {
        if (isNode && !this._nodeTaskMap.has(key)) {
            const promise = action();
            this._nodeTaskMap.set(key, promise);
            this.addPromise(promise);
        }
    }

    public addPromise(promise: Promise<unknown>) {
        if (isNode) {
            this._promises.push(promise);
        }
    }

    public async waitAll(): Promise<void> {
        if (isNode) {
            while (this.needWait()) {
                const promises = this._promises.splice(0, this._promises.length);
                await Promise.allSettled(promises);
            }            
        }
    }

    public needWait(): boolean {
        return isNode && this._promises.length > 0;
    }

    public getCompletedTasks(): string[] {
        if (isNode) {
            if (this._nodeTaskMap.size > 0)
                return [...this._nodeTaskMap.keys()];

            return [];
        }

        return (window as any).completedTasks;
    }

    public isTaskCompleted(key: string): boolean {
        if (!isNode && (window as any).completedTasks) {
            return ((window as any).completedTasks as string[]).some(t => t === key);
        }
        return false;
    }

    public removeTaskFromCompleted(key: string): void {
        if (!isNode && (window as any).completedTasks) {
            const tasks = ((window as any).completedTasks as string[])
            tasks.splice(tasks.indexOf(key), 1);
        }
    }
}
