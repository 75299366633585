import { isEmpty, notEmpty } from "./ArrayHelper";

export const isDate = (date: Date | null) => {
    const valueOf = date?.valueOf?.();
    return valueOf != null && !isNaN(valueOf);
}

export const dateISOFormat = (date: Date | null) => {
    if (date == null || !isDate(date)) {
        return null;
    }
    const year = ('0000' + date.getFullYear()).slice(-4);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`
}

const zFill = (num: number | string) => {
    let strNum = (num ?? '').toString();
    while (strNum.length < 2) {
        strNum = `0${strNum}`;
    }
    return strNum;
}

export const inMs = {
    day: 1000 * 60 * 60 * 24,
    hour: 1000 * 60 * 60,
    minute: 1000 * 60,
    second: 1000,
};

export const timeSpanToString = (timeSpanInMs: number) => {
    const sign = timeSpanInMs < 0 ? '-' : '+';
    timeSpanInMs = Math.abs(timeSpanInMs);
    const hours = Math.floor(timeSpanInMs / inMs.hour);
    timeSpanInMs = timeSpanInMs % inMs.hour;
    const minutes = Math.floor(timeSpanInMs / inMs.minute);
    timeSpanInMs = timeSpanInMs % inMs.minute;
    const seconds = Math.floor(timeSpanInMs / inMs.second);
    return `${sign}${zFill(hours)}:${zFill(minutes)}:${zFill(seconds)}`;
}

export const parseDateTime = (value: string) => {
    const [date, time] = (value || '').split(/T|\s+/);
    return {
        date: date ?? '',
        time: time ?? ''
    };
}

export const parseFromtoDateTime = (value: string) => {
    let [from, to] = value.split(',');
    const {
        date: fromDate,
        time: fromTime
    } = parseDateTime(from);
    const {
        date: toDate,
        time: toTime
    } = parseDateTime(to);
    return { fromDate, fromTime, toDate, toTime }
}

export const updatetDateTimePart = ({ dateTimeString, date, time }: { dateTimeString: string; date?: string; time?: string; }) => {
    const result = parseDateTime(dateTimeString);
    if (date !== undefined) {
        result.date = date;
    }
    if (time !== undefined) {
        result.time = time;
    }
    if (result.time) {
        result.time = 'T' + result.time;
    }
    return [result.date, result.time].filter(notEmpty).join('');
}

export type UpdateFromToDateTimePartParams = {
    fromToString: string;
    fromDate?: string;
    fromTime?: string;
    toDate?: string;
    toTime?: string;
};

export const updateFromToDateTimePart = ({ fromToString, fromDate, fromTime, toDate, toTime }: UpdateFromToDateTimePartParams) => {
    let [from, to] = fromToString.split(',');
    if (fromDate !== undefined || fromTime !== undefined) {
        from = updatetDateTimePart({ dateTimeString: from, date: fromDate, time: fromTime });
    }
    if (toDate !== undefined || toTime !== undefined) {
        to = updatetDateTimePart({ dateTimeString: to, date: toDate, time: toTime });
    }
    return [from, to].join(',');
}

export const getCorrectTime = (value: string) => {
    if (isEmpty(value)) {
        return '';
    }
    value = value.replace(/\s/g, '');
    if (/^([0-1]?[0-9]|2[0-4]):([0-5]?[0-9])(:[0-5]?[0-9])?$/.test(value)) {
        return value.split(':').map(part => '0'.repeat(2 - part.length) + part).join(':');
    }
    return '';
}
