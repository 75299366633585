import { AttrMapping, IData } from "@utils/AttrMapping";

export type IOsobaHist = IData & {
    nazev: string;
    pracovniPozice: string;
    titulPred: string;
    jmeno: string;
    prijmeni: string;
    titulZa: string;
    email: string;
    mobil: string;
    telefonZamestnani: string;
}

export const OsobaHistAttrMap = new AttrMapping<IOsobaHist>('Osoba_hist', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'pracovniPozice', serverName: 'Pracovni_pozice', type: 'string' },
    { clientName: 'titulPred', serverName: 'Titul_pred', type: 'string' },
    { clientName: 'jmeno', serverName: 'Jmeno', type: 'string' },
    { clientName: 'prijmeni', serverName: 'Prijmeni', type: 'string' },
    { clientName: 'titulZa', serverName: 'Titul_za', type: 'string' },
    { clientName: 'email', serverName: 'Email', type: 'string' },
    { clientName: 'mobil', serverName: 'Mobil', type: 'string' },
    { clientName: 'telefonZamestnani', serverName: 'Telefon_zam', type: 'string' },
]);
