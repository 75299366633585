import { useAppSettings } from "@store/nodeContextStore";
import { useCallback, useState } from "react";

export const useToggleState = (defaultValue: boolean = false) => {
    const [state, setState] = useState(defaultValue);
    const toggleState = useCallback(() => setState(prevState => !prevState), [setState]);
    return [state, toggleState, setState] as const;
}

export const useLoadState = (timeout?: number) => {
    const [isLoaded, setLoaded] = useState(false);
    const { appSettings } = useAppSettings();

    const onLoad = useCallback(() => {
        setTimeout(() => setLoaded(true), timeout ?? appSettings.externalWidgets.loaderTimeout ?? 0);
    }, [timeout, appSettings.externalWidgets.loaderTimeout]);

    return { isLoaded, setLoaded, onLoad };
}

export const useRefreshState = () => {
    const [state, setState] = useState<number>();
    return [
        () => setState(state => ((state ?? 0) + 1) % (Number.MAX_SAFE_INTEGER - 1)),
        state
    ] as const;
}
