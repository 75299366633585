import { useEffect, useState } from "react";

export type LoginInfo = {
    userName?: string,
    name?: string,
    isLogged: boolean
}

type StopInterval = () => void;

type LoginModules = {
    login: (loginCredentials: ILoginCredentials) => Promise<LoginResult>;
    loginWithToken: (token: string) => Promise<LoginResult>;
    logout: () => Promise<void>;
    getLoginState: () => Promise<LoginInfo>;
    redirectToApp: () => void;
    initCheckLoginInterval: (callback: () => void) => StopInterval;
    changeLanguage: (language: string) => void;
    tryChangeLanguage: (language: string) => Promise<string>;
}

const requireAsync = (...modules: string[]) => new Promise<any>((resolve, reject) => {
    requirejs(modules, (...loadedModules: any[]) => resolve(loadedModules), reject)
});

const replaceFunction = (obj: any, name: any, funcFactory: (oldFunction: Function) => Function) => {
    obj[name] = funcFactory(obj[name]);
}

let getLoginModulesPromise: Promise<LoginModules>;
export const getLoginModules = () => getLoginModulesPromise ??= new Promise<LoginModules>(async (resolve, reject) => {
    await requireAsync('js/TescoSW.Sources.js', 'js/nenmultiweb.js');
    const [appConfigurationModule, globalContextModule] = await requireAsync('Configuration/AppConfiguration', 'Global/Base/GlobalContext');
    const { AppConfiguration } = appConfigurationModule;
    const { GlobalContext, ContextType } = globalContextModule;

    const fixLoginInIframe = () => {
        replaceFunction(GlobalContext, '_get', (oldFunction: any) => function (name: string) {
            return oldFunction(name, ContextType.current);
        });
        replaceFunction(GlobalContext, '_register', (oldFunction: any) => function (name: string, property: any) {
            return oldFunction(name, property, ContextType.current);
        });
        Object.defineProperty(AppConfiguration, 'instance', {
            get: function () { return AppConfiguration['_instance']; },
            set: function (value) { AppConfiguration['_instance'] = value; }
        });
    }

    fixLoginInIframe();
    const [nen] = await requireAsync('nenmultiweb');
    const { loginInit, getLoginState, login, loginWithToken, logout, redirectToApp, initCheckLoginInterval, changeLanguage, tryChangeLanguage } = nen.lightwebAuthManager;
    await loginInit();
    resolve({
        getLoginState,
        login,
        loginWithToken,
        logout,
        redirectToApp,
        initCheckLoginInterval,
        changeLanguage,
        tryChangeLanguage,
    });
});

export type LoginStatus = 'logged' | 'login' | 'loading' | 'error';

export const getLoginInfo = async (): Promise<LoginInfo> => {
    const { getLoginState } = await getLoginModules();
    return await getLoginState();
}

export interface ILoginCredentials {
    /** Uživatelské jméno */
    userName: string;
    /** Heslo uživatele */
    password: string;
    /** Kód jazyka */
    language: string;
}

export type LoginResult = {
    isSucceful: boolean;
    isInProgress?: boolean;
    errorLocKey?: string;
    errorMessage?: string;
    errorCode?: string;
    afterLoginConfirmLocKey?: string;
}

export const login = async (loginCredentials: ILoginCredentials) => {
    const { login } = await getLoginModules();
    return await login(loginCredentials);
}

export const loginWithToken = async (token: string): Promise<LoginResult> => {
    const { loginWithToken } = await getLoginModules();
    return await loginWithToken(token);
}

export const logout = async () => {
    const { logout } = await getLoginModules();
    await logout();
}

export const changeLanguage = async (language: string) => {
    const { changeLanguage } = await getLoginModules();
    changeLanguage(language);
}

export const tryChangeLanguage = async (language: string) => {
    const { tryChangeLanguage } = await getLoginModules();
    return await tryChangeLanguage(language);
}

export const initCheckLoginInterval = async (callback: () => void): Promise<StopInterval> => {
    const { initCheckLoginInterval } = await getLoginModules();
    return initCheckLoginInterval(callback);
}

export const getAppUrl = (search?: string, startSlash?: boolean) => {
    const urlParams = new URLSearchParams(search ?? window.location.search);
    const appSearch = decodeURIComponent(urlParams.get('appSearch') ?? '');
    return `${startSlash ? '/' : ''}main.html${appSearch}`;
}

export const redirectToApp = () => {
    const currentWindow = top ?? self;
    if (currentWindow) {
        currentWindow.location.href = getAppUrl();
    }
}

export const useMwServiceWorker = () => {
    const [swLoaded, setSwLoaded] = useState(false);

    useEffect(() => {
        if (!('serviceWorker' in navigator)) {
            return;
        }
        try {
            let refreshing = false;

            // detect controller change and refresh the page
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (!refreshing) {
                    console.log('[Service Worker] reload');
                    window.location.reload();
                    refreshing = true;
                }
            })

            let timeout: number;
            navigator.serviceWorker.register('js/serviceworker/TescoSW.ServiceWorker.Loader.js', { scope: './' })
                .then(registration => {
                    try {
                        if (registration.waiting) {
                            console.log('[Service Worker] installed');
                            registration.waiting.postMessage('update');
                        } else {
                            timeout = window.setTimeout(() => {
                                setSwLoaded(true);
                            }, 500);
                        }
                        const existed = !!registration.active;

                        registration.addEventListener('updatefound', () => {
                            window.clearTimeout(timeout);
                            const { installing } = registration;
                            if (installing) {
                                installing.addEventListener('error', () => setSwLoaded(true));
                                installing.addEventListener('statechange', event => {
                                    const sw = event.target as ServiceWorker;
                                    const { waiting } = registration;
                                    if (registration.installing) {
                                        return;
                                    }
                                    if (sw.state == 'redundant' || !waiting) {
                                        console.log('[Service Worker] redundant');
                                        setSwLoaded(true);
                                        return;
                                    }
                                    if (existed) {
                                        waiting.postMessage('update');
                                        console.log('[Service Worker] installed');
                                        window.location.reload();
                                        return;
                                    }
                                    setSwLoaded(true);
                                    console.log('[Service Worker] initialized for the first time');
                                });
                                setSwLoaded(false);
                            }
                        });
                    } catch (e) {
                        setSwLoaded(true);
                        console.error(e);
                    }
                });
        } catch (e) {
            setSwLoaded(true);
            console.error(e);
        }
    }, [setSwLoaded]);

    return { swLoaded };
}
