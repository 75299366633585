import React, { FC, PropsWithChildren, ReactNode, useEffect } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { useFilterManagerContext } from "../FilterManager";
import FilterInput from "@components/FilterInput/FilterInput";
import { useId } from "@utils/UseId";
import { useChangingFilterValue } from "..";

const cn = setupCn('filter-manager-input');

export type FilterManagerInputProps<TAttr extends string = string> = PropsWithChildren<{
    id?: string;
    className?: Classes;
    headerText?: ReactNode;
    title?: string;
    name: TAttr;
}>;

export type FilterManagerInputType<TAttr extends string = string> = FC<FilterManagerInputProps<TAttr>>;

const FilterManagerInput: FilterManagerInputType = ({
    id,
    headerText,
    className,
    title,
    name
}) => {
    const {
        filterValues,
        onFilterValueChange,
        setIsChanged
    } = useFilterManagerContext(name);
    const filterValue = (name && filterValues[name]) ?? '';

    const { controlId } = useId({ id: `text-${id ?? name}` });
    const { isChanged, setValue, applyValue } = useChangingFilterValue(filterValue, onFilterValueChange);

    useEffect(() => {
        setIsChanged(name, isChanged);
    }, [name, isChanged, setIsChanged])

    return (
        <FilterInput
            id={controlId}
            headerText={headerText}
            title={title}
            onChange={setValue}
            className={cn.with(className).main()}
            name={name}
            isInline
            value={filterValue}
            onChangeDone={applyValue} />
    );
};

FilterManagerInput.displayName = 'FilterManagerInput';

export default React.memo(FilterManagerInput);
