import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { useFormatText } from "@utils/FormatTextUtils";

const cn = setupCn('formated-text');

export type FormatedTextProps = PropsWithChildren<{
    className?: Classes;
    boldPrimaryColor?: boolean;
}>;

const FormatedText: FC<FormatedTextProps> = ({
    className,
    boldPrimaryColor,
    children
}) => {
    const formatedText = useFormatText(typeof children == 'string' ? children : '');
    return (
        <span className={cn.with(className).main({
            '--bold-primary-color': boldPrimaryColor
        })}>
            {typeof children == 'string' ? formatedText : children}
        </span>
    );
};

FormatedText.displayName = 'FormatedText';

export default React.memo(FormatedText);
