import React, { ChangeEventHandler, FC, PropsWithChildren } from "react"
import { setupCn } from "@utils/BemUtils";
import FormControl, { FormControlBaseProps } from "@components/FormControl";

const cn = setupCn('gov-form-control');
const cnCheckBox = cn.setupSubCn('__checkbox');

export type CheckBoxProps = FormControlBaseProps & PropsWithChildren<{
    id: string;
    name?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    onChange?: ChangeEventHandler;
    value?: string;
    isChecked?: boolean;
}>;

const CheckBox: FC<CheckBoxProps> = ({
    id,
    name,
    isRequired,
    isDisabled,
    isChecked,
    onChange,
    value,
    children,
    ...formControlProps
}) => {
    return (
        <FormControl
            {...formControlProps}
            isCustom
            labelFor={id}
            afterLabel={<span className={cn('__indicator')} />}>
            <input
                id={id}
                className={cnCheckBox()}
                type="checkbox"
                name={name}
                checked={isChecked}
                value={value}
                onChange={onChange}
                disabled={isDisabled}
                aria-required={isRequired}
                aria-disabled={isDisabled} />
        </FormControl>
    );
};

CheckBox.displayName = 'CheckBox';

export default React.memo(CheckBox);
