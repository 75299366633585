import React, { useCallback, useEffect } from 'react';
import GovMenu from '@components/GovMenu/GovMenu';
import HeaderContainer from '@components/Container/HeaderContainer';
import ButtonLink from '@components/Button/ButtonLink';
import { setupCn } from '@utils/BemUtils';
import LanguageSwitcher from '@components/LanguageSwitcher/LanguageSwitcher';
import { useToggleState } from '@utils/StateUtils';
import Logo from '@components/Logo/Logo';
import { useConstants } from '@constants/urlConstants';
import { useLocalization } from '@store/localizationStore';
import { NavLinkProps } from 'react-router-dom';
import { useAppSettings } from '@store/nodeContextStore';
import UniversalLink from '@components/UniversalLink/UniversalLink';

import './Header.scss'

const cn = setupCn('gov-header');

const isActiveLinkFactory = (urls: string[], exact?: boolean): NavLinkProps['isActive'] =>
    (_, location) =>
        urls.some(url => exact ? location.pathname == url : location.pathname.startsWith(url))

const Header: React.FC = () => {
    const { ts, t } = useLocalization();
    const { getUrl } = useConstants();
    const { getOutageVersion, getInformationForUsersUrl } = useAppSettings();
    const informationForUsersUrl = getInformationForUsersUrl();

    const [isNavigationActive, toggleNavigation] = useToggleState(false);


    const onMobileLinkClick = useCallback(() => {
        if (isNavigationActive)
            toggleNavigation();
    }, [isNavigationActive, toggleNavigation]);

    useEffect(() => {
        document.body.style.overflow = isNavigationActive ? 'hidden' : 'initial';
    }, [isNavigationActive]);

    return (
        <HeaderContainer noYOffset isNavigationActive={isNavigationActive} aria-label={t('NEN-786855')}>
            <div className={cn('__holder')}>
                <div className={cn('__left')}>
                    <Logo isNavigationActive={isNavigationActive} />
                </div>
                <div className={cn('__right')}>
                    <div className={cn.subCn('__item', '--login')}>
                        {
                            getOutageVersion().enabled ? <p>{ts('NEN-770209')}</p> : <ButtonLink to={getUrl('prihlaseni')} isInversed isOutlined>{ts("NEN-749252", { isPrimaryTheme: true })}</ButtonLink>
                        }
                        <ButtonLink
                            to={getUrl('registrace')}
                            isInversed
                            isOutlined>
                            {ts("NEN-743741", { isPrimaryTheme: true })}
                        </ButtonLink>
                    </div>
                    <div className={cn('__fixed-items')}>
                        <div className={cn.subCn('__item', '--mobile')}>
                            <button onClick={toggleNavigation} className={cn.with({
                                'active': isNavigationActive
                            })('__hamburger')}>
                                <span></span>
                                <span></span>
                                <div>MENU</div>
                            </button>
                        </div>
                        <div className={cn('__item', '__lang')}>
                            <LanguageSwitcher id="header-lang-select" />
                        </div>
                    </div>
                </div>
            </div>
            <hr className={cn('__separator')} />
            <div
                className={cn.with({
                    'active': isNavigationActive
                })('__overlay')}
                onClick={toggleNavigation}
            />
            <GovMenu isActive={isNavigationActive} onLinkClick={onMobileLinkClick} links={[
                <GovMenu.Link to={getUrl('home')}
                    isActive={isActiveLinkFactory([
                        getUrl('home'),
                        getUrl('skoleniZzvz'),
                        getUrl('videa'),
                        getUrl('prirucky')
                    ], true)}
                    lockey="NEN-738416" />,
                <GovMenu.Link to={getUrl('verejneZakazky')}
                    isActive={isActiveLinkFactory([
                        getUrl('verejneZakazky'),
                        getUrl('registrDns'),
                        getUrl('vestnik'),
                        getUrl('registrSK')
                    ])}
                    lockey="NEN-738417" />,
                <GovMenu.Link to={getUrl('profilyZadavateluPlatne')}
                    isActive={isActiveLinkFactory([
                        getUrl('profilyZadavateluPlatne'),
                        getUrl('profilyZadavateluZneplatnene'),
                    ])}
                    lockey="NEN-738418" />,
                <GovMenu.Link to={getUrl('registrZadavatelu')}
                    isActive={isActiveLinkFactory([
                        getUrl('registrZadavatelu'),
                        getUrl('registrDodavatelu'),
                    ])}
                    lockey="NEN-738419" />,
                <UniversalLink
                    to={informationForUsersUrl}
                    isInversed
                    isExternal
                    size='large'
                    isStandalone
                >
                    {ts("NEN-819382")}
                </UniversalLink>,
            ]} />
        </HeaderContainer>
    );
}

export default React.memo(Header);