import { useLocalization } from "@store/localizationStore";
import { Classes, setupCn } from "@utils/BemUtils";
import React, { MouseEventHandler } from "react";
import { FC } from "react"
import { Link, LinkProps } from "react-router-dom";

const cn = setupCn('gov-pagination__item');

export type PaginationArrowDirection = 'left' | 'right';

export type PaginationArrowProps =
    Pick<LinkProps, 'to'> &
    {
        className?: Classes;
        direction: PaginationArrowDirection;
        onClick?: MouseEventHandler;
        isDisabled?: boolean;
    }

const PaginationArrow: FC<PaginationArrowProps> = ({ className, to, onClick, direction, isDisabled }) => {
    const { t } = useLocalization();

    const labelLocKey = direction == 'left' ? 'NEN-765109' : 'NEN-765110';
    const directionModificator = direction == 'left' ? '--arrow-left' : '--arrow-right';

    return (
        <Link
            to={to}
            className={cn.with(className).main(directionModificator, {
                '--disabled': isDisabled
            })}
            onClick={onClick}
            rel={direction == 'left' ? 'prev' : 'next'}
            aria-label={`${t(labelLocKey)} ${t('NEN-787016')}`} />
    );
}

export default React.memo(PaginationArrow);
