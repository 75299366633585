import { AttrMapping, IData } from "@utils/AttrMapping";

export type ICpvNipezSubjektuModel = IData & {
    id: number;
    nipezKod: string;
    nipezNazev: string;
    cpvKod: string;
    cpvNazev: string;
}

export const CpvNipezSubjektuAttrMap = new AttrMapping<ICpvNipezSubjektuModel>('CPVNIPEZ_subjektu', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'nipezKod', serverName: 'NIPEZ_subjekt.Kod', type: 'string' },
    { clientName: 'nipezNazev', serverName: 'NIPEZ_subjekt.Nazev', type: 'string' },
    { clientName: 'cpvKod', serverName: 'CPV_subjekt.Kod', type: 'string' },
    { clientName: 'cpvNazev', serverName: 'CPV_subjekt.Nazev', type: 'string' },
]);
