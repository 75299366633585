import { AttrMapping, IData } from "@utils/AttrMapping";

export type ICinnostSubjektuHist = IData & {
    hlavniPredmetCinnosti: string;
    jinaCinnost: string;
}

export const CinnostSubjektuHistAttrMap = new AttrMapping<ICinnostSubjektuHist>('Cinnost_subj_hist', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'hlavniPredmetCinnosti', serverName: 'Predmet_cinnosti.Nazev', type: 'string' },
    { clientName: 'jinaCinnost', serverName: 'Jina_cinnost', type: 'string' },
]);
