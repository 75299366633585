import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { EnumDescription } from '@services/EnumDescriptionService';
import { AppDispatch, ApplicationState, ApplicationStateSelector } from '.';
import { getErrorStoreActions } from './errorStore';
import { getNodeContext } from './nodeContextStore';

export const name = 'enumDescriptions';

// Declare an interface of the store's state.
export type EnumDescriptionsState = {
    descriptions: { [enumName: string]: EnumDescription }
}

// Create the slice.
export const slice = createSlice({
    name,
    initialState: {
        descriptions: {}
    } as EnumDescriptionsState,
    reducers: {
        addDescriptions: (state, action: PayloadAction<EnumDescription[]>) => {
            const newDescriptions = { ...state.descriptions };
            action.payload?.forEach(enumDesc => newDescriptions[enumDesc.enumName] = enumDesc);
            return {
                ...state,
                descriptions: newDescriptions
            };
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getEnumDescriptionsState = (state: ApplicationState) => state[name];
export const getAllEnumDescriptions = createSelector([getEnumDescriptionsState],
    (enumDescriptions) => enumDescriptions?.descriptions
);
export const getEnumDescription = createSelector(
    getAllEnumDescriptions,
    (_: unknown, enumName: string) => enumName,
    (enumDescriptions, enumName) => enumDescriptions[enumName]
);

// Define action creators.
export const actionCreators = {
    getEnumDescription: (enumName: string) =>
        (dispatch: AppDispatch, getState: ApplicationStateSelector): EnumDescription | null => {
            const appState = getState();
            const enumDescription = getEnumDescription(appState, enumName);
            if (enumDescription) {
                return enumDescription;
            }

            const nodeContext = getNodeContext(appState);
            const promise = nodeContext.enumDescriptionService
                .getEnumsDescriptions(enumName);

            promise.catch(e => {
                dispatch(getErrorStoreActions().push(e))
            })

            nodeContext.taskManager.addPromise(promise);

            return null;
        }
};

export const useEnumDescriptions = () => {
    const dispatch: AppDispatch = useDispatch();
    const enumDescriptions = useSelector(getAllEnumDescriptions);
    return {
        getEnumDescription: (enumName: string) => enumDescriptions[enumName] ?? dispatch(actionCreators.getEnumDescription(enumName))
    }
}

export type UseEnumDescriptionsType = ReturnType<typeof useEnumDescriptions>;
