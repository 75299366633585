import { useDetailFrameContext } from "@components/DetailFrame";
import { useLocalization } from "@store/localizationStore";
import { useDescriptionManager } from "@store/nodeContextStore";
import { AttrName, IData } from "@utils/AttrMapping";

export const useDescription = (text: string) => {
    useDescriptionManager().content = text;
}

export type DetailDescriptionParams<T extends IData> = {
    lockey: string,
    attrName?: AttrName<T>
}
export const useDetailDescription = <T extends IData>(prms: DetailDescriptionParams<T>[]) => {
    const { t } = useLocalization();
    const { getAttr } = useDetailFrameContext<T>();
    const text = prms.reduce((acc, curr) =>
        `${acc} ${t(curr.lockey)}${curr.attrName ? ': ' + getAttr(curr.attrName)?.value : ''}.`,
        '').trim();

    useDescription(text);
}
