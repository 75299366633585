import FilterInput from "@components/FilterInput/FilterInput";
import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from "react"
import { useLocalization } from "@store/localizationStore";
import { isEmpty, notEmpty } from "@utils/ArrayHelper";
import { setupCn, Classes } from "@utils/BemUtils";
import { getCorrectTime, parseFromtoDateTime as parseFromToDateTime, updateFromToDateTimePart, UpdateFromToDateTimePartParams } from "@utils/DateUtils";
import { renderIf } from "@utils/RenderUtils";
import { ChangeDoneReason, FilterManagerChangeEventHandler, useFilterManagerContext } from "../FilterManager";
import { useId } from "@utils/UseId";
import Grid from "@components/Grid";
import './FilterManagerDate.scss';
import FlexBox from "@components/FlexBox/FlexBox";
import FilterHeader from "../FilterHeader";

const cn = setupCn('filter-manager-date');

export type FilterManagerDateProps<TAttr extends string = string> = PropsWithChildren<{
    id?: string;
    className?: Classes;
    headerText?: ReactNode;
    hideTime?: boolean;
    name: TAttr;
}>;

export type FilterManagerDateType<TAttr extends string = string> = FC<FilterManagerDateProps<TAttr>>;

const getApplyValuePart = (date: string, time: string) => {
    if (isEmpty(date)) {
        return '';
    }
    time = getCorrectTime(time);
    if (notEmpty(time)) {
        return `${date}T${time}`;
    }
    return date;
}

const getApplyValue = (value: string) => {
    const values = parseFromToDateTime(value);
    const from = getApplyValuePart(values.fromDate, values.fromTime);
    const to = getApplyValuePart(values.toDate, values.toTime);
    if (isEmpty(from) && isEmpty(to)) {
        return '';
    }
    return `${from},${to}`;
}

const FilterManagerDate: FilterManagerDateType = ({
    id,
    className,
    name,
    hideTime,
    headerText
}) => {
    const { generateId } = useId({ id: `date-${id ?? name}` });

    const {
        filterValues,
        onFilterValueChange,
        setIsChanged,
        clearRequesting,
    } = useFilterManagerContext(name);
    const { ts } = useLocalization();
    const oldValue = (name && filterValues[name]) ?? '';
    const [actualValue, setActualValue] = useState(oldValue);
    const values = parseFromToDateTime(actualValue);
    const actualApplyValue = getApplyValue(actualValue);
    const isChanged = oldValue != actualApplyValue;

    const processNewValue = useCallback((updateParams: UpdateFromToDateTimePartParams, reason?: ChangeDoneReason) => {
        const newVal = updateFromToDateTimePart(updateParams);
        if (reason == 'enter' || reason == 'blur') {
            onFilterValueChange(name, getApplyValue(newVal));
        }
        return newVal;
    }, [onFilterValueChange, name]);

    const handleChange = useCallback<FilterManagerChangeEventHandler>((inputName, value, reason?: ChangeDoneReason) => {
        switch (inputName) {
            case 'from-date':
                setActualValue(prevValue => processNewValue({ fromToString: prevValue, fromDate: value }, reason));
                return;
            case 'from-time':
                setActualValue(prevValue => processNewValue({ fromToString: prevValue, fromTime: value }, reason));
                return;
            case 'to-date':
                setActualValue(prevValue => processNewValue({ fromToString: prevValue, toDate: value }, reason));
                return;
            case 'to-time':
                setActualValue(prevValue => processNewValue({ fromToString: prevValue, toTime: value }, reason));
                return;
        }
    }, [processNewValue]);

    useEffect(() => setActualValue(oldValue || ''),
        [oldValue]);

    useEffect(() => {
        setIsChanged(name, isChanged);
    }, [name, isChanged, setIsChanged]);

    return (
        <div className={cn.with(className).main()}>
            <FilterHeader>{headerText}</FilterHeader>
            <Grid columns='3' isPlain>
                <FlexBox wrap>
                    <FilterInput
                        id={generateId('__from-date')}
                        headerText={ts('NEN-742321')}
                        className={cn('__date')}
                        onChange={handleChange}
                        onChangeDone={handleChange}
                        isInline
                        clearRequesting={clearRequesting}
                        value={values.fromDate}
                        name='from-date'
                        type='date' />
                    {
                        renderIf(!hideTime,
                            <FilterInput
                                headerText={ts('NEN-765995')}
                                id={generateId('__from-time')}
                                className={cn('__time')}
                                onChange={handleChange}
                                onChangeDone={handleChange}
                                isInline
                                clearRequesting={clearRequesting}
                                value={values.fromTime}
                                name='from-time'
                                type='time' />
                        )
                    }
                </FlexBox>
                <FlexBox wrap>
                    <FilterInput
                        headerText={ts('NEN-742322')}
                        id={generateId('__to-date')}
                        className={cn('__date')}
                        onChange={handleChange}
                        onChangeDone={handleChange}
                        clearRequesting={clearRequesting}
                        isInline
                        value={values.toDate}
                        name='to-date'
                        type='date' />
                    {
                        renderIf(!hideTime,
                            <FilterInput
                                headerText={ts('NEN-765995')}
                                id={generateId('__to-time')}
                                className={cn('__time')}
                                onChange={handleChange}
                                onChangeDone={handleChange}
                                clearRequesting={clearRequesting}
                                isInline
                                value={values.toTime}
                                name='to-time'
                                type='time' />
                        )
                    }
                </FlexBox>
            </Grid>
        </div>
    );
};

FilterManagerDate.displayName = 'FilterManagerDate';

export default React.memo(FilterManagerDate);
