import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import FilterManagerGroup, { FilterGroupContext, useFilterGroupExpandToggle, useFilterGroupRegister } from "./FilterManagerGroup/FilterManagerGroup";
import FilterManagerInput, { FilterManagerInputType } from "./FilterManagerInput/FilterManagerInput";
import FilterManagerEnum, { FilterManagerEnumType } from "./FilterManagerEnum/FilterManagerEnum";
import { UrlParamObject, useUrlParamsExtended } from "@utils/QueryHook";
import FilterManagerBool, { FilterManagerBoolType } from "./FilterManagerBool/FilterManagerBool";
import FilterManagerDate, { FilterManagerDateType } from "./FilterManagerDate/FilterManagerDate";
import FilterManagerNumber, { FilterManagerNumberType } from "./FilterManagerNumber/FilterManagerNumber";
import FilterManagerLov, { FilterManagerLovType } from "./FilterManagerLov/FilterManagerLov";
import FilterInput from "@components/FilterInput/FilterInput";
import { useLocalization } from "@store/localizationStore";
import { FilterParamsNames } from "@constants/urlConstants";
import Accordion from "@components/Accordion";
import Button from "@components/Button/Button";
import './FilterManager.scss';
import FilterManagerRow from "./FilterManagerRow/FilterManagerRow";
import offsets from "@styles/offsets";
import { IdContext } from "@utils/UseId";
import Search from "@components/Search";
import { iaddToArray, iremoveFromArray, notEmpty } from "@utils/ArrayHelper";
import { renderIf } from "@utils/RenderUtils";
import { useRefreshState } from "@utils/StateUtils";

const cn = setupCn('filter-manager');

export type ChangeDoneReason = 'blur' | 'enter' | 'buttonClick';

export type FilterManagerChangeEventHandler<TValue = string> = (name: string | undefined, value: TValue) => void;
export type FilterManagerChangeDoneEventHandler = (name: string | undefined, value: string, reason: ChangeDoneReason) => void;

export const useChangingFilterValue = (filterValue: string, onChangeDone?: FilterManagerChangeEventHandler) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(filterValue);
    }, [filterValue]);

    const handleFilterValueChange = useCallback<FilterManagerChangeEventHandler>((_, newValue) => {
        setValue(newValue);
    }, []);

    const isChanged = filterValue != value;

    const applyValue = useCallback<FilterManagerChangeEventHandler>((name, value) => {
        if (isChanged) {
            onChangeDone?.(name, value);
        }
    }, [onChangeDone, isChanged]);

    return { value, setValue: handleFilterValueChange, isChanged, applyValue };
}

export type FilterManagerProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    paramNames: FilterParamsNames;
}>;

export type FilterManagerType<TAttr extends string = string> = FC<FilterManagerProps> & {
    GroupsContainer: typeof Accordion;
    Group: typeof FilterManagerGroup;
    Row: typeof FilterManagerRow;
    Input: FilterManagerInputType<TAttr>;
    Enum: FilterManagerEnumType<TAttr>;
    Bool: FilterManagerBoolType<TAttr>;
    Date: FilterManagerDateType<TAttr>;
    Number: FilterManagerNumberType<TAttr>;
    Lov: FilterManagerLovType<TAttr>;
}

type FilterManagerContextType = {
    onFilterValueChange: FilterManagerChangeEventHandler;
    filterValues: UrlParamObject;
    setIsChanged: (name: string, isChanged: boolean) => void;
    clearRequesting: number | undefined;
}

const FilterManagerContext = React.createContext<FilterManagerContextType>({
    filterValues: {},
    onFilterValueChange: () => null,
    setIsChanged: () => null,
    clearRequesting: undefined,
});

const FilterManager: FilterManagerType = React.memo(({
    id,
    className,
    paramNames,
    children
}: FilterManagerProps) => {
    const { ts } = useLocalization();
    id = `${id}-filter`;

    const {
        filterParams,
        updateFilterUrl,
        replaceFilterUrlParams
    } = useUrlParamsExtended({ paramNames });

    const isAppliedFilter = filterParams && Object.entries(filterParams)
        .some(([k, v]) => !k.includes('sort') && k !== 'page' && notEmpty(v));

    const [changedNames, setChangedNames] = useState<string[]>([]);
    const [requestClear, clearRequesting] = useRefreshState();

    const handleClearAll = useCallback(() => {
        requestClear();
        replaceFilterUrlParams({ update: undefined });
    }, [replaceFilterUrlParams, requestClear]);

    const changeUrlValue = useCallback((name: string | undefined, value: string) => name && updateFilterUrl({
        update: {
            [name]: value == '' ? undefined : value,
            page: undefined
        }
    }), [updateFilterUrl]);

    const handleIsChangedUpdate = useCallback((name: string, isChanged: boolean) => {
        setChangedNames(changedNames => isChanged ? iaddToArray(changedNames, name) : iremoveFromArray(changedNames, name));
    }, []);

    const filterManagerContext = useMemo<FilterManagerContextType>(() => ({
        filterValues: filterParams ?? {},
        onFilterValueChange: changeUrlValue,
        setIsChanged: handleIsChangedUpdate,
        clearRequesting,
    }), [filterParams, changeUrlValue, clearRequesting, handleIsChangedUpdate]);

    const {
        handleContentToggle,
        isContentHidden,
        registerFilterName
    } = useFilterGroupExpandToggle({ defaultVisible: false, filterValues: filterManagerContext.filterValues })

    const query = filterManagerContext.filterValues['query'];
    const { isChanged: isQueryChanged, setValue: setQueryValue, applyValue: applyQueryValue } = useChangingFilterValue(query ?? '', changeUrlValue);

    const withAdvanced = !!children;
    return (
        <IdContext.Provider value={id}>
            <div id={id} className={cn.with(className).main({
                '--with-advanced': withAdvanced
            })}>
                <div className={cn('__head')}>
                    <Search id={id} isWide className={offsets['u-mb--unset']}>
                        <FilterInput
                            id={`${id}__fast-search`}
                            headerText={ts('NEN-742948')}
                            className={cn.with(offsets['u-mb--unset'])('__search-input')}
                            name='query'
                            isInline
                            value={query}
                            onChange={setQueryValue}
                            onChangeDone={applyQueryValue} />
                    </Search>
                    {
                        !withAdvanced ? null :
                            <Button
                                className={cn.with('flat')('__advanced-toggle')}
                                onClick={handleContentToggle}
                                isOutlined>
                                {ts('NEN-747766')}
                            </Button>
                    }
                </div>
                {
                    renderIf(withAdvanced,
                        <>
                            <div className={cn.subCn('__advanced', {
                                '--hidden': isContentHidden
                            })}>
                                <FilterManagerContext.Provider value={filterManagerContext}>
                                    <FilterGroupContext.Provider value={registerFilterName}>
                                        {children}
                                    </FilterGroupContext.Provider>
                                </FilterManagerContext.Provider>
                            </div>
                            <div className={cn('__buttons')}>
                                {
                                    renderIf(isAppliedFilter,
                                        <div className={offsets['u-pt--20']}>
                                            <Button isOutlined onClick={handleClearAll}>{ts('NEN-768417')}</Button>
                                            {' '}
                                        </div>
                                    )
                                }
                                {
                                    renderIf(!isContentHidden && (changedNames?.length || isQueryChanged),
                                        <div className={offsets['u-pt--20']}>
                                            <Button>{ts('NEN-768418')}</Button>
                                        </div>
                                    )
                                }
                            </div>
                        </>
                    )
                }
            </div>
        </IdContext.Provider >
    );
}) as unknown as FilterManagerType;

export const useFilterManagerContext = (filterName?: string) => {
    useFilterGroupRegister(filterName);
    return useContext(FilterManagerContext);
}

FilterManager.displayName = 'FilterManager';
FilterManager.GroupsContainer = Accordion;
FilterManager.Group = FilterManagerGroup;
FilterManager.Row = FilterManagerRow;
FilterManager.Input = FilterManagerInput;
FilterManager.Enum = FilterManagerEnum;
FilterManager.Bool = FilterManagerBool;
FilterManager.Date = FilterManagerDate;
FilterManager.Number = FilterManagerNumber;
FilterManager.Lov = FilterManagerLov;

export default FilterManager;
