import { useAppSettings } from "@store/nodeContextStore";
import { useLocalization } from "@store/localizationStore";
import UniversalLink from "@components/UniversalLink/UniversalLink";
import ButtonLink from "@components/Button/ButtonLink";
import { setupCn } from "@utils/BemUtils";

import './Manuals.scss';

const cnContainer = setupCn('manuals');
const cn = setupCn('manual');
const Manuals: React.FC<{}> = () => {
    const { ts, t } = useLocalization();
    const { getManuals } = useAppSettings();
    const manualsSettings = getManuals();

    return (
        <>
            <div className={cnContainer()}>
                {
                    manualsSettings.manuals.map((manual, index) => {
                        return (
                            <div key={`${manual.caption} ${index}`} className={cn()}>
                                <h4 className={cn('__header')}>
                                    <UniversalLink
                                        to={manual.url}
                                        isExternal>
                                        {manual.caption}
                                    </UniversalLink>
                                </h4>
                                <div className={cn('__description')}>{manual.description}</div>
                            </div>
                        )
                    })
                }
            </div>
            <ButtonLink
                isOutlined
                isAnchor
                targetBlank
                to={manualsSettings.showAllUrl}
                aria-label={t("NEN-786555")}>
                {ts("NEN-645393")}
            </ButtonLink>
        </>
    )
};

Manuals.displayName = 'Prirucky';

export default Manuals;