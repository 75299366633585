import { DeferredStateful, PromiseUtils } from "./PromiseUtils";
import { Queue } from "./Queue";
/**
 * Objekt vytvoření při asynchronním zamknutí, pomocí kterého jde (a je potřeba) zámek odemknout
 */
export class Releaser {
    private readonly _releasingFunc: () => void;

    constructor(releasingFunc: () => void) {
        this._releasingFunc = releasingFunc;
    }

    /**
     * Uvolní zámek
     */
    public release(): void {
        if (this._releasingFunc != null)
            this._releasingFunc();
    }
}

/**
 * Asynchronní zámek, který jde opakovaně zamykat
 */
export class AsyncLock {

    private readonly _semaphore: AsyncSemaphore;
    private readonly _releaser: Promise<Releaser>;

    constructor() {
        this._semaphore = new AsyncSemaphore(1);
        this._releaser = Promise.resolve(new Releaser(() => { this._semaphore.release(); }));
    }

    /**
     * Zamkne zámek a vrací promis, na který je potřeba čekat, dokud se zámek neodemkne
     */
    public lock(): Promise<Releaser> {
        const wait = this._semaphore.wait();
        // pokud je už promis dokončený, tak se vrací releaser, což je dokončený promis s releaserem, jinak se k nedokončenému přidá další uvolnění
        return wait.isSettled ? this._releaser : wait.promise.then(() => { return new Releaser(() => { this._semaphore.release(); }); });
    }
}

export class MultiAsyncLock {
    private readonly _locks: Map<any, AsyncLock>= new Map<any, AsyncLock>();

    public get locks() { return this._locks; }

    public getLock(key: any): AsyncLock {
        if (this._locks.has(key))
            return this._locks.get(key)!;

        const lock = new AsyncLock();
        this._locks.set(key, lock);
        return lock;
    }
}

/**
 * Asynchronní semafor udržující frontu všech čekajících úkolů
 */
class AsyncSemaphore {

    private static _completed: DeferredStateful<void> = { promise: Promise.resolve(), resolve: () => null, reject: () => null, isSettled: true, isResolved: true, isRejected: false, promiseValue: undefined };
    private _waiters: Queue<DeferredStateful<void>> = new Queue<DeferredStateful<void>>();
    private _currentCount: number;

    constructor(initialCount: number) {
        if (initialCount < 0)
            throw new Error("initialCount can't be lower than zero");
        this._currentCount = initialCount;
    }

    public wait(): DeferredStateful<void> {
        if (this._currentCount > 0) {
            --this._currentCount;
            return AsyncSemaphore._completed;
        }

        const waiter = PromiseUtils.deferStateful<void>();
        this._waiters.enqueue(waiter);
        return waiter;
    }

    public release(): void {
        if (!this._waiters.isEmpty()) {
            this._waiters.dequeue()!.resolve();
        } else {
            ++this._currentCount;
        }
    }
}