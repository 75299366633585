import { IBasicData, IData } from "@utils/AttrMapping";
import { ServiceBase, Result } from "./ServiceBase";

export type GetDataRowsAttribute = { clientName: string | any, serverName: string };

export interface IGetDataRowsParams {
    className: string;
    condition?: string;
    attributes?: GetDataRowsAttribute[];
    startIndex?: number;
    count?: number;
    conditionParams?: any[];
    orderBy?: string[];
    isLoadMore?: boolean;
    abortSignal?: AbortSignal;
}

export interface IGetCountDataRowsParams {
    className: string;
    condition?: string;
    conditionParams?: any[];
    abortSignal?: AbortSignal;
}

export default class DataRowsService extends ServiceBase {
    public async loadData<T extends IData = IBasicData>(params: IGetDataRowsParams): Promise<Result<T[]>> {
        return await this.requestJson<T[]>({
            url: `/api/datarows?className=${params.className}`,
            method: 'POST',
            abortSignal: params.abortSignal,
            data: {
                ...params,
                conditionParams: params.conditionParams?.map(p => p.toString())
            }
        });
    }

    public async getCount(params: IGetCountDataRowsParams): Promise<Result<number>> {
        return await this.requestJson<number>({
            url: `/api/datarows/count?className=${params.className}`,
            method: 'POST',
            abortSignal: params.abortSignal,
            data: {
                ...params,
                conditionParams: params.conditionParams?.map(p => p.toString())
            }
        });
    }

    public async loadDataWithCount<T extends IData = IBasicData>(params: IGetDataRowsParams): Promise<Result<{ collection: T[], count: number }>> {
        return await this.requestJson<{ collection: T[], count: number }>({
            url: `/api/datarows/datawithcount?className=${params.className}`,
            method: 'POST',
            abortSignal: params.abortSignal,
            data: {
                ...params,
                conditionParams: params.conditionParams?.map(p => p.toString())
            }
        });
    }
}