import { AttrMapping, IData } from "@utils/AttrMapping";

export type IParametrPolozkyHistModel = IData & {
    vlastnostHodnota: string;
    vlastnostNazev: string;
    operator: string;
}

export const ParametrPolozkyHistModelAttrMap = new AttrMapping<IParametrPolozkyHistModel>('Parametr_predm_hist', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'operator', serverName: 'Operator', type: 'string' },
    { clientName: 'vlastnostHodnota', serverName: 'Hodnota_vlastnosti', type: 'string' },
    { clientName: 'vlastnostNazev', serverName: 'Nazev_vlastnosti', type: 'string' },
]);
