import { Classes, setupCn } from "@utils/BemUtils";
import React from "react";
import { FC } from "react"

const cn = setupCn('gov-pagination__item');

export type PaginationSeparatorProps = {
    className?: Classes;
}

const PaginationSeparator: FC<PaginationSeparatorProps> = ({ className }) => (
    <span className={cn.with(className).main('--dots')} aria-hidden='true'>...</span>
);

export default React.memo(PaginationSeparator);
