import { BreadcrumbRoute } from "@components/Breadcrumb/BreadcrumbRoute";
import React from "react";
import { Switch, SwitchProps } from "react-router";
import { Page404 } from "@pages/error/Page404";

export const SwitchWith404: React.FC<SwitchProps> = (props) => (
    <Switch {...props}>
        {props.children}
        <BreadcrumbRoute title='404' path='*' brComponent={Page404} />
    </Switch>
);
