import classNames from "classnames";
import { FC } from "react";
import { Classes, setupCn } from "@utils/BemUtils";
import './LoaderOverlay.scss';

const cn = setupCn('loader-overlay');

export type LoaderOverlayProps = {
    className?: Classes;
}

const LoaderOverlay: FC<LoaderOverlayProps> = ({
    children,
    className
}) => (
    <div className={classNames(cn(), className)}>
        {children}
    </div>
)

export default LoaderOverlay;
