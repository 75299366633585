import React, { FC } from "react";
import { match, useRouteMatch } from "react-router";
import { DetailFrame, useDetailFrameContext } from "@components/DetailFrame";
import Tabs from "@components/Tabs";
import { urlConstants } from "@constants/urlConstants";
import { UrlParamObject } from "@utils/QueryHook";
import GroupBox from "@components/GroupBox";
import { DataLabelTyped } from "@components/DataLabel";
import { DNSystemAttrMap, IDNSystemiModel } from "@models/IDNSystemModel";
import { IZpModel, ZpAttrMap } from "@models/IZpModelHist";
import { DependentListFrameTyped } from "@components/ListFrame/DependentListFrame";
import { columnWidths, ListFrameColumns } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";
import { IPolozkyDNSModel, PolozkyDNSAttrMap } from "@models/IPolozkyDNSModel";
import { IDodavateleDNSModel, DodavateleDNSAttrMap } from "@models/IDodavateleDNSModel";
import { DetailDescriptionParams, useDetailDescription } from "@utils/DescriptionHook";
import { ParsedUrlCondition } from "@store/breadcrumbTitleStore";
import { IInfoUverejneniModel, InfoUverejneniAttrMap } from "../../models/IInfoUVerejneniModel";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "../../models/IUverejnenyDokumModel";
import { downloadButtonOnly } from "../../components/Table/Buttons/DownloadAllToolbarButton";

const DataLabel = DataLabelTyped<IDNSystemiModel>();
const DependentListFrame = DependentListFrameTyped<IDNSystemiModel>();

const registrDnsDetailLoadkey = 'detail-dns';

export const parseDnsUrl = (match: match<UrlParamObject> | null) => {
    return (match?.params[urlConstants.registrDnsDetail.detailIdParam] || '');
}

export const prepareDnsCondition = (id: string): ParsedUrlCondition => {
    return {
        condition: 'ID=@0',
        conditionParams: [id]
    }
}

const DetailDNSystem: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const { condition, conditionParams } = prepareDnsCondition(parseDnsUrl(match));

    return (
        <DetailFrame
            condition={condition}
            conditionParams={conditionParams}
            attrMapping={DNSystemAttrMap}
            loadKey={registrDnsDetailLoadkey}
        >
            <Tabs tabs={
                [
                    {
                        lockey: 'NEN-812702',
                        path: urlConstants.registrDnsDetail.path,
                        render: () => <DetailDNS />
                    },
                    {
                        lockey: 'NEN-749348',
                        path: urlConstants.registrDnsPostupy.path,
                        render: () => <PostupyDNS />
                    }
                ]
            } />
        </DetailFrame>
    );
}

const commonDescriptionParts: DetailDescriptionParams<IDNSystemiModel>[] = [
    {
        lockey: 'NEN-812716',
        attrName: 'nazev',
    },
    {
        lockey: 'NEN-812641',
        attrName: 'zadavatelNazev',
    },
    {
        lockey: 'NEN-812597',
        attrName: 'predmet',
    }
]

const DetailDNS: React.FC = () => {
    const { getAttr } = useDetailFrameContext<IDNSystemiModel>();

    const match = useRouteMatch();    

    useDetailDescription<IDNSystemiModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812507'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812507">
                <DataLabel lockey="NEN-812716" attrName='nazev' />
                <DataLabel lockey="NEN-812641" attrName='zadavatelNazev' detailLink={detailLinks.getSubjektDetailUrl(getAttr('zadavatelID')?.value, match, true)} />
                <DataLabel lockey="NEN-812637" attrName='stav' />
                <DataLabel lockey="NEN-812705" attrName='zakazka' detailLink={detailLinks.getZpDetailUrl(getAttr('zakazkaKod')?.value, match, true)} detailLinkShowIf="Exists(Zadavaci_postup_hist, Hist=&VZ and aktualni = true)" />
                <DataLabel lockey="NEN-812638" attrName='datumZavedeni' />
                <DataLabel lockey="NEN-812639" attrName='terminUkonceni' />
                <DataLabel lockey="NEN-812840" attrName='delkaMesic' showIf="Delka_trvani_mesice<>null" />
                <DataLabel lockey="NEN-812841" attrName='delkaRok' showIf="Delka_trvani_roky<>null" />
                <DataLabel lockey="NEN-812640" attrName='pocetDod' />
            </GroupBox>
            <GroupBox lockey="NEN-812597">
                <DataLabel lockey="NEN-812717" attrName='predmet' fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-812642" fillContent>
                <ListFrameKomodity />
            </GroupBox>
            <GroupBox lockey="NEN-825609" fillContent showIf="Pristupnost_dodav = !Pristupnost_dodav.Vsichni" >
                <ListFrameDodavatele />
            </GroupBox>
            <GroupBox lockey="NEN-820438" fillContent showIf="exists(Uverejneny_dokum, (ZP=&VZ or ZP.Hierarchie=&VZ) and Uverejnen=true and (Info_uver.Uverejneni IN['VYZ','JED','NAB','ZP','KD']))">
                <ListFrameZadavaciDokumentace />
            </GroupBox>
            <GroupBox lockey="NEN-820437" fillContent showIf="exists(Info_uverejneni, ZP=&VZ and Uverejneni IN['DODINFO'] and Platny=true)">
                <ListFrameVysvetleniZD />
            </GroupBox>
        </>
    );
}

const komodityModelColumns: ListFrameColumns<IPolozkyDNSModel> = [
    {
        attrName: 'nazevPolozky',
        locKey: 'NEN-812620'
    },
    {
        attrName: 'mnozstvi',
        locKey: 'NEN-812621',
        width: 100
    },
    {
        attrName: 'vlastniJednotka',
        locKey: 'NEN-812882',
        width: 200
    }
];

const ListFrameKomodity: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={registrDnsDetailLoadkey + '-komodity'}
            condition="DNS=@0 and smazano <> true"
            conditionParams={'id'}
            lightVersion
            attrMapping={PolozkyDNSAttrMap}
            paramNames={useStaticObject({ filterParamName: 'kom', routeParamName: urlConstants.registrDnsDetail.urlRouteParam })}
            columns={komodityModelColumns} />
    );
}

const dodavateleModelColumns: ListFrameColumns<IDodavateleDNSModel> = [
    {
        attrName: 'dodNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'dodICO',
        locKey: 'NEN-812665',
        width: 260
    },
];

const ListFrameDodavatele: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={registrDnsDetailLoadkey + '-dodavatele'}
            condition="DNS=@0 and Platny = true"
            conditionParams={'id'}
            lightVersion
            attrMapping={DodavateleDNSAttrMap}
            paramNames={useStaticObject({ filterParamName: 'dod', routeParamName: urlConstants.registrDnsDetail.urlRouteParam })}
            columns={dodavateleModelColumns} />
    );
}

const PostupyDNS: React.FC = () => {
    useDetailDescription<IDNSystemiModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812718'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812718" fillContent>
                <ListFrameZakazky />
            </GroupBox>
        </>
    );
}
const zakazkyModelColumns: ListFrameColumns<IZpModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812625',
        width: columnWidths.zpCode
    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812626'
    },
    {
        attrName: 'stavZP',
        locKey: 'NEN-812605',
        width: 120
    },
    {
        attrName: 'zadavatelNazev',
        locKey: 'NEN-812618'
    },
    {
        attrName: 'podaniLhuta',
        locKey: 'NEN-738562',
        width: 150
    }
];

const ListFrameZakazky: FC = () => {

    return (
        <DependentListFrame
            condition="Hist <> null and Hierarchie = null and ((uver_profil = true and aktualni = true) or (uver_vestnik = true and aktualni = true and not exists(Zadavaci_postup_hist,Hist = &Hist and uver_profil = true and aktualni = true))) and not (Exists(DNSystem, VZ = &Hist)) and Hist|Verejna_zakazka.DNS.Existujici_DNS = @0"
            conditionParams={'id'}
            orderBy={useOrderByMemo<IZpModel>({ desc: true, column: "id" })}
            listFrameKey={registrDnsDetailLoadkey + '-zavedene-zakazky'}
            lightVersion
            attrMapping={ZpAttrMap}
            detailLinkFactory={detailLinks.getLfZpDetailLinkFactory(true)}
            paramNames={useStaticObject({ filterParamName: 'zazak', routeParamName: urlConstants.registrDnsDetail.urlRouteParam })}
            columns={zakazkyModelColumns} />
    );
}
const vysvetleniZDModelColumns: ListFrameColumns<IInfoUverejneniModel> = [
    {
        attrName: 'datumUverejneni',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'predmet',
        locKey: 'NEN-812643'
    }
];

const ListFrameVysvetleniZD: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={registrDnsDetailLoadkey + '-vysvetleniZD'}
            condition="ZP = @0 and Uverejneni='DODINFO' and Platny=true"
            conditionParams={['vz']}
            orderBy='datumUverejneni'
            lightVersion
            attrMapping={InfoUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'vysvZD', routeParamName: urlConstants.registrDnsDetail.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailDodInfoLinkFactory()}
            columns={vysvetleniZDModelColumns} />
    );
}
const zaddokumDNSModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];
const ListFrameZadavaciDokumentace: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={registrDnsDetailLoadkey + '-zadavaci-dokum-dns'}
            condition="Info_uver.Uverejneni IN['VYZ','JED','NAB','ZP','KD'] and Uverejnen=true and (Info_uver.ZP=@0 or Info_uver.ZP.Hierarchie=@0) and Polozka=null"
            conditionParams={['vz']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'zdDNS', routeParamName: urlConstants.registrDnsDetail.urlRouteParam })}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            columns={zaddokumDNSModelColumns}
            extraButtons={downloadButtonOnly}
        />
    );
}

export default DetailDNSystem;