import { DependencyList, useEffect, useMemo, useRef, useState } from "react";

export const useArrayMemo = <T extends number | string | Date | bigint | null | undefined | boolean>(array: T[]): T[] => useMemo(() => array, [array.join('&;&')]);
export const useNewArrayMemo = <T extends number | string | Date | bigint | null | undefined | boolean>(...array: T[]): T[] => useMemo(() => array, [array.join('&;&')]);

export const useStaticObject = <T>(obj: T): T => useRef<T>(obj).current;
export const useNewStaticArray = <T>(...array: T[]): T[] => useRef<T[]>(array).current;

const usePrevious = (value: any, initialValue: any) => {
    const ref = useRef(initialValue);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export const useEffectDebugger = (effectHook: React.EffectCallback, dependencies: React.DependencyList) => {
    const previousDeps = usePrevious(dependencies, []);

    const changedDeps = dependencies.reduce((accum, dependency, index) => {
        if (dependency !== previousDeps[index]) {
            const keyName = `Dep on index ${index}`;
            return {
                ...accum,
                [keyName]: {
                    before: previousDeps[index],
                    after: dependency
                }
            };
        }

        return accum;
    }, {});

    if (Object.keys(changedDeps).length) {
        console.log('[use-effect-debugger] ', changedDeps);
    }

    useEffect(effectHook, dependencies);
};

export const useScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
}

export type UseAsyncValueParams<T> = {
    defaultValue?: T;
    deps?: DependencyList;
}

export const useAsyncValue = <T extends unknown>(getValue: () => Promise<T>, { defaultValue, deps }: UseAsyncValueParams<T> = {}): T | undefined => {
    const [value, setValue] = useState<T | undefined>(defaultValue);

    useEffect(() => {
        let cancel = false;
        getValue().then(result => {
            if (!cancel) {
                setValue(result);
            }
        })
        return () => { cancel = true; }
    }, deps ?? []);

    return value;
}

export const useAsyncValueWithState = <T extends unknown>(getValue: () => Promise<T>, { defaultValue, deps }: UseAsyncValueParams<T> = {}): { value: T | undefined, isLoaded: boolean } => {
    const [value, setValue] = useState<{
        value: T | undefined;
        isLoaded: boolean;
    }>(() => ({
        value: defaultValue,
        isLoaded: false
    }));

    useEffect(() => {
        let cancel = false;
        getValue().then(result => {
            if (!cancel) {
                setValue({
                    value: result,
                    isLoaded: true
                });
            }
        })
        return () => { cancel = true; }
    }, deps ?? []);

    return value;
}