import React from 'react'
import GovLayout from '@components/GovLayout/GovLayout';
import { useLocalization } from '@store/localizationStore'
import { usePageTitle } from '@utils/PageTitleHook'
import ContentBlock from '@components/ContentBlock';
import UniversalLink from '@components/UniversalLink/UniversalLink';
import offsets from '@styles/offsets';
import { MdOutlineHandyman } from 'react-icons/md';
import Icon from '@components/Icon';
import { useScrollToTop } from '@utils/ReactUtils';

const verzeProOdstavku = () => {
    const { ts, t } = useLocalization();

    useScrollToTop();

    usePageTitle(t('NEN-768774'));

    return (
        <GovLayout
            ratio='1-2'
            spreadRight={3}
            leftCol={
                <div>
                    <h2 className="gov-title--alpha">{ts('NEN-768774')}</h2>
                    <Icon icon={MdOutlineHandyman} size="32" />
                </div>
            }
            rightCol={
                <>
                    <ContentBlock>
                        <p className={offsets['u-mb--25']}>
                            {ts('NEN-768775')}
                        </p>
                        <p>
                            {ts('NEN-768810')}
                        </p>
                    </ContentBlock>

                    <ContentBlock>
                        <p>
                            {ts('NEN-754476')}
                            <UniversalLink to="mailto:hotline@nipez.cz" title="hotline@nipez.cz" isExternal sameTarget>
                                hotline@nipez.cz
                            </UniversalLink>
                            {ts('NEN-754478')}
                            <UniversalLink to="https://nen.nipez.cz/servicedesk/" title="ServiceDesk" isExternal>
                                ServiceDesk
                            </UniversalLink>
                        </p>
                    </ContentBlock>
                </>
            }
        />
    )
};

export default React.memo(verzeProOdstavku);
