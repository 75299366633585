import React from "react";
import { FC, ReactNode } from "react";
import { setupCn } from "@utils/BemUtils";

const cn = setupCn('gov-layout');
const colCn = cn.setupSubCn('__col');

export type GovLayoutRatio = '3-1' | '1-2';
export type GovLayoutSpread = 1 | '1' | 2 | '2' | 3 | '3';

export type GovLayoutProps = {
    leftCol?: ReactNode;
    rightCol?: ReactNode;
    ratio?: GovLayoutRatio;
    spreadLeft?: GovLayoutSpread;
    spreadRight?: GovLayoutSpread;
}

const GovLayout: FC<GovLayoutProps> = ({
    leftCol,
    rightCol,
    ratio,
    spreadLeft,
    spreadRight,
}) => {
    const ratioModifier = ratio ? `--ratio-${ratio}` : null;
    const spreadLeftModifier = spreadLeft != null ? `--spread-${spreadLeft}` : null;
    const spreadRightModifier = spreadRight != null ? `--spread-${spreadRight}` : null;

    return (
        <div className={cn.main(ratioModifier)}>
            <div className={colCn.main("--pos-left", spreadLeftModifier)}>
                {leftCol}
            </div>
            <div className={colCn.main("--pos-right", spreadRightModifier)}>
                {rightCol}
            </div>
        </div>
    );
}

GovLayout.displayName = 'GovLayout';

export default React.memo(GovLayout);