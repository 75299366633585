import React, { FC, PropsWithChildren, useMemo } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { WhispererItem } from "@components/WhispererInput";
import Button from "@components/Button/Button";
import { useLocalization } from "@store/localizationStore";
import { stopPropagation } from "@utils/EventUtils";

const cn = setupCn('gov-multiselect');

export type MultiselectTagsProps = PropsWithChildren<{
    className?: Classes;
    items?: WhispererItem[];
    onRemove?: (item: WhispererItem) => void;
}>;

const MultiselectTags: FC<MultiselectTagsProps> = ({
    items,
    onRemove,
    className
}) => {
    const { t } = useLocalization();
    const renderedItems = useMemo(() => items?.map(item => (
        <li className={cn('__tag')} multiselect-id={item.id} key={item.id}>
            <span>{item.text}</span>
            <Button onClick={() => onRemove?.(item)} isFlat aria-label={t('NEN-765694')} hasIconOnly />
        </li>
    )), [items, onRemove]);

    return (
        <ul className={cn.with(className)('__tags')} onClick={stopPropagation}>
            {renderedItems}
        </ul>
    );
};

MultiselectTags.displayName = 'MultiselectTags';

export default React.memo(MultiselectTags);
