/// <reference path="../declarations/TescoSW.Crypto.LightWeb.ES5.d.ts"/>
import { ISignatureDataStream, ISignGroup } from "MultiWebClient/MWCore/TescoSW/Cryptography/Crypto/CryptoInterfaces"
import { detect } from 'detect-browser';
import { TestResult } from "@components/CompatibilityTest/CompatibilityTests"
import { ISignatureProviderProfile } from "MultiWebClient/MWCore/TescoSW/Global/Base/Crypto/ISignatureProvider"
import CryptoService from "services/CryptoService"
import { ISignatureProviderSettings } from "./interface/ISignatureProvider"
import { PromiseUtils } from "@utils/PromiseUtils";

type GetCryptoNativeLanguageProps = {
    language?: string;
}

const getCryptoNativeLanguage = ({ language }: GetCryptoNativeLanguageProps) => {
    switch (language?.toLowerCase()) {
        case 'en':
            return 'en';
        case 'cz':
        case 'cs':
        default:
            return 'cs';
    }
}

type CreateStringStreamProps = {
    referenceUri: string
    objectIdentity: string
    text: string
}

const createStringStream = ({ referenceUri, objectIdentity, text }: CreateStringStreamProps): ISignatureDataStream => {
    return {
        read: clbk => clbk(new TextEncoder().encode(text), true),
        getName: () => Promise.resolve(objectIdentity),
        getMetadata: () => Promise.resolve(null),
        getReferenceUri: () => Promise.resolve(referenceUri)
    }
}

type CreateSignGroupProps = {
    text: string
    referenceUri: string
    objectIdentity: string
    provider: ISignatureProviderSettings
    providerId: string
}

const createSignGroup = ({ text, referenceUri, objectIdentity, provider, providerId }: CreateSignGroupProps): ISignGroup => {
    return {
        providerId,
        provider,
        inputStreams: [{
            signingStream: createStringStream({ referenceUri, objectIdentity, text }),
            displayStream: createStringStream({ referenceUri, objectIdentity, text })
        }]
    }
}

export type SigningSessionType = typeof import('multiwebclient/mwcore/tescosw/cryptography/crypto/signingsession').SigningSession;
export type SigningExtensionCommunicatorType = typeof import('MultiWebClient/MWCore/TescoSW/Cryptography/Crypto/SigningExtensionCommunicator').SigningExtensionCommunicator;
export type DataConverterType = typeof import('MultiWebClient/MWCore/TescoSW/Global/Base/Converters/DataConverter').DataConverter;
export type NativeAppStreamReaderType = typeof import('MultiWebClient/MWCore/TescoSW/Cryptography/Crypto/NativeAppStreamReader').NativeAppStreamReader;
export type SigningActiveXCommunicatorType = typeof import('LightWebClient/TescoSW/Cryptography/SigningActiveXCommunicator').SigningActiveXCommunicator;

export type SigningSessionInstance = SigningSessionType['prototype'];
export type SigningExtensionCommunicatorInstance = SigningExtensionCommunicatorType['prototype'];
export type DataConverterInstance = DataConverterType['prototype'];
export type NativeAppStreamReaderInstance = NativeAppStreamReaderType['prototype'];
export type SigningActiveXCommunicatorInstance = SigningActiveXCommunicatorType['prototype'];

type CryptoComponentsType = {
    SigningSession: SigningSessionType;
    SigningExtensionCommunicator: SigningExtensionCommunicatorType;
    DataConverter: DataConverterType;
    NativeAppStreamReader: NativeAppStreamReaderType;
    SigningActiveXCommunicator: SigningActiveXCommunicatorType;
}

let cryptoComponentsPromise: Promise<CryptoComponentsType>;
const getCryptoComponents = () => cryptoComponentsPromise ??= new Promise((resolve, reject) => {
    try {
        requirejs([
            'multiwebclient/mwcore/tescosw/cryptography/crypto/signingsession',
            'MultiWebClient/MWCore/TescoSW/Cryptography/Crypto/SigningExtensionCommunicator',
            'MultiWebClient/MWCore/TescoSW/Global/Base/Converters/DataConverter',
            'MultiWebClient/MWCore/TescoSW/Cryptography/Crypto/NativeAppStreamReader',
            'LightWebClient/TescoSW/Cryptography/SigningActiveXCommunicator'
        ], (
            { SigningSession }: { SigningSession: CryptoComponentsType['SigningSession'] },
            { SigningExtensionCommunicator }: { SigningExtensionCommunicator: CryptoComponentsType['SigningExtensionCommunicator'] },
            { DataConverter }: { DataConverter: CryptoComponentsType['DataConverter'] },
            { NativeAppStreamReader }: { NativeAppStreamReader: CryptoComponentsType['NativeAppStreamReader'] },
            { SigningActiveXCommunicator }: { SigningActiveXCommunicator: CryptoComponentsType['SigningActiveXCommunicator'] }
        ) => resolve({
            SigningSession,
            SigningExtensionCommunicator,
            DataConverter,
            NativeAppStreamReader,
            SigningActiveXCommunicator
        }), reject);
    } catch (e) {
        reject(e);
    }
});

type CryptoErrorsModuleType = typeof import('MultiWebClient/MWCore/TescoSW/Cryptography/Crypto/CryptoExceptions');
let cryptoErrorsromise: Promise<CryptoErrorsModuleType>;
const getCryptoErrors = () => cryptoErrorsromise ??= new Promise((resolve, reject) => {
    try {
        requirejs([
            'MultiWebClient/MWCore/TescoSW/Cryptography/Crypto/CryptoExceptions'
        ], (
            CryptoExceptions: CryptoErrorsModuleType,
        ) => resolve(CryptoExceptions), reject);
    } catch (e) {
        reject(e);
    }
});

const isIE = () => detect()?.name == 'ie';

let loadIEPolyfillsPromise: Promise<void>;
export const loadIEPolyfills = () => loadIEPolyfillsPromise ?? new Promise<void>((resolve, reject) => {
    try {
        requirejs([
            'static/js/polyfills/polyfill-textencoding',
            'static/js/polyfills/base64',
            'static/js/polyfills/customEvent',
        ], () => resolve(), reject);
    } catch (e) {
        reject(e);
    }
})

const loadPolyfillForIE = async () => {
    if (!isIE()) {
        return;
    }
    await loadIEPolyfills();
    if (document.querySelector("meta[name='MultiWebCryptoExtension']") == null) {
        const meta = document.createElement('meta');
        meta.name = 'MultiWebCryptoExtension';
        document.head.appendChild(meta);
    }
}

type CreateSigningSessionProps = {
    language?: string
}

export const createSigningSession = async ({ language }: CreateSigningSessionProps) => {
    console.debug('[SigningSession] create');
    const [{
        SigningExtensionCommunicator,
        SigningSession,
        SigningActiveXCommunicator
    }] = await Promise.all([getCryptoComponents(), loadPolyfillForIE()]);
    const communicator = isIE() ? new SigningActiveXCommunicator() : new SigningExtensionCommunicator();
    const signingSession = new SigningSession(communicator);
    await signingSession.open(getCryptoNativeLanguage({ language }));
    console.debug('[SigningSession] created');
    return signingSession;
}

type CryptoNativeVersionInfo = {
    extension: string;
    nativeComponent: string;
}

export type CryptoComponentDownloadUrl = {
    downloadUrl?: (lang: string) => string;
    linkTarget?: string;
}

export type CryptoComponentInfo = CryptoComponentDownloadUrl & {
    version?: string;
    requiredVersion?: string;
    result: TestResult;
}

export type CryptoNativeCheckResult = {
    result: TestResult;
    messageLocKey?: string;
    nativeApp: CryptoComponentInfo;
    extension: CryptoComponentInfo;
}

const getOKResult = (versions?: CryptoNativeVersionInfo): CryptoNativeCheckResult => ({
    result: TestResult.ok,
    nativeApp: {
        result: TestResult.ok,
        version: versions?.nativeComponent
    },
    extension: {
        result: TestResult.ok,
        version: versions?.extension
    }
});

const defaultCryproNativeUpdateUrl = 'https://download.tescosw.cz/crypto/?lang={lang}';
const isDefaultCryptoNativeUrl = (url?: string): boolean => url?.startsWith('https://download.tescosw.cz/crypto/?lang=') ?? false;

type GetCryptoDownloadUrlProps = {
    downloadUrl?: string;
}

const getCryptoDownloadUrl = ({ downloadUrl }: GetCryptoDownloadUrlProps): CryptoComponentDownloadUrl => {
    if (downloadUrl == null || isDefaultCryptoNativeUrl(downloadUrl)) {
        return {
            downloadUrl: language => defaultCryproNativeUpdateUrl.replace('{lang}', getCryptoNativeLanguage({ language })),
            linkTarget: '_blank'
        };
    }
    return {
        downloadUrl: () => downloadUrl
    }
}

type GetCryptoErrorInfoProps = {
    error: Error
    versions?: CryptoNativeVersionInfo
}

export const getCryptoErrorInfo = async ({ error, versions }: GetCryptoErrorInfoProps): Promise<CryptoNativeCheckResult> => {
    const {
        CryptoExtensionUnavailableException,
        EInsufficientVersionException,
        //NativeAppBulkException,
        //NativeAppException,
        NativeAppExitedException,
        //NativeAppSigningException,
        NativeAppUnavailableException,
        ProtectedModeException
    } = await getCryptoErrors();
    const result: CryptoNativeCheckResult = {
        result: TestResult.failed,
        nativeApp: {
            result: TestResult.notEvaluated
        },
        extension: {
            result: TestResult.notEvaluated
        }
    }

    if (error instanceof CryptoExtensionUnavailableException) {
        return {
            ...result,
            extension: {
                result: TestResult.failed,
                ...getCryptoDownloadUrl({ downloadUrl: error.downloadURL })
            },
            nativeApp: {
                result: TestResult.nonCheckable
            }
        }
    }

    if (error instanceof NativeAppUnavailableException) {
        return {
            ...result,
            extension: {
                result: TestResult.ok
            },
            nativeApp: {
                result: TestResult.failed,
                ...getCryptoDownloadUrl({ downloadUrl: error.downloadURL })
            }
        }
    }

    if (error instanceof ProtectedModeException) {
        return {
            ...result,
            messageLocKey: 'NEN-746900'
        }
    }

    if (error instanceof EInsufficientVersionException) {
        return {
            ...result,
            nativeApp: {
                result: error.nativeAppVersionSufficient ? TestResult.failed : TestResult.ok,
                version: error.installedNativeAppVersion ?? versions?.nativeComponent,
                requiredVersion: error.requiredNativeAppVersion
            },
            extension: {
                result: error.extensionVersionSufficient ? TestResult.failed : TestResult.ok,
                version: error.installedExtensionVersion ?? versions?.extension,
                requiredVersion: error.requiredExtensionVersion
            },
            messageLocKey:
                error.nativeAppVersionSufficient && error.extensionVersionSufficient ? 'NEN-746903' :
                    error.nativeAppVersionSufficient ? 'NEN-746901' :
                        error.extensionVersionSufficient ? 'NEN-746902' : undefined
        }
    }

    if (error instanceof NativeAppExitedException) {
        return getOKResult();
    }

    console.error(error);
    return result;
}


export type CryptoNativeCheckProps = {
    language?: string;
}

const isCryptoExtensionAttached = () => Boolean(document.querySelector("meta[name='MultiWebCryptoExtension']"));

const waitForCryptoExtensionInit = async (): Promise<boolean> => {
    // počká 6s na inicializaci CryptoWebExtension
    for (let attemps = 30; attemps; attemps--) {
        if (isCryptoExtensionAttached()) {
            return true;
        }

        await PromiseUtils.delay(200);
    }

    return isCryptoExtensionAttached();
}

export const cryptoNativeCheck = async ({ language }: CryptoNativeCheckProps): Promise<CryptoNativeCheckResult> => {
    let signingSession: SigningSessionInstance | null = null;
    let versions: CryptoNativeVersionInfo | undefined;
    try {
        await waitForCryptoExtensionInit();
        signingSession = await createSigningSession({ language });
        versions = await signingSession['_getVersionInfo']().catch(() => undefined);
        await signingSession['_checkRequiredVersions'](); //  kontrola verzi crypto extension a app
        return getOKResult(versions);
    } catch (e) {
        return getCryptoErrorInfo({ error: e, versions });
    }
    finally {
        signingSession?.dispose();
    }
};

type SignStringProps = {
    signatureData: string
    referenceUri: string
    objectIdentity: string
    profile: ISignatureProviderProfile
    language?: string
}

export const signString = async ({ signatureData, referenceUri, objectIdentity, profile, language }: SignStringProps) => {
    if (profile?.settings == null) {
        return null;
    }
    const {
        DataConverter,
        NativeAppStreamReader
    } = await getCryptoComponents();
    let signingSession: SigningSessionInstance | null = null;
    try {
        signingSession = await createSigningSession({ language });
        console.log('[SigningSession] create signautre');
        const result = await signingSession.createTextSignatures(createSignGroup({
            text: signatureData,
            referenceUri,
            objectIdentity,
            provider: profile.settings,
            providerId: profile.id
        }));
        console.log('[SigningSession] signature created');
        const signedInfo = result[0];
        console.log(result);
        const signatures = await Promise.all(signedInfo.infos.map(async si => si.stream && DataConverter.bufferToBase64(await new NativeAppStreamReader(si.stream).read())));
        return signatures[0];
    } finally {
        console.log('[SigningSession] disposed');
        signingSession?.dispose();
    }
}

export type SignObjectParams = {
    instanceID: string;
    cryptoService: CryptoService;
    language?: string;
}

export const signObject = async ({ instanceID, cryptoService, language }: SignObjectParams): Promise<boolean> => {
    if (!instanceID) {
        return false;
    }
    const profile = await cryptoService.getDigitalSignatureSettings({ instanceID });
    if (!profile?.settings) {
        return false;
    }
    const dataForSign = await cryptoService.getDataForSign({ instanceID });
    if (!dataForSign) {
        return false;
    }
    const signature = await signString({ signatureData: dataForSign.signatureData, referenceUri: dataForSign.referenceUri, objectIdentity: dataForSign.objectIdentity, profile, language });
    if (!signature) {
        return false;
    }
    return await cryptoService.sendSignature([{
        profileId: profile.id,
        providerType: profile.settings.providerType,
        providerVersion: profile.settings.providerVersion,
        signatures: [{
            instanceID,
            signature,
            signedData: dataForSign.signatureData
        }]
    }]);
}
