import { MaybeArray, toArray } from "@utils/ArrayHelper";
import { useMemo } from "react";
import { TableColumn } from "../components/Table";
import { AttrMapping, AttrName } from "./AttrMapping";

export type OrderByObject<T extends string = string> = { desc?: boolean, column: T };
export type OrderBy<T extends string = string> = OrderByObject<T> | T;

export const useOrderByMemo = <T extends {}>(...array: OrderBy<AttrName<T>>[]): OrderBy<AttrName<T>>[] => {
    const hash = array.map(orderby => {
        if (typeof orderby == 'object') {
            return `${orderby.desc}-${orderby.column}`;
        }

        return orderby;
    }).join("&;&");

    return useMemo(() => array, [hash]);
}

const linguisticTypesRegex = /strnum|text|string/i;

export const createOrderBy = (orderBy: MaybeArray<OrderBy>, attrMapping: AttrMapping): string[] =>
    toArray(orderBy).map(order => {
        let column = '', desc = false, orderByPart = '';
        if (typeof order == 'string') {
            column = order;
        } else {
            column = order.column;
            desc = order.desc ?? false;
        }

        orderByPart = desc ? 'DESC ' : '';
        orderByPart += attrMapping.getByClientName(column)?.type.match(linguisticTypesRegex) ? 'LINGUISTIC ' : '';

        return orderByPart + attrMapping.toServerName(column);
    });


export const getOrderByColumnSort = (orderBy: MaybeArray<OrderBy> | undefined, column: TableColumn<any>): string => {
    if (!orderBy)
        return '';

    if (Array.isArray(orderBy)) {
        const orderByColumn = orderBy.find(orderByPart => {
            return ((typeof orderByPart === 'object' && orderByPart.column == column.attrName) || (orderByPart == column.attrName))
        })

        if (orderByColumn && typeof orderByColumn === 'object') {
            return orderByColumn.desc ? 'desc' : 'asc';
        }
    }
    return '';
}

export const orderByStringToObject = (column: string | OrderBy): OrderByObject => {
    if (typeof column === 'string')
        return { desc: false, column: column };

    return column;
}