import React, { FC, FormEventHandler, PropsWithChildren, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import FilterInput from "@components/FilterInput/FilterInput";
import Button from "@components/Button/Button";
import { tryChangeLanguage, getAppUrl, getLoginInfo, initCheckLoginInterval, login, LoginInfo, LoginResult, loginWithToken, logout, redirectToApp, useMwServiceWorker } from "@utils/LoginUtils";
import { useCurrentLanguage, actionCreators, useLocalization } from "@store/localizationStore";
import { renderIf } from "@utils/RenderUtils";
import ButtonLink from "@components/Button/ButtonLink";
import { useDispatch } from "react-redux";
import { FilterManagerChangeEventHandler } from "@components/FilterManager";
import Blockquote from "@components/Blockquote";
import colors from "@styles/colors";
import { postWindowMessage, useWindowMessage } from "@utils/WindowMessageUtils";
import Icon from "@components/Icon";
import './LoginForm.scss';
import offsets from "@styles/offsets";
import { useLocation } from "react-router";

const cn = setupCn('login-form');

export type LoginFormProps = PropsWithChildren<{
    className?: Classes;
}>;

const LoginForm: FC<LoginFormProps> = ({
    className,
}) => {
    const { ts } = useLocalization();
    const { swLoaded } = useMwServiceWorker();
    const [loginStatus, setLoginStatus] = useState<LoginInfo | undefined>();
    const [loginResult, setLoginResult] = useState<LoginResult | undefined>();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const location = useLocation();
    const userNameInputRef = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const language = useCurrentLanguage();

    const portalUrl = getAppUrl(location.search, true);

    const updateLoginInfo = useCallback(async () => {
        setLoginStatus(await getLoginInfo());
    }, []);

    const checkAdminLoginLanguage = useCallback(async (language: string) => {
        if (!loginResult && !loginStatus?.isLogged) {
            return;
        }

        const loginLangLocKey = await tryChangeLanguage(language);
        if (loginLangLocKey !== loginResult?.afterLoginConfirmLocKey) {
            setLoginResult({
                ...(loginResult ?? { isSucceful: loginStatus?.isLogged ?? false }),
                afterLoginConfirmLocKey: loginLangLocKey
            });
        }
    }, [loginResult, loginStatus]);

    const handleUserNameChange = useCallback<FilterManagerChangeEventHandler>((_, value) => setUserName(value),
        []);

    const handlePasswordChange = useCallback<FilterManagerChangeEventHandler>((_, value) => setPassword(value),
        []);

    useEffect(() => {
        if (loginResult?.afterLoginConfirmLocKey && !loginStatus?.isLogged) {
            setLoginResult(undefined);
        }
        checkAdminLoginLanguage(language);
    }, [loginStatus]);

    useEffect(() => {
        updateLoginInfo();
        const stopIntervalPromise = initCheckLoginInterval(updateLoginInfo);
        return () => {
            stopIntervalPromise.then(stopInterval => stopInterval());
        };
    }, [updateLoginInfo]);

    useWindowMessage('changeLanguage', (payload) =>
        dispatch(actionCreators.changeLanguage(payload.language)));

    useEffect(() => {
        postWindowMessage(parent, 'requestInitLang', {});
    }, []);

    useLayoutEffect(() => {
        const notify = () => postWindowMessage(parent, 'iframeResized', { height: document.body.scrollHeight });
        const ro = new ResizeObserver(notify);
        notify();
        ro.observe(document.body);
        return () => {
            ro.disconnect();
        }
    }, []);

    useEffect(() => {
        checkAdminLoginLanguage(language);
    }, [language]);

    useEffect(() => {
        postWindowMessage(parent, 'loginStatus', { isLogged: loginStatus?.isLogged });
    }, [loginStatus]);


    const checkLoginResult = useCallback((result: LoginResult) => {
        if (result.isSucceful && !result.afterLoginConfirmLocKey) {
            redirectToApp();
            return;
        }
        if (result.errorCode && ['InvalidCredentials', 'UserNotFound'].includes(result.errorCode)) {
            userNameInputRef.current?.focus();
            userNameInputRef.current?.setSelectionRange(0, -1)
        }
        setLoginResult(result);
    }, [])

    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    useEffect(() => {
        if (!token) {
            return;
        }

        loginWithToken(token).then(result => checkLoginResult(result))
    }, [token, checkLoginResult]);


    const handleLogin = useCallback<FormEventHandler>(event => {
        event.preventDefault();

        if (loginResult?.isInProgress) {
            return;
        }

        setLoginResult({ isSucceful: false, isInProgress: true });
        login({ userName, password, language })
            .then(result => checkLoginResult(result))
            .catch((error: Error) => {
                setLoginResult({
                    isSucceful: false,
                    errorMessage: error.message
                });
            });
    }, [userName, password, language, loginResult?.isInProgress, checkLoginResult]);

    const handleLogout = useCallback<FormEventHandler>(() => {
        setLoginStatus(undefined);
        logout().then(updateLoginInfo);
    }, [updateLoginInfo]);

    const render = (content: ReactNode) => (
        <div className={cn.with(className).main()}>
            {content}
        </div>
    );

    if (loginStatus == null || !swLoaded) {
        return render(
            <h2 className={offsets["u-mb--unset"]}>
                {ts('NEN-749989')}
            </h2>
        );
    }

    if (loginStatus.isLogged) {
        return render(<>
            <h2>
                {ts('NEN-749988')}{' '}
                {loginStatus.userName}
            </h2>
            {renderIf(loginStatus.name,
                <p>
                    <strong>{loginStatus.name}</strong>
                </p>

            )}
            {loginResult?.afterLoginConfirmLocKey &&
                <Blockquote type='information'>{ts(loginResult.afterLoginConfirmLocKey)}</Blockquote>
            }
            <div>
                <Button isOutlined onClick={handleLogout}>{ts('NEN-749991')}</Button>{' '}
                <ButtonLink to={portalUrl} target='_top'>
                    {ts('NEN-749990', { isPrimaryTheme: true })}
                    <Icon iconName="arrow-on" />
                </ButtonLink>
            </div>
        </>);
    }

    let errorMessage: ReactNode = loginResult?.errorMessage;
    if (loginResult?.errorLocKey) {
        errorMessage = ts(loginResult?.errorLocKey);
    }

    return render(
        <form method='post' onSubmit={handleLogin}>
            {renderIf(errorMessage,
                <p className={colors.error}>{errorMessage}</p>
            )}
            <FilterInput
                headerText={ts('NEN-749300')}
                inputRef={userNameInputRef}
                id='username'
                name='username'
                autoComplete='username'
                value={userName}
                onChange={handleUserNameChange}
                defaultBehaviorUnset />
            <FilterInput
                headerText={ts('NEN-749301')}
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                onChange={handlePasswordChange}
                defaultBehaviorUnset />
            <Button type='submit' disabled={loginResult?.isInProgress}>
                {ts('NEN-749299', { isPrimaryTheme: true })}
            </Button>
        </form>
    );
};

LoginForm.displayName = 'LoginForm';

export default React.memo(LoginForm);
