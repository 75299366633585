import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { default as FilterManagerBase, FilterManagerType } from "@components/FilterManager";
import { useLocalization } from "@store/localizationStore";
import { DruhZadavacihoRizeniAttrMap } from "@models/enumerators/IDruhZadavacihoRizeni";
import { NUTSModelAttrMap } from "@models/enumerators/INUTSModel";
import { NIPEZModelAttrMap } from "@models/enumerators/INIPEZModel";
import { CPVModelAttrMap } from "@models/enumerators/ICPVModel";
import { FilterParamsNames, urlConstants } from "@constants/urlConstants";
import { createTransposeOrder } from "@components/EnumChecks/EnumChecks";
import { Druh_VZEnum } from "@models/staticEnumerators/Druh_VZEnum";
import { Typ_VZEnum } from "@models/staticEnumerators/Typ_VZEnum";
import { HlavniPredmetCinnostiAttrMap } from "@models/enumerators/IHlavniPredmetCinnostiModel";
import { PravniFormaAttrMap } from "@models/enumerators/IPravniFormaModel";
import { Stav_postupuEnum } from "@models/staticEnumerators/Stav_postupuEnum";
import { IZpModel } from "@models/IZpModelHist";
import { renderIf } from "@utils/RenderUtils";
import { useNewArrayMemo } from "@utils/ReactUtils";
import { kodNazevAttrs, lovCPVConditionFactory, lovNIPEZConditionFactory } from "@models/filters/NipezFilters";

const cn = setupCn('verejne-zakazky-filter');

export type VerejneZakazkyFilterProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    paramNames?: FilterParamsNames;
    hideActualState?: boolean;
    actualStateHiddenValues?: Stav_postupuEnum[];
}>;

const FilterManager = FilterManagerBase as FilterManagerType<keyof IZpModel>;

const druhVZHiddenValues: Druh_VZEnum[] = ['Sluz', 'Staveb', 'Dodav'];
const druhVZOrder = createTransposeOrder<Druh_VZEnum>('Dodavky', 'Sluzby', 'Stavebni_prace', 'Koncese_na_sluzby', 'Koncese_na_stavebni_prace');
const typVZOrder = createTransposeOrder<Typ_VZEnum>('Maly_rozsah', 'Podlimitni', 'Podlimitni_mimo_ZVZ', 'Nadlimitni', 'Podlimitni_SoN', 'Nadlimitni_Son', 'Koncese_maleho_rozsahu', 'Koncese');
const stavZPHiddenValues: Stav_postupuEnum[] = ['ukonceno_ve_spec'];
const stavZPOrder = createTransposeOrder<Stav_postupuEnum>('planovana', 'neukoncena', 'zadana', 'plneni', 'zrusena');

const lovNipezConditonFactoryFunction = lovNIPEZConditionFactory('cpvPredmetu');
const lovCPVConditonFactoryFunction = lovCPVConditionFactory('nipezPredmetu');

const VerejneZakazkyFilter: FC<VerejneZakazkyFilterProps> = ({
    id,
    className,
    paramNames,
    hideActualState,
    actualStateHiddenValues
}) => {
    const { ts } = useLocalization();
    const actualStateHiddenValuesMemo = useNewArrayMemo(...stavZPHiddenValues, ...actualStateHiddenValues ?? []);
    return (
        <FilterManager
            id={id}
            className={cn.with(className)()}
            paramNames={paramNames ?? urlConstants.verejneZakazky.paramNames}>
            <FilterManager.GroupsContainer id='groups' size='small'>
                <FilterManager.Group id='general' header={ts('NEN-812859')} defaultVisible noTopBorder>
                    <FilterManager.Row>
                        <FilterManager.Input name='nazev' headerText={ts('NEN-742282')} />
                        <FilterManager.Lov
                            headerText={ts('NEN-812690')}
                            maxItems={null}
                            name='druhZR'
                            attrMapping={DruhZadavacihoRizeniAttrMap}
                            showAttrs='nazev' />
                        <FilterManager.Lov
                            headerText={ts('NEN-743013')}
                            name='mistoNUTS'
                            attrMapping={NUTSModelAttrMap}
                            showAttrs={kodNazevAttrs}
                            condition='(Plati_od=NULL OR Plati_od <= DB.getDateTime()) AND (Plati_do=NULL OR Plati_do >= DB.getDateTime())' />
                    </FilterManager.Row>
                    <FilterManager.Enum name='druhVZ' headerText={ts('NEN-812860')} enumName='Druh_VZ' hiddenValues={druhVZHiddenValues} order={druhVZOrder} />
                    <FilterManager.Enum name='typVZ' headerText={ts('NEN-812689')} enumName='Typ_VZ' order={typVZOrder} />
                    {renderIf(!hideActualState, () =>
                        <FilterManager.Enum name='stavZP' headerText={ts('NEN-751267')} enumName='Stav_postupu' hiddenValues={actualStateHiddenValuesMemo} order={stavZPOrder} />
                    )}                    
                    <FilterManager.Number name='predpokladHodnotaCZK' headerText={ts('NEN-742286')} />
                    <FilterManager.Date name='podaniLhuta' headerText={ts('NEN-742285')} />
                    <FilterManager.Date name='datumPrvniUver' headerText={ts('NEN-794420')} />
                    <FilterManager.Date name='datumPosledniUver' headerText={ts('NEN-794423')} />
                </FilterManager.Group>
                <FilterManager.Group id='item' header={ts('NEN-743014')}>
                    <FilterManager.Row>
                        <FilterManager.Lov
                            headerText={ts('NEN-743015')}
                            name='nipezPredmetu'
                            attrMapping={NIPEZModelAttrMap}
                            showAttrs={kodNazevAttrs}
                            condition={lovNipezConditonFactoryFunction} />
                        <FilterManager.Lov
                            headerText={ts('NEN-743016')}
                            name='cpvPredmetu'
                            attrMapping={CPVModelAttrMap}
                            showAttrs={kodNazevAttrs}
                            condition={lovCPVConditonFactoryFunction} />
                        <FilterManager.Input name='mistoPlneni' headerText={ts('NEN-743017')} />
                    </FilterManager.Row>
                    <FilterManager.Row>
                        <FilterManager.Input name='popisPredmet' headerText={ts('NEN-751268')} />
                    </FilterManager.Row>
                </FilterManager.Group>
                <FilterManager.Group id='contractor' header={ts('NEN-751972')}>
                    <FilterManager.Row>
                        <FilterManager.Input name='dodavatelNazev' headerText={ts('NEN-812861')} />
                        <FilterManager.Input name='dodavatelICO' headerText={ts('NEN-812693')} />
                    </FilterManager.Row>
                    <FilterManager.Row>
                        <FilterManager.Input name='ucastnikNazev' headerText={ts('NEN-812862')} />
                        <FilterManager.Input name='ucastnikICO' headerText={ts('NEN-812863')} />
                    </FilterManager.Row>
                </FilterManager.Group>
                <FilterManager.Group id='authority' header={ts('NEN-751471')}>
                    <FilterManager.Row>
                        <FilterManager.Input name='zadavatelNazev' headerText={ts('NEN-742292')} />
                        <FilterManager.Input name='zadavatelICO' headerText={ts('NEN-742293')} />
                    </FilterManager.Row>
                    <FilterManager.Row>
                        <FilterManager.Lov
                            headerText={ts('NEN-812505')}
                            name='zadavatelHlavniPredmet'
                            attrMapping={HlavniPredmetCinnostiAttrMap}
                            showAttrs='nazev'
                            condition='(Plati_od=null or Plati_od <= DB.getDate()) and (Plati_do=null or  DB.getDate() <= Plati_do)' />
                        <FilterManager.Lov
                            headerText={ts('NEN-812869')}
                            name='zadavatelPravniForma'
                            attrMapping={PravniFormaAttrMap}
                            showAttrs='nazev'
                            condition='(Plati_od=null or Plati_od <= DB.getDate()) and (Plati_do=null or  DB.getDate() <= Plati_do)' />
                        <FilterManager.Lov
                            headerText={ts('NEN-812868')}
                            name='zadavatelNUTS'
                            attrMapping={NUTSModelAttrMap}
                            showAttrs={kodNazevAttrs}
                            condition='(Plati_od=NULL OR Plati_od <= DB.getDateTime()) AND (Plati_do=NULL OR Plati_do >= DB.getDateTime())' />
                    </FilterManager.Row>
                    <FilterManager.Row>
                        <FilterManager.Input name='zadavatelObec' headerText={ts('NEN-812866')} />
                        <FilterManager.Input name='zadavatelCastObce' headerText={ts('NEN-812865')} />
                        <FilterManager.Input name='zadavatelUlice' headerText={ts('NEN-812867')} />
                    </FilterManager.Row>
                </FilterManager.Group>
                <FilterManager.Group id='code' header={ts('NEN-742295')}>
                    <FilterManager.Row>
                        <FilterManager.Input name='kod' headerText={ts('NEN-742283')} />
                        <FilterManager.Input name='kodVestnik' headerText={ts('NEN-812731')} />
                        <FilterManager.Input name='kodEU' headerText={ts('NEN-812691')} />
                        <FilterManager.Input name='kodET' headerText={ts('NEN-812630')} />
                        <FilterManager.Input name='kodIEN' headerText={ts('NEN-812692')} />
                    </FilterManager.Row>
                </FilterManager.Group>
            </FilterManager.GroupsContainer>
        </FilterManager>
    );
};

export default React.memo(VerejneZakazkyFilter);
