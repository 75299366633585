import { IdContext, useId } from "@utils/UseId";
import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "utils/BemUtils";
import AccordionPage from "./AccordionPage";

const cn = setupCn('gov-accordion');

export type AccordionSize = 'small' | 'large';

export type AccordionProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    isBordered?: boolean;
    asSection?: boolean;
    asSidebar?: boolean;
    noInnerOffset?: boolean;
    noInnerYOffset?: boolean;
    noInnerXOffset?: boolean;
    withModalPadding?: boolean;
    size?: AccordionSize;
}>;

type AccordionType = FC<AccordionProps> & {
    Page: typeof AccordionPage;
}

const Accordion: AccordionType = React.memo(({
    id,
    className,
    isBordered,
    asSection,
    asSidebar,
    noInnerOffset,
    noInnerYOffset,
    noInnerXOffset,
    withModalPadding,
    size,
    children
}: AccordionProps) => {
    const { controlId } = useId({ id: cn(`-${id}`) });

    const sizeModifier = `--${size ?? 'large'}`;

    return (
        <div id={controlId} className={cn.with(className).main(sizeModifier, {
            '--bordered': isBordered,
            '--as-section': asSection,
            '--as-sidebar': asSidebar,
            '--no-inner-offset': noInnerOffset,
            '--no-inner-y-offset': noInnerYOffset,
            '--no-inner-x-offset': noInnerXOffset,
            '--modal-padding': withModalPadding,
        })}>
            <IdContext.Provider value={controlId}>
                {children}
            </IdContext.Provider>
        </div>
    );
}) as unknown as AccordionType;

Accordion.Page = AccordionPage;

Accordion.displayName = 'Accordion';

export default Accordion;
