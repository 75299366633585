import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { AppDispatch, ApplicationState, ApplicationStateSelector } from ".";
import { IGetDataRowsParams } from "@services/DataRowsService";
import { EvaluatedCondition } from "@services/EvaluateConditionsService";
import { AttrMapping, AttrMappingDescription, AttrName, IData } from "@utils/AttrMapping";
import { Deferred, PromiseUtils } from "@utils/PromiseUtils";
import { getErrorStoreActions } from "./errorStore";
import { getDataRowsService, getEvaluateConditionsService, useTaskManager } from "./nodeContextStore";

export const name = 'detailObjectStore'

export type DetailObjectStoreState = {
    objects: {
        [key: string]: ObjectType<IData>
    }
}

export type ObjectType<T extends IData> = {
    id: string,
    isFetching: boolean,
    object: T | null,
    evaluatedConditions: EvaluatedCondition[]
}

// Create the slice.
export const slice = createSlice({
    name,
    initialState: {
        objects: {}
    } as DetailObjectStoreState,
    reducers: {
        setFetching: (state, action: PayloadAction<{ key: string, isFetching: boolean }>) => {
            return {
                ...state,
                objects: {
                    ...state.objects,
                    [action.payload.key]: {
                        ...state.objects[action.payload.key],
                        isFetching: action.payload.isFetching
                    }
                }
            };
        },
        updateData: (state, action: PayloadAction<{ key: string, id: string, object: IData | null }>) => {
            return {
                ...state,
                objects: {
                    ...state.objects,
                    [action.payload.key]: {
                        ...state.objects[action.payload.key],
                        id: action.payload.id,
                        object: action.payload.object
                    }
                }
            };
        },
        setConditions: (state, action: PayloadAction<{ key: string, evaluatedConditions: EvaluatedCondition[] }>) => {
            return {
                ...state,
                objects: {
                    ...state.objects,
                    [action.payload.key]: {
                        ...state.objects[action.payload.key],
                        evaluatedConditions: action.payload.evaluatedConditions
                    }
                }
            };
        },
        clearObject: (state, action: PayloadAction<{ key: string }>) => {
            return {
                ...state,
                objects: {
                    ...state.objects,
                    [action.payload.key]: undefined
                }
            } as DetailObjectStoreState;
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getObjectStoreState = (state: ApplicationState) => state[name];

export const getAllObjects = createSelector([getObjectStoreState],
    (objectsStore) => objectsStore?.objects
);

export const getDetailObjectInfo = createSelector(
    getAllObjects,
    (_: unknown, key: string) => key,
    (objects, key) => objects[key]
);

// Define action creators.
export const actionCreators = {
    clear: (key: string) =>
        (dispatch: AppDispatch) => {
            const actions = slice.actions;
            dispatch(actions.clearObject({ key }));
        },
    setFetching: (key: string, value: boolean) =>
        (dispatch: AppDispatch) => {
            dispatch(slice.actions.setFetching({ key: key, isFetching: value }));
        },
    loadObject: (key: string, params: IGetDataRowsParams) =>
        async (dispatch: AppDispatch, getState: ApplicationStateSelector): Promise<any> => {
            const actions = slice.actions;

            const service = getDataRowsService(getState());
            const result = await service.loadData({
                ...params
            });

            if (!result.hasErrors && result.value.length == 1) {
                dispatch(actions.updateData({ key: key, id: result.value[0].id.toString(), object: result.value[0] }));
            } else {
                dispatch(actions.updateData({ key: key, id: '', object: null }));
                dispatch(getErrorStoreActions().push(result.errors || ['Obj not found!']));
            }

            return result;
        },
    evaluateConditions: (key: string, conditions: Map<string, boolean>, stateSelector: ((state: ApplicationState) => ObjectType<IData> | undefined), params: IGetDataRowsParams) =>
        async (dispatch: AppDispatch, getState: ApplicationStateSelector): Promise<any> => {
            const actions = slice.actions;
            const id = stateSelector(getState())?.id;
            if (!id)
                return;

            const service = getEvaluateConditionsService(getState());
            const result = await service.evaluateConditions({
                id: id,
                className: params.className,
                conditions: [...conditions.keys()],
            });
            if (!result.hasErrors) {
                dispatch(actions.setConditions({ key: key, evaluatedConditions: result.value }));
            } else {
                dispatch(getErrorStoreActions().push(result.errors || ['Condition evaluation error!']));
            }
        }
}


//hooks
export type UseDetailObjectParams<T extends IData> = {
    condition: string,
    conditionParams: string[]
    attrMapping: AttrMapping<T>
    loadKey: string,
}

export type GetAttrMethodType<T extends IData> = {
    getAttr: <TAttr extends AttrName<T>>(attrName: TAttr) => GetAttrType<T, T[TAttr]>
}

export type GetAttrType<T extends IData, TValue> = {
    value: TValue | null;
    isFetching: boolean;
    data?: T;
    attrInfo?: AttrMappingDescription<T>;
    objectName?: string;
}

export type EvaluateConditionMethodType = {
    evaluateCondition: (condition: string) => boolean | undefined
}

export type UseDetailObjectReturnType<T extends IData> = GetAttrMethodType<T> & EvaluateConditionMethodType;


export const useDetailObject = <T extends IData>({
    condition,
    conditionParams,
    attrMapping,
    loadKey,
}: UseDetailObjectParams<T>): GetAttrMethodType<T> & EvaluateConditionMethodType => {
    loadKey = [loadKey, ...conditionParams].join('-')
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const state = useSelector((state: ApplicationState) => getDetailObjectInfo(state, loadKey));
    const taskManager = useTaskManager();
    const attrsToLoad = useMemo(() => new Set<AttrName<T>>(), [condition, conditionParams.join('-'), match?.path]);
    const conditionsToEvaluate = useMemo(() => new Map<string, boolean>(), [condition, conditionParams.join('-'), match?.path]);
    let setAttrPromise: Deferred<void>;

    const loadData = useCallback(async (controller?: AbortController) => {
        dispatch(actionCreators.setFetching(loadKey, true));

        await dispatch(actionCreators.loadObject(loadKey, {
            className: attrMapping.objectName,
            condition,
            conditionParams,
            attributes: attrMapping.getDataRowAttributes(
                ...attrsToLoad.add('id')
            ),
            abortSignal: controller?.signal
        }));
        if (conditionsToEvaluate.size > 0) {
            await dispatch(actionCreators.evaluateConditions(loadKey, conditionsToEvaluate, (state: ApplicationState) => getDetailObjectInfo(state, loadKey), {
                className: attrMapping.objectName,
                attributes: attrMapping.getDataRowAttributes('id'),
                abortSignal: controller?.signal
            }));
        }

        dispatch(actionCreators.setFetching(loadKey, false));
    }, [condition, conditionParams.join('-'), dispatch, match?.path]);

    const getAttr = useCallback((attrName: AttrName<T>): GetAttrType<T, any> => {
        const attrInfo = attrMapping.getByClientName(attrName);
        if (attrInfo) {
            attrsToLoad.add(attrName);
            const isFetching = !state || (state?.isFetching ?? false);
            const data = isFetching ? undefined : state?.object as T | undefined;
            return {
                value: data?.[attrName] ?? null as any,
                attrInfo,
                data,
                isFetching,
                objectName: attrMapping.objectName,
            }
        } else {
            console.error(`${attrName} not found!`);
        }
        return {
            value: null,
            isFetching: false
        };
    }, [condition, conditionParams.join('-'), state, match?.path]);

    const evaluateCondition = useCallback((condition: string) => {
        if (!conditionsToEvaluate.has(condition)) {
            conditionsToEvaluate.set(condition, false);
        }
        return state?.evaluatedConditions?.find(c => c.condition == condition)?.result;
    }, [condition, conditionParams.join('-'), state, match?.path]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [conditionParams.join('-')]);

    useEffect(() => {
        return () => {
            dispatch(actionCreators.clear(loadKey));
        }
    }, [condition, conditionParams.join('-')]);

    useEffect(() => {
        const controller = new AbortController();

        if (taskManager.isTaskCompleted(loadKey)) {
            taskManager.removeTaskFromCompleted(loadKey);
        } else {
            loadData(controller);
        }

        return () => {
            controller?.abort();
        }
    }, [condition, conditionParams.join('-'), match?.path]);

    taskManager.add(loadKey + '-attr-set', () => {
        setAttrPromise = PromiseUtils.defer();

        setAttrPromise.promise.then(() => {
            taskManager.add(loadKey, loadData);
        });

        setAttrPromise?.resolve();
        return setAttrPromise.promise;
    });

    return { getAttr, evaluateCondition };
}