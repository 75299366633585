import CountUpSsr, { CountUpSsrProps } from "@components/CountUpSSR/CountUpSsr";
import React, { FC, ReactNode, useMemo, useState } from "react"
import ReactVisibilitySensor from "react-visibility-sensor";
import { useLocalization } from "@store/localizationStore";
import { useStatistics } from "@store/statisticsStore";
import { setupCn } from "@utils/BemUtils";
import { formatNumber, getExponentionalParts, randomInteger } from "@utils/NumberUtils";
import FlexBox from "@components/FlexBox/FlexBox";
import { useSwitchByLanguage } from "@utils/LanguageUtils";

const cn = setupCn('statistics');
const cnSection = cn.setupSubCn('__section');

const floorMld = (num: number, decimals: number = 0) => Math.floor(num / (10 ** (9 - decimals))) / (10 ** decimals);

const Section: React.FC<{
    title: string,
    lockey: string,
    start: number,
    end: number,
    decimals?: number,
    counterProps: Partial<CountUpSsrProps>,
    suffixNode?: ReactNode
}> = ({ title, lockey, counterProps, start, end, suffixNode, decimals }) => {
    const { ts } = useLocalization();

    return (
        <div className={cnSection()}>
            <div
                className={cnSection('__number')}
                title={title}>
                <CountUpSsr
                    {...counterProps}
                    decimals={decimals}
                    start={start}
                    end={end} />
                {suffixNode}
            </div>
            <h3 className={cnSection('__title')}>{ts(lockey)}</h3>
        </div>
    );
}

const Statistics: FC = () => {
    const [counterStarted, setCounterStarted] = useState(false);

    const { switchByLanguage } = useSwitchByLanguage();

    const counterProps: Partial<CountUpSsrProps> = {
        duration: 3,
        disabled: !counterStarted,
        decimal: switchByLanguage({ cz: ',', en: '.' }),
        separator: '\xa0'
    }
    const statistics = useStatistics();
    const hodnotaZakazek = useMemo<{ value: number, suffix?: ReactNode, decimals: number }>(() => {
        const mldValue = floorMld(statistics?.hodnotaZakazekCelkem ?? 0);
        if (Math.abs(mldValue) < 100000) {
            return {
                value: mldValue,
                decimals: 0
            };
        }
        const {
            mantissa,
            exponent
        } = getExponentionalParts({
            value: statistics?.hodnotaZakazekCelkem ?? 0,
            fractionDigits: 1,
            cutType: 'floor'
        });
        return {
            value: mantissa,
            suffix: <>x10<sup>{exponent - 9}</sup></>,
            decimals: 1
        };
    }, [statistics?.hodnotaZakazekCelkem]);
    return (
        <ReactVisibilitySensor
            active={!counterStarted}
            partialVisibility
            onChange={isVisible => isVisible && setCounterStarted(true)}
            delayedCall>
            <FlexBox className={cn()}>
                <Section
                    title={formatNumber({ value: statistics?.pocetZadavatelu ?? 0 })}
                    start={randomInteger(3, 9)}
                    end={statistics?.pocetZadavatelu ?? 0}
                    counterProps={counterProps}
                    lockey='NEN-749239'>
                </Section>

                <Section
                    title={formatNumber({ value: statistics?.pocetDodavatelu ?? 0 })}
                    start={randomInteger(3, 9)}
                    end={statistics?.pocetDodavatelu ?? 0}
                    counterProps={counterProps}
                    lockey='NEN-749240'>
                </Section>

                <Section
                    title={formatNumber({ value: statistics?.pocetZakazekCelkem ?? 0 })}
                    start={randomInteger(3, 9)}
                    end={statistics?.pocetZakazekCelkem ?? 0}
                    counterProps={counterProps}
                    lockey='NEN-749241'>
                </Section>

                <Section
                    title={`${formatNumber({ value: statistics?.hodnotaZakazekCelkem ?? 0 })} Kč`}
                    decimals={hodnotaZakazek.decimals}
                    start={randomInteger(3, 9) / 10}
                    end={hodnotaZakazek.value}
                    counterProps={counterProps}
                    suffixNode={<h2>{hodnotaZakazek.suffix}</h2>}
                    lockey='NEN-749242'>
                </Section>
            </FlexBox>
        </ReactVisibilitySensor>
    );
};

Statistics.displayName = 'Statistics';

export default React.memo(Statistics);
