import { FC } from "react"
import { useAppSettings } from "@store/nodeContextStore";
import LargeWidget from "@components/Widgets/LargeWidget";

const Skoleni: FC = () => {
    const { getAllWidgetUrl } = useAppSettings();

    return <LargeWidget src={getAllWidgetUrl('skoleni')} />
};

Skoleni.displayName = 'Skoleni';

export default Skoleni;