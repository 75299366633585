import { FC, FormEventHandler, PropsWithChildren, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocalizationService, { Language } from "services/LocalizationService";
import { AppDispatch } from "store";
import { getCurrentLanguage, actionCreators, useLocalization } from '@store/localizationStore';
import { setupCn } from "@utils/BemUtils";
import { useHistory, useLocation } from "react-router";

const cn = setupCn('gov-lang-switch');

const enRegExp = new RegExp(/^\/en\//);

const LanguageSwitcher: FC<PropsWithChildren<{ id: string }>> = ({ id }) => {
    const { ts } = useLocalization();
    const dispatch: AppDispatch = useDispatch();
    const currentLanguage = useSelector(getCurrentLanguage);
    const allLanguages = LocalizationService.supportedLanguages;
    const location = useLocation();
    const { push } = useHistory();

    const onChange = useCallback<FormEventHandler<HTMLSelectElement>>(event => {
        dispatch(actionCreators.changeLanguage(event.currentTarget.value as Language));
        const url = location.pathname + location.search + location.hash;
        const isEnInUrl = enRegExp.test(location.pathname);
        if (event.currentTarget.value == 'EN' && !isEnInUrl) {
            push('/en' + url);
        } else if (event.currentTarget.value != 'EN' && isEnInUrl) {
            push(url.replace(/^\/en/, ''));
        }
    }, [dispatch, push, location]);

    useEffect(() => {
        if (enRegExp.test(location.pathname)) {
            dispatch(actionCreators.changeLanguage('EN'));
        } else {
            dispatch(actionCreators.changeLanguage('CZ'));
        }
    }, [dispatch, location]);

    useEffect(() => {
        const altLink = document.querySelector<HTMLLinkElement>('link[rel="alternate"]');
        if (altLink) {
            const url = location.pathname + location.search + location.hash;
            let base = window.location.href;
            if (location.pathname.length > 1) {
                base = base
                    .substring(0, window.location.href.indexOf(location.pathname));
            }
            base = base.replace(/\/$/, '');

            altLink.hreflang = currentLanguage == 'EN' ? 'cs' : 'en';
            const isEnInUrl = enRegExp.test(location.pathname);
            if (currentLanguage == 'EN' && isEnInUrl) {
                altLink.href = base + url.replace('/en', '');
            } else if (currentLanguage != 'EN' && !isEnInUrl) {
                altLink.href = base + '/en' + url;
            }
        }
    }, [currentLanguage, location]);

    return (
        <div className={cn.main()}>
            <select
                id={id}
                className={cn('__select')}
                value={currentLanguage}
                onChange={onChange}>
                {
                    allLanguages.map(lang =>
                        <option key={lang} value={lang}>
                            {lang}
                        </option>
                    )
                }
            </select>
            <label className="u-sr-only" htmlFor={id}>
                {ts("NEN-463236", { isPrimaryTheme: true })}
            </label>
        </div>
    )
}

LanguageSwitcher.displayName = 'LanguageSwitcher';

export default LanguageSwitcher;
