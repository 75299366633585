import GovLink from "@components/GovLink/GovLink";
import { CSSProperties, FC } from "react";
import Skeleton from "react-loading-skeleton"
import { Classes, setupCn } from "@utils/BemUtils";
import { renderIf } from "@utils/RenderUtils"
import { DetailLink } from "@components/ListFrame";

const cn = setupCn('gov-table');

export type TableDetailLinkProps = {
    isSkeleton?: boolean;
    detailLink: DetailLink | null;
    className?: Classes;
    alignRight?: boolean;
    withArrow?: boolean;
    style?: CSSProperties;
}

const TableDetailCell: FC<TableDetailLinkProps> = ({
    isSkeleton,
    detailLink,
    withArrow,
    children,
    className,
    alignRight,
    style
}) => {
    return (
        <td
            className={cn.with(className, cn('__row-controls'), { 'u-align--right': alignRight }).subCn('__cell', {
                '--narrow': withArrow
            })}
            style={style}>
            {renderIf(isSkeleton, <Skeleton />)}
            {
                detailLink?.url && !isSkeleton &&
                <GovLink
                    to={{ pathname: detailLink.url }}
                    aria-label={detailLink.title}
                    hasArrow={withArrow}>
                    <span className={cn('__row-button-text')}>{children}</span>
                </GovLink>
            }
        </td>
    )
}

export default TableDetailCell;
