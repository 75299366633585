import { AttrMapping, IData } from "@utils/AttrMapping";

export type IRelevantniCinnostSubjektuModel = IData & {
    id: number;
    relevantniCinnostNazev: string;
    jinaCinnost: string;
}

export const RelevantniCinnostSubjektuAttrMap = new AttrMapping<IRelevantniCinnostSubjektuModel>('Rel_cinnost_subj', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'relevantniCinnostNazev', serverName: 'Rel_cinnost.Nazev', type: 'string' },
    { clientName: 'jinaCinnost', serverName: 'Jina_cinnost', type: 'string' },
]);
