import { setupCn } from "@utils/BemUtils";
import Pictogram from '@components/Pictogram/Pictogram';
import { Link } from "react-router-dom";
import { useLocalization } from '@store/localizationStore';
import { useAppSettings } from "@store/nodeContextStore";
import { useConstants } from "@constants/urlConstants";

import './Logo.scss'
const cn = setupCn('gov-logo');
const cnEnv = cn.setupSubCn('__env-text');

const Logo: React.FC<{ isNavigationActive: boolean }> = (isNavigationActive) => {
    const { ts } = useLocalization();
    const { appSettings } = useAppSettings();
    const { getUrl } = useConstants();

    return (
        <Link to={getUrl('home')} className={cn.main('--inversed')}>
            <Pictogram />
            <div className={cn('__sitename')}>
                <span>
                    NEN
                </span>
                {
                    appSettings?.isTestEnvironment || appSettings?.outageVersion?.enabled ?
                        <span className={cnEnv()}>
                            {appSettings?.isTestEnvironment ? ts("NEN-768453") : ts("NEN-768812")}
                        </span> : null
                }
            </div>
        </Link>
    );
}

export default Logo;