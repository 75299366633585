import React, { FC, useCallback } from "react";
import ButtonBase, { ButtonBaseProps } from "./ButtonBase";

export type ButtonProps =
    Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'className'> &
    ButtonBaseProps &
    {
        buttonRef?: React.Ref<HTMLButtonElement>;
    };

const Button: FC<ButtonProps> = ({ buttonRef, ...restProps }) => {
    const ButtonElement: FC<any> = useCallback(({ type, ...buttonProps }) => <button ref={buttonRef} type={type ?? 'button'} {...buttonProps} />,
        [buttonRef]);

    return (
        <ButtonBase
            element={ButtonElement}
            {...restProps} />
    )
}

export default React.memo(Button);
