import React, { FC } from "react";
import GovLink from "../GovLink";
import GovLinkBase, { GovLinkBaseProps } from "../GovLink/GovLinkBase";

export type UniversalLinkTo = {
    to: string,
    sameTarget?: boolean
}
export type UniversalLinkProps = React.PropsWithChildren<Omit<GovLinkBaseProps, "href" | "to"> & UniversalLinkTo>;

const AnchorLink: React.FC<UniversalLinkTo> = ({ sameTarget, ...props }) => <a {...props} target={sameTarget ? '' : '_blank'} />

const UniversalLink: FC<UniversalLinkProps> = props => {
    const { isExternal, ...linkProps } = props;
    if (isExternal) {
        const { to, ...aProps } = {
            ...linkProps,
            href: props.to
        };
        return (
            <GovLinkBase
                element={AnchorLink}
                {...aProps} />

        );
    }
    return (
        <GovLink {...props} />
    );
}

export default React.memo(UniversalLink);