import React, { FC } from "react"
import CountUp, { CountUpProps } from "react-countup";
import { setupCn, Classes } from "@utils/BemUtils";
import { formatNumber } from "@utils/NumberUtils";

const cn = setupCn('count-up-ssr');

export type CountUpSsrProps = CountUpProps & {
    className?: Classes;
    disabled?: boolean;
};

const CountUpSsr: FC<CountUpSsrProps> = ({
    className,
    children,
    disabled,
    end,
    ...countUpProps
}) => {
    const { start, decimals, decimal, separator, suffix } = countUpProps;
    const formatedNumber = `${formatNumber({ value: end ?? 0, decimal, decimals, separator })}${suffix ?? ''}`;
    return (
        <CountUp {...countUpProps} end={disabled ? start ?? 0 : end}>
            {({ countUpRef }) => (
                <h2 className={cn.with(className)()} ref={countUpRef}>{formatedNumber}</h2>
            )}
        </CountUp>
    );
};

CountUpSsr.displayName = 'CountUpSsr';

export default React.memo(CountUpSsr);
