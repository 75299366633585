import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { StatisticsData } from '@services/NENService';
import { AppDispatch, ApplicationState, ApplicationStateSelector } from '.';
import { getErrorStoreActions } from './errorStore';
import { getNodeContext } from './nodeContextStore';

export const name = 'statisticsStore';

// Declare an interface of the store's state.
export type StatisticsState = {
    statisticsData: StatisticsData | null;
    fetchingStarted: boolean;
};

// Create the slice.
export const slice = createSlice({
    name,
    initialState: {
        fetchingStarted: false,
        statisticsData: null
    } as StatisticsState,
    reducers: {
        setStatistisc: (state, action: PayloadAction<StatisticsData | null>) => {
            return { ...state, statisticsData: action.payload };
        },
        setFetchingStarted: (state) => {
            return { ...state, fetchingStarted: true };
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getStatistics = (state: ApplicationState) => state[name];
export const getStatisticsData = createSelector(getStatistics, statistics => statistics.statisticsData);

const getStatisticsTaskKey = 'get-statistics';

// Define action creators.
export const actionCreators = {
    getStatistics: () =>
        (dispatch: AppDispatch, getState: ApplicationStateSelector): StatisticsData | null => {
            const appState = getState();
            const statistics = getStatistics(appState);
            if (statistics.statisticsData) {
                return statistics.statisticsData;
            }

            if (statistics.fetchingStarted) {
                return null;
            }

            dispatch(slice.actions.setFetchingStarted());

            const nodeContext = getNodeContext(appState);
            
            if (nodeContext.taskManager.isTaskCompleted(getStatisticsTaskKey)) {
                nodeContext.taskManager.removeTaskFromCompleted(getStatisticsTaskKey);
                return null;
            }

            const promise = nodeContext.nenService.getStatistics()
                .then(result => dispatch(slice.actions.setStatistisc(result)));

            promise.catch(e => {
                dispatch(getErrorStoreActions().push(e))
            })

            nodeContext.taskManager.add(getStatisticsTaskKey, () => promise);

            return null;
        }
};

export const useStatistics = () => {
    const dispatch: AppDispatch = useDispatch();
    const statistics = useSelector(getStatisticsData);
    return statistics ?? dispatch(actionCreators.getStatistics());
}

export type UseStatisticsType = ReturnType<typeof useStatistics>;
