import React, { FC } from "react"
import { AttrMapping, IData } from "@utils/AttrMapping";
import { TableColumn } from "./Table";
import TableRow, { DetailLinkFactory } from "./TableRow";

export type TableRowsProps = {
    attrMapping: AttrMapping;
    skeletonCount?: number;
    isLoading?: boolean;
    isNext?: boolean;
    data?: IData[];
    columns: TableColumn[];
    detailLinkFactory?: DetailLinkFactory;
}

const TableRows: FC<TableRowsProps> = ({
    attrMapping,
    skeletonCount,
    isLoading,
    isNext,
    data,
    columns,
    detailLinkFactory
}) => {
    const dataCount = data?.length ?? 0;
    skeletonCount ??= 0;
    return (
        <>
            {
                data?.map(row =>
                    <TableRow
                        key={row.id}
                        isSkeleton={isLoading && !isNext}
                        detailLinkFactory={detailLinkFactory}
                        columns={columns}
                        attrMapping={attrMapping}
                        data={row} />
                )
            }
            {
                !isLoading ? null :
                    [...Array(Math.max(skeletonCount - dataCount, 0)).keys()]?.map(index =>
                        <TableRow
                            key={`skeleton${index}`}
                            isSkeleton
                            detailLinkFactory={detailLinkFactory}
                            columns={columns}
                            attrMapping={attrMapping}
                            data={null} />
                    )
            }
        </>
    );
};

export default React.memo(TableRows);
