import CertificateDetail from "crypto/CertificateDetail/CertificateDetail";
import CryptoPart from "crypto/CryptoPart/CryptoPart";
import { IEncryptionInfo } from "crypto/interface/ICryptographicInfo";
import { getEncryptionTooltipParts } from "crypto/SignatureUtils";
import React, { FC, PropsWithChildren } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";
import { MdLockOutline } from "react-icons/md";

const cn = setupCn('encryption-info');

export type EncryptionInfoProps = PropsWithChildren<{
    className?: Classes;
    encryptionInfo: IEncryptionInfo;
}>;

const EncryptionInfo: FC<EncryptionInfoProps> = ({
    className,
    encryptionInfo
}) => {
    const localization = useLocalization();
    const { ts } = localization;
    const subtextParts = getEncryptionTooltipParts(encryptionInfo); //datum a čas šifry, název certifikátu
    return (
        <CryptoPart
            id='encrpytion-info'
            className={cn.with(className)()}
            caption={ts('GUI-536875')}
            captionIcon={MdLockOutline}
            captionDescription={<>
                {subtextParts.map((part, i) => <React.Fragment key={i}>{part}<br /></React.Fragment>)}
                {ts('GUI-536869')}: {encryptionInfo.algorithm}
            </>}>
            <CertificateDetail
                className={cn('__certificate-detail')}
                certificate={encryptionInfo.certificate} />
        </CryptoPart>
    );
};

EncryptionInfo.displayName = 'EncryptionInfo';

export default React.memo(EncryptionInfo);
