import { AttrMapping, IData } from "@utils/AttrMapping";
import { CreateConditionFactory, createIn, createSubtreeCondition } from "@utils/FilterCondition";

export type ISubjektModel = IData & {
    adresaProfilNEN: string;
    castObce: string;
    cisloOrientacni: number;
    cisloPopisne: number;
    cisloUctu: string;
    dalsiProfil: string;
    datumDeaktivace: Date;
    datumNarozeni: Date;
    datumUverejneniVVZ: Date;
    datumVytvoreni: Date;
    dic: string;
    dominantniVliv: boolean;
    doplnekKNazvu: string;
    email: string;
    evidencniCisloVeVestniku: string;
    externiAdministrator: boolean;
    fax: string;
    iban: string;
    ico: string;
    id: number;
    jinyIdentifikátor: string;
    kategorieZadavatele: string;
    kodBanky: string;
    multiprofil: boolean;
    nazevOrganizace: string;
    nazevSdruzZadav: string;
    nazevUctu: string;
    nipezSubjektu: number;
    cpvNipezSubjektu: number;
    nutsKod: string;
    obec: string;
    obrana: boolean;
    pfNazev: string;
    pocetZam: number;
    profID: number;
    profilZadavatele: string;
    predcisliUctu: number;
    predmetCinnostiOrg: string;
    psc: string;
    relativniCinnost: string;
    relativniCinnostJinak: boolean;
    rocniObrat: string;
    rodneCislo: string;
    rokVznikuOrg: string;
    stat: string;
    subjID: number;
    swift: string;
    telefon1: string;
    telefon2: string;
    typSubjektu: string;
    ulice: string;
    vedouciClenSdruz: string;
    www: string;
    zpusobJednani: string;
    NUTS: number;
    pravniForma: number;
}

const createPredmetCinnostiCondition = ({ objectName, subjectAttrName, ciselnikAttrName }: { objectName: string; subjectAttrName: string; ciselnikAttrName: string; }): CreateConditionFactory =>
    ({ value, conditionParams }) => {
        const stringValue = value as string;
        if (!stringValue) {
            return null;
        }

        const parentInCondition = createIn('Nadrizeny', stringValue);
        const condition = `EXISTS(${objectName},${subjectAttrName}=&ID AND (${createIn(ciselnikAttrName, stringValue)} OR EXISTS(DStrom,ID=&${ciselnikAttrName} AND ${parentInCondition})))`;
        
        return { condition, conditionParams };
    }

export const SubjektAttrMap = new AttrMapping<ISubjektModel>('Subjekt', [
    { clientName: 'adresaProfilNEN', serverName: 'Adresa_Profil_NEN', type: 'string' },
    { clientName: 'castObce', serverName: 'Cast_obce', type: 'string' },
    { clientName: 'cisloOrientacni', serverName: 'Cislo_orientacni', type: 'number' },
    { clientName: 'cisloPopisne', serverName: 'Cislo_popisne', type: 'number' },
    { clientName: 'cisloUctu', serverName: 'cislo_uctu', type: 'string' },
    { clientName: 'dalsiProfil', serverName: 'Dalsi_profil_1', type: 'string' },
    { clientName: 'datumNarozeni', serverName: 'Datum_narozeni', type: 'date' },
    { clientName: 'dic', serverName: 'DIC', type: 'string' },
    { clientName: 'dominantniVliv', serverName: 'Dominantni_vliv', type: 'bool' },
    { clientName: 'doplnekKNazvu', serverName: 'Doplnek_k_nazvu', type: 'string' },
    { clientName: 'email', serverName: 'Email', type: 'string' },
    { clientName: 'externiAdministrator', serverName: 'ext_admin', type: 'bool' },
    { clientName: 'evidencniCisloVeVestniku', serverName: 'Evid_c_ve_Vestniku', type: 'string' },
    { clientName: 'fax', serverName: 'Fax', type: 'string' },
    { clientName: 'iban', serverName: 'IBAN', type: 'string' },
    { clientName: 'ico', serverName: 'ICO', type: 'string' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'jinyIdentifikátor', serverName: 'Jiny_identifikator', type: 'string' },
    { clientName: 'kategorieZadavatele', serverName: 'Kategorie_zadavatele', type: 'enum', typeParam: 'Kategorie_zadavatele' },
    { clientName: 'kodBanky', serverName: 'Banka_uctu.Kod', type: 'string' },
    { clientName: 'nazevOrganizace', serverName: 'Nazev', type: 'string' },
    { clientName: 'nazevSdruzZadav', serverName: 'Nazev_sdruz_zad', type: 'string' },
    { clientName: 'nazevUctu', serverName: 'Nazev_uctu', type: 'string' },
    { clientName: 'obec', serverName: 'Obec', type: 'string' },
    { clientName: 'obrana', serverName: 'Obrana', type: 'bool' },
    { clientName: 'pfNazev', serverName: 'PF.Nazev', type: 'string' },
    { clientName: 'pocetZam', serverName: 'Pocet_zam', type: 'number' },
    { clientName: 'predcisliUctu', serverName: 'Predcisli_uctu', type: 'number' },
    { clientName: 'predmetCinnostiOrg', serverName: 'Predmet_cinnosti_org', type: 'string' },
    { clientName: 'profilZadavatele', serverName: 'Profil_zadavatele', type: 'string' },
    { clientName: 'psc', serverName: 'PSC', type: 'string' },
    { clientName: 'relativniCinnost', serverName: 'Rel_cin', type: 'string' },
    { clientName: 'relativniCinnostJinak', serverName: 'Rel_cinnost_jinak', type: 'bool' },
    { clientName: 'rocniObrat', serverName: 'Rocni_obrat', type: 'string' },
    { clientName: 'rodneCislo', serverName: 'Rodne_cislo', type: 'string' },
    { clientName: 'rokVznikuOrg', serverName: 'Rok_vzniku_org', type: 'string' },
    { clientName: 'nutsKod', serverName: 'vazba_NUTS.Kod', type: 'string' },
    { clientName: 'stat', serverName: 'Stat_adresy.Kod', type: 'string' },
    { clientName: 'subjID', serverName: 'ID', type: 'id' },
    { clientName: 'swift', serverName: 'Banka_uctu.SWIFT', type: 'string' },
    { clientName: 'telefon1', serverName: 'Telefon_1', type: 'string' },
    { clientName: 'telefon2', serverName: 'Telefon_2', type: 'string' },
    { clientName: 'typSubjektu', serverName: 'Typ_subj', type: 'string' },
    { clientName: 'ulice', serverName: 'Ulice', type: 'string' },
    { clientName: 'vedouciClenSdruz', serverName: 'Vedouci_clen_sdruz', type: 'string' },
    { clientName: 'www', serverName: 'WWW', type: 'string' },
    { clientName: 'zpusobJednani', serverName: 'Zpusob_jednani', type: 'string' },
    { clientName: 'nipezSubjektu', serverName: '', type: 'id', filterCondition: createPredmetCinnostiCondition({ objectName: 'VSubjekt_NIPEZ', subjectAttrName: 'subj_ID', ciselnikAttrName: 'NIPEZ_id' }) },
    { clientName: 'cpvNipezSubjektu', serverName: '', type: 'id', filterCondition: createPredmetCinnostiCondition({ objectName: 'CPVNIPEZ_subjektu', subjectAttrName: 'Subj', ciselnikAttrName: 'CPV_subjekt' }) },
    { clientName: 'NUTS', serverName: 'vazba_NUTS', type: 'id', filterCondition: createSubtreeCondition },
    { clientName: 'pravniForma', serverName: 'PF', type: 'id' },
]);
