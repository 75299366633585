import React, { ChangeEvent } from "react"
import Modal from "@components/Modal/Modal"
import { useLocalization } from "@store/localizationStore"
import FormControl from "@components/FormControl";
import Button from "@components/Button/Button";
import CheckBox from "@components/CheckBox";
import FormGroup from "@components/FormGroup/FormGroup";
import { useUserCookiesSetings } from "@store/userCookieSettingsStore";
import GovLink from "@components/GovLink";
import { useConstants } from "@constants/urlConstants";

const CookieModal: React.FC = () => {
    const { ts } = useLocalization();
    const { userCookieSettings, closeModal } = useUserCookiesSetings();

    if (!userCookieSettings.showModal)
        return null;

    return (
        <Modal headerText={ts("NEN-770174")} onClose={closeModal}>
            <PreferencesForm />
        </Modal>
    );
}

const PreferencesForm = () => {
    const { ts } = useLocalization();
    const { userCookieSettings, saveUserCookieSettings, setCookiesSetting } = useUserCookiesSetings();
    const { getUrl } = useConstants();

    return (
        <>
            <p>
                {ts("NEN-770175")}
            </p>
            <FormControl isCustom>
                <FormGroup items={[
                    <CheckBox id='functional-cookies' isDisabled isChecked headerText={
                        <>
                            {ts("NEN-770176")}
                            <p>
                                {ts("NEN-770177")}
                            </p>
                        </>
                    } />,
                    <CheckBox id='statistics-cookies'
                        isChecked={userCookieSettings.settings.statistics}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCookiesSetting('statistics', event.target.checked)}
                        headerText={
                            <>
                                {ts("NEN-770178")}
                                <p>
                                    {ts("NEN-770179")}
                                </p>
                            </>
                        } />,
                    <CheckBox id='preferences-cookies'
                        isChecked={userCookieSettings.settings.preferences}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setCookiesSetting('preferences', event.target.checked)}
                        headerText={
                            <>
                                {ts("NEN-770180")}
                                <p>
                                    {ts("NEN-770181")}
                                </p>
                            </>
                        } />
                ]} />
            </FormControl>
            <p>
                {ts("NEN-770182")}
                {" "}
                <GovLink to={{ pathname: getUrl('osobniUdajeACookies') }}>{ts("NEN-770159")}</GovLink>.
            </p>
            <Button onClick={saveUserCookieSettings}>
                {ts("NEN-770184")}
            </Button>
        </>
    );
}

export default CookieModal;