import React from "react"
import { match, useRouteMatch } from "react-router";
import { DataLabelTyped } from "@components/DataLabel";
import { DetailFrame, useDetailFrameContext } from "@components/DetailFrame/DetailFrame"
import GroupBox from "@components/GroupBox";
import Tabs from "@components/Tabs";
import { urlConstants } from "@constants/urlConstants";
import { CinnostSubjektuAttrMap, ICinnostSubjektuModel } from "@models/ICinnostSubjektuModel";
import { CpvNipezSubjektuAttrMap, ICpvNipezSubjektuModel } from "@models/ICpvNipezSubjektuModel";
import { IJinaAdresaSubjektuModel, JinaAdresaSubjektuAttrMap } from "@models/IJinaAdresaSubjektuModel";
import { IJinaInformaceSubjektu, JinaInformaceSubjektuAttrMap } from "@models/IJinaInformaceSubjektu";
import { IOsobaModel, OsobaAttrMap } from "@models/IOsobaModel";
import { IRelevantniCinnostSubjektuModel, RelevantniCinnostSubjektuAttrMap } from "@models/IRelevantniCinnostSubjektuModel";
import { ISubjektModel, SubjektAttrMap } from "@models/ISubjektModel";
import { UrlParamObject } from "@utils/QueryHook";
import { columnWidths, DependentListFrameTyped, ListFrameColumns } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { detailLinks } from "@constants/detailLinks";
import { DetailDescriptionParams, useDetailDescription } from "@utils/DescriptionHook";
import { ParsedUrlCondition } from "@store/breadcrumbTitleStore";

const DataLabel = DataLabelTyped<ISubjektModel>();
const DependentListFrame = DependentListFrameTyped<ISubjektModel>();

const detailSubjektuLoadkey = 'detail-subjektu';

export const parseSubjektUrl = (match: match<UrlParamObject> | null) => {
    return (match?.params[urlConstants.subjektDetail.detailIdParam] || '');
}

export const prepareSubjektCondition = (id: string): ParsedUrlCondition => {
    return {
        condition: 'ID=@0 and Plati_DO = null',
        conditionParams: [id]
    }
}

const DetailSubjektu: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const { condition, conditionParams } = prepareSubjektCondition(parseSubjektUrl(match));
    const routeParamName = urlConstants.subjektDetail.urlRouteParam;

    return (
        <DetailFrame
            condition={condition}
            conditionParams={conditionParams}
            attrMapping={SubjektAttrMap}
            loadKey={detailSubjektuLoadkey}
        >
            <Tabs tabs={
                [
                    {
                        lockey: 'NEN-812656',
                        path: urlConstants.subjektDetail.path,
                        render: () => <ZakladniInformace routeParamName={routeParamName} />
                    },
                    {
                        lockey: 'NEN-812662',
                        path: urlConstants.subjektDetailDalsiInformace.path,
                        render: () => <DalsiInformace routeParamName={routeParamName} />
                    }
                ]
            } />
        </DetailFrame>
    );
}

const commonDescriptionParts: DetailDescriptionParams<ISubjektModel>[] = [
    {
        lockey: 'NEN-812506',
        attrName: 'nazevOrganizace',
    },
    {
        lockey: 'NEN-812510',
        attrName: 'doplnekKNazvu',
    },
    {
        lockey: 'NEN-812698',
        attrName: 'evidencniCisloVeVestniku',
    },
    {
        lockey: 'NEN-812546',
        attrName: 'pfNazev',
    },
    {
        lockey: 'NEN-812517',
        attrName: 'obec',
    },
    {
        lockey: 'NEN-812518',
        attrName: 'castObce',
    },
    {
        lockey: 'NEN-812523',
        attrName: 'ulice',
    },
    {
        lockey: 'NEN-812525',
        attrName: 'cisloOrientacni',
    },
    {
        lockey: 'NEN-812527',
        attrName: 'stat',
    }
]

const ZakladniInformace: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const { getAttr } = useDetailFrameContext<ISubjektModel>();
    const match = useRouteMatch();

    useDetailDescription<ISubjektModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812656'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812507">
                <DataLabel lockey="NEN-812506" attrName='nazevOrganizace' fillGrid />
                <DataLabel lockey="NEN-812757" attrName='adresaProfilNEN' detailLink={detailLinks.getProfilDetailUrl(getAttr('id')?.value, match, true)} detailLinkShowIf='exists(Profil_LW, Subj = &ID and Platny = true)' fillGrid/>
                <DataLabel lockey="NEN-812510" attrName='doplnekKNazvu' />
                <DataLabel lockey="NEN-812511" attrName='ico' />
                <DataLabel lockey="NEN-812512" attrName='dic' />
                <DataLabel lockey="NEN-812513" attrName='jinyIdentifikátor' />
                <DataLabel lockey="NEN-812514" attrName='datumNarozeni' showIf="PF.Kod in['101','102','105','106','107','108','421','950','999']" />
                <DataLabel lockey="NEN-812615" attrName='pfNazev' />
                <DataLabel lockey="NEN-778515" attrName='telefon1' />
                <DataLabel lockey="NEN-812551" attrName='telefon2' />
                <DataLabel lockey="NEN-812552" attrName='fax' />
                <DataLabel lockey="NEN-812553" attrName='www' />
                <DataLabel lockey="NEN-812554" attrName='email' />
                <DataLabel lockey="NEN-812532" attrName='kategorieZadavatele' showIf="Zadavatel=true or Sdruzeni_zadav = True" />
                <DataLabel lockey="NEN-812654" attrName='relativniCinnost' showIf="Zadavatel=true or Sdruzeni_zadav = True" />
                <DataLabel lockey="NEN-812737" attrName='relativniCinnostJinak' showIf="Zadavatel=true or Sdruzeni_zadav = True" />
                <DataLabel lockey="NEN-812738" attrName='dominantniVliv' showIf="Zadavatel=true or Sdruzeni_zadav = True" />
                <DataLabel lockey="NEN-812739" attrName='obrana' showIf="Zadavatel=true or Sdruzeni_zadav = True" />
            </GroupBox>
            <GroupBox lockey="NEN-812555" showIf="Sdruzeni_zadav = True and (Nazev_sdruz_zad<>null OR Zpusob_jednani <>null OR Vedouci_clen_sdruz<>null)">
                <DataLabel lockey="NEN-812556" attrName='nazevSdruzZadav' />
                <DataLabel lockey="NEN-812557" attrName='zpusobJednani' />
                <DataLabel lockey="NEN-812558" attrName='vedouciClenSdruz' />
            </GroupBox>
            <GroupBox lockey="NEN-812516">
                <DataLabel lockey="NEN-812517" attrName='obec' />
                <DataLabel lockey="NEN-812518" attrName='castObce' />
                <DataLabel lockey="NEN-812523" attrName='ulice' />
                <DataLabel lockey="NEN-812524" attrName='cisloPopisne' />
                <DataLabel lockey="NEN-812525" attrName='cisloOrientacni' />
                <DataLabel lockey="NEN-812526" attrName='psc' />
                <DataLabel lockey="NEN-812527" attrName='stat' />
                <DataLabel lockey="NEN-812777" attrName='nutsKod' />
            </GroupBox>
            <GroupBox lockey="NEN-812528" showIf="Nazev_uctu <> null or IBAN <> null or Predcisli_uctu <> null or Cislo_uctu <> null or Banka_uctu.Kod <> null  or Banka_uctu.SWIFT <> null">
                <DataLabel lockey="NEN-812533" attrName='nazevUctu' />
                <DataLabel lockey="NEN-812543" attrName='predcisliUctu' />
                <DataLabel lockey="NEN-812544" attrName='cisloUctu' />
                <DataLabel lockey="NEN-812503" attrName='kodBanky' />
                <DataLabel lockey="NEN-812593" attrName='iban' />
                <DataLabel lockey="NEN-812504" attrName='swift' />
            </GroupBox>
            <GroupBox lockey="NEN-812681" fillContent showIf="(Dodavatel = True or Predregistrovany = True) and (Predmet_cinnosti_org <> null or Rocni_obrat <> null or Rok_vzniku_org <> null or Pocet_zam <> null)">
                <DataLabel lockey="NEN-812531" attrName='predmetCinnostiOrg' fillGrid rawText/>
                <DataLabel lockey="NEN-812548" attrName='rocniObrat' />
                <DataLabel lockey="NEN-812547" attrName='rokVznikuOrg' />
                <DataLabel lockey="NEN-812549" attrName='pocetZam' />
            </GroupBox>
            <GroupBox lockey="NEN-812596" fillContent showIf="Sdruzeni_zadav = true and Vedouci_sdruzeni=null">
                <ListFrameClen routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812848" fillContent showIf="(Dodavatel = True or Predregistrovany = True) and Exists(CPVNIPEZ_subjektu, Subj = &ID)">
                <ListFrameNipez routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812658" fillContent showIf="(Zadavatel = True or Sdruzeni_zadav = True) and Exists(Cinnost_subj, Subj = &ID)">
                <ListFrameHlavniPredmetCinnosti routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812659" fillContent showIf="(Zadavatel = True or Sdruzeni_zadav = True) and Exists(Rel_cinnost_subj, Subj = &ID)">
                <ListFrameRelevantniCinnost routeParamName={routeParamName} />
            </GroupBox>
        </>
    );
}

const clenSdruzeniColumns: ListFrameColumns<ISubjektModel> = [
    {
        attrName: 'nazevOrganizace',
        locKey: 'NEN-812506',
        width: '200%'
    },
    {
        attrName: 'ico',
        locKey: 'NEN-812508',
        width: columnWidths.ico
    },
    {
        attrName: 'kategorieZadavatele',
        locKey: 'NEN-812532',
    },
    {
        attrName: 'nutsKod',
        locKey: 'NEN-812777',
    }
];

const ListFrameClen: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const params = useStaticObject({ filterParamName: 'csd', routeParamName });
    return (
        <DependentListFrame
            listFrameKey={detailSubjektuLoadkey + '-clen-sdruzeni' }
            condition="Vedouci_sdruzeni=@0"
            conditionParams={'id'}
            lightVersion
            attrMapping={SubjektAttrMap}
            columns={clenSdruzeniColumns}
            paramNames={params} />
    );
}

const cpvNipezSubjektuModelColumns: ListFrameColumns<ICpvNipezSubjektuModel> = [
    {
        attrName: 'nipezKod',
        locKey: 'NEN-812585',
        width: 100
    },
    {
        attrName: 'nipezNazev',
        locKey: 'NEN-812582',
        width: 150
    },
    {
        attrName: 'cpvKod',
        locKey: 'NEN-812586',
        width: 100
    },
    {
        attrName: 'cpvNazev',
        locKey: 'NEN-812583',
        width: 150
    }
];

const ListFrameNipez: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const params = useStaticObject({ filterParamName: 'snpz', routeParamName });
    return (
        <DependentListFrame
            listFrameKey={detailSubjektuLoadkey + '-cpv-nipez'}
            lightVersion
            condition="Subj=@0"
            conditionParams={'id'}
            attrMapping={CpvNipezSubjektuAttrMap}
            columns={cpvNipezSubjektuModelColumns}
            paramNames={params} />
    );
}

const cinnostSubjektuColumns: ListFrameColumns<ICinnostSubjektuModel> = [
    {
        attrName: 'predmetCinnostiNazev',
        locKey: 'NEN-812505',
        width: 800
    },
    {
        attrName: 'jinaCinnost',
        locKey: 'NEN-812530',
    }
];

const ListFrameHlavniPredmetCinnosti: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const params = useStaticObject({ filterParamName: 'shpc', routeParamName });
    return (
        <DependentListFrame
            listFrameKey={detailSubjektuLoadkey + '-predmet-cinnosti'}
            condition="Subj=@0"
            conditionParams={'id'}
            lightVersion
            attrMapping={CinnostSubjektuAttrMap}
            columns={cinnostSubjektuColumns}
            paramNames={params} />
    );
}

const relevantniCinnostSubjektuColumns: ListFrameColumns<IRelevantniCinnostSubjektuModel> = [
    {
        attrName: 'relevantniCinnostNazev',
        locKey: 'NEN-812650',
        width: 800
    },
    {
        attrName: 'jinaCinnost',
        locKey: 'NEN-812530',
    }
];

const ListFrameRelevantniCinnost: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const params = useStaticObject({ filterParamName: 'srcs', routeParamName });
    return (
        <DependentListFrame
            listFrameKey={detailSubjektuLoadkey + '-relevantni-cinnost'}
            condition="Subj=@0"
            conditionParams={'id'}
            lightVersion
            attrMapping={RelevantniCinnostSubjektuAttrMap}
            columns={relevantniCinnostSubjektuColumns}
            paramNames={params} />
    );
}

const DalsiInformace: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    useDetailDescription<ISubjektModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812662'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812660" fillContent showIf='Exists(Jina_adresa_subj, Subj = &ID)'>
                <ListFrameJineAdresy routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812661" fillContent>
                <ListFrameKontaktniOsoby routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812559" fillContent showIf='Exists(Jina_informace_subj, Subj = &ID)'>
                <ListFrameDalsiInformace routeParamName={routeParamName} />
            </GroupBox>
        </>
    );
}

const jineAdresySubjektuColumns: ListFrameColumns<IJinaAdresaSubjektuModel> = [
    {
        attrName: 'nazevSubjektu',
        locKey: 'NEN-812600',
        width: 200
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517',
        width: 100
    },
    {
        attrName: 'castObce',
        locKey: 'NEN-812518',
        width: 100
    },
    {
        attrName: 'ulice',
        locKey: 'NEN-812523',
        width: 100
    },
    {
        attrName: 'cisloPopisne',
        locKey: 'NEN-812524',
        width: 100
    },
    {
        attrName: 'cisloOrientacni',
        locKey: 'NEN-812525',
        width: 100
    },
    {
        attrName: 'psc',
        locKey: 'NEN-812526',
        width: 50
    },
    {
        attrName: 'statAdresyKod',
        locKey: 'NEN-812527',
        width: 50
    }
];

const ListFrameJineAdresy: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const params = useStaticObject({ filterParamName: 'sjao', routeParamName });
    return (
        <DependentListFrame
            listFrameKey={detailSubjektuLoadkey + '-jine-adresy'}
            condition="Subj=@0"
            conditionParams={'id'}
            lightVersion
            attrMapping={JinaAdresaSubjektuAttrMap}
            columns={jineAdresySubjektuColumns}
            paramNames={params} />
    );
}

const kontaktniOsobySubjektuColumns: ListFrameColumns<IOsobaModel> = [
    {
        attrName: 'titulPred',
        locKey: 'NEN-812568',
        width: 75
    },
    {
        attrName: 'jmeno',
        locKey: 'NEN-812567',
    },
    {
        attrName: 'prijmeni',
        locKey: 'NEN-812566',
        width: 130
    },
    {
        attrName: 'titulZa',
        locKey: 'NEN-812569',
        width: 75
    },
    {
        attrName: 'email',
        locKey: 'NEN-812580',
        width: 250
    },
    {
        attrName: 'telefonZamestnani',
        locKey: 'NEN-812550',
    },
    {
        attrName: 'mobil',
        locKey: 'NEN-812551',
        width: 130
    }

];

const ListFrameKontaktniOsoby: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const params = useStaticObject({ filterParamName: 'skoo', routeParamName });
    return (
        <DependentListFrame
            listFrameKey={detailSubjektuLoadkey + '-kontaktni-osoby'}
            condition="Patri_do=@0 AND Kontakt = true AND Aktivni_uzivatel = true"
            conditionParams={'id'}
            lightVersion
            attrMapping={OsobaAttrMap}
            columns={kontaktniOsobySubjektuColumns}
            paramNames={params} />
    );
}

const dalsiInformaceSubjektuColumns: ListFrameColumns<IJinaInformaceSubjektu> = [
    {
        attrName: 'popis',
        locKey: 'NEN-812545',
    },
    {
        attrName: 'text',
        locKey: 'NEN-812562'
    }
];

const ListFrameDalsiInformace: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    const params = useStaticObject({ filterParamName: 'sdio', routeParamName });
    return (
        <DependentListFrame
            listFrameKey={detailSubjektuLoadkey + '-dalsi-informace'}
            condition="Subj=@0"
            conditionParams={'id'}
            lightVersion
            attrMapping={JinaInformaceSubjektuAttrMap}
            columns={dalsiInformaceSubjektuColumns}
            paramNames={params} />
    );
}

export default React.memo(DetailSubjektu);
