import Tabs from "@components/Tabs";
import { urlConstants } from "@constants/urlConstants";
import React, { FC } from "react"
import RegistrDns from "../registrDns/RegistrDns";
import RegistrSK from "../registrSK/RegistrSK";
import VerejneZakazky from "../verejneZakazky/VerejneZakazky";
import VestnikNen from "../vestnikNen/VestnikNen";

const VerejneZakazkyPages: FC = () => {
    return (
        <Tabs
            noMargin
            tabs={
                [
                    {
                        lockey: 'NEN-744019',
                        path: urlConstants.verejneZakazky.path,
                        component: VerejneZakazky
                    },
                    {
                        lockey: 'NEN-744020',
                        path: urlConstants.registrDns.path,
                        component: RegistrDns
                    },
                    {
                        lockey: 'NEN-744021',
                        path: urlConstants.vestnik.path,
                        component: VestnikNen
                    },
                    {
                        lockey: 'NEN-812877',
                        path: urlConstants.registrSK.path,
                        component: RegistrSK
                    }
                ]
            } />
    );
};

VerejneZakazkyPages.displayName = 'VerejneZakazkyPages';

export default React.memo(VerejneZakazkyPages);
