import { AttrMapping, IData } from "@utils/AttrMapping";

export type IJinaAdresaSubjektuHist = IData & {
    nazev: string;
    obec: string;
    castObce: string;
    ulice: string;
    cisloPopisne: string;
    cisloOrientacni: string;
    psc: string;
}

export const JinaAdresaSubjektuHistAttrMap = new AttrMapping<IJinaAdresaSubjektuHist>('Jina_adresa_sub_hist', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'nazev', serverName: 'Nazev_Subjektu', type: 'string' },
    { clientName: 'obec', serverName: 'Obec', type: 'string' },
    { clientName: 'castObce', serverName: 'Cast_obce', type: 'string' },
    { clientName: 'ulice', serverName: 'Ulice', type: 'string' },
    { clientName: 'cisloPopisne', serverName: 'Cislo_popisne', type: 'string' },
    { clientName: 'cisloOrientacni', serverName: 'Cislo_orientacni', type: 'string' },
    { clientName: 'psc', serverName: 'PSC', type: 'string' },
]);
