import { ServiceBase } from "./ServiceBase";

export default class CompatibilityTestService extends ServiceBase {
    public async getSysDateTime(): Promise<string> {
        return (await this.requestJson<string>({
            url: `/api/compatibilityTest/sysDateTime`,
            method: 'POST'
        })).value;
    }
}
