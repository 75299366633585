import { AttrMapping, IData } from "@utils/AttrMapping";

export type IDetailUverejneniModel = IData & {
    castObce: string;
    cislo: number;
    cisloOrient: string;
    cisloPop: number;
    cisloSmlouva: string;
    datumOdeslani: Date;
    datumPodpisu: Date;
    datumPotvrz: Date;
    datumUver: Date;
    datumUverInfo: Date;
    datumUzavreni: Date;
    datumVyber: Date;
    dic: string;
    emailAdresa: string;
    formKod: string;
    formNazev: string;
    formTyp: string;
    ico: string;
    icoVed: string;
    idInfoUver: number;
    menaUverKod: string;
    menaUverNazev: string;
    mnnc: boolean;
    mnncDuvod: string;
    nabCenaBez: number;
    nabCenaS: number;
    nazev: string;
    nazevDodPoddod: string;
    nazevVed: string;
    obec: string;
    odkaz: string;
    odkazSmlouva: string;
    odstoupeni: boolean;
    odstoupeniDuvod: string;
    oduvodVyberuDod: string;
    oduvodKomunProstredky: string;
    oduvodNerozdeleni: string;
    oduvodPouziti: string;
    oduvodPouzitiJRBU: string;
    oduvodPouzitiJRSU: string;
    oduvodStanoveniPozadav: string;
    plneniRok: number;
    plneniCenaS: number;
    plneniCenaBez: number;
    pravFormaNazev: string;
    predmetInfo: string;
    predmetZprava: string;
    prijataOpatreni: string;
    psc: string;
    ulice: string;
    uredniNazev: string;
    sdruzDod: boolean;
    smluvniCenaBez: number;
    smluvniCenaBezAkt: number;
    smluvniCenaS: number;
    smluvniCenaSAkt: number;
    soutezCenaBez: number;
    statKod: string;
    statNazev: string;
    text: string;
    textDotaz: string;
    textVysvetleni: string;
    uzavrenaSmlouva: boolean;
    vylouc: boolean;
    vyloucDuvod: string;
    zaklPopisPlneni: string;
}



export const DetailUverejneniAttrMap = new AttrMapping<IDetailUverejneniModel>('Detail_uverejneni', [
    { clientName: 'castObce', serverName: 'Cast_obce', type: 'string' },
    { clientName: 'cislo', serverName: 'Cislo', type: 'number' },
    { clientName: 'cisloOrient', serverName: 'Cislo_orientacni', type: 'string' },
    { clientName: 'cisloPop', serverName: 'Cislo_popisne', type: 'number' },
    { clientName: 'cisloSmlouva', serverName: 'Evidencni_cislo_sml', type: 'string' },
    { clientName: 'datumOdeslani', serverName: 'Datum_odeslani', type: 'dateTime' },
    { clientName: 'datumPodpisu', serverName: 'Datum_zmeny', type: 'date' },
    { clientName: 'datumPotvrz', serverName: 'Datum_potvrzeni', type: 'dateTime' },
    { clientName: 'datumUver', serverName: 'Datum_uverejneni', type: 'dateTime' },
    { clientName: 'datumUverInfo', serverName: 'Info_uver.Datum_uverejneni', type: 'dateTime' },
    { clientName: 'datumUzavreni', serverName: 'Datum_uzavreni', type: 'date' },
    { clientName: 'datumVyber', serverName: 'Datum_vyberu', type: 'date' },
    { clientName: 'dic', serverName: 'DIC', type: 'string' },
    { clientName: 'emailAdresa', serverName: 'Email_adresa', type: 'string' },
    { clientName: 'formKod', serverName: 'Typ_form.Kod_VVZ', type: 'string' },
    { clientName: 'formNazev', serverName: 'Typ_form.Nazev', type: 'string' },
    { clientName: 'formTyp', serverName: 'Typ_formulare', type: 'enum', typeParam: 'Typ_formulare' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'idInfoUver', serverName: 'Info_uver', type: 'id' },
    { clientName: 'ico', serverName: 'IC', type: 'string' },
    { clientName: 'icoVed', serverName: 'ICO_vedouciho', type: 'string' },
    { clientName: 'menaUverKod', serverName: 'Mena_uver.Kod', type: 'string' },
    { clientName: 'menaUverNazev', serverName: 'Mena_uver.Nazev', type: 'string' },
    { clientName: 'mnnc', serverName: 'Vyrazena_pro_MNNC', type: 'bool' },
    { clientName: 'mnncDuvod', serverName: 'Duvod_MNNC', type: 'string' },
    { clientName: 'nabCenaBez', serverName: 'Nab_cena_bez_DPH', type: 'money', },
    { clientName: 'nabCenaS', serverName: 'Nabidkova_cena', type: 'money', },
    { clientName: 'nazev', serverName: 'Nazev', type: 'string' },
    { clientName: 'nazevDodPoddod', serverName: 'Nazev_dod_poddod', type: 'string' },
    { clientName: 'nazevVed', serverName: 'Nazev_vedouciho', type: 'string' },
    { clientName: 'obec', serverName: 'Obec', type: 'string' },
    { clientName: 'odkaz', serverName: 'Odkaz', type: 'string' },
    { clientName: 'odkazSmlouva', serverName: 'Info_uver.Historie.Odkaz_na_smlouvu', type: 'string' },
    { clientName: 'odstoupeni', serverName: 'Odstoupeni', type: 'bool' },
    { clientName: 'odstoupeniDuvod', serverName: 'Duvod_odstoupeni', type: 'string' },
    { clientName: 'oduvodVyberuDod', serverName: 'Oduv_vyberu_dodavatele', type: 'text' },
    { clientName: 'oduvodKomunProstredky', serverName: 'Oduv_komunikace', type: 'text' },
    { clientName: 'oduvodNerozdeleni', serverName: 'Oduv_nadlimit_casti', type: 'text' },
    { clientName: 'oduvodPouziti', serverName: 'Oduv_JRBU_ZR', type: 'text' },
    { clientName: 'oduvodPouzitiJRBU', serverName: 'Oduv_JRBU', type: 'text' },
    { clientName: 'oduvodPouzitiJRSU', serverName: 'Oduv_JRSU', type: 'text' },
    { clientName: 'oduvodStanoveniPozadav', serverName: 'Oduv_obratu', type: 'text' },
    { clientName: 'plneniRok', serverName: 'Rok', type: 'number' },
    { clientName: 'plneniCenaS', serverName: 'Skutecna_cena', type: 'money', },
    { clientName: 'plneniCenaBez', serverName: 'Cena_bez_PDH', type: 'money', },
    { clientName: 'pravFormaNazev', serverName: 'PF.Nazev', type: 'string' },
    { clientName: 'predmetInfo', serverName: 'Info_uver.Predmet', type: 'string' },
    { clientName: 'predmetZprava', serverName: 'Nazev', type: 'string' },
    { clientName: 'prijataOpatreni', serverName: 'Prijata_opatreni', type: 'text' },
    { clientName: 'psc', serverName: 'PSC', type: 'string' },
    { clientName: 'ulice', serverName: 'Ulice', type: 'string' },
    { clientName: 'uredniNazev', serverName: 'Uredni_nazev', type: 'string' },
    { clientName: 'sdruzDod', serverName: 'Sdruzeni_dodavatelu', type: 'bool' },
    { clientName: 'smluvniCenaBez', serverName: 'Smluvni_cena_bez_DPH', type: 'money', },
    { clientName: 'smluvniCenaBezAkt', serverName: 'Sml_cena_bez_DPH_akt', type: 'money', },
    { clientName: 'smluvniCenaS', serverName: 'Smluvni_cena_s_DPH', type: 'money', },
    { clientName: 'smluvniCenaSAkt', serverName: 'Sml_cena_s_DPH_akt', type: 'money', },
    { clientName: 'soutezCenaBez', serverName: 'Soutez_cena_bez_DPH', type: 'money', },
    { clientName: 'statKod', serverName: 'Stat_uver.Kod', type: 'string' },
    { clientName: 'statNazev', serverName: 'Stat_uver.Nazev', type: 'string' },
    { clientName: 'text', serverName: 'Info_uver.Text', type: 'string' },
    { clientName: 'textDotaz', serverName: 'Text_dotazu', type: 'string' },
    { clientName: 'textVysvetleni', serverName: 'Informace', type: 'text' },
    { clientName: 'uzavrenaSmlouva', serverName: 'Uzavrena_smlouva', type: 'bool' },
    { clientName: 'vylouc', serverName: 'Vylouceni', type: 'bool' },
    { clientName: 'vyloucDuvod', serverName: 'Duvod_vylouceni', type: 'text' },
    { clientName: 'zaklPopisPlneni', serverName: 'Zakl_popis_plneni', type: 'text' },
]);
