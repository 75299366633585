import { FilterManagerLovConditionFactory } from "@components/FilterManager/FilterManagerLov/FilterManagerLov";
import { createIn, joinConditionsByAnd } from "@utils/FilterCondition";


export const kodNazevAttrs = ['kod', 'nazev'];

export const lovCPVConditionFactory = (paramName: string): FilterManagerLovConditionFactory => (urlParams) => {
    const cpv = urlParams?.[paramName];
    const condition = joinConditionsByAnd(
        'Plati_DO = null',
        cpv ? `EXISTS(NIPEZ,${createIn('ID', cpv)} AND CPV_NIPEZU=&ID)` : null
    );
    return condition as string;
}

export const lovNIPEZConditionFactory = (paramName: string): FilterManagerLovConditionFactory => (urlParams) => {
    const nipez = urlParams?.[paramName];
    const condition = joinConditionsByAnd(
        'Plati_DO = null AND CPV_NIPEZu <> null',
        createIn('CPV_NIPEZU', nipez)
    );
    return condition as string;
}