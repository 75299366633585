import { ServiceBase, Result } from "./ServiceBase";

export interface IEvaluateConditionsServiceParams {
    className: string;
    id: string;
    conditions: string[];
    abortSignal?: AbortSignal;
}

export type EvaluatedCondition = {
    condition: string,
    result: boolean
}

export default class EvaluateConditionsService extends ServiceBase {
    public async evaluateConditions<T extends EvaluatedCondition>(params: IEvaluateConditionsServiceParams): Promise<Result<T[]>> {
        return await this.requestJson<T[]>({
            url: `/api/evaluateConditions?className=${params.className}id=${params.id}`,
            method: 'POST',
            abortSignal: params.abortSignal,
            data: params
        });
    }
}