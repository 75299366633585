import CryptoDetail from "crypto/CryptoDetail/CryptoDetail";
import { cryptoNativeCheck, CryptoNativeCheckResult } from "crypto/CryptoUtils";
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react"
import { useCurrentLanguage, useLocalization } from "@store/localizationStore";
import { useCompatibilityTestService, useCryptoService, useInstanceService } from "@store/nodeContextStore";
import { is } from "@utils/ArrayHelper";
import { setupCn, Classes } from "@utils/BemUtils";
import { usePageTitle } from "@utils/PageTitleHook";
import { checkBrowser, checkCookies, checkMinimalResulution, checkMultiwebTests, checkRecommendedResulution, checkServiceWorker, DateTimeDifferenceResult, getActualResolution, getBrowserAndOs, getDateTimeDifferenceResult, indexedDBTest, IndexedDBTestResult, MultiwebTestResult, popupWindowCheck, ResolutionTestResult, ServiceWorkerResult, signTest, SignTestResult, TestResult, testResultSwitch } from "./CompatibilityTests";
import CryptoTestResult from "./Components/CryptoTestResult/CryptoTestResult";
import ResultBadge from "./Components/ResultBadge/ResultBadge";
import ResultText from "./Components/ResultText/ResultText";
import Button from "@components/Button/Button";
import ContentBlock from "@components/ContentBlock/ContentBlock";
import Blockquote from "@components/Blockquote/Blockquote";

import './CompatibilityTest.scss';

const cn = setupCn('compatibility-test');
const subRowHeaderCn = cn.setupSubCn('__sub-row-header');

const nevyhodnocenoLK = 'NEN-746904';
const nepodporovanoLK = 'NEN-746912';
const povolenoLK = 'NEN-746914';
const anoLK = 'NEN-746905';
const neLK = 'NEN-746906';
const nepovolenoLK = 'NEN-746924';
const podporovanoLK = 'NEN-748097';
const neuplnneLK = 'NEN-748101';
const castecnePodporovanoLK = 'NEN-748098';
const zakazanoLK = 'NEN-748104';

export type CompatibilityTestProps = PropsWithChildren<{
    className?: Classes;
}>;

type UseTestStateParams<T> = {
    test: () => Promise<T>;
    privateResultFnc: (result: T) => TestResult;
    publicResultFnc: (result: T) => TestResult;
    afterTest?: (result: RunTestResult<T>) => void;
}

type RunTestResult<T> = {
    privateResult: TestResult;
    publicResult: TestResult;
    result?: T;
}

const useTestState = <T extends unknown>({ test, publicResultFnc, privateResultFnc, afterTest }: UseTestStateParams<T>) => {
    const [testResult, setTestResult] = useState<RunTestResult<T>>({
        privateResult: TestResult.notEvaluated,
        publicResult: TestResult.notEvaluated,
        result: undefined
    });

    const runningPromise = useRef<Promise<RunTestResult<T>>>();
    const runTestFnc = useCallback(async (): Promise<RunTestResult<T>> => {
        try {
            setTestResult({
                privateResult: TestResult.evaluating,
                publicResult: TestResult.evaluating,
                result: undefined
            });
            const result = await test();
            const newTestResult: RunTestResult<T> = {
                result,
                publicResult: publicResultFnc(result),
                privateResult: privateResultFnc(result)
            };
            setTestResult(newTestResult);
            afterTest?.(newTestResult);
            return newTestResult;
        } catch {
            const newResult = {
                privateResult: TestResult.nonCheckable,
                publicResult: TestResult.nonCheckable
            };
            setTestResult(newResult);
            return newResult;
        }
    }, [test, privateResultFnc, publicResultFnc]);

    const runTest = useCallback(() => {
        runningPromise.current = runTestFnc();
    }, [runTestFnc]);

    return {
        ...testResult,
        runTest,
        runningPromise
    }
}

type UseTestStateType = ReturnType<typeof useTestState>;

const isResult = (states: TestResult[], resultSelector: (result: RunTestResult<unknown>) => TestResult) => (test: PromiseSettledResult<RunTestResult<unknown>>) => {
    return test.status == 'fulfilled' && test.value && is(resultSelector(test.value), states);
}

const isNotResult = (states: TestResult[], resultSelector: (result: RunTestResult<unknown>) => TestResult) => (test: PromiseSettledResult<RunTestResult<unknown>>) => {
    return test.status == 'rejected' || test.value == null || !is(resultSelector(test.value), states);
}

const runTests = (...tests: UseTestStateType[]) => tests.forEach(test => test.runTest());
const runTestsIfNot = (...tests: UseTestStateType[]) => tests.forEach(test => test.runningPromise.current == null && test.runTest());

const allTestOk = async (...tests: UseTestStateType[]) => {
    runTestsIfNot(...tests);
    const testsPromises = tests.map(test => test.runningPromise.current).filter(a => a) as Promise<RunTestResult<unknown>>[];
    const testsResults = await Promise.allSettled(testsPromises);
    const privateResult = testsResults.every(isResult([TestResult.ok], res => res.privateResult)) ? TestResult.ok :
        testsResults.some(isNotResult([TestResult.ok, TestResult.partially], res => res.privateResult)) ? TestResult.failed : TestResult.partially;
    const publicResult = testsResults.every(isResult([TestResult.ok], res => res.publicResult)) ? TestResult.ok :
        testsResults.some(isNotResult([TestResult.ok, TestResult.partially], res => res.publicResult)) ? TestResult.failed : TestResult.partially;
    return { privateResult, publicResult };
}

const getfinallyTestResultLK = (result: TestResult) => testResultSwitch(result, {
    defaultValue: nevyhodnocenoLK,
    evaluating: 'NEN-748109',
    ok: 'NEN-748110',
    partially: 'NEN-748111',
    failed: 'NEN-748112'
})

const CompatibilityTest: FC<CompatibilityTestProps> = ({
    className
}) => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-744259'));
    const testService = useCompatibilityTestService();
    const cryptoService = useCryptoService();
    const instanceService = useInstanceService();
    const language = useCurrentLanguage();
    const {
        browser,
        browserName,
        browserOs
    } = getBrowserAndOs();
    const resolution = getActualResolution();
    const cryptoNativeTest = useTestState<CryptoNativeCheckResult>({
        test: () => cryptoNativeCheck({ language }),
        publicResultFnc: res => res.result,
        privateResultFnc: res => res.result
    });
    const browserTypeTest = useTestState({
        test: () => Promise.resolve(checkBrowser()),
        publicResultFnc: res => res.publicResult,
        privateResultFnc: res => res.privateResult
    });
    const serviceWorkerTest = useTestState<ServiceWorkerResult>({
        test: () => Promise.resolve(checkServiceWorker()),
        publicResultFnc: () => TestResult.ok,
        privateResultFnc: res => res.result
    });
    const indexedDBUseTest = useTestState<IndexedDBTestResult>({
        test: () => indexedDBTest(),
        publicResultFnc: () => TestResult.ok,
        privateResultFnc: res => res.result
    });
    const popupsTest = useTestState<TestResult>({
        test: () => new Promise(resolve => setTimeout(() => resolve(popupWindowCheck()), 2000)),
        publicResultFnc: () => TestResult.ok,
        privateResultFnc: res => res == TestResult.failed ? TestResult.partially : res
    });
    const multiwebSupportTest = useTestState<MultiwebTestResult>({
        test: () => checkMultiwebTests(),
        publicResultFnc: () => TestResult.ok,
        privateResultFnc: res => res
    });
    const signingTest = useTestState<SignTestResult>({
        test: useCallback(() => signTest({ instanceService, cryptoService, language }), [instanceService, cryptoService, language]),
        publicResultFnc: () => TestResult.ok,
        privateResultFnc: res => res.result
    });
    const cookiesEnabledTest = useTestState<TestResult>({
        test: () => Promise.resolve(checkCookies()),
        publicResultFnc: res => res,
        privateResultFnc: res => res
    });
    const minimalResolutionTest = useTestState<ResolutionTestResult>({
        test: () => Promise.resolve(checkMinimalResulution()),
        publicResultFnc: res => res.publicResult,
        privateResultFnc: res => res.privateResult,
    });
    const recommendedResolutionTest = useTestState<ResolutionTestResult>({
        test: () => Promise.resolve(checkRecommendedResulution()),
        publicResultFnc: res => res.publicResult,
        privateResultFnc: res => res.privateResult
    });
    const timeDifferenceTest = useTestState<DateTimeDifferenceResult>({
        test: () => testService.getSysDateTime().then(getDateTimeDifferenceResult),
        publicResultFnc: res => res.result,
        privateResultFnc: res => res.result
    });
    const browserTestsResult = useTestState({
        test: () => allTestOk(
            serviceWorkerTest,
            indexedDBUseTest,
            cookiesEnabledTest,
            popupsTest,
            multiwebSupportTest,
            browserTypeTest
        ),
        publicResultFnc: res => res.publicResult,
        privateResultFnc: res => res.privateResult,
    });
    const allTestResult = useTestState({
        test: () => allTestOk(
            browserTestsResult,
            timeDifferenceTest,
            minimalResolutionTest
        ),
        publicResultFnc: res => res.publicResult,
        privateResultFnc: res => res.privateResult,
    });

    useEffect(() => {
        runTests(
            allTestResult,
            recommendedResolutionTest,
            cryptoNativeTest
        );
    }, []);

    const handleSignTest = useCallback(() => {
        signingTest.runTest();
    }, [signingTest.runTest]);

    const { ts } = useLocalization();
    const signTestDisabled = !is(cryptoNativeTest.publicResult, [TestResult.ok]) || is(signingTest.publicResult, [TestResult.evaluating]);

    return (
        <ContentBlock>
            <table className={cn('__table')}>
                <thead>
                    <tr>
                        <th rowSpan={2}></th>
                        <th rowSpan={2}>
                            <div>
                                <ResultBadge result={allTestResult.privateResult}
                                    failedText={ts(nepodporovanoLK)}
                                    partiallyText={ts(castecnePodporovanoLK)} />
                                {ts('NEN-746916')}
                                <ResultText result={allTestResult.privateResult}>
                                    {ts(getfinallyTestResultLK(allTestResult.privateResult))}
                                    </ResultText>
                            </div>
                        </th>
                        <th className='border-bottom-0 pb-0'>
                            <div>
                                <ResultBadge result={allTestResult.publicResult}
                                    failedText={ts(nepodporovanoLK)}
                                    partiallyText={ts(castecnePodporovanoLK)} />
                                {ts('NEN-746915')}
                                <ResultText result={allTestResult.publicResult}>
                                    {ts(getfinallyTestResultLK(allTestResult.publicResult))}
                                </ResultText>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{ts('NEN-746917')}</th>
                        <td>HTML5</td>
                        <td>HTML5</td>
                    </tr>
                    <tr>
                        <th scope="row">{ts('NEN-746918')}</th>
                        <td>
                            {browserOs}
                        </td>
                        <td>
                            {browserOs}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{ts('NEN-746919')}</th>
                        <td>
                            <ResultBadge
                                result={browserTestsResult.privateResult}
                                partiallyText={ts(castecnePodporovanoLK)}
                                failedText={ts(nepodporovanoLK)} />
                            {
                                ts(testResultSwitch(browserTestsResult.privateResult, {
                                    defaultValue: nevyhodnocenoLK,
                                    partially: neuplnneLK,
                                    ok: podporovanoLK,
                                    failed: neLK
                                }))
                            }
                        </td>
                        <td>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <ResultBadge
                                        result={browserTestsResult.publicResult}
                                        partiallyText={ts(castecnePodporovanoLK)}
                                        failedText={ts(nepodporovanoLK)} />
                                    {
                                        ts(testResultSwitch(browserTestsResult.publicResult, {
                                            defaultValue: nevyhodnocenoLK,
                                            partially: neuplnneLK,
                                            ok: podporovanoLK,
                                            failed: neLK
                                        }))
                                    }
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-770532')}</th>
                        <td>
                            <ResultBadge
                                result={browserTypeTest.privateResult}
                                failedText={ts(nepodporovanoLK)} />
                            {browserName} {browser?.version}
                        </td>
                        <td>
                            <ResultBadge
                                result={browserTypeTest.publicResult}
                                partiallyText={ts(castecnePodporovanoLK)}
                                failedText={ts(nepodporovanoLK)} />
                            {browserName} {browser?.version}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-746920')}</th>
                        <td>
                            <ResultBadge
                                result={serviceWorkerTest.privateResult}
                                failedText={ts(nepodporovanoLK)} />
                            {
                                ts(testResultSwitch(serviceWorkerTest.result?.result, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: anoLK,
                                    failed: neLK
                                }))
                            }
                            {
                                !serviceWorkerTest.result?.messageLocKey ? null :
                                    <>
                                        <br />
                                        {ts(serviceWorkerTest.result.messageLocKey)}
                                    </>
                            }
                        </td>
                        <td>
                            <ResultBadge
                                result={serviceWorkerTest.publicResult}
                                failedText={ts(nepodporovanoLK)} />
                            {
                                ts(testResultSwitch(serviceWorkerTest.result?.result, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: anoLK,
                                    failed: neLK
                                }))
                            }
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-746921')}</th>
                        <td>
                            <ResultBadge
                                result={indexedDBUseTest.privateResult}
                                failedText={ts(nepodporovanoLK)} />
                            {
                                ts(testResultSwitch(indexedDBUseTest.result?.result, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: anoLK,
                                    failed: neLK
                                }))
                            }
                            {
                                !indexedDBUseTest?.result?.messageLocKey ? null :
                                    <>
                                        <br />
                                        {ts(indexedDBUseTest.result.messageLocKey)}
                                    </>
                            }
                        </td>
                        <td>
                            <ResultBadge
                                result={indexedDBUseTest.publicResult}
                                failedText={ts(nepodporovanoLK)} />
                            {
                                ts(testResultSwitch(indexedDBUseTest.result?.result, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: anoLK,
                                    failed: neLK
                                }))
                            }
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-746922')}</th>
                        <td>
                            <ResultBadge result={cookiesEnabledTest.privateResult} />
                            {
                                ts(testResultSwitch(cookiesEnabledTest.result, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: povolenoLK,
                                    failed: neLK
                                }))
                            }
                        </td>
                        <td>
                            <ResultBadge result={cookiesEnabledTest.publicResult} />
                            {
                                ts(testResultSwitch(cookiesEnabledTest.result, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: povolenoLK,
                                    failed: neLK
                                }))
                            }
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-746923')}</th>
                        <td>
                            <ResultBadge
                                result={popupsTest.privateResult}
                                partiallyText={ts(zakazanoLK)}
                                notEvaluatedText={ts(nevyhodnocenoLK)}
                                failedText={ts(nepovolenoLK)} />
                            {ts(testResultSwitch(popupsTest.result, {
                                defaultValue: nevyhodnocenoLK,
                                ok: povolenoLK,
                                failed: neLK
                            }))}
                            {
                                popupsTest.result != TestResult.failed ? null :
                                    <>
                                        <br />
                                        {ts('NEN-749339')}
                                    </>
                            }
                        </td>
                        <td>
                            <ResultBadge
                                result={popupsTest.publicResult}
                                notEvaluatedText={ts(nevyhodnocenoLK)}
                                failedText={ts(nepovolenoLK)} />
                            {ts(testResultSwitch(popupsTest.result, {
                                defaultValue: nevyhodnocenoLK,
                                ok: povolenoLK,
                                failed: neLK
                            }))}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-746925')}</th>
                        <td>
                            <ResultBadge
                                result={multiwebSupportTest.privateResult}
                                failedText={ts(nepodporovanoLK)} />
                            {
                                ts(testResultSwitch(multiwebSupportTest.result, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: anoLK,
                                    failed: neLK
                                }))
                            }
                        </td>
                        <td>
                            <ResultBadge
                                result={multiwebSupportTest.publicResult}
                                failedText={ts(nepodporovanoLK)} />
                            {
                                ts(testResultSwitch(multiwebSupportTest.publicResult, {
                                    defaultValue: nevyhodnocenoLK,
                                    ok: anoLK,
                                    failed: neLK
                                }))}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{ts('NEN-746934')}</th>
                        <td>
                            <ResultBadge result={minimalResolutionTest.privateResult} />
                            {resolution}
                        </td>
                        <td>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <ResultBadge result={minimalResolutionTest.publicResult} />
                                    {resolution}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-746926')}</th>
                        <td>
                            <ResultBadge result={minimalResolutionTest.privateResult} />
                            {minimalResolutionTest.result?.resolutionPrivateText}
                        </td>
                        <td>
                            <ResultBadge result={minimalResolutionTest.publicResult} />
                            {minimalResolutionTest.result?.resolutionPublicText}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row" className={subRowHeaderCn()}>{ts('NEN-746927')}</th>
                        <td>
                            <ResultBadge result={recommendedResolutionTest.privateResult} />
                            {recommendedResolutionTest.result?.resolutionPrivateText}
                        </td>
                        <td>
                            <ResultBadge result={recommendedResolutionTest.publicResult} />
                            {recommendedResolutionTest.result?.resolutionPublicText}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{ts('NEN-746930')}</th>
                        <td>
                            {timeDifferenceTest.result?.timeSpan}
                        </td>
                        <td>
                            {timeDifferenceTest.result?.timeSpan}
                        </td>
                    </tr>
                </tbody>
                <tbody className={cn("__crypto")}>
                    <tr>
                        <th scope="row">{ts('NEN-746928')}</th>
                        <td colSpan={2}>
                            {
                                cryptoNativeTest.publicResult == TestResult.evaluating &&
                                    <ResultBadge
                                        result={cryptoNativeTest.publicResult}
                                        evaluatingText={ts('NEN-824286')} />
                            }
                            <CryptoTestResult result={cryptoNativeTest.result} />
                            {
                                cryptoNativeTest.publicResult != TestResult.evaluating &&
                                <Button
                                    disabled={signTestDisabled}
                                    size='normal'
                                    onClick={handleSignTest}>
                                    {ts('NEN-746929')}
                                </Button>
                            }
                            {
                                is(cryptoNativeTest.publicResult, [TestResult.ok, TestResult.notEvaluated, TestResult.evaluating]) ? null :
                                    <>
                                        <Blockquote type='warning'>{ts('NEN-746913')}</Blockquote>
                                        <Blockquote type='error'>{ts('NEN-748118')}</Blockquote>
                                    </>
                            }
                            {
                                signingTest.publicResult != TestResult.evaluating ? null :
                                    <div className='mt-10'>
                                        <ResultBadge result={TestResult.evaluating} />
                                    </div>
                            }
                            {
                                !signingTest.result?.signatureInfo ? null :
                                    <CryptoDetail
                                        id='test-crypto-detail'
                                        cryptoInfo={signingTest.result.signatureInfo} />
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </ContentBlock>
    );
};

CompatibilityTest.displayName = 'CompatibilityTest';

export default React.memo(CompatibilityTest);
