import { Classes, setupCn } from "@utils/BemUtils";
import React, { MouseEventHandler, PropsWithChildren } from "react";
import { FC } from "react"
import { Link, LinkProps } from "react-router-dom";

const cn = setupCn('gov-pagination__item');

export type PaginationPageProps =
    Pick<LinkProps, 'to'> &
    PropsWithChildren<{
        className?: Classes;
        isActive?: boolean;
        onClick?: MouseEventHandler;
    }>;

const PaginationPage: FC<PaginationPageProps> = ({ className, to, isActive, onClick, children }) => (
    <Link
        to={to}
        onClick={onClick}
        aria-current={isActive}
        className={cn.with(className).main({
            '--active': isActive
        })}>
        {children}
    </Link>
);

export default React.memo(PaginationPage);
