import { DependencyList, EventHandler, SyntheticEvent, useCallback } from "react";

export const withPreventDefault = <E extends SyntheticEvent<unknown>, T extends EventHandler<E>>(eventHandler: T): EventHandler<E> => {
    return (event: E) => {
        event?.preventDefault();
        eventHandler?.(event);
    };
}

export const usePreventDefaultCallback = <E extends SyntheticEvent<unknown>, T extends EventHandler<E>>(eventHandler: T | null | undefined, deps: DependencyList): EventHandler<E> => {
    return useCallback((event: E) => {
        event?.preventDefault();
        eventHandler?.(event);
    }, deps);
}

export const useStopPropagationCallback = <E extends SyntheticEvent<unknown>, T extends EventHandler<E>>(eventHandler: T | null | undefined, deps: DependencyList): EventHandler<E> => {
    return useCallback((event: E) => {
        event?.stopPropagation();
        eventHandler?.(event);
    }, deps);
}

export const stopPropagation: EventHandler<SyntheticEvent<unknown>> = (event) => event.stopPropagation();
