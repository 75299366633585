import { FC } from "react";
import { useBreadcrumb } from "@store/breadcrumbStore";
import GovLink from "@components/GovLink/GovLink";
import Skeleton from "react-loading-skeleton";
import { setupCn } from "@utils/BemUtils";
import Container from "@components/Container";
import offsets from "../../styles/offsets";
import { useLocalization } from "@store/localizationStore";

import './Breadcrumb.scss';
import React from "react";

const cn = setupCn('gov-breadcrumbs');

export const Breadcrumb: FC = () => {
    const { t } = useLocalization();
    const [, , items, lastItem] = useBreadcrumb();

    if (!lastItem)
        return null;

    return (
        <Container className={offsets['u-pb--unset']}>
            <nav aria-label={t('NEN-645485')} className={cn()}>
                {
                    items.map(({ href, title }, index) =>
                        <React.Fragment key={href} >
                            {index > 0 && <span className={cn.subCn('__arrow')} aria-hidden='true'></span>}
                            <span className={cn.subCn('__item')}>
                                {
                                    index < items.length - 1 ?
                                        <GovLink
                                            to={href}
                                            title={title}
                                            isStandalone>
                                            {title || <Skeleton width={60} />}
                                        </GovLink>
                                        :
                                        <strong
                                            aria-current="page"
                                            className="gov-title gov-title--delta">
                                            {title || <Skeleton width={60} />}
                                        </strong>
                                }
                            </span>
                        </React.Fragment>
                    )
                }
            </nav>
        </Container>
    );
}
