import React, { FC } from "react"
import { setupCn } from "@utils/BemUtils";
import Text, { TextProps } from '../Text';

const cn = setupCn('gov-note');

export type NoteProps = TextProps;

const Note: FC<NoteProps> = ({
    className,
    ...props
}) => {
    return (
        <Text
            className={cn.with(className).main()}
            {...props} />
    );
};

Note.displayName = 'Note';

export default React.memo(Note);
