import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isNode } from "@utils/node-utils";
import queryString from "query-string";
import { NodeContextHttp } from "@store/nodeContextStore";
import { ClientError } from "@store/errorStore";

export interface IRequestOptions {
    url: string;
    data?: any;
    abortSignal?: AbortSignal;
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
}

export class Result<T> {
    public value: T;
    public errors: (ClientError | string)[];
    public get hasErrors(): boolean {
        return this.errors != null && Array.isArray(this.errors) && this.errors.length > 0;
    }

    constructor(value: T, ...errors: (ClientError | string)[]) {
        this.value = value;
        this.errors = errors?.[0] ? errors : [];
    }
}

export abstract class ServiceBase {
    protected _nodeContextHttp: NodeContextHttp;

    constructor(nodeContext: NodeContextHttp) {
        this._nodeContextHttp = nodeContext;
    }

    /**
     * Make request with JSON data.
     * @param opts
     */
    protected async requestJson<T>({
        url,
        method,
        abortSignal,
        data
    }: IRequestOptions): Promise<Result<T>> {
        let axiosResult: AxiosResponse<any> = {} as AxiosResponse<any>;
        let result = null;
        let axiosRequestConfig: AxiosRequestConfig = {
            headers: {
                "X-XSRF-TOKEN": this._nodeContextHttp.cookies.getCookie("XSRF-TOKEN-Client"),
                "Language": this._nodeContextHttp.getLanguage(),
            },
            signal: abortSignal
        };

        const processQuery = (url: string, data: any): string => {
            if (data) {
                return `${url}?${queryString.stringify(data)}`;
            }
            return url;
        };

        if (isNode && this._nodeContextHttp) {
            url = this._nodeContextHttp.host + url;

            // Make SSR requests 'authorized' from the NodeServices to the web server.
            axiosRequestConfig = {
                ...axiosRequestConfig,
                headers: {
                    ...axiosRequestConfig.headers,
                    Cookie: this._nodeContextHttp.cookies.cookies,
                },

                httpsAgent: new (require("https").Agent)({
                    rejectUnauthorized: false
                }),
            }
        }

        try {
            switch (method) {
                case "GET":
                    axiosResult = await Axios.get(processQuery(url, data), axiosRequestConfig);
                    break;
                case "POST":
                    axiosResult = await Axios.post(url, data, axiosRequestConfig);
                    break;
                case "PUT":
                    axiosResult = await Axios.put(url, data, axiosRequestConfig);
                    break;
                case "PATCH":
                    axiosResult = await Axios.patch(url, data, axiosRequestConfig);
                    break;
                case "DELETE":
                    axiosResult = await Axios.delete(processQuery(url, data), axiosRequestConfig);
                    break;
            }

            if (isNode && this._nodeContextHttp && axiosResult && axiosResult.headers['set-cookie']) {
                this._nodeContextHttp.cookies.updateCookies((axiosResult.headers['set-cookie'] as string[]));
            }

            result = new Result(axiosResult.data);
        } catch (error) {
            if (Axios.isCancel(error)) {
                result = new Result({});
            } else if (Axios.isAxiosError(error)) {
                result = new Result(null, error.response?.data ?? error.message);
            } else {
                result = new Result(null, error);
            }
        }

        return result;
    }
}