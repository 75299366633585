import { TestResult } from "@components/CompatibilityTest/CompatibilityTests";
import React, { FC, PropsWithChildren, ReactNode } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn } from "@utils/BemUtils";

import './_badges.scss';

const cn = setupCn('gov-badge');

export const booleanTestResult = (value: boolean | undefined | null, nullResult: TestResult = TestResult.notEvaluated, okResult: TestResult = TestResult.ok, failedResult: TestResult = TestResult.failed) => {
    return value == null ? nullResult :
        value ? okResult : failedResult;
}

export type ResultBadgeProps = PropsWithChildren<{
    result: TestResult;
    spaceLeft?: boolean;
    onClick?: React.MouseEventHandler;
    okText?: ReactNode;
    failedText?: ReactNode;
    evaluatingText?: ReactNode;
    notEvaluatedText?: ReactNode;
    partiallyText?: ReactNode;
    nonCheckableText?: ReactNode;
    clickable?: boolean;
    noSpace?: boolean;
}>;

const ResultBadge: FC<ResultBadgeProps> = ({
    okText,
    failedText,
    evaluatingText,
    notEvaluatedText,
    partiallyText,
    nonCheckableText,
    clickable,
    onClick,
    result,
    noSpace,
    spaceLeft,
    children
}) => {
    let info: [string, ReactNode];
    const { ts } = useLocalization();
    switch (result) {
        case TestResult.ok:
            info = [cn('--success'), okText ?? 'OK'];
            break;
        case TestResult.failed:
            info = [cn('--error'), failedText ?? ts('NEN-746907')];
            break;
        case TestResult.evaluating:
            info = [cn('--light'), evaluatingText ?? ts('NEN-746908')];
            break;
        case TestResult.notEvaluated:
            info = [cn('--dark'), notEvaluatedText ?? ts('NEN-747754')];
            break;
        case TestResult.partially:
            info = [cn('--warning'), partiallyText ?? ts('NEN-746910')];
            break;
        case TestResult.nonCheckable:
        default:
            info = [cn('--inversed'), nonCheckableText ?? ts('NEN-746909')]
    }

    const mainClassName = cn.with(info[0]).main({
        '--no-space': noSpace,
        '--space-left': !noSpace && spaceLeft,
        '--space-right': !noSpace && !spaceLeft
    });

    return (
        clickable ?
            <button onClick={onClick} className={mainClassName}>{children ?? info[1]}</button> :
            <span onClick={onClick} className={mainClassName}>{children ?? info[1]}</span>
    )
}

ResultBadge.displayName = 'ResultBadge';

export default React.memo(ResultBadge);
