import { useId } from "@utils/UseId";
import React, { FC, MouseEventHandler, PropsWithChildren, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { setupCn, Classes } from "utils/BemUtils";
import './AccordionPage.scss';

const cn = setupCn('gov-accordion');

export type AccordionPageProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    isExpanded?: boolean;
    onToggle?: MouseEventHandler;
    header: ReactNode;
    noTopBorder?: boolean;
}>;

type AccordionPageStatus = 'visible' | 'hidding' | 'hidding-2' | 'hidden';

type StatusType = {
    status: AccordionPageStatus;
    height?: string;
    minHeight?: string;
};

const AccordionPage: FC<AccordionPageProps> = ({
    id,
    className,
    header,
    isExpanded,
    noTopBorder,
    onToggle,
    children
}) => {
    const contentRef = useRef<HTMLDivElement>(null);

    const { generateId } = useId({ id });

    const contentId = generateId('content');
    const headerId = generateId('header');

    const [status, setStatus] = useState<StatusType>({ status: isExpanded ? 'visible' : 'hidden' });

    if (onToggle == null) {
        isExpanded = status.status == 'visible';
    }

    const actualStatus: StatusType = isExpanded && status.status !== 'visible' ? { status: 'visible' } : status;

    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (!isExpanded) {
            setStatus({ status: 'hidding' });
        }
    }, [isExpanded]);

    const toggleStatus = useCallback(() => {
        setStatus({ status: actualStatus.status == 'visible' ? 'hidden' : 'visible' })
    }, [actualStatus.status]);

    useLayoutEffect(() => {
        const element = contentRef.current;
        if (!element) {
            return;
        }
        switch (actualStatus.status) {
            case 'hidding':
                const contentHeight = element.scrollHeight;
                requestAnimationFrame(() => {
                    setStatus({ status: 'hidding-2', height: contentHeight + 'px' });
                });
                break;
            case 'hidding-2':
                requestAnimationFrame(() => setStatus({ status: 'hidden' }));
                break;
            case 'visible':
                setStatus({ status: 'visible', minHeight: element.scrollHeight + 'px' });
                break;

        }
    }, [actualStatus.status]);

    return (
        <>
            <button
                className={cn.with(className, {
                    'is-expanded': isExpanded
                }).subCn('__header', {
                    '--noborder': noTopBorder,
                })}
                onClick={onToggle ?? toggleStatus}
                aria-expanded={isExpanded}
                aria-controls={contentId}>
                <h3 className={cn('__header-title')} id={headerId}>
                    {header}
                </h3>
            </button>
            <div
                ref={contentRef}
                role='region'
                className={cn.subCn('__content', {
                    '--is-visible': actualStatus.status == 'visible',
                    '--is-hidding': actualStatus.status == 'hidding' || actualStatus.status == 'hidding-2',
                })}
                style={{
                    height: actualStatus.height,
                    minHeight: actualStatus.minHeight
                }}
                id={contentId}
                aria-labelledby={headerId}>
                <div className={cn('__content-inner')}>
                    {children}
                </div>
            </div>
        </>
    );
};

AccordionPage.displayName = 'AccordionPage';

export default React.memo(AccordionPage);
