import React, { useEffect, useRef, useState } from 'react';
import { setupCn } from '@utils/BemUtils';
import Button from '@components/Button/Button';
import { useLocalization } from '@store/localizationStore';
import { createPortal } from 'react-dom';
import './Modal.scss';

const cn = setupCn('gov-modal');

export type ModalProps = {
    onClose: () => void;
    headerText: React.ReactNode;
    buttonText?: string;
    noContentOffset?: boolean;
}

const Modal: React.FC<ModalProps> = ({ onClose, headerText, buttonText, noContentOffset, children }) => {
    const { t } = useLocalization();
    const dialogRef = useRef<HTMLDivElement>(null);
    const [isMounted, setIsMounted] = useState(false);

    const onMouseDown = React.useCallback((event: MouseEvent) => {
        if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
            onClose();
        }
    }, [dialogRef, onClose]);

    const onKeyUp = React.useCallback((event: KeyboardEvent) => {
        if (event.key == 'Escape') {
            onClose();
        }
    }, [onClose]);

    useEffect(() => setIsMounted(true), []);

    useEffect(() => {
        const currentFocus = document.activeElement as HTMLElement;
        dialogRef.current?.focus();
        document.body.style.overflow = 'hidden';

        document.addEventListener('mousedown', onMouseDown);
        document.addEventListener('keyup', onKeyUp);
        return () => {
            document.removeEventListener('mousedown', onMouseDown);
            document.removeEventListener('keyup', onKeyUp);
            document.body.style.overflow = '';
            currentFocus?.focus();
        };
    }, [onMouseDown, onKeyUp]);

    if (!isMounted) {
        return null;
    }

    return createPortal(
        <div className={cn.with('is-active is-visible')()}>
            <div tabIndex={0} ref={dialogRef} role='dialog' aria-modal='true' className={cn('__content')} aria-labelledby='modal-heading'>
                <div className={cn('__header')}>
                    <h2 id='modal-heading'>
                        {headerText}
                    </h2>
                </div>
                <div className={cn.subCn('__content-inner', { '--no-offset': noContentOffset })} style={{ overflow: 'auto' }}>
                    {children}
                </div>
                <Button onClick={onClose} isSecondary className={cn('__close')} aria-labelledby='modal-close'>
                    <span
                        id='modal-close'
                        className='u-sr-only gov-button__label'>
                        {buttonText ?? t('GUI-0')}
                    </span>
                </Button>
            </div>
            <div className={cn('__backdrop')}></div>
        </div>,
        document.body
    );
}

export default Modal;