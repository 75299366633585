import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";

import './Blockquote.scss'

const cn = setupCn('gov-blockquote');

export type BlockquoteType = 'information' | 'warning' | 'error' | 'example' | 'math';

export type BlockquoteProps = PropsWithChildren<{
    type?: BlockquoteType;
    className?: Classes;
}>;

const Blockquote: FC<BlockquoteProps> = ({
    type,
    className,
    children,
}) => {
    type ??= 'information';
    const typeModifier = type != 'information' ? `--${type}` : null;

    return (
        <blockquote
            className={cn.with(className).main(typeModifier)}>
            {children}
        </blockquote>

    );
};

Blockquote.displayName = 'Blockquote';

export default React.memo(Blockquote);
