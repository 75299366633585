import { FC, Fragment, ReactNode, useCallback } from "react";
import { ClientError, useErrorStore } from "@store/errorStore";
import { useLocalization } from "@store/localizationStore";
import { isNode } from "@utils/node-utils";
import Modal from "@components/Modal/Modal";
import { arrayInjectSeparator, uniqueArray } from "@utils/ArrayHelper";
import { SwitchByLanguage, useSwitchByLanguage } from "@utils/LanguageUtils";

const lockeyPattern = 'lockey: ';

const DEFAULT_MESSAGE: SwitchByLanguage = {
    cz: 'Aplikace provedla něco, co jsme nečekali. Zkuste to znovu. V případě opakování problému se obraťte na správce systému',
    en: 'The application did something unexpected, please try again. If the problem still occurs, contact your system administrator',
}

const OFFLINE_MESSAGE: SwitchByLanguage = {
    cz: 'Připojení k serveru se nezdařilo. Zkontrolujte, jestli jste připojení k internetu',
    en: 'Could not establish connection to server. Make sure you are connected to the internet',
}

const TOO_MANY_REQUEST_MESSAGE: SwitchByLanguage = {
    cz: 'Bylo odesláno příliš mnoho požadavků během krátké doby',
    en: 'Too many requests are made within a short period of time',
};

const DEFAULT_HEADER: SwitchByLanguage = {
    cz: 'Něco se nepovedlo',
    en: 'Something went wrong',
}

const CLOSE_TEXT: SwitchByLanguage = {
    cz: 'Zavřít',
    en: 'Close',
}

const isLocKeyError = (errorText: string) => errorText.includes?.(lockeyPattern);

const useErrorMessage = (errors: ClientError[]): { text: ReactNode, title?: ReactNode } | undefined => {
    const { switchByLanguage } = useSwitchByLanguage();
    const { ts } = useLocalization();

    if (!errors?.length) {
        return undefined;
    }

    if (!isNode && window?.navigator?.onLine == false) {
        return { text: switchByLanguage(OFFLINE_MESSAGE) };
    }

    if (errors.some(error => error.errorText?.includes?.('Too Many Requests'))) {
        return { text: switchByLanguage(TOO_MANY_REQUEST_MESSAGE) };
    }

    const userErrors = errors.filter(error => error.isUserException || isLocKeyError(error.errorText));
    if (!userErrors.length) {
        return { text: switchByLanguage(DEFAULT_MESSAGE) };
    }

    if (userErrors.length == 1 && userErrors[0].isUserException) {
        return {
            title: userErrors[0].errorTitle,
            text: userErrors[0].errorText,
        };
    }

    let title = undefined;
    if (uniqueArray(userErrors.map(error => error.errorTitle)).length == 1 && userErrors[0].errorTitle) {
        title = userErrors[0].errorTitle;
    }

    const texts: ReactNode[] = [];
    const usedTexts = new Set<string>();
    const usedLocKeys = new Set<string>();
    for (const error of userErrors) {
        const errorText = error.isUserException ? error.errorText : undefined;
        if (errorText != null) {
            if (usedTexts.has(error.errorText)) {
                continue;
            }

            texts.push(error.errorText);
            usedTexts.add(error.errorText);
        }

        const { locKey, locParams } = error.isUserException
            ? error
            : {
                locKey: error.errorText?.substring(lockeyPattern.length),
                locParams: undefined,
            };

        if (!locKey) {
            continue;
        }

        const key = [locKey, ...locParams ?? []].join(';');
        if (usedLocKeys.has(key)) {
            continue;
        }

        texts.push(ts(locKey, { locParams }));
        usedLocKeys.add(key);
    }

    const text = arrayInjectSeparator(
        texts.map((text, i) => <Fragment key={i}>{text}</Fragment>),
        i => <br key={`br${i}`} />);

    return { title, text };
}

export const ErrorMessage: FC = () => {
    const { errors, clear } = useErrorStore();

    const dismiss = useCallback(() =>
        clear(),
        [clear]);

    const { switchByLanguage } = useSwitchByLanguage();

    const error = useErrorMessage(errors);
    if (!error) {
        return null;
    }

    const headerText = error.title || switchByLanguage(DEFAULT_HEADER);

    return (
        <Modal
            headerText={headerText}
            buttonText={switchByLanguage(CLOSE_TEXT)}
            onClose={dismiss}>
            <p>{error.text}</p>
        </Modal>
    );
}