import { columnWidths, ListFrame, ListFrameColumns } from "@components/ListFrame";
import FilterManager from "@components/FilterManager";
import { urlConstants } from "@constants/urlConstants";
import { DNSystemAttrMap, IDNSystemiModel } from "@models/IDNSystemModel";
import React, { FC, useRef } from "react"
import { useLocalization } from "@store/localizationStore";
import { usePageTitle } from "@utils/PageTitleHook";
import { AttrNames } from "@utils/AttrMapping";
import ContentBlock from "@components/ContentBlock";
import Paginator from "@components/Pagination";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";
import { useDescription } from "@utils/DescriptionHook";

export const ON_PAGE_ROWS = 50;

const columns: ListFrameColumns<IDNSystemiModel> = [
    {
        attrName: 'nazev',
        locKey: 'NEN-812716'
    },
    {
        attrName: 'zadavatelNazev',
        locKey: 'NEN-812641'
    },
    {
        attrName: 'cpvKod',
        locKey: 'NEN-812701',
        width: 120
    },
    {
        attrName: 'stav',
        locKey: 'NEN-812623',
        width: 80
    },
    {
        attrName: 'datumZavedeni',
        locKey: 'NEN-812638',
        width: columnWidths.dateTime
    },
    {
        attrName: 'terminUkonceni',
        locKey: 'NEN-812624',
        width: columnWidths.date
    }
];

const paramNames = urlConstants.registrDns.paramNames;
const registrDnsLoadKey = 'registr-dns-seznam';
const fulltextAttributes: AttrNames<IDNSystemiModel> = ['nazev', 'zadavatelNazev', 'cpvKod'];

const RegistrDns: FC = () => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-744123'));
    useDescription(t('NEN-789438'));

    const listFrameRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <h2 className='u-sr-only'>{t('NEN-744020')}</h2>
            <ContentBlock>
                <FilterManager id='registr-dns-filter' paramNames={paramNames} />
                <ListFrame
                    containerRef={listFrameRef}
                    listFrameKey={registrDnsLoadKey}
                    paramNames={paramNames}
                    skeletonCount={ON_PAGE_ROWS}
                    onPageCount={ON_PAGE_ROWS}
                    attrMapping={DNSystemAttrMap}
                    fulltextAttributes={fulltextAttributes}
                    orderBy={useOrderByMemo<IDNSystemiModel>({ desc: false, column: 'stav' }, { desc: true, column: 'datumZavedeni' })}
                    detailLinkFactory={detailLinks.getDnsDetailLinkFactory()}
                    columns={columns} />
            </ContentBlock>
            <Paginator
                loadMoreButton
                collectionKey={registrDnsLoadKey}
                scrollToRef={listFrameRef} />
        </>
    );
}

export default React.memo(RegistrDns);
