import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import LargeWidget from "@components/Widgets/LargeWidget";
import { useAppSettings } from "@store/nodeContextStore";
import { useLocation } from "react-router";

const DetailInformace: FC = () => {
    const { getDetailWidgetUrl, updateDetailWidgetLanguage } = useAppSettings();
    const location = useLocation();
    const [url, setUrl] = useState<string>(location.search);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        setUrl(updateDetailWidgetLanguage(location.search));
    }, [updateDetailWidgetLanguage, location, location.search, setUrl]);

    const scrollUp = useCallback(async (event: MessageEvent) => {
        if (event?.source == iframeRef?.current?.contentWindow
            && event.data == 'ScrollUp') {
            setTimeout(() => {
                const rect = iframeRef?.current?.getBoundingClientRect();
                if (rect) {
                    window.scrollTo({ top: rect.top + 25, behavior: 'smooth' });
                }
            }, 800);
        }
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('message', scrollUp);
        return () => {
            window.removeEventListener('message', scrollUp);
        };
    }, [scrollUp]);

    return <LargeWidget ref={iframeRef} src={getDetailWidgetUrl() + url} />
};

DetailInformace.displayName = 'DetailInformace';

export default React.memo(DetailInformace);
