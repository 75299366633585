import { UrlParamObject } from "@utils/QueryHook";
import { useCallback } from "react";
import { match, useRouteMatch } from "react-router";

export const paramsPrefix = 'p:'

export type FilterParamsNames = {
    filterParamName: string;
    routeParamName: string;
};

type ListUrl = {
    url: (match: match<UrlParamObject>) => string;
    pureUrl: string;
    path: string;
    paramNames: FilterParamsNames;
}

type DetailUrl = {
    url: string;
    path: string;
    pathWithoutRouteParam: string;
    detailIdParam: string;
    urlRouteParam: string;
    addPageUrl: (pageUrl: string) => number
}

const createPureUrl = (url: string, anywhere?: boolean) => ({
    url: (match: match<UrlParamObject>) => {
        if (match?.params?.language) {
            return `/${match.params.language}${url}`;
        }
        return url;
    },
    pureUrl: url,
    path: anywhere ? `/:language(en)?/*${url}` : `/:language(en)?${url}`
});

const createListUrl = ({ name, filterParam, urlRouteParam }: { name: string; filterParam: string, urlRouteParam?: string }): ListUrl => {
    urlRouteParam ??= 'filter';
    return {
        url: (match: match<UrlParamObject>) => {
            if (match?.params?.language) {
                return `/${match.params.language}/${name}`;
            }
            return `/${name}`;
        },
        pureUrl: `/${name}`,
        path: `/:language(en)?/${name}/:${urlRouteParam}(${paramsPrefix}[^\/]*)?`,
        paramNames: {
            routeParamName: urlRouteParam,
            filterParamName: filterParam
        }
    }
}

const createDetailUrl = ({ url, detailIdParam, urlRouteParam }: { url: string, detailIdParam: string, urlRouteParam: string }) => {
    const pathWithoutRouteParam = `/:language(en)?/*/${url}/:${detailIdParam}([^\/]+)`;
    const routeParam = `/:${urlRouteParam}(${paramsPrefix}[^\/]*)?`;
    const path = `${pathWithoutRouteParam}${routeParam}`;
    const pages: string[] = [];
    return {
        url: url,
        pathWithoutRouteParam: pathWithoutRouteParam,
        path: path,
        get pathWithPages() {
            return [...pages, path];
        },
        detailIdParam: detailIdParam,
        urlRouteParam: urlRouteParam,
        addPageUrl: (pageUrl: string) => pages.push(`${pathWithoutRouteParam}/${pageUrl}${routeParam}`)
    }
}

const createDetailPageUrl = ({ detailUrl, url }: { url: string, detailUrl: DetailUrl }) => {
    detailUrl.addPageUrl(url);
    return {
        path: `${detailUrl.pathWithoutRouteParam}/${url}/:${detailUrl.urlRouteParam}(${paramsPrefix}[^\/]*)?`,
        url: `/${url}`
    }
}

const home = createPureUrl('/');
const loginFrame = createPureUrl('/login-frame');

const provozniRad = createPureUrl('/provozni-rad');
const skoleniVse = createPureUrl('/skoleni-vse');
const videa = createPureUrl('/videa');
const videaVse = createPureUrl('/videa-vse');
const skoleniZzvz = createPureUrl('/skoleni-zzvz');
const skoleniZzvzVse = createPureUrl('/skoleni-zzvz-vse');
const prirucky = createPureUrl('/prirucky');
const provozniInformace = createPureUrl('/provozni-informace');
const detailInformace = createPureUrl('/detail-provozni-informace', true);

const rozcestnikRegistrace = createPureUrl('/registrace');
const prohlaseniOPristupnosti = createPureUrl('/prohlaseni-o-pristupnosti');

const registrDns = createListUrl({ name: 'registr-dns', filterParam: 'dns' });
const registrDnsDetail = createDetailUrl({ url: 'detail-dns', detailIdParam: 'registrDnsId', urlRouteParam: 'dnsFilter' });
//const registrDnsPostupy = createDetailUrl({ url: 'postupy-dns', detailIdParam: 'registrDnsId', urlRouteParam: 'dnsFilter' });
const registrDnsPostupy = createDetailPageUrl({ url: 'postupy-dns', detailUrl: registrDnsDetail });

const vestnik = createListUrl({ name: 'vestnik', filterParam: 'vestnik' });
const vestnikDetail = createDetailUrl({ url: 'detail-vestniku', detailIdParam: 'vestnikId', urlRouteParam: 'vestnikFilter' });

const registrSK = createListUrl({ name: 'registr-sk', filterParam: 'sk' });
const registrSKDetail = createDetailUrl({ url: 'detail-sk', detailIdParam: 'skId', urlRouteParam: 'skFilter' });

const verejneZakazky = createListUrl({ name: 'verejne-zakazky', filterParam: 'vz' });
const verejneZakazkyDetail = createDetailUrl({ url: 'detail-zakazky', detailIdParam: 'zakazkaId', urlRouteParam: 'vzFilter' });
const VerejneZakazkyCasti = createDetailPageUrl({ url: 'casti', detailUrl: verejneZakazkyDetail });
const verejneZakazkyDetailZadavaciDokumentace = createDetailPageUrl({ url: 'zadavaci-dokumentace', detailUrl: verejneZakazkyDetail });
const verejneZakazkyDetailVysvetleniDokumentace = createDetailPageUrl({ url: 'vysvetleni-dokumentace', detailUrl: verejneZakazkyDetail });
const verejneZakazkyDetailVysledek = createDetailPageUrl({ url: 'vysledek', detailUrl: verejneZakazkyDetail });

const verejneZakazkyCastiDetail = createDetailUrl({ url: 'detail-casti', detailIdParam: 'castId', urlRouteParam: 'cvzFilter' });
const verejneZakazkyDetailCastiZadavaciDokumentace = createDetailPageUrl({ url: 'zadavaci-dokumentace', detailUrl: verejneZakazkyCastiDetail });
const verejneZakazkyDetailCastiVysvetleniDokumentace = createDetailPageUrl({ url: 'vysvetleni-dokumentace', detailUrl: verejneZakazkyCastiDetail });
const verejneZakazkyDetailCastiVysledek = createDetailPageUrl({ url: 'vysledek', detailUrl: verejneZakazkyCastiDetail });

const profilyZadavateluPlatne = createListUrl({ name: 'profily-zadavatelu-platne', filterParam: 'pzp' });
const profilyZadavateluZneplatnene = createListUrl({ name: 'profily-zadavatelu-zneplatnene', filterParam: 'pzz' });
const profilZadavateleDetail = createDetailUrl({ url: 'detail-profilu', detailIdParam: 'profilId', urlRouteParam: 'pFilter' });
const profilZadavateleDetailKontakty = createDetailPageUrl({ url: 'dalsi-informace', detailUrl: profilZadavateleDetail });
const profilZadavateleDetailZahajeneZakazky = createDetailPageUrl({ url: 'zahajene-zakazky', detailUrl: profilZadavateleDetail });
const profilZadavateleDetailUzavreneZakazky = createDetailPageUrl({ url: 'uzavrene-zakazky', detailUrl: profilZadavateleDetail });
const profilZadavateleDetailPlanovaneZakazky = createDetailPageUrl({ url: 'planovane-zakazky', detailUrl: profilZadavateleDetail });
const profilZadavateleDetailSeznamDns = createDetailPageUrl({ url: 'seznam-dns', detailUrl: profilZadavateleDetail });
const profilZadavateleDetailZadavateleMultiprofilu = createDetailPageUrl({ url: 'zadavatele-multiprofilu', detailUrl: profilZadavateleDetail });

const detailDetailUver = createDetailUrl({ url: 'detail-uverejneni', detailIdParam: 'uverejneniId', urlRouteParam: 'uverFilter' });
const detailInfoUver = createDetailUrl({ url: 'detail-info', detailIdParam: 'infoId', urlRouteParam: 'infoFilter' });
const detailDetailPolozkaPredm = createDetailUrl({ url: 'detail-polozka', detailIdParam: 'polozkaId', urlRouteParam: 'polozkaFilter' });
const detailVysledek = createDetailUrl({ url: 'detail-vysledek', detailIdParam: 'vysledekId', urlRouteParam: 'vysledekFilter' });
const detailVysledekSON = createDetailUrl({ url: 'detail-vysledek-SON', detailIdParam: 'vysledekSonId', urlRouteParam: 'vysledekSonFilter' });
const detailucast = createDetailUrl({ url: 'detail-ucast', detailIdParam: 'ucastId', urlRouteParam: 'ucastFilter' });
const detailDodInfo = createDetailUrl({ url: 'detail-vysvetleni', detailIdParam: 'vysvetleniId', urlRouteParam: 'vysvetleniFilter' });
const detailUdajePod = createDetailUrl({ url: 'detail-udaje-podani', detailIdParam: 'udajeId', urlRouteParam: 'udajePodaniFilter' });
const detailOdpoved = createDetailUrl({ url: 'detail-odpoved', detailIdParam: 'odpovedId', urlRouteParam: 'odpovedFilter' });
const detailUverDokum = createDetailUrl({ url: 'detail-dokument', detailIdParam: 'dokumId', urlRouteParam: 'dokumFilter' });
const detailExtPodpis = createDetailUrl({ url: 'detail-podpis', detailIdParam: 'podpisId', urlRouteParam: 'podpisFilter' });

const registrZadavatelu = createListUrl({ name: 'registr-zadavatelu', filterParam: 'zad' });
const registrDodavatelu = createListUrl({ name: 'registr-dodavatelu', filterParam: 'dod' });
const subjektDetail = createDetailUrl({ url: 'detail-subjektu', detailIdParam: 'subjektId', urlRouteParam: 'sFilter' });
const subjektDetailDalsiInformace = createDetailPageUrl({ url: 'dalsi-informace', detailUrl: subjektDetail });

const compatibilityTest = createPureUrl('/kompatibilita');
const registrace = createPureUrl('/registrace');
const registraceZadavatel = createPureUrl(registrace.pureUrl + '/zadavatel');
const registraceDodavatel = createPureUrl(registrace.pureUrl + '/dodavatel');
const registraceSpolecneZadavani = createPureUrl(registrace.pureUrl + '/spolecne-zadavani');
const prihlaseni = createPureUrl('/prihlaseni');
const obnoveniUctu = createPureUrl('/obnoveni-uctu');
const zmenaHesla = createPureUrl('/zmena-hesla');
const design = createPureUrl('/design');
const osobniUdajeACookies = createPureUrl('/osobni-udaje-a-cookies');


const page413 = createPureUrl('/413');

export const urlConstants = {
    loginFrame,
    home,
    provozniRad,
    skoleniVse,
    videa,
    videaVse,
    skoleniZzvz,
    skoleniZzvzVse,
    prirucky,
    compatibilityTest,
    verejneZakazky,
    verejneZakazkyDetail,
    VerejneZakazkyCasti,
    verejneZakazkyCastiDetail,
    verejneZakazkyDetailZadavaciDokumentace,
    verejneZakazkyDetailVysvetleniDokumentace,
    verejneZakazkyDetailVysledek,
    verejneZakazkyDetailCastiZadavaciDokumentace,
    verejneZakazkyDetailCastiVysvetleniDokumentace,
    verejneZakazkyDetailCastiVysledek,
    registrDns,
    registrDnsDetail,
    registrDnsPostupy,
    registrSK,
    registrSKDetail,
    registrZadavatelu,
    registrDodavatelu,
    subjektDetail,
    subjektDetailDalsiInformace,
    vestnik,
    vestnikDetail,
    profilyZadavateluPlatne,
    profilyZadavateluZneplatnene,
    profilZadavateleDetail,
    profilZadavateleDetailKontakty,
    profilZadavateleDetailZahajeneZakazky,
    profilZadavateleDetailUzavreneZakazky,
    profilZadavateleDetailPlanovaneZakazky,
    profilZadavateleDetailSeznamDns,
    profilZadavateleDetailZadavateleMultiprofilu,
    detailDetailUver,
    detailInfoUver,
    detailDetailPolozkaPredm,
    detailVysledek,
    detailVysledekSON,
    detailucast,
    detailDodInfo,
    detailUdajePod,
    detailOdpoved,
    detailUverDokum,
    detailExtPodpis,
    provozniInformace,
    detailInformace,
    registrace,
    registraceZadavatel,
    registraceDodavatel,
    registraceSpolecneZadavani,
    prihlaseni,
    obnoveniUctu,
    zmenaHesla,
    design,
    rozcestnikRegistrace,
    prohlaseniOPristupnosti,
    osobniUdajeACookies,
    page413
}

export const useConstants = () => {
    const match = useRouteMatch();
    const getUrl = useCallback((name: keyof typeof urlConstants) => {
        const url = urlConstants[name].url;
        if (url instanceof Function) {
            return url(match);
        }
        return url;
    }, [match]);
    return { getUrl };
}
