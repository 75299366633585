import React from "react";
import { useRouteMatch } from "react-router";
import { DetailFrame } from "@components/DetailFrame/DetailFrame";
import { urlConstants } from "@constants/urlConstants";
import { IPolozkaPredmetuHistModel, PolozkaPredmetuHistAttrMap } from "@models/IPolozkaPredmetuHistModel";
import { UrlParamObject } from "@utils/QueryHook";
import GroupBox from "@components/GroupBox";
import { DataLabelTyped } from "@components/DataLabel";
import { IParametrPolozkyHistModel, ParametrPolozkyHistModelAttrMap } from "@models/IParametrPolozkyHistModel";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { downloadButtonOnly } from "@components/Table/Buttons/DownloadAllToolbarButton";
import { columnWidths, DependentListFrameTyped, ListFrameColumn } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { useOrderByMemo } from "@utils/OrderByUtils";

const DataLabel = DataLabelTyped<IPolozkaPredmetuHistModel>();

const detailDetailPolozkaPredmLoadkey = 'detail-polozka';
const DependentListFrame = DependentListFrameTyped<IPolozkaPredmetuHistModel>();

const DetailPolozkaPredm: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const id = (match?.params[urlConstants.detailDetailPolozkaPredm.detailIdParam] || '');

    return (
        <DetailFrame
            condition='ID=@0'
            conditionParams={[id]}
            attrMapping={PolozkaPredmetuHistAttrMap}
            loadKey={detailDetailPolozkaPredmLoadkey}
        >
            <DetailPolozka />
        </DetailFrame>
    );
}
const DetailPolozka: React.FC = () => {
    return (
        <>
            <GroupBox lockey="NEN-812723" >
                <DataLabel lockey="NEN-812571" attrName='nazevPolozky' fillGrid />
                <DataLabel lockey="NEN-812572" attrName='popisPolozky' fillGrid rawText />
                <DataLabel lockey="NEN-812585" attrName='nipezPolozkyKod' />
                <DataLabel lockey="NEN-812582" attrName='nipezPolozkyNazev' />
                <DataLabel lockey="NEN-812749" attrName='plniDod' />
                <DataLabel lockey="NEN-812586" attrName='cpvPolozkyKod' />
                <DataLabel lockey="NEN-812583" attrName='cpvPolozkyNazev' />
                <DataLabel lockey="NEN-812573" attrName='mnozstvi' />
                <DataLabel lockey="NEN-812882" attrName='jednotkaKod' />
                <DataLabel lockey="NEN-812598" attrName='predpoklHodnota' />
                <DataLabel lockey="NEN-812599" attrName='predpoklHodnotaMJ' />
                <DataLabel lockey="NEN-812828" attrName='popisMistoPlneni' showIf="Hist.Misto_k_celku = true" fillGrid />
            </GroupBox>
            <GroupBox lockey="NEN-812576" fillContent>
                <ListFrameParametr />
            </GroupBox>
            <GroupBox lockey="NEN-812699" fillContent>
                <ListFrameDokum />
            </GroupBox>
        </>
    );
}

const parametrModelColumns: ListFrameColumn<IParametrPolozkyHistModel>[] = [
    {
        attrName: 'vlastnostNazev',
        locKey: 'NEN-812577',
        width: 300
    },
    {
        attrName: 'operator',
        locKey: 'NEN-812579',
        width: 150
    },
    {
        attrName: 'vlastnostHodnota',
        locKey: 'NEN-812578',
        width: 200
    }
];

const ListFrameParametr: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailDetailPolozkaPredmLoadkey + '-parametr'}
            condition="Polozka=@0"
            conditionParams={'id'}
            orderBy='vlastnostNazev'
            lightVersion
            attrMapping={ParametrPolozkyHistModelAttrMap}
            paramNames={useStaticObject({ filterParamName: 'par', routeParamName: urlConstants.detailDetailPolozkaPredm.urlRouteParam })}
            columns={parametrModelColumns} />
    );
}

const dokumModelColumns: ListFrameColumn<IUverejnenyDokumModel>[] = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];

const ListFrameDokum: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailDetailPolozkaPredmLoadkey + '-dokum-polozka'}
            condition="ZP=@0 and Polozka=@1 and Uverejnen=true"
            conditionParams={['zpID', 'id']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'dpd', routeParamName: urlConstants.detailDetailPolozkaPredm.urlRouteParam })}
            columns={dokumModelColumns}
            extraButtons={downloadButtonOnly} />
    );

}

export default DetailPolozkaPredm;