import React, { FC, HTMLAttributes, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";

const cn = setupCn('gov-container');

export type ContainerProps = PropsWithChildren<{
    className?: Classes;
    noYOffset?: boolean;
    asPage?: boolean;
    branded?: boolean;
} & HTMLAttributes<HTMLElement>>;

const defaultElement: FC<any> = (props) => <div {...props} />;

const Container: FC<ContainerProps & {
    element?: React.FC<any>,
}> = ({
    className,
    asPage,
    branded,
    noYOffset,
    element: Element,
    children,
    ...rest
}) => {
        Element ??= defaultElement
        return (
            <Element className={cn.with(className).main({
                '--no-y-offset': noYOffset,
                '--as-page': asPage,
                '--branded': branded,
            })} {...rest}>
                <div className={cn('__content')}>
                    {children}
                </div>
            </Element>
        );
    };

export default React.memo(Container);
