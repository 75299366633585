import React, { FC, PropsWithChildren, ReactNode } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import Icon from "@components/Icon";
import { IconType } from "react-icons";
import Accordion from "@components/Accordion";
import './CryptoPart.scss';
import { renderIf } from "@utils/RenderUtils";
import FlexBox from "@components/FlexBox/FlexBox";

const cn = setupCn('crypto-part');

export type CryptoPartProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    caption: ReactNode;
    captionIcon?: IconType;
    captionDescription?: ReactNode;
    toggleButtonContent?: ReactNode;
}>;

const CryptoPart: FC<CryptoPartProps> = ({
    id,
    className,
    caption,
    captionDescription,
    captionIcon,
    toggleButtonContent,
    children
}) => {
    return (
        <Accordion.Page className={cn.with(className).main()} id={id} header={
            <FlexBox gap='4px'>
                <FlexBox direction='column' align='start' gap='4px'>
                    <FlexBox className={cn('__caption')} gap='4px'>
                        {renderIf(captionIcon, captionIcon =>
                            <Icon className={cn('__caption-icon')} icon={captionIcon} size='24' />
                        )}
                        {caption}
                    </FlexBox>
                    {captionDescription}
                </FlexBox>
                {toggleButtonContent}
            </FlexBox>
        }>
            <div className={cn('__content')}>
                {children}
            </div>
        </Accordion.Page>
    );
};

CryptoPart.displayName = 'CryptoPart';

export default React.memo(CryptoPart);
