import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import Checks, { ChecksItem } from "@components/Checks";
import { FilterManagerChangeEventHandler } from "@components/FilterManager";

const cn = setupCn('bool-checks');

const items: ChecksItem[] = [
    {
        locKey: 'NEN-738573',
        value: 'ano'
    },
    {
        locKey: 'NEN-738574',
        value: 'ne'
    }
];

export type BoolChecksProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    value?: string;
    oneColumn?: boolean;
    onChange?: FilterManagerChangeEventHandler;
    name?: string;
}>;

const BoolChecks: FC<BoolChecksProps> = ({
    id,
    className,
    name,
    oneColumn,
    value,
    onChange
}) => {
    return (
        <Checks
            id={id}
            className={cn.with(className)()}
            onChange={onChange}
            name={name}
            value={value}
            oneColumn={oneColumn}
            items={items} />
    );
};

BoolChecks.displayName = 'BoolChecks';

export default React.memo(BoolChecks);
