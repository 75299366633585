import React, { FC, PropsWithChildren, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom";
import { useCurrentLanguage, useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";
import "./LoginFormFrame.scss";
import { usePrivatePortal } from "@store/nodeContextStore";
import Blockquote from "@components/Blockquote";
import { postWindowMessage, useWindowMessage } from "@utils/WindowMessageUtils";
import { useAsyncValue } from "@utils/ReactUtils";
import { checkMultiwebTests, checkServiceWorker, TestResult } from "@components/CompatibilityTest/CompatibilityTests";
import Loader from "@components/Loader";

const cn = setupCn('login-form-frame');

export type LoginFormFrameProps = PropsWithChildren<{
    className?: Classes;
}>;

const LoginFormFrame: FC<LoginFormFrameProps> = ({
    className
}) => {
    const timeout = useRef(0);
    const { ts } = useLocalization();
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const [iframeHeight, setIframeHeight] = useState(80);

    const canLogin = useAsyncValue(async () => await checkMultiwebTests() && checkServiceWorker().result == TestResult.ok, { defaultValue: undefined });

    const currentLanguage = useCurrentLanguage();

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const token = urlParams.get("token");
    urlParams.delete("token");

    const urlParamsToString = urlParams.toString();
    const appSearch = encodeURIComponent(urlParamsToString ? `?${urlParamsToString}` : '');

    const iframeParams = new URLSearchParams();

    if (appSearch) {
        iframeParams.append("appSearch", appSearch);
    }

    if (token) {
        iframeParams.append("token", token);
    }

    const { loginIframeUrl } = usePrivatePortal();

    useEffect(() => {
        const postMessage = () => {
            window.clearTimeout(timeout.current);
            if (iframeRef.current?.contentWindow?.document?.readyState != "complete") {
                timeout.current = window.setTimeout(postMessage, 100);
                return;
            }
            postWindowMessage(iframeRef.current?.contentWindow, 'changeLanguage', { language: currentLanguage });
        }
        postMessage();
    }, [currentLanguage]);

    useWindowMessage('iframeResized', payload => {
        setIframeHeight(payload.height)
    });

    useWindowMessage('requestInitLang', () => {
        postWindowMessage(iframeRef.current?.contentWindow, 'changeLanguage', { language: currentLanguage });
    }, [currentLanguage]);

    return (
        canLogin === undefined ?
            <Loader withOverlay /> :
            canLogin ?
                <div className={cn.with(className)()}>
                    {
                        !loginIframeUrl ?
                            <Blockquote type='error'>Autorizovaná část je nedostupná.</Blockquote> :
                            <iframe
                                ref={iframeRef}
                                role="presentation"
                                className={cn('__iframe')}
                                style={{ height: iframeHeight }}
                                src={`${loginIframeUrl}?${iframeParams.toString()}`} />
                    }
                </div> :
                <Blockquote type='error'>{ts('NEN-787081')}</Blockquote>
    );
};

LoginFormFrame.displayName = 'LoginFormFrame';

export default React.memo(LoginFormFrame);
