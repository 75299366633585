import { AttrMapping, IData } from "@utils/AttrMapping";

export type IJinaAdresaSubjektuModel = IData & {
    id: number;
    nazevSubjektu: string;
    obec: string;
    castObce: string;
    ulice: string;
    cisloPopisne: string;
    cisloOrientacni: string;
    psc: string;
    statAdresyKod: string;
}

export const JinaAdresaSubjektuAttrMap = new AttrMapping<IJinaAdresaSubjektuModel>('Jina_adresa_subj', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'nazevSubjektu', serverName: 'Nazev_subjektu', type: 'string' },
    { clientName: 'obec', serverName: 'Obec', type: 'string' },
    { clientName: 'castObce', serverName: 'Cast_obce', type: 'string' },
    { clientName: 'ulice', serverName: 'Ulice', type: 'string' },
    { clientName: 'cisloPopisne', serverName: 'Cislo_popisne', type: 'string' },
    { clientName: 'cisloOrientacni', serverName: 'Cislo_orientacni', type: 'string' },
    { clientName: 'psc', serverName: 'PSC', type: 'string' },
    { clientName: 'statAdresyKod', serverName: 'Stat_adresy.Kod', type: 'string' }
]);
