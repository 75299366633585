import { nanoid } from "@reduxjs/toolkit";
import { createContext, useCallback, useContext, useState } from "react"
import { joinNonEmpty } from "./StringUtils";

type IdFactory = (props: { controlId: string; id: string; }) => string;
type IdControlFactory = (props: { parentId?: string; id: string; }) => string;

export const IdContext = createContext<string | undefined>(undefined);

const defaultIdFactory: IdFactory = ({ controlId, id }) => joinNonEmpty('__', controlId, id);
const defaultControlIdFactory: IdControlFactory = ({ parentId, id }) => joinNonEmpty('__', parentId, id);

type UseIdParams = {
    id?: string;
    controlIdFactory?: IdControlFactory
    childIdFactory?: IdFactory;
};

const useGenerateId = ({ controlId, childIdFactory }: { controlId: string; childIdFactory?: IdFactory; }) => 
    useCallback((childId: string) => (childIdFactory ?? defaultIdFactory)({ controlId, id: childId }),
        [childIdFactory, controlId]);

export const useId = ({ controlIdFactory, childIdFactory, id = 'control' }: UseIdParams) => {
    const parentId = useContext(IdContext);
    const controlId = (controlIdFactory ?? defaultControlIdFactory)({ parentId, id });

    return {
        controlId,
        parentId,
        generateId: useGenerateId({ childIdFactory, controlId }),
    };
}

export const useNanoid = ({ childIdFactory }: { childIdFactory?: IdFactory } = {}) => {
    const controlId = useState(() => nanoid())[0];

    return {
        controlId,
        generateId: useGenerateId({ childIdFactory, controlId }),
    };
}
