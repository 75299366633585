import { ICryptographicInfo } from "crypto/interface/ICryptographicInfo";
import { ISignaturesDataDTO } from "crypto/interface/ISignatureDataDTO";
import { ISignatureProviderProfile } from "crypto/interface/ISignatureProvider";
import { ServiceBase } from "./ServiceBase";
import { ISignatureInfo } from "crypto/interface/ISignatureInfo";

type DataForSign = {
    signatureData: string;
    referenceUri: string;
    objectIdentity: string;
}

type GetDataForSignParams = {
    instanceID: string;
}

type GetCryptographicInfoParams = {
    instanceID: string;
}

type GetDigitalSignatureSettingsParams = {
    instanceID: string;
}

type GetDocumentSignatureInfoParams = {
    objectName: string;
    id: string;
    attrName: string;
}


export default class CryptoService extends ServiceBase {
    public async getDataForSign(params: GetDataForSignParams): Promise<DataForSign> {
        const result = (await this.requestJson<DataForSign>({
            url: `/api/crypto/getDataForSign`,
            method: 'POST',
            data: params
        })).value;
        return result;
    }

    public async sendSignature(params: ISignaturesDataDTO[]): Promise<boolean> {
        return (await this.requestJson<string>({
            url: `/api/crypto/sendSignature`,
            method: 'POST',
            data: params
        })).value == 'Signed';
    }

    public async getCryptographicInfo(params: GetCryptographicInfoParams): Promise<ICryptographicInfo> {
        return (await this.requestJson<ICryptographicInfo>({
            url: `/api/crypto/getCryptographicInfo`,
            method: 'POST',
            data: params
        })).value;
    }

    public async getDigitalSignatureSettings(params: GetDigitalSignatureSettingsParams): Promise<ISignatureProviderProfile> {
        return (await this.requestJson<ISignatureProviderProfile>({
            url: `/api/crypto/getDigitalSignatureSettings`,
            method: 'POST',
            data: params
        })).value;
    }

    public async getDocumentSignatureInfo(params: GetDocumentSignatureInfoParams): Promise<ISignatureInfo> {
        return (await this.requestJson<ISignatureInfo>({
            url: `/api/crypto/getDocumentSignatureInfo`,
            method: 'POST',
            data: params
        })).value;
    }
}
