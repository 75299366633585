import React, { FC, PropsWithChildren } from 'react'
import { setupCn, Classes } from '@utils/BemUtils';
import { useDetailFrameContext } from '@components/DetailFrame';

import './InfoText.scss';
import Note from '@components/Note/Note';

const cn = setupCn('info-text');

export type InfoTextProps = PropsWithChildren<{
    className?: Classes;
    fillGrid?: boolean;
}>;

const InfoText: FC<InfoTextProps & { showIf?: string | boolean }> = ({
    className,
    children,
    showIf,
    fillGrid
}) => {
    const { evaluateCondition } = useDetailFrameContext();

    if (showIf === false)
        return null;

    if (typeof showIf === 'string' && evaluateCondition(showIf) === false)
        return null;

    return (
        <div className={cn.with(className).main({
            '--fill-grid': fillGrid
        })}>
            <Note>{children}</Note>
        </div>
    );
};

InfoText.displayName = 'InfoText';

export default React.memo(InfoText);
