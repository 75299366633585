import { LoginFormFrame } from "@components/LoginForm";
import { urlConstants, useConstants } from "@constants/urlConstants";
import React, { FC, PropsWithChildren, useState } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";
import { usePageTitle } from "@utils/PageTitleHook";
import ContentBlock from "@components/ContentBlock";
import SmallWidget from "@components/Widgets/SmallWidget";
import GovLayout from "@components/GovLayout/GovLayout";
import { useAppSettings } from "@store/nodeContextStore";
import UniversalLink from "@components/UniversalLink/UniversalLink";
import { joinPath } from "@utils/PathUtils";
import { useWindowMessage } from "@utils/WindowMessageUtils";
import Tabs from "@components/Tabs";

import './Prihlaseni.scss';
import { useDescription } from "@utils/DescriptionHook";

const cn = setupCn('prihlaseni');

export type PrihlaseniProps = PropsWithChildren<{
    className?: Classes;
}>;

const Prihlaseni: FC<PrihlaseniProps> = ({
    className
}) => {
    const { ts, t } = useLocalization();
    const { appSettings, getSmallWidgetUrl } = useAppSettings();
    const { anotherEnvironment, loginCaption } = appSettings;
    const [loginStatus, setLoginStatus] = useState(false);
    const { getUrl } = useConstants();
    usePageTitle(t('NEN-749987'));
    useDescription(t('NEN-789459'));

    useWindowMessage('loginStatus', payload => {
        setLoginStatus(payload.isLogged)
    });

    const loginBlock = (
        <ContentBlock lockey={loginCaption} height='100%' className={cn('__content')}>
            <div className={cn.with(className).main()}>
                <LoginFormFrame />
                {
                    !loginStatus &&
                    <>
                        <p><UniversalLink to={getUrl('registrace')}>{ts('NEN-749116')}</UniversalLink></p>
                        <p><UniversalLink to={getUrl('obnoveniUctu')}>{ts('NEN-749297')}</UniversalLink></p>
                    </>
                }
                <p><UniversalLink to={getUrl('compatibilityTest')}>{ts('NEN-746935')}</UniversalLink></p>

                {
                    anotherEnvironment?.loginLocKey && anotherEnvironment.url &&
                    <p>
                        {ts('NEN-749117', {
                            locParams: [
                                <UniversalLink isExternal to={joinPath(anotherEnvironment.url, getUrl('prihlaseni'))}>
                                    {ts(anotherEnvironment.loginLocKey)}
                                </UniversalLink>
                            ]
                        })}
                    </p>
                }

            </div>
        </ContentBlock>
    );

    return (
        <GovLayout
            leftCol={loginBlock}
            rightCol={
                <ContentBlock height='100%' className={cn.main()}>
                    <Tabs
                        displayAsFlex
                        noMargin
                        tabs={[
                            {
                                lockey: 'NEN-767364',
                                path: urlConstants.prihlaseni.path,
                                render: () =>
                                    <SmallWidget
                                        height='400px'
                                        baseDetailUrl={getUrl('provozniInformace')}
                                        src={getSmallWidgetUrl('provozniInformacePrihlaseni')}
                                        allLink={{ href: getUrl('provozniInformace'), titleLockey: 'NEN-786556' }}
                                        withPadding
                                    />
                            },
                        ]}
                    />
                </ContentBlock>
            }
            ratio='1-2'
            spreadRight={1} />
    );
};

Prihlaseni.displayName = 'Prihlaseni';

export default React.memo(Prihlaseni);
