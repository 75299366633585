import { CreateConditionFactory, createIn, createSubtreeCondition, joinConditionsByOr, toSplittedLinguisticLike } from "@utils/FilterCondition";
import { AttrMapping, IData } from "@utils/AttrMapping";

export type IZpModel = IData & {
    cpvPredmetu: number;
    cpvPredmetuKod: string;
    cpvPredmetuNazev: string;
    createCas: string;
    datumPosledniUver: Date;
    datumProfil: Date;
    datumProfilMimo: Date;
    datumPrvniUver: Date;
    datumUkonceni: Date;
    datumVestnik: Date;
    datumZruseni: Date;
    dnsZavedeneID: number;
    dnsZavedeneNazev: string;
    dodavatelICO: string;
    dodavatelNazev: string;
    druhVZ: number;
    druhZR: number;
    druhZRNazev: string;
    histId: number;
    hlavniMistoNUTS: string;
    identifDNS: string;
    identifEU: string;
    identifSouvis: string;
    identifZP: string;
    importovanaZakazka: boolean;
    jeToRD: boolean;
    kod: string;
    kodET: string;
    kodEU: string;
    kodIEN: string;
    kodPuvodniProfil: string;
    kodVestnik: string;
    kodZakazkaProfil: string;
    mistoKPolozce: boolean;
    mistoNUTS: number;
    mistoPlneni: string;
    naZakladeRSRD: boolean;
    nazev: string;
    nazevPredmetu: string;
    nazevRSRD: string;
    nipezPredmetu: number;
    nipezPredmetuKod: string;
    nipezPredmetuNazev: string;
    osobaDalsiInfo: string;
    osobaEmail: string;
    osobaFax: string;
    osobaFunkce: string;
    osobaJmeno: string;
    osobaMobil: string;
    osobaPrijmeni: string;
    osobaTelefon: string;
    osobaTitPred: string;
    osobaTitZa: string;
    oteviraniLhuta: Date;
    oteviraniNavrhLhuta: Date;
    oteviraniPredNabLhuta: Date;
    oteviraniPredZajemLhuta: Date;
    oteviraniZadostLhuta: Date;
    podaniInformaceLhuta: Date;
    podaniLhuta: Date;
    podaniNavrhLhuta: Date;
    podaniPredNabLhuta: Date;
    podaniPredZajemLhuta: Date;
    podaniZadostLhuta: Date;
    popisPredmet: string;
    predchoziCastId: number;
    predpokladHodnota: number;
    predpokladHodnotaCZK: number;
    predpokladMenaNazev: string;
    profId: number;
    profilMimo: string;
    profilNazev: string;
    rozdeleniNaCasti: boolean;
    specifZRNazev: string;
    stavZP: string;
    typVZ: string;
    ucastnikICO: string;
    ucastnikNazev: string;
    uverProfil: string;
    uzamceni: boolean;
    vzDnsZavedeneID: number;
    vzDnsZavedeneCelekID: number;
    vzDnsZavedeneNazev: string;
    vzDnsZavedeneCelekNazev: string;
    vzDnsZavedeneKod: string;
    vzDnsZavedeneCelekKod: string;
    vzDnsZavedeneKodNazev: string;
    vzDnsZavedeneCelekKodNazev: string;
    zadavanaDNS: boolean;
    zadavatelCastObce: string;
    zadavatelHlavniPredmet: number;
    zadavatelICO: string;
    zadavatelID: number;
    zadavatelNazev: string;
    zadavatelNUTS: number;
    zadavatelObec: string;
    zadavatelPravniForma: number;
    zadavatelUlice: string;
    zavedeniDNS: boolean;
    fullTextFiltr: string;
}

const createMistoPlneniCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    const mistoNutsLike = toSplittedLinguisticLike('vazba_NUTS.Nazev', value as string, conditionParams);
    const polozkaPredmetuLike = toSplittedLinguisticLike('Misto_plneni', value as string, conditionParams);
    const condition = joinConditionsByOr(
        toSplittedLinguisticLike(attrInfo.serverName, value as string, conditionParams),
        mistoNutsLike && `EXISTS(Misto_NUTS_hist,ZP=&Hist AND ${mistoNutsLike})`,
        polozkaPredmetuLike && `EXISTS(Polozka_predm_hist,ZP=&Hist AND ${polozkaPredmetuLike})`
    );
    return condition ? {
        condition,
        conditionParams
    } : null;
}

const createPredmetCondition = (polozkaAttrName: string): CreateConditionFactory => ({ attrInfo, value, conditionParams }) => {
    const stringValue = value as string;
    if (!stringValue) {
        return null;
    }
    const parentInCondition = createIn('Nadrizeny', stringValue);
    const condition = joinConditionsByOr(
        `${createIn(attrInfo.serverName, stringValue)} OR EXISTS(DStrom,ID=&${attrInfo.serverName} AND ${parentInCondition})`,
        `EXISTS(Polozka_predm_hist,ZP=&Hist AND (${createIn(polozkaAttrName, stringValue)} OR EXISTS(DStrom,ID=&${polozkaAttrName} AND ${parentInCondition})))`
    );
    return condition ? {
        condition,
        conditionParams
    } : null;
}

const createNazevDodavateleCondition: CreateConditionFactory = ({ value, conditionParams }) => {
    const likeCondition = toSplittedLinguisticLike('Uredni_nazev', value as string, conditionParams);
    return {
        condition: `EXISTS(Detail_uverejneni,Info_uver.ZP=&Hist AND Info_uver.Uverejneni = 'VYS' AND Info_uver.Platny = true AND Uverejnen = true AND ${likeCondition})`,
        conditionParams
    };
}

const createDodavatelICOCondition: CreateConditionFactory = ({ value, conditionParams }) => {
    const likeCondition = toSplittedLinguisticLike('IC', value as string, conditionParams);
    return {
        condition: `EXISTS(Detail_uverejneni,Info_uver.ZP=&Hist AND Info_uver.Uverejneni = 'VYS' AND Info_uver.Platny = true AND Uverejnen = true AND ${likeCondition})`,
        conditionParams
    };
}

const createUcastnikNazevCondition: CreateConditionFactory = ({ value, conditionParams }) => {
    const condition = joinConditionsByOr(
        `EXISTS(Detail_uverejneni,Info_uver.ZP=&Hist AND Info_uver.Uverejneni = 'UCH' AND Info_uver.Platny = true AND Uverejnen = true AND ${toSplittedLinguisticLike('Uredni_nazev', value as string, conditionParams)})`,
        `EXISTS(Detail_uverejneni,Info_uver.ZP=&ID AND Info_uver.Uverejneni = 'UCH' AND Info_uver.Platny = true AND Uverejnen = true AND Sdruzeni_dodavatelu = true AND ${toSplittedLinguisticLike('Nazev_vedouciho', value as string, conditionParams)})`
    );
    return condition ? {
        condition,
        conditionParams
    } : null;
}

const createUcastnikICOCondition: CreateConditionFactory = ({ value, conditionParams }) => {
    const condition = joinConditionsByOr(
        `EXISTS(Detail_uverejneni,Info_uver.ZP=&Hist AND Info_uver.Uverejneni = 'UCH' AND Info_uver.Platny = true AND Uverejnen = true AND ${toSplittedLinguisticLike('IC', value as string, conditionParams)})`,
        `EXISTS(Detail_uverejneni,Info_uver.ZP=&ID AND Info_uver.Uverejneni = 'UCH' AND Info_uver.Platny = true AND Uverejnen = true AND Sdruzeni_dodavatelu = true AND ${toSplittedLinguisticLike('ICO_vedouciho', value as string, conditionParams)})`
    );
    return condition ? {
        condition,
        conditionParams
    } : null;
}

const createHlavniPredmetCinnostiCondition: CreateConditionFactory = ({ value, conditionParams }) => {
    const inCondition = createIn('Predmet_cinnosti', value as string);
    const condition = inCondition && `EXISTS(Cinnost_subj,Subj=&Zadavatel AND ${inCondition})`
    return condition ? {
        condition,
        conditionParams
    } : null;
}

export const ZpAttrMap = new AttrMapping<IZpModel>('Zadavaci_postup_hist', [
    { clientName: 'cpvPredmetu', serverName: 'CPV_predmetu', type: 'id', filterCondition: createPredmetCondition('CPV_polozky') },
    { clientName: 'cpvPredmetuKod', serverName: 'CPV_predmetu.Kod', type: 'string' },
    { clientName: 'cpvPredmetuNazev', serverName: 'CPV_predmetu.Nazev', type: 'string' },
    { clientName: 'createCas', serverName: 'create_cas', type: 'dateTime' },
    { clientName: 'datumPosledniUver', serverName: 'Hist.Datum_posl_uver', type: 'dateTime' },
    { clientName: 'datumProfil', serverName: 'Datum_uver_profil', type: 'dateTime' },
    { clientName: 'datumProfilMimo', serverName: 'Datum_uver_mimo_NEN', type: 'dateTime' },
    { clientName: 'datumPrvniUver', serverName: 'Hist.Datum_prvni_uver', type: 'dateTime' },
    { clientName: 'datumUkonceni', serverName: 'Datum_ukonceni', type: 'date' },
    { clientName: 'datumVestnik', serverName: 'Datum_uver_vestnik', type: 'dateTime' },
    { clientName: 'datumZruseni', serverName: 'Datum_zruseni_ZR', type: 'dateTime' },
    { clientName: 'dnsZavedeneID', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.ID', type: 'id' },
    { clientName: 'dnsZavedeneNazev', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.Nazev_DNS', type: 'string' },
    { clientName: 'dodavatelICO', serverName: 'Dodavatel.ICO', type: 'string', filterCondition: createDodavatelICOCondition },
    { clientName: 'dodavatelNazev', serverName: '', type: 'string', filterCondition: createNazevDodavateleCondition },
    { clientName: 'druhVZ', serverName: 'Druh_VZ', type: 'enum', typeParam: 'Druh_VZ' },
    { clientName: 'druhZR', serverName: 'Druh_ZR', type: 'id' },
    { clientName: 'druhZRNazev', serverName: 'Druh_ZR.Nazev', type: 'string' },
    { clientName: 'histId', serverName: 'Hist', type: 'id' },
    { clientName: 'hlavniMistoNUTS', serverName: 'Hlavni_misto_nuts.Nazev', type: 'string' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'identifDNS', serverName: 'Kod_souvisejici_ZP', type: 'string' },
    { clientName: 'identifEU', serverName: 'Identifikator_EU', type: 'string' },
    { clientName: 'identifSouvis', serverName: 'Identif_ZP_z_DNS', type: 'string' },
    { clientName: 'identifZP', serverName: 'Identifikator_ZP', type: 'string' },
    { clientName: 'importovanaZakazka', serverName: 'Importovana_zakazka', type: 'bool' },
    { clientName: 'jeToRD', serverName: 'Jedna_se_o_RD', type: 'bool' },
    { clientName: 'kod', serverName: 'Kod', type: 'string' },
    { clientName: 'kodET', serverName: 'System_c_ET', type: 'string' },
    { clientName: 'kodIEN', serverName: 'System_c_IEN', type: 'string' },
    { clientName: 'kodPuvodniProfil', serverName: 'Puvodni_kod_profilu', type: 'string' },
    { clientName: 'kodEU', serverName: 'Evid_c_Uv_EU', type: 'string' },
    { clientName: 'kodVestnik', serverName: 'Evid_c_ve_Vestniku', type: 'string' },
    { clientName: 'kodZakazkaProfil', serverName: 'Kod_na_profil', type: 'string' },
    { clientName: 'mistoKPolozce', serverName: 'Misto_k_celku', type: 'bool' },
    { clientName: 'mistoNUTS', serverName: 'Hlavni_misto_nuts', type: 'id', filterCondition: createSubtreeCondition },
    { clientName: 'mistoPlneni', serverName: 'Misto_plneni', type: 'string', filterCondition: createMistoPlneniCondition },
    { clientName: 'naZakladeRSRD', serverName: 'Na_zaklade_RS', type: 'bool' },
    { clientName: 'nazev', serverName: 'Nazev', type: 'string' },
    { clientName: 'nazevPredmetu', serverName: 'Nazev_predmetu', type: 'string' },
    { clientName: 'nazevRSRD', serverName: 'Evidencni_cislo_sml', type: 'string' },
    { clientName: 'nipezPredmetu', serverName: 'NIPEZ_predmetu', type: 'id', filterCondition: createPredmetCondition('NIPEZ_polozky') },
    { clientName: 'nipezPredmetuKod', serverName: 'NIPEZ_predmetu.Kod', type: 'string' },
    { clientName: 'nipezPredmetuNazev', serverName: 'NIPEZ_predmetu.Nazev', type: 'string' },
    { clientName: 'osobaDalsiInfo', serverName: 'Osoba_zadavat.Dalsi_informace', type: 'string' },
    { clientName: 'osobaEmail', serverName: 'Osoba_zadavat.Email', type: 'string' },
    { clientName: 'osobaFax', serverName: 'Osoba_zadavat.Fax', type: 'string' },
    { clientName: 'osobaFunkce', serverName: 'Osoba_zadavat.Funkce', type: 'string' },
    { clientName: 'osobaJmeno', serverName: 'Osoba_zadavat.Jmeno', type: 'string' },
    { clientName: 'osobaMobil', serverName: 'Osoba_zadavat.Mobil', type: 'string' },
    { clientName: 'osobaPrijmeni', serverName: 'Osoba_zadavat.Prijmeni', type: 'string' },
    { clientName: 'osobaTelefon', serverName: 'Osoba_zadavat.Telefon_zam', type: 'string' },
    { clientName: 'osobaTitPred', serverName: 'Osoba_zadavat.Titul_pred', type: 'string' },
    { clientName: 'osobaTitZa', serverName: 'Osoba_zadavat.Titul_za', type: 'string' },
    { clientName: 'oteviraniLhuta', serverName: 'Podani_nabidka.Datum_otevirani', type: 'dateTime' },
    { clientName: 'oteviraniNavrhLhuta', serverName: 'Podani_navrh.Datum_otevirani', type: 'dateTime' },
    { clientName: 'oteviraniPredNabLhuta', serverName: 'Podani_predb_nab.Datum_otevirani', type: 'dateTime' },
    { clientName: 'oteviraniPredZajemLhuta', serverName: 'Podani_projeveni.Datum_otevirani', type: 'dateTime' },
    { clientName: 'oteviraniZadostLhuta', serverName: 'Podani_zadost.Datum_otevirani', type: 'dateTime' },
    { clientName: 'podaniInformaceLhuta', serverName: 'Podani_informace.Lhuta', type: 'dateTime' },
    { clientName: 'podaniLhuta', serverName: 'Podani_nabidka.Lhuta', type: 'dateTime' },
    { clientName: 'podaniNavrhLhuta', serverName: 'Podani_navrh.Lhuta', type: 'dateTime' },
    { clientName: 'podaniPredNabLhuta', serverName: 'Podani_predb_nab.Lhuta', type: 'dateTime' },
    { clientName: 'podaniPredZajemLhuta', serverName: 'Podani_projeveni.Lhuta', type: 'dateTime' },
    { clientName: 'podaniZadostLhuta', serverName: 'Podani_zadost.Lhuta', type: 'dateTime' },
    { clientName: 'popisPredmet', serverName: 'Popis_predmetu', type: 'text' },
    { clientName: 'predchoziCastId', serverName: 'Hist.Predchozi_cast', type: 'id' },
    { clientName: 'predpokladHodnota', serverName: 'Predpokl_hodnota', type: 'money' },
    { clientName: 'predpokladHodnotaCZK', serverName: 'Predpokl_hodnota_CZK', type: 'money' },
    { clientName: 'predpokladMenaNazev', serverName: 'Predpoklada_mena.Nazev', type: 'string' },
    { clientName: 'profId', serverName: 'Prof', type: 'id' },
    { clientName: 'profilMimo', serverName: 'www_profil_mimo', type: 'string' },
    { clientName: 'profilNazev', serverName: 'Prof.Nazev', type: 'string' },
    { clientName: 'rozdeleniNaCasti', serverName: 'Rozdeleni_na_casti', type: 'bool' },
    { clientName: 'specifZRNazev', serverName: 'Specif_ZR.Nazev', type: 'string' },
    { clientName: 'stavZP', serverName: 'Stav_ZP', type: 'enum', typeParam: 'Stav_postupu' },
    { clientName: 'typVZ', serverName: 'Typ_VZ_uziv', type: 'enum', typeParam: 'Typ_VZ' },
    { clientName: 'ucastnikICO', serverName: '', type: 'string', filterCondition: createUcastnikICOCondition },
    { clientName: 'ucastnikNazev', serverName: '', type: 'string', filterCondition: createUcastnikNazevCondition },
    { clientName: 'uverProfil', serverName: 'Uver_profil', type: 'bool' },
    { clientName: 'uzamceni', serverName: 'Uzamceni', type: 'bool' },
    { clientName: 'vzDnsZavedeneID', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.ID', type: 'id' },
    { clientName: 'vzDnsZavedeneCelekID', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Hierarchie.ID', type: 'id' },
    { clientName: 'vzDnsZavedeneNazev', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Nazev', type: 'string' },
    { clientName: 'vzDnsZavedeneCelekNazev', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Hierarchie.Nazev', type: 'string' },
    { clientName: 'vzDnsZavedeneKod', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Kod', type: 'string' },
    { clientName: 'vzDnsZavedeneCelekKod', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Hierarchie.Kod', type: 'string' },
    { clientName: 'vzDnsZavedeneKodNazev', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Kod_nazev', type: 'string' },
    { clientName: 'vzDnsZavedeneCelekKodNazev', serverName: 'Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Hierarchie.Kod_nazev', type: 'string' },
    { clientName: 'zadavanaDNS', serverName: 'Zadavana_v_DNS', type: 'bool' },
    { clientName: 'zadavatelCastObce', serverName: 'Zadavatel.Cast_obce', type: 'string' },
    { clientName: 'zadavatelHlavniPredmet', serverName: '', type: 'number', filterCondition: createHlavniPredmetCinnostiCondition },
    { clientName: 'zadavatelICO', serverName: 'Zadavatel.ICO', type: 'string' },
    { clientName: 'zadavatelID', serverName: 'Zadavatel', type: 'id' },
    { clientName: 'zadavatelNazev', serverName: 'Zadavatel.Nazev', type: 'string' },
    { clientName: 'zadavatelNUTS', serverName: 'Zadavatel.vazba_NUTS', type: 'id', filterCondition: createSubtreeCondition },
    { clientName: 'zadavatelObec', serverName: 'Zadavatel.Obec', type: 'string' },
    { clientName: 'zadavatelPravniForma', serverName: 'Zadavatel.PF', type: 'id' },
    { clientName: 'zadavatelUlice', serverName: 'Zadavatel.Ulice', type: 'string' },
    { clientName: 'zavedeniDNS', serverName: 'Zavedeni_DNS', type: 'bool' },
    { clientName: 'fullTextFiltr', serverName: 'Fulltext_filtr', type: 'string' }
]);
