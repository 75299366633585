import React, { createContext, FC, KeyboardEventHandler, MouseEventHandler, PropsWithChildren, ReactElement, ReactNode, useContext } from "react";
import { Classes, setupCn } from "@utils/BemUtils";
import './FormControl.scss';

const cn = setupCn('gov-form-control');

const NoContainer: FC = ({ children }) => children as ReactElement ?? null;

export type FormControlContextType = {
    labelClassName?: Classes;
    defaultPlaceholder?: string;
    isInputStandalone?: boolean;
    afterContainer?: ReactNode;
};

export const FormControlContext = createContext<FormControlContextType>({});

export type FormControlBaseProps = {
    className?: Classes;
    headerText?: ReactNode;
    headerTitle?: string;
    hasError?: boolean;
    isInversed?: boolean;
    title?: string;
    onFormControlKeyUp?: KeyboardEventHandler<HTMLDivElement>;
    onFormControlClick?: MouseEventHandler<HTMLDivElement>;
    defaultBehaviorUnset?: boolean;
}

export type FormControlProps = FormControlBaseProps & PropsWithChildren<{
    labelFor?: string;
    afterLabel?: ReactNode;
    isCustom?: boolean;
    container?: FC;
    notEmpty?: boolean;
    isTime?: boolean;
    isDate?: boolean;
    hidden?: boolean;
}>;

const FormControl: FC<FormControlProps> = ({
    labelFor,
    headerText,
    headerTitle,
    notEmpty,
    title,
    afterLabel,
    className,
    container: ControlContainer,
    isCustom,
    hasError,
    isDate,
    isTime,
    onFormControlKeyUp,
    onFormControlClick,
    isInversed,
    defaultBehaviorUnset,
    hidden,
    children
}) => {
    ControlContainer ??= NoContainer;
    const { labelClassName, afterContainer } = useContext(FormControlContext);
    headerTitle ??= typeof headerText == 'string' ? headerText : undefined;
    return (
        <div
            title={title}
            onKeyUp={onFormControlKeyUp}
            onClick={onFormControlClick}
            className={cn.with(className, {
                'not-empty': notEmpty || isTime || isDate, // s novější verzí design-system, než 3.0 odstranit isTime || isDate
                'default-behaviour-unset': defaultBehaviorUnset || isTime || isDate
            }).main({
                '--custom': isCustom,
                '--error': hasError,
                '--inversed': isInversed,
                '--hidden': hidden,
                '__timepicker': isTime,
                '__datepicker': isDate
            })}>
            <ControlContainer>
                {children}
                {
                    headerText &&
                    <label className={cn.with(labelClassName)('__label')} title={headerTitle} htmlFor={labelFor}>{headerText}</label>
                }
                {afterLabel}
            </ControlContainer>
            {afterContainer}
        </div>
    );
};

FormControl.displayName = 'FormControl';

export default React.memo(FormControl);
