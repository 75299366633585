import { ServiceBase } from "./ServiceBase";

export default class HeartBeatService extends ServiceBase {
    public async beat() {
        return await this.requestJson<void>({
            url: `/api/heartbeat`,
            method: 'GET'
        });
    }
}
