import React from 'react'
import Grid from '@components/Grid';
import GovLayout from '@components/GovLayout/GovLayout';
import GridTile from '@components/GridTile/GridTile';
import { useBreadcrumb } from "@store/breadcrumbStore";
import { useConstants } from '@constants/urlConstants';
import { MdOutlinePerson, MdOutlineHandyman, MdOutlineGroups } from 'react-icons/md';
import Icon from '@components/Icon';
import { useDescription } from '@utils/DescriptionHook';
import { useLocalization } from '@store/localizationStore';
import { useAppSettings } from '@store/nodeContextStore';
import ContentBlock from '@components/ContentBlock';
import UniversalLink from '@components/UniversalLink/UniversalLink';

const RozcestnikRegistrace = () => {
    const { t, ts } = useLocalization();
    const [, , , lastItem] = useBreadcrumb();
    const { getUrl } = useConstants();
    const { appSettings } = useAppSettings();

    const rightCol = appSettings.registration.enabled ?
        (
            <Grid columns="3">
                <GridTile to={getUrl('registraceDodavatel')} locKey="NEN-767684" linkText="NEN-770788" icon={<Icon icon={MdOutlineHandyman} size="tile" />} />
                <GridTile to={getUrl('registraceZadavatel')} locKey="NEN-767683" linkText="NEN-770787" icon={<Icon icon={MdOutlinePerson} size="tile" />} />
                <GridTile to={getUrl('registraceSpolecneZadavani')} locKey="NEN-767685" linkText="NEN-770789" icon={<Icon icon={MdOutlineGroups} size="tile" />} />
            </Grid>
        )
        : 
        (
            <ContentBlock>
                <p>
                    {ts('NEN-790963')}<UniversalLink to="https://sd.nipez.cz/nen" isExternal>{ts('NEN-790964')}</UniversalLink>{ts('NEN-790965')}
                </p>
            </ContentBlock>
        )

    useDescription(t('NEN-789455'));

    return (
        <GovLayout
            ratio='1-2'
            spreadRight={3}
            leftCol={<h2 className="gov-title--alpha">{lastItem?.title}</h2>}
            rightCol={rightCol}
        />
    )
};


export default React.memo(RozcestnikRegistrace);