import { MutableRefObject, Ref } from "react";

/**
 * Vytvoří callback pro nastavení reference. Callback nastaví referenci do výstupní
 * reference i vnitřní reference. Podporuje RefObject i callback.
 * @param innerRef Reference pro vnitřní užití v komponentě.
 * @param forwardRef Reference pro přeposlání mimo komponentu.
 */
export const combineRefs = <T>(...refs: (Ref<T> | undefined)[]) => {
    const refCallback = (instance: T) => {
        refs.forEach((ref) => {
            if (!ref) {
                return;
            } else if (typeof ref === "function") {
                ref(instance);
            } else {
                (ref as MutableRefObject<T>).current = instance;
            }
        });
    };
    return refCallback;
};
