import { DataLabelTyped } from "@components/DataLabel";
import GroupBox from "@components/GroupBox";
import { IProfilZadavateleModel } from "@models/IProfilZadavateleModel";
import React from "react";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { IJinaInformaceSubjektuHist, JinaInformaceSubjektuHistAttrMap } from "@models/IJinaInformaceSubjektuHist";
import { ITrzisteHist, TrzisteHistAttrMap } from "@models/ITrzisteModelHist";
import { downloadButtonOnly } from "@components/Table/Buttons/DownloadAllToolbarButton";
import { IProfZadavAttrMap, IProfZadavModel } from "@models/IProfZadavModel";
import { columnWidths, DependentListFrameTyped, ListFrameColumn } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { urlConstants } from "@constants/urlConstants";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";

const DataLabel = DataLabelTyped<IProfilZadavateleModel>();
const DependentListFrame = DependentListFrameTyped<IProfilZadavateleModel>();

const detailProfilZadavateleLoadKey = 'detail-profil-zadavatele';

const ZakladniInformacePage: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <>
            <GroupBox lockey="NEN-812507">
                <DataLabel lockey="NEN-812506" attrName='nazevOrganizace' fillGrid/>
                <DataLabel lockey="NEN-812510" attrName='doplnekKNazvu' />
                <DataLabel lockey="NEN-812511" attrName='ico' />
                <DataLabel lockey="NEN-812512" attrName='dic' />
                <DataLabel lockey="NEN-812513" attrName='jinyIdentifikátor' />
                <DataLabel lockey="NEN-812514" attrName='datumNarozeni' />
                <DataLabel lockey="NEN-812515" attrName='rodneCislo' />
                <DataLabel lockey="NEN-812546" attrName='pfNazev' />
                <DataLabel lockey="NEN-778515" attrName='telefon1' />
                <DataLabel lockey="NEN-812551" attrName='telefon2' />
                <DataLabel lockey="NEN-812552" attrName='fax' />
                <DataLabel lockey="NEN-812554" attrName='email' />
                <DataLabel lockey="NEN-812553" attrName='www' />
                <DataLabel lockey="NEN-812700" attrName='typSubjektu' />
                <DataLabel lockey="NEN-812532" attrName='kategorieZadavatele' />
                <DataLabel lockey="NEN-812740" attrName='obrana' />
                <DataLabel lockey="NEN-812741" attrName='dominantniVliv' />
                <DataLabel lockey="NEN-812653" attrName='relativniCinnost' />
                <DataLabel lockey="NEN-812742" attrName='relativniCinnostJinak' />
                <DataLabel lockey="NEN-812603" attrName='profilZadavatele' />
                <DataLabel lockey="NEN-812703" attrName='dalsiProfil' />
                <DataLabel lockey="NEN-812666" attrName='adresaProfilu' />
                <DataLabel lockey="NEN-812776" attrName='datumUverejneniVVZ' />
                <DataLabel lockey="NEN-812774" attrName='datumVytvoreni' />
                <DataLabel lockey="NEN-812698" attrName='evidencniCisloVeVestniku' />
                <DataLabel lockey="NEN-812726" attrName='multiprofil' showIf='Prof.Multiprofil = true' />
                <DataLabel lockey="NEN-812729" attrName='nazevProfilu' showIf='Prof.Multiprofil = true' />
                <DataLabel lockey="NEN-769956" attrName='adresaProfiluMulti' showIf='Prof.Multiprofil = true' />
            </GroupBox>
            <GroupBox lockey="NEN-812516">
                <DataLabel lockey="NEN-812517" attrName='obec' />
                <DataLabel lockey="NEN-812518" attrName='castObce' />
                <DataLabel lockey="NEN-812523" attrName='ulice' />
                <DataLabel lockey="NEN-812524" attrName='cisloPopisne' />
                <DataLabel lockey="NEN-812525" attrName='cisloOrientacni' />
                <DataLabel lockey="NEN-812526" attrName='psc' />
                <DataLabel lockey="NEN-812527" attrName='stat' />
                <DataLabel lockey="NEN-812777" attrName='nutsKod' />
            </GroupBox>
            <GroupBox lockey="NEN-812704" fillContent showIf="Exists(Uverejneny_dokum, Info_uver.misto = 'PROFIL' and Uverejnen = true and Info_uver.Prof = &prof and Info_uver.Subj = &subj)">
                <ListFrameUverejneneDokumenty />
            </GroupBox>
            <GroupBox lockey="NEN-812528" showIf='Subj.Nazev_uctu <> null or Subj.Predcisli_uctu <> null or Subj.cislo_uctu <> null or Subj.Banka_uctu.Kod<>null or Subj.IBAN<>null or Subj.Banka_uctu.SWIFT<>null'>
                <DataLabel lockey="NEN-812533" attrName='nazevUctu' />
                <DataLabel lockey="NEN-812543" attrName='predcisliUctu' />
                <DataLabel lockey="NEN-812544" attrName='cisloUctu' />
                <DataLabel lockey="NEN-812503" attrName='kodBanky' />
                <DataLabel lockey="NEN-812593" attrName='iban' />
                <DataLabel lockey="XG-336210" attrName='swift' />
            </GroupBox>
            <GroupBox lockey="NEN-812696" fillContent showIf='Exists(Trziste_hist, aktualni = true and Prof = &prof and Subj = &subj)'>
                <ListFrameTrziste />
            </GroupBox>
            <GroupBox lockey="NEN-812559" fillContent showIf='Exists(Jina_infor_subj_hist, aktualni = true and Prof = &prof and Subj = &subj)'>
                <ListFrameJinaInformaceSubjektu />
            </GroupBox>
            <GroupBox lockey="NEN-812879" fillContent showIf='Exists(Profily_zadav, Subj=&Subj)'>
                <ListFrameProfZadav />
            </GroupBox>
        </>
    )
}
const IProfZadavModelColumns: ListFrameColumn<IProfZadavModel>[] = [
    {
        attrName: 'nazev',
        locKey: 'NEN-812827'
    },
    {
        attrName: 'platiOd',
        locKey: 'NEN-812574',
        width: columnWidths.date,
    },
    {
        attrName: 'platiDo',
        locKey: 'NEN-812575',
        width: columnWidths.date
    }
];

const ListFrameProfZadav: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-jiny-profil'}
            condition='Subj = @0'
            conditionParams={'subjID'}
            lightVersion
            attrMapping={IProfZadavAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pjprof', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={IProfZadavModelColumns} />
    );
}

const jinaInformaceSubjektuModelColumns: ListFrameColumn<IJinaInformaceSubjektuHist>[] = [
    {
        attrName: 'popis',
        locKey: 'NEN-812545'
    },
    {
        attrName: 'text',
        locKey: 'NEN-812562'
    }
];

const ListFrameJinaInformaceSubjektu: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-jina-informace-profil'}
            condition='aktualni = true and Prof = @0 and Subj = @1'
            conditionParams={['profID', 'subjID']}
            lightVersion
            attrMapping={JinaInformaceSubjektuHistAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pjinfo', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={jinaInformaceSubjektuModelColumns} />
    );
}

const trzisteModelColumns: ListFrameColumn<ITrzisteHist>[] = [
    {
        attrName: 'nazev',
        locKey: 'NEN-812695',
    },
    {
        attrName: 'adresa',
        locKey: 'NEN-747452'
    },
    {
        attrName: 'adresaProfilu',
        locKey: 'NEN-747453'
    }
];

const ListFrameTrziste: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-seznam-trzist-profil'}
            condition='aktualni = true and Prof = @0 and Subj = @1'
            conditionParams={['profID', 'subjID']}
            lightVersion
            attrMapping={TrzisteHistAttrMap}
            paramNames={useStaticObject({ filterParamName: 'petrz', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={trzisteModelColumns} />
    );
}

const uverejneneDokumentyModelColumns: ListFrameColumn<IUverejnenyDokumModel>[] = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];

const ListFrameUverejneneDokumenty: React.FC = () => {

    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-verejne-dokumenty-profil'}
            condition="Info_uver.misto = 'PROFIL' and Uverejnen = true and Info_uver.Prof = @0 and Info_uver.Subj = @1"
            conditionParams={['profID', 'subjID']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>({desc: true, column: "datumUver"})}
            lightVersion
            paramNames={useStaticObject({ filterParamName: 'pvdok', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            attrMapping={UverejnenyDokumAttrMap}
            columns={uverejneneDokumentyModelColumns}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            extraButtons={downloadButtonOnly} />
    );
}


export default ZakladniInformacePage;