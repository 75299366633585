import { useEffect, useState, useCallback, useRef } from "react";
import { useLocalization } from "@store/localizationStore";
import { useWidgetService } from "@store/nodeContextStore";
import Modal from "@components/Modal/Modal";
import { emailPattern } from "@utils/EmailUtils";
import InputWithButton from "@components/InputWithButton/InputWithButton";
import { FilterManagerChangeDoneEventHandler, FilterManagerChangeEventHandler } from "@components/FilterManager";
import { useSwitchByLanguage } from "@utils/LanguageUtils";

const NewsSubscribeWidget = () => {
    const { ts } = useLocalization();
    const { switchByLanguage, currentLanguage } = useSwitchByLanguage();
    const [hasError, setHasError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [callWasSuccessful, setCallWasSuccessful] = useState(false);

    const controllerRef = useRef(new AbortController());
    const service = useWidgetService();

    const handleChange = useCallback<FilterManagerChangeEventHandler>((_, newValue) => {
        setHasError(newValue != '' && !emailPattern.test(newValue));
    }, [])

    const close = useCallback(() => {
        setShowModal(false);
    }, []);

    const submitForm = useCallback<FilterManagerChangeDoneEventHandler>(async (_, value, reason) => {
        if (reason != 'enter' && reason != 'buttonClick') {
            return;
        }
        setCallWasSuccessful(false);

        const controller = new AbortController();
        controllerRef.current = controller;

        const result = await service.newsSubscribe(controllerRef.current, value, switchByLanguage({ cz: 'cs', en: 'en' }));

        if (result.value == 200 && !result.hasErrors) {
            setCallWasSuccessful(true);
        }
        setShowModal(true);
    }, [controllerRef, switchByLanguage, service])

    useEffect(() => {
        return () => {
            controllerRef.current.abort();
        }
    }, [controllerRef, currentLanguage])

    return (
        <>
            {
                showModal &&
                <Modal
                    headerText={ts(callWasSuccessful ? "NEN-818605" : "NEN-818607")}
                    buttonText={ts("NEN-644809").toString()}
                    onClose={close}>
                    <p>{ts(callWasSuccessful ? "NEN-818604" : "NEN-818606")}</p>
                </Modal>
            }

            <h2 id='receive-notifications'>{ts('NEN-749127')}</h2>
            <InputWithButton
                id="news-subscribe-widget"
                buttonTextLocKey="NEN-819429"
                buttonTitleLocKey="NEN-819429"
                placeholderLocKey="NEN-819406"
                headerText="NEN-819406"
                isWide
                disableButton={{ disabledIfEmpty: true, disable: hasError }}
                hasError={hasError}
                inputType="email"
                shouldClearInput={callWasSuccessful}
                onInputChange={handleChange}
                onInputChangeDone={submitForm} />
        </>
    )
};

NewsSubscribeWidget.displayName = 'NewsSubscribeWidget';

export default NewsSubscribeWidget;