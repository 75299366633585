import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, useLocation } from 'react-router';
import { AppDispatch, ApplicationState, ApplicationStateSelector } from '.';
import { DNSystemAttrMap } from '../models/IDNSystemModel';
import { ProfilZadavateleAttrMap } from '../models/IProfilZadavateleModel';
import { SubjektAttrMap } from '../models/ISubjektModel';
import { SystemKvalifikaceAttrMap } from '../models/ISystemKvalifikaceModel';
import { ZpAttrMap } from '../models/IZpModelHist';
import { parseDnsUrl, prepareDnsCondition } from '../pages/detailDNSystem/DetailDNSystem';
import { parseProfilUrl, prepareProfilCondition } from '../pages/detailProfilZadavatele/DetailProfilZadavatele';
import { parseSkUrl, prepareSkCondition } from '../pages/detailSystemKvalifikace/DetailSystemKvalifikace';
import { parseVZUrl, prepareVZCondition } from '../pages/detailVerejneZakazky/DetailVerejneZakazky';
import { parseSubjektUrl, prepareSubjektCondition } from '../pages/registry/registrySubjektu/detailRegistru/DetailSubjektu';
import { IGetDataRowsParams } from '../services/DataRowsService';
import { AttrMapping, AttrName, IData } from '../utils/AttrMapping';
import { Deferred, PromiseUtils } from '../utils/PromiseUtils';
import { UrlParamObject } from '../utils/QueryHook';
import { getErrorStoreActions } from './errorStore';
import { useLocalization } from './localizationStore';
import { getDataRowsService, useTaskManager } from './nodeContextStore';

export const name = 'breadcrumbTitles';

export type BreadcrumbTitleStoreState = {
    objectTitles: {
        [key: string]: { isFetching: boolean, value: string | null };
    }
}

export const slice = createSlice({
    name,
    initialState: {
        objectTitles: {}
    } as BreadcrumbTitleStoreState,
    reducers: {
        setObjectTitle: (state, action: PayloadAction<{ key: string, value: string | null, isFetching: boolean }>) => {
            return {
                ...state,
                objectTitles: {
                    ...state.objectTitles,
                    [action.payload.key]: {
                        isFetching: action.payload.isFetching,
                        value: action.payload.value
                    }
                }
            } as BreadcrumbTitleStoreState
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getBreadcrumbState = (state: ApplicationState) => state[name];

export const getBreadcrumbObjectTitles = createSelector([getBreadcrumbState],
    (breadcrumbTitleStore) => breadcrumbTitleStore?.objectTitles
);

export const getBreadcrumbObjectTitle = createSelector(
    getBreadcrumbObjectTitles,
    (_: unknown, key: string) => key,
    (objects, key) => objects[key]
);

// Define action creators.
export const actionCreators = {
    load: (key: string, params: IGetDataRowsParams) =>
        async (dispatch: AppDispatch, getState: ApplicationStateSelector): Promise<any> => {
            const actions = slice.actions;

            dispatch(actions.setObjectTitle({ key: key, value: null, isFetching: true }));

            const attrName = params.attributes?.[0].clientName as string ?? ''
            const appState = getState();
            const service = getDataRowsService(appState);

            const promise = service.loadData({
                ...params
            });

            const result = await promise;

            if (!result.hasErrors && result.value.length == 1) {
                dispatch(actions.setObjectTitle({ key: key, value: result.value[0][attrName] as string, isFetching: false }));
            } else {
                dispatch(actions.setObjectTitle({ key: key, value: null, isFetching: false }));
                dispatch(getErrorStoreActions().push(result.errors || ['Obj not found!']));
            }

            return result;
        }
}

export type useBreadcrumbTitleGetterType<T extends IData> = (fallbackLockey?: string) =>
    (match: match<UrlParamObject> | null, lockey?: string) =>
        string | null;

type AttrToLoadType<T extends IData> = {
    attrMapping: AttrMapping<T>,
    attrName: AttrName<T>,
    condition: string,
    conditionParams: string[]
}

export type ParsedUrlCondition = {
    condition: string;
    conditionParams: string[];
}

export type  UrlConditionParser = (match: match<UrlParamObject> | null) => ParsedUrlCondition;

const useTitleFactory = <T extends IData>(
    attrMapping: AttrMapping<T>,
    attrName: AttrName<T>,
    attrsToLoad: Map<string, AttrToLoadType<T>>,
    parser: UrlConditionParser): useBreadcrumbTitleGetterType<T> => {
    const state = useSelector(getBreadcrumbState);
    const { t } = useLocalization();

    const callback = useCallback((match, fallbackLockey?: string): string | null => {
        const attrInfo = attrMapping.getByClientName(attrName);
        if (attrInfo) {
            const { condition, conditionParams } = parser(match);
            const key = `${attrMapping.objectName}-${condition}-${conditionParams.join('-')}`;
            const title = state.objectTitles[key];
            if (!title) {
                attrsToLoad.set(key, { attrMapping, attrName, condition, conditionParams: conditionParams })
                return null;
            } else if (title.isFetching) {
                return null
            }
            return title.value || ((fallbackLockey && t(fallbackLockey)) || null);
        } else {
            console.error(attrName + " not found!");
        }
        return null;
    }, [attrMapping, attrName, parser, state.objectTitles, t, attrsToLoad]);

    return ((fallbackLockey?: string) => {
        return (match: match<UrlParamObject> | null) => callback(match, fallbackLockey);
    });
}

export const useBreadcrumbTitle = () => {
    const dispatch: AppDispatch = useDispatch();
    const attrsToLoad = useMemo(() => new Map<string, AttrToLoadType<any>>(), []);
    const location = useLocation();
    const taskManager = useTaskManager();
    const key = `bread-title-render-${location.pathname}`

    const getVZTitle = useTitleFactory(
        ZpAttrMap,
        'nazev',
        attrsToLoad,
        (match) => prepareVZCondition(parseVZUrl(match).code)
    );

    const getProfilTitle = useTitleFactory(
        ProfilZadavateleAttrMap,
        'nazevOrganizace',
        attrsToLoad,
        (match) => prepareProfilCondition(parseProfilUrl(match))
    );

    const getDnsTitle = useTitleFactory(
        DNSystemAttrMap,
        'nazev',
        attrsToLoad,
        (match) => prepareDnsCondition(parseDnsUrl(match))
    );

    const getSkTitle = useTitleFactory(
        SystemKvalifikaceAttrMap,
        'skNazev',
        attrsToLoad,
        (match) => prepareSkCondition(parseSkUrl(match))
    );

    const getSubjektTitle = useTitleFactory(
        SubjektAttrMap,
        'nazevOrganizace',
        attrsToLoad,
        (match) => prepareSubjektCondition(parseSubjektUrl(match))
    );

    const loadData = useCallback(async (controller?: AbortController) => {
        if (attrsToLoad.size > 0) {
            for (const [key, val] of attrsToLoad) {
                await dispatch(actionCreators.load(key, {
                    className: val.attrMapping.objectName,
                    condition: val.condition,
                    conditionParams: val.conditionParams,
                    attributes: val.attrMapping.getDataRowAttributes(val.attrName),
                    abortSignal: controller?.signal
                }));
            }
            attrsToLoad.clear();
        }
    }, [dispatch]);

    useEffect(() => {
        const controller = new AbortController();

        if (taskManager.isTaskCompleted(key)) {
            taskManager.removeTaskFromCompleted(key);
        } else {
            loadData(controller);
        }

        return () => {
            controller?.abort();
        }
    }, [location.pathname]);

    let setAttrPromise: Deferred<void>;

    taskManager.add(key + '-title-set', () => {
        setAttrPromise = PromiseUtils.defer();

        setAttrPromise.promise.then(() => {
            taskManager.add(key, loadData);
        });

        setAttrPromise?.resolve();
        return setAttrPromise.promise;
    });

    return { getVZTitle, getProfilTitle, getDnsTitle, getSubjektTitle, getSkTitle };
}

export type UseBreadcrumbTitle = ReturnType<typeof useBreadcrumbTitle>;