import React, { CSSProperties, PropsWithChildren, ReactEventHandler, useEffect, useRef } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { combineRefs } from "@utils/CombineRefs";

const cn = setupCn('resizable-iframe');

export type ResizableIframeProps = PropsWithChildren<{
    className?: Classes;
    id?: string;
    src: string;
    onLoad?: ReactEventHandler<HTMLIFrameElement>;
    visibility?: CSSProperties['visibility'];
    label?: string;
}>;

const ResizableIframe = React.forwardRef<HTMLIFrameElement, ResizableIframeProps>(({
    className,
    src,
    id,
    onLoad,
    visibility,
    label
}, iframeRef) => {
    const innerRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        //lazy loading otherwise crashes in Node
        import("iframe-resizer").then(iframeResizer => {
            const iframeR = innerRef?.current;
            if (iframeR) {
                iframeResizer.iframeResizer({
                    heightCalculationMethod: 'bodyScroll'
                }, iframeR);
            }
        });
    }, [])

    return (
        <iframe
            style={{ visibility: visibility, display: visibility == 'hidden' ? 'none' : '' }}
            src={src}
            ref={combineRefs(innerRef, iframeRef)}
            frameBorder={0}
            className={cn.with(className)()}
            id={id}
            onLoad={onLoad}
            title={label}
            role={label ? '' : 'presentation'}
            width="100%" />
    );
});

ResizableIframe.displayName = 'ResizableIframe';

export default ResizableIframe;
