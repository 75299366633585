import { FC, useRef } from "react";
import { useLocalization } from "@store/localizationStore";
import { usePageTitle } from "@utils/PageTitleHook";
import { urlConstants } from "@constants/urlConstants";
import RegistrZadavateluFilter from "./ProfilyZadavateluFilter";
import { IProfilZadavateleModel, ProfilZadavateleAttrMap } from "@models/IProfilZadavateleModel";
import ListFrame, { columnWidths, ListFrameColumns, ListFrameProps } from "@components/ListFrame";
import { inferredPartial } from "@utils/TypeUtils";
import ContentBlock from "@components/ContentBlock";
import Paginator from "@components/Pagination";
import { detailLinks } from "@constants/detailLinks";
import { useDescription } from "@utils/DescriptionHook";

export const ON_PAGE_ROWS = 50;

const columns: ListFrameColumns<IProfilZadavateleModel> = [
    {
        attrName: 'nazevOrganizace',
        locKey: 'NEN-812769',
        width: '200%'
    },
    {
        attrName: 'multiprofil',
        locKey: 'NEN-812726',
        width: '50%'
    },
    {
        attrName: 'nazevProfilu',
        locKey: 'NEN-812770',
    },
    {
        attrName: 'datumDeaktivace',
        locKey: 'NEN-812775',
        width: columnWidths.dateTime
    },
];

const paramNames = urlConstants.profilyZadavateluZneplatnene.paramNames;

const listFrameProps = inferredPartial<ListFrameProps<typeof ProfilZadavateleAttrMap>>()({
    fulltextAttributes: ['nazevOrganizace', 'nazevProfilu'],
    orderBy: ['nazevOrganizace', { desc: true, column: "id" }],
});

const profilyZadavateluLoadKey = 'profily-zadavatelu-zneplatnene-seznam';

const ProfilyZadavateluZneplatnene: FC = () => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-602382'));
    useDescription(t('NEN-789442'));

    const frameContainer = useRef<HTMLDivElement>(null);

    return (
        <>
            <h2 className='u-sr-only'>{t('NEN-602382')}</h2>
            <ContentBlock>
                <RegistrZadavateluFilter paramNames={paramNames} />
                <ListFrame
                    {...listFrameProps}
                    listFrameKey={profilyZadavateluLoadKey}
                    condition='Platny <> true and adresa_profil_nen<>null'
                    paramNames={paramNames}
                    onPageCount={ON_PAGE_ROWS}
                    skeletonCount={ON_PAGE_ROWS}
                    attrMapping={ProfilZadavateleAttrMap}
                    detailLinkFactory={detailLinks.getProfilZadavateleDetailLinkFactory()}
                    columns={columns} />
            </ContentBlock>
            <Paginator
                loadMoreButton
                scrollToRef={frameContainer}
                collectionKey={profilyZadavateluLoadKey} />
        </>
    );
}

export default ProfilyZadavateluZneplatnene;
