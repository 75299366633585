import { FC } from "react";
import { FilterParamsNames } from "@constants/urlConstants";
import { IProfilZadavateleModel } from "@models/IProfilZadavateleModel";
import { IZpModel, ZpAttrMap } from "@models/IZpModelHist";
import VerejneZakazkyFilter from "../../verejneZakazky/verejneZakazky/VerejneZakazkyFilter";
import { columnWidths, DependentListFrameTyped, ListFrameColumns } from "@components/ListFrame";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { Stav_postupuEnum } from "@models/staticEnumerators/Stav_postupuEnum";
import { detailLinks } from "@constants/detailLinks";
import { FulltextAttr } from "@utils/FilterCondition";

const DependentListFrame = DependentListFrameTyped<IProfilZadavateleModel>();

export const ON_PAGE_ROWS = 50;

const detailProfilZadavateleLoadKey = 'detail-profil-zadavatele';
const fulltextAttributes: FulltextAttr[] = [{ attrName: 'fullTextFiltr', type: 'contains' }, 'hlavniMistoNUTS', 'nipezPredmetuNazev'];

const defaultColumns: ListFrameColumns<IZpModel> = [ // default pokud neni predan parametr se sloupci
    {
        attrName: 'kod',
        locKey: 'NEN-812594',
        width: columnWidths.zpCode
    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812694',
        width: '200%'
    },
    {
        attrName: 'stavZP',
        locKey: 'NEN-738558',
        width: 120
    },
    {
        attrName: 'zadavatelNazev',
        locKey: 'NEN-812618',
        width: 200
    },
    {
        attrName: 'podaniLhuta',
        locKey: 'NEN-738562',
        width: columnWidths.dateTime
    }
];

const ListFrameZakazky: FC<{
    condition: string,
    loadKey: string,
    paramNames?: FilterParamsNames,
    columns?: ListFrameColumns<IZpModel>,
    hideActualState?: boolean,
    actualStateHiddenValues?: Stav_postupuEnum[]
}> = ({
    condition,
    loadKey,
    paramNames,
    columns,
    hideActualState,
    actualStateHiddenValues
}) => {
        columns ??= defaultColumns;
        return (
            <>
                <VerejneZakazkyFilter
                    id={detailProfilZadavateleLoadKey + loadKey}
                    paramNames={paramNames}
                    hideActualState={hideActualState}
                    actualStateHiddenValues={actualStateHiddenValues}
                />
                <DependentListFrame
                    listFrameKey={detailProfilZadavateleLoadKey + loadKey}
                    withPaginator
                    detailLinkFactory={detailLinks.getLfZpDetailLinkFactory(true)}
                    condition={condition}
                    conditionParams={'profID'}
                    fulltextAttributes={fulltextAttributes}
                    orderBy={useOrderByMemo<IZpModel>({ desc: true, column: "id" })}
                    onPageCount={ON_PAGE_ROWS}
                    attrMapping={ZpAttrMap}
                    skeletonCount={ON_PAGE_ROWS}
                    columns={columns}
                    paramNames={paramNames}
                />
            </>
        );
    }

export default ListFrameZakazky;