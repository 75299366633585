import React, { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import GovLinkBase, { GovLinkBaseProps } from "./GovLinkBase";

export type GovLinkProps = GovLinkBaseProps & Omit<LinkProps, 'className'>;

const LinkElement: React.FC<any> = (linkProps) => <Link {...linkProps} />
const GovLink: FC<GovLinkProps> = ({ ...restProps }) => {
    return (
        <GovLinkBase
            element={LinkElement}
            {...restProps} />
    )
}

export default React.memo(GovLink);
