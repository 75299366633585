import { FC, useRef } from "react";
import { useLocalization } from "@store/localizationStore";
import { usePageTitle } from "@utils/PageTitleHook";
import { urlConstants } from "@constants/urlConstants";
import { AttrNames } from "@utils/AttrMapping";
import { ISubjektModel, SubjektAttrMap } from "@models/ISubjektModel";
import RegistryDodavateluFilter from "./RegistryDodavateluFilter";
import { columnWidths, ListFrame, ListFrameColumns } from "@components/ListFrame";
import ContentBlock from "@components/ContentBlock";
import Paginator from "@components/Pagination";
import { detailLinks } from "@constants/detailLinks";
import { useDescription } from "@utils/DescriptionHook";
import { useOrderByMemo } from "@utils/OrderByUtils";

export const ON_PAGE_ROWS = 50;

const columns: ListFrameColumns<ISubjektModel> = [
    {
        attrName: 'nazevOrganizace',
        locKey: 'NEN-812506',
        width: '200%'
    },
    {
        attrName: 'ico',
        locKey: 'NEN-812508',
        width: columnWidths.ico
    },
    {
        attrName: 'externiAdministrator',
        locKey: 'NEN-812714',
    },
    {
        attrName: 'pfNazev',
        locKey: 'NEN-812546',
    },
    {
        attrName: 'nutsKod',
        locKey: 'NEN-812777',
    }
];

const paramNames = urlConstants.registrDodavatelu.paramNames;
const registrDodavateluLoadKey = 'registr-dodavatelu-seznam';
const fulltextAttributes: AttrNames<ISubjektModel> = ['nazevOrganizace', 'ico', 'nutsKod'];

const RegistrDodavatelu: FC = () => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-602387'));
    useDescription(t('NEN-789444'));

    const frameContainer = useRef<HTMLDivElement>(null);

    return (
        <>
            <h2 className='u-sr-only'>{t('NEN-602387')}</h2>
            <ContentBlock>
                <RegistryDodavateluFilter id={registrDodavateluLoadKey} paramNames={paramNames} />
                <ListFrame
                    listFrameKey={registrDodavateluLoadKey}
                    condition="Dodavatel = true and Plati_DO = null"
                    paramNames={paramNames}
                    fulltextAttributes={fulltextAttributes}
                    orderBy={useOrderByMemo<ISubjektModel>("nazevOrganizace", { desc: true, column: "id" })}
                    onPageCount={ON_PAGE_ROWS}
                    skeletonCount={ON_PAGE_ROWS}
                    attrMapping={SubjektAttrMap}
                    detailLinkFactory={detailLinks.getDetailSubjektLinkFactory()}
                    columns={columns} />
            </ContentBlock>
            <Paginator
                loadMoreButton
                scrollToRef={frameContainer}
                collectionKey={registrDodavateluLoadKey} />
        </>
    );
}

export default RegistrDodavatelu;
