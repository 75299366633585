import FilterManager from "@components/FilterManager";
import { urlConstants } from "@constants/urlConstants";
import React, { FC, useRef } from "react"
import { useLocalization } from "@store/localizationStore";
import { usePageTitle } from "@utils/PageTitleHook";
import { ISystemKvalifikaceModel, SystemKvalifikaceAttrMap } from "@models/ISystemKvalifikaceModel";
import { columnWidths, ListFrame, ListFrameColumns } from "@components/ListFrame";
import { AttrNames } from "@utils/AttrMapping";
import ContentBlock from "@components/ContentBlock";
import Paginator from "@components/Pagination";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";
import { useDescription } from "@utils/DescriptionHook";

export const ON_PAGE_ROWS = 50;

const columns: ListFrameColumns<ISystemKvalifikaceModel> = [
    {
        attrName: 'skKod',
        locKey: 'NEN-812767',
        width: 150
    },
    {
        attrName: 'skNazev',
        locKey: 'NEN-812758'
    },
    {
        attrName: 'skZadavatelNazev',
        locKey: 'NEN-812641'
    },
    {
        attrName: 'skStav',
        locKey: 'NEN-812766',
        width: 80
    },
    {
        attrName: 'obdobiOd',
        locKey: 'NEN-812759',
        width: columnWidths.date
    },
    {
        attrName: 'obdobiDo',
        locKey: 'NEN-812762',
        width: columnWidths.date
    }
];

const paramNames = urlConstants.registrSK.paramNames;
const registrSkLoadKey = 'registr-SK-seznam';
const fulltextAttributes: AttrNames<ISystemKvalifikaceModel> = ['skKod', 'skNazev', 'skZadavatelNazev'];


const RegistrSk: FC = () => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-812878'));
    useDescription(t('NEN-789440'));

    const listFrameRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <h2 className='u-sr-only'>{t('NEN-812877')}</h2>
            <ContentBlock>
                <FilterManager id='registr-sk-filter' paramNames={paramNames} />
                <ListFrame
                    containerRef={listFrameRef}
                    listFrameKey={registrSkLoadKey}
                    paramNames={paramNames}
                    skeletonCount={ON_PAGE_ROWS}
                    onPageCount={ON_PAGE_ROWS}
                    fulltextAttributes={fulltextAttributes}
                    attrMapping={SystemKvalifikaceAttrMap}
                    orderBy={useOrderByMemo<ISystemKvalifikaceModel>({ desc: true, column: "skKod" })}
                    detailLinkFactory={detailLinks.getSkDetailLinkFactory()}
                    columns={columns} />
            </ContentBlock>
            <Paginator
                loadMoreButton
                collectionKey={registrSkLoadKey}
                scrollToRef={listFrameRef} />
        </>
    );
}

export default React.memo(RegistrSk);
