import React, { FC, ReactNode, useCallback } from "react";
import { LocationDescriptorObject } from "history";
import { Classes, setupCn } from "@utils/BemUtils";
import { useLocalization } from "@store/localizationStore";
import { useUrlParamsExtended } from "@utils/QueryHook";
import Skeleton from "react-loading-skeleton";
import { FilterParamsNames } from "@constants/urlConstants";
import Pagination from "./Pagination";
import { renderIf } from "@utils/RenderUtils";
import { useCollectionPaginator } from "@store/collectionStore";
import FlexBox from "@components/FlexBox/FlexBox";
import './Paginator.scss';
import PaginatorLoadMore from "./PaginatorLoadMore";

export type PaginatorComponentProps = {
    totalCount?: number;
    onPageCount?: number | null;
    loadMoreButton?: boolean;
    scrollToRef?: React.RefObject<HTMLElement>;
    paramNames: FilterParamsNames;
    isLoading?: boolean;
    className?: Classes;
    extraButtons?: ReactNode[];
}

export type PaginatorQueryParams = {
    onpage?: string;
    page?: string;
}

export type PaginatorHistoryState = {
    isLoadMore?: boolean;
}

export const parseActualPage = (page: number | string | undefined): [number, number] => {
    if (!page || page <= 0) {
        return [1, 1];
    }
    if (typeof page == 'number') {
        return [page, page];
    }
    const pageRange = page.toString()
        .split('-')
        .map(part => Number(part))
        .filter(part => Number.isFinite(part));
    if (pageRange.length > 1) {
        return [Math.max(pageRange[0], 1), pageRange[1]];
    }
    if (pageRange.length == 1) {
        const pageIndex = Math.max(pageRange[0], 1);
        return [pageIndex, pageIndex];
    }
    return [1, 1];
}

const cn = setupCn('paginator');
const pagContainerCn = cn.setupSubCn('__container');

export const PaginatorComponent: FC<PaginatorComponentProps> = React.memo(({
    className,
    onPageCount,
    totalCount,
    loadMoreButton,
    scrollToRef,
    paramNames,
    isLoading: isLoadingProp,
    extraButtons
}) => {
    const isLoading = isLoadingProp && (totalCount == null || onPageCount == null);
    const { t } = useLocalization();
    const { filterParams, getUpdatedFilterUrl } = useUrlParamsExtended({ paramNames });
    const pageScroll = useCallback(() => {
        if (scrollToRef?.current) {
            const scrollY = scrollToRef?.current.getBoundingClientRect().top + window.scrollY - 20;
            window.scrollTo({ top: scrollY, behavior: 'smooth' });
        }
    }, [scrollToRef]);

    const pageUrl = useCallback(({ page, historyState }: { page: string | number; historyState?: PaginatorHistoryState; }): LocationDescriptorObject<PaginatorHistoryState> => {
        const pathname = getUpdatedFilterUrl({ update: { page } });
        return {
            pathname,
            state: historyState
        };
    }, [getUpdatedFilterUrl]);

    const pageRangePath = useCallback(({ from, to, historyState }: { from: number; to: number; historyState?: PaginatorHistoryState; }) =>
        pageUrl({ page: `${from}-${to}`, historyState }),
        [pageUrl]);

    if (!isLoading && (totalCount == null || onPageCount == null)) {
        return null;
    }

    totalCount ??= 1000;
    onPageCount ??= 10;
    const maxPage = Math.ceil(totalCount / onPageCount);
    if (maxPage <= 1) {
        return extraButtons && totalCount > 0 ? (
            <FlexBox className={pagContainerCn()}>
                <div className={pagContainerCn('__buttons')}>
                    {extraButtons}
                </div>
            </FlexBox>
        ) : null
    }

    const pagesRange = parseActualPage(filterParams?.page);
    const actualPage = Math.min(maxPage, Math.max(...pagesRange));
    const isLast = actualPage >= maxPage;
    const pages = [... new Set([
        1,
        actualPage - 2,
        actualPage - 1,
        actualPage,
        actualPage + 1,
        maxPage
    ])]
        .filter(page => page > 0 && page <= maxPage)
        .sort((a, b) => a - b);

    const buttons: ReactNode[] = [];
    pages.forEach((page, index) => {
        const withseparator = index != 0 && pages[index - 1] != page - 1;
        if (withseparator) {
            buttons.push(<Pagination.Separator key={`${page}-sep`} />);
        }
        buttons.push(
            <Pagination.Page key={page} to={pageUrl({ page })} onClick={pageScroll} isActive={page == actualPage}>
                {
                    isLoading
                        ? <Skeleton />
                        : <span>
                            <span className='u-sr-only'>{t('NEN-787016')}</span> {page}
                        </span>}
            </Pagination.Page>
        );
    });

    return (
        <nav className={cn.with(className)()}>
            <FlexBox className={pagContainerCn()}>
                <div className={pagContainerCn('__buttons')}>
                    {renderIf(loadMoreButton && !isLast,
                        <PaginatorLoadMore
                            actualPage={actualPage}
                            getPageRangePath={pageRangePath}
                            onPageCount={onPageCount}
                            rangeFrom={pagesRange[0]} />
                    )}
                    {extraButtons}
                </div>
                <div>
                    <Pagination>
                        <Pagination.Arrow to={pageUrl({ page: Math.max(actualPage - 1, 1) })} direction='left' isDisabled={pagesRange[0] == 1} onClick={pageScroll} />
                        {buttons}
                        <Pagination.Arrow to={pageUrl({ page: Math.min(actualPage + 1, maxPage) })} direction='right' isDisabled={isLast} onClick={pageScroll} />
                    </Pagination>
                </div>
            </FlexBox>
        </nav>
    )
});

PaginatorComponent.displayName = 'PaginatorComponent';

export type PaginatorProps =
    Omit<PaginatorComponentProps, 'isLoading' | 'paramNames' | 'onPageCount' | 'totalCount'> &
    {
        collectionKey: string;
    }

const Paginator: FC<PaginatorProps> = ({ collectionKey, ...restProps }) => {
    const {
        count,
        isFetchedOnce,
        isFetching,
        onPage,
        paramNames
    } = useCollectionPaginator({ collectionKey }) ?? {};

    if (!paramNames) {
        return null;
    }

    return (
        <PaginatorComponent
            {...restProps}
            paramNames={paramNames}
            onPageCount={onPage}
            totalCount={count}
            isLoading={isFetching || !isFetchedOnce}
        />
    );
}

export default React.memo(Paginator);
