import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import Grid from "@components/Grid";
import './FilterManagerRow.scss';

const cn = setupCn('filter-manager-row');

export type FilterManagerRowProps = PropsWithChildren<{
    className?: Classes;
}>;

const FilterManagerRow: FC<FilterManagerRowProps> = ({
    className,
    children
}) => {
    return (
        <Grid className={cn.with(className).main()} columns='3' isPlain alignItems='start'>
            {children}
        </Grid>
    );
};

FilterManagerRow.displayName = 'FilterManagerRow';

export default React.memo(FilterManagerRow);
