import Button from "@components/Button/Button";
import ContentBlock from "@components/ContentBlock";
import FormControl from "@components/FormControl";
import Grid from "@components/Grid";
import Icon from "@components/Icon";
import Loader from "@components/Loader";
import { useLocalization } from "@store/localizationStore";
import { setupCn } from "@utils/BemUtils";
import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";
import { MdClose, MdDone } from "react-icons/md";
import { useRouteMatch } from "react-router";
import Note from "@components/Note";

import './CustomForm.scss';

const cn = setupCn('custom-form');
const cnRule = cn.setupSubCn('__rule');
const govCn = setupCn('gov-form-control__input');

export type CustomFormState = {
    [key: string]: {
        value?: string;
        type?: React.HTMLInputTypeAttribute;
        headerText: string;
        changedOnce?: boolean;
        validation?: ValidationType,
    }
}

export type ValidationType = {
    required?: {
        rules?: ValidationRule[];
    };
    optional?: {
        minimalRequired?: number;
        lockey?: string;
        rules?: ValidationRule[];
    };
};
export type ValidationRule = {
    regex?: RegExp;
    custom?: (value: string, state: CustomFormState) => boolean;
    lockey?: string;
    valid?: boolean;
};
export type CustomFormStateKey = Extract<keyof CustomFormState, string>;
export type CustomFormStateKeys = Array<CustomFormStateKey>;

export type UseCustomFormParams = {
    state: CustomFormState,
    onSubmit?: (event: FormEvent<HTMLFormElement>, state: CustomFormState) => Promise<void>;
    onChange?: (event: ChangeEvent<HTMLInputElement>, state: CustomFormState) => Promise<void>;
}

type CustomFormProps = UseCustomFormParams & {
    lockey?: string;
    formDescLockey?: string;
}

const validateField = (state: CustomFormState, fieldName: CustomFormStateKey): ValidationType => {
    if (!state[fieldName].validation)
        return {};

    const testRule = (rule: ValidationRule) => ({
        ...rule,
        valid: rule.regex?.test?.(state[fieldName].value ?? '')
            ?? rule.custom?.(state[fieldName].value ?? '', state)
            ?? false
    });

    const requiredRules = state[fieldName].validation?.required?.rules?.map(testRule);
    const optionalRules = state[fieldName].validation?.optional?.rules?.map(testRule);

    return {
        ...state[fieldName].validation,
        required: {
            ...state[fieldName].validation?.required,
            rules: requiredRules
        },
        optional: {
            ...state[fieldName].validation?.optional,
            rules: optionalRules
        }
    };
};

const isRequiredValid = (validation?: ValidationType) =>
    (validation?.required?.rules?.every(rule => rule.valid) ?? true)

const isOptionalValid = (validation?: ValidationType) =>
    (validation?.optional?.rules?.filter(rule => rule.valid).length ?? 0)
    >= (validation?.optional?.minimalRequired ?? 0);

const isFieldValid = (validation?: ValidationType) =>
    isRequiredValid(validation) && isOptionalValid(validation);

const validate = (state: CustomFormState, touch?: boolean) => {
    let valid = true;
    const newState = { ...state };
    (Object.keys(newState) as CustomFormStateKeys).forEach(fieldName => {
        const validation = validateField(newState, fieldName);
        valid = valid && isFieldValid(validation);
        newState[fieldName] = {
            ...newState[fieldName],
            changedOnce: touch ? true : newState[fieldName].changedOnce,
            validation: validation
        }
    });
    return { valid, newState };
};

const useCustomForm = ({ state, onChange, onSubmit }: UseCustomFormParams) => {
    const [formState, setFormState] = useState<CustomFormState>(state);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        try {
            const { valid, newState } = validate(formState, true);
            setIsValid(valid);
            if (!valid) {
                setFormState(newState)
                return;
            }

            await onSubmit?.(event, newState);
        } finally {
            setLoading(false);
        }
    }, [formState, setFormState, setLoading, setIsValid, onSubmit]);

    const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(async event => {
        const fieldName = event.target.name as keyof typeof formState;
        const state = {
            ...formState,
            [fieldName]: {
                ...formState[fieldName],
                changedOnce: true,
                value: event.target.value
            }
        };
        const { newState, valid } = validate(state);
        setIsValid(valid);
        setFormState(newState);

        await onChange?.(event, newState);
    }, [formState, setFormState, setIsValid, onChange]);

    return { formState, setFormState, handleSubmit, handleChange, loading, isValid };
}

const FormContentWrapper: React.FC<{ lockey: string | undefined }> = ({ lockey, children }) =>
    lockey ? <ContentBlock lockey={lockey}>{children}</ContentBlock> : <>{children}</>

const CustomForm: React.FC<CustomFormProps> = ({
    lockey,
    state,
    onSubmit,
    onChange,
    formDescLockey
}) => {
    const { ts } = useLocalization();

    const { formState, handleChange, handleSubmit, loading, isValid } = useCustomForm({
        state,
        onSubmit,
        onChange
    });

    return (
        <form onSubmit={handleSubmit} className={cn()}>
            {loading && <Loader withOverlay />}
            <FormContentWrapper lockey={lockey}>
                <Grid isPlain columns="2">
                    {
                        (Object.keys(formState) as CustomFormStateKeys)
                            .map(fieldName =>
                                <FormControl
                                    hidden={formState[fieldName].type == 'hidden'}
                                    key={'form_control_' + fieldName}
                                    notEmpty={!!formState[fieldName].value}
                                    headerText={ts(formState[fieldName].headerText)}
                                    hasError={formState[fieldName].changedOnce && !isFieldValid(formState[fieldName].validation)}
                                    labelFor={fieldName}>
                                    <input
                                        className={govCn()}
                                        onChange={handleChange}
                                        type={formState[fieldName].type ?? 'text'}
                                        name={fieldName}
                                        id={fieldName}
                                        value={formState[fieldName].value}
                                        aria-required={true} />
                                </FormControl>
                            )
                    }
                </Grid>
                {
                    formDescLockey &&
                    <Note className="form-desc">{ts(formDescLockey)}</Note>
                }
                {
                    ValdiationRuleLabelFactory(formState, 'required')
                }
                {
                    ValdiationRuleLabelFactory(formState, 'optional')
                }
                <Button type="submit" disabled={!isValid}>
                    {ts("NEN-645280")}
                </Button>
            </FormContentWrapper>
        </form>
    );
}

const ValdiationRuleLabelFactory = (formState: CustomFormState, type: keyof ValidationType): React.ReactNode =>
    (Object.keys(formState) as CustomFormStateKeys)
        .map(fieldName => {
            return (
                <React.Fragment key={fieldName + '_rule-block'}>
                    {
                        type == 'optional' && formState[fieldName].validation?.[type]?.lockey &&
                        <ValdiationRuleLabel
                            key={type + '_rule-label'}
                            fieldName={fieldName}
                            valid={isOptionalValid(formState[fieldName].validation)}
                            lockey={formState[fieldName].validation?.[type]?.lockey} />
                    }
                    {
                        formState[fieldName].validation?.[type]?.rules
                            ?.map(rule => rule.lockey &&
                                <ValdiationRuleLabel
                                    fieldName={fieldName}
                                    isOptional={type == 'optional'}
                                    key={fieldName + rule.lockey}
                                    valid={!!rule.valid}
                                    lockey={rule.lockey} />)
                    }
                </React.Fragment>
            );
        });

const ValdiationRuleLabel: React.FC<{
    valid: boolean;
    lockey: string | undefined;
    fieldName: string;
    isOptional?: boolean;
}> = ({ valid, lockey, isOptional, fieldName }) => {
    const { ts } = useLocalization();
    const match = useRouteMatch();

    return (
        <div className={cnRule.main({
            '--valid': valid,
            '--optional': isOptional
        })} aria-invalid={!valid}>
            <Icon icon={valid ? MdDone : MdClose} size="24" />
            <a tabIndex={valid ? -1 : 0} href={`${match.url}#${fieldName}`} className="gov-note">{ts(lockey)}</a>
        </div>
    );
}

export default CustomForm;