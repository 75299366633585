import Tabs from "@components/Tabs";
import React, { FC } from "react"
import { urlConstants } from "@constants/urlConstants";
import ProfilyZadavateluPlatne from "../profilyZadavatelu/ProfilyZadavateluPlatne";
import ProfilyZadavateluZneplatnene from "../profilyZadavatelu/ProfilyZadavateluZneplatnene";

const ProfilyZadavateluPages: FC = () => {
    return (
        <Tabs
            noMargin
            tabs={
                [
                    {
                        lockey: 'NEN-602383',
                        path: urlConstants.profilyZadavateluPlatne.path,
                        component: ProfilyZadavateluPlatne
                    },
                    {
                        lockey: 'NEN-602382',
                        path: urlConstants.profilyZadavateluZneplatnene.path,
                        component: ProfilyZadavateluZneplatnene
                    },
                ]
            } />
    );
};

ProfilyZadavateluPages.displayName = 'ProfilyZadavateluPages';

export default React.memo(ProfilyZadavateluPages);
