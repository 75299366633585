import React, { FC, useCallback } from "react"
import { setupCn } from "@utils/BemUtils";
import { WhispererItem } from "@components/WhispererInput";
import Multiselect from "@components/Multiselect";
import { LovBaseProps, useLovBase } from "./LovBase";

const cn = setupCn('lov-multiselect');

export type LovMultiselectProps = LovBaseProps<(string | number)[]>;

const LovMultiselect: FC<LovMultiselectProps> = (props) => {
    const { className } = props;
    const {
        selectedItems,
        setSelectedItems,
        handleChangeDone,
        controlProps
    } = useLovBase(props, {
        filterDefaultText: '',
        selectedItemsAreInWhiperer: false
    });

    const handleSelectedItemsChange = useCallback((value: WhispererItem[]) => {
        setSelectedItems(value);
        handleChangeDone(value.map(item => item.id));
    }, [handleChangeDone, setSelectedItems]);

    return (
        <Multiselect
            {...controlProps}
            className={cn.with(className).main()}
            selectedItems={selectedItems}
            onSelectedItemsChanged={handleSelectedItemsChange} />
    );
};

LovMultiselect.displayName = 'LovMultiselect';
LovMultiselect.defaultProps = {
    maxItems: 10
}

export default React.memo(LovMultiselect);
