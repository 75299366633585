import { columnWidths, ListFrame, ListFrameColumns } from "@components/ListFrame";
import FilterManager from "@components/FilterManager";
import { urlConstants } from "@constants/urlConstants";
import { IInfoUverejneniModel, InfoUverejneniAttrMap } from "@models/IInfoUVerejneniModel";
import React, { FC, useRef } from "react"
import { useLocalization } from "@store/localizationStore";
import { usePageTitle } from "@utils/PageTitleHook";
import ContentBlock from "@components/ContentBlock";
import Paginator from "@components/Pagination";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";
import { useDescription } from "@utils/DescriptionHook";

export const ON_PAGE_ROWS = 50;

const columns: ListFrameColumns<IInfoUverejneniModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812594',
        width: columnWidths.zpCode
    },
    {
        attrName: 'text',
        locKey: 'NEN-812643',
        width: 200
    },
    {
        attrName: 'datumUverejneni',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812694'
    },
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812618'
    }
];

const paramNames = urlConstants.vestnik.paramNames;
const vestnikLoadKey = 'vestnik-seznam';

const VestnikNen: FC = () => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-744124'));
    useDescription(t('NEN-789439'));

    const listFrameRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <h2 className='u-sr-only'>{t('NEN-744021')}</h2>
            <ContentBlock>
                <FilterManager id='vestnik-nen-filter' paramNames={paramNames} />
                <ListFrame
                    listFrameKey={vestnikLoadKey}
                    containerRef={listFrameRef}
                    condition="Datum_uverejneni <> null and SK=null"
                    paramNames={paramNames}
                    skeletonCount={ON_PAGE_ROWS}
                    onPageCount={ON_PAGE_ROWS}
                    orderBy={useOrderByMemo<IInfoUverejneniModel>({ desc: true, column: "datumUverejneni" })}
                    fulltextAttributes={[{ attrName: 'fullTextFiltr', type: 'contains' }]}
                    attrMapping={InfoUverejneniAttrMap}
                    detailLinkFactory={detailLinks.getDetailInfoUverejneniLinkFactory()}
                    columns={columns} />
            </ContentBlock>
            <Paginator loadMoreButton collectionKey={vestnikLoadKey} scrollToRef={listFrameRef} />
        </>
    );
}

export default React.memo(VestnikNen);
