import React, { FC } from "react";
import { useAppSettings } from '@store/nodeContextStore';
import Alert from "@components/Alert";
import { setupCn } from "@utils/BemUtils";

const cn = setupCn("home__alerts");

export const CriticalInformation: FC = () => {
    const { getCriticalInformation } = useAppSettings();
    const items = getCriticalInformation();
    if (!items || !items.enabled || !items.texts) {
        return null;
    }

    return <div className={cn()}>
        {
            getCriticalInformation().texts.map((value) => (
                <Alert closeButton type="warning" key={value.header}>
                    <>
                        <strong>{value.header}</strong>
                        <div>{value.body}</div>
                    </>
                </Alert>
            ))
        }
    </div>
}

export default React.memo(CriticalInformation);