import { ReactNode, useMemo } from "react"
import { useCollectionManager, useListFrameBase } from "@store/collectionStore";
import Table, { TableProps, DetailLinkFactory } from "@components/Table";
import { ListFrameBaseProps } from "./Types";
import { ListFrameButton } from "@components/Table/Buttons/Types";
import { AttrMapping } from "@utils/AttrMapping";
import React from "react";
import { useLocalization } from "@store/localizationStore";

export type ListFrameProps<Mapping extends AttrMapping = AttrMapping> = ListFrameBaseProps<Mapping> &
    Required<Pick<TableProps<Mapping>, 'paramNames'>> & Pick<TableProps<Mapping>, 'onPageCount'>;

const ListFrame = <Mapping extends AttrMapping = AttrMapping>({
    condition,
    listFrameKey,
    orderBy,
    fulltextAttributes,
    skeletonCount,
    detailLinkFactory,
    extraButtons,
    onPageCount,
    columns,
    ...restProps
}: ListFrameProps<Mapping>) => {
    const { t } = useLocalization();
    const { attrMapping, paramNames } = restProps;
    if (onPageCount === undefined) {
        onPageCount = 10;
    }
    onPageCount ??= undefined;
    
    const { orderBy: newOrderBy, filterHiddenColumns, onSortClickCallback } = useListFrameBase({ paramNames, orderBy, columns, mapping: restProps.attrMapping });

    const {
        collectionInfo,
        fetchCount,
        condition: finalCondition,
        conditionParams: finalConditionParams,
        match
    } = useCollectionManager({
        attrMapping,
        condition,
        attributes: [
            ...columns.map(col => col.attrName.toString()),
            ...detailLinkFactory?.additionalAttriubtes ?? []
        ],
        key: listFrameKey,
        onPage: onPageCount,
        paramNames,
        fulltextAttributes,
        orderBy: newOrderBy
    });

    const newDetailLinkFactory = useMemo<DetailLinkFactory | undefined>(
        () => detailLinkFactory ? data => detailLinkFactory({ data, match, t }) : undefined,
        [detailLinkFactory, match, t]);

    const extraButtonsNodes = useMemo<ReactNode[] | undefined>(() => extraButtons?.map((ToolbarButton: ListFrameButton, i) => (
        <ToolbarButton
            key={i}
            condition={finalCondition}
            conditionParams={finalConditionParams as string[]}
            classname={attrMapping.objectName} />
    )), [finalCondition, finalConditionParams]);

    if (skeletonCount == null || collectionInfo?.isLoadMore) {
        skeletonCount = fetchCount;
    }

    return (
        <Table
            {...restProps}
            columns={filterHiddenColumns}
            collectionInfo={collectionInfo}
            skeletonCount={skeletonCount}
            detailLinkFactory={newDetailLinkFactory}
            extraButtons={extraButtonsNodes}
            onSortClick={onSortClickCallback}
            orderBy={newOrderBy}
        />
    );
};

export default React.memo(ListFrame) as typeof ListFrame;
