export const isScrollDown = (element: HTMLElement, acceptedPixels = 0) => {
    return element.scrollTop + element.clientHeight >= element.scrollHeight - acceptedPixels;
}

export const scrollToRef = (element: HTMLElement | null) => {
    const scrollContainer = element?.parentElement;
    if (!scrollContainer || !element) {
        return;
    }
    const itemHeight = element.offsetHeight;
    const itemTop = element.offsetTop;
    const itemBottom = itemTop + itemHeight;
    if (itemTop < scrollContainer.scrollTop) {
        scrollContainer.scrollTo(scrollContainer.scrollLeft, itemTop);
        return;
    }
    if (itemBottom > scrollContainer.scrollTop + scrollContainer.clientHeight) {
        scrollContainer.scrollTo(scrollContainer.scrollLeft, itemBottom - scrollContainer.clientHeight);
    }
}
