import { ServiceBase } from "./ServiceBase";

export type StatisticsData = {
    pocetZadavatelu: number;
    pocetDodavatelu: number;
    pocetZakazekCelkem: number;
    hodnotaZakazekCelkem: number;
}

export default class NENService extends ServiceBase {   
    public async getStatistics(): Promise<StatisticsData> {
        return (await this.requestJson<StatisticsData>({
            url: `/api/nen/getStatistics`,
            method: 'GET'
        })).value;
    }
}
