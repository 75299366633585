import React, { FC, PropsWithChildren } from "react"
import { TestResult } from "@components/CompatibilityTest/CompatibilityTests";
import ResultBadge from "@components/CompatibilityTest/Components/ResultBadge/ResultBadge";
import { getSignatureStateTooltip, hasSignatureWarning, isEncrypted, isSignatureEmpty } from "crypto/SignatureUtils";
import { useLocalization } from "@store/localizationStore";
import { ISignatureInfo } from "crypto/interface/ISignatureInfo";

export type CryptoDetailSummaryBadgeProps = PropsWithChildren<{
    signatureInfo: ISignatureInfo | undefined;
}>;

const CryptoDetailSummaryBadge: FC<CryptoDetailSummaryBadgeProps> = ({
    signatureInfo,
}) => {
    const localization = useLocalization();

    if (!signatureInfo) {
        return null;
    }

    const signatureState = getSignatureStateTooltip(signatureInfo, localization);

    const headerClass = hasSignatureWarning(signatureInfo) || isEncrypted({ signatureInfo }) ? TestResult.partially :
        !isSignatureEmpty(signatureInfo) ? TestResult.ok : null;

    return (
        <ResultBadge
            noSpace
            result={headerClass ?? TestResult.notEvaluated}>
            {signatureState}
        </ResultBadge>
    );
};

CryptoDetailSummaryBadge.displayName = 'CryptoDetailSummaryBadge';

export default React.memo(CryptoDetailSummaryBadge);
