type FormatNumberParams = {
    decimal?: string;
    decimals?: number;
    separator?: string;
    value: number;
}

export const formatNumber = ({
    value,
    decimals,
    decimal = ',',
    separator = '\xa0'
}: FormatNumberParams): string => {
    let [int, dec] = value.toString().split(".");
    int = int.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    if (decimals != null && dec != null && dec.length > decimals) {
        dec = Math.round(Number(dec.slice(0, decimals + 1)) / 10).toString();
    }
    return [int, dec].filter(part => part != null).join(decimal);
}

type GetExponentionalPartsParams = {
    value: number;
    fractionDigits?: number;
    cutType?: 'floor' | 'round';
};

export const getExponentionalParts = ({ value, fractionDigits = 2, cutType = 'round' }: GetExponentionalPartsParams) => {
    if (typeof value !== 'number') {
        return {
            mantissa: 0,
            exponent: 0 
        }
    }
    if (cutType == 'floor') {
        fractionDigits++;
    }
    const [mantissa, exponent] = value.toExponential(fractionDigits).split('e');
    return {
        mantissa: Number(mantissa.slice(0, cutType == 'floor' ? -1 : 0)),
        exponent: Number(exponent)
    };
}

export const randomInteger = (from: number, to: number) => {
    return Math.floor(Math.random() * (to - from + 1)) + from;
}
