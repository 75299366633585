import { FC } from "react"
import { useAppSettings } from "@store/nodeContextStore";
import LargeWidget from "@components/Widgets/LargeWidget";
import { useLocalization } from "@store/localizationStore";
import { useDescription } from "@utils/DescriptionHook";

const Informace: FC = () => {
    const { t } = useLocalization();
    const { getAllWidgetUrl } = useAppSettings();
    useDescription(t('NEN-789450'));

    return <LargeWidget src={getAllWidgetUrl('provozniInformace')} />
};

Informace.displayName = 'Informace';

export default Informace;
