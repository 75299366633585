import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import './FilterHeader.scss';

const cn = setupCn('filter-header');

export type FilterHeaderProps = PropsWithChildren<{
    className?: Classes;
    withSpace?: boolean;
}>;

const FilterHeader: FC<FilterHeaderProps> = ({
    className,
    withSpace,
    children
}) => {
    return (
        <h3 className={cn.with(className).main({
            '--with-space': withSpace
        })}>
            {children}
        </h3>
    );
};

FilterHeader.displayName = 'FilterHeader';

export default React.memo(FilterHeader);
