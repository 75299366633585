import React from "react";
import { useRouteMatch } from "react-router";
import { DataLabelTyped } from "@components/DataLabel";
import { DetailFrame, useDetailFrameContext } from "@components/DetailFrame";
import GroupBox from "@components/GroupBox";
import { urlConstants } from "@constants/urlConstants";
import { DetailUverejneniAttrMap, IDetailUverejneniModel } from "@models/IDetailUverejneniModel";
import { IInfoUverejneniModel, InfoUverejneniAttrMap } from "@models/IInfoUVerejneniModel";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { UrlParamObject } from "@utils/QueryHook";
import { downloadButtonOnly } from "@components/Table/Buttons/DownloadAllToolbarButton";
import { ListFrameColumns, DependentListFrameTyped, columnWidths } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";
import { useDetailDescription } from "@utils/DescriptionHook";

const DataLabel = DataLabelTyped<IInfoUverejneniModel>();
const DependentListFrame = DependentListFrameTyped<IInfoUverejneniModel>();

const detailInfoUver = 'detail-info';

const DetailInfoUver: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const id = (match?.params[urlConstants.detailInfoUver.detailIdParam] || '');

    return (
        <DetailFrame
            condition='ID=@0'
            conditionParams={[id]}
            attrMapping={InfoUverejneniAttrMap}
            loadKey={detailInfoUver}
        >
            <DetailInfo />
        </DetailFrame>
    );
}

const DetailInfo: React.FC = () => {
    const { getAttr } = useDetailFrameContext<IInfoUverejneniModel>();
    const match = useRouteMatch<UrlParamObject>();

    useDetailDescription<IInfoUverejneniModel>([
        {
            lockey: 'NEN-812628',
            attrName: 'zakazka',
        },
        {
            lockey: 'NEN-812643',
            attrName: 'text',
        },
        {
            lockey: 'NEN-812641',
            attrName: 'zadavatelNazev',
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812688">
                <DataLabel lockey="NEN-812641" attrName='zadavatelNazev' detailLink={detailLinks.getSubjektDetailUrl(getAttr('zadavatelID')?.value, match, true)} />
                <DataLabel lockey="NEN-812628" attrName='zakazka' detailLink={detailLinks.getZpDetailUrl(getAttr('kod')?.value, match, true)} />
            </GroupBox>
            <GroupBox lockey="NEN-812672" showIf="Detail = 'INFO'">
                <DataLabel lockey="NEN-812645" attrName='datumUverejneni' />
                <DataLabel lockey="NEN-812643" attrName='text' />
                <DataLabel lockey="NEN-812722" attrName='datumOduver' showIf="Datum_oduverejneni<>null" />
                <DataLabel lockey="NEN-812721" attrName='oduverejnil' showIf="Oduverejnil<>null" />
            </GroupBox>
            <GroupBox lockey="NEN-812672" showIf="Detail <> 'INFO'">
                <DataLabel lockey="NEN-812645" attrName='datumUverejneni' />
                <DataLabel lockey="NEN-812643" attrName='text' />
                <DataLabel lockey="NEN-812722" attrName='datumOduver' showIf="Datum_oduverejneni<>null" />
                <DataLabel lockey="NEN-812721" attrName='oduverejnil' showIf="Oduverejnil<>null" />
            </GroupBox>
            <GroupBox lockey="NEN-747346" showIf="Detail = 'INFO'">
                <DataLabel lockey="NEN-812838" attrName='dnsNazev' />
                <DataLabel lockey="NEN-812667" attrName='dnsPocetDod' showIf="Pocet_dodavatelu<>null" />
                <DataLabel lockey="NEN-812627" attrName='dnsDny' showIf="Delka_trvani_dny <> null" />
                <DataLabel lockey="NEN-812840" attrName='dnsMesic' showIf="Delka_trvani_mesice<>null" />
                <DataLabel lockey="NEN-812841" attrName='dnsRok' showIf="Delka_trvani_roky<>null" />
                <DataLabel lockey="NEN-812624" attrName='terminUkonceni' showIf="Termin_ukonceni<>null" />
                <DataLabel lockey="NEN-812839" attrName='dnsPredmet' showIf="Uverejneni <> 'DNSAKTUAL'" fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-812608" fillContent showIf="Detail = 'SON'">
                <ListFrameVysledekSON />
            </GroupBox>
            <GroupBox lockey="NEN-807738" fillContent showIf="Uverejneni in ['PZ'] and Uver_pis_zprava=true and exists(Detail_uverejneni, Info_uver=&ID and Info_uver<>null and Osoba_stret=null and Subdod=null and Pod=null)">
                <ListFrameDetailPZ />
            </GroupBox>
            <GroupBox lockey="NEN-825137" fillContent showIf="Detail in ['SON'] and exists(Detail_uverejneni, Info_uver=&ID and Info_uver<>null and Osoba_stret=null and Subdod=null and Pod=null)">
                <ListFrameDetailOduvodSoN />
            </GroupBox>
            <GroupBox lockey="NEN-807292" fillContent showIf="((Uverejneni in ['PZ'] and Uver_pis_zprava=true) or Detail='SON') and exists(Detail_uverejneni, Info_uver=&ID and Info_uver<>null and Osoba_stret<>null)">
                <ListFrameOsoby />
            </GroupBox>
            <GroupBox lockey="NEN-812734" fillContent showIf="(Uverejneni in ['SUB'] or (Uverejneni in ['PZ'] and Uver_pis_zprava=true)) and exists(Detail_uverejneni, Info_uver=&ID and Info_uver<>null and Subdod<>null)">
                <ListFrameSubdod />
            </GroupBox>
            <GroupBox lockey="NEN-747322" showIf="Detail = 'ZRUS'">
                <DataLabel lockey="NEN-812647" attrName='datumZrus' />
                <DataLabel lockey="NEN-812611" attrName='ustanoveni' showIf="Duvod_zruseni<>null" />
                <DataLabel lockey="NEN-812612" attrName='oduvod' showIf="Duvod_zruseni<>null" fillGrid rawText />
                <DataLabel lockey="NEN-812614" attrName='oduvodZrus' showIf="Oduvod_zruseni <>null" fillGrid rawText />
                <DataLabel lockey="NEN-812613" attrName='blizsiOduvod' showIf="Blizsi_oduvodneni<>null" fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-812745" fillContent showIf="ZP.Druh_ZR.Kod in['ZP27','ZP28'] and Uverejneni in ['UCH']">
                <ListFrameUcastPT />
            </GroupBox>
            <GroupBox lockey="NEN-747640" fillContent showIf="Detail = 'VVZ'">
                <ListFrameVvz />
            </GroupBox>
            <GroupBox lockey="NEN-812673" fillContent showIf="Detail = 'PLN'">
                <ListFramePlneni />
            </GroupBox>
            <GroupBox lockey="NEN-812875" fillContent showIf="Uverejneni = 'ZMENASML'">
                <ListFrameDodatek />
            </GroupBox>
            <GroupBox lockey="NEN-812745" fillContent showIf="not(ZP.Druh_ZR.Kod in['ZP27','ZP28']) and Uverejneni in ['UCH']">
                <ListFrameUcast />
            </GroupBox>
            <GroupBox lockey="NEN-812608" fillContent showIf="Detail = 'VYS'">
                <ListFrameVysledek />
            </GroupBox>
            <GroupBox lockey="NEN-812772" fillContent showIf="Uverejneni = 'DODINFO' and ZP.Druh_ZR.Kod in['ZP25','ZP26']">
                <ListFrameZpravaVysvetleniSON />
            </GroupBox>
            <GroupBox lockey="NEN-812733" fillContent showIf="Uverejneni = 'DODINFO' and not(ZP.Druh_ZR.Kod in['ZP25','ZP26'])">
                <ListFrameZpravaVysvetleni />
            </GroupBox>
            <GroupBox lockey="NEN-807151" fillContent showIf="Uverejneni = 'UDAJNAB'">
                <ListFrameZpravaUdajePod />
            </GroupBox>
            <GroupBox lockey="NEN-812832" fillContent showIf="not(Detail in ['PLN','VVZ','UCH','SUB','VYS','SON']) and exists(Uverejneny_dokum, Info_uver=&ID and Info_uver<>null)">
                <ListFrameDokum />
            </GroupBox>
            <GroupBox lockey="NEN-812832" fillContent showIf="Uverejneni='VYS' and exists(Uverejneny_dokum, Info_uver=&ID and Info_uver<>null)">
                <ListFrameDokumVys />
            </GroupBox>
        </>
    );
}

const udajePodModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'textVysvetleni',
        locKey: 'NEN-807155'
    }
];

const ListFrameZpravaUdajePod: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-uver-vysvetleni'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detvysv', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={udajePodModelColumns} />
    );
}

const vysvetleniModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'textVysvetleni',
        locKey: 'NEN-812733'
    }
];

const ListFrameZpravaVysvetleni: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-uver-vysvetleni'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detvysv', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={vysvetleniModelColumns} />
    );
}
const vysvetleniSONModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'textVysvetleni',
        locKey: 'NEN-812772'
    }
];

const ListFrameZpravaVysvetleniSON: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-vysvetleni-uver'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detvysvSON', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={vysvetleniSONModelColumns} />
    );
}

const SubdodModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'ico',
        locKey: 'NEN-812665',
        width: columnWidths.ico
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    }
];

const ListFrameSubdod: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-subdod'}
            condition="Info_uver=@0 and Subdod <>null"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detsub', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={SubdodModelColumns} />
    );
}
const DetailPZModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'oduvodVyberuDod',
        locKey: 'NEN-821548'
    },
    {
        attrName: 'oduvodKomunProstredky',
        locKey: 'NEN-812886',
    },
    {
        attrName: 'oduvodNerozdeleni',
        locKey: 'NEN-812888',
    },
    {
        attrName: 'oduvodStanoveniPozadav',
        locKey: 'NEN-812889',
    }
];

const ListFrameDetailPZ: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-pis-zprava'}
            condition="Info_uver=@0 and Osoba_stret=null and Subdod=null"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detpz', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={DetailPZModelColumns} />
    );
}

const DetailOduvodSoNModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'oduvodKomunProstredky',
        locKey: 'NEN-812886',
    }
];

const ListFrameDetailOduvodSoN: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-oduvod-son'}
            condition="Info_uver=@0 and Osoba_stret=null and Subdod=null and Pod=null"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detoduv', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={DetailOduvodSoNModelColumns} />
    );
}

const OsobyModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'nazev',
        locKey: 'NEN-812890'
    },
    {
        attrName: 'prijataOpatreni',
        locKey: 'NEN-812883',
    }
];

const ListFrameOsoby: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-osoby'}
            condition="Info_uver=@0 and Osoba_stret<>null"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detosoba', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={OsobyModelColumns} />
    );
}
const VVZModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'datumPotvrz',
        locKey: 'NEN-812709',
        width: columnWidths.dateTime
    },
    {
        attrName: 'formKod',
        locKey: 'NEN-812712',
        width: 110
    },
    {
        attrName: 'formNazev',
        locKey: 'NEN-812713',
        width: 200
    },
    {
        attrName: 'datumOdeslani',
        locKey: 'NEN-781270',
        width: columnWidths.dateTime
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-781265',
        width: columnWidths.dateTime
    },
    {
        attrName: 'formTyp',
        locKey: 'NEN-812711',
        width: 110
    }
];

const ListFrameVvz: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-vvz'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detvvz', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            columns={VVZModelColumns} />
    );
}

const UcastModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    },
    {
        attrName: 'nabCenaS',
        locKey: 'NEN-812850'
    },
    {
        attrName: 'nabCenaBez',
        locKey: 'NEN-812851'
    },
    {
        attrName: 'menaUverKod',
        locKey: 'NEN-812607'
    },
    {
        attrName: 'uzavrenaSmlouva',
        locKey: 'NEN-824859'
    }
];

const ListFrameUcast: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-ucast'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detuca', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={UcastModelColumns} />
    );
}

const UcastPTModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'ico',
        locKey: 'NEN-812665'
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    }

];

const ListFrameUcastPT: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-ucast-pt'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detucaPT', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={UcastPTModelColumns} />
    );
}

const VysledekModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'cisloSmlouva',
        locKey: 'NEN-812606'
    },
    {
        attrName: 'datumUzavreni',
        locKey: 'NEN-812601',
        width: columnWidths.dateTime

    },
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'smluvniCenaS',
        locKey: 'NEN-812609'
    },
    {
        attrName: 'smluvniCenaBez',
        locKey: 'NEN-812602'
    },
    {
        attrName: 'smluvniCenaSAkt',
        locKey: 'NEN-812872'
    },
    {
        attrName: 'smluvniCenaBezAkt',
        locKey: 'NEN-812873'
    },
    {
        attrName: 'menaUverKod',
        locKey: 'NEN-812607'
    }
];

const ListFrameVysledek: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-vysledek'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detvys', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={VysledekModelColumns} />
    );
}

const VysledekSONModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'datumVyber',
        locKey: 'NEN-812663',
        width: columnWidths.dateTime
    },
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'soutezCenaBez',
        locKey: 'NEN-812664'
    },
    {
        attrName: 'menaUverKod',
        locKey: 'NEN-812607'
    }
];

const ListFrameVysledekSON: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-detail-vysledek-SON'}
            condition="Info_uver=@0 and Pod<>null"
            conditionParams={'id'}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detvysvSON', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={VysledekSONModelColumns} />
    );
}

const dodatekModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'cislo',
        locKey: 'NEN-812668',
        width: 100

    },
    {
        attrName: 'datumPodpisu',
        locKey: 'NEN-812669',
        width: columnWidths.date
    },
    {
        attrName: 'emailAdresa',
        locKey: 'NEN-812874'
    }
];

const ListFrameDodatek: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-dodatek'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detdod', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            columns={dodatekModelColumns} />
    );
}

const plneniModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'cisloSmlouva',
        locKey: 'NEN-812606',
        width: 120
    },
    {
        attrName: 'plneniRok',
        locKey: 'NEN-812674',
        width: 100

    },
    {
        attrName: 'plneniCenaS',
        locKey: 'NEN-812676'
    },
    {
        attrName: 'plneniCenaBez',
        locKey: 'NEN-812675'
    }
];

const ListFramePlneni: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-plneni'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: 'plneniRok' })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detpln', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            columns={plneniModelColumns} />
    );
}

const dokumModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'datumOduverejneni',
        locKey: 'NEN-812722',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];

const ListFrameDokum: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-dokum'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detdok', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            columns={dokumModelColumns}
            extraButtons={downloadButtonOnly} />
    );
}

const dokumVysledekModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'cisloSmlouva',
        locKey: 'NEN-812880',
        width: 120
    },
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'datumOduverejneni',
        locKey: 'NEN-812722',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];

const ListFrameDokumVys: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailInfoUver + '-dokum-vys'}
            condition="Info_uver=@0"
            conditionParams={'id'}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detdokvys', routeParamName: urlConstants.detailInfoUver.urlRouteParam })}
            columns={dokumVysledekModelColumns}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            extraButtons={downloadButtonOnly} />
    );
}

export default DetailInfoUver;