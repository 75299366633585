import ContentBlock from "@components/ContentBlock";
import UniversalLink from "@components/UniversalLink/UniversalLink";
import { useConstants } from "@constants/urlConstants";
import { useCurrentLanguage, useLocalization } from "@store/localizationStore";
import { useAppSettings } from "@store/nodeContextStore";
import { useSwitchByLanguage } from "@utils/LanguageUtils";
import { FC } from "react";

export const TextyKRegistracim: FC = ({ children }) => {
    const { ts } = useLocalization();
    const { appSettings } = useAppSettings();
    const { currentLanguage, switchByLanguage } = useSwitchByLanguage();

    const additionalLinks = switchByLanguage(appSettings.registration.additionalLinks);

    return (
        <ContentBlock>
            <p>
                {ts('NEN-785789')}
                <UniversalLink to={additionalLinks.documentsForDownload} isExternal>{ts('NEN-821204')}</UniversalLink>
                {ts('NEN-821208')}
            </p>
            {currentLanguage == 'CZ' &&
                <p>
                    <UniversalLink to={additionalLinks.faqGetDigitalSignature} isExternal>{ts('NEN-785811')}</UniversalLink>
                    {ts('NEN-785812')}
                </p>
            }
            <p>
                {ts('NEN-785797')}
                <UniversalLink to={additionalLinks.operatingRules} isExternal>{ts('NEN-785798')}</UniversalLink>
                {ts('NEN-790332')}
            </p>
            {children}
        </ContentBlock>
    )
}

export const TextyKRegistracimZadavatel: FC = () => {
    const { ts } = useLocalization();
    const { getUrl } = useConstants();
    const currentLanguage = useCurrentLanguage();

    return (
        <TextyKRegistracim>
            <p>
                {ts('NEN-808272')}
                <UniversalLink to={getUrl('registrZadavatelu')} isExternal>{ts('NEN-808274')}</UniversalLink>
                {ts('NEN-808297')}
            </p>
            {currentLanguage == 'EN' &&
                <p>
                    {ts('NEN-815404')}
                </p>
            }
        </TextyKRegistracim>
    );
}

export const TextyKRegistracimDodavatel: FC = () => {
    const { ts } = useLocalization();
    const { getUrl } = useConstants();

    return (
        <TextyKRegistracim>
            <p>
                {ts('NEN-808272')}
                <UniversalLink to={getUrl('registrDodavatelu')} isExternal>{ts('NEN-808273')}</UniversalLink>
                {ts('NEN-808297')}
            </p>
        </TextyKRegistracim>
    );
}
