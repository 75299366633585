import { CancellationToken, CancellationTokenSource, CancelError } from "./CancellationTokenSource";

/**
 * Pomocné metody pro práci s promisy
 * @includeToDoc
 */
export class PromiseUtils {

    /*
     * Řídí vypisování ladících informací
     */
    public static debugInfo: boolean = false;

    /**
     * Funkce vytvoří "while" cyklus, ve kterém spouští předanou akci dokud je splněný predikát a umožní čekat na souhrnný výsledek
     * @param predicate Predikátová funkce určující, zda se má pokračovat v cyklu
     * @param action Funkce, která se bude cyklicky vykonávat
     * @returns Souhrnný promise se všemi dílčími částmi
     */
    public static while(predicate: () => boolean | Promise<boolean>, action: () => void | Promise<void>): Promise<void> {
        function loop(): Promise<void> | undefined {
            if (!predicate())
                return undefined;
            return Promise.resolve(action()).then(loop);
        }

        return Promise.resolve().then(loop);
    }

    /**
     * Funkce vytvoří "for" cyklus, ve kterém sériově spustí předanou akci a umožní čekat na souhrnný výsledek 
     * @param startIndexValue Počáteční hodnota indexu
     * @param lessThan Hodnota před jejímž dosažením se cyklus ukončí
     * @returns Souhrnný promise se všemi dílčími částmi
     */
    public static for(startIndexValue: number, indexLessThan: number, action: (index: number) => void | Promise<void>): Promise<void> {
        function loop(i: number): Promise<void> | undefined {
            if (i < indexLessThan) {
                return Promise.resolve(action(i)).then(loop.bind(null, ++i) as () => Promise<void> | undefined);
            }
            return undefined;
        }

        return Promise.resolve(startIndexValue).then(loop);
    }

    /**
     * Vytvoří odložený promise, který je pak možné ručně dokončit nebo zrušit
     */
    public static defer<R>(): Deferred<R> {
        var result: any = {};
        result.promise = new Promise((resolve: any, reject: any) => {
            result.resolve = resolve;
            result.reject = reject;
        });
        return result;
    }

    /**
     * Vytvoří odložený promise, na kterém jde kontrolovat jeho stav
     */
    public static deferStateful<R>(): DeferredStateful<R> {
        const result = PromiseUtils.defer<R>() as any;
        result.isSettled = false;
        result.isResolved = false;
        result.isRejected = false;

        result.promise = result.promise.then((res: R) => {
            result.promiseValue = res;
            result.isSettled = true;
            result.isResolved = true;
            return res;
        }, (e: Error) => {
            result.isSettled = true;
            result.isRejected = true;
            throw e;
        });
        return result;
    }

    /**
     * Polyfill pro async/await
     * @ignore
     */
    public static awaiter(thisArg: any, body: () => Iterator<any>, P: PromiseConstructor = Promise): Promise<any> {
        let bodyIterator: Iterator<any>;
        return new P(function(resolve, reject) {
            function fulfilled(value: any) {
                try {
                    step(bodyIterator.next(value));
                } catch (e) {
                    reject(e);
                }
            }

            function rejected(value: any) {
                try {
                    step(bodyIterator.throw!(value));
                } catch (e) {
                    reject(e);
                }
            }

            function step(result: IteratorResult<any>) { result.done ? resolve(result.value) : new P(function(resolve) { resolve(result.value); }).then(fulfilled, rejected); }

            bodyIterator = body.apply(thisArg);
            step(bodyIterator.next());
        });
    };

    /**
     * Vrátí promise, který se dokončí za stanovený čas
     * @param timeout Doba v ms
     * @param cancellationToken Token, kterým lze promise stornovat
     */
    public static delay(timeout: number, cancellationToken: CancellationToken = CancellationToken.none): Promise<void> {
        if (PromiseUtils.debugInfo && timeout > 0)
            console.info(`Waiting: ${timeout}ms`); //` vetšinou bychom se měli vyhýbat použití delaye

        return new Promise<void>((resolve, reject) => {
            cancellationToken.throwIfCancellationRequested();
            let registration: { unregister(): void; } | undefined;

            const hid = self.setTimeout(() => {
                if (registration)
                    registration.unregister();
                resolve();
            }, timeout);

            registration = cancellationToken.register(() => {
                clearTimeout(hid);
                reject(new CancelError(undefined, cancellationToken.id));
            });
        });
    }

    /**
     * Zruší předchozí promise, pokud existuje, a odloží provádění předané funkce o zadaný čas
     * @param cts Pokud je předaný source předchozího promisu, tak se ukončí 
     * @param delay Doba v ms, po které se spoustí provádění předané funkce
     * @param func Funkce, která se spustí po prodlevě
     */
    public static cancelPrevAndDelayCall<TResult>(cts: CancellationTokenSource, delay: number, func: (cancellationToken?: CancellationToken) => TResult | PromiseLike<TResult>): { promise: Promise<TResult>, cts: CancellationTokenSource } {
        if (cts)
            cts.cancel();

        cts = new CancellationTokenSource();
        return { promise: PromiseUtils.delay(delay, cts.token).then(() => func(cts.token)), cts: cts };
    }

    /**
     * Handler pro zachytávání výjimek CancelError. Tyto výjimky jsou zahozeny, ostatní jsou propagovány dále.
     * @param e Zpracovávaná chyba
     */
    public static cancellationCatch(e: Error): void {
        if (e instanceof CancelError) {
            //logger.log("cancellationCatch: ", e.message);
            return;
        }
        throw e;
    }

    /**
     * Handler pohltí všechny výjimky
     * @param e Zpracovávaná chyba
     */
    public static catchAll(e: Error): void {
        console.error("CatchAll: ", e);
    }
}

/**
 * Odložený promise, který je možné ručně dokončit nebo zrušit
 * @includeToDoc
 */
export interface Deferred<R> {
    /** Čekající promise */
    readonly promise: Promise<R>;
    /** Metoda umožňující dokončení promisu */
    readonly resolve: (value?: R | Promise<R>) => void;
    /** Metoda umožňující zrušení promisu */
    readonly reject: (error?: any) => void;
}

/**
 * Odložený promise, na kterém lze navíc sledovat jeho stav
 * @includeToDoc
 */
export interface DeferredStateful<R> extends Deferred<R> {
    /** Zda je už ukončený (ať už pozitivně nebo negativně) */
    readonly isSettled: boolean;
    /** Zda je ukončený a doběhl v pořádku */
    readonly isResolved: boolean;
    /** Zda je ukončený a byl zrušen nebo skončil s chybou */
    readonly isRejected: boolean;
    /** Pokud doběhl v pořádku, tak tady je hodnota */
    readonly promiseValue: R;
}