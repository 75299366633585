import { Stringable } from "@store/localizationStore"
import { AttrMapping, AttrMappingDescription, AttrType } from "./AttrMapping"
import { UrlParamObject } from "./QueryHook"
import { rtrimText } from "./StringUtils"

export type FulltextAttrType = 'like' | 'contains';

export type FulltextAttrObject = {
    attrName: string;
    type: FulltextAttrType;
}

export type FulltextAttr = string | FulltextAttrObject;

const toFullTextObject = (attrName: FulltextAttr): FulltextAttrObject => {
    if (typeof attrName == 'string') {
        return { attrName, type: 'like' };
    }
    return attrName;
}

export type FilterConditionParams = {
    attrMapping: AttrMapping
    urlParams?: UrlParamObject;
    fulltextParam?: string;
    fulltextAttributes?: FulltextAttr[];
}

export type CrateConditionPartParams = {
    attrInfo: AttrMappingDescription;
    value: unknown;
    conditionParams: unknown[];
}

export type CrateConditionResult = {
    condition: string;
    conditionParams: unknown[];
}

export type CreateConditionFactory = (params: CrateConditionPartParams) => CrateConditionResult | null;

export const joinConditionsByAnd = (...conditionParts: (string | null | undefined)[]) => {
    conditionParts = conditionParts.filter(part => part);
    if (conditionParts.length == 0) {
        return undefined;
    }
    return `(${conditionParts.filter(part => part).join(') AND (')})`;
}

export const joinConditionsByOr = (...conditionParts: (string | null | undefined | boolean)[]) => {
    conditionParts = conditionParts.filter(part => part);
    if (conditionParts.length == 0) {
        return undefined;
    }
    return `(${conditionParts.filter(part => part).join(') OR (')})`;
};

const escapeStringQuery = (query: string) => {
    return rtrimText(query || '', '\\')
        .replace(/%/g, '\\%')
        .replace(/([^\\]|^)\*/g, '$1%')
        .replace(/\\\*/g, '*');
}

export const toLinguisticLike = (serverName: string, value: string, conditionParams: any[]) => {
    const escapedValue = escapeStringQuery(value);
    conditionParams.push(`%${escapedValue}%`);
    return `:Linguistic.LIKE(${serverName}, @${conditionParams.length - 1})`;
};

export const toSplittedLinguisticLike = (serverName: string, value: string, conditionParams: any[]) => {
    if (value == null || value == '') {
        return null;
    }
    const condition = joinConditionsByOr(...(value as string)
        .split('|')
        .map(strValue => toLinguisticLike(serverName, strValue?.trim(), conditionParams))
    );
    return condition ? `(${condition})` : null;
}

export const toLinguisticContains = (serverName: string, value: string, conditionParams: any[]) => {
    const escapedValue = escapeStringQuery(value);
    conditionParams.push(`${escapedValue}`);
    return `:DBNEN.FiltrovatZPNaLW(${serverName}, @${conditionParams.length})`;
};

export const toSplittedLinguisticContains = (serverName: string, value: string, conditionParams: any[]) => {
    if (value == null || value == '') {
        return null;
    }
    const condition = joinConditionsByOr(...(value as string)
        .split('|')
        .map(strValue => toLinguisticContains(serverName, strValue?.trim(), conditionParams))
    );
    return condition ? `(${condition})` : null;
}

export const createIn = (serverAttrName: string, values: string | string[] | null | undefined) => {
    if (!values) {
        return null;
    }
    const valuesArray = Array.isArray(values) ? values : values.split(',');
    const valuesIn = valuesArray.join(',');
    if (!valuesIn) {
        return null;
    }
    return `${serverAttrName} in [${valuesIn}]`;
}

export const createSubtreeCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    if (value == null || value == '') {
        return null;
    }
    const inCondition = createIn(attrInfo.serverName, value as string);
    const parentInCondition = createIn('Nadrizeny', value as string);
    return {
        condition: `${inCondition} OR Exists(DStrom, ID=&${attrInfo.serverName} AND ${parentInCondition})`,
        conditionParams
    };
}

export const createBasicCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    conditionParams.push(value);
    return {
        condition: `${attrInfo.serverName}=@${conditionParams.length - 1}`,
        conditionParams
    };
}

const trueValues = ['ano', 'true', 'a', false] as unknown[];
const falseValues = ['ne', 'false', 'n', true] as unknown[];
const createBoolCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    const values = (value as Stringable)?.toString().toLowerCase().split(',');
    const trueValue = values.find(val => trueValues.includes(val)) != null;
    const falseValue = values.find(val => falseValues.includes(val)) != null;
    if (value == null || trueValue && falseValue) {
        return null;
    }
    return {
        condition: `${attrInfo.serverName}${trueValue ? '=' : '<>'}true`,
        conditionParams
    };
};

const createStringCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    if (!value) {
        return null;
    }
    const condition = toSplittedLinguisticLike(attrInfo.serverName, value as string, conditionParams);
    return condition ? {
        condition: `(${condition})`,
        conditionParams
    } : null;
};

const createEnumCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    if (!value) {
        return null;
    }
    const valuesArray = Array.isArray(value) ? value : (value as string).split(',');
    const values = valuesArray.map(val => `!${attrInfo.typeParam}.${val}`);
    const condition = createIn(attrInfo.serverName, values);
    return condition ? {
        condition,
        conditionParams
    } : null;
};

const createIdCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    if (!value) {
        return null;
    }
    const condition = createIn(attrInfo.serverName, value as string);
    return condition ? {
        condition,
        conditionParams
    } : null;
};

const createDateCondition: CreateConditionFactory = ({ attrInfo, value, conditionParams }) => {
    if (!value) {
        return null;
    }
    const stringValue = (value as Stringable).toString();
    if (stringValue.includes(',')) {
        const [from, to] = stringValue.split(',');
        const condition = joinConditionsByAnd(
            from && `${attrInfo.serverName} >= '${from}'`,
            to && `${attrInfo.serverName} <= '${to}'`
        );
        return condition ? {
            condition,
            conditionParams: conditionParams
        } : null;
    }
    return {
        condition: `${attrInfo.serverName}='${stringValue}'`,
        conditionParams: conditionParams
    };
};

const createNumberCondition: CreateConditionFactory = ({ attrInfo, value }) => {
    const stringValue = (value as Stringable)?.toString();
    if (stringValue == null || stringValue == '') {
        return null;
    }
    if (stringValue.includes(',')) {
        const [from, to] = stringValue.split(',');
        const condition = joinConditionsByAnd(
            from && `${attrInfo.serverName} >= ${from}`,
            to && `${attrInfo.serverName} <= ${to}`
        );
        return condition ? {
            condition,
            conditionParams: []
        } : null;
    }
    return {
        condition: `${attrInfo.serverName}=${stringValue}`,
        conditionParams: []
    };
}

const conditionFactory = new Map<AttrType, CreateConditionFactory>();
conditionFactory.set('bool', createBoolCondition);
conditionFactory.set('string', createStringCondition);
conditionFactory.set('text', createStringCondition);
conditionFactory.set('enum', createEnumCondition);
conditionFactory.set('date', createDateCondition);
conditionFactory.set('dateTime', createDateCondition);
conditionFactory.set('number', createNumberCondition);
conditionFactory.set('money', createNumberCondition);
conditionFactory.set('id', createIdCondition);

export const createConditionPart: CreateConditionFactory = (params) => {
    const conditionCreator = params.attrInfo.filterCondition
        ?? conditionFactory.get(params.attrInfo.type)
        ?? createBasicCondition;
    return conditionCreator(params);
}

export const createFilterCondition = ({
    attrMapping,
    urlParams,
    fulltextParam,
    fulltextAttributes
}: FilterConditionParams): CrateConditionResult => {
    if (!urlParams || !attrMapping) {
        return {
            condition: '',
            conditionParams: []
        };
    }
    const filterAttrs = [...Object.keys(urlParams)]
        .map(urlParam => attrMapping.getByClientName(urlParam))
        .filter(attr => attr) as AttrMappingDescription[];
    const conditions: (string | undefined | null)[] = [];
    const conditionParams: unknown[] = [];
    filterAttrs.forEach(attrInfo => {
        const part = createConditionPart({
            attrInfo,
            value: urlParams[attrInfo.clientName],
            conditionParams
        });
        if (part) {
            conditions.push(part.condition);
        }
    });

    const fulltextValue = fulltextParam ? urlParams[fulltextParam] : null;
    if (fulltextValue && fulltextAttributes) {
        const fulltextConditionParts = fulltextAttributes.map(toFullTextObject)
            .map(({ attrName, type }) => {
                if (type == 'contains') {
                    return toSplittedLinguisticContains(attrMapping.toServerName(attrName), fulltextValue, conditionParams);
                }
                return toSplittedLinguisticLike(attrMapping.toServerName(attrName), fulltextValue, conditionParams);
            });
        conditions.push(joinConditionsByOr(...fulltextConditionParts));
    }

    const joinedConditions = joinConditionsByAnd(...conditions);

    return {
        condition: joinedConditions ? joinedConditions : '',
        conditionParams
    };
}