import React, { FC, KeyboardEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { generatePath, Redirect, StaticContext, useRouteMatch } from "react-router";
import { NavLink, Route, RouteComponentProps, Switch } from "react-router-dom";
import { useLocalization } from "@store/localizationStore";
import { setupCn } from "@utils/BemUtils";
import './Tabs.scss';
import { useDetailFrameContext } from "../DetailFrame";

const cn = setupCn('gov-tabs');

export type TabType = {
    path: string;
    lockey: string;
    showIf?: string | boolean;
    render?: (props: RouteComponentProps<any, StaticContext, any>) => React.ReactNode;
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export type TabWithInfo = TabType & {
    generatedPath: string;
    isSelected: boolean;
}

export type TabsProps = {
    tabs: TabType[];
    fallbackTo?: string
    id?: string;
    headerRight?: ReactNode;
    ariaLabel?: string;
    noMargin?: boolean;
    displayAsFlex?: boolean;
};

const getTabId = (componentId: string, tabIndex: number) => `${componentId ?? 'pages'}-tab${tabIndex + 1}`;

const Tabs: FC<TabsProps> = ({ tabs, ariaLabel, fallbackTo, headerRight, id = 'pages', noMargin, displayAsFlex = false }) => {
    const { ts } = useLocalization();
    const match = useRouteMatch();
    const linksHolderRef = useRef<HTMLDivElement | null>(null);

    const { evaluateCondition } = useDetailFrameContext();

    const [focusIndex, setFocusIndex] = useState<number | null>(null);
    const [focusId, setFocusId] = useState<string | null>(`${id}-tab1`);

    const isTriggerIndex = useCallback((index: number) => {
        return (index >= 0 && index <= (tabs.length - 1));
    }, [tabs]);

    const keys = useMemo(() => [13, 32, 37, 39], [])


    const handleKeyDown = useCallback<KeyboardEventHandler>(e => {
        const { keyCode } = e;
        if (keys?.includes(keyCode)) {
            e.preventDefault();
            if (!isTriggerIndex(focusIndex ?? 0)) {
                return;
            }

            if (keyCode === 39) {
                setFocusIndex(index => {
                    index ??= 0;
                    return (index + 1) >= tabs.length ? 0 : (index + 1)
                });
            } else if (keyCode === 37) {
                setFocusIndex(index => {
                    index ??= 0;
                    return (index - 1) < 0 ? (tabs.length - 1) : (index - 1)
                });
            } else if (keyCode === 13 || keyCode === 32) {
                if (focusIndex != null && linksHolderRef.current) {
                    ([...linksHolderRef.current.children][focusIndex] as HTMLElement)?.click();
                }
            }
        }
    }, [tabs, isTriggerIndex, focusIndex, keys]);

    const tabsWithInfo = tabs.map<TabWithInfo>(tab => {
        const generatedPath = decodeURI(generatePath(tab.path, match?.params));
        return {
            ...tab,
            generatedPath,
            isSelected: match.url == generatedPath
        };
    });

    const selectedTabIndex = tabsWithInfo.findIndex(tab => tab.isSelected);

    const handleTabFocus = useCallback((id: string) => {
        if (linksHolderRef.current) {
            setFocusId(id);
            setFocusIndex([...linksHolderRef.current.children].findIndex(el => el.id == id));
        }
    }, []);

    useEffect(() => {
        if (focusIndex != null && linksHolderRef.current) {
            ([...linksHolderRef.current.children][focusIndex] as HTMLElement)?.focus();
        }
    }, [focusIndex]);

    const evalCond = (cond: string | boolean | undefined) =>
        cond === undefined || cond === true || (typeof cond === 'string' && evaluateCondition(cond))

    return (
        <div className={cn.main({
            '--display-flex': displayAsFlex
        })}>
            <div className={cn.subCn('__links-holder', {
                '--no-margin': noMargin
            })} ref={linksHolderRef} onKeyDown={handleKeyDown} role='tablist' aria-label={ariaLabel}>
                {
                    tabsWithInfo.map((tab, index) => {
                        const tabId = getTabId(id, index);
                        return evalCond(tab.showIf) === false ? null : <NavLink
                            className={cn.with('gov-button')('__link')}
                            id={tabId}
                            role='tab'
                            aria-selected={tab.isSelected}
                            tabIndex={focusId == tabId ? 0 : -1}
                            activeClassName='is-active'
                            onFocus={() => handleTabFocus(tabId)}
                            key={`${tab.path}-${tab.showIf}`}
                            exact to={{ pathname: tab.generatedPath }}>
                            {ts(tab.lockey)}
                        </NavLink>
                    })
                }
                {headerRight}
            </div>
            <div className={cn('__content-holder')}>
                <div className={cn.with('is-active')('__content')}
                    role='tabpanel'
                    aria-labelledby={selectedTabIndex == null ? undefined : getTabId(id, selectedTabIndex)}>
                    <Switch>
                        {
                            tabs.map((tab) => tab.showIf && evalCond(tab.showIf) === false ? null : <Route
                                key={`${tab.path}-${tab.showIf}`}
                                exact path={tab.path}
                                component={tab.component}
                                render={tab.render} />)
                        }
                        <Route path='*'>
                            <Redirect to={fallbackTo ? fallbackTo : tabsWithInfo[0]?.generatedPath} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Tabs);
