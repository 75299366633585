import React, { FC } from "react";
import { Classes, setupCn } from "@utils/BemUtils";
import { IconType } from "react-icons";
import './Icon.scss';

const cn = setupCn('general-icon');
const cnBasic = setupCn('gov-icon');
const cnComplex = setupCn('gov-complex-icon');

export type IconSize = "16" | "20" | "24" | "32" | "tile";

export type IconProps = {
    className?: Classes;
    iconName?: string;
    isComplex?: boolean;
    iconRef?: React.Ref<HTMLSpanElement>;
    icon?: IconType;
    size?: IconSize;
    isPrimary?: boolean;
};

const Icon: FC<IconProps> = ({
    className, iconName, isComplex, iconRef, icon: IconElement, size, isPrimary
}) => {
    if (IconElement) {
        const sizeModifier = size && `--size-${size}`;
        const primaryColorModifier = isPrimary ? `--primaryColor` : null;
        return (
            <IconElement aria-hidden='true' className={cn.with(className).main(sizeModifier, primaryColorModifier)} />
        );
    }
    const govCn = isComplex ? cnComplex : cnBasic;
    return (
        <span aria-hidden='true' ref={iconRef} className={govCn.with(className).main(`--${iconName}`)} />
    );
}

export default React.memo(Icon);