import { AttrMapping, IData } from "@utils/AttrMapping";

export type ISystemKvalifikaceModel = IData & {
    obdobiOd: Date;
    obdobiDo: Date;
    skKod: string;
    skNazev: string;
    skPredmet: string;
    skStav: string;
    skUcel: string
    skZadavatelNazev: string;
    skZadavatelID: number
}

export const SystemKvalifikaceAttrMap = new AttrMapping<ISystemKvalifikaceModel>('System_kvalifikace', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'obdobiOd', serverName: 'Obdobi_OD', type: 'date' },
    { clientName: 'obdobiDo', serverName: 'Obdobi_DO', type: 'date' },
    { clientName: 'skKod', serverName: 'Kod_SK', type: 'string' },
    { clientName: 'skNazev', serverName: 'Nazev', type: 'string' },
    { clientName: 'skPredmet', serverName: 'Predmet', type: 'string' },
    { clientName: 'skStav', serverName: 'Stav', type: 'enum', typeParam: 'Stav_SK' },
    { clientName: 'skUcel', serverName: 'Ucel', type: 'string' },
    { clientName: 'skZadavatelNazev', serverName: 'Zadavatel.Nazev', type: 'string' },
    { clientName: 'skZadavatelID', serverName: 'Zadavatel', type: 'id' },
]);