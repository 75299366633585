import React, { createContext, useContext, useLayoutEffect } from "react"
import { BreadcrumbItem, useBreadcrumb } from "@store/breadcrumbStore";
import { useTaskManager } from "@store/nodeContextStore";

const breadRenderKey = 'breadcrumb-render';

export const BreadcrumbContext = createContext<BreadcrumbItem[]>([]);

export const WithBreadcrumb: React.FC<{
    prependHome?: boolean
}> = ({ prependHome, children}) => {
    const breadCrumbItems = useContext(BreadcrumbContext);
    const [appendItems, setItems] = useBreadcrumb();
    const taskManager = useTaskManager();
    const key = breadRenderKey + '-' + breadCrumbItems.map(i => i.title + i.href).join('-');

    const addItems = () => Promise.resolve(
        prependHome ? appendItems(breadCrumbItems) : setItems(breadCrumbItems)
    );

    useLayoutEffect(() => {
        if (taskManager.isTaskCompleted(key)) {
            taskManager.removeTaskFromCompleted(key);
        } else {
            addItems();
        }
    }, [breadCrumbItems]);

    taskManager.add(key, addItems);

    return (
        <>
            {children}
        </>
    );
}

WithBreadcrumb.defaultProps = {
    prependHome: true
}
