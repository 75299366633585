import React, { createContext, FC, FocusEventHandler, KeyboardEventHandler, MouseEventHandler, PropsWithChildren, UIEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import './WhispererInput.scss';
import FilterInput, { FilterInputProps } from "@components/FilterInput/FilterInput";
import { FormControlBaseProps } from "@components/FormControl";
import { useToggleState } from "@utils/StateUtils";
import { isScrollDown, scrollToRef } from "@utils/ScrollUtils";
import { stopPropagation } from "@utils/EventUtils";

const cn = setupCn('whisperer-input');

export type WhispererInputClasses = {
    whispererClass?: Classes;
    itemClass?: Classes;
    componentClass?: Classes;
}

export type WhispererItem<T = any> = {
    id: string | number;
    text: string;
    data?: T;
}

export type WhispererItemHandler = (item: WhispererItem) => void;
export type WhispererItemSelectedHandler = (item: WhispererItem | null) => void;

export type WhispererInputProps =
    FormControlBaseProps &
    Pick<FilterInputProps, 'onBlur' | 'onFocus' | 'value' | 'onChange' | 'name'> &
    PropsWithChildren<{
        id: string;
        className?: Classes;
        onWhispererScrollDown?: () => void;
        items?: WhispererItem[];
        onItemSelected?: WhispererItemSelectedHandler;
        classes: WhispererInputClasses;
        onWhispererShow?: (element: HTMLElement | null) => void;
    }>;

type WhispererInputContainerContextType = {
    whispererId?: string;
    isCollapsed: boolean;
    containerClass: Classes;
    onClick?: MouseEventHandler;
}

const WhispererInputContainerContext = createContext<WhispererInputContainerContextType>({ whispererId: undefined, isCollapsed: true, containerClass: '' });

const WhispererInputContainer: FC = ({ children }) => {
    const { whispererId, isCollapsed, containerClass, onClick } = useContext(WhispererInputContainerContext);
    return (
        <div
            className={cn.raw(containerClass)}
            onClick={onClick}
            role='combobox'
            aria-haspopup='listbox'
            aria-owns={whispererId}
            aria-expanded={!isCollapsed}>
            {children}
        </div>
    );
}

const WhispererInput: FC<WhispererInputProps> = ({
    id,
    className,
    onWhispererShow,
    onWhispererScrollDown,
    onItemSelected,
    items,
    children,
    onBlur,
    onFocus,
    classes,
    ...rest
}) => {
    const whispererId = `${id}__whisperer`;
    const inputRef = useRef<HTMLInputElement>(null);
    const [isCollapsed, _, setIsCollapsed] = useToggleState(true);
    const [preselectedIndex, setPreselectedIndex] = useState(0);
    const whispererRef = useRef<HTMLUListElement>(null);

    const handleItemSelected: WhispererItemSelectedHandler = useCallback(item => {
        setIsCollapsed(true);
        onItemSelected?.(item);
    }, [onItemSelected]);

    const checkWhispererScroll = useCallback((container: HTMLElement | null) => {
        if (!isCollapsed && container && isScrollDown(container, 40)) {
            onWhispererScrollDown?.();
        }
    }, [isCollapsed, onWhispererScrollDown]);

    const handleWhispererScroll = useCallback<UIEventHandler>(event => {
        checkWhispererScroll(event.target as HTMLElement);
    }, [checkWhispererScroll]);

    const itemsMemo = useMemo(() => items?.map((item, index) => {
        const isSelected = preselectedIndex == index;
        return (
            <li
                key={item.id}
                ref={isSelected ? scrollToRef : undefined}
                title={item.text}
                className={cn.with(classes?.itemClass, {
                    'selected': isSelected
                })('__item')}
                aria-selected={isSelected}
                onMouseMove={() => setPreselectedIndex(index)}
                onMouseDown={() => handleItemSelected(item)}
                role='option'>
                {item.text}
            </li>
        );
    }), [items, handleItemSelected, preselectedIndex]);

    const whisperer = useMemo(() => (
        <ul ref={whispererRef} id={whispererId} role="listbox" className={cn.with(classes?.whispererClass).subCn('__items', {
            '--is-collapsed': isCollapsed
        })} onScroll={handleWhispererScroll} onClick={stopPropagation}>
            {itemsMemo}
        </ul>
    ), [whispererId, isCollapsed, handleWhispererScroll, itemsMemo, whispererRef]);

    const handleContainerClick = useCallback<MouseEventHandler>(() => {
        setIsCollapsed(false);
    }, []);

    const containerContext = useMemo<WhispererInputContainerContextType>(() => ({
        isCollapsed,
        whispererId,
        containerClass: cn.with(classes?.componentClass)('__container'),
        onClick: handleContainerClick
    }), [isCollapsed, whispererId, classes?.componentClass, handleContainerClick]);

    const handeFormControlKeyUp: KeyboardEventHandler = useCallback(event => {
        event.stopPropagation();
        const itemsLength = items?.length ?? 0;
        switch (event.keyCode) {
            case 38:
                event.preventDefault();
                setPreselectedIndex(id => id <= 0 ? (itemsLength - 1) : (id - 1));
                if (isCollapsed) {
                    setIsCollapsed(false);
                }
                break;
            case 40:
                event.preventDefault();
                setPreselectedIndex(id => (id + 1) % itemsLength);
                if (isCollapsed) {
                    setIsCollapsed(false);
                }
                break;
            case 13:
                if (!isCollapsed) {
                    onItemSelected?.(items?.[preselectedIndex] ?? null);
                    setIsCollapsed(true);
                }
                event.preventDefault();
                break;
            case 27:
                if (!isCollapsed) {
                    setIsCollapsed(true);
                }
                event.preventDefault();
                break;
        }
    }, [items, preselectedIndex, isCollapsed, onItemSelected]);

    const handleInputKeyDown: KeyboardEventHandler = useCallback(event => {
        switch (event.keyCode) {
            case 38:
            case 40:
                event.preventDefault();
                break;
        }
    }, []);

    const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(event => {
        setIsCollapsed(false);
        onFocus?.(event);
    }, [onFocus]);

    const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(event => {
        setIsCollapsed(true);
        onBlur?.(event);
    }, [onBlur]);

    useEffect(() => {
        setPreselectedIndex(0);
    }, [rest.value]);

    useEffect(() => {
        if (!isCollapsed) {
            onWhispererShow?.(whispererRef.current);   
            checkWhispererScroll(whispererRef.current);         
        }
    }, [isCollapsed, checkWhispererScroll]);

    return (
        <WhispererInputContainerContext.Provider value={containerContext}>
            <FilterInput
                {...rest}
                onFormControlKeyUp={handeFormControlKeyUp}
                className={cn.with(className).main()}
                id={id}
                inputRef={inputRef}
                isInline
                onKeyDown={handleInputKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                container={WhispererInputContainer}
                afterLabel={whisperer}
            />
        </WhispererInputContainerContext.Provider>
    );
};

WhispererInput.displayName = 'WhispererInput';

export default React.memo(WhispererInput);
