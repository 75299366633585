import React from "react"
import ContentBlock from "@components/ContentBlock";
import { useLocalization } from "@store/localizationStore";
import Button from "@components/Button/Button";
import { useUserCookiesSetings } from "@store/userCookieSettingsStore";
import { useScrollToTop } from "@utils/ReactUtils";
import FormatedText from "@components/FormatedText/FormatedText";
import UniversalLink from "@components/UniversalLink/UniversalLink";
import { setupCn } from "@utils/BemUtils";

import './OsobniUdajeACookies.scss';

const personalInfoCn = setupCn('personal-info');

const OsobniUdajeACookies: React.FC = () => {
    const { ts, t } = useLocalization();
    const { openModal } = useUserCookiesSetings();

    useScrollToTop();

    return (
        <>
            <ContentBlock lockey="NEN-791018">
                <table className={personalInfoCn.with("gov-table--tablet-block")()}>
                    <thead>
                        <tr>
                            <th colSpan={2}>{ts("NEN-791021")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ts("NEN-791022")}</td>
                            <td>
                                <FormatedText>{ts("NEN-791024")}</FormatedText>{" "}
                                <UniversalLink to="mailto:gdpr@mmr.cz" isExternal sameTarget>
                                    gdpr@mmr.cz
                                </UniversalLink>
                            </td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791028")}</td>
                            <td>
                                <p>{ts("NEN-791031")}</p>
                                <p>{ts("NEN-791032")}</p>
                                <p>{ts("NEN-791034")}</p>
                                {ts("NEN-791035")}
                                <ul>
                                    <li>{ts("NEN-791037")}</li>
                                    <li>{ts("NEN-791038")}</li>
                                    <li>{ts("NEN-791039")}</li>
                                    <li>{ts("NEN-791040")}</li>
                                    <li>{ts("NEN-791041")}</li>
                                    <li>{ts("NEN-791042")}</li>
                                    <li>{ts("NEN-791043")}</li>
                                    <li>{ts("NEN-791044")}</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791046")}</td>
                            <td>{ts("NEN-791048")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791049")}</td>
                            <td>
                                <ol>
                                    <li>{ts("NEN-791050")}</li>
                                    <li>{ts("NEN-791051")}</li>
                                    <li>{ts("NEN-791052")}</li>
                                    <li>{ts("NEN-791053")}</li>
                                    <li>{ts("NEN-791054")}</li>
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791055")}</td>
                            <td>
                                <p><FormatedText>{ts("NEN-791056")}</FormatedText></p>
                                {ts("NEN-791057")}
                                <ol>
                                    <li>{ts("NEN-791058")}</li>
                                    <li>{ts("NEN-791059")}</li>
                                </ol>
                                <h3>{ts("NEN-791060")}</h3>
                                {ts("NEN-791061")}{" "}<u>{ts("NEN-791062")}</u>.
                                <ul>
                                    <li>{ts("NEN-791063")}</li>
                                    <li>{ts("NEN-791064")}</li>
                                    <li>
                                        {ts("NEN-791067")}{" "}
                                        <UniversalLink to="https://nen.nipez.cz" isExternal>
                                            https://nen.nipez.cz
                                        </UniversalLink>
                                    </li>
                                    <li>{ts("NEN-791070")}</li>
                                    <li>{ts("NEN-791071")}</li>
                                    <li>{ts("NEN-791072")}</li>
                                </ul>
                                <h3>{ts("NEN-791073")}</h3>
                                {ts("NEN-791074")}
                                <ul>
                                    <li>{ts("NEN-791075")}</li>
                                    <li>{ts("NEN-791076")}</li>
                                    <li>{ts("NEN-791077")}</li>
                                    <li>{ts("NEN-791078")}</li>
                                    <li>{ts("NEN-791079")}</li>
                                    <li>{ts("NEN-791080")}</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791081")}</td>
                            <td>{ts("NEN-791082")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791083")}</td>
                            <td>{ts("NEN-791084")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791085")}</td>
                            <td>{ts("NEN-791086")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791087")}</td>
                            <td>{ts("NEN-791088")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791089")}</td>
                            <td>{ts("NEN-791090")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791091")}</td>
                            <td>{ts("NEN-791092")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791093")}</td>
                            <td>{ts("NEN-791092")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791094")}</td>
                            <td>{ts("NEN-791092")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791095")}</td>
                            <td>{ts("NEN-791096")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791097")}</td>
                            <td>
                                {ts("NEN-791098")}{" "}
                                <UniversalLink to="http://www.uoou.cz/" isExternal>
                                    {ts("NEN-791099")}
                                </UniversalLink>.
                            </td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791100")}</td>
                            <td>{ts("NEN-791101")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791102")}</td>
                            <td>{ts("NEN-791103")}</td>
                        </tr>
                        <tr>
                            <td>{ts("NEN-791104")}</td>
                            <td>{ts("NEN-791105")}</td>
                        </tr>
                    </tbody>
                </table>
            </ContentBlock>
            <ContentBlock lockey="NEN-791107">
                <p>{ts("NEN-791108")}</p>
                <h3>{ts("NEN-791109")}</h3>
                <p>{ts("NEN-791110")}</p>

                <table className="gov-table--tablet-block">
                    <thead>
                        <tr>
                            <th>{ts("NEN-791111")}</th>
                            <th>{ts("NEN-791112")}</th>
                            <th>{ts("NEN-791113")}</th>
                            <th>{ts("NEN-791114")}</th>
                            <th>{ts("NEN-791115")}</th>
                            <th>{ts("NEN-791116")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791117")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791118")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791121")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791127")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791118")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791128")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791129")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791130")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791118")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791131")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791132")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791118")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791131")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791133")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791118")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791134")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791135")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791118")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791675")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791673")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791118")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791136")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791137")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791138")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791139")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791140")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791141")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791142")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791143")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791138")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791144")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791140")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791141")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791142")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791145")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>
                            <td data-title={t("NEN-791113")}></td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791147")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791148")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791149")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791144")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791150")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791148")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791151")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791144")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-791120")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-791152")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791148")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791153")}</td>                       
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>                                         
                            <td data-title={t("NEN-791113")}>{ts("NEN-792929")}</td>                                         
                            <td data-title={t("NEN-791114")}>{ts("NEN-792932")}</td>                       
                            <td data-title={t("NEN-791115")}>{ts("NEN-792930")}</td>                                         
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>                                         
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791158")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-792932")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-792933")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791155")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-792932")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-792934")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-792935")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791119")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-792932")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-792936")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                        <tr>
                            <td data-title={t("NEN-791111")}>{ts("NEN-791157")}</td>
                            <td data-title={t("NEN-791112")}>{ts("NEN-791146")}</td>
                            <td data-title={t("NEN-791113")}>{ts("NEN-791144")}</td>
                            <td data-title={t("NEN-791114")}>{ts("NEN-792932")}</td>
                            <td data-title={t("NEN-791115")}>{ts("NEN-792937")}</td>
                            <td data-title={t("NEN-791116")}>{ts("NEN-791122")}</td>
                        </tr>
                    </tbody>
                </table>
                <p>{ts("NEN-791160")}</p>
                <p>
                    {ts("NEN-791163")} (
                    {
                        <UniversalLink to="https://docs.microsoft.com/cs-cz/azure/azure-monitor/app/app-insights-overview" isExternal>
                            {ts("NEN-791164")}
                        </UniversalLink>
                    }).
                </p>
                <p>{ts("NEN-791165")}</p>
            </ContentBlock>
            <ContentBlock lockey="NEN-770174">
                <p>
                    {ts("NEN-770175")}
                </p>
                <Button onClick={openModal}>
                    {ts("NEN-770173")}
                </Button>
            </ContentBlock>
        </>
    );
};

OsobniUdajeACookies.displayName = 'ZpracovaniOsobnichUdaju';

export default OsobniUdajeACookies;