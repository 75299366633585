import { AttrMapping, IData } from "@utils/AttrMapping";

export type IPolozkyDNSModel = IData & {
    nazevPolozky: string;
    mnozstvi: number;
    vlastniJednotka: number;
}

export const PolozkyDNSAttrMap = new AttrMapping<IPolozkyDNSModel>('Polozky_DNS', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'mnozstvi', serverName: 'Mnozstvi', type: 'number' },
    { clientName: 'nazevPolozky', serverName: 'Nazev_polozky', type: 'string' },
    { clientName: 'vlastniJednotka', serverName: 'Vlastni_jednotka', type: 'number' },
]);
