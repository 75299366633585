import { GetDataRowsAttribute } from "services/DataRowsService";
import { CreateConditionFactory } from "./FilterCondition";
import { truthy, uniqueArray } from "./ArrayHelper";

export type IData = {
    id: number;
}

export type IBasicData = IData & { [clientName: string]: unknown };

export type AttrType = 'id' | 'string' | 'date' | 'dateTime' | 'number' | 'bool' | 'enum' | 'file' | 'money' | 'text';

export type AttrName<T> = keyof T & string;
export type AttrNames<T> = AttrName<T>[];

type TypeParamsMap<T extends IData> = {
    file: FileConverterParam<T>;
    enum: EnumConverterParam;
}

export type AttrMappingDescription<T extends IBasicData = IBasicData, TAttr extends AttrType = AttrType> = {
    clientName: AttrName<T>,
    type: TAttr;
    serverName: string,
    requiredAttriubtes?: AttrNames<T>;
    defaultFormat?: AttrType,
    filterCondition?: CreateConditionFactory;
} & (
    TAttr extends keyof TypeParamsMap<T> ? {
        typeParam: TAttr extends keyof TypeParamsMap<T> ? TypeParamsMap<T>[TAttr] : never;
    } : {
        typeParam?: unknown;
    }
);

export type FileConverterParam<IModel> = {
    getFileName?: (data?: IModel, fileName?: string) => string | undefined;
    hasDigitalSignarue?: (data?: IModel) => boolean | undefined;
}

export type EnumConverterParam = string;

export class AttrMapping<T extends IData = any>{
    public readonly clientMap: Map<keyof T, AttrMappingDescription<T, AttrType>>;
    public readonly serverMap: Map<string, AttrMappingDescription<T, AttrType>>;
    public readonly objectName: string;

    constructor(objectName: string, entries: AttrMappingDescription<T, AttrType>[]) {
        this.objectName = objectName;
        this.clientMap = new Map(entries.map(entry => [entry.clientName, entry] as const));
        this.serverMap = new Map(entries.map(entry => [entry.serverName, entry] as const));
    }

    public toServerNames(...clientNames: AttrNames<T>): string[] {
        return clientNames.map(clientName => this.toServerName(clientName));
    }

    public toClientNames(...serverNames: string[]): AttrName<T>[] {
        return serverNames.map(serverName => this.toClientName(serverName));
    }

    public toServerName(clientName: AttrName<T>): string {
        return this.getByClientName(clientName)?.serverName as string;
    }

    public toClientName(serverName: string): AttrName<T> {
        return this.getByServerName(serverName)?.clientName as AttrName<T>;
    }

    public getDataRowAttributes(...clientNames: AttrNames<T>): GetDataRowsAttribute[] {
        const allClientNames = clientNames.map(clientName => {
            const attributeInfo = this.getByClientName(clientName);
            return [attributeInfo?.clientName, ...attributeInfo?.requiredAttriubtes ?? []];
        });
        return uniqueArray(...allClientNames).map(clientName => {
            const serverName = clientName && this.toServerName(clientName);
            return serverName ? { clientName, serverName } : undefined;
        }).filter(truthy);
    }

    public getByServerName(serverName: string): AttrMappingDescription<T, AttrType> | undefined {
        return this.serverMap.get(serverName);
    }

    public getByClientName(clientName: AttrName<T>): AttrMappingDescription<T, AttrType> | undefined {
        return this.clientMap.get(clientName);
    }
}