import { useLocalization } from 'store/localizationStore';
import { setupCn } from '@utils/BemUtils';
import { urlConstants, useConstants } from '@constants/urlConstants';
import Statistics from '@components/Statistics/Statistics';
import FormatedText from '@components/FormatedText/FormatedText';
import LoginCard from './LoginCard/LoginCard';
import GovLayout from '@components/GovLayout/GovLayout';
import ContentBlock from '@components/ContentBlock';
import Tabs from '@components/Tabs';
import ButtonLink from '@components/Button/ButtonLink';
import Container from '@components/Container';
import { usePageTitle } from '@utils/PageTitleHook';
import FlexBox from '@components/FlexBox/FlexBox';
import { useAppSettings } from '@store/nodeContextStore';
import { useScrollToTop } from '@utils/ReactUtils';
import NewsWidget from '@components/Widgets/News/NewsWidget';
import NewsSubscribe from '@components/Widgets/NewsSubscribe/NewsSubscribeWidget';
import { useDescription } from '@utils/DescriptionHook';
import './Home.scss';
import ministerstvo from '@images/logoMMR.svg';
import ministerstvo_en from '@images/logoMMR-EN.svg';
import nipez from '@images/logoNIPEZ.svg';
import nipez_en from '@images/logoNIPEZ-EN.svg';
import { CriticalInformation } from '@components/CriticalInformation';
import TrainingWidget from '@components/Widgets/Training/TrainingWidget';
import Manuals from '@components/Manuals/Manuals';
import { useSwitchByLanguage } from '@utils/LanguageUtils';

const cn = setupCn('home');

const Home = () => {
    const { t, ts } = useLocalization();
    const { getUrl } = useConstants();
    const { switchByLanguage } = useSwitchByLanguage();
    const { appSettings } = useAppSettings();
    usePageTitle('');
    useDescription(t('NEN-648904'));
    useScrollToTop();

    return (
        <div className={cn()}>
            <h1 className='u-sr-only'>{t('NEN-645300')}</h1>
            <CriticalInformation />
            <Container>
                <GovLayout
                    leftCol={<LoginCard />}
                    rightCol={
                        <ContentBlock height='100%' className={cn()}>
                            <Tabs
                                displayAsFlex
                                noMargin
                                tabs={[
                                    {
                                        lockey: 'NEN-767461',
                                        path: urlConstants.home.path,
                                        render: () => <TrainingWidget
                                            trainingsType="Nen"
                                            linkTo={"skoleniVse"} />
                                    },
                                    {
                                        lockey: 'NEN-793789',
                                        path: urlConstants.skoleniZzvz.path,
                                        render: () => <TrainingWidget
                                            trainingsType="Zzvz"
                                            linkTo={"skoleniZzvz"} />
                                    }, 
                                    //{
                                    //    lockey: 'NEN-767964',
                                    //    path: urlConstants.videa.url,
                                    //    render: () => <SmallWidget height='270px' src={getSmallWidgetUrl('videa')} allHref={urlConstants.videaVse.url} withPadding />
                                    //},
                                    {
                                        lockey: 'NEN-767963',
                                        path: urlConstants.prirucky.path,
                                        render: () => <Manuals />
                                    }
                                ]}
                            />
                        </ContentBlock>
                    }
                    ratio='1-2'
                    spreadRight={1}
                />
            </Container>

            <Container className="u-bg-color--white">
                <GovLayout
                    ratio='1-2'
                    spreadRight={3}
                    leftCol={
                        <div className={cn('__informace__left')}>
                            <h2 className="gov-title--alpha">{ts('NEN-767975')}</h2>
                            <ButtonLink
                                className="u-hide--tablet"
                                isOutlined
                                aria-label={t('NEN-786556')}
                                to={getUrl('provozniInformace')}>
                                {ts("NEN-645393")}
                            </ButtonLink>
                        </div>
                    }
                    rightCol={
                        <NewsWidget />
                    }
                />
                <ButtonLink
                    className="u-hide--from-tablet"
                    isOutlined
                    aria-label={t('NEN-786556')}
                    to={getUrl('provozniInformace')}>{ts("NEN-645393")}</ButtonLink>
            </Container>

            {
                !appSettings?.isTestEnvironment &&
                <Container>
                    <Statistics />
                </Container>
            }

            <Container className="u-bg-color--white-overlay">
                <GovLayout
                    leftCol={
                        <>
                            <h2>{ts('NEN-749126')}</h2>
                            <p>
                                <FormatedText boldPrimaryColor>{ts('NEN-749112')}</FormatedText>
                            </p>
                        </>
                    }
                    rightCol={
                        <NewsSubscribe />
                    }
                    ratio='1-2'
                    spreadRight={1}
                />
            </Container>

            <Container className="u-bg-color--white">
                <FlexBox className={cn('__logos')} justify="space-evenly">
                    <img src={switchByLanguage({ cz: ministerstvo, en: ministerstvo_en })} alt="ministerstvo pro mistni rozvoj" />
                    <img src={switchByLanguage({ cz: nipez, en: nipez_en })} alt="nipez" />
                </FlexBox>
            </Container>
        </div>
    )
};

export default Home;
