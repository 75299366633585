import { FC, ReactNode } from "react";
import { NonFalseable } from "./TypeUtils";

export const renderIf = <
    T extends unknown,
    TIf extends ((value: NonFalseable<T>) => ReactNode),
    TIfNode extends ReactNode,
    TElse extends (() => ReactNode) | undefined = undefined
>(
    shouldRender: T,
    nodeFactory: TIf | TIfNode,
    elseNodeFactory?: TElse
):
    (TIf extends (...args: any[]) => infer TIfRet ? TIfRet : TIfNode) |
    (TElse extends undefined ? null : (TElse extends () => infer TElseRet ? TElseRet extends undefined ? null : TElseRet : null)) => {
    if (!shouldRender) {
        return (elseNodeFactory?.() ?? null) as (TElse extends undefined ? null : (TElse extends () => infer TElseRet ? TElseRet extends undefined ? null : TElseRet : null));
    }
    if (typeof nodeFactory == 'function') {
        return nodeFactory(shouldRender as NonFalseable<T>);
    }
    return nodeFactory as (TIf extends (...args: any[]) => infer TIfRet ? TIfRet : TIfNode);
}

export const notRenderIf = (shouldntRender: unknown, nodeFactory: ReactNode | FC): ReactNode => {
    if (shouldntRender) {
        return null;
    }
    if (typeof nodeFactory == 'function') {
        const NodeFactory = nodeFactory;
        return <NodeFactory />;
    }
    return nodeFactory;
}
