import { ArrayValues } from "@utils/TypeUtils";

const colorClassPrefix = 'color--';

const colorsNames = [
    'blue',
    'grey-dark',
    'white',
    'blue-dark',
    'blue-focus',
    'blue-light',
    'blue-inactive-1',
    'blue-inactive-2',
    'blue-inactive-3',
    'blue-hover',
    'grey-mid',
    'grey-light',
    'grey-inactive',
    'grey-bg',
    'yellow',
    'yellow-dark',
    'error',
    'success',
    'grey-dark-rgb',
    'white-rgb',
] as const;

const colors: Record<string, string> = {};
colorsNames.forEach(color => {
    const className = `${colorClassPrefix}${color}`;
    colors[color] = className;
});

type ColorsClassesEnum = {
    [K in ArrayValues<typeof colorsNames>]: `${typeof colorClassPrefix}${K}`
};

export default colors as ColorsClassesEnum;
