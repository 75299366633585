import React, { FC, PropsWithChildren, ReactNode } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import Icon from "@components/Icon";
import { MdWorkspacePremium } from "react-icons/md";
import Button from "@components/Button/Button";
import DocumentSignatureModal from "@components/DocumentSignatureModal";
import { useToggleState } from "@utils/StateUtils";
import { renderIf } from "@utils/RenderUtils";
import { useLocalization } from "@store/localizationStore";

const cn = setupCn('document-signature-button');

export type SignatureButtonProps = PropsWithChildren<{
    className?: Classes;
    objectName: string;
    id: string;
    attrName: string;
    fileName?: ReactNode;
}>;

const DocumentSignatureButton: FC<SignatureButtonProps> = ({
    className,
    ...signatureModalParams
}) => {
    const { t } = useLocalization();
    const [isSignatureShown, toggleSignatureShown] = useToggleState();

    return (
        <>
            <Button
                className={cn.with(className).main()}
                title={t('NEN-808699')}
                hasIconOnly
                isFlat
                onClick={toggleSignatureShown}>
                <Icon
                    icon={MdWorkspacePremium}
                    size='16' />
            </Button>
            {renderIf(isSignatureShown,
                <DocumentSignatureModal
                    {...signatureModalParams}
                    onClose={toggleSignatureShown} />
            )}
        </>
    );
};

DocumentSignatureButton.displayName = 'DocumentSignatureButton';

export default React.memo(DocumentSignatureButton);
