import { AttrMapping, IData } from "@utils/AttrMapping";

export type IUverejnenyDokumModel = IData & {
    antivir: string;
    cisloSmlouva: string;
    datumUver: Date;
    datumOduverejneni: Date;
    datumVlozeni: Date;
    format: string;
    nazev: string;
    nazevPolozky: string;
    odkazLW: string;
    oduverejnil: string;
    podepsan: boolean;
    soubor: string;
    typDokument: string;
    uverejnil: string;
    velikost: number;
    podpisID: number;
    podpisDatumUver: Date;
}

export const UverejnenyDokumAttrMap = new AttrMapping<IUverejnenyDokumModel>('Uverejneny_dokum', [
    { clientName: 'antivir', serverName: 'AntivirStatus', type: 'string' },
    { clientName: 'cisloSmlouva', serverName: 'Evidencni_cislo_sml', type: 'string' },
    { clientName: 'datumUver', serverName: 'Info_uver.Datum_uverejneni', type: 'dateTime' },
    { clientName: 'datumOduverejneni', serverName: 'Datum_oduverejneni', type: 'dateTime' },
    { clientName: 'podpisDatumUver', serverName: 'Podpis.Info_uver.Datum_uverejneni', type: 'dateTime' },
    { clientName: 'datumVlozeni', serverName: 'Datum_vlozeni', type: 'dateTime' },
    { clientName: 'format', serverName: 'Format', type: 'string' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'nazev', serverName: 'Nazev', type: 'string' },
    { clientName: 'nazevPolozky', serverName: 'Polozka.Nazev_polozky', type: 'string' },
    { clientName: 'odkazLW', serverName: 'Odkaz_LW', type: 'string' },
    { clientName: 'oduverejnil', serverName: 'Oduverejnil', type: 'string' },
    { clientName: 'podepsan', serverName: 'Podepsan', type: 'bool' },
    { clientName: 'soubor', serverName: 'Soubor', type: 'file',
        requiredAttriubtes: ['nazev', 'podepsan'],
        typeParam: {
            getFileName: (data?, fileName?) => data?.nazev || fileName,
            hasDigitalSignarue: (data?) => data?.podepsan,
        },
    },
    { clientName: 'typDokument', serverName: 'typdokument.Nazev', type: 'string' },
    { clientName: 'uverejnil', serverName: 'Info_uver.Uverejnil', type: 'string' },
    { clientName: 'velikost', serverName: 'Velikost', type: 'number' },
    { clientName: 'podpisID', serverName: 'Podpis', type: 'id' },

]);
