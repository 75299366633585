import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import Button, { ButtonProps } from "@components/Button/Button";
import { useLocalization } from "@store/localizationStore";
import { useId } from "@utils/UseId";
import { FormControlContext } from "@components/FormControl";
import FilterInput from "@components/FilterInput/FilterInput";
import { FilterManagerChangeDoneEventHandler, FilterManagerChangeEventHandler } from "@components/FilterManager";

import './InputWithButton.scss';

const cn = setupCn('input-with-button');
const cnSearch = setupCn('gov-search');

export type InputWithButtonProps =
    Pick<ButtonProps, 'onMouseDown' | 'onClick'> & PropsWithChildren<
    {
        placeholderLocKey: string;
        buttonTextLocKey: string;
        buttonTitleLocKey: string;
        id: string;
        className?: Classes;
        isWide?: boolean;
        disableButton?: boolean | { disabledIfEmpty: boolean, disable: boolean };
        hasError?: boolean;
        inputType?: string;
        onInputChange?: FilterManagerChangeEventHandler;
        onInputChangeDone?: FilterManagerChangeDoneEventHandler;
        headerText?: string;
        shouldClearInput?: boolean;
    }>;

const InputWithButton: FC<InputWithButtonProps> = ({
    id,
    className,
    isWide,
    onMouseDown,
    onClick,
    placeholderLocKey,
    buttonTextLocKey,
    buttonTitleLocKey,
    disableButton,
    inputType,
    onInputChange,
    onInputChangeDone,
    hasError,
    headerText,
    shouldClearInput,
    children
}) => {
    const [inputValue, setInputValue] = useState('');
    const { ts, d, t } = useLocalization();

    useEffect(() => {
        if (shouldClearInput) {
            setInputValue('');
        }
    }, [shouldClearInput, setInputValue]);

    disableButton = typeof disableButton === 'object'
        ? disableButton.disable || (disableButton.disabledIfEmpty && !inputValue.length)
        : disableButton;

    const { generateId } = useId({ id: `inputwithbutton-${id}` });
    const buttontextId = generateId('button-text');
    const buttonContent = isWide ? ts(buttonTextLocKey, { isPrimaryTheme: true }) : (
        <span id={buttontextId} className="u-sr-only gov-button__label">
            {ts(buttonTextLocKey)}
        </span>
    );

    const handleChange = useCallback<FilterManagerChangeEventHandler>((name, newValue) => {
        setInputValue(newValue);
        onInputChange?.(name, newValue);
    }, [onInputChange]);

    const handleChangeDone = useCallback<FilterManagerChangeDoneEventHandler>((name, newValue, reason) => {
        if (disableButton) {
            return;
        }

        onInputChangeDone?.(name, newValue, reason);
    }, [onInputChangeDone, disableButton]);

    const handleButtonClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>((event) => {
        handleChangeDone(event.currentTarget.name, inputValue, 'buttonClick')
    }, [handleChangeDone, inputValue]);

    const inputElement = children ?? <FilterInput
        id={id}
        headerText={ts(headerText)}
        className={cn.main('__input', { '--error': hasError })}
        type={inputType}
        value={inputValue}
        onChange={handleChange}
        onChangeDone={handleChangeDone} />

    const buttonOnclick = onClick ?? handleButtonClick;
    return (
        <div className={cn.with(className, cnSearch()).main({
            '--wide': isWide
        })}>
            <div className={cnSearch('__inner')}>
                <FormControlContext.Provider value={{
                    labelClassName: 'u-sr-only',
                    defaultPlaceholder: t(placeholderLocKey),
                    isInputStandalone: true
                }}>
                    {inputElement}
                </FormControlContext.Provider>
                <Button
                    tabIndex={-1}
                    className={cnSearch('__submit')}
                    onMouseDown={onMouseDown}
                    onClick={buttonOnclick}
                    labelled-by={isWide ? undefined : buttontextId}
                    hasIconOnly={!isWide}
                    disabled={disableButton}
                    title={d(buttonTitleLocKey)}>
                    {buttonContent}
                </Button>
            </div>
        </div>
    );
};

InputWithButton.displayName = 'InputWithButton';

export default React.memo(InputWithButton);
