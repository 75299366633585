import React, { CSSProperties, FC } from "react";
import { useLocalization } from "@store/localizationStore";
import { AttrMapping, IBasicData, IData } from "@utils/AttrMapping";
import { setupCn } from "@utils/BemUtils";
import { renderIf } from "@utils/RenderUtils";
import { TableColumn } from "./Table";
import TableCell from './TableCell';
import TableDetailCell from "./TableDetailCell";
import responsibility from "@styles/responsibility";
import { DetailLink } from "@components/ListFrame";

const cn = setupCn('gov-table');
const cnCell = cn.setupSubCn('__cell');

export type DetailLinkFactory = (data: IBasicData) => DetailLink;

export type TableRowProps = {
    columns: TableColumn[];
    data: IData | null;
    attrMapping: AttrMapping;
    isSkeleton?: boolean;
    detailLinkFactory?: DetailLinkFactory;
}

const displayNoneStyles: CSSProperties = {
    display: 'none',
    visibility: 'hidden'
};

const TableRow: FC<TableRowProps> = ({
    attrMapping,
    columns,
    data,
    isSkeleton,
    detailLinkFactory
}) => {
    const detailLink = !detailLinkFactory ? null :
        data ? detailLinkFactory(data) : null;
    const { ts } = useLocalization();
    return (
        <tr className={cn('__row')}>
            {
                renderIf(detailLinkFactory,
                    <TableDetailCell
                        className={cnCell.with(responsibility['u-hide--tablet'])()}
                        isSkeleton={isSkeleton}
                        detailLink={detailLink}>
                        {ts('NEN-748837')}
                    </TableDetailCell>
                )
            }
            {
                columns.map((column, index) => <TableCell
                    className={cnCell.main({
                        '--second': index == 0,
                        '--last': index == columns.length - 1,
                        '--is-skeleton': isSkeleton,
                        '--wide': column.isWide
                    })}
                    key={index}
                    attrMapping={attrMapping}
                    isSkeleton={isSkeleton}
                    data={data}
                    column={column}
                />)
            }
            {
                renderIf(detailLinkFactory,
                    <TableDetailCell
                        style={displayNoneStyles}
                        className={cnCell.with(
                            responsibility['u-display-block'],
                            responsibility['u-hide--from-tablet']
                        )()}
                        withArrow
                        isSkeleton={isSkeleton}
                        detailLink={detailLink}>
                        {ts('NEN-748837')}
                    </TableDetailCell>
                )
            }
        </tr>
    )
};

export default React.memo(TableRow);
