import IconLabel from "@components/IconLabel/IconLabel";
import { CryptoNativeCheckResult } from "crypto/CryptoUtils";
import React, { FC, PropsWithChildren } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";
import CryptoComponentResult from "./CryptoComponentResult/CryptoComponentResult";

const cn = setupCn('crypto-test-result');

export type CryptoTestResultProps = PropsWithChildren<{
    className?: Classes;
    result?: CryptoNativeCheckResult | null;
}>;

const CryptoTestResult: FC<CryptoTestResultProps> = ({
    className,
    result
}) => {
    const { ts } = useLocalization();

    if (result == null) {
        return null;
    }

    const {
        extension,
        nativeApp,
        messageLocKey,
    } = result;

    return (
        <div className={cn.with(className)()}>
            <div>
                <CryptoComponentResult componentInfo={extension}>Crypto Web Extension</CryptoComponentResult>
            </div>
            <div>
                <CryptoComponentResult componentInfo={nativeApp}>Crypto Native App</CryptoComponentResult>
            </div>
            {
                messageLocKey ? <IconLabel type='warning'>{ts(messageLocKey)}</IconLabel> : null
            }
        </div>
    );
};

CryptoTestResult.displayName = 'CryptoTestResult';

export default React.memo(CryptoTestResult);
