import React, { FC, PropsWithChildren, ReactNode } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import BoolChecks from "@components/BoolChecks/BoolChecks";
import { useFilterManagerContext } from "../FilterManager";
import { useId } from "@utils/UseId";
import FilterHeader from "../FilterHeader";

const cn = setupCn('filter-manager-bool');

export type FilterManagerBoolProps<TAttr extends string = string> = PropsWithChildren<{
    id?: string;
    headerText?: ReactNode;
    className?: Classes;
    moreColumns?: boolean;
    name: TAttr;
}>;

export type FilterManagerBoolType<TAttr extends string = string> = FC<FilterManagerBoolProps<TAttr>>

const FilterManagerBool: FilterManagerBoolType = ({
    id,
    className,
    name,
    moreColumns,
    headerText
}) => {
    const { controlId } = useId({ id: `date-${id ?? name}` });

    const {
        filterValues,
        onFilterValueChange
    } = useFilterManagerContext(name);
    return (
        <div className={cn.with(className)()}>
            <FilterHeader withSpace>{headerText}</FilterHeader>
            <BoolChecks id={controlId} name={name} onChange={onFilterValueChange} value={name && filterValues[name]} oneColumn={!moreColumns} />
        </div>
    );
};

FilterManagerBool.displayName = 'FilterManagerBool';

export default React.memo(FilterManagerBool);
