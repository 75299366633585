import React, { FC, PropsWithChildren } from "react"
import { SkeletonTheme } from "react-loading-skeleton";

export type SkeletonPrimaryThemeProps = PropsWithChildren<{}>;

const SkeletonPrimaryTheme: FC<SkeletonPrimaryThemeProps> = ({
    children
}) => {
    return (
        <SkeletonTheme color='#4091c7' highlightColor='#69a9d3'>
            {children}
        </SkeletonTheme>
    );
};

export default React.memo(SkeletonPrimaryTheme);
