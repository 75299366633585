import classNames, { Argument } from "classnames";
import { combineArrays, DeepArray, flatArrayDeep, notEmptyItems, toArray, uniqueArray } from "./ArrayHelper";

export type ComponentNames = DeepArray<string>;
export type Classes = Argument | Argument[];

const combineSegments = (segments1: string[], segments2: string[]) =>
    combineArrays(segments1, segments2, (s1, s2) => `${s1}${s2}`);

const filterResult = <T extends unknown>(...arrays: T[][]): T[] => {
    return notEmptyItems(uniqueArray(...arrays))
}

export const setupCna = (...mainClasses: ComponentNames[]) => {
    const prefixesFlatten = uniqueArray(
        flatArrayDeep(mainClasses)
            .filter(name => name)
    );
    const cna = (...postfixes: Classes[]) => {
        if (postfixes.length == 0) {
            return prefixesFlatten;
        }
        const classesString = classNames(postfixes);
        if (!classesString) {
            return [];
        }
        return filterResult(combineSegments(prefixesFlatten, classesString.split(' ')));
    }
    cna.setupSubCna = (...infixes: string[]) => setupCna(...combineSegments(prefixesFlatten, infixes));
    cna.subCna = (infixes: string | string[], ...postfixes: Classes[]) => filterResult([...cna(infixes), ...cna.setupSubCna(...toArray(infixes))(postfixes)]);
    cna.main = (...postfixes: Classes[]) => filterResult([...cna(), ...cna(postfixes)]);
    cna.raw = (...className: Classes[]) => (classNames(...className) ?? '').split(' ');
    cna.with = (...className: Classes[]) => {
        const cnaWith = (...postfixes: Classes[]) => filterResult(cna(postfixes), classNames(className).split(' '));
        cnaWith.main = (...postfixes: Classes[]) => filterResult(cna.main(postfixes), classNames(className).split(' '));
        return cnaWith;
    };
    const cn = (...postfixes: Classes[]) => cna(...postfixes).join(' ');
    cn.setupSubCn = (...infixes: string[]) => cna.setupSubCna(...infixes).string;
    cn.subCn = (infixes: string | string[], ...postfixes: Classes[]) => cna.subCna(infixes, ...postfixes).join(' ');
    cn.main = (...postfixes: Classes[]) => cna.main(...postfixes).join(' ');
    cn.with = (...className: Classes[]) => {
        const cnWith = (...postfixes: Classes[]) => [cn(...postfixes), classNames(className)].filter(c => c).join(' ');
        cnWith.main = (...postfixes: Classes[]) => [cn.main(postfixes), classNames(className)].filter(c => c).join(' ');
        cnWith.subCn = (infixes: string | string[], ...postfixes: Classes[]) => [cn.subCn(infixes, ...postfixes), classNames(className)].filter(c => c).join(' ');
        return cnWith;
    };
    cn.raw = classNames;
    cna.string = cn;
    return cna;
}

export const setupCn = (...prefixes: ComponentNames[]) => {
    return setupCna(prefixes).string;
}

export type Cn = ReturnType<typeof setupCn>;
