import React, { FC, PropsWithChildren } from "react"
import InputWithButton, { InputWithButtonProps } from "@components/InputWithButton/InputWithButton";
import { ButtonProps } from "@components/Button/Button";

export type SearchProps = PropsWithChildren<
    Pick<ButtonProps, 'onMouseDown' | 'onClick'> &
    Pick<InputWithButtonProps, 'isWide' | 'className' | 'id'>>;

const Search: FC<SearchProps> = ({
    id,
    className,
    isWide,
    onMouseDown,
    onClick,
    children
}) => {
    return (
        <InputWithButton
            className={className}
            id={`search-${id}`}
            buttonTextLocKey="NEN-765498"
            buttonTitleLocKey="NEN-765498"
            placeholderLocKey="NEN-765498"
            onMouseDown={onMouseDown}
            onClick={onClick}
            isWide={isWide}>
            {children}
        </InputWithButton>
    )
};

Search.displayName = 'Search';

export default React.memo(Search);
