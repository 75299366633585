import { Classes, setupCn } from "@utils/BemUtils";
import React, { PropsWithChildren } from "react";
import { FC } from "react"
import PaginationArrow from "./PaginationArrow";
import PaginationPage from "./PaginationPage";
import PaginationSeparator from "./PaginationSeparator";

const cn = setupCn('gov-pagination');

export type PaginationProps = PropsWithChildren<{
    className?: Classes;
}>;

export type PaginationType = FC<PaginationProps> & {
    Page: typeof PaginationPage;
    Separator: typeof PaginationSeparator;
    Arrow: typeof PaginationArrow;
}

const Pagination: PaginationType = React.memo(({ className, children }: PaginationProps) => {
    return (
        <div className={cn.with(className)()}>
            <div className={cn.with(className)('__holder')}>
                {children}
            </div>
        </div>
    );
}) as unknown as PaginationType;

Pagination.Page = PaginationPage;
Pagination.Separator = PaginationSeparator;
Pagination.Arrow = PaginationArrow;

export default Pagination;
