import React, { FC, useCallback, useEffect, useState } from "react"
import { setupCn } from "@utils/BemUtils";
import { WhispererItem } from "@components/WhispererInput";
import SelectInput from "@components/SelectInput";
import { getExactLovValue, getLovText, lovToWhispererItem } from "@utils/LovUtils";
import { LovBaseProps, useLovBase } from "./LovBase";
import { useDispatch } from "react-redux";

const cn = setupCn('lov-input');

export type LovInputProps = LovBaseProps<string>;

const LovInput: FC<LovInputProps> = (props) => {
    const dispatch = useDispatch();
    const [validateCounter, setValidateCounter] = useState(0);
    const [isInvalid, setIsInvalid] = useState(false);

    const {
        className,
        attrMapping,
        hasError,
        condition,
        value,
    } = props;
    const {
        selectedItems,
        setSelectedItems,
        actualValue,
        showAttrsArray,
        dataRowsService,
        setFilterText,
        setActualValue,
        controlProps,
        handleChangeDone
    } = useLovBase(props, {
        filterDefaultText: value,
        selectedItemsAreInWhiperer: true
    });

    const isEmpty = actualValue == '';
    const selectedItem: WhispererItem | null = selectedItems[0]?.data;
    const setSelectedItem = useCallback((value: WhispererItem | null) => setSelectedItems(value ? [value] : []), [setSelectedItems]);

    useEffect(() => {
        if (selectedItem && actualValue != getLovText(selectedItem?.data, showAttrsArray)) {
            setSelectedItem(null);
        }
    }, [actualValue]);

    useEffect(() => {
        setIsInvalid(false);
    }, [isEmpty]);

    useEffect(() => {
        let cancel = false;
        const isEmpty = actualValue == null || actualValue == '';
        if (isEmpty) {
            handleChangeDone('');
        }
        const isInvalid = !isEmpty && selectedItem == null;
        if (!isInvalid) {
            setIsInvalid(false);
            return;
        }
        getExactLovValue({
            dataRowsService,
            dispatch,
            condition,
            actualValue,
            attrMapping,
            showAttrs: showAttrsArray,
            conditionParams: [Number.parseInt(actualValue)]
        }).then(exactValue => {
            if (exactValue == null || cancel) {
                setIsInvalid(true);
                handleChangeDone('');
                return;
            }
            setSelectedItem(lovToWhispererItem(exactValue, showAttrsArray));
            setActualValue(getLovText(exactValue, showAttrsArray));
            handleChangeDone(exactValue.id?.toString() ?? '');
            setIsInvalid(false);
        }).catch(() => setIsInvalid(true));
        return () => { cancel = true };
    }, [validateCounter]);

    const handleItemSelected = useCallback((item: WhispererItem | null) => {
        const itemText = getLovText(item?.data, showAttrsArray);
        setActualValue(itemText);
        setSelectedItem(item);
        setIsInvalid(false);
        setFilterText(itemText);
        handleChangeDone(item?.data?.id?.toString());
    }, [handleChangeDone, showAttrsArray]);

    const handleBlur = useCallback(() => {
        setValidateCounter(prev => prev + 1);
    }, [setValidateCounter]);

    return (
        <SelectInput
            {...controlProps}
            className={cn.with(className).main()}
            hasError={hasError || isInvalid}
            onItemSelected={handleItemSelected}
            onBlur={handleBlur} />
    );
};

LovInput.displayName = 'LovInput';
LovInput.defaultProps = {
    maxItems: 10
}

export default React.memo(LovInput);
