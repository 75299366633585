import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPasswordResetService } from "@store/nodeContextStore";
import CustomForm, { CustomFormState } from "@components/CustomForm/CustomForm";
import ContentBlock from "@components/ContentBlock";
import { useLocalization } from "@store/localizationStore";
import { getErrorStoreActions } from "@store/errorStore";
import { useQuery } from "@utils/QueryHook";
import { DetailFrame, useDetailFrameContext } from "@components/DetailFrame";
import { AkceptaceZmenyAttrMapping, IAkceptaceZmenyModel } from "@models/IAkceptaceZmenyModel";
import { DataLabelTyped } from "@components/DataLabel";
import GroupBox from "@components/GroupBox";

const DataLabel = DataLabelTyped<IAkceptaceZmenyModel>();

const ZmenaHesla: React.FC = () => {
    const { ts } = useLocalization();
    const guid = useQuery<{ guid: string }>()?.guid;
    const [requestState, setRequestState] = useState({ sucess: false, lockey: '' });

    if (!guid) {
        return (
            <ContentBlock>
                <h3>{ts('NEN-645302')}</h3>
            </ContentBlock>
        );
    }

    if (requestState.lockey) {
        return (
            <ContentBlock>
                <h3>{ts(requestState.lockey)}</h3>
            </ContentBlock>
        );
    }

    return (
        <DetailFrame
            condition='ID > 0 AND Kod=@0 AND Do > DB.GetDateTime()'
            conditionParams={[guid]}
            attrMapping={AkceptaceZmenyAttrMapping}
            loadKey={'zmena-hesla-detail'}>
            <GroupBox lockey="NEN-812507">
                <DataLabel lockey="NEN-645285" attrName='username' />
                <DataLabel lockey="NEN-645286" attrName='osJmeno' />
                <DataLabel lockey="NEN-645287" attrName='osPrijmeni' />
                <DataLabel lockey="NEN-537477" attrName='osEmail' />
                <DataLabel lockey="NEN-645281" attrName='patriDoNazev' />
                <Form setRequestState={setRequestState} guid={guid} />
            </GroupBox>
        </DetailFrame>
    );
}

const Form: React.FC<{
    setRequestState: Dispatch<SetStateAction<{
        sucess: boolean, lockey: string
    }>>,
    guid: string
}> = ({ setRequestState, guid }) => {
    const dispatch = useDispatch();
    const passwordService = useSelector(getPasswordResetService);
    const errorStoreActions = useSelector(getErrorStoreActions);
    const { getAttr } = useDetailFrameContext<IAkceptaceZmenyModel>();

    const onSubmit = useCallback(async (_, state: CustomFormState) => {
        const result = await passwordService.resetConfirm({
            password: state.password?.value?.trim(),
            passwordConfirm: state.passwordConfirm?.value?.trim(),
            guid: guid
        });

        if (!result.hasErrors) {
            setRequestState({ sucess: true, lockey: 'NEN-645301' });
        } else {
            switch (result?.errors?.[0]) {
                case "E1":
                    setRequestState({ sucess: false, lockey: 'NEN-645302' });
                    break;
                case "E2":
                    setRequestState({ sucess: false, lockey: 'NEN-645303' });
                    break;
                default:
                    dispatch(errorStoreActions.push(result.errors || ['Form error']));
            }
        }
    }, [passwordService, setRequestState, dispatch, errorStoreActions]);

    return (
        <CustomForm
            formDescLockey="NEN-645290" /* Heslo nesmí být stejné jako posledních 12 použitých hesel. */
            state={{
                username: {
                    value: getAttr('username')?.value ?? '',
                    headerText: 'NEN-645285',
                    type: 'hidden'
                },
                password: {
                    value: '',
                    type: 'password',
                    headerText: 'NEN-776018',
                    validation: {
                        required: {
                            rules: [
                                {
                                    regex: /^[^\s]{12,127}$/,
                                    lockey: 'NEN-776017' /* Délka hesla musí být v rozsahu 12-127 znaků. */
                                },
                                {
                                    custom: value => !value?.toLowerCase()?.includes(getAttr('username')?.value?.toLowerCase() ?? ''),
                                    lockey: 'NEN-824099' /* Heslo nesmí obsahovat uživatelské jméno. */
                                }
                            ]
                        },
                        optional: {
                            lockey: 'NEN-776020', /* Heslo musí splňovat alespoň 3 ze 4 následujících pravidel: */
                            minimalRequired: 3,
                            rules: [
                                {
                                    regex: /[a-z]/,
                                    lockey: 'NEN-645293' /* Heslo obsahuje malé písmena. */
                                },
                                {
                                    regex: /[A-Z]/,
                                    lockey: 'NEN-645294' /* Heslo obsahuje velké písmena. */
                                },
                                {
                                    regex: /\d/,
                                    lockey: 'NEN-645295' /* Heslo obsahuje číslo. */
                                },
                                {
                                    regex: /[-+_!@#$&%^*.,?]/,
                                    lockey: 'NEN-645296' /* Heslo obsahuje speciální znak. */
                                },
                            ]
                        }
                    }
                },
                passwordConfirm: {
                    value: '',
                    type: 'password',
                    headerText: 'NEN-776019',
                    validation: {
                        required: {
                            rules: [
                                {
                                    custom: (value, state) => value === state.password.value,
                                    lockey: 'NEN-645298' /* Zadaná hesla musejí být stejná. */
                                }
                            ]
                        }
                    }
                }
            }}
            onSubmit={onSubmit} />
    );
}

export default ZmenaHesla;