import React, { CSSProperties, ReactNode, RefObject, useLayoutEffect, useRef, useState } from "react"
import { useLocalization } from "@store/localizationStore";
import { AttrType, AttrMapping, IData, AttrName } from "@utils/AttrMapping";
import { setupCn } from "@utils/BemUtils";
import classNames from "classnames";
import TableRows from "./TableRows";
import { PaginatorComponent } from "@components/Pagination";
import { CollectionType } from "@store/collectionStore";
import { getColumnCSSWidth } from "./TableCell";
import { DetailLinkFactory } from "./TableRow";
import { FilterParamsNames } from "@constants/urlConstants";
import { renderIf } from "@utils/RenderUtils";
import { combineRefs } from "@utils/CombineRefs";
import { TableEmptyMessage } from "@components/Table";
import FlexBox from "@components/FlexBox/FlexBox";
import './Table.scss';
import { AttrModel } from "@components/ListFrame";
import { OrderBy, getOrderByColumnSort } from "@utils/OrderByUtils";
import { MaybeArray } from "@utils/ArrayHelper";
import TableHeadCell from "@components/Table/TableHeadCell";

const cn = setupCn('gov-table');
const cnSortable = setupCn('gov-sortable-table');
const cnSortableTrigger = cnSortable.setupSubCn('__trigger');
const cnHeadCell = cn.setupSubCn('__head-cell');

export type TableColumn<TData extends IData = IData> = {
    attrName: AttrName<TData>;
    width?: number | string;
    format?: AttrType;
    formatParam?: unknown;
    isWide?: boolean;
} & ({
    hidden: true;
    locKey?: string;
} | {
    hidden?: boolean;
    locKey: string;
});

export type OnSortClickEventParams<Mapping extends AttrMapping> = {
    column: TableColumn<AttrModel<Mapping>>;
    event: React.MouseEvent;
};

export type OnSortClickEventHandler<Mapping extends AttrMapping> = (params: OnSortClickEventParams<Mapping>) => void;

export type TableProps<Mapping extends AttrMapping> = {
    columns: TableColumn<AttrModel<Mapping>>[];
    attrMapping: Mapping;
    skeletonCount?: number;
    detailLinkFactory?: DetailLinkFactory;
    collectionInfo?: CollectionType;
    onPageCount?: number | null;
    paramNames?: FilterParamsNames;
    lightVersion?: boolean;
    onSortClick?: OnSortClickEventHandler<Mapping>;
    isSortable?: boolean;
    extraButtons?: ReactNode[];
    withPaginator?: boolean;
    containerRef?: RefObject<HTMLDivElement | null>;
    orderBy?: MaybeArray<OrderBy>
}

function Table<Mapping extends AttrMapping>({
    attrMapping,
    columns,
    detailLinkFactory,
    skeletonCount,
    onPageCount,
    onSortClick,
    collectionInfo,
    paramNames,
    lightVersion,
    isSortable = true,
    extraButtons,
    containerRef,
    withPaginator,
    orderBy,
}: TableProps<Mapping>) {
    const { d, t } = useLocalization();

    const containerInnerRef = useRef<HTMLDivElement>(null);
    const headRowRef = useRef<HTMLTableRowElement>(null);

    const sortableCn = isSortable ? cnSortable : (() => undefined);

    const {
        isFetching,
        isFetchedOnce,
        isLoadMore: isNext,
        count,
        collection,
        isTooGeneralQuery,
    } = collectionInfo ?? {};

    const [loadingInfo, setLoadingInfo] = useState({
        firstColumnWidth: 0,
        widths: [] as number[]
    });

    useLayoutEffect(() => {
        const ths = Array.from<HTMLElement>(headRowRef.current?.children as any);
        const allWidths = ths.map(td => td.offsetWidth);
        setLoadingInfo({
            firstColumnWidth: allWidths[0],
            widths: allWidths.slice(1)
        })
    }, [isFetching]);

    const getLoadingMinWidth = (width?: number): CSSProperties | undefined =>
        (isFetching || !isFetchedOnce) && width != null ?
            { minWidth: `${width}px` } :
            undefined;

    const isLoading = isFetching || !isFetchedOnce;
    const isEmpty = (collection == null || !collection.length) && !isLoading;

    return (
        <>
            {renderIf(isEmpty, () =>
                <TableEmptyMessage isTooGeneralQuery={isTooGeneralQuery} />
            )}
            <div ref={combineRefs(containerInnerRef, containerRef)} className={cn('-cover')} style={{ display: isEmpty ? 'none' : '' }}>
                <table className={cn.with(sortableCn()).main('--tablet-block')}>
                    <thead className={cn('__head')}>
                        <tr className={cn('__head-row')} ref={headRowRef}>
                            {
                                detailLinkFactory &&
                                <th
                                    className={classNames(cnHeadCell.main('--first'), cn('__head-row-controls'))}
                                    style={getLoadingMinWidth(loadingInfo.firstColumnWidth)}>
                                    <span className='u-sr-only'>{t('NEN-748840')}</span>
                                </th>
                            }
                            {columns.map((column, index) => (
                                <th
                                    key={index}
                                    className={cnHeadCell.main()}
                                    title={d(column.locKey)}
                                    style={{
                                        ...getLoadingMinWidth(loadingInfo.widths?.[index]),
                                        width: getColumnCSSWidth(column.width)
                                    }}>
                                    <TableHeadCell
                                        cn={cnSortableTrigger}
                                        locKey={column.locKey!}
                                        asc={getOrderByColumnSort(orderBy, column) == 'asc'}
                                        desc={getOrderByColumnSort(orderBy, column) == 'desc'}
                                        onSortClick={event => onSortClick?.({ event, column })}
                                        isSortable={
                                            isSortable
                                            && attrMapping.getByClientName(column.attrName)?.type != 'text'
                                        } />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className={cn('__body')}>
                        <TableRows
                            data={collection}
                            detailLinkFactory={detailLinkFactory}
                            columns={columns as TableColumn[]}
                            attrMapping={attrMapping}
                            isNext={isNext}
                            skeletonCount={skeletonCount}
                            isLoading={isLoading} />
                    </tbody>
                </table>
            </div>
            {
                (withPaginator || lightVersion && onPageCount !== null) && paramNames ?
                    <PaginatorComponent
                        loadMoreButton={!lightVersion}
                        scrollToRef={containerInnerRef}
                        paramNames={paramNames as FilterParamsNames}
                        onPageCount={onPageCount}
                        totalCount={count}
                        isLoading={isLoading}
                        extraButtons={extraButtons} /> :
                    extraButtons && !isEmpty ?
                        <FlexBox className="paginator__container">
                            <div className="paginator__container__buttons">
                                {extraButtons}
                            </div>
                        </FlexBox> : null
            }
        </>
    );
}

export default React.memo(Table);
