function transformKey<K>(key: K): K {
    if (typeof key == 'string') {
        return key.toLowerCase() as unknown as K;
    }
    return key;
}

export class CaseInsensitiveMap<K, V> extends Map<K, V> {
    constructor(items?: Iterable<[K, V]>) {
        super(items && [...items].map(([key, value]) => [transformKey(key), value]));
    }

    public get(key: K) {
        return super.get(transformKey(key));
    }

    public set(key: K, value: V) {
        return super.set(transformKey(key), value);
    }

    public has(key: K) {
        return super.has(transformKey(key));
    }

    public delete(key: K) {
        return super.delete(transformKey(key));
    }
}