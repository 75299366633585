import { DependencyList, useLayoutEffect } from "react";

export const useWindowMessage = <T = any>(actionName: string, callback: (payload: T) => void, deps: DependencyList = []) => {
    useLayoutEffect(() => {
        const listener = async (event: MessageEvent) => {
            console.debug('[WindowMessage - Recieved]', event.data);
            if (typeof event?.data != 'object') {
                return;
            }
            if (event.data.action == actionName) {
                callback(event.data.payload);
            }
        }
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, deps);
}

export const postWindowMessage = <T = any>(window: Window | undefined | null, actionName: string, payload: T) => {
    const action = {
        action: actionName,
        payload
    };
    console.debug('[WindowMessage - Post]', action);
    window?.postMessage(action, '*');
}
