import React, { FC } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import GovLinkBase, { GovLinkBaseProps } from "./GovLinkBase";

export type GovNavLinkProps = GovLinkBaseProps & Omit<NavLinkProps, 'className'>;

const NavLinkElement = React.forwardRef<HTMLAnchorElement, NavLinkProps>((navLinkProps, ref) =>
    <NavLink innerRef={ref} {...navLinkProps} activeClassName='is-active' />)

const GovNavLink: FC<GovNavLinkProps> = ({ ...restProps }) => {
    return (
        <GovLinkBase
            element={NavLinkElement}
            {...restProps} />
    )
}

export default React.memo(GovNavLink);
