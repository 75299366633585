import { AttrMapping, IData } from "@utils/AttrMapping";

export type IDodavateleDNSModel = IData & {
    dodICO: string;
    dodNazev: string;
}

export const DodavateleDNSAttrMap = new AttrMapping<IDodavateleDNSModel>('Registrovany_dodav', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'dodICO', serverName: 'Dodavatel.ICO', type: 'string' },
    { clientName: 'dodNazev', serverName: 'Dodavatel.Nazev', type: 'string' },
]);
