import React, { FC } from "react"
import { setupCn } from "@utils/BemUtils";
import WhispererInput, { WhispererInputClasses, WhispererInputProps } from "@components/WhispererInput";

const cn = setupCn('gov-multiselect');

export type SelectInputProps = Omit<WhispererInputProps, 'classes'>;

export const selectClasses: WhispererInputClasses = {
    componentClass: cn(),
    whispererClass: cn('__options'),
    itemClass: cn('__option')
}

const SelectInput: FC<SelectInputProps> = (props) => {
    return (
        <WhispererInput
            {...props}
            classes={selectClasses} />
    );
};

SelectInput.displayName = 'SelectInput';

export default React.memo(SelectInput);
