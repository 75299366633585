import { FC } from "react";
import { useLocalization } from "@store/localizationStore";
import ContentBlock from "@components/ContentBlock";
import { useLocation } from "react-router";

export const Page404: FC = () => {
    const { ts } = useLocalization();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const getText = () => {
        if (params.file) {

            if (params.file == "multiple") {
                return {
                    headlineText: ts('NEN-781344', { width: 500 }), // V�mi po�adovan� soubory nebyly nalezeny.
                    text: ts('NEN-645267', { width: 250 }) // Pros�m, zkontrolujte si URL adresu.
                }
            }

            return {
                headlineText: ts('NEN-781351', { width: 500 }), // V�mi po�adovan� soubor nebyl nalezen.
                text: ts('NEN-645267', { width: 250 }) // Pros�m, zkontrolujte si URL adresu.
            }
        }

        return {
            headlineText: ts('NEN-645266', { width: 500 }), // V�mi po�adovan� str�nka neexistuje.
            text: ts('NEN-645267', { width: 250 }) // Pros�m, zkontrolujte si URL adresu.
        };
    }

    const { headlineText, text } = getText();
    return (
        <ContentBlock>
            <h2>{headlineText}</h2>
            <p>{text}</p>
        </ContentBlock>
    );
}