import { match, useRouteMatch } from "react-router";
import ZakladniInformacePage from "./page/ZakladniInformace";
import { UrlParamObject } from "@utils/QueryHook";
import { FilterParamsNames, urlConstants } from "@constants/urlConstants";
import { IProfilZadavateleModel, ProfilZadavateleAttrMap } from "@models/IProfilZadavateleModel";
import { DetailFrame, useDetailFrameContext } from "@components/DetailFrame/DetailFrame";
import Tabs from "@components/Tabs";
import ListFrameZakazky from "./page/PUTINZakazky";
import { IZpModel } from "@models/IZpModelHist";
import DalsiInformacePage from "./page/DalsiInformace";
import ContentBlock from "@components/ContentBlock";
import { useLocalization } from "@store/localizationStore";
import FlexBox from "@components/FlexBox/FlexBox";
import { Stav_postupuEnum } from "@models/staticEnumerators/Stav_postupuEnum";
import { joinConditionsByOr } from "@utils/FilterCondition";
import { ParsedUrlCondition } from "@store/breadcrumbTitleStore";
import { DNSystemAttrMap, IDNSystemiModel } from "../../models/IDNSystemModel";
import { AttrNames } from "../../utils/AttrMapping";
import React, { FC } from "react";
import { ON_PAGE_ROWS } from "../verejneZakazky/registrDns/RegistrDns";
import { detailLinks } from "../../constants/detailLinks";
import { useOrderByMemo } from "../../utils/OrderByUtils";
import { DetailDescriptionParams, useDetailDescription } from "../../utils/DescriptionHook";
import { columnWidths, DependentListFrameTyped, ListFrameColumns } from "../../components/ListFrame";
import FilterManager from "../../components/FilterManager";

const detailProfilZadavateleLoadKey = 'detail-profil-zadavatele';
const DependentListFrame = DependentListFrameTyped<IProfilZadavateleModel>();

export const parseProfilUrl = (match: match<UrlParamObject> | null) => {
    var param = match?.params[urlConstants.profilZadavateleDetail.detailIdParam] || '';
    let kodProfilu = param;
    let idSubj = "";
    if (/^\d+$/.test(param)) {
        idSubj = param;
    }
    return { idSubj, kodProfilu };
}

export const prepareProfilCondition = ({ idSubj, kodProfilu }: { idSubj: string, kodProfilu: string }): ParsedUrlCondition => {
    return {
        condition: joinConditionsByOr(
            idSubj != "" && 'subj=@0 and Platny=true',
            kodProfilu != "" && `DB.Upper(Adresa_profil_NEN) like '%/${kodProfilu.toUpperCase().replace('_', '\\_')}'`,
        ) ?? '1=0',
        conditionParams: [idSubj]
    }
}

const routeParamNameMulti = urlConstants.profilZadavateleDetail.urlRouteParam;
const zahajeneZakazkyParamNames = { filterParamName: 'pzvz', routeParamName: routeParamNameMulti };
const uzavreneZakazkyParamNames = { filterParamName: 'puvz', routeParamName: routeParamNameMulti };
const planovaneZakazkyParamNames = { filterParamName: 'ppvz', routeParamName: routeParamNameMulti };
const dnsProfilParamNames = { filterParamName: 'sdns', routeParamName: routeParamNameMulti };

const DetailProfilZadavatele: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();

    const { condition, conditionParams } = prepareProfilCondition(parseProfilUrl(match));

    return (
        <DetailFrame
            condition={condition}
            conditionParams={conditionParams}
            attrMapping={ProfilZadavateleAttrMap}
            loadKey={detailProfilZadavateleLoadKey}>
            <PageDetailuProfiluZadavatele />
        </DetailFrame>
    );
}

const commonDescriptionParts: DetailDescriptionParams<IProfilZadavateleModel>[] = [
    {
        lockey: 'NEN-812506',
        attrName: 'nazevOrganizace',
    },
    {
        lockey: 'NEN-812510',
        attrName: 'doplnekKNazvu',
    },
    {
        lockey: 'NEN-812698',
        attrName: 'evidencniCisloVeVestniku',
    },
    {
        lockey: 'NEN-812546',
        attrName: 'pfNazev',
    },
    {
        lockey: 'NEN-812517',
        attrName: 'obec',
    },
    {
        lockey: 'NEN-812518',
        attrName: 'castObce',
    },
    {
        lockey: 'NEN-812523',
        attrName: 'ulice',
    },
    {
        lockey: 'NEN-812525',
        attrName: 'cisloOrientacni',
    },
    {
        lockey: 'NEN-812527',
        attrName: 'stat',
    }
]

const actualStateHiddenUkonceneZakazky: Stav_postupuEnum[] = ['planovana', 'neukoncena'];
const PageDetailuProfiluZadavatele: React.FC = () => {
    const { getAttr } = useDetailFrameContext<IProfilZadavateleModel>();
    const { ts } = useLocalization();

    useDetailDescription<IProfilZadavateleModel>(commonDescriptionParts);

    const id = getAttr('nazevOrganizace');
    if (!id.isFetching && !id.value) {
        return (
            <ContentBlock>
                <FlexBox direction='column' style={{ padding: '16px' }}>
                    <h2>{ts('NEN-770247')}</h2>
                    <p>{ts('NEN-770248')}</p>
                </FlexBox>
            </ContentBlock>
        );
    }
    return (
        <Tabs tabs={
            [
                {
                    lockey: 'NEN-812507',
                    path: urlConstants.profilZadavateleDetail.path,
                    render: () => <ZakladniInformacePage routeParamName={routeParamNameMulti} />
                },
                {
                    lockey: 'NEN-812542',
                    path: urlConstants.profilZadavateleDetailKontakty.path,
                    render: () => <DalsiInformacePage />
                },
                {
                    lockey: 'NEN-812853',
                    path: urlConstants.profilZadavateleDetailZahajeneZakazky.path,
                    render: () =>
                        <ContentBlock>
                            <ListFrameZakazky
                                condition='hierarchie = null and Stav_zp = !Stav_postupu.Neukoncena and aktualni = true and uver_profil = true and Prof=@0'
                                loadKey='-zahajene-zakazky'
                                paramNames={zahajeneZakazkyParamNames}
                                columns={zpZahajeneColumns}
                                hideActualState
                            />
                        </ContentBlock>
                },
                {
                    lockey: 'NEN-812854',
                    path: urlConstants.profilZadavateleDetailUzavreneZakazky.path,
                    render: () =>
                        <ContentBlock>
                            <ListFrameZakazky
                                condition='hierarchie = null and Stav_zp <> !Stav_postupu.Neukoncena and Stav_zp <> !Stav_postupu.Planovana and aktualni = true and uver_profil = true and Prof = @0'
                                loadKey='-ukoncene-zakazky'
                                paramNames={uzavreneZakazkyParamNames}
                                columns={zpUkonceneColumns}
                                actualStateHiddenValues={actualStateHiddenUkonceneZakazky}
                            />
                        </ContentBlock>
                },
                {
                    lockey: 'NEN-812855',
                    path: urlConstants.profilZadavateleDetailPlanovaneZakazky.path,
                    render: () =>
                        <ContentBlock>
                            <ListFrameZakazky
                                condition='hierarchie = null and Stav_zp = !Stav_postupu.Planovana and aktualni = true and uver_profil = true and Prof = @0'
                                loadKey='-planovane-zakazky'
                                paramNames={planovaneZakazkyParamNames}
                                columns={zpPlanovaneColumns}
                                hideActualState
                            />
                        </ContentBlock>
                },
                {
                    lockey: 'NEN-814485',
                    path: urlConstants.profilZadavateleDetailSeznamDns.path,
                    render: () =>
                        <ContentBlock>
                            <FilterManager id='seznam-dns-filter' paramNames={dnsProfilParamNames} />
                            <ListFrameDnsProfil
                                condition='Zadavatel=@0'
                                loadKey='-seznam-dns'
                                paramNames={dnsProfilParamNames}
                                columns={seznamDnsProfilColumns}
                                hideActualState
                            />
                        </ContentBlock>
                },
            ]
        } />
    );
}

const zpZahajeneColumns: ListFrameColumns<IZpModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812594',
        width: columnWidths.zpCode
    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812694',
        width: '200%'
    },
    {
        attrName: 'stavZP',
        locKey: 'NEN-738558',
        width: 120
    },
    {
        attrName: 'zadavatelNazev',
        locKey: 'NEN-812618',
        width: 200
    },
    {
        attrName: 'podaniLhuta',
        locKey: 'NEN-738562',
        width: columnWidths.dateTime
    }
];

const zpUkonceneColumns: ListFrameColumns<IZpModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812594',
        width: columnWidths.zpCode
    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812694',
        width: '200%'
    },
    {
        attrName: 'stavZP',
        locKey: 'NEN-738558',
        width: 120
    },
    {
        attrName: 'zadavatelNazev',
        locKey: 'NEN-812618',
        width: 200
    },
    {
        attrName: 'podaniLhuta',
        locKey: 'NEN-738562',
        width: columnWidths.dateTime
    }
];

const zpPlanovaneColumns: ListFrameColumns<IZpModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812594',
        width: columnWidths.zpCode
    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812694',
        width: '200%'
    },
    {
        attrName: 'zadavatelNazev',
        locKey: 'NEN-812618',
        width: 200
    },
    {
        attrName: 'podaniLhuta',
        locKey: 'NEN-738562',
        width: columnWidths.dateTime
    }
];
const seznamDnsProfilColumns: ListFrameColumns<IDNSystemiModel> = [
    {
        attrName: 'nazev',
        locKey: 'NEN-812716'
    },
    {
        attrName: 'cpvKod',
        locKey: 'NEN-812701',
        width: 120
    },
    {
        attrName: 'stav',
        locKey: 'NEN-812623',
        width: 80
    },
    {
        attrName: 'datumZavedeni',
        locKey: 'NEN-812638',
        width: columnWidths.dateTime
    },
    {
        attrName: 'terminUkonceni',
        locKey: 'NEN-812624',
        width: columnWidths.date
    }
];

const fulltextAttributes: AttrNames<IDNSystemiModel> = ['nazev', 'zadavatelNazev', 'cpvKod'];

const ListFrameDnsProfil: FC<{
    condition: string,
    loadKey: string,
    columns?: ListFrameColumns<IDNSystemiModel>,
    paramNames?: FilterParamsNames,
    hideActualState?: boolean
}> = ({
    condition,
    loadKey,
    columns,
    paramNames,
    hideActualState
}) => (
    <DependentListFrame
        listFrameKey={detailProfilZadavateleLoadKey + '-dns-seznam'}
        condition={condition}
        conditionParams={'subjID'}
        orderBy={useOrderByMemo<IDNSystemiModel>({ desc: false, column: 'stav' }, { desc: true, column: 'datumZavedeni' })}
        fulltextAttributes={fulltextAttributes}
        paramNames={dnsProfilParamNames}
        skeletonCount={ON_PAGE_ROWS}
        onPageCount={ON_PAGE_ROWS}
        attrMapping={DNSystemAttrMap}
        columns={seznamDnsProfilColumns}
        detailLinkFactory={detailLinks.getDnsDetailLinkFactory(true)}
    />
);


export default DetailProfilZadavatele;