import { FC, ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { useEnumDescriptions, UseEnumDescriptionsType } from "@store/enumDescriptionsStore";
import { useLocalization, UseLocalizationType } from "@store/localizationStore";
import { AttrType, EnumConverterParam, FileConverterParam, IBasicData } from "./AttrMapping";
import { renderIf } from "./RenderUtils";
import { setupCn } from "./BemUtils";
import "./AttrConverter.scss";
import DocumentSignatureButton from "@components/DocumentSignatureButton";

export type AttrConverterRequirements = {
    localization: UseLocalizationType;
    enumDescriptions: UseEnumDescriptionsType;
}

export type AttrConverter<T = any> = (props: ConvertAttrValueParams & AttrConverterRequirements & ConvertAttrParams<T>) => ReactNode;
export type AttrStringConverter<T = any> = (props: ConvertAttrValueParams & AttrConverterRequirements & ConvertAttrParams<T>) => string | null;

const stringConverter: AttrStringConverter = ({ value }) => (value as object)?.toString() ?? null;

const convertDate = (value: unknown, options: Intl.DateTimeFormatOptions) => value instanceof Date ? value.toLocaleString([], options) :
    typeof value === 'string' && !isNaN(Date.parse(value)) ? new Date(value).toLocaleString([], options) : null;

const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
const dateTimeOptions: Intl.DateTimeFormatOptions = { ...dateOptions, hour: '2-digit', minute: '2-digit' };
const dateConverter: AttrStringConverter = ({ value }) => convertDate(value, dateOptions);
const dateTimeConverter: AttrStringConverter = ({ value }) => convertDate(value, dateTimeOptions);

const boolConverter: AttrStringConverter = ({ value, localization }) => value == null ? null : localization.t(value ? 'NEN-738573' : 'NEN-738574');

const enumStringConverter: AttrStringConverter<EnumConverterParam> = ({ value, localization, enumDescriptions, formatParam }) => {
    if (value == null) {
        return null;
    }
    const { getEnumDescription } = enumDescriptions;
    const enumDescription = formatParam && getEnumDescription(formatParam);
    if (!enumDescription) {
        return '';
    }
    const locKey = enumDescription.items.find(item => item.name == value || item.intValue == value)?.locKey;
    return locKey ? localization.t(locKey) : '';
}

const enumNodeConverter: AttrConverter<EnumConverterParam> = ({ value, localization, enumDescriptions, formatParam }) => {
    if (value == null) {
        return null;
    }
    const { getEnumDescription } = enumDescriptions;
    const enumDescription = formatParam && getEnumDescription(formatParam);
    if (!enumDescription) {
        return <Skeleton />;
    }
    const locKey = enumDescription.items.find(item => item.name == value || item.intValue == value)?.locKey;
    return locKey ? localization.ts(locKey) : '';
}

const fileCn = setupCn('file-value');

const fileNodeConverter: AttrConverter<FileConverterParam<{}>> = (prms) => {
    const { value, data, formatParam, objectName, serverAttrName } = prms;
    if (value == null) {
        return null;
    }

    const fileName = (value as string)?.split(':')[2];
    const linkText = fileStringConverter(prms);

    let hasSignature = false;
    if (formatParam && typeof formatParam == 'object') {
        hasSignature = formatParam.hasDigitalSignarue?.(data ?? undefined) ?? false;
    }

    return (
        <span className={fileCn.main()}>
            <Link className={fileCn('__file')} to={'/file?id=' + data?.id} target='_blank' rel='nofollow'>{linkText}</Link>
            {renderIf(hasSignature && objectName && serverAttrName && data?.id, id => 
                <DocumentSignatureButton
                    className={fileCn('__file-signature')}
                    id={id.toString()}
                    fileName={fileName}
                    objectName={objectName!}
                    attrName={serverAttrName!} />)}
        </span>
    );
}

const fileStringConverter: AttrStringConverter<FileConverterParam<{}>> = ({ value, data, formatParam }) => {
    if (value == null) {
        return null;
    }

    let linkText = (value as string)?.split(':')[2];

    if (formatParam && typeof formatParam == 'object') {
        linkText = formatParam.getFileName?.(data ?? undefined, linkText) ?? linkText;
    }

    return linkText;
}

const moneyConverter: AttrStringConverter = ({ value }) => (value as number)?.toLocaleString([], { minimumFractionDigits: 2 }) ?? null;

const attrNodeConverters = new Map<AttrType, AttrConverter<any>>();
attrNodeConverters.set('string', stringConverter);
attrNodeConverters.set('text', stringConverter);
attrNodeConverters.set('number', stringConverter);
attrNodeConverters.set('date', dateConverter);
attrNodeConverters.set('dateTime', dateTimeConverter);
attrNodeConverters.set('bool', boolConverter);
attrNodeConverters.set('money', moneyConverter);
attrNodeConverters.set('enum', enumNodeConverter);
attrNodeConverters.set('file', fileNodeConverter);

const attrToStringConverters = new Map<AttrType, AttrStringConverter<any>>();
attrToStringConverters.set('string', stringConverter);
attrToStringConverters.set('text', stringConverter);
attrToStringConverters.set('number', stringConverter);
attrToStringConverters.set('date', dateConverter);
attrToStringConverters.set('dateTime', dateTimeConverter);
attrToStringConverters.set('bool', boolConverter);
attrToStringConverters.set('money', moneyConverter);
attrToStringConverters.set('enum', enumStringConverter);
attrToStringConverters.set('file', fileStringConverter);

export type CovertAttrFormatParams<T = unknown> = {
    format: AttrType;
    formatParam?: T;
}

export type ConvertAttrParams<T = unknown> = CovertAttrFormatParams<T> & {
    objectName?: string;
    serverAttrName?: string;
}

export type ConvertAttrValueParams = {
    value: unknown;
    data?: IBasicData | null
}

export type ConvertStringAttrValueParams = ConvertAttrValueParams & {
    emptyValue?: string;
}

export type ConvertNodeAttrValueParams = ConvertAttrValueParams & {
    emptyValue?: FC;
}

export function stringAttrValue({
    emptyValue,
    ...params
}: ConvertStringAttrValueParams & AttrConverterRequirements & ConvertAttrParams): string {
    const toStringConverter = attrToStringConverters.get(params.format) || stringConverter;
    return (toStringConverter(params) ?? emptyValue) || '';
}

export function nodeAttrValue({
    emptyValue: EmptyValue,
    ...params
}: ConvertNodeAttrValueParams & AttrConverterRequirements & ConvertAttrParams): ReactNode {
    const nodeConverter = attrNodeConverters.get(params.format) || stringConverter;
    return nodeConverter(params) ?? (EmptyValue && <EmptyValue />) ?? null;
}

export const useAttrConverter = () => {
    const requiredParams: AttrConverterRequirements = {
        localization: useLocalization(),
        enumDescriptions: useEnumDescriptions(),
    };

    const withFormat = (formatParams: ConvertAttrParams) => {
        return {
            nodeAttrValue: (params: ConvertNodeAttrValueParams) => nodeAttrValue({ ...requiredParams, ...formatParams, ...params }),
            stringAttrValue: (params: ConvertStringAttrValueParams) => stringAttrValue({ ...requiredParams, ...formatParams, ...params })
        };
    }

    return {
        nodeAttrValue: (params: ConvertNodeAttrValueParams & ConvertAttrParams) => nodeAttrValue({ ...params, ...requiredParams }),
        stringAttrValue: (params: ConvertStringAttrValueParams & ConvertAttrParams) => stringAttrValue({ ...params, ...requiredParams }),
        withFormat
    };
}
