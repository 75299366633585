import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import './Grid.scss';
import { BottomSpace, bottomSpaceClass } from "@styles/offsets";

const cn = setupCn('gov-grid');

export type ColumnsCount = 1 | '1' | 2 | '2' | 3 | '3' | 4 | '4' | '4-2' | '2-mobile';
export type GridAlignItems = 'center' | 'start' | 'end';

export type GridProps = PropsWithChildren<{
    className?: Classes;
    isPlain?: boolean;
    lowerRowSpaces?: boolean;
    bottomSpace?: BottomSpace;
    columns: ColumnsCount;
    alignItems?: GridAlignItems;
}>;

const Grid: FC<GridProps> = ({
    className,
    isPlain,
    lowerRowSpaces,
    alignItems,
    bottomSpace,
    children,
    columns
}) => {
    const columnsModifier = `--x${columns}`;
    const alignItemsModifier = alignItems && `--align-items-${alignItems}`
    const bottomSpaceModifier = bottomSpaceClass(bottomSpace);

    return (
        <div className={cn.with(className, bottomSpaceModifier).main(columnsModifier, alignItemsModifier, {
            '--plain': isPlain,
            '--lower-row-spaces': lowerRowSpaces
        })}>
            {children}
        </div>
    );
};

Grid.displayName = 'Grid';

export default React.memo(Grid);
