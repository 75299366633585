import { Result, ServiceBase } from "./ServiceBase";

export type ResetRequestParams = {
    username: string | undefined;
    email: string | undefined;
}

export type ResetConfirmParams = {
    password: string | undefined;
    passwordConfirm: string | undefined;
    guid: string | undefined;
}
export default class PasswordResetService extends ServiceBase {
    public async resetRequest(params: ResetRequestParams): Promise<Result<any>> {
        return await this.requestJson({
            url: '/api/passwordreset/reset-request',
            method: 'POST',
            data: params
        });
    }

    public async resetConfirm(params: ResetConfirmParams): Promise<Result<any>> {
        return await this.requestJson({
            url: '/api/passwordreset/reset-confirm',
            method: 'POST',
            data: params
        });
    }
}