import { ReactNode } from "react";
import { setupCn } from "@utils/BemUtils";

const cn = setupCn("gov-form-group");

const FormGroup: React.FC<{ items: ReactNode[] }> = ({ items }) => {
    return (
        <ul className={cn.main("--complex")}>
            {
                items.map((item, index) =>
                    <li className={cn("__item")} key={index}>
                        {item}
                    </li>)
            }
        </ul>
    );
}

export default FormGroup;