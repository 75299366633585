import { AttrMapping, IData } from "@utils/AttrMapping";

export type IMistoNUTSModel = IData & {
    cpvPolozkyKod: string;
    createCas: Date;
    nazevPolozky: string;
    nutsKod: string;
    nutsNazev: string;
    nipezPolozkyKod: string;
}

export const MistoNUTSAttrMap = new AttrMapping<IMistoNUTSModel>('Misto_NUTS_hist', [
    { clientName: 'cpvPolozkyKod', serverName: 'CPV_polozky.Kod', type: 'string' },
    { clientName: 'createCas', serverName: 'create_cas', type: 'dateTime' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'nazevPolozky', serverName: 'Nazev_polozky', type: 'string' },
    { clientName: 'nutsKod', serverName: 'vazba_NUTS.Kod', type: 'string' },
    { clientName: 'nutsNazev', serverName: 'vazba_NUTS.Nazev', type: 'string' },
    { clientName: 'nipezPolozkyKod', serverName: 'NIPEZ_polozky.Kod', type: 'string' },
]);
