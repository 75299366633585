import CertificateDetail from "crypto/CertificateDetail/CertificateDetail";
import CryptoPart from "crypto/CryptoPart/CryptoPart";
import { ISignatureInfo, SignatureInfoType } from "crypto/interface/ISignatureInfo";
import { updateSignatureValidationMessage, formatDistinguishedNamePart, getSignatureState, hasSignatureWarning, isEncrypted } from "crypto/SignatureUtils";
import React, { FC, PropsWithChildren } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";
import ResultBadge from "@components/CompatibilityTest/Components/ResultBadge/ResultBadge";
import { TestResult } from "@components/CompatibilityTest/CompatibilityTests";
import { MdLockOutline, MdAccessTime, MdWorkspacePremium, MdWarning, MdCheckCircle } from 'react-icons/md';
import Icon from "@components/Icon";
import FlexBox from "@components/FlexBox/FlexBox";
import './SignatureInfo.scss';
import Note from "@components/Note/Note";

const cn = setupCn('signature-info');

export type SignatureInfoProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    signatureInfo: ISignatureInfo;
}>;

const SignatureInfo: FC<SignatureInfoProps> = ({
    id,
    className,
    signatureInfo,
}) => {
    const localization = useLocalization();
    const { ts } = localization;

    let icon = undefined;
    switch (signatureInfo.type) {
        case SignatureInfoType.digitalSignature:
            icon = MdWorkspacePremium;
            break;
        case SignatureInfoType.timeStamp:
            icon = MdAccessTime;
            break;
        case SignatureInfoType.digitalMark:
            icon = MdLockOutline;
            break;
    }

    updateSignatureValidationMessage(signatureInfo);

    const caption = signatureInfo.certificate ? formatDistinguishedNamePart(signatureInfo.certificate.subject, 'CN') : '';
    const date = signatureInfo.time ? new Date(signatureInfo.time.toString()).toLocaleDateString() + ', ' + new Date(signatureInfo.time.toString()).toLocaleTimeString() : "";
    const hasWarning = hasSignatureWarning(signatureInfo, false);
    const warningText = hasWarning ? getSignatureState(signatureInfo, localization) : null;
    const showWarningIcon = (hasWarning || isEncrypted({ signatureInfo }));

    return (
        <CryptoPart
            id={id}
            className={cn.with(className)()}
            caption={caption}
            captionIcon={icon}
            captionDescription={<>
                {date}
                {
                    !warningText ? null :
                        <>
                            <br />
                            <ResultBadge result={TestResult.partially}>{warningText}</ResultBadge>
                        </>
                }
            </>}
            toggleButtonContent={<Icon className={cn('__validity-icon')} icon={showWarningIcon ? MdWarning : MdCheckCircle} />}>
            <FlexBox direction='column' gap='16px' align='start'>
                <Note>{ts('GUI-653208')}</Note>
                <CertificateDetail
                    className={cn('__certificate-detail')}
                    certificate={signatureInfo.certificate} />
            </FlexBox>
        </CryptoPart >
    );
};

SignatureInfo.displayName = 'SignatureInfo';

export default React.memo(SignatureInfo);
