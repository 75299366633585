import React, { FC, ReactNode } from "react";
import { TableColumn } from "./Table";
import { useLocalization } from "@store/localizationStore";
import { Classes } from "@utils/BemUtils";
import classNames from "classnames";
import Skeleton from 'react-loading-skeleton';
import { AttrMapping, IData } from "@utils/AttrMapping";
import { useAttrConverter } from "@utils/AttrConverter";

export type TableCellProps = {
    attrMapping: AttrMapping;
    column: TableColumn;
    data: IData | null;
    isSkeleton?: boolean;
    className?: Classes;
}

const cellPadding = 10;
export const getColumnCSSWidth = (width?: number | null | string) => width ? (typeof width == 'string' ? width : `${width + cellPadding}px`) : '100%';

const TableCell: FC<TableCellProps> = ({
    attrMapping,
    column,
    data,
    isSkeleton,
    className
}) => {
    const { t } = useLocalization();
    const attrInfo = attrMapping.getByClientName(column.attrName);
    const format = column.format ?? attrInfo?.defaultFormat ?? attrInfo?.type ?? 'string';
    const formatParam = column.formatParam ?? attrInfo?.typeParam;
    const { nodeAttrValue, stringAttrValue } = useAttrConverter().withFormat({
        format,
        formatParam,
        objectName: attrMapping.objectName,
        serverAttrName: attrInfo?.serverName,
    });
    let content: ReactNode = null;
    let title: string | undefined = undefined;
    if (isSkeleton) {
        content = <Skeleton />;
    } else {
        content = nodeAttrValue({ value: data?.[column.attrName], data: data });
        title = stringAttrValue({ value: data?.[column.attrName], data: data });
    }

    return (
        <td
            className={classNames(className)}
            title={title}
            style={{
                width: getColumnCSSWidth(column.width)
            }}
            data-title={t(column.locKey)}>
            {content}
        </td>
    );
};

export default React.memo(TableCell);
