import { AttrMapping, IData } from "@utils/AttrMapping";

export type IJinaInformaceSubjektu = IData & {
    poradi: number;
    popis: string;
    text: string;
}

export const JinaInformaceSubjektuAttrMap = new AttrMapping<IJinaInformaceSubjektu>('Jina_informace_subj', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'poradi', serverName: 'Poradi', type: 'number' },
    { clientName: 'popis', serverName: 'Popis', type: 'string' },
    { clientName: 'text', serverName: 'Text_informace', type: 'text' },
]);
