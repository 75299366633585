import { TestResult } from "@components/CompatibilityTest/CompatibilityTests";
import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";

const cn = setupCn('result-text');

export type ResultTextProps = PropsWithChildren<{
    className?: Classes;
    result: TestResult;
}>;

const ResultText: FC<ResultTextProps> = ({
    className,
    result,
    children
}) => {
    return (
        <div className={cn.with(className, {
            'text-success': result == TestResult.ok,
            'text-warning-dark': result == TestResult.partially,
            'text-danger': result == TestResult.failed,
            'text-primary': result == TestResult.evaluating
        })()}>
            {children}
        </div>
    );
};

ResultText.displayName = 'ResultText';

export default React.memo(ResultText);
