import { TestResult } from "@components/CompatibilityTest/CompatibilityTests";
import { CryptoComponentInfo } from "crypto/CryptoUtils";
import React, { FC, PropsWithChildren } from "react"
import { useSelector } from "react-redux";
import { getCurrentLanguage, useLocalization } from "@store/localizationStore";
import { setupCn } from "@utils/BemUtils";
import ResultBadge from "../../ResultBadge/ResultBadge";

import './CryptoComponentResult.scss';

const cn = setupCn('crypto-component-result');

export type CryptoComponentResultProps = PropsWithChildren<{
    componentInfo: CryptoComponentInfo;
}>;

const CryptoComponentResult: FC<CryptoComponentResultProps> = ({
    componentInfo,
    children
}) => {
    componentInfo ??= { result: TestResult.notEvaluated };
    const {
        result,
        downloadUrl,
        linkTarget,
        requiredVersion,
        version
    } = componentInfo;
    const { ts } = useLocalization();
    const language = useSelector(getCurrentLanguage);
    const linkHref = downloadUrl?.(language);
    return (
        <div className={cn()}>
            <ResultBadge result={result} failedText={ts('NEN-746933')} />
            {children}
            {
                version == null ? null :
                    <>&nbsp;({ts('NEN-746931')}&nbsp;{version})</>
            }
            {
                result == TestResult.ok || requiredVersion == null ? null :
                    <>&nbsp;({ts('NEN-747319')}&nbsp;{requiredVersion})</>
            }
            {
                result == TestResult.ok || linkHref == null ? null :
                    <a className={cn('__link')} target={linkTarget} href={linkHref}>{ts('NEN-746932')}</a>
            }
        </div>
    );
};

CryptoComponentResult.displayName = 'CryptoComponentResult';

export default React.memo(CryptoComponentResult);
