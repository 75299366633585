import { pageIds } from "@components/Accessibility/pageIds";
import GovLink from "@components/GovLink";
import { useConstants } from "@constants/urlConstants";
import { useLocalization } from "@store/localizationStore";
import { setupCn } from "@utils/BemUtils";
import React, { FC } from "react";
import { useRouteMatch } from "react-router";

import './Shortcuts.scss';

const cn = setupCn('shortcuts');

const Shortcuts: FC = () => {
    const { ts, t } = useLocalization();
    const match = useRouteMatch();
    const { getUrl } = useConstants();
    return (
        <nav className={cn()} title={t('NEN-786979')}>
            <ul>
                <li>
                    <a
                        accessKey="o"
                        href={`${match.url}#${pageIds.content}`}>
                        {ts('NEN-645333') /* Přeskočit na Hlavní obsah */} (Accesskey: o)
                    </a>
                </li>
                <li>
                    <a
                        accessKey="n"
                        href={`${match.url}#${pageIds.menu}`}>
                        {ts('NEN-645332') /* Přeskočit na Hlavní navigaci */} (Accesskey: n)
                    </a>
                </li>
                <li>
                    <a
                        accessKey="i"
                        href={`${match.url}#${pageIds.footer}`}>
                        {ts('NEN-785549') /* Přeskočit na Patičku */} (Accesskey: i)
                    </a>
                </li>
                <li>
                    <GovLink
                        accessKey="p"
                        to={`${getUrl('prihlaseni')}#${pageIds.content}`}>
                        {ts('NEN-785550') /*Přeskočit na stránku Přihlášení */} (Accesskey: p)
                    </GovLink>
                </li>
            </ul>
        </nav>
    );
}

export default React.memo(Shortcuts);