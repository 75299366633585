import React, { FC, PropsWithChildren } from "react"
import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import SkeletonPrimaryTheme from "./SkeletonPrimaryTheme";

export type SkeletonPrimaryProps = PropsWithChildren<SkeletonProps>;

const SkeletonPrimary: FC<SkeletonPrimaryProps> = (props) => {
    return (
        <SkeletonPrimaryTheme>
            <Skeleton {...props} />
        </SkeletonPrimaryTheme>
    );
};

export default React.memo(SkeletonPrimary);
