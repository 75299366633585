import { FC, ReactElement, ReactNode, useMemo } from "react"
import { useDependentCollectionManager, useListFrameBase } from "@store/collectionStore";
import Table from "@components/Table/Table";
import { useRouteMatch } from "react-router";
import { DetailLinkFactory } from "@components/Table/TableRow";
import React from "react";
import { ListFrameBaseProps } from "./Types";
import { AttrMapping, AttrName, IData } from "@utils/AttrMapping";
import { useDetailFrameContext } from "@components/DetailFrame";
import { useArrayMemo } from "@utils/ReactUtils";
import { ListFrameButton } from "@components/Table/Buttons/Types";
import { MaybeArray, toArray } from "@utils/ArrayHelper";
import { useLocalization } from "@store/localizationStore";

export type DependentListFrameProps<T extends IData, Mapping extends AttrMapping = AttrMapping> = ListFrameBaseProps<Mapping> & {
    conditionParams: MaybeArray<AttrName<T>>;
}

const DependentListFrameBase = <T extends IData = IData, Mapping extends AttrMapping = AttrMapping>({
    condition,
    conditionParams,
    listFrameKey,
    orderBy,
    fulltextAttributes,
    onPageCount,
    skeletonCount,
    detailLinkFactory,
    extraButtons,
    columns,
    ...restProps
}: DependentListFrameProps<T, Mapping>) => {
    const { t } = useLocalization();
    const { attrMapping, paramNames } = restProps;
    const { getAttr } = useDetailFrameContext();
    if (onPageCount === undefined)
        onPageCount = 10;
    onPageCount ??= undefined;

    const params = useArrayMemo(toArray(conditionParams).map(p => getAttr(p)?.value));
    const { orderBy: newOrderBy, filterHiddenColumns, onSortClickCallback } = useListFrameBase({ paramNames, orderBy, columns, mapping: restProps.attrMapping });

    const {
        collectionInfo,
        fetchCount,
        condition: finalCondition,
        conditionParams: finalConditionParams
    } = useDependentCollectionManager({
        attrMapping,
        condition,
        conditionParams: params,
        attributes: [
            ...columns.map(col => col.attrName.toString()),
            ...detailLinkFactory?.additionalAttriubtes ?? []
        ],
        key: listFrameKey,
        onPage: onPageCount,
        paramNames,
        fulltextAttributes,
        orderBy: newOrderBy,
    });

    const match = useRouteMatch();

    const newDetailLinkFactory = useMemo<DetailLinkFactory | undefined>(
        () => detailLinkFactory ? data => detailLinkFactory({ data, match, t }) : undefined,
        [detailLinkFactory, match, t]);

    const extraButtonNodes = useMemo<ReactNode[] | undefined>(() => extraButtons?.map((ToolbarButton: ListFrameButton, i) => (
        <ToolbarButton
            key={i}
            condition={finalCondition}
            conditionParams={finalConditionParams}
            classname={attrMapping.objectName} />
    )), [finalCondition, finalConditionParams]);

    skeletonCount ??= 1;

    if (collectionInfo?.isLoadMore) {
        skeletonCount = fetchCount;
    }

    return (
        <Table
            {...restProps}
            columns={filterHiddenColumns}
            collectionInfo={collectionInfo}
            skeletonCount={skeletonCount}
            onPageCount={onPageCount}
            extraButtons={extraButtonNodes}
            detailLinkFactory={newDetailLinkFactory}
            onSortClick={onSortClickCallback}
            orderBy={newOrderBy} />
    );
};

(DependentListFrameBase as FC).displayName = 'DependentListFrame';
const DependentListFrame = React.memo(DependentListFrameBase) as typeof DependentListFrameBase;

export const DependentListFrameTyped = <T extends IData>() => DependentListFrame as <Mapping extends AttrMapping>(props: DependentListFrameProps<T, Mapping>) => ReactElement;

export default DependentListFrame;