import { Fragment, ReactNode, useMemo } from "react";
import { renderIf } from "./RenderUtils";

const newLineToBr = (text: string): ReactNode => {
    return text?.split('\n').map((line, index) =>
        <Fragment key={index}>
            {renderIf(index != 0, <br />)}
            {line}
        </Fragment>
    ) ?? null;
}

export const formatText = (textToFormat: string): ReactNode => {
    if (!textToFormat) {
        return '';
    }
    const tokens = textToFormat.split(/(\[[^\[\]]*?\])/g);
    if (!tokens) {
        return newLineToBr(textToFormat) ?? '';
    }
    return tokens?.map((token, index) => {
        if (/^\[.*\]$/.test(token)) {
            return <b key={index}>{newLineToBr(token.slice(1, -1))}</b>;
        }
        return newLineToBr(token);
    });
}

export const useFormatText = (textToFormat: string) => {
    return useMemo(() => formatText(textToFormat), [textToFormat]);
}
