import { AttrMapping, IData } from "@utils/AttrMapping";

export type IEnumeratorModel = IData & {
    kod: string;
    nazev: string;
}

export const createEnumeratorAttrMap = (objectName: string) => new AttrMapping<IEnumeratorModel>(objectName, [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'kod', serverName: 'Kod', type: 'string' },
    { clientName: 'nazev', serverName: 'Nazev', type: 'string' }
]);
