import React, { FC, PropsWithChildren } from "react"
import { Classes, setupCn } from "@utils/BemUtils";
import FilterManager from "@components/FilterManager";
import { FilterParamsNames, urlConstants } from "@constants/urlConstants";

const cn = setupCn('registry-zadavatelu-filter');

export type RegistrZadavateluFilterProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    paramNames?: FilterParamsNames;
}>;

const RegistryZadavateluFilter: FC<RegistrZadavateluFilterProps> = ({
    id,
    className,
    paramNames
}) => {
    return (
        <FilterManager
            id={id}
            className={cn.with(className)()}
            paramNames={paramNames ?? urlConstants.registrZadavatelu.paramNames}>
        </FilterManager>
    );
};

export default React.memo(RegistryZadavateluFilter);
