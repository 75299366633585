import { FC } from "react";
import { useLocalization } from 'store/localizationStore';
import { Classes } from "@utils/BemUtils";
import UniversalLink, { UniversalLinkProps } from "../UniversalLink/UniversalLink";

type FooterMenuProps = {
    locKey: string;
    className: Classes
}

type FooterMenuType = FC<FooterMenuProps> & {
    Link: typeof FooterLink;
}

const FooterMenu: FooterMenuType = (({
    locKey,
    children,
    className
}) => {
    const { ts } = useLocalization();
    return (
        <div className={`${className}__col`}>
            <h3 className={`${className}__headline`}>
                {ts(locKey)}
            </h3>
            <ul className="gov-list--plain">
                {children}
            </ul>
        </div >
    );
}) as FooterMenuType;

export type FooterLinkProps = UniversalLinkProps & {
    locKey: string;
    className?: string;
}

const FooterLink: FC<FooterLinkProps> = ({
    locKey,
    to,
    isExternal,
    className
}) => {
    const { d, ts } = useLocalization();
    return (
        <li className={className}>
            <UniversalLink isStandalone isInversed isExternal={isExternal} to={to} title={d(locKey)}>{ts(locKey)}</UniversalLink>
        </li>
    );
}

FooterMenu.Link = FooterLink;

export default FooterMenu;