import { isNode } from "./node-utils";

export class TitleManager {
    private _title: string;

    public get title(): string { return isNode ? this._title : document.title }
    public set title(value: string) {
        if (isNode) {
            this._title = value;
            return;
        }
        document.title = value;
    }

    constructor(title?: string) {
        this._title = title || '';
    }
}
