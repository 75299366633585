import React from "react";
import { useRouteMatch } from "react-router";
import { DetailFrame } from "@components/DetailFrame/DetailFrame";
import { urlConstants } from "@constants/urlConstants";
import { UrlParamObject } from "@utils/QueryHook";
import GroupBox from "@components/GroupBox";
import { DataLabelTyped } from "@components/DataLabel";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { useDetailDescription } from "@utils/DescriptionHook";

const detailExtPodpisLoadkey = 'detail-podpis';

const DataLabel = DataLabelTyped<IUverejnenyDokumModel>();

const DetailExtPodpis: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const id = (match?.params[urlConstants.detailExtPodpis.detailIdParam] || '');
    const route = urlConstants.detailExtPodpis.urlRouteParam;
    return (
        <DetailFrame
            condition='ID=@0'
            conditionParams={[id]}
            attrMapping={UverejnenyDokumAttrMap}
            loadKey={detailExtPodpisLoadkey}
        >
            <DetailPodpis routeParamName={route} />
        </DetailFrame>
    );
}
const DetailPodpis: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    useDetailDescription<IUverejnenyDokumModel>([
        {
            lockey: 'NEN-812592'
        },
        {
            lockey: 'NEN-812682',
            attrName: 'typDokument'
        },
        {
            lockey: 'NEN-812506',
            attrName: 'nazev'
        },
        {
            lockey: 'NEN-812748',
            attrName: 'odkazLW'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812852">
                <DataLabel lockey="NEN-812563" attrName='nazev' />
                <DataLabel lockey="NEN-812682" attrName='typDokument' />
                <DataLabel lockey="NEN-812645" attrName='datumUver' />
                <DataLabel lockey="NEN-812719" attrName='uverejnil' />
                <DataLabel lockey="NEN-812722" attrName='datumOduverejneni' />
                <DataLabel lockey="NEN-812721" attrName='oduverejnil' />
                <DataLabel lockey="NEN-812715" attrName='antivir' />
                <DataLabel lockey="NEN-812591" attrName='soubor' />
                <DataLabel lockey="NEN-812590" attrName='velikost' />
                <DataLabel lockey="NEN-812589" attrName='format' />
                <DataLabel lockey="NEN-812748" attrName='odkazLW' fillGrid />
            </GroupBox>
        </>
    );
}

export default DetailExtPodpis;