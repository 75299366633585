import { JsEvent } from "@utils/Events";
import { PromisesStacker, PromiseStackerItems } from "@utils/PromisesStacker";
import { ServiceBase } from "./ServiceBase";

export type GetDescriptionParams = string[];

export type EnumItem = {
    intValue: number;
    name: string;
    description: string;
    locKey: string;
}

export type EnumDescription = {
    enumName: string;
    items: EnumItem[];
}

export default class EnumDescriptionService extends ServiceBase {
    public static enumDescriptionsLoaded = new JsEvent<EnumDescriptionService, EnumDescription[]>();

    private static _promiseStacker?: PromisesStacker<EnumDescription>;

    public getEnumsDescriptions(...enumNames: GetDescriptionParams) {
        const stacker = EnumDescriptionService._promiseStacker ??= new PromisesStacker<EnumDescription>({});
        stacker.onTick = items => this._getEnumsDescriptions(items);
        return Promise.all(enumNames.map(enumName => stacker.request(enumName)));
    }

    public static getAllDescriptions(): EnumDescription[] {
        return EnumDescriptionService._promiseStacker?.getAllResolved() ?? [];
    }

    private async _getEnumsDescriptions(items: PromiseStackerItems<EnumDescription>): Promise<void> {
        const response = await this.requestJson<EnumDescription[]>({
            url: '/api/enum/descriptions',
            method: 'POST',
            data: [...items.keys()]
        });
        if (response.hasErrors) {
            [...items.values()].forEach((itemPromise) => {
                itemPromise.reject(response.errors);
            });
            EnumDescriptionService._promiseStacker = undefined;
            EnumDescriptionService.enumDescriptionsLoaded.trigger(this, []);
            return;
        }
        EnumDescriptionService.enumDescriptionsLoaded.trigger(this, response.value);
        const result = new Map(response.value.map(enumDesc => [enumDesc.enumName, enumDesc]));
        [...items.entries()].forEach(([enumName, itemPromise]) => {
            const enumDesc = result.get(enumName);
            if (enumDesc) {
                itemPromise.resolve(enumDesc);
                return;
            }
            itemPromise.resolve();
        });
    }
}