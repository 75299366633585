import React, { FC, PropsWithChildren } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";
import Button from "@components/Button/Button";

const cn = setupCn('filter-apply-button');

export type FilterApplyButtonProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const FilterApplyButton: FC<FilterApplyButtonProps> = ({
    id,
    className,
    onClick,
    onMouseDown
}) => {
    const { d, ts } = useLocalization();
    id = `${id}__search`
    return (
        <Button
            tabIndex={-1}
            className={cn.with(className)()}
            onMouseDown={onMouseDown}
            labelled-by={id}
            hasIconOnly
            title={d('NEN-765498')}
            onClick={onClick}>
            <span id={id} className="u-sr-only gov-button__label">
                {ts('NEN-765498', { isPrimaryTheme: true })}
            </span>
        </Button>
    );
};

FilterApplyButton.displayName = 'FilterApplyButton';

export default React.memo(FilterApplyButton);
