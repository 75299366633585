import React from "react";
import { useBreadcrumb } from "@store/breadcrumbStore";
import { usePageTitle } from "@utils/PageTitleHook";
import { useLocation } from "react-router";
import { normalizePath } from "@utils/PathUtils";
import classNames from "classnames";
import { urlConstants, useConstants } from "@constants/urlConstants";
import Skeleton from "react-loading-skeleton";

export default React.memo(() => {
    const [, , , lastItem] = useBreadcrumb();
    const location = useLocation();
    const { getUrl } = useConstants();
    usePageTitle(lastItem?.title);

    const pathName = normalizePath(location.pathname, { startSlash: true });
    const hideHeader = pathName.includes(urlConstants.detailInformace.pureUrl)
        || pathName == getUrl('rozcestnikRegistrace')
        || pathName == getUrl('prihlaseni');

    return (
        <h1 className={classNames({ 'u-sr-only': hideHeader })}>
            {lastItem?.title || <Skeleton width={160} />}
        </h1>
    );
});