import React from "react"
import { useLocalization } from "@store/localizationStore";
import { ListFrameButtonProps } from "./Types";
import ButtonLink from "@components/Button/ButtonLink";

import './DownloadAllButton.scss';

export const DownloadAllButton: React.FC<ListFrameButtonProps> = ({ classname, condition, conditionParams }) => {
    const { ts } = useLocalization();

    return (
        <ButtonLink className="download-all" to={'/file/getall?prms=' + btoa(JSON.stringify({
            classname: classname,
            condition: condition || '',
            conditionParams: JSON.stringify(conditionParams)
        }))} target='_blank' rel='nofollow' isOutlined>
            {ts('NEN-812847')}
        </ButtonLink>
    );
}

export const downloadButtonOnly = [DownloadAllButton];
