import React, { FC } from "react";
import { Classes, setupCn } from "@utils/BemUtils";

const cn = setupCn('gov-button');

export type ButtonSize = 'small' | 'normal' | 'large';

export type ButtonBaseProps = React.PropsWithChildren<{
    className?: Classes;
    size?: ButtonSize;
    isOutlined?: boolean;
    isFlat?: boolean;
    hasIconOnly?: boolean;
    isInversed?: boolean;
    isSecondary?: boolean;
}>

const ButtonBase: FC<ButtonBaseProps & {
    element: React.FC<any>;
    disabled?: boolean;
}> = ({ className, size, isOutlined, isInversed, element: Element, isFlat, hasIconOnly, isSecondary, ...restProps }) => {
    const sizeModifier = (!size || size == 'normal') ? null : `--${size}`;
    const role = isSecondary ? '--secondary' : '--primary'
    const styleModifier = isFlat ? null : `${role}${isOutlined ? '-outlined' : ''}`;

    return (
        <Element
            className={cn.with(className).main(sizeModifier, styleModifier, {
                '--disabled': restProps.disabled,
                '--inversed': isInversed,
                '--icon-only': hasIconOnly
            })}
            {...restProps} />
    )
}

export default React.memo(ButtonBase);
