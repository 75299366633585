import { ArrayValues, EnumFromKeys, WithPrefix } from "@utils/TypeUtils";

const breakpoints = ['portable', 'tablet-landscape', 'tablet', 'mobile-landscape', 'mobile', 'palm'] as const;
const breakpointsPrefixes = ['u-hide--', 'u-hide--from-'] as const;

export type Breakpoints = ArrayValues<typeof breakpoints>;

const displays = ['block', 'flex'] as const;

type ResponsibilityClasses =
    WithPrefix<typeof breakpoints, ArrayValues<typeof breakpointsPrefixes>> |
    WithPrefix<typeof displays, 'u-display-'>;

type ResponsibilityClassesEnum = EnumFromKeys<ResponsibilityClasses>;

const responsibility: Record<string, string> = {};
breakpointsPrefixes.forEach(prefix => breakpoints.forEach(breakpoint => {
    const className = `${prefix}${breakpoint}`;
    responsibility[className] = className;
}));

displays.forEach(display => {
    const className = `u-display-${display}`;
    responsibility[className] = className;
});

export default (responsibility as ResponsibilityClassesEnum);
