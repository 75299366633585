import React, { FC, PropsWithChildren, useMemo } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { FilterManagerChangeEventHandler } from "@components/FilterManager";
import Checks, { CheckOrder, ChecksColumns, checksColumns, ChecksItem } from "@components/Checks";
import { useEnumDescriptions } from "@store/enumDescriptionsStore";
import { EnumItem } from "services/EnumDescriptionService";
import { transposeArray } from "@utils/ArrayHelper";

const cn = setupCn('enum-checks');

export type EnumOrder<T = string> = { [columnsCount in ChecksColumns]?: T[] }

export const createTransposeOrder = <T extends unknown>(...oneColumnOrder: T[]) => ({
    oneColumn: oneColumnOrder,
    twoColumns: transposeArray(oneColumnOrder, 2),
    threeColumns: transposeArray(oneColumnOrder, 3)
});

export type EnumChecksProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    value?: string;
    enumName: string;
    oneColumn?: boolean;
    onChange?: FilterManagerChangeEventHandler;
    name?: string;
    hiddenValues?: string[];
    order?: EnumOrder;
}>;

const EnumChecks: FC<EnumChecksProps> = ({
    id,
    className,
    name,
    oneColumn,
    value,
    enumName,
    order,
    hiddenValues,
    onChange
}) => {
    const enumDesc = useEnumDescriptions().getEnumDescription(enumName);

    const hiddenValuesMemo = useMemo(
        () => (hiddenValues ?? []).map(value => value?.toLowerCase()),
        [hiddenValues?.join(',')]);

    const items = useMemo<ChecksItem[]>(
        () => {
            let filteredItems = enumDesc?.items.filter(item => !hiddenValuesMemo.includes(item.name?.toLowerCase()));
            if (!filteredItems) {
                return [];
            }
            const actualOrder = order || createTransposeOrder(...filteredItems.map(item => item.name));
            const getItemOrder = (item: EnumItem): CheckOrder => {
                const result: CheckOrder = {};
                checksColumns.forEach(columnsCount => {
                    const index = actualOrder?.[columnsCount]?.indexOf(item.name);
                    if (index != null && index > -1) {
                        result[columnsCount] = index;
                    }
                });
                return result;
            }
            return filteredItems.map(item => ({
                value: item.name,
                locKey: item.locKey,
                order: getItemOrder(item)
            }))
        },
        [enumDesc, hiddenValuesMemo, order]);

    return (
        <Checks
            id={id}
            className={cn.with(className)()}
            onChange={onChange}
            name={name}
            value={value}
            oneColumn={oneColumn}
            items={items} />
    );
};

EnumChecks.displayName = 'EnumChecks';

export default React.memo(EnumChecks);
