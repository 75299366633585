import React, { FC, PropsWithChildren, ReactNode } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import Modal, { ModalProps } from "@components/Modal/Modal";
import { useCryptoService } from "@store/nodeContextStore";
import { useAsyncValueWithState } from "@utils/ReactUtils";
import { renderIf } from "@utils/RenderUtils";
import CryptoDetail from "crypto/CryptoDetail/CryptoDetail";
import { useCurrentLanguage, useLocalization } from "@store/localizationStore";
import Loader from "@components/Loader/Loader";
import CryptoDetailSummaryBadge from "crypto/CryptoDetailSummaryBadge";
import Note from "@components/Note/Note";
import { useNanoid } from "@utils/UseId";

const cn = setupCn('document-signature-modal');

export type SignatureModalProps = PropsWithChildren<{
    className?: Classes;
    objectName: string;
    id: string;
    attrName: string;
    fileName?: ReactNode;
}> & Pick<ModalProps, 'onClose'>;

const DocumentSignatureModal: FC<SignatureModalProps> = ({
    className,
    objectName,
    id,
    attrName,
    fileName,
    ...modalProps
}) => {
    const { ts } = useLocalization();
    const language = useCurrentLanguage();
    const cryptoService = useCryptoService();

    const { controlId } = useNanoid();

    const {
        isLoaded,
        value: signatureInfo,
    } = useAsyncValueWithState(() => cryptoService.getDocumentSignatureInfo({ objectName, id, attrName }));

    const signatureDownloadLink = signatureInfo?.signatureInsideDocument ? undefined :
        `/api/crypto/getDocumentSignature?objectName=${objectName}&id=${id}&attrName=${attrName}&language=${language}`;

    return (
        <Modal {...modalProps} noContentOffset={isLoaded} headerText={<>
            {ts('NEN-808325')}
            {renderIf(isLoaded, <div><CryptoDetailSummaryBadge signatureInfo={signatureInfo} /></div>)}
        </>}>
            <div className={cn.with(className).main()}>
                {renderIf(!isLoaded,
                    <Loader />
                )}
                {renderIf(isLoaded && signatureInfo, signatureInfo =>
                    <CryptoDetail
                        id={controlId}
                        cryptoInfo={{ signatureInfo }}
                        signatureDownloadLink={signatureDownloadLink}
                        signatureDataDescription={fileName}
                        withoutSummary
                        withModalPaddig />
                )}
                {renderIf(isLoaded && !signatureInfo, () =>
                    <Note>{ts('NEN-808326')}</Note>
                )}
            </div>
        </Modal>
    );
};

DocumentSignatureModal.displayName = 'DocumentSignatureModal';

export default React.memo(DocumentSignatureModal);
