import React, { FC, PropsWithChildren } from "react"
import { Classes } from "@utils/BemUtils";
import FilterManager from "@components/FilterManager";
import { FilterParamsNames } from "@constants/urlConstants";

export type ProfilyZadavateluFilterProps = PropsWithChildren<{
    className?: Classes;
    paramNames: FilterParamsNames;
}>;

const ProfilyZadavateluFilter: FC<ProfilyZadavateluFilterProps> = ({
    className,
    paramNames
}) => {
    return (
        <FilterManager
            id='profily-zadavatelu-filter'
            className={className}
            paramNames={paramNames}>
        </FilterManager>
    );
};

export default React.memo(ProfilyZadavateluFilter);
