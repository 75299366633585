import { AttrMapping, IData } from "@utils/AttrMapping";

export type ITrzisteHist = IData & {
    nazev: string;
    adresa: string;
    adresaProfilu: string;
}

export const TrzisteHistAttrMap = new AttrMapping<ITrzisteHist>('Trziste_hist', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'nazev', serverName: 'Nazev', type: 'string' },
    { clientName: 'adresa', serverName: 'Internet_adresa', type: 'string' },
    { clientName: 'adresaProfilu', serverName: 'Profil_zadavatele', type: 'string' },
]);
