import React from "react";
import { Component } from "react";
import { match, matchPath, Route, RouteComponentProps, RouteProps } from "react-router";
import { UrlParamObject } from "../../utils/QueryHook";
import { BreadcrumbContext, WithBreadcrumb } from "./withBreadcrumb";

type BreadCrumbRouteProps = RouteProps & {
    title?: string | ((match: match<UrlParamObject> | null) => string | null | undefined);
    hideBreadcrumbHome?: boolean;
    computedMatch?: match;
    brComponent?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
};

export class BreadcrumbRoute extends Component<BreadCrumbRouteProps> {

    static contextType = BreadcrumbContext;

    public render() {
        const {
            title,
            brComponent,
            hideBreadcrumbHome,
            ...routeProps
        } = this.props;
        const withBreadcrumb = brComponent != null;
        if (brComponent) {
            routeProps.component = brComponent;
        }
        const match = this.props.computedMatch ?? (routeProps.location?.pathname ? matchPath(routeProps.location.pathname, routeProps) : null);
        const breadCrumbItems = [...(this.context ?? [])];
        if (title) {
            if (title instanceof Function) {
                breadCrumbItems.push({ href: match?.url, title: title(match) });
            } else {
                breadCrumbItems.push({ href: match?.url, title });
            }
        }
        const route = <Route {...routeProps} />;
        return (
            <BreadcrumbContext.Provider value={breadCrumbItems}>
                {
                    withBreadcrumb ?
                        <WithBreadcrumb prependHome={!hideBreadcrumbHome} >
                            {route}
                        </WithBreadcrumb> :
                        route
                }
            </BreadcrumbContext.Provider>
        );
    }
}
