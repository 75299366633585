import React from 'react'
import LargeWidget from '@components/Widgets/LargeWidget';
import { useAppSettings } from '@store/nodeContextStore';
import { TextyKRegistracimZadavatel } from '@pages/registrace/TextKRegistracim';
import { useLocalization } from '@store/localizationStore';
import { useDescription } from '@utils/DescriptionHook';

const RegistraceSdruzeni: React.FC = () => {
    const { t } = useLocalization();
    const { getAllWidgetUrl } = useAppSettings();
    useDescription(t('NEN-789458'));

    return (
        <>
            <TextyKRegistracimZadavatel />
            <LargeWidget src={getAllWidgetUrl('registraceSdruzeni')} />
        </>
    )
};

export default RegistraceSdruzeni;