import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import DataRowsService from "services/DataRowsService";
import EnumDescriptionService from "services/EnumDescriptionService";
import LocalizationService, { Language } from "services/LocalizationService";
import { ApplicationState } from "store";
import { CookieManager as CookiesManager } from "@utils/CookiesUtils";
import { TaskManager } from "@utils/node-utils";
import { TitleManager } from "@utils/TitleManager";
import EvaluateConditionsService from "services/EvaluateConditionsService";
import CompatibilityTestService from "services/CompatibilityTestService";
import CryptoService from "services/CryptoService";
import InstanceService from "services/InstanceService";
import NENService from "@services/NENService";
import PasswordResetService from "@services/PasswordResetService";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import HeartBeatService from "@services/HeartBeatService";
import { DescriptionManager } from "@utils/DescriptionManager";
import WidgetService from "@services/WidgetService";
import { SwitchByLanguage, useSwitchByLanguage } from "@utils/LanguageUtils";

export const name = 'nodeContext';

export type NodeContextHttp = {
    host?: string;
    cookies: CookiesManager;
    getLanguage: () => Language;
}

export type PrivatePortal = {
    url: string | null;
    loginIframeUrl: string | null;
}

//Mirror of server side AppSettings
export type AppSettings = {
    heartBeatTimeout: number;
    isTestEnvironment: boolean;
    isDevEnvironment: boolean;
    outageVersion: OutageVersionSettings;
    criticalInformation: CriticalInformationSettings;
    anotherEnvironment?: AnotherEnvironmentSettings;
    externalWidgets: ExternalWidgetsSettings;
    appInsightsKey?: string;
    serviceDesk: ServiceDeskSettings;
    registration: RegistrationSettings;
    loginCaption: string;
    manualsSettings: ManualsSettings
    informationForUsersUrl: LocalizedSettings<string>;
    faq: LocalizedSettings<string>;
}

type AnotherEnvironmentSettings = {
    loginLocKey: string;
    linkLocKey: string;
    url: string;
}

type OutageVersionSettings = {
    text: LocalizedSettings<string>;
    enabled: boolean;
}
type CriticalInformationSettings = {
    texts: Array<LocalizedSettings<CriticalInformationText>>;
    enabled: boolean;
}
export type CriticalInformationText = {
    header: string;
    body: string;
}

type ExternalWidgetsSettings = {
    loaderTimeout: number;
    mainUrl: string;
    detailUrl: DetailUrlSettings;
    alerts: LocalizedSettings<ExternalWidgetUrlSettings>;
    skoleni: LocalizedSettings<ExternalWidgetUrlSettings>;
    skoleniZzvz: LocalizedSettings<ExternalWidgetUrlSettings>;
    videa: LocalizedSettings<ExternalWidgetUrlSettings>;
    provozniInformace: LocalizedSettings<ExternalWidgetUrlSettings>;
    provozniInformacePrihlaseni: LocalizedSettings<ExternalWidgetUrlSettings>;
    provozniRad: LocalizedSettings<ExternalWidgetUrlSettings>;
    registraceDodavatel: LocalizedSettings<ExternalWidgetUrlSettings>;
    registraceZadavatel: LocalizedSettings<ExternalWidgetUrlSettings>;
    registraceSdruzeni: LocalizedSettings<ExternalWidgetUrlSettings>;
}

type ExternalWidgetUrlSettings = {
    smallWidgetUrl: string;
    allWidgetUrl: string;
}

type LocalizedSettings<T = any> = SwitchByLanguage<T>;

type ServiceDeskSettings = {
    url: string;
}

type DetailUrlSettings = {
    baseUrl: string
    languageRegex: string
}

type WidgetName = keyof Omit<ExternalWidgetsSettings, 'loaderTimeout' | 'mainUrl' | 'detailUrl'>;

type RegistrationSettings = {
    additionalLinks: LocalizedSettings<AdditionalLinks>;
    enabled: boolean;
}

type ManualsSettings = {
    showAllUrl: LocalizedSettings<string>;
    manuals: LocalizedSettings<Manual>[];
}

type Manual = {
    caption: string;
    description: string;
    url: string;
}

type AdditionalLinks = {
    faqGetDigitalSignature: string;
    documentsForDownload: string;
    operatingRules: string;
}

export type NodeContextState = {
    http: NodeContextHttp;
    titleManager: TitleManager;
    descriptionManager: DescriptionManager;
    localizationService: LocalizationService;
    taskManager: TaskManager;
    enumDescriptionService: EnumDescriptionService;
    dataRowsService: DataRowsService;
    compatibilityTestService: CompatibilityTestService;
    evaluateConditionsService: EvaluateConditionsService;
    cryptoService: CryptoService;
    instanceService: InstanceService;
    nenService: NENService;
    passwordResetService: PasswordResetService;
    heartBeatService: HeartBeatService;
    widgetService: WidgetService;
    privatePortal: PrivatePortal;
    appSettings: AppSettings;
}

export const slice = createSlice({
    name,
    initialState: {} as NodeContextState,
    reducers: {
        init(state, action: PayloadAction<NodeContextState>) {
            return action.payload;
        }
    }
});

export const { reducer } = slice;

export const getNodeContext = (state: ApplicationState) => state[name];
export const getLocalizationService = (state: ApplicationState) => getNodeContext(state).localizationService;
export const getTitleManager = (state: ApplicationState) => getNodeContext(state).titleManager;
export const getDescriptionManager = (state: ApplicationState) => getNodeContext(state).descriptionManager;
export const getTaskManager = (state: ApplicationState) => getNodeContext(state).taskManager;
export const getNodeHttp = (state: ApplicationState) => getNodeContext(state).http;
export const getCookieManager = (state: ApplicationState) => getNodeHttp(state).cookies;
export const getEnumDescriptionService = (state: ApplicationState) => getNodeContext(state).enumDescriptionService;
export const getDataRowsService = (state: ApplicationState) => getNodeContext(state).dataRowsService;
export const getEvaluateConditionsService = (state: ApplicationState) => getNodeContext(state).evaluateConditionsService;
export const getCompatibilityTestService = (state: ApplicationState) => getNodeContext(state).compatibilityTestService;
export const getCryptoService = (state: ApplicationState) => getNodeContext(state).cryptoService;
export const getInstanceService = (state: ApplicationState) => getNodeContext(state).instanceService;
export const getNENService = (state: ApplicationState) => getNodeContext(state).nenService;
export const getPasswordResetService = (state: ApplicationState) => getNodeContext(state).passwordResetService;
export const getHeartBeatService = (state: ApplicationState) => getNodeContext(state).heartBeatService;
export const getWidgetService = (state: ApplicationState) => getNodeContext(state).widgetService;
export const getPrivatePortal = (state: ApplicationState) => getNodeContext(state).privatePortal;
export const getAppSettings = (state: ApplicationState) => getNodeContext(state).appSettings;

export const useNodeContext = () => useSelector(getNodeContext);
export const useLocalizationService = () => useSelector(getLocalizationService);
export const useNodeHttp = () => useSelector(getNodeHttp);
export const useCookieManager = () => useSelector(getCookieManager);
export const useTitleManager = () => useSelector(getTitleManager);
export const useDescriptionManager = () => useSelector(getDescriptionManager);
export const useTaskManager = () => useSelector(getTaskManager);
export const useEnumDescriptionService = () => useSelector(getEnumDescriptionService);
export const useDataRowsService = () => useSelector(getDataRowsService);
export const useCompatibilityTestService = () => useSelector(getCompatibilityTestService);
export const useCryptoService = () => useSelector(getCryptoService);
export const useInstanceService = () => useSelector(getInstanceService);
export const useNENService = () => useSelector(getNENService);
export const useWidgetService = () => useSelector(getWidgetService);
export const usePrivatePortal = () => useSelector(getPrivatePortal);
export const useAppSettings = () => {
    const appSettings = useSelector(getAppSettings);
    const { switchByLanguage } = useSwitchByLanguage();

    const getWidget = (name: WidgetName) => switchByLanguage(appSettings?.externalWidgets?.[name]);
    const getSmallWidgetUrl = (name: WidgetName) => appSettings?.externalWidgets?.mainUrl + getWidget(name)?.smallWidgetUrl;
    const getAllWidgetUrl = (name: WidgetName) => appSettings?.externalWidgets?.mainUrl + getWidget(name)?.allWidgetUrl;
    const getDetailWidgetUrl = () => appSettings?.externalWidgets?.mainUrl + appSettings?.externalWidgets?.detailUrl?.baseUrl;
    const updateDetailWidgetLanguage = (query: string) => query.replace(new RegExp(appSettings?.externalWidgets?.detailUrl?.languageRegex), `$1${switchByLanguage({ cz: 'cs', en: 'en' })}`);
    const getOutageVersion = () => ({ enabled: appSettings?.outageVersion?.enabled, text: switchByLanguage(appSettings?.outageVersion?.text) });
    const getCriticalInformation = () => ({ enabled: appSettings?.criticalInformation?.enabled, texts: appSettings?.criticalInformation?.texts?.map(switchByLanguage) });
    const getManuals = () => ({
        showAllUrl: switchByLanguage(appSettings?.manualsSettings?.showAllUrl),
        manuals: appSettings?.manualsSettings?.manuals.map(switchByLanguage)
    });
    const getInformationForUsersUrl = () => switchByLanguage(appSettings?.informationForUsersUrl);
    const getFaqUrl = () => switchByLanguage(appSettings?.faq);

    return { appSettings, getCriticalInformation, getSmallWidgetUrl, getAllWidgetUrl, getDetailWidgetUrl, getOutageVersion, updateDetailWidgetLanguage, getManuals, getInformationForUsersUrl, getFaqUrl };
}

export const useWidgetDetailRedirect = () => {
    const { appSettings, getDetailWidgetUrl } = useAppSettings();
    const [url, setUrl] = useState<{ url: string, source: MessageEventSource | null }>({ url: '', source: null });

    const mainURL = useMemo(() => appSettings?.externalWidgets?.mainUrl,
        [appSettings?.externalWidgets?.mainUrl])

    const reset = useCallback(() => {
        setUrl({ url: '', source: null });
    }, [setUrl]);

    const onMessage = useCallback(async (event: MessageEvent) => {
        if (event?.origin != (new URL(mainURL)).origin
            || !event?.data
            || typeof event.data !== 'string'
            || !/^(\?id=)|(https?)/.test(event.data))
            return;

        const url = event.data.replace(getDetailWidgetUrl(), '')
        setUrl({ url: url, source: event.source });
    }, [setUrl, mainURL, getDetailWidgetUrl]);

    useLayoutEffect(() => {
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [onMessage]);

    return { url, reset };
}

export const useHeartBeat = () => {
    const heartBeatService = useSelector(getHeartBeatService);
    const { appSettings } = useAppSettings();
    const beat = useCallback(async () => {
        setTimeout(() => requestAnimationFrame(async () => {
            const result = await heartBeatService.beat();

            if (result.hasErrors) {
                //Token corrupted/expired
                window.location.reload();
                return;
            }

            beat();
        }), (appSettings?.heartBeatTimeout || 15) * 60 * 1000);
    }, [heartBeatService, appSettings])

    useEffect(() => {
        beat();
    }, [beat]);
}

export const createPrivatePortal = (privatePortalPath?: string, publicPortalPath?: string): PrivatePortal => {
    publicPortalPath ??= '';
    if (!privatePortalPath) {
        return {
            url: null,
            loginIframeUrl: null,
        }
    }

    return {
        url: `/${privatePortalPath}/`,
        loginIframeUrl: `${privatePortalPath}/login-frame`
    };
};
