import React, { PropsWithChildren, FC } from "react";
import { AttrName, IData } from "@utils/AttrMapping";
import { useDetailFrameContext } from "@components/DetailFrame";
import GovLink from "@components/GovLink";
import DataLabelComponent, { DataLabelComponentProps } from "./DataLabelComponent";
import { useLocalization } from "@store/localizationStore";
import { useAttrConverter } from "@utils/AttrConverter";
import Skeleton from "react-loading-skeleton";
import { Classes } from "@utils/BemUtils";

type DataLabelProps<T extends IData> = PropsWithChildren<{
    className?: Classes;
    showIf?: string | boolean;
    detailLinkShowIf?: string | boolean;
    attrName: AttrName<T>;
    lockey: string;
    detailLink?: string;
}> & Pick<DataLabelComponentProps, 'valueTitle' | 'fillGrid' | 'rawText'>;

type DataLabelType<T extends IData> = FC<DataLabelProps<T>>;

const DataLabel: DataLabelType<any> = ({
    className,
    showIf,
    detailLinkShowIf,
    attrName,
    lockey,
    children,
    detailLink,
    fillGrid,
    rawText,
    valueTitle,
}) => {
    const { getAttr, evaluateCondition } = useDetailFrameContext();

    const evalCond = (cond: string | boolean | undefined) =>
        cond === undefined || cond === true || (typeof cond === 'string' && evaluateCondition(cond))

    const {
        isFetching = true,
        value,
        attrInfo,
        data: objectData,
        objectName
    } = getAttr(attrName);

    const { d, ts } = useLocalization();

    const { nodeAttrValue, stringAttrValue } = useAttrConverter().withFormat({
        format: attrInfo?.defaultFormat ?? attrInfo?.type ?? 'string',
        formatParam: attrInfo?.typeParam,
        objectName,
        serverAttrName: attrInfo?.serverName,
    });

    if (evalCond(showIf) === false) {
        return null;
    }

    let content = children;
    if (children == null) {
        const data = objectData ?? { id: getAttr('id')?.value ?? 0 };
        content = isFetching ? <Skeleton /> : nodeAttrValue({ value, data });
        valueTitle ??= isFetching ? undefined : stringAttrValue({ value, data });
    }

    if (!content) {
        return null;
    }

    const showLink = detailLink && evalCond(detailLinkShowIf) === true;
    const detailElement = value != null && showLink ?
        <GovLink
            to={{ pathname: detailLink }}>
            {content}
        </GovLink>
        : null;

    return (
        <DataLabelComponent
            className={className}
            caption={ts(lockey)}
            captionTitle={d(lockey)}
            valueTitle={valueTitle}
            fillGrid={fillGrid}
            notWrapValue={!!detailElement}
            rawText={rawText}>
            {detailElement || content}
        </DataLabelComponent>
    );
}

export const DataLabelTyped = <T extends IData>() => DataLabel as DataLabelType<T>;

export default React.memo(DataLabel);