import { AsyncLock, Releaser } from "./AsyncLock";
import { DeferredStateful, PromiseUtils } from "./PromiseUtils";

export type EventCallback<TSender = any, TEventData = any> = (sender: TSender, prm: TEventData) => void;
export type AwaitableStandardEventCallback<TSender, TEventData> = (cancellableEventArgs: CancellableEventArgs, sender: TSender, prm: TEventData) => Promise<void>;

interface IListenerData<TSender, TEventData> {
    listener: EventCallback<TSender, TEventData>;
    oneTimeOnly?: boolean;
    limitingPredicate?: () => boolean;
}

/**
 * Třída reprezentuje obecnou událost a umožňuje přidávání a odebírání listenerů a její vyvolávání
 * @includeToDoc
 */
export class JsEvent<TSender, TEventData> {
    private _listeners: IListenerData<TSender, TEventData>[] = [];

    /**
     * Informace, zda je událost aktivní a volají se listenery
     */
    public enabled: boolean = true;

    /**
     * Zaregistruje listener události
     * @param listener Naslouchající funkce
     * @param oneTimeOnly Zda má listener zavolán pouze jednou a pak uvolněn
     * @param asFirst Umožňuje zařadit listener na první místo
     */
    public add(listener: EventCallback<TSender, TEventData>, oneTimeOnly?: boolean, asFirst?: boolean): void {
        if (asFirst)
            this._listeners.unshift({ listener: listener, oneTimeOnly: oneTimeOnly });
        else
            this._listeners.push({ listener: listener, oneTimeOnly: oneTimeOnly });
    }

    /**
     * Zaregistruje listener události a při splnění predikátu je tento listener uvolněn
     * @param listener Naslouchající funkce
     * @param limitingPredicate Predikát, při jehož splnění dojde k uvolnění listeneru
     */
    public addPredicateLimitedListener(listener: EventCallback<TSender, TEventData>, limitingPredicate: () => boolean) {
        this._listeners.push({ listener: listener, limitingPredicate: limitingPredicate });
    }

    /**
     * Odstraní předaný listener, takže už nebude dostávát informace o události
     * @param listener Funkce k odregistrování
     */
    public remove(listener: EventCallback<TSender, TEventData>): void {
        if (typeof listener === 'function') {
            for (var i = 0, l = this._listeners.length; i < l; i++) {
                if (this._listeners[i].listener === listener) {
                    this._listeners.splice(i, 1);
                    break;
                }
            }
        }
    }

    /**
     * Odstraní všechny listenery
     */
    public removeAll(): void {
        this._listeners = [];
    }

    /**
     * Vyvolá událost
     * @param sender Objekt, na kterém byla událost vyvolaná
     * @param arg Data události předávaná do listenerů
     */
    public trigger(sender: TSender, arg: TEventData): void {
        if (!this.enabled)
            return;

        const listeners = [...this._listeners];
        const listenersForRemoval = [];
        for (const listenerData of listeners) {
            listenerData.listener.call(null, sender, arg);

            if (listenerData.oneTimeOnly || (listenerData.limitingPredicate && listenerData.limitingPredicate()))
                listenersForRemoval.push(listenerData.listener);
        }

        listenersForRemoval.forEach(l => this.remove(l));
    }

    /**
     * Zjistí, zda je předaný listener už zaregistrovaný
     * @param listener Hledaná funkce
     */
    public containsListener(listener: EventCallback<TSender, TEventData>): boolean {
        return this._listeners.some(ld => ld.listener === listener);
    }

    /**
     * Přidá do listenerů aktuální instance Eventu listenery z Eventu stejného typu předaného do parametru
     * @param eventToMerge Událost, jejíž listenery se mají přidat do této instance Eventu
     * @param removeListenersFromSourceEvent (Nepovinný) Určuje, zda se mají listenery ze zdrojového eventu rovnou odstranit. Výchozí = false
     */
    public mergeWith(eventToMerge: JsEvent<TSender, TEventData>, removeListenersFromSourceEvent?: boolean) {
        for (let listenerData of eventToMerge._listeners) {
            if (!this.containsListener(listenerData.listener)) {
                this.add(listenerData.listener, listenerData.oneTimeOnly);
            }
            if (removeListenersFromSourceEvent)
                eventToMerge.remove(listenerData.listener);
        }

    }

    /**
     * Počká na první vyvolání události
     * @param timeout Pokud je nastavený timeout (v ms) a událost není do té doby vyvolaná, tak promis končí chybou
     */
    public waitForEventTrigger(timeout?: number): Promise<void> {
        return new Promise((resolve, reject) => {
            let handle: number;

            this.add(() => {
                if (handle)
                    clearTimeout(handle);
                resolve();
            }, true);
            //let handler:TimerHandler =
            if (timeout)
                handle = self.setTimeout(() => reject(new Error(`Timeout after ${timeout}ms`)), timeout); //`
        });
    }
}

/**
 * Třída reprezentuje stavovou událost. První vyvolání události ji nastaví aktivní stav. To lze provést pouze jednou.
 * Každé přidání listeneru v aktivním stavu ho automaticky hned vyvolá.
 * @includeToDoc
 */
export class StatefulEvent<TSender, TEventData> extends JsEvent<TSender, TEventData> {
    private _active: boolean = false;
    private _sender?: TSender;
    private _eventData?: TEventData;

    /**
     * Zda je událost aktivní (má aktivní stav) a tedy zda už byla spuštěna
     */
    public get active(): boolean {
        return this._active;
    }

    /**
     * Vyvolá událost a tím změní stav této instance na aktivní. Všechny další přidání listenerů automaticky vyvolávají událost.
     * Trigger (a tedy nastavení stavu) lze volat pouze jednou!
     * @param sender Objekt, na kterém byla událost vyvolaná
     * @param arg Data události předávaná do listenerů
     * @throws Vyhazuje Error pokud je už aktivní
     */
    public trigger(sender: TSender, arg: TEventData): void {
        if (!this.enabled)
            return;

        if (this.active)
            throw new Error("Stateful event already triggered. Multiple state changes forbidden.");

        this._sender = sender;
        if (this._sender === undefined)
            throw new Error("Stateful event triggered without sender parameter.");

        this._eventData = arg;
        this._active = true;

        super.trigger(sender, arg);
    }

    /**
     * Zaregistruje listener události.
     * Pokud už byla událost aktivována, tak ihned listener spustí!
     * @param listener Naslouchající funkce
     * @param oneTimeOnly Zda má listener zavolán pouze jednou a pak uvolněn - irelevantní, vždy je volán pouze jednou
     */
    public add(listener: EventCallback<TSender, TEventData>, oneTimeOnly?: boolean): void {
        super.add(listener, oneTimeOnly);

        if (!this.enabled || !this.active)
            return;

        if (this._sender === undefined)
            throw new Error("Stateful event triggered without sender parameter.");

        listener.call(null, this._sender, this._eventData!); // TEventData může mít i typ undefined, takže nelze provést runtime kontrolu
    }
}
/**
 * Rozhraní obecného listeneru, u kterého nám nezáleží na předaných argumentech ani na návratové hodnotě
 * @includeToDoc
 */
export interface IListener {
    (...args: any[]): any;
}

/**
 * Asynchronní událost.
 * Listenery berou jako parametr CancellableEventArgs, kterým je možné indikovat zrušení - negativní výsledek triggeru.
 * Trigger vrací promise - počká se na doběhnutí všech listenerů a vrací se true, pokud nebyl event zrušen.
 * Při opětovném volání triggeru z jiného místa, před doběhnutím předchozího, se na doběhnutí předchozího počká.
 * @includeToDoc
 */
export class AwaitableStandardEvent<TSender, TEventData> {

    /**
     * Informace, zda je událost aktivní a volají se listenery
     */
    public enabled: boolean = true;

    private _listeners: Array<AwaitableStandardEventCallback<TSender, TEventData>> = [];
    private _cancellableEventArgs: CancellableEventArgs = new CancellableEventArgs();

    private _triggeredProcessingLock: AsyncLock = new AsyncLock();
    private _lockPromise: Promise<Releaser> | null = null;
    private _locked = false;

    /**
     * Zaregistruje listener události
     * @param listener Naslouchající funkce
     */
    public add(listener: AwaitableStandardEventCallback<TSender, TEventData>): void {
        this._listeners.push(listener);
    }

    /**
     * Odstraní předaný listener, takže už nebude dostávát informace o události
     * @param listener Funkce k odregistrování
     */
    public remove(listener: AwaitableStandardEventCallback<TSender, TEventData>): void {
        if (typeof listener === 'function') {
            for (let i = 0, l = this._listeners.length; i < l; i++) {
                if (this._listeners[i] === listener) {
                    this._listeners.splice(i, 1);
                    break;
                }
            }
        }
    }

    /**
     * Odstraní všechny listenery
     */
    public removeAll(): void {
        this._listeners = [];
    }

    /**
     * Vyvolá událost
     * @param sender Objekt, na kterém byla událost vyvolaná
     * @param arg Data události předávaná do listenerů
     */
    public async trigger(sender: TSender, arg: TEventData): Promise<boolean> {
        if (!this.enabled)
            return false;

        if (!this._locked) {
            //console.log("Locking...");
            this._lockPromise = this._triggeredProcessingLock.lock(); // Zamče event, aby doběhly všechny listenery před zpracováním dalšího triggru eventu
            this._locked = true;
        } else {
            //console.log("Waiting for previous trigger to finish.");
            if (this._lockPromise)
                await this._lockPromise;
            //console.log("Continuing...");
        }

        this._cancellableEventArgs = new CancellableEventArgs();
        const listeners = this._listeners.slice(0);
        const promises: (Promise<void>)[] = listeners.map(listener => listener(this._cancellableEventArgs, sender, arg));
        await Promise.all(promises); // Počká na doběhnutí všech asychnronních listenerů

        if (this._locked) {
            //console.log("Unlocking...");
            if (this._lockPromise)
                (await this._lockPromise).release();
            this._locked = false;
        }

        return this._cancellableEventArgs.cancelEvent;
    }

    /**
     * Zjistí, zda je předaný listener už zaregistrovaný
     * @param listener Hledaná funkce
     */
    public containsListener(listener: AwaitableStandardEventCallback<TSender, TEventData>): boolean {
        return this._listeners.indexOf(listener) > -1;
    }

    /**
     * Přidá do listenerů aktuální instance Eventu listenery z Eventu stejného typu předaného do parametru
     * @param eventToMerge Událost, jejíž listenery se mají přidat do této instance Eventu
     * @param removeListenersFromSourceEvent (Nepovinný) Určuje, zda se mají listenery ze zdrojového eventu rovnou odstranit. Výchozí = false
     */
    public mergeWith(eventToMerge: AwaitableStandardEvent<TSender, TEventData>, removeListenersFromSourceEvent?: boolean) {
        for (let listener of eventToMerge._listeners) {
            if (!this.containsListener(listener)) {
                this.add(listener);
            }
            if (removeListenersFromSourceEvent)
                eventToMerge.remove(listener);
        }

    }
}

/**
 * Třída pro použití v eventu, která může indikovat požadavek na zrušení.
 * @includeToDoc
 */
export class CancellableEventArgs {
    private _cancelEvent: boolean = false;

    /**
     * Stav příznaku zrušení
     */
    public get cancelEvent() {
        return this._cancelEvent;
    }

    /**
     * Nastaví příznak zrušení
     */
    public cancel() {
        this._cancelEvent = true;
    }
}

/**
 * Třída umožňuje vytvoření promisu, který se resolvne při triggeru předané události nebo rejectne po timeoutu
 * @includeToDoc
 */
export class EventAwaiter {
    private _awaiter: DeferredStateful<void> = PromiseUtils.deferStateful<void>();
    private readonly _eventHandler: () => void;

    private static _isDebugging = false;

    constructor(eventToAwait: JsEvent<any, any>, timeout: number = 1000) {
        if (EventAwaiter._isDebugging) console.time("EventAwaiter");
        const timeoutId = self.setTimeout(() => {
            if (this._eventHandler)
                eventToAwait.remove(this._eventHandler);
            this._awaiter.reject();
            if (EventAwaiter._isDebugging) {
                console.log("EventAwaiter timed out.");
                console.timeEnd("EventAwaiter");
            }
        }, timeout);

        eventToAwait.add(this._eventHandler = () => {
            clearTimeout(timeoutId);
            this._awaiter.resolve();
            if (EventAwaiter._isDebugging) {
                console.log("EventAwaiter timed resolved.");
                console.timeEnd("EventAwaiter");
            }
        });
    }

    /**
     * Umožní čekat na spuštění události
     */
    public get awaiter(): Promise<void> {
        return this._awaiter.promise;
    }

    /**
     * Zda už byla událost vyvolaná
     */
    public get isResolved(): boolean {
        return this._awaiter.isResolved;
    }

    /**
     * Vrací promise, který se resolvne při triggeru předané události nebo rejectne po timeoutu. POZOR - Je potřeba handlovat možnou exception při rejectu.
     * @param eventToAwait Událost, při jejímž triggrování se promise resolvuje
     * @param timeout Čas, po které dojde k rejectu promisu, pokud do té doby nedošlo k resolvu; defaultně je 1000ms
     */
    public static createEventAwaiter(eventToAwait: JsEvent<any, any>, timeout: number = 1000): Promise<void> {
        return new EventAwaiter(eventToAwait, timeout).awaiter;
    }
}
