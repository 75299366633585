import React, { FC, PropsWithChildren } from "react"
import { useHistory } from "react-router-dom";
import { setupCn, Classes } from "@utils/BemUtils";
import * as qs from 'query-string';
import { UrlParamObject } from "@utils/QueryHook";
import ButtonLink from "@components/Button/ButtonLink";
import { usePrivatePortal } from "@store/nodeContextStore";
import { useLocalization } from "@store/localizationStore";

const cn = setupCn('multiweb-link');

export type MultiwebLinkProps = PropsWithChildren<{
    className?: Classes;
    urlParams: UrlParamObject;
}>;

const MultiwebLink: FC<MultiwebLinkProps> = ({
    urlParams,
    className,
    children
}) => {
    const { t } = useLocalization();
    const privatePortal = usePrivatePortal();
    const history = useHistory();

    const urlParamsString = qs.stringify(urlParams);

    const hasPrivatePortal = !!privatePortal.url;

    const url = !privatePortal.url ? '#' : history.createHref({
        pathname: privatePortal.url,
        search: urlParamsString
    });

    return (
        <ButtonLink
            isAnchor
            size='small'
            isOutlined
            className={cn.with(className)()}
            to={url}
            title={t(hasPrivatePortal ? 'NEN-768242' : 'NEN-768241')}
            disabled={!hasPrivatePortal}
            target={hasPrivatePortal ? '_blank' : undefined}>
            {children}
        </ButtonLink>
    );
};

MultiwebLink.displayName = 'MultiwebLink';

export default React.memo(MultiwebLink);
