import React, { ChangeEventHandler, FC, PropsWithChildren, useCallback, useMemo } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import { useLocalization } from "@store/localizationStore";
import { FilterManagerChangeEventHandler } from "@components/FilterManager";
import { breakpointClass, BreakpointsClassParam } from "@utils/ResponsibilityUtils";
import Grid from "@components/Grid";
import CheckBox from "@components/CheckBox";
import { bottomSpaceClass } from "@styles/offsets";

const cn = setupCn('checks');

export const VALUES_SEPARATOR = ',';

export const checksColumns = ['oneColumn', 'twoColumns', 'threeColumns'] as const;
export type ChecksColumns = typeof checksColumns[number];

export type CheckOrder = { [breakpoint in ChecksColumns]?: number };

export type ChecksItem = {
    locKey: string;
    value: string;
    order?: CheckOrder;
}

export type ChecksProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    items: ChecksItem[];
    oneColumn?: boolean;
    value?: string;
    onChange?: FilterManagerChangeEventHandler;
    name?: string;
}>;

const columnsBreakpoints: { [columnsCount in ChecksColumns]: BreakpointsClassParam } = {
    oneColumn: 'low',
    twoColumns: 'low',
    threeColumns: 'mobile-landscape'
};

const getItemOrderClasses = (item: ChecksItem) => {
    const order = item?.order;
    if (order == null) {
        return null;
    }
    return checksColumns.map(columnsCount => {
        if (order[columnsCount] != null) {
            return breakpointClass('u-order', columnsBreakpoints[columnsCount], order[columnsCount]);
        }
    });
}

const Checks: FC<ChecksProps> = ({
    id,
    className,
    onChange,
    oneColumn,
    value,
    name,
    items
}) => {
    const { ts, t } = useLocalization();

    const values = value?.split(VALUES_SEPARATOR).filter(v => v) || [];

    const handleCheck = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
        const enumValue = event.target.value;
        let newCheckedValues = [...values];
        if (event.target.checked) {
            if (!newCheckedValues.includes(enumValue)) {
                newCheckedValues = [...newCheckedValues, enumValue];
            }
        } else {
            newCheckedValues = newCheckedValues.filter(checkedValue => checkedValue != enumValue);
        }
        onChange?.(name, newCheckedValues.join(VALUES_SEPARATOR));
    }, [value, name, onChange]);

    const orderedItems = useMemo(() => [...items ?? []].sort((a, b) => (a.order?.oneColumn ?? 0) - (b.order?.oneColumn ?? 0)),
        [items]);

    return (
        <Grid className={cn.with(className)()} isPlain lowerRowSpaces bottomSpace='32' columns={oneColumn ? 1 : 3}>
            {
                orderedItems?.map((item, index) =>
                    <CheckBox
                        key={item.value}
                        className={[getItemOrderClasses(item), bottomSpaceClass('unset')]}
                        id={`${id}__${index}`}
                        title={t(item.locKey)}
                        headerText={ts(item.locKey)}
                        value={item.value}
                        onChange={handleCheck}
                        isChecked={values.includes(item.value.toString())} />
                )
            }
        </Grid>
    );
};

export default React.memo(Checks);
