import React from "react";
import { match, useRouteMatch } from "react-router";
import { DataLabelTyped } from "@components/DataLabel";
import GroupBox from "@components/GroupBox";
import { urlConstants } from "@constants/urlConstants";
import { IZpModel, ZpAttrMap } from "@models/IZpModelHist";
import Tabs, { TabType } from "@components/Tabs";
import { DetailFrame, useDetailFrameContext } from "@components/DetailFrame/DetailFrame";
import { UrlParamObject } from "@utils/QueryHook";
import { IInfoUverejneniModel, InfoUverejneniAttrMap } from "@models/IInfoUVerejneniModel";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { EvidUverVVZAttrMap, IEvidUverVVZModel } from "@models/IEvidUverVVZModel";
import { DetailUverejneniAttrMap, IDetailUverejneniModel } from "@models/IDetailUverejneniModel";
import { IPolozkaPredmetuHistModel, PolozkaPredmetuHistAttrMap } from "@models/IPolozkaPredmetuHistModel";
import { IMistoNUTSModel, MistoNUTSAttrMap } from "@models/IMistoNUTSModelHist";
import InfoText from "@components/InfoText/InfoText";
import FormatedText from "@components/FormatedText/FormatedText";
import { useLocalization } from "@store/localizationStore";
import MultiwebLink from "@components/MultiwebLink/MultiwebLink";
import { downloadButtonOnly } from "@components/Table/Buttons/DownloadAllToolbarButton";
import { DependentListFrameTyped } from "@components/ListFrame/DependentListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { columnWidths, ListFrameColumns } from "@components/ListFrame";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { renderIf } from "@utils/RenderUtils";
import { detailLinks } from "@constants/detailLinks";
import { DetailDescriptionParams, useDetailDescription } from "@utils/DescriptionHook";
import { ParsedUrlCondition } from "@store/breadcrumbTitleStore";

const DataLabel = DataLabelTyped<IZpModel>();
const DependentListFrame = DependentListFrameTyped<IZpModel>();

const detailVerejneZakazkyLoadKey = 'detail-verejne-zakazky'

export const parseVZUrl = (match: match<UrlParamObject> | null) => {
    const isCast = !!match?.params[urlConstants.verejneZakazkyCastiDetail.detailIdParam];
    const urlCode = (isCast ? match?.params[urlConstants.verejneZakazkyCastiDetail.detailIdParam] : match?.params[urlConstants.verejneZakazkyDetail.detailIdParam]) || '';
    const code = urlCode.replace(/-/g, '/');
    const isVerejnaZakazka = /.+\/V\d+/.test(code);
    return { code, isCast, urlCode, isVerejnaZakazka };
}

export const prepareVZCondition = (code: string): ParsedUrlCondition => {
    return {
        condition: 'Kod=@0 AND aktualni = true and not exists(Zadavaci_postup_hist,Hist = &Hist and create_cas>&create_cas and aktualni = true)',
        conditionParams: [code]
    }
}

const DetailVerejneZakazky: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const { ts } = useLocalization();
    const { code, isCast, urlCode, isVerejnaZakazka } = parseVZUrl(match);
    const { condition, conditionParams } = prepareVZCondition(code);
    const routeParamName = isCast ? urlConstants.verejneZakazkyCastiDetail.urlRouteParam : urlConstants.verejneZakazkyDetail.urlRouteParam;
    const tabs: TabType[] = [
        {
            lockey: 'NEN-812829',
            path: isCast ? urlConstants.verejneZakazkyCastiDetail.path : urlConstants.verejneZakazkyDetail.path,
            render: () => <ZakladInfo routeParamName={routeParamName} isVerejnaZakazka={isVerejnaZakazka} />
        },
        {
            lockey: 'NEN-770284',
            showIf: "exists(Uverejneny_dokum, (ZP=&Hist or ZP.Hierarchie=&Hist or DNS.VZ=&Hist or DNS.VZ.Hierarchie=&Hist) and Uverejnen=true and (Info_uver.Uverejneni IN['VYZ','JED','NAB','ZP','KD','VYLOUC','NEJ','DNS','DNSAKTUAL'] or Info_uver.Uverejneni=null))",
            path: isCast ? urlConstants.verejneZakazkyDetailCastiZadavaciDokumentace.path : urlConstants.verejneZakazkyDetailZadavaciDokumentace.path,
            render: () => <ZadavaciDokumentace routeParamName={routeParamName} />
        },
        {
            lockey: 'NEN-812732',
            showIf: "not(Druh_ZR.Kod in['ZP25','ZP26','ZP27','ZP28']) and exists(Info_uverejneni, ZP=&Hist and Uverejneni IN['DODINFO'] and Platny=true)",
            path: isCast ? urlConstants.verejneZakazkyDetailCastiVysvetleniDokumentace.path : urlConstants.verejneZakazkyDetailVysvetleniDokumentace.path,
            render: () => <VysvetleniDokumentace routeParamName={routeParamName} />
        },
        {
            lockey: 'NEN-812771',
            showIf: "Druh_ZR.Kod in['ZP25','ZP26'] and exists(Info_uverejneni, ZP=&Hist and Uverejneni IN['DODINFO'] and Platny=true)",
            path: isCast ? urlConstants.verejneZakazkyDetailCastiVysvetleniDokumentace.path : urlConstants.verejneZakazkyDetailVysvetleniDokumentace.path,
            render: () => <VysvetleniDokumentaceSON routeParamName={routeParamName} />
        },
        {
            lockey: 'NEN-812831',
            showIf: "exists(Info_uverejneni, ZP=&Hist and Uverejneni IN['VYS','PZ','PLN','SUB','UCH','ZRUS','ZMENASML','DNS'] and Platny=true)",
            path: isCast ? urlConstants.verejneZakazkyDetailCastiVysledek.path : urlConstants.verejneZakazkyDetailVysledek.path,
            render: () => <Vysledek routeParamName={routeParamName} />
        }
    ]

    if (!isCast) {
        tabs.splice(1, 0, {
            lockey: 'NEN-812636',
            showIf: 'Rozdeleni_na_casti = true or (Vazba_na_predch_cast = true and Hist.Predchozi_cast.Aktual_uverejneni=true) or exists(Zadavaci_postup,Aktual_uverejneni=true and Predchozi_cast=&Hist.ID)',
            path: urlConstants.VerejneZakazkyCasti.path,
            render: () => <Casti routeParamName={routeParamName} />
        })
    }

    return (
        <DetailFrame
            condition={condition}
            conditionParams={conditionParams}
            attrMapping={ZpAttrMap}
            loadKey={detailVerejneZakazkyLoadKey}
        >
            <Tabs tabs={tabs} headerRight={
                <div className='text-right'>
                    <MultiwebLink
                        urlParams={{ zp: urlCode }}>
                        {ts('NEN-750892')}
                    </MultiwebLink>
                </div>
            } />
        </DetailFrame>
    );
}

const commonDescriptionParts: DetailDescriptionParams<IZpModel>[] = [
    {
        lockey: 'NEN-812694',
        attrName: 'nazev',
    },
    {
        lockey: 'NEN-812618',
        attrName: 'zadavatelNazev',
    },
    {
        lockey: 'NEN-812570',
        attrName: 'popisPredmet',
    }
]

const ZakladInfo: React.FC<{ routeParamName: string, isVerejnaZakazka: boolean }> = ({
    routeParamName,
    isVerejnaZakazka
}) => {
    const { getAttr } = useDetailFrameContext<IZpModel>();
    const { ts } = useLocalization();
    const match = useRouteMatch<UrlParamObject>();
    let dnsDetailLink = '';
    let zakazkaDetailLink = '';
    let zakazkaDetailCelekLink = '';
    if (isVerejnaZakazka) {
        dnsDetailLink = detailLinks.getDnsDetailUrl(getAttr('dnsZavedeneID')?.value, match, true);
        zakazkaDetailLink = detailLinks.getZpDetailUrl(getAttr('vzDnsZavedeneKod')?.value, match, true);
        zakazkaDetailCelekLink = detailLinks.getZpDetailUrl(getAttr('vzDnsZavedeneCelekKod')?.value, match, true);
    }

    useDetailDescription<IZpModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812507'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812507">
                <DataLabel lockey="NEN-812694" attrName='nazev' fillGrid />
                <DataLabel lockey="NEN-812618" attrName='zadavatelNazev' detailLink={detailLinks.getSubjektDetailUrl(getAttr('zadavatelID')?.value, match, true)} />
                <DataLabel lockey="NEN-812594" attrName='kod' />
                <DataLabel lockey="NEN-812605" attrName='stavZP' />
                {
                    renderIf(isVerejnaZakazka,
                        <>
                            <DataLabel lockey="NEN-773253" attrName='dnsZavedeneNazev' detailLink={dnsDetailLink} showIf="Druh_ZR.Kod ='ZP8' and Hist|Verejna_zakazka.DNS.Existujici_DNS.Stav in [!Stav_DNS.Otevren, !Stav_DNS.Ukoncen]" />
                            <DataLabel lockey="NEN-812705" attrName='vzDnsZavedeneKodNazev' detailLink={zakazkaDetailLink} showIf="Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Hierarchie=null and Druh_ZR.Kod ='ZP9'" />
                            <DataLabel lockey="NEN-812705" attrName='vzDnsZavedeneCelekKodNazev' detailLink={zakazkaDetailCelekLink} showIf="Hist|Verejna_zakazka.DNS.Existujici_DNS.VZ.Hierarchie<>null and Druh_ZR.Kod ='ZP9'" />
                        </>
                    )
                }
                <DataLabel lockey="NEN-812743" attrName='rozdeleniNaCasti' showIf="not(Druh_ZR.Kod IN['ZP25','ZP26','ZP27','ZP28'])" />
                <DataLabel lockey="NEN-812731" attrName='kodVestnik' showIf='Evid_c_ve_Vestniku<>null' />
                <DataLabel lockey="NEN-812687" attrName='kodZakazkaProfil' showIf='Uverejneni.Datum_uverejneni>$Novela2016 and Datum_uver_profil<>null and Kod_na_profil<>null' />
                <DataLabel lockey="NEN-812756" attrName='typVZ' showIf="not(Druh_ZR.Kod IN['ZP25','ZP26','ZP27','ZP28'])" />
                <DataLabel lockey="NEN-812622" attrName='druhZRNazev' />
                <DataLabel lockey="NEN-812826" attrName='specifZRNazev' />
                <DataLabel lockey="NEN-812678" attrName='druhVZ' />
                <DataLabel lockey="NEN-812707" attrName='predpokladHodnota' showIf='Hodnota_uverejnena = true' />
                <DataLabel lockey="NEN-812587" attrName='predpokladMenaNazev' showIf='Hodnota_uverejnena = true' />
                <DataLabel lockey="NEN-812744" attrName='datumZruseni' showIf='Uverejneni.Datum_uverejneni>$Novela2016 and Datum_zruseni_ZR<>null' />
                <DataLabel lockey="NEN-812746" attrName='datumProfil' showIf='Uverejneni.Datum_uverejneni>$Novela2016 and Datum_uver_profil<>null' />
                <DataLabel lockey="NEN-812817" attrName='datumVestnik' showIf='Uverejneni.Datum_uverejneni>$Novela2016 and Datum_uver_profil=null and Datum_uver_vestnik<>null' />
                <DataLabel lockey="NEN-824098" attrName='datumUkonceni' showIf="Druh_ZR.Kod IN['ZP25','ZP26'] and Datum_ukonceni<>null" />
                <DataLabel lockey="NEN-824104" attrName='identifEU' showIf="Identifikator_EU<>null" />
            </GroupBox>
            <GroupBox lockey="NEN-812595" showIf="Podani_projeveni.Lhuta<>null or Podani_zadost.Lhuta<>null or Podani_predb_nab.Lhuta<>null or Podani_nabidka.Lhuta<>null or Podani_navrh.Lhuta<>null or Podani_informace.Lhuta<>null">
                <InfoText showIf='Hist.Rozdeleni_na_casti = true and Hist.Lhuty_na_castech = true' fillGrid>
                    <FormatedText>{ts('NEN-749962')}</FormatedText>
                </InfoText>
                <DataLabel lockey="NEN-812822" attrName='podaniPredZajemLhuta' showIf="Druh_ZR.Kod in ['ZP10','ZP12','ZP19','ZP33'] and Podani_projeveni.Lhuta<>null" />
                <DataLabel lockey="NEN-812818" attrName='podaniZadostLhuta' showIf="Druh_ZR.Kod in ['ZP8','ZP10','ZP12','ZP16','ZP19','ZP26','ZP34','ZP35'] and Podani_zadost.Lhuta<>null" />
                <DataLabel lockey="NEN-812819" attrName='podaniPredNabLhuta' showIf="Druh_ZR.Kod in ['ZP12','ZP13','ZP19','ZP34'] and Podani_predb_nab.Lhuta<>null" />
                <DataLabel lockey="NEN-812619" attrName='podaniLhuta' showIf="Druh_ZR.Kod IN['ZP1','ZP2','ZP3','ZP4','ZP5','ZP6','ZP7','ZP8','ZP9','ZP10','ZP11','ZP12','ZP13','ZP14','ZP15','ZP16','ZP17','ZP18','ZP19','ZP20','ZP21','ZP22','ZP23','ZP24','ZP29','ZP30','ZP31','ZP32','ZP33','ZP34'] and Podani_nabidka.Lhuta<>null" />
                <DataLabel lockey="NEN-812670" attrName='podaniNavrhLhuta' showIf="Druh_ZR.Kod IN['ZP25','ZP26'] and Podani_navrh.Lhuta<>null" />
                <DataLabel lockey="NEN-812671" attrName='podaniInformaceLhuta' showIf="Druh_ZR.Kod IN['ZP27','ZP28'] and Podani_informace.Lhuta<>null" />
            </GroupBox>
            <GroupBox lockey="NEN-812534">
                <DataLabel lockey="NEN-812535" attrName='osobaTitPred' showIf='Osoba_zadavat.Titul_pred<>null' />
                <DataLabel lockey="NEN-812536" attrName='osobaJmeno' showIf='Osoba_zadavat.Jmeno<>null' />
                <DataLabel lockey="NEN-812537" attrName='osobaPrijmeni' showIf='Osoba_zadavat.Prijmeni<>null' />
                <DataLabel lockey="NEN-812538" attrName='osobaTitZa' showIf='Osoba_zadavat.Titul_za<>null' />
                <DataLabel lockey="NEN-812540" attrName='osobaFunkce' showIf='Osoba_zadavat.Funkce<>null' />
                <DataLabel lockey="NEN-812541" attrName='osobaEmail' showIf='Osoba_zadavat.Email<>null' />
                <DataLabel lockey="NEN-812550" attrName='osobaTelefon' showIf='Osoba_zadavat.Telefon_zam<>null' />
                <DataLabel lockey="NEN-812551" attrName='osobaMobil' showIf='Osoba_zadavat.Mobil<>null' />
                <DataLabel lockey="NEN-812708" attrName='osobaFax' showIf='Osoba_zadavat.Fax<>null' />
                <DataLabel lockey="NEN-812542" attrName='osobaDalsiInfo' showIf='Osoba_zadavat.Dalsi_informace<>null' />
            </GroupBox>
            <GroupBox lockey="NEN-812597">
                <DataLabel lockey="NEN-812570" attrName='popisPredmet' fillGrid rawText />
                <DataLabel lockey="NEN-812683" attrName='nipezPredmetuKod' />
                <DataLabel lockey="NEN-812684" attrName='nipezPredmetuNazev' />
                <DataLabel lockey="NEN-812846" attrName='hlavniMistoNUTS' />
                <DataLabel lockey="NEN-812685" attrName='cpvPredmetuKod' />
                <DataLabel lockey="NEN-812686" attrName='nipezPredmetuNazev' />
                <DataLabel lockey="NEN-812502" attrName='nazevPredmetu' />
                <DataLabel lockey="NEN-812584" attrName='mistoPlneni' showIf='Misto_plneni<>null' fillGrid />
            </GroupBox>
            <GroupBox lockey="NEN-812581" fillContent>
                <ListFramePolozky routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812677" fillContent showIf="Misto_k_celku=true">
                <ListFrameMistoPol routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812677" fillContent showIf="Misto_k_celku<>true">
                <ListFrameMisto routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812833">
                <DataLabel lockey="NEN-812735" attrName='naZakladeRSRD' showIf='Uverejneni.Datum_uverejneni>$Novela2016' />
                <DataLabel lockey="NEN-812736" attrName='nazevRSRD' showIf='Na_zaklade_RS=true' />
                <DataLabel lockey="NEN-812561" attrName='zadavanaDNS' showIf='Uverejneni.Datum_uverejneni>$Novela2016' />
                <DataLabel lockey="NEN-812560" attrName='identifDNS' showIf='Zadavana_v_DNS=true' />
                <DataLabel lockey="NEN-812529" attrName='zavedeniDNS' showIf='Uverejneni.Datum_uverejneni>$Novela2016' />
                <DataLabel lockey="NEN-812755" attrName='jeToRD' showIf='Jedna_se_o_RD<>null' />
                <DataLabel lockey="NEN-769974" attrName='importovanaZakazka' />
                <DataLabel lockey="NEN-812752" attrName='identifSouvis' showIf='Identif_ZP_z_DNS<>null' />
                <DataLabel lockey="NEN-812730" attrName='identifZP' showIf='Identifikator_ZP<>null' />
                <DataLabel lockey="NEN-812824" attrName='profilMimo' showIf='www_profil_mimo<>null' />
                <DataLabel lockey="NEN-812751" attrName='kodPuvodniProfil' showIf='Puvodni_kod_profilu<>null' />
                <DataLabel lockey="NEN-812629" attrName='kodEU' showIf='Evid_c_Uv_EU<>null' />
                <DataLabel lockey="NEN-812630" attrName='kodET' showIf='System_c_ET<>null' />
                <DataLabel lockey="NEN-812631" attrName='kodIEN' showIf='System_c_IEN<>null' />
            </GroupBox>
            <GroupBox lockey="NEN-812747" fillContent>
                <ListFrameEvidenceUver routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812720" fillContent showIf="Exists(VW_Evidence_VVZ, ZP=&Hist and datum_uver<>null)">
                <ListFrameEvidUverVVZ routeParamName={routeParamName} />
            </GroupBox>
        </>
    );
}

const mistoModelColumns: ListFrameColumns<IMistoNUTSModel> = [
    {
        attrName: 'nutsKod',
        locKey: 'NEN-812680',
    },
    {
        attrName: 'nutsNazev',
        locKey: 'NEN-812679',
    }
];

const ListFrameMisto: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            lightVersion
            listFrameKey={detailVerejneZakazkyLoadKey + '-misto'}
            condition='ZP = @0 and Hist.Prof = @1 and aktualni=true'
            conditionParams={['histId', 'profId']}
            attrMapping={MistoNUTSAttrMap}
            paramNames={useStaticObject({ filterParamName: 'mis', routeParamName })}
            orderBy={useOrderByMemo<IMistoNUTSModel>({ desc: true, column: "createCas" })}
            columns={mistoModelColumns} />
    );
}

const mistoPolModelColumns: ListFrameColumns<IMistoNUTSModel> = [
    {
        attrName: 'nutsKod',
        locKey: 'NEN-812680',
    },
    {
        attrName: 'nutsNazev',
        locKey: 'NEN-812679',
    },
    {
        attrName: 'nipezPolozkyKod',
        locKey: 'NEN-812585'
    },
    {
        attrName: 'cpvPolozkyKod',
        locKey: 'NEN-812586'
    },
    {
        attrName: 'nazevPolozky',
        locKey: 'NEN-812571'
    }
];

const ListFrameMistoPol: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-misto-polozka'}
            condition='ZP = @0 and Hist.Prof = @1 and aktualni=true'
            conditionParams={['histId', 'profId']}
            orderBy={useOrderByMemo<IMistoNUTSModel>({ desc: true, column: "createCas" })}
            lightVersion
            attrMapping={MistoNUTSAttrMap}
            paramNames={useStaticObject({ filterParamName: 'mispol', routeParamName })}
            columns={mistoPolModelColumns} />
    );
}
const polozkyPredmetuModelColumns: ListFrameColumns<IPolozkaPredmetuHistModel> = [
    {
        attrName: 'nazevPolozky',
        locKey: 'NEN-812571',
    },
    {
        attrName: 'cpvPolozkyKod',
        locKey: 'NEN-812586'
    },
    {
        attrName: 'cpvPolozkyNazev',
        locKey: 'NEN-812583'
    },
    {
        attrName: 'popisPolozky',
        locKey: 'NEN-812572'
    }
];

const ListFramePolozky: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            lightVersion
            listFrameKey={`${detailVerejneZakazkyLoadKey}-polozky-predmetu`}
            attrMapping={PolozkaPredmetuHistAttrMap}
            conditionParams={['histId', 'profId']}
            paramNames={useStaticObject({ filterParamName: 'polp', routeParamName })}
            detailLinkFactory={detailLinks.getDetailPolozkaPredmetuLinkFactory()}
            condition='ZP = @0 and Hist.Prof = @1 and aktualni=true'
            columns={polozkyPredmetuModelColumns}
            orderBy={useOrderByMemo<IPolozkaPredmetuHistModel>({ desc: true, column: "createCas" })} />
    );
}
const evidUverVVZModelColumns: ListFrameColumns<IEvidUverVVZModel> = [
    {
        attrName: 'datumPotvrzeni',
        locKey: 'NEN-812709',
        width: columnWidths.dateTime
    },
    {
        attrName: 'datumUverejneni',
        locKey: 'NEN-781265',
        width: columnWidths.dateTime
    },
    {
        attrName: 'evidCisloForm',
        locKey: 'NEN-812710',
        width: 150
    },
    {
        attrName: 'druhForm',
        locKey: 'NEN-812713'
    }
];

const ListFrameEvidUverVVZ: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={`${detailVerejneZakazkyLoadKey}-evidence-vvz`}
            lightVersion
            attrMapping={EvidUverVVZAttrMap}
            condition='ZP = @0 and datum_uver<>null'
            conditionParams={'histId'}
            orderBy={useOrderByMemo<IEvidUverVVZModel>({ desc: true, column: "datumUverejneni" })}
            paramNames={useStaticObject({ filterParamName: 'pvvz', routeParamName })}
            columns={evidUverVVZModelColumns} />
    );
}
const evidenceUverModelColumns: ListFrameColumns<IInfoUverejneniModel> = [
    {
        attrName: 'datumUverejneni',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'text',
        locKey: 'NEN-812643'
    },
    {
        attrName: 'datumOduver',
        locKey: 'NEN-812722',
        width: columnWidths.dateTime
    },
    {
        attrName: 'oduverejnil',
        locKey: 'NEN-812721'
    }
];

const ListFrameEvidenceUver: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-evidence-uver'}
            lightVersion
            condition='ZP = @0'
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IInfoUverejneniModel>({ desc: true, column: "datumUverejneni" })}
            paramNames={useStaticObject({ filterParamName: 'peu', routeParamName })}
            attrMapping={InfoUverejneniAttrMap}
            detailLinkFactory={detailLinks.getDetailInfoUverejneniLinkFactory(true)}
            columns={evidenceUverModelColumns} />
    );
}
const Casti: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    useDetailDescription<IZpModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812636'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812636" showIf="Rozdeleni_na_casti = true" fillContent>
                <ListFrameCasti routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-613664" showIf="(Vazba_na_predch_cast=true and Hist.Predchozi_cast.Aktual_uverejneni=true) or exists(Zadavaci_postup,Aktual_uverejneni=true and Predchozi_cast=&Hist.ID)" fillContent>
                <ListFramePropojeneCasti routeParamName={routeParamName} />
            </GroupBox>
        </>
    );
}
const castiModelColumns: ListFrameColumns<IZpModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812564',
        width: 200

    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812565'
    },
    {
        attrName: 'nipezPredmetuNazev',
        locKey: 'NEN-812830',
        width: 250
    },
    {
        attrName: 'stavZP',
        locKey: 'NEN-738558',
        width: 150
    }
];

const ListFrameCasti: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {

    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-casti'}
            condition="aktualni = true and Hist.Hierarchie = @0 and Prof = @1"
            conditionParams={['histId', 'profId']}
            orderBy='kod'
            lightVersion
            attrMapping={ZpAttrMap}
            paramNames={useStaticObject({ filterParamName: 'cast', routeParamName })}
            detailLinkFactory={detailLinks.getLfZpDetailLinkFactory(false, true)}
            columns={castiModelColumns} />
    );
}
const propojeneCastiModelColumns: ListFrameColumns<IZpModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812564',
        width: 200

    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812565'
    },
    {
        attrName: 'nipezPredmetuNazev',
        locKey: 'NEN-812830',
        width: 250
    },
    {
        attrName: 'stavZP',
        locKey: 'NEN-738558',
        width: 150
    }
];

const ListFramePropojeneCasti: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-propojene-casti'}
            condition="aktualni = true and Prof = @1 and ((Hist.Predchozi_cast=@0) or (Hist<>@0 and @2>0 and (@2=Hist or (Hist.Predchozi_cast>0 and Hist.Predchozi_cast=@2))))"
            conditionParams={['histId', 'profId', 'predchoziCastId']}
            orderBy='kod'
            lightVersion
            attrMapping={ZpAttrMap}
            paramNames={useStaticObject({ filterParamName: 'procast', routeParamName })}
            detailLinkFactory={detailLinks.getLfZpDetailLinkFactory(false, true)}
            columns={propojeneCastiModelColumns} />
    );
}
const ZadavaciDokumentace: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    useDetailDescription<IZpModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812616'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812616" fillContent showIf="exists(Uverejneny_dokum, Info_uver.ZP=&Hist and Uverejnen=true and Polozka=null and Info_uver.Uverejneni IN['VYZ','JED','NAB','ZP','KD'])">
                <ListFrameZadDokum routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812881" fillContent showIf="exists(Uverejneny_dokum, ZP=&Hist and Uverejnen=true and Polozka<>null)">
                <ListFramePrilohaPoloz routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812724" fillContent showIf="exists(Uverejneny_dokum, (ZP=&Hist or DNS.VZ=&Hist) and Uverejnen=true and (Info_uver.Uverejneni=null or Info_uver.Uverejneni IN['VYLOUC','NEJ','DNS','DNSAKTUAL']) and Polozka=null)">
                <ListFrameOstatDokum routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-807150" fillContent showIf="exists(Info_uverejneni, ZP=&Hist and Platny=true and Uverejneni = 'UDAJNAB')">
                <ListFrameUdajePodani routeParamName={routeParamName} />
            </GroupBox>
        </>
    );
}
const udajPodModelColumns: ListFrameColumns<IInfoUverejneniModel> = [
    {
        attrName: 'datumUverejneni',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'predmet',
        locKey: 'NEN-812643'
    }
];

const ListFrameUdajePodani: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-udaje-podani'}
            condition="ZP = @0 and Uverejneni='UDAJNAB' and Platny=true"
            conditionParams={['histId']}
            orderBy='datumUverejneni'
            lightVersion
            attrMapping={InfoUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'upod', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUdajePodaniLinkFactory()}
            columns={udajPodModelColumns} />
    );
}
const zaddokumModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];
const ListFrameZadDokum: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-zadavaci-dokum'}
            condition="Info_uver.Uverejneni IN['VYZ','JED','NAB','ZP','KD'] and Uverejnen=true and (Info_uver.ZP=@0 or Info_uver.ZP.Hierarchie=@0) and Polozka=null"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pzd', routeParamName })}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            columns={zaddokumModelColumns}
            extraButtons={downloadButtonOnly}
        />
    );
}
const prilohaPolozkyModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'nazevPolozky',
        locKey: 'NEN-812571',
    },
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];
const ListFramePrilohaPoloz: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-prilohy-polozky'}
            condition="Uverejnen=true and ZP=@0 and Polozka<>null"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pod', routeParamName })}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            columns={prilohaPolozkyModelColumns}
            extraButtons={downloadButtonOnly} />
    );
}
const ostatdokumModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];
const ListFrameOstatDokum: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-ostatni-dokum'}
            condition="(not(Info_uver.Uverejneni IN['PZ','VYS','VYZ','JED','NAB','ZP','DODINFO','KD','ZRUS','ZMENASML','UDAJNAB']) or Info_uver.Uverejneni=null) and Uverejnen=true and (Info_uver.ZP=@0 or Info_uver.DNS.VZ=@0) and Polozka=null"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pod', routeParamName })}
            columns={ostatdokumModelColumns}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            extraButtons={downloadButtonOnly} />
    );
}

const VysvetleniDokumentace: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    useDetailDescription<IZpModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812732'
        }
    ]);

    return (
        <GroupBox lockey="NEN-812732" fillContent>
            <ListFrameVysvetleni routeParamName={routeParamName} />
        </GroupBox>
    );
}
const vysvetleniModelColumns: ListFrameColumns<IInfoUverejneniModel> = [
    {
        attrName: 'datumUverejneni',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'predmet',
        locKey: 'NEN-812643'
    }
];

const ListFrameVysvetleni: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-vysvetleni'}
            condition="ZP = @0 and Uverejneni='DODINFO' and Platny=true"
            conditionParams={['histId']}
            orderBy='datumUverejneni'
            lightVersion
            attrMapping={InfoUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'vysv', routeParamName })}
            detailLinkFactory={detailLinks.getDetailDodInfoLinkFactory()}
            columns={vysvetleniModelColumns} />
    );
}

const VysvetleniDokumentaceSON: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    useDetailDescription<IZpModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812771'
        }
    ]);

    return (
        <GroupBox lockey="NEN-812771" fillContent>
            <ListFrameVysvetleniSON routeParamName={routeParamName} />
        </GroupBox>
    );
}
const vysvetleniSONModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'datumUverInfo',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'predmetInfo',
        locKey: 'NEN-812643'
    }
    ,
    {
        attrName: 'idInfoUver',
        locKey: 'NEN-812643',
        hidden: true
    }
];

const ListFrameVysvetleniSON: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-vysvetleni-SoN'}
            condition="Info_uver.ZP = @0 and Info_uver.Uverejneni='DODINFO' and Uverejnen=true"
            conditionParams={['histId']}
            orderBy='datumUverInfo'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'vysvson', routeParamName })}
            detailLinkFactory={detailLinks.getDetailDodInfoSonLinkFactory}
            columns={vysvetleniSONModelColumns} />
    );
}
const Vysledek: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    useDetailDescription<IZpModel>([
        ...commonDescriptionParts,
        {
            lockey: 'NEN-812831'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812608" fillContent showIf="not(Druh_ZR.Kod in['ZP25','ZP26','ZP27','ZP28']) and exists(Info_uverejneni, ZP=&Hist and Platny = true and Uverejneni IN['VYS'])">
                <ListFrameVysledek routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812608" fillContent showIf="Druh_ZR.Kod in['ZP25','ZP26'] and exists(Info_uverejneni, ZP=&Hist and Platny = true and Uverejneni IN['VYS'])">
                <ListFramevysledekSON routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812832" fillContent showIf="exists(Info_uverejneni, ZP=&Hist and Platny=true and Uverejneni IN['VYS','PZ','ZRUS','ZMENASML','DNS'])">
                <ListFrameVysledekDokum routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812875" fillContent showIf="exists(Info_uverejneni, ZP=&Hist and Platny=true and Uverejneni IN['ZMENASML'])">
                <ListFrameDodatek routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812745" fillContent showIf="not(Druh_ZR.Kod in['ZP27','ZP28']) and exists(Info_uverejneni, ZP=&Hist and Platny = true and Uverejneni IN['UCH'])">
                <ListFrameUcast routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812745" fillContent showIf="Druh_ZR.Kod in['ZP27','ZP28'] and exists(Info_uverejneni, ZP=&Hist and Platny = true and Uverejneni IN['UCH'])">
                <ListFrameUcastPT routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812673" fillContent showIf="not(Druh_ZR.Kod in['ZP27','ZP28']) and exists(Info_uverejneni, ZP=&Hist and Platny = true and Uverejneni IN['PLN'])">
                <ListFramePlneni routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-807738" fillContent showIf="exists(Info_uverejneni, ZP<>null and ZP=&Hist and Platny = true and Uverejneni = 'PZ' and Uver_pis_zprava=true and exists(Detail_uverejneni, Info_uver.ZP=&&Hist and Info_uver<>null and Uverejnen=true and Osoba_stret=null and Subdod=null and Uredni_nazev=null))">
                <ListFrameDetailPZ routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-825137" fillContent showIf="exists(Info_uverejneni, ZP<>null and ZP=&Hist and Platny = true and Detail = 'SON' and exists(Detail_uverejneni, Info_uver.ZP=&&Hist and Info_uver<>null and Uverejnen=true and Osoba_stret=null and Subdod=null and Pod = null))">
                <ListFrameOduvodSON routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-807292" fillContent showIf="exists(Info_uverejneni, ZP<>null and ZP=&Hist and Platny = true and ((Uverejneni = 'PZ' and Uver_pis_zprava=true) or Detail = 'SON') and exists(Detail_uverejneni, Info_uver.ZP=&&Hist and Info_uver<>null and Uverejnen=true and Osoba_stret<>null))">
                <ListFrameOsoby routeParamName={routeParamName} />
            </GroupBox>
            <GroupBox lockey="NEN-812734" fillContent showIf="not(Druh_ZR.Kod in['ZP27','ZP28']) and exists(Info_uverejneni, ZP=&Hist and Platny = true and (Uverejneni in ['SUB'] or (Uverejneni = 'PZ' and Uver_pis_zprava=true)) and exists(Detail_uverejneni, Info_uver.ZP=&&Hist and Info_uver<>null and Uverejnen=true and Subdod <>null))">
                <ListFrameSubdod routeParamName={routeParamName} />
            </GroupBox>
        </>
    );
}
const vysledekdokumModelColumns: ListFrameColumns<IUverejnenyDokumModel> = [
    {
        attrName: 'cisloSmlouva',
        locKey: 'NEN-812880',
        width: 120
    },
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];
const ListFrameVysledekDokum: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-vysledek-dokum'}
            condition="(Info_uver.Uverejneni IN['VYS','PZ','ZRUS','ZMENASML','DNS']) and Uverejnen = true and Info_uver.ZP = @0"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'vysdok', routeParamName })}
            columns={vysledekdokumModelColumns}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            extraButtons={downloadButtonOnly} />
    );
}
const vysledekModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'cisloSmlouva',
        locKey: 'NEN-812606',
        width: 120
    },
    {
        attrName: 'datumUzavreni',
        locKey: 'NEN-812601',
        width: columnWidths.date
    },
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'smluvniCenaS',
        locKey: 'NEN-812633'
    },
    {
        attrName: 'smluvniCenaBez',
        locKey: 'NEN-812632'
    },
    {
        attrName: 'smluvniCenaSAkt',
        locKey: 'NEN-812872'
    },
    {
        attrName: 'smluvniCenaBezAkt',
        locKey: 'NEN-812873'
    },
    {
        attrName: 'menaUverKod',
        locKey: 'NEN-812607'
    }
];

const ListFrameVysledek: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-vysledek'}
            condition="Uverejnen = true and Info_uver.Uverejneni = 'VYS' and Info_uver.ZP = @0"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'vys', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={vysledekModelColumns} />
    );
}
const VysledekSONModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'datumVyber',
        locKey: 'NEN-812663',
        width: columnWidths.date
    },
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506'
    },
    {
        attrName: 'soutezCenaBez',
        locKey: 'NEN-812664'
    },
    {
        attrName: 'menaUverKod',
        locKey: 'NEN-812607'
    }
];

const ListFramevysledekSON: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-vysledek-SON'}
            condition="Uverejnen = true and Info_uver.Uverejneni = 'VYS' and Info_uver.ZP = @0 and Pod<>null"
            conditionParams={['histId']}
            orderBy='id'
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'vysson', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={VysledekSONModelColumns} />
    );
}

const ucastModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506',

    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    },
    {
        attrName: 'nabCenaS',
        locKey: 'NEN-812850'
    },
    {
        attrName: 'nabCenaBez',
        locKey: 'NEN-812851'
    },
    {
        attrName: 'menaUverKod',
        locKey: 'NEN-812607'
    },
    {
        attrName: 'uzavrenaSmlouva',
        locKey: 'NEN-824859'
    }
];

const ListFrameUcast: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-ucast'}
            condition="Uverejnen = true and Info_uver.Uverejneni = 'UCH' and Info_uver.ZP = @0"
            conditionParams={'histId'}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'uca', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={ucastModelColumns} />
    );
}
const ucastPTModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506',

    },
    {
        attrName: 'ico',
        locKey: 'NEN-812508',
        width: columnWidths.ico
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    }
];

const ListFrameUcastPT: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-ucast-IoT'}
            condition="Uverejnen = true and Info_uver.Uverejneni = 'UCH' and Info_uver.ZP = @0"
            conditionParams={'histId'}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'ucaPT', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={ucastPTModelColumns} />
    );
}
const dodatekModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'cislo',
        locKey: 'NEN-812668',
        width: 100

    },
    {
        attrName: 'datumPodpisu',
        locKey: 'NEN-812669',
        width: columnWidths.dateTime
    },
    {
        attrName: 'emailAdresa',
        locKey: 'NEN-812874'
    },
    {
        attrName: 'smluvniCenaS',
        locKey: 'NEN-812871',
        width: 220
    },
    {
        attrName: 'smluvniCenaBez',
        locKey: 'NEN-812870',
        width: 220
    }
];

const ListFrameDodatek: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-dodatek'}
            condition="Uverejnen = true and Info_uver.Uverejneni = 'ZMENASML' and Info_uver.ZP = @0"
            conditionParams={'histId'}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'dodat', routeParamName })}
            columns={dodatekModelColumns} />
    );
}

const plneniModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'cisloSmlouva',
        locKey: 'NEN-812606',
        width: 120
    },
    {
        attrName: 'plneniRok',
        locKey: 'NEN-812674',
        width: 100

    },
    {
        attrName: 'plneniCenaS',
        locKey: 'NEN-812676'
    },
    {
        attrName: 'plneniCenaBez',
        locKey: 'NEN-812675'
    }
];

const ListFramePlneni: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-plneni'}
            condition="Uverejnen = true and Info_uver.Uverejneni = 'PLN' and Info_uver.ZP = @0"
            conditionParams={'histId'}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: 'plneniRok' })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pln', routeParamName })}
            columns={plneniModelColumns} />
    );
}

const DetailPZModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'oduvodVyberuDod',
        locKey: 'NEN-821548'
    },
    {
        attrName: 'oduvodKomunProstredky',
        locKey: 'NEN-812886',
    },
    {
        attrName: 'oduvodNerozdeleni',
        locKey: 'NEN-812888',
    },
    {
        attrName: 'oduvodStanoveniPozadav',
        locKey: 'NEN-812889',
    }
];

const ListFrameDetailPZ: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-detail-pz'}
            condition="Uverejnen = true and Info_uver.Uverejneni = 'PZ' and Info_uver.ZP = @0 and Osoba_stret=null and Subdod=null"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detailpz', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={DetailPZModelColumns} />
    );
}
const DetailOduvodSONModelColumns: ListFrameColumns<IDetailUverejneniModel> = [

    {
        attrName: 'oduvodKomunProstredky',
        locKey: 'NEN-812886',
    }
];

const ListFrameOduvodSON: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-detail-duvod'}
            condition="Uverejnen = true and Info_uver.Detail = 'SON' and Info_uver.ZP = @0 and Osoba_stret=null and Subdod=null and Pod=null"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'detailduvson', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={DetailOduvodSONModelColumns} />
    );
}

const OsobyModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'nazev',
        locKey: 'NEN-812890'
    },
    {
        attrName: 'prijataOpatreni',
        locKey: 'NEN-812883',
    }
];

const ListFrameOsoby: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-detail-osoba'}
            condition="Uverejnen = true and (Info_uver.Uverejneni = 'PZ' or Info_uver.Detail = 'SON') and Info_uver.ZP = @0 and Osoba_stret<>null"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'osob', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={OsobyModelColumns} />
    );
}

const subdodModelColumns: ListFrameColumns<IDetailUverejneniModel> = [
    {
        attrName: 'uredniNazev',
        locKey: 'NEN-812506',
    },
    {
        attrName: 'ico',
        locKey: 'NEN-812508',
        width: columnWidths.ico
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    }
];
const ListFrameSubdod: React.FC<{ routeParamName: string }> = ({
    routeParamName
}) => {
    return (
        <DependentListFrame
            listFrameKey={detailVerejneZakazkyLoadKey + '-subdod'}
            condition="Uverejnen = true and (Info_uver.Uverejneni in ['SUB'] or (Info_uver.Uverejneni = 'PZ' and Info_uver.Uver_pis_zprava=true)) and Info_uver.ZP = @0 and Subdod <>null"
            conditionParams={['histId']}
            orderBy={useOrderByMemo<IDetailUverejneniModel>({ desc: true, column: "id" })}
            lightVersion
            attrMapping={DetailUverejneniAttrMap}
            paramNames={useStaticObject({ filterParamName: 'sub', routeParamName })}
            detailLinkFactory={detailLinks.getDetailUverejneniLinkFactory()}
            columns={subdodModelColumns} />
    );
}

export default DetailVerejneZakazky;