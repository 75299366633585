import { Stringable } from "@store/localizationStore";
import { Breakpoints } from "styles/responsibility";
import { notEmpty } from "./ArrayHelper";

export type BreakpointsClassParam = Breakpoints | 'none' |'low'

export const breakpointClass = (property: string, breakpoint: BreakpointsClassParam, value?: Stringable): string => {
    if (breakpoint == null || breakpoint == 'none') {
        return '';
    }
    const stringValue = value = value?.toString();
    const breakpointPart = breakpoint == 'low' ? '' : `-${breakpoint}`;
    const valuePart = notEmpty(stringValue) ? `-${stringValue}` : '';
    return `${property}${breakpointPart}${valuePart}`;
}
