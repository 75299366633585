import { WhispererItem } from "@components/WhispererInput";
import DataRowsService from "@services/DataRowsService";
import { getErrorStoreActions } from "@store/errorStore";
import { AppDispatch } from "@store/index";
import { toArray } from "./ArrayHelper";
import { AttrMapping, IBasicData } from "./AttrMapping";
import { joinConditionsByAnd, joinConditionsByOr, toSplittedLinguisticLike } from "./FilterCondition";

export const getLovText = (lovData: IBasicData | null, showAttrs: string[]) => lovData ? showAttrs.map(showAttr => lovData[showAttr]).join(' | ') : '';

export type GetLovItemsParams = {
    dataRowsService: DataRowsService;
    dispatch: AppDispatch;
    actualValue: string;
    condition?: string | null;
    conditionParams: any[];
    showAttrs: string[];
    attrMapping: AttrMapping;
    maxCount?: number;
    abortController?: AbortController
}

export const getLovItems = async ({
    dataRowsService,
    dispatch,
    condition,
    maxCount,
    showAttrs,
    attrMapping,
    conditionParams,
    abortController
}: GetLovItemsParams) => {
    const fullCondition = joinConditionsByAnd(
        'ID > 0',
        condition
    );
    const result = await dataRowsService.loadDataWithCount({
        className: attrMapping.objectName,
        attributes: attrMapping.getDataRowAttributes('id', ...showAttrs),
        condition: fullCondition || undefined,
        conditionParams: conditionParams,
        count: maxCount,
        abortSignal: abortController?.signal
    });
    if (result.hasErrors) {
        dispatch(getErrorStoreActions().push(result.errors));
    }
    return result?.value;
}

export const getLovLikeItems = async (params: GetLovItemsParams) => {
    const { actualValue, showAttrs, attrMapping, condition, conditionParams } = params;
    const likeCondition = joinConditionsByOr(...showAttrs
        .map(clientName => attrMapping.getByClientName(clientName)?.serverName || null)
        .map(serverName => serverName && toSplittedLinguisticLike(serverName, actualValue, conditionParams)));
    const fullCondition = joinConditionsByAnd(
        likeCondition,
        condition
    );
    return await getLovItems({
        ...params,
        condition: fullCondition,
        conditionParams: conditionParams
    })
}

export const getExactLovValue = async (params: Omit<GetLovItemsParams, 'maxCount'>) => {
    const { actualValue, condition, showAttrs } = params;
    const attrsValues = actualValue.split('|').map(attrValue => attrValue.trim());
    try {
        const exactFullCondition = joinConditionsByAnd(
            condition,
            ...toArray(showAttrs).map((attr, index) => `${attr}='${attrsValues[index] || ''}'`)
        );
        const exactResult = await getLovItems({
            ...params,
            condition: exactFullCondition,
            maxCount: 1
        });
        if (exactResult?.count == 1) {
            return exactResult.collection[0];
        }
    } catch (e) {
        console.error(e);
    }

    try {
        const likeResult = await getLovLikeItems({
            ...params,
            maxCount: 1
        });
        if (likeResult?.count == 1) {
            return likeResult.collection[0];
        }
    } catch (e) {
        console.error(e);
    }
    return null;
}

export const lovToWhispererItem = (data: IBasicData, showAttrs: string[]): WhispererItem<IBasicData> => ({
    id: data.id,
    text: getLovText(data, showAttrs),
    data
});
