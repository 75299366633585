import { CaseInsensitiveMap } from "./CaseInsensitiveMap";
import { DeferredStateful, PromiseUtils } from "./PromiseUtils";

export type PromiseStackerItems<T> = Map<string, DeferredStateful<T>>;
export type PromisesStackerTiskCallback<T> = (items: PromiseStackerItems<T>) => Promise<void>;

export type PromisesStackerProps<T> = {
    timeout?: number;
    onTick?: PromisesStackerTiskCallback<T>;
}

type PromisesStackerSet = {
    keys: Set<string>;
    timer?: unknown;
}

export class PromisesStacker<T> {
    public onTick?: PromisesStackerTiskCallback<T>;
    private _requested: PromisesStackerSet;
    private _allRequested = new CaseInsensitiveMap<string, DeferredStateful<T>>();
    private _timeout: number;

    constructor({
        timeout,
        onTick
    }: PromisesStackerProps<T>) {
        this._timeout = timeout ?? 300;
        this.onTick = onTick;
        this._requested = this._createRequestedSet();
    }

    public getAllResolved(): T[] {
        return [...this._allRequested.values()]
            .filter(itemPromise => itemPromise.isResolved)
            .map(itemPromise => itemPromise.promiseValue);
    }

    public request(key: string) {
        let itemDeferPromise = this._allRequested.get(key);
        if (!itemDeferPromise) {
            const requested = this._requested;
            requested.keys.add(key);
            itemDeferPromise = PromiseUtils.deferStateful();
            this._allRequested.set(key, itemDeferPromise);
            this._runTimer();
        }
        return itemDeferPromise.promise;
    }

    private _runTimer() {
        const requested = this._requested;
        if (requested.timer != null) {
            return;
        }
        requested.timer = setTimeout(() => this._handleTimerTick(), this._timeout);
    }

    private _handleTimerTick() {
        const requested = this._popRequested();
        const requestedMap = new Map(requested.map(key => [key, this._allRequested.get(key) || PromiseUtils.deferStateful<T>()]));
        this.onTick?.(requestedMap);
    }

    private _popRequested(): string[] {
        const requested = this._requested;
        this._requested = this._createRequestedSet();
        return [...requested?.keys ?? []];
    }

    private _createRequestedSet(): PromisesStackerSet {
        return {
            keys: new Set()
        }
    }
}