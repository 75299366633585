import { AttrMapping, IData } from "@utils/AttrMapping";

export type IOsobaModel = IData & {
    nazev: string;
    pracovniPozice: string;
    titulPred: string;
    jmeno: string;
    prijmeni: string;
    titulZa: string;
    email: string;
    mobil: string;
    telefonZamestnani: string;
}

export const OsobaAttrMap = new AttrMapping<IOsobaModel>('Osoba', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'pracovniPozice', serverName: 'Nahled_ext.Pracovni_pozice', type: 'string' },
    { clientName: 'titulPred', serverName: 'Nahled_ext.Titul_pred', type: 'string' },
    { clientName: 'jmeno', serverName: 'Nahled_ext.Jmeno', type: 'string' },
    { clientName: 'prijmeni', serverName: 'Nahled_ext.Prijmeni', type: 'string' },
    { clientName: 'titulZa', serverName: 'Nahled_ext.Titul_za', type: 'string' },
    { clientName: 'email', serverName: 'Nahled_ext.Email', type: 'string' },
    { clientName: 'mobil', serverName: 'Nahled_ext.Mobil', type: 'string' },
    { clientName: 'telefonZamestnani', serverName: 'Nahled_ext.Telefon_zam', type: 'string' },
]);
