import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, LOCATION_CHANGE } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, initStore, reducers } from './';
import { NodeContextState } from './nodeContextStore';

export default function configureStore(history: History, nodeContext: NodeContextState, initialState?: ApplicationState) {
    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: routerReducer(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const store = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    const disposeStore = initStore(store.dispatch, nodeContext);

    return { store, disposeStore };
}

//Force initial state from SSR
const routerReducer = (history: History) => {
    const initialState = {
        location: history.location,
        action: history.action,
    };
    return (state = initialState, arg: any = {}) => {
        if (arg.type === LOCATION_CHANGE) {
            return { ...state, ...arg.payload };
        }
        return state;
    }
};
