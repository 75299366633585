import { FC, MouseEventHandler } from "react";
import { Cn } from "@utils/BemUtils";
import { useLocalization } from "@store/localizationStore";

export type TableHeadCellProps = {
    cn: Cn;
    isSortable: boolean;
    onSortClick?: MouseEventHandler<HTMLButtonElement>;
    asc?: boolean;
    desc?: boolean;
    locKey: string;
}
const TableHeadCell: FC<TableHeadCellProps> = ({
    isSortable,
    onSortClick,
    cn,
    locKey,
    asc,
    desc
}) => {
    const { t, ts } = useLocalization();
    if (isSortable) {
        let title = '';
        if (asc || desc) {
            title = `${t(locKey)} ${asc ? t('GUI-280054') : t('GUI-280052')}`;
        }
        return (
            <button
                aria-label={title}
                className={cn.main({
                    '--asc': asc,
                    '--dsc': desc,
                })}
                onClick={onSortClick}>
                <div>
                    {ts(locKey)}
                </div>
            </button>
        );
    }

    return (
        <div>
            {ts(locKey)}
        </div>
    );
};

TableHeadCell.displayName = 'TableHeadCell';

export default TableHeadCell;