import React, { useCallback } from "react";
import { useLocalization } from "@store/localizationStore";
import FooterMenu from "@components/FooterMenu/FooterMenu";
import Icon from "@components/Icon/Icon";
import FooterContainer from "@components/Container/FooterContainer";
import Button from "@components/Button/Button";
import { useConstants } from "@constants/urlConstants";
import { setupCn } from "@utils/BemUtils";
import UniversalLink from "@components/UniversalLink/UniversalLink";
import FlexBox from "@components/FlexBox/FlexBox";
import { BsPencilSquare } from "react-icons/bs";
import { useAppSettings } from "@store/nodeContextStore";
import { pageIds } from "@components/Accessibility/pageIds";

import './Footer.scss'
import Note from "@components/Note/Note";

const cn = setupCn('gov-footer');

export const Footer: React.FC = () => {
    const { d, ts, t } = useLocalization();
    const { getUrl } = useConstants();
    const scrollUp = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const { appSettings, getManuals, getFaqUrl } = useAppSettings();
    const { anotherEnvironment, serviceDesk } = appSettings;
    const manualsSettings = getManuals();
    const faqUrl = getFaqUrl();

    const tel = t("NEN-767512");
    const telNoSpace = tel?.replace(/\s/g, '');

    return (
        <FooterContainer className={cn.with("u-bg-color--grey-dark")()} id={pageIds.footer}>
            <section className={cn.with("u-screen--only")("__upper")}>
                <FooterMenu locKey="NEN-767472" className={cn()}>
                    <li>
                        <FlexBox justify="start" className={cn("__row--inversed")}>
                            <Icon icon={BsPencilSquare} size="24" className={cn("__icon")} />
                            <UniversalLink
                                className={cn("__link")}
                                isInversed
                                isExternal
                                to={serviceDesk?.url}
                                title={d("NEN-749124")}>
                                {ts("NEN-749124")}
                            </UniversalLink>
                        </FlexBox>
                    </li>
                    <li>
                        <FlexBox justify="start" className={cn("__row--inversed")} title={t('NEN-645478')}>
                            <Icon iconName='phone' className={cn("__icon")} />
                            <div>
                                <UniversalLink
                                    className={cn(["__link", "__note"])}
                                    isInversed
                                    isExternal
                                    sameTarget
                                    to={'tel:' + telNoSpace}>
                                    <span aria-hidden='true' title={t('NEN-645446')}>{tel}</span>
                                    <span className='u-sr-only'>{telNoSpace}</span>
                                </UniversalLink>
                                <Note className={cn('__note')} aria-hidden='true'>{ts('NEN-767513')}</Note>
                                <span className='u-sr-only'>{t('NEN-786806')}</span>
                            </div>
                        </FlexBox>
                    </li>
                    <li>
                        <FlexBox justify="start" className={cn("__row--inversed")} title={t('NEN-645476')}>
                            <Icon iconName='mail' className={cn("__icon")} />
                            <UniversalLink className={cn("__link")} isInversed isExternal sameTarget to="mailto:hotline@nipez.cz" title={d("NEN-767356")}>{ts("NEN-767356")}</UniversalLink>
                        </FlexBox>
                    </li>
                </FooterMenu>
                <FooterMenu locKey="NEN-767460" className={cn()}>
                    <FooterMenu.Link isExternal to="https://portal-vz.cz/info-forum-vzdelavani/skoleni/" locKey="NEN-767461" />
                    <FooterMenu.Link to={getUrl('osobniUdajeACookies')} locKey="NEN-770159" />
                    <FooterMenu.Link isExternal to={manualsSettings.showAllUrl} locKey="NEN-767462" />
                    <FooterMenu.Link to={faqUrl} locKey="NEN-767463" isExternal />
                    <FooterMenu.Link to={getUrl('prohlaseniOPristupnosti')} locKey="NEN-767354" />
                    <FooterMenu.Link to="https://isvz.nipez.cz/reporty" locKey="NEN-767368" isExternal />
                </FooterMenu>
                <FooterMenu locKey="NEN-767464" className={cn()}>
                    {
                        anotherEnvironment?.linkLocKey && anotherEnvironment.url &&
                        <FooterMenu.Link isExternal to={anotherEnvironment.url} locKey={anotherEnvironment.linkLocKey} />
                    }
                    <FooterMenu.Link isExternal to="https://portal-vz.cz/" locKey="NEN-767467" />
                    <FooterMenu.Link isExternal to="https://eur-lex.europa.eu/oj/direct-access.html" locKey="NEN-767468" />
                    <FooterMenu.Link isExternal to="https://vvz.nipez.cz/" locKey="NEN-767469" />
                    <FooterMenu.Link isExternal to="https://isvz.nipez.cz/" locKey="NEN-767471" />
                    <FooterMenu.Link isExternal to="https://skd.nipez.cz" locKey="NEN-754139" />
                    <FooterMenu.Link isExternal to="https://download.tescosw.cz/crypto/" locKey="NEN-660632" />
                </FooterMenu>
            </section>
            <hr />
            <section className={cn("__lower")}>
                <p className="gov-footnote">
                    {ts("NEN-767362")}
                </p>
                <p className="gov-footnote">
                    {ts("NEN-767361")}
                </p>
            </section>
            <Button
                onClick={scrollUp}
                isOutlined
                isInversed
                className={cn("__scroll-up")}
                aria-label={t("NEN-767360")}
                title={t("NEN-767360")} />
        </FooterContainer>
    );
}