import React from 'react';
import LargeWidget from '@components/Widgets/LargeWidget';
import { useAppSettings } from '@store/nodeContextStore';

const SkoleniZzvz: React.FC = () => {
    const { getAllWidgetUrl } = useAppSettings();

    return <LargeWidget src={getAllWidgetUrl('skoleniZzvz')} />
}

SkoleniZzvz.displayName = 'SkoleniZzvz';

export default React.memo(SkoleniZzvz);