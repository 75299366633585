import { isEmptyOrWhitespace, notEmptyOrWhitespace } from "./ArrayHelper";

type SlashParams = {
    startSlash?: boolean;
    endSlash?: boolean;
}

type NormalizePathParams = SlashParams & {
    defaultValue?: string;
}

export const normalizePath = (path: string, options?: NormalizePathParams) => {
    const {
        defaultValue = '',
        endSlash = false,
        startSlash = false
    } = options ?? {};

    if (isEmptyOrWhitespace(path)) {
        path = defaultValue;
    }

    path = path?.replace(/^\/*(.*?)\/*$/, '$1');
    if (isEmptyOrWhitespace(path)) {
        return (startSlash || endSlash) ? '/' : path;
    }

    const start = startSlash ? '/' : '';
    const end = endSlash ? '/' : '';
    return `${start}${path}${end}`;
}

export function joinPath(...segments: string[]): string;
export function joinPath(options: SlashParams, ...segments: string[]): string;
export function joinPath(optionsOrSegment: string | SlashParams, ...segments: string[]): string {
    const options: SlashParams = typeof optionsOrSegment == 'string' ? {} : optionsOrSegment;
    const allSegments = typeof optionsOrSegment == 'string' ? [optionsOrSegment, ...segments] : segments;
    const path = allSegments
        .map((segment, index, { length }) => normalizePath(segment, {
            startSlash: index == 0 && segment.startsWith('/'),
            endSlash: (index == length - 1) && segment.endsWith('/')
        }))
        .filter(notEmptyOrWhitespace)
        .join('/');
    return normalizePath(path, options);
}
