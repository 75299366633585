import { Language } from "@services/LocalizationService";
import { useCurrentLanguage } from "@store/localizationStore";
import { useCallback } from "react";

export type SwitchByLanguageKeys = 'cz' | 'en';

export type SwitchByLanguage<T = string> = Record<SwitchByLanguageKeys, T>;

export const switchByLanguage = <T>(switchData: SwitchByLanguage<T>, language: Language) =>
    language == 'EN'
        ? switchData?.en
        : switchData?.cz;

export const useSwitchByLanguage = () => {
    const currentLanguage = useCurrentLanguage();

    const switchByLanguageCallback = useCallback<SwitchByLanguageResolver>(
        switchData => switchByLanguage(switchData, currentLanguage),
        [currentLanguage]);

    return {
        switchByLanguage: switchByLanguageCallback,
        currentLanguage,
    };
}

export type SwitchByLanguageResolver = <T>(switchData: SwitchByLanguage<T>) => T;
