import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import SkeletonPrimary from '@components/Skeleton/SkeletonPrimary';
import React, { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, ApplicationState, ApplicationStateSelector } from '.';
import LocalizationService, { Language as Language } from '@services/LocalizationService';
import { getErrorStoreActions } from './errorStore';
import { getLocalizationService, getNodeContext } from './nodeContextStore';
import { isNode } from '@utils/node-utils';
import { getUserCookieSettings } from './userCookieSettingsStore';

export const name = 'localization';

// Declare an interface of the store's state.
export type LocalizationItem = {
    code: string;
    text: string | null,
    description: string | null;
    isMissing?: boolean | null;
}

export type LanguageData = {
    [key: string]: LocalizationItem;
}

export type LanguagesData = Record<Language, LanguageData>;

export type LocalizationStoreState = {
    languages: LanguagesData;
    currentLanguage: Language;
}

// Create the slice.
export const slice = createSlice({
    name,
    initialState: {
        currentLanguage: LocalizationService.DEFAULT_LANGUAGE,
        languages: {}
    } as LocalizationStoreState,
    reducers: {
        init: (state, action: PayloadAction<Language>) => {
            return {
                ...state,
                currentLanguage: action.payload
            }
        },
        updateLanguageData: (state, action: PayloadAction<LanguagesData>) => {
            const languages = { ...state.languages };
            Object.keys(action.payload).forEach(language => {
                languages[language as Language] = {
                    ...languages[language as Language],
                    ...action.payload[language as Language]
                }
            })
            return { ...state, languages };
        },
        addLanguageData: (state, action: PayloadAction<{ language: Language; locItems: LocalizationItem[] }>) => {
            const language = { ...state.languages[action.payload.language] };
            action.payload.locItems
                .filter(item => item)
                .forEach(locItem => language[locItem.code] = locItem);
            return {
                ...state,
                languages: {
                    ...state.languages,
                    [action.payload.language]: language
                }
            };
        },
        changeLanguage: (state, action: PayloadAction<Language>) => {
            return {
                ...state,
                currentLanguage: action.payload
            }
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getLanguageState = (state: ApplicationState) => state[name];
export const getLanguagesData = createSelector([getLanguageState],
    (languageStore) => languageStore?.languages
);
export const getCurrentLanguage = createSelector([getLanguageState],
    (languageStore) => languageStore?.currentLanguage || LocalizationService.DEFAULT_LANGUAGE
);
export const getCurrentLanguageData = createSelector([getLanguagesData, getCurrentLanguage],
    (languagesData, currentLanguage) => currentLanguage ? languagesData?.[currentLanguage] : undefined
);
export const getLocItemFromLanguageData = (languageData: LanguageData | undefined, locKey: string) => languageData?.[locKey];
export const getLocItem = (state: ApplicationState, locKey: string) => getLocItemFromLanguageData(getCurrentLanguageData(state), locKey);


// Define action creators.
export const actionCreators = {
    getLocItem: (locKey: string) =>
        (dispatch: AppDispatch, getState: ApplicationStateSelector): LocalizationItem | null => {
            const appState = getState();
            const currentLanguage = getCurrentLanguage(appState) || LocalizationService.DEFAULT_LANGUAGE;
            const locItem = getLocItem(appState, locKey);
            const nodeContext = getNodeContext(appState);
            if (locItem) {
                return locItem;
            }

            const promise = nodeContext.localizationService
                .getLocalizationItems({ locKeys: [locKey], language: currentLanguage });

            promise.catch(e => {
                dispatch(getErrorStoreActions().push(e))
            })

            nodeContext.taskManager.addPromise(promise);

            return null;
        },
    t: (locKey: string, defaultText: string = '') =>
        (dispatch: AppDispatch): string => {
            return dispatch(actionCreators.getLocItem(locKey))?.text ?? defaultText ?? '';
        },
    d: (locKey: string, defaultText: string = '') =>
        (dispatch: AppDispatch): string => {
            return dispatch(actionCreators.getLocItem(locKey))?.description ?? defaultText ?? '';
        },
    changeLanguage: (language: Language) =>
        (dispatch: AppDispatch, getState: ApplicationStateSelector) => {
            const state = getState();
            if (getUserCookieSettings(state).settings.preferences)
                getLocalizationService(state).setLanguageToCookie(language);

            if (!isNode && document?.documentElement) {
                document.documentElement.lang = language == 'CZ' ? 'cs' : 'en';
                const manifest = document.head.querySelector<HTMLLinkElement>('[rel="manifest"]');
                if (manifest) {
                    manifest.href = manifest.href.replace(/\.(CZ|EN)\./, `.${language}.`);
                }
            }
            dispatch(slice.actions.changeLanguage(language));
        }
};

export type Stringable = {
    toString(): string;
}

export const convertParams = (text?: string | null, params?: Stringable[]) => {
    if (text == null || !params?.length) {
        return text;
    }
    for (let i = params.length - 1; i >= 0; i--) {
        text = text.replace(new RegExp(`@${i}`, 'g'), params[i]?.toString());
    }
    return text;
}

export const convertNodeParams = (text?: string | null, params?: ReactNode[]) => {
    if (text == null || !params?.length) {
        return text;
    }
    if (params.every(param => param == null || typeof param == 'string')) {
        return convertParams(text, params as string[]);
    }
    const tokens = text.split(/(@\d+)/g);
    return React.Children.toArray(tokens.map(token => {
        if (token.match(/@\d/)) {
            const n = Number(token.slice(1));
            return params[n];
        }
        return token;
    }));
}

const randomWidth = (minWidth?: number, maxWidth?: number) => {
    minWidth ??= 40;
    maxWidth ??= Math.max(minWidth, 120);
    minWidth = Math.min(minWidth || 40, maxWidth);
    return Math.floor(Math.random() * (maxWidth - minWidth)) + minWidth;
}

export type CreateSkeletonProps = {
    minWidth?: number;
    maxWidth?: number;
    width?: string | number | undefined;
    isPrimaryTheme?: boolean;
}

export const createSkeleton = (props?: CreateSkeletonProps) => {
    const SkeletonFactory = props?.isPrimaryTheme ? SkeletonPrimary : Skeleton;
    return <SkeletonFactory width={props?.width ?? randomWidth(props?.minWidth, props?.maxWidth)} />;
}

const createLocalizationToolkit = (dispatch: AppDispatch, currentLanguageData: LanguageData | undefined) => {
    const getLocalizationItem = (locKey: string): LocalizationItem | null => {
        const locItem = getLocItemFromLanguageData(currentLanguageData, locKey);
        if (locItem) {
            return locItem;
        }
        return dispatch(actionCreators.getLocItem(locKey));
    }
    const t = <T extends unknown = string>(locKey?: string | null, params?: { loadingElement?: T, locParams?: Stringable[] }) => locKey == null ? '' : (convertParams(getLocalizationItem(locKey)?.text, params?.locParams) ?? params?.loadingElement ?? '');
    const d = <T extends unknown = string>(locKey?: string | null, params?: { loadingElement?: T, locParams?: Stringable[] }) => locKey == null ? '' : (convertParams(getLocalizationItem(locKey)?.description, params?.locParams) ?? params?.loadingElement ?? '');
    const ts = (locKey?: string | null, params?: { locParams?: Stringable[] } & CreateSkeletonProps) => locKey == null ? '' : (convertNodeParams(getLocalizationItem(locKey)?.text, params?.locParams) ?? createSkeleton(params) ?? '');
    const ds = (locKey?: string | null, params?: { locParams?: Stringable[] } & CreateSkeletonProps) => locKey == null ? '' : (convertNodeParams(getLocalizationItem(locKey)?.description, params?.locParams) ?? createSkeleton(params) ?? '');
    return { t, d, ts, ds, getLocalizationItem };
}

export const useCurrentLanguage = () => useSelector(getCurrentLanguage);
export const useLocalization = () => createLocalizationToolkit(useDispatch(), useSelector(getCurrentLanguageData));
export const useLocalizationText = () => useLocalization().t;
export const useLocalizationDesc = () => useLocalization().d;

export type UseLocalizationType = ReturnType<typeof useLocalization>;
export type UseLocalizationTextType = ReturnType<typeof useLocalizationText>;
export type UseLocalizationDescType = ReturnType<typeof useLocalizationDesc>;
