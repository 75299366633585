import { isNode } from "./node-utils";

export class DescriptionManager {
    private _description: string = '';

    public get content(): string {
        return isNode
            ? this._description
            : (document.querySelector<HTMLMetaElement>('meta[name="description"]')?.content || '');
    }

    public set content(value: string) {
        this._description = value;
        if (isNode) {
            return;
        }
        const meta = document.querySelector<HTMLMetaElement>('meta[name="description"]');
        if (meta) {
            meta.content = value;
        }
    }
}
