import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "utils/BemUtils";
import { useLocalization } from "@store/localizationStore";
import { renderIf } from "@utils/RenderUtils";

const cn = setupCn('gov-content-block');

export type ContentBlockProps = PropsWithChildren<{
    className?: Classes;
    withEServiceIcon?: boolean;
    lockey?: string;
    height?: string;
}>;

const ContentBlock: FC<ContentBlockProps> = ({
    className,
    withEServiceIcon,
    children,
    lockey,
    height
}) => {
    const { d, ts } = useLocalization();

    return (
        <div className={cn.with(className).main({
            '--e-service-icon': withEServiceIcon
        })} title={d(lockey)} style={{ height: height }}>
            {
                renderIf(lockey, () => (
                    <>
                        <h2>{ts(lockey)}</h2>
                        <hr className={cn.subCn('__separator')} />
                    </>
                ))
            }
            {children}
        </div>
    );
};

ContentBlock.displayName = 'ContentBlock';

export default React.memo(ContentBlock);
