import * as qs from 'query-string';
import { generatePath, match, useHistory, useLocation, useRouteMatch } from 'react-router';
import * as H from 'history';
import { ParseOptions, StringifiableRecord } from 'query-string';
import { useCallback, useMemo } from 'react';
import { splitParam } from './StringUtils';
import { FilterParamsNames, paramsPrefix } from '@constants/urlConstants';

const parseParams: ParseOptions = {
    parseBooleans: false,
    parseNumbers: false,
    sort: false
};

export type UrlParamObject = { [param: string]: string | undefined };
export type UrlParamObjects = { [param: string]: UrlParamObject | undefined };

export const useQuery = <T extends {} = {}>() => {
    return qs.parse(useLocation().search, parseParams) as T;
}

export const parseUrlParams = (paramsString?: string) => {
    if (paramsString == null) {
        return undefined;
    }
    if (paramsPrefix && paramsString.startsWith(paramsPrefix)) {
        paramsString = paramsString.slice(paramsPrefix.length);
    }
    const result: UrlParamObjects = {};
    paramsString.split(';').forEach(paramString => {
        const [paramName, paramValue] = splitParam(paramString, ':');
        result[paramName] = qs.parse(`?${paramValue}`, parseParams) as UrlParamObject;
    });
    return result;
}

export const parseUrlFilterParams = (filterParam: string, paramsString?: string) => {
    return parseUrlParams(paramsString)?.[filterParam];
}

export type UpdateFilterParamProps = {
    update: StringifiableRecord | undefined;
}

export type UpdateQueryParamProps = {
    update: GenerateQueryPathParams | undefined;
}

export const getReplacedQueryParamUrl = ({ routeParamName, params, match, update }: UpdateQueryParamProps & GenerateQueryPathProps) => {
    if (!update) {
        return match?.url;
    }

    const updatedParams: GenerateQueryPathParams = { ...params };
    Object.entries(update).forEach(([paramKey, paramValues]) => {
        updatedParams[paramKey] = { ...paramValues }
    });

    return generateQueryPath({ match, routeParamName, params: updatedParams })
}

export const getUpdatedQueryParamUrl = ({ routeParamName, params, match, update }: UpdateQueryParamProps & GenerateQueryPathProps) => {
    if (!update) {
        return match?.url;
    }

    const updatedParams: GenerateQueryPathParams = { ...params };
    Object.entries(update).forEach(([paramKey, paramValues]) => {
        updatedParams[paramKey] = {
            ...updatedParams[paramKey],
            ...paramValues
        }
    });

    return generateQueryPath({ match, routeParamName, params: updatedParams })
}

export type GenerateQueryPathParams = { [key: string]: StringifiableRecord | undefined };
export type GenerateQueryPathProps = {
    routeParamName: string;
    params: GenerateQueryPathParams;
    match?: match<UrlParamObject> | null;
};

export const generateQueryPath = ({ params, routeParamName, match }: GenerateQueryPathProps) => {
    const paramStrings: string[] = [];
    Object.entries(params).forEach(([paramKey, paramValues]) => {
        if (!paramValues) {
            return;
        }
        const updateValues: StringifiableRecord = {};
        Object.entries(paramValues).forEach(([k, v]) => {
            if (v == 'none') {
                delete paramValues[k];
                return;
            }
            updateValues[k] = v && encodeURIComponent(v)
        });
        const queryParamsString = qs.stringify(updateValues, {
            encode: false,
            sort: false
        });
        if (queryParamsString != '') {
            paramStrings.push(`${paramKey}:${queryParamsString}`);
        }
    });

    const resultParamsString = paramStrings.join(';');
    return match?.path && routeParamName && generatePath(match.path, {
        ...match.params,
        [routeParamName]: resultParamsString == '' ? undefined : `${paramsPrefix}${resultParamsString}`
    }) || match?.url;
}

export type UpdateQueryParamUrl = GenerateQueryPathProps & {
    history: H.History;
}

export const replaceQueryParamUrl = (props: UpdateQueryParamUrl & UpdateQueryParamProps & { historyState?: any }) => {
    const path = getReplacedQueryParamUrl(props);
    if (path && props.history) {
        props.history.push(path, props.historyState);
    }
}

export const updateQueryParamUrl = (props: UpdateQueryParamUrl & UpdateQueryParamProps & { historyState?: any }) => {
    const path = getUpdatedQueryParamUrl(props);
    if (path && props.history) {
        props.history.push(path, props.historyState);
    }
}

const addFilterParamName = ({ update, ...otherParams }: UpdateFilterParamProps, filterParamName: string): UpdateQueryParamProps => ({
    ...otherParams,
    update: {
        [filterParamName]: update
    }
});

export const useUrlParams = ({ paramNames }: { paramNames: FilterParamsNames; }) => {
    const { filterParamName, routeParamName } = paramNames;

    const match = useRouteMatch<UrlParamObject>();

    const allUrlParams = useMemo(() => parseUrlParams(match?.params[routeParamName]) || {},
        [match?.params, routeParamName]);

    const filterParams = useMemo(() => allUrlParams[filterParamName],
        [allUrlParams, filterParamName]);

    return useMemo(() => ({
        allUrlParams,
        filterParams,
        match,
    }), [allUrlParams, filterParams, match]);
}

export const useUrlParamsExtended = ({ paramNames }: { paramNames: FilterParamsNames; }) => {
    const urlParamsBase = useUrlParams({ paramNames });
    const { allUrlParams, match } = urlParamsBase;
    const { filterParamName, routeParamName } = paramNames;
    const history = useHistory();

    const updateParams: UpdateQueryParamUrl = useMemo(() => ({ history, match, params: allUrlParams, routeParamName }),
        [history, match, allUrlParams, routeParamName]);

    const updateUrl = useCallback((params: UpdateQueryParamProps) =>
        updateQueryParamUrl({ ...updateParams, ...params }),
        [updateParams]);

    const getUpdatedUrl = useCallback((params: UpdateQueryParamProps) =>
        getUpdatedQueryParamUrl({ ...updateParams, ...params }),
        [updateParams]);

    const updateFilterUrl = useCallback((params: UpdateFilterParamProps) =>
        updateQueryParamUrl({ ...updateParams, ...addFilterParamName(params, filterParamName) }),
        [updateParams, filterParamName]);

    const getUpdatedFilterUrl = useCallback((params: UpdateFilterParamProps) =>
        getUpdatedQueryParamUrl({ ...updateParams, ...addFilterParamName(params, filterParamName) }),
        [updateParams, filterParamName]);

    const replaceUrlParams = useCallback((params: UpdateQueryParamProps) =>
        replaceQueryParamUrl({ ...updateParams, ...params }),
        [updateParams]);

    const getReplacedParamsUrl = useCallback((params: UpdateQueryParamProps) =>
        getReplacedQueryParamUrl({ ...updateParams, ...params }),
        [updateParams]);

    const replaceFilterUrlParams = useCallback((params: UpdateFilterParamProps) =>
        replaceQueryParamUrl({ ...updateParams, ...addFilterParamName(params, filterParamName) }),
        [updateParams, filterParamName]);

    const getReplacedFilterParamsUrl = useCallback((params: UpdateFilterParamProps) =>
        getReplacedQueryParamUrl({ ...updateParams, ...addFilterParamName(params, filterParamName) }),
        [updateParams, filterParamName]);

    return useMemo(() => ({
        ...urlParamsBase,
        updateUrl,
        updateFilterUrl,
        getUpdatedUrl,
        getUpdatedFilterUrl,
        replaceUrlParams,
        getReplacedParamsUrl,
        replaceFilterUrlParams,
        getReplacedFilterParamsUrl,
    }), [
        urlParamsBase,
        updateUrl,
        updateFilterUrl,
        getUpdatedUrl,
        getUpdatedFilterUrl,
        replaceUrlParams,
        getReplacedParamsUrl,
        replaceFilterUrlParams,
        getReplacedFilterParamsUrl,
    ]);
}
