import React, { FC, PropsWithChildren } from "react"
import { Classes, setupCn } from "@utils/BemUtils";
import { default as FilterManagerBase, FilterManagerType } from "@components/FilterManager";
import { FilterParamsNames, urlConstants } from "@constants/urlConstants";
import { useLocalization } from "@store/localizationStore";
import { NIPEZModelAttrMap } from "@models/enumerators/INIPEZModel";
import { ISubjektModel } from "@models/ISubjektModel";
import { kodNazevAttrs, lovCPVConditionFactory, lovNIPEZConditionFactory } from "@models/filters/NipezFilters";
import { CPVModelAttrMap } from "@models/enumerators/ICPVModel";
import { PravniFormaAttrMap } from "@models/enumerators/IPravniFormaModel";
import { NUTSModelAttrMap } from "@models/enumerators/INUTSModel";

const cn = setupCn('registry-dodavatelu-filter');

const FilterManager = FilterManagerBase as FilterManagerType<keyof ISubjektModel>;

export type RegistrDodavateluFilterProps = PropsWithChildren<{
    id: string;
    className?: Classes;
    paramNames?: FilterParamsNames;
}>;

const lovNipezConditonFactoryFunction = lovNIPEZConditionFactory('cpvNipezSubjektu');
const lovCPVConditonFactoryFunction = lovCPVConditionFactory('nipezSubjektu');

const RegistryDodavateluFilter: FC<RegistrDodavateluFilterProps> = ({
    id,
    className,
    paramNames
}) => {
    const { ts } = useLocalization();

    return (
        <FilterManager
            id={id}
            className={cn.with(className)()}
            paramNames={paramNames ?? urlConstants.registrDodavatelu.paramNames}>
            <FilterManager.GroupsContainer id='groups' size='small'>
                <FilterManager.Group id='item' header={ts('NEN-812848')} defaultVisible noTopBorder>
                    <FilterManager.Row>
                        <FilterManager.Lov
                            headerText={ts('NEN-743015')}
                            name='nipezSubjektu'
                            attrMapping={NIPEZModelAttrMap}
                            showAttrs={kodNazevAttrs}
                            condition={lovNipezConditonFactoryFunction} />
                        <FilterManager.Lov
                            headerText={ts('NEN-743016')}
                            name='cpvNipezSubjektu'
                            attrMapping={CPVModelAttrMap}
                            showAttrs={kodNazevAttrs}
                            condition={lovCPVConditonFactoryFunction} />
                    </FilterManager.Row>
                </FilterManager.Group>
                <FilterManager.Group id='contractor' header={ts('NEN-751972')}>
                    <FilterManager.Row>
                        <FilterManager.Input name='nazevOrganizace' headerText={ts('NEN-812506')} />
                        <FilterManager.Input name='ico' headerText={ts('NEN-812511')} />
                    </FilterManager.Row>
                    <FilterManager.Row>
                        <FilterManager.Lov
                            headerText={ts('NEN-812869')}
                            name='pravniForma'
                            attrMapping={PravniFormaAttrMap}
                            showAttrs='nazev'
                            condition='(Plati_od=null or Plati_od <= DB.getDate()) and (Plati_do=null or  DB.getDate() <= Plati_do)' />
                        <FilterManager.Lov
                            headerText={ts('NEN-812868')}
                            name='NUTS'
                            attrMapping={NUTSModelAttrMap}
                            showAttrs={kodNazevAttrs}
                            condition='(Plati_od=NULL OR Plati_od <= DB.getDateTime()) AND (Plati_do=NULL OR Plati_do >= DB.getDateTime())' />
                    </FilterManager.Row>
                    <FilterManager.Row>
                        <FilterManager.Input name='obec' headerText={ts('NEN-812866')} />
                        <FilterManager.Input name='castObce' headerText={ts('NEN-812865')} />
                        <FilterManager.Input name='ulice' headerText={ts('NEN-812867')} />
                    </FilterManager.Row>
                </FilterManager.Group>
            </FilterManager.GroupsContainer>
        </FilterManager>
    );
};

export default React.memo(RegistryDodavateluFilter);
