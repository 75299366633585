import * as CollectionStore from './collectionStore';
import * as DetailObjecStore from './detailObjectStore';
import * as Localization from './localizationStore';
import * as NodeContextStore from './nodeContextStore';
import * as BreadcrumbStore from './breadcrumbStore';
import * as BreadcrumbTitleStore from './breadcrumbTitleStore';
import * as EnumDescriptionsStore from './enumDescriptionsStore';
import * as ErrorsStore from './errorStore';
import * as StatisticsStore from './statisticsStore';
import * as UserCookieSettingsStore from './userCookieSettingsStore';
import * as WidgetStore from './WidgetStore/widgetStore';

import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Store } from 'redux';
import LocalizationService, { Language } from '@services/LocalizationService';
import { isNode } from '@utils/node-utils';
import EnumDescriptionService, { EnumDescription } from '@services/EnumDescriptionService';
import { EventCallback } from '@utils/Events';
import { LocalizationItem } from './localizationStore';


// The top-level state object
export type ApplicationState = {
    [Localization.name]: Localization.LocalizationStoreState;
    [NodeContextStore.name]: NodeContextStore.NodeContextState;
    [BreadcrumbStore.name]: BreadcrumbStore.BreadcrumbStoreState;
    [EnumDescriptionsStore.name]: EnumDescriptionsStore.EnumDescriptionsState;
    [ErrorsStore.name]: ErrorsStore.ErrorStoreState;
    [CollectionStore.name]: CollectionStore.CollectionStoreState,
    [DetailObjecStore.name]: DetailObjecStore.DetailObjectStoreState,
    [StatisticsStore.name]: StatisticsStore.StatisticsState,
    [BreadcrumbTitleStore.name]: BreadcrumbTitleStore.BreadcrumbTitleStoreState,
    [UserCookieSettingsStore.name]: UserCookieSettingsStore.UserCookieSettingsState,
    [WidgetStore.name]: WidgetStore.WidgetStore,
}

export type ApplicationStateSelector = () => ApplicationState;

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    [Localization.name]: Localization.reducer,
    [NodeContextStore.name]: NodeContextStore.reducer,
    [BreadcrumbStore.name]: BreadcrumbStore.reducer,
    [EnumDescriptionsStore.name]: EnumDescriptionsStore.reducer,
    [ErrorsStore.name]: ErrorsStore.reducer,
    [CollectionStore.name]: CollectionStore.reducer,
    [DetailObjecStore.name]: DetailObjecStore.reducer,
    [StatisticsStore.name]: StatisticsStore.reducer,
    [BreadcrumbTitleStore.name]: BreadcrumbTitleStore.reducer,
    [UserCookieSettingsStore.name]: UserCookieSettingsStore.reducer,
    [WidgetStore.name]: WidgetStore.widgetRootReducer
};

export const initStore = (dispatch: AppDispatch, nodeContext: NodeContextStore.NodeContextState) => {
    dispatch(NodeContextStore.slice.actions.init(nodeContext));
    dispatch(UserCookieSettingsStore.slice.actions.setUserCookieSettings(UserCookieSettingsStore.getUserCookieSettingsFromCookie(nodeContext.http)));
    const language = nodeContext.localizationService.getLanguageFromCookie();
    dispatch(Localization.slice.actions.init(language));

    const onLocLoaded: EventCallback<LocalizationService, { language: Language; locItems: LocalizationItem[] }> = (_, payload) => {
        dispatch(Localization.slice.actions.addLanguageData(payload));
    };
    LocalizationService.localizationsLoaded.add(onLocLoaded);

    const onEnumDescriptionLoaded: EventCallback<EnumDescriptionService, EnumDescription[]> = (_, payload) => {
        dispatch(EnumDescriptionsStore.slice.actions.addDescriptions(payload));
    };
    EnumDescriptionService.enumDescriptionsLoaded.add(onEnumDescriptionLoaded);
    if (isNode) {
        dispatch(Localization.slice.actions.addLanguageData({
            language,
            locItems: LocalizationService.getAllLocItems(language)
        }));
        dispatch(EnumDescriptionsStore.slice.actions.addDescriptions(EnumDescriptionService.getAllDescriptions()));
    }

    return () => {
        LocalizationService.localizationsLoaded.remove(onLocLoaded);
        EnumDescriptionService.enumDescriptionsLoaded.remove(onEnumDescriptionLoaded);
    }
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppDispatch = ThunkDispatch<ApplicationState, any, AnyAction>;
export type AppThunkAction = (dispatch: AppDispatch, getState: ApplicationStateSelector) => void;
export type AppStore = Store<ApplicationState>;

export function withStore<TStoreState, TActionCreators, TComponent extends React.ComponentType<TStoreState & TActionCreators & any>>(
    component: TComponent,
    stateSelector: (state: ApplicationState) => TStoreState,
    actionCreators: TActionCreators
): TComponent {
    return connect(stateSelector, actionCreators)(component) as any as TComponent;
}