import Tabs from "@components/Tabs";
import React, { FC } from "react"
import { urlConstants } from "@constants/urlConstants";
import RegistrDodavatelu from "../registrySubjektu/RegistryDodavatelu";
import RegistrZadavatelu from "../registrySubjektu/RegistryZadavatelu";

const RegistryPages: FC = () => {
    return (
        <Tabs
            noMargin
            tabs={
                [
                    {
                        lockey: 'NEN-602386',
                        path: urlConstants.registrZadavatelu.path,
                        component: RegistrZadavatelu
                    },
                    {
                        lockey: 'NEN-602387',
                        path: urlConstants.registrDodavatelu.path,
                        component: RegistrDodavatelu
                    },
                ]
            } />
    );
};

RegistryPages.displayName = 'RegistryPages';

export default React.memo(RegistryPages);
