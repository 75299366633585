import Button from "@components/Button/Button";
import Icon from "@components/Icon/Icon";
import GovLink from "@components/GovLink/GovLink";
import { FC } from "react";
import { RouteComponentProps } from "react-router";
import { usePageTitle } from "@utils/PageTitleHook";
import { useLocalization } from "@store/localizationStore";
import { setupCn } from "@utils/BemUtils";
import Accordion from "@components/Accordion";
import { useConstants } from "@constants/urlConstants";

const cn = setupCn('design');

const Design: FC<RouteComponentProps> = () => {
    const { ts, t } = useLocalization();
    usePageTitle(t('764292'));
    const { getUrl } = useConstants();

    return (
        <div className={cn.main()}>
            <h2 className={cn('__header')}>{ts('NEN-764293', { width: 500 })}</h2>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Button size='small'>Small</Button>
                <Button>Normal</Button>
                <Button size='large'>Big</Button>

                <Button disabled size='small'>Small</Button>
                <Button disabled>Normal</Button>
                <Button disabled size='large'>Big</Button>

                <Button isOutlined size='small'>Small</Button>
                <Button isOutlined>Normal</Button>
                <Button isOutlined size='large'>Big</Button>

                <Button isOutlined disabled size='small'>Small</Button>
                <Button isOutlined disabled>Normal</Button>
                <Button isOutlined disabled size='large'>Big</Button>
            </div>
            <div style={{ backgroundColor: '#2362a2', padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Button isInversed size='small'>Small</Button>
                <Button isInversed>Normal</Button>
                <Button isInversed size='large'>Big</Button>

                <Button disabled isInversed size='small'>Small</Button>
                <Button disabled isInversed>Normal</Button>
                <Button disabled isInversed size='large'>Big</Button>

                <Button isOutlined isInversed size='small'>Small</Button>
                <Button isOutlined isInversed>Normal</Button>
                <Button isOutlined isInversed size='large'>Big</Button>

                <Button isOutlined disabled isInversed size='small'>Small</Button>
                <Button isOutlined disabled isInversed>Normal</Button>
                <Button isOutlined disabled isInversed size='large'>Big</Button>
            </div>

            <h2 className={cn('__header')}>{ts('NEN-764389', { width: 500 })}</h2>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} size='small'>Small</GovLink>
                <GovLink to={getUrl('design')}>Normal</GovLink>
                <GovLink to={getUrl('design')} size='large'>Big</GovLink>

                <GovLink to={getUrl('design')} disabled size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} disabled>Normal</GovLink>
                <GovLink to={getUrl('design')} disabled size='large'>Big</GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} size='small'><Icon iconName='place' />Small</GovLink>
                <GovLink to={getUrl('design')}><Icon iconName='place' />Normal</GovLink>
                <GovLink to={getUrl('design')} size='large'><Icon iconName='place' />Big</GovLink>

                <GovLink to={getUrl('design')} disabled size='small'><Icon iconName='place' />Small</GovLink>
                <GovLink to={getUrl('design')} disabled><Icon iconName='place' />Normal</GovLink>
                <GovLink to={getUrl('design')} disabled size='large'><Icon iconName='place' />Big</GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')}>Normal<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')} size='large'>Big<Icon iconName='lightbulb' /></GovLink>

                <GovLink to={getUrl('design')} disabled size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')} disabled>Normal<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')} disabled size='large'>Big<Icon iconName='lightbulb' /></GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} isExternal size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isExternal>Normal</GovLink>
                <GovLink to={getUrl('design')} isExternal size='large'>Big</GovLink>

                <GovLink to={getUrl('design')} isExternal disabled size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isExternal disabled>Normal</GovLink>
                <GovLink to={getUrl('design')} isExternal disabled size='large'>Big</GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} hasArrow size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} hasArrow>Normal</GovLink>
                <GovLink to={getUrl('design')} hasArrow size='large'>Big</GovLink>

                <GovLink to={getUrl('design')} hasArrow disabled size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} hasArrow disabled>Normal</GovLink>
                <GovLink to={getUrl('design')} hasArrow disabled size='large'>Big</GovLink>
            </div>

            <h3>Standalone</h3>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} isStandalone size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone>Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone size='large'>Big</GovLink>

                <GovLink to={getUrl('design')} isStandalone disabled size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone disabled>Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone disabled size='large'>Big</GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} isStandalone size='small'><Icon iconName='place' />Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone><Icon iconName='place' />Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone size='large'><Icon iconName='place' />Big</GovLink>

                <GovLink to={getUrl('design')} isStandalone disabled size='small'><Icon iconName='place' />Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone disabled><Icon iconName='place' />Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone disabled size='large'><Icon iconName='place' />Big</GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} isStandalone size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')} isStandalone>Normal<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')} isStandalone size='large'>Big<Icon iconName='lightbulb' /></GovLink>

                <GovLink to={getUrl('design')} isStandalone disabled size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')} isStandalone disabled>Normal<Icon iconName='lightbulb' /></GovLink>
                <GovLink to={getUrl('design')} isStandalone disabled size='large'>Big<Icon iconName='lightbulb' /></GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} isStandalone isExternal size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone isExternal>Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone isExternal size='large'>Big</GovLink>

                <GovLink to={getUrl('design')} isStandalone isExternal disabled size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone isExternal disabled>Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone isExternal disabled size='large'>Big</GovLink>
            </div>
            <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <GovLink to={getUrl('design')} isStandalone hasArrow size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone hasArrow>Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone hasArrow size='large'>Big</GovLink>

                <GovLink to={getUrl('design')} isStandalone hasArrow disabled size='small'>Small</GovLink>
                <GovLink to={getUrl('design')} isStandalone hasArrow disabled>Normal</GovLink>
                <GovLink to={getUrl('design')} isStandalone hasArrow disabled size='large'>Big</GovLink>
            </div>

            <div style={{ backgroundColor: '#2362a2' }}>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed size='large'>Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed disabled size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed disabled>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed disabled size='large'>Big</GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed size='small'><Icon iconName='place' />Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed><Icon iconName='place' />Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed size='large'><Icon iconName='place' />Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed disabled size='small'><Icon iconName='place' />Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed disabled><Icon iconName='place' />Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed disabled size='large'><Icon iconName='place' />Big</GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed>Normal<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed size='large'>Big<Icon iconName='lightbulb' /></GovLink>

                    <GovLink to={getUrl('design')} isInversed disabled size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed disabled>Normal<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed disabled size='large'>Big<Icon iconName='lightbulb' /></GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed isExternal size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isExternal>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isExternal size='large'>Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed isExternal disabled size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isExternal disabled>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isExternal disabled size='large'>Big</GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed hasArrow size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed hasArrow>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed hasArrow size='large'>Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed hasArrow disabled size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed hasArrow disabled>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed hasArrow disabled size='large'>Big</GovLink>
                </div>
                <h3 style={{ color: 'white' }}>Standalone</h3>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed isStandalone size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone size='large'>Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed isStandalone disabled size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone disabled>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone disabled size='large'>Big</GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed isStandalone size='small'><Icon iconName='place' />Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone><Icon iconName='place' />Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone size='large'><Icon iconName='place' />Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed isStandalone disabled size='small'><Icon iconName='place' />Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone disabled><Icon iconName='place' />Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone disabled size='large'><Icon iconName='place' />Big</GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed isStandalone size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone>Normal<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone size='large'>Big<Icon iconName='lightbulb' /></GovLink>

                    <GovLink to={getUrl('design')} isInversed isStandalone disabled size='small'>Small<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone disabled>Normal<Icon iconName='lightbulb' /></GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone disabled size='large'>Big<Icon iconName='lightbulb' /></GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed isStandalone isExternal size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone isExternal>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone isExternal size='large'>Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed isStandalone isExternal disabled size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone isExternal disabled>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone isExternal disabled size='large'>Big</GovLink>
                </div>
                <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <GovLink to={getUrl('design')} isInversed isStandalone hasArrow size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone hasArrow>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone hasArrow size='large'>Big</GovLink>

                    <GovLink to={getUrl('design')} isInversed isStandalone hasArrow disabled size='small'>Small</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone hasArrow disabled>Normal</GovLink>
                    <GovLink to={getUrl('design')} isInversed isStandalone hasArrow disabled size='large'>Big</GovLink>
                </div>
            </div>

            <h2 className={cn('__header')}>Accordion</h2>
            <div style={{ padding: '6rem' }}>
                <Accordion id='accordion-base'>
                    <Accordion.Page header='Accordion base 1' id='accordion-base1'>
                        První obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion base 2' id='accordion-base2'>
                        Druhý obsah
                    </Accordion.Page>
                    <Accordion.Page isExpanded header='Accordion base 3 - expanded' id='accordion-base3'>
                        Třetí obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion base 4' id='accordion-base4'>
                        Čtvrtý obsah
                    </Accordion.Page>
                </Accordion>
            </div>

            <h3>Bordered</h3>
            <div style={{ padding: '6rem' }}>
                <Accordion isBordered id='accordion-bordered'>
                    <Accordion.Page header='Accordion bordered 1' id='accordion-bordered1'>
                        První obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion bordered 2' id='accordion-bordered2'>
                        Druhý obsah
                    </Accordion.Page>
                    <Accordion.Page isExpanded header='Accordion bordered 3 - expanded' id='accordion-bordered3'>
                        Třetí obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion bordered 4' id='accordion-bordered4'>
                        Čtvrtý obsah
                    </Accordion.Page>
                </Accordion>
            </div>

            <h3>As sidebar</h3>
            <div style={{ padding: '6rem' }}>
                <Accordion asSidebar id='accordion-as-sidebar'>
                    <Accordion.Page header='Accordion as sidebar 1' id='accordion-as-sidebar1'>
                        První obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion as sidebar 2' id='accordion-as-sidebar2'>
                        Druhý obsah
                    </Accordion.Page>
                    <Accordion.Page isExpanded header='Accordion as sidebar 3 - expanded' id='accordion-as-sidebar3'>
                        Třetí obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion as sidebar 4' id='accordion-as-sidebar4'>
                        Čtvrtý obsah
                    </Accordion.Page>
                </Accordion>
            </div>

            <h3>As section</h3>
            <div style={{ padding: '6rem' }}>
                <Accordion asSection id='accordion-as-section'>
                    <Accordion.Page header='Accordion as section 1' id='accordion-as-section1'>
                        První obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion as section 2' id='accordion-as-section2'>
                        Druhý obsah
                    </Accordion.Page>
                    <Accordion.Page isExpanded header='Accordion as section 3 - expanded' id='accordion-as-section3'>
                        Třetí obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion as section 4' id='accordion-as-section4'>
                        Čtvrtý obsah
                    </Accordion.Page>
                </Accordion>
            </div>

            <h3>No inner offset</h3>
            <div style={{ padding: '6rem' }}>
                <Accordion noInnerOffset id='accordion-no-inner-offset'>
                    <Accordion.Page header='Accordion no inner offset 1' id='accordion-no-inner-offset1'>
                        První obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion no inner offset 2' id='accordion-no-inner-offset2'>
                        Druhý obsah
                    </Accordion.Page>
                    <Accordion.Page isExpanded header='Accordion no inner offset 3 - expanded' id='accordion-no-inner-offset3'>
                        Třetí obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion no inner offset 4' id='accordion-no-inner-offset4'>
                        Čtvrtý obsah
                    </Accordion.Page>
                </Accordion>
            </div>

            <h3>No inner Y offset</h3>
            <div style={{ padding: '6rem' }}>
                <Accordion noInnerYOffset id='accordion-no-inner-y-offset'>
                    <Accordion.Page header='Accordion no inner y offset 1' id='accordion-no-inner-y-offset1'>
                        První obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion no inner y offset 2' id='accordion-no-inner-y-offset2'>
                        Druhý obsah
                    </Accordion.Page>
                    <Accordion.Page isExpanded header='Accordion no inner y offset 3 - expanded' id='accordion-no-inner-y-offset3'>
                        Třetí obsah
                    </Accordion.Page>
                    <Accordion.Page header='Accordion no inner y offset 4' id='accordion-no-inner-y-offset4'>
                        Čtvrtý obsah
                    </Accordion.Page>
                </Accordion>
            </div>
        </div>
    );
}

export default Design;
