import { setupCn } from "@utils/BemUtils";
import nenlogo from 'images/nen_logo_white.svg';
import './Pictogram.scss'

const cn = setupCn('gov-pictogram');

const Pictogram: React.FC = () => {
    return (
        <div className={cn.main('--inversed')}>
            <img src={nenlogo} alt="logo NEN"/>
        </div>
    );
}

export default Pictogram;