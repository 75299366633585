import React, { FC, PropsWithChildren, ReactNode } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import EnumChecks, { EnumOrder } from "@components/EnumChecks/EnumChecks";
import { useFilterManagerContext } from "../FilterManager";
import { useId } from "@utils/UseId";
import FilterHeader from "../FilterHeader";

const cn = setupCn('filter-manager-enum');

export type FilterManagerEnumProps<TAttr extends string = string> = PropsWithChildren<{
    id?: string;
    headerText?: ReactNode;
    className?: Classes;
    name: TAttr;
    oneColumn?: boolean;
    enumName: string;
    order?: EnumOrder;
    hiddenValues?: string[];
}>;

export type FilterManagerEnumType<TAttr extends string = string> = FC<FilterManagerEnumProps<TAttr>>;

const FilterManagerEnum: FilterManagerEnumType = ({
    id,
    className,
    headerText,
    enumName,
    oneColumn,
    hiddenValues,
    order,
    name
}) => {
    const {
        filterValues,
        onFilterValueChange
    } = useFilterManagerContext(name);
    const { controlId } = useId({ id: `enum-checks-${id ?? name}` });
    return (
        <div className={cn.with(className).main()}>
            <FilterHeader withSpace>{headerText}</FilterHeader>
            <EnumChecks
                id={controlId}
                name={name}
                enumName={enumName}
                onChange={onFilterValueChange}
                value={name && filterValues[name]}
                hiddenValues={hiddenValues}
                oneColumn={oneColumn}
                order={order} />
        </div>
    );
};

FilterManagerEnum.displayName = 'FilterManagerEnum';

export default React.memo(FilterManagerEnum);
