import React from "react";
import { useRouteMatch } from "react-router";
import { DetailFrame } from "@components/DetailFrame/DetailFrame";
import { urlConstants } from "@constants/urlConstants";
import { UrlParamObject } from "@utils/QueryHook";
import GroupBox from "@components/GroupBox";
import { DataLabelTyped } from "@components/DataLabel";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { useDetailDescription } from "@utils/DescriptionHook";
import { columnWidths, DependentListFrameTyped, ListFrameColumn } from "@components/ListFrame";
import { downloadButtonOnly } from "@components/Table/Buttons/DownloadAllToolbarButton";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { useStaticObject } from "@utils/ReactUtils";
import { detailLinks } from "@constants/detailLinks";

const detailUverDokumLoadkey = 'detail-dokument';

const DataLabel = DataLabelTyped<IUverejnenyDokumModel>();
const DependentListFrame = DependentListFrameTyped<IUverejnenyDokumModel>();

const DetailUverDokum: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const id = (match?.params[urlConstants.detailUverDokum.detailIdParam] || '');
    const route = urlConstants.detailUverDokum.urlRouteParam;
    return (
        <DetailFrame
            condition='ID=@0'
            conditionParams={[id]}
            attrMapping={UverejnenyDokumAttrMap}
            loadKey={detailUverDokumLoadkey}
        >
            <DetailDokum routeParamName={route} />
        </DetailFrame>
    );
}
const DetailDokum: React.FC<{ routeParamName: string }> = ({ routeParamName }) => {
    useDetailDescription<IUverejnenyDokumModel>([
        {
            lockey: 'NEN-812592'
        },
        {
            lockey: 'NEN-812682',
            attrName: 'typDokument'
        },
        {
            lockey: 'NEN-812506',
            attrName: 'nazev'
        },
        {
            lockey: 'NEN-812748',
            attrName: 'odkazLW'
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812852">
                <DataLabel lockey="NEN-812571" attrName='nazevPolozky' />
                <DataLabel lockey="NEN-812880" attrName='cisloSmlouva' />
                <DataLabel lockey="NEN-812563" attrName='nazev' />
                <DataLabel lockey="NEN-812682" attrName='typDokument' />
                <DataLabel lockey="NEN-812645" attrName='datumUver' />
                <DataLabel lockey="NEN-812719" attrName='uverejnil' />
                <DataLabel lockey="NEN-812722" attrName='datumOduverejneni' />
                <DataLabel lockey="NEN-812721" attrName='oduverejnil' />
                <DataLabel lockey="NEN-812715" attrName='antivir' />
                <DataLabel lockey="NEN-812591" attrName='soubor' />
                <DataLabel lockey="NEN-812590" attrName='velikost' />
                <DataLabel lockey="NEN-812589" attrName='format' />
                <DataLabel lockey="NEN-812748" attrName='odkazLW' fillGrid />
            </GroupBox>
            <GroupBox lockey="NEN-810144" fillContent showIf='exists(Uverejneny_dokum, Podpis = &ID and Uverejnen = true)'>
                <ListFramePodpis routeParamName={routeParamName} />
            </GroupBox>
        </>
    );
}
const podpisModelColumns: ListFrameColumn<IUverejnenyDokumModel>[] = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'podpisDatumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];

const ListFramePodpis: React.FC<{ routeParamName: string }> = ({
    routeParamName }) => {
    return (
        <DependentListFrame
            lightVersion
            listFrameKey={detailUverDokumLoadkey + '-podpis'}
            condition="Podpis=@0 and Uverejnen=true"
            conditionParams={['id']}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'ext', routeParamName })}
            detailLinkFactory={detailLinks.getDetailExtPodpisLinkFactory()}
            columns={podpisModelColumns}
            extraButtons={downloadButtonOnly} />
    );

}

export default DetailUverDokum;