import { HTTPStatus } from "MultiWebClient/MWCore/TescoSW/Global/Base/Comunication/HTTPStatus";
import { Result, ServiceBase } from "./ServiceBase";

export type WidgetLanguage = 'cs' | 'en';

export default class WidgetService extends ServiceBase {

    public async loadData<T>(
        endPoint: string,
        queryFilter: string = "",
        language: WidgetLanguage = "cs",
        controller?: AbortController): Promise<Result<T>> {
        return await (await this.requestJson<T>({
            url: `/api/widget/${endPoint}`,
            method: 'GET',
            abortSignal: controller?.signal,
            data: { language, queryFilter }
        }));
    }

    public async newsSubscribe(controller: AbortController, email: string, language: WidgetLanguage = "cs"): Promise<Result<HTTPStatus>> {
        return await (await this.requestJson({
            url: `/api/widget/newsSubscribe`,
            method: 'POST',
            abortSignal: controller?.signal,
            data: { email, language }
        }));
    }
}
