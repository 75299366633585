import React, { FC, PropsWithChildren } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import './Text.scss';

const cn = setupCn('text');

export type TextProps = PropsWithChildren<{
    className?: Classes;
    title?: string;
    isBold?: boolean;
}> & React.HTMLProps<HTMLParagraphElement>;

const Text: FC<TextProps> = ({
    className,
    isBold,
    title,
    children
}) => {
    return (
        <p title={title} className={cn.with(className).main({
            '--bold': isBold,
        })}>
            {children}
        </p>
    );
};

Text.displayName = 'Text';

export default React.memo(Text);
