export const inferredPartial = <T extends unknown>() => <TPartial extends Partial<T>>(props: TPartial): TPartial => props;

export type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>

export type ObjectToCamel<T> = {
    [K in keyof T as CamelCase<string & K>]: T[K] extends Record<string, any> ? KeysToCamelCase<T[K]> : T[K]
}

export type KeysToCamelCase<T> = {
    [K in keyof T as CamelCase<string & K>]: T[K] extends Array<any> ? KeysToCamelCase<T[K][number]>[] : ObjectToCamel<T[K]>
}

export type KeyArray = Readonly<string[]>;
export type ValuesFromArray<arr extends Readonly<string[]>, T> = ObjectToCamel<{ [A in arr[number]]: T }>;
export type ArrayValues<arr extends KeyArray> = keyof {
    [K in arr[number]]: unknown
}

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export type WithPrefix<arr extends KeyArray, TPrefix extends string> = keyof {
    [K in arr[number]as `${TPrefix}${K}`]: null
}

export type WithPostfix<arr extends KeyArray, TPostfix extends string> = keyof {
    [K in arr[number]as `${K}${TPostfix}`]: null
}

export type WrappedString<arr extends KeyArray, TPrefix extends string, TPostfix extends string = ''> = keyof {
    [K in arr[number]as `${TPrefix}${K}${TPostfix}`]: null
}

export type EnumFromKeys<T extends string> = {
    [K in T]: K
}

export type FalseValues = null | undefined | false | 0 | void | '';
export type NonFalseable<T> = Exclude<T, FalseValues>; 
