import { AttrMapping, IData } from "@utils/AttrMapping";

export type IInfoUverejneniModel = IData & {
    blizsiOduvod: string;
    cpvKod: string;
    datumUverejneni: Date;
    datumOduver: Date;
    datumZrus: Date;
    dnsDny: number;
    dnsMesic: number;
    dnsNazev: string;
    dnsPocetDod: number;
    dnsPredmet: string;
    dnsRok: number;
    dodinfo: number;
    druhZRNazev: string;
    ico: string;
    kod: string;
    kodDI: string;
    nazev: string;
    nazevDI: string;
    oduvod: string;
    oduvodZrus: string;
    oduverejnil: string;
    predmet: string;
    strukturovanaPZ: boolean;
    terminUkonceni: Date;
    text: string;
    uredniNazev: string;
    ustanoveni: string;
    zadavatelNazev: string;
    zadavatelID: number;
    zakazka: string;
    fullTextFiltr: string;
}

export const InfoUverejneniAttrMap = new AttrMapping<IInfoUverejneniModel>('Info_uverejneni', [
    { clientName: 'blizsiOduvod', serverName: 'Blizsi_oduvodneni', type: 'string' },
    { clientName: 'cpvKod', serverName: 'Historie.CPV_predmetu.Kod', type: 'string' },
    { clientName: 'dodinfo', serverName: 'Dod_info', type: 'id' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'kod', serverName: 'Historie.Kod', type: 'string' },
    { clientName: 'kodDI', serverName: 'Dod_info.Kod', type: 'string' },
    { clientName: 'datumUverejneni', serverName: 'Datum_uverejneni', type: 'dateTime' },
    { clientName: 'datumOduver', serverName: 'Datum_oduverejneni', type: 'dateTime' },
    { clientName: 'datumZrus', serverName: 'Datum_zruseni_ZR', type: 'dateTime' },
    { clientName: 'dnsDny', serverName: 'Delka_trvani_dny', type: 'number' },
    { clientName: 'dnsMesic', serverName: 'Delka_trvani_mesice', type: 'number' },
    { clientName: 'dnsNazev', serverName: 'Nazev_DNS', type: 'string' },
    { clientName: 'dnsPocetDod', serverName: 'Pocet_dodavatelu', type: 'number' },
    { clientName: 'dnsPredmet', serverName: 'Predmet', type: 'text' },
    { clientName: 'dnsRok', serverName: 'Delka_trvani_roky', type: 'number' },
    { clientName: 'druhZRNazev', serverName: 'Historie.Druh_ZR.Nazev', type: 'string' },
    { clientName: 'ico', serverName: 'IC', type: 'string' },
    { clientName: 'nazev', serverName: 'Historie.Nazev', type: 'string' },
    { clientName: 'nazevDI', serverName: 'Dod_info.Nazev', type: 'string' },
    { clientName: 'oduvod', serverName: 'Duvod_zruseni.Oduvodneni', type: 'string' },
    { clientName: 'oduvodZrus', serverName: 'Oduvod_zruseni', type: 'string' },
    { clientName: 'oduverejnil', serverName: 'Oduverejnil', type: 'string' },
    { clientName: 'predmet', serverName: 'Predmet', type: 'string' },
    { clientName: 'strukturovanaPZ', serverName: 'Uver_pis_zprava', type: 'bool' },
    { clientName: 'terminUkonceni', serverName: 'Termin_ukonceni', type: 'date' },
    { clientName: 'text', serverName: 'Text', type: 'string' },
    { clientName: 'uredniNazev', serverName: 'Uredni_nazev', type: 'string' },
    { clientName: 'ustanoveni', serverName: 'Duvod_zruseni.Ustanoveni', type: 'string' },
    { clientName: 'zadavatelNazev', serverName: 'Historie.Zadavatel.Nazev', type: 'string' },
    { clientName: 'zadavatelID', serverName: 'Historie.Zadavatel', type: 'id' },
    { clientName: 'zakazka', serverName: 'ZP.Kod_Nazev', type: 'string' },
    { clientName: 'fullTextFiltr', serverName: 'Fulltext_filtr', type: 'string' }
]);
