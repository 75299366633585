import React, { ChangeEventHandler, FC, FormEventHandler, KeyboardEventHandler, PropsWithChildren, ReactNode, RefObject, useCallback, useContext, useEffect, useRef, useState } from "react"
import { setupCn } from "@utils/BemUtils";
import { ChangeDoneReason, FilterManagerChangeDoneEventHandler, FilterManagerChangeEventHandler } from "@components/FilterManager";
import FilterApplyButton from "@components/FilterManager/FilterApplyButton/FilterApplyButton";
import FormControl, { FormControlBaseProps, FormControlContext } from "@components/FormControl";
import './FilterInput.scss';
import { renderIf } from "@utils/RenderUtils";
import { combineRefs } from "@utils/CombineRefs";
import { SwitchByLanguage, useSwitchByLanguage } from "@utils/LanguageUtils";

const govCn = setupCn('gov-form-control__input');
const cn = setupCn('filter-input');

const dateInputSettings: SwitchByLanguage<{ lang: string; placeholder: string; }> = {
    cz: {
        lang: 'cs',
        placeholder: 'dd. mm. rrrr',
    },
    en: {
        lang: 'en',
        placeholder: 'dd. mm. yyyy',
    }
}

export type FilterInputProps =
    Pick<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onFocus' | 'onBlur'> &
    FormControlBaseProps &
    PropsWithChildren<{
        id: string;
        onChange?: FilterManagerChangeEventHandler;
        onChangeDone?: FilterManagerChangeDoneEventHandler;
        onInputChange?: (sender: HTMLInputElement | undefined, value: string) => void;
        onInputChangeDone?: (sender: HTMLInputElement | undefined, value: string) => void;
        value?: string;
        name?: string;
        autoComplete?: string;
        onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
        onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
        useApplyButton?: boolean;
        container?: FC;
        afterLabel?: ReactNode;
        type?: string;
        inputRef?: RefObject<HTMLInputElement>;
        placeholder?: string;
        buttons?: ReactNode[];
        isInline?: boolean;
        afterButtons?: ReactNode[];
        clearRequesting?: number;
    }>;

const FilterInput: FC<FilterInputProps> = ({
    id,
    onChange,
    onInputChange,
    onInputChangeDone,
    onChangeDone,
    onKeyUp,
    onKeyDown,
    autoComplete,
    useApplyButton,
    buttons,
    afterButtons,
    value,
    type,
    name,
    container,
    onFocus,
    onBlur,
    inputRef: inputRefProp,
    afterLabel,
    isInline,
    placeholder,
    children,
    className,
    clearRequesting,
    ...formControlProps
}) => {
    value ??= '';
    const inputRef = useRef<HTMLInputElement>(null);
    const { switchByLanguage } = useSwitchByLanguage();
    const [actualValue, setActualValue] = useState(value);
    const isEmpty = actualValue == '';

    const { defaultPlaceholder, isInputStandalone } = useContext(FormControlContext);
    placeholder ??= defaultPlaceholder;

    useEffect(() => {
        setActualValue(value ?? '');
    }, [value]);

    useEffect(() => {
        if (clearRequesting != null && isEmpty && inputRef.current) {
            inputRef.current.value = '';
        }
    }, [clearRequesting, isEmpty]);

    const handleInput = useCallback<FormEventHandler<HTMLInputElement>>(event => {
        //ios clear button fix
        // https://github.com/facebook/react/issues/8938
        setTimeout(() => {
            if (event?.nativeEvent?.target instanceof HTMLInputElement)
                event.nativeEvent.target.defaultValue = ''
        }, 0);
    }, []);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(event => {
        setActualValue(event.target.value);
        onChange?.(event.target.name, event.target.value);
        onInputChange?.(event.target, event.target.value);
    }, [onChange, onInputChange]);

    const handleChangeDone = useCallback((reason: ChangeDoneReason) => {
        const newValue = inputRef.current?.value || '';
        setActualValue(newValue);
        onChangeDone?.(inputRef.current?.name || name, newValue, reason);
        onInputChangeDone?.(inputRef.current ?? undefined, newValue);
    }, [onChangeDone, onInputChangeDone, name]);

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
        if (event.key === 'Enter') {
            handleChangeDone('enter');
        }
        onKeyDown?.(event);
    }, [handleChangeDone, onKeyDown]);

    const handleApplyClick = useCallback<React.MouseEventHandler>(() => {
        handleChangeDone('enter');
    }, [handleChangeDone]);

    const handleBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>((event) => {
        handleChangeDone('blur');
        onBlur?.(event);
    }, [handleChangeDone, onBlur]);

    const applyButton = renderIf(useApplyButton,
        <FilterApplyButton
            id={id}
            key='applyButton'
            onClick={handleApplyClick} />
    );

    const inputAttributes = type == 'date' ? switchByLanguage(dateInputSettings) :
        type == 'time' ? { placeholder: 'hh:mm' } :
            {};

    return (
        <FormControl
            {...formControlProps}
            container={container}
            className={cn.with(className).main({
                '--inline': isInline
            })}
            notEmpty={!isEmpty}
            isTime={type == 'time'}
            isDate={type == 'date'}
            afterLabel={<>
                {applyButton}
                {afterLabel}
            </>}
            labelFor={id}>
            <input
                id={id}
                className={govCn.with(cn('__input')).main({
                    '--standalone': isInputStandalone
                })}
                ref={combineRefs(inputRef, inputRefProp)}
                name={name}
                onInput={handleInput}
                onFocus={onFocus}
                type={type || 'text'}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyUp={onKeyUp}
                autoComplete={autoComplete ?? 'off'}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                value={actualValue}
                {...inputAttributes} />
        </FormControl>
    );
};

FilterInput.displayName = 'FilterInput';

export default React.memo(FilterInput);
