import EncryptionInfo from "crypto/EncryptionInfo/EncryptionInfo";
import { ICryptographicInfo } from "crypto/interface/ICryptographicInfo";
import { ISignatureInfo } from "crypto/interface/ISignatureInfo";
import SignatureInfo from "crypto/SignatureInfo/SignatureInfo";
import { isSigned } from "crypto/SignatureUtils";
import React, { FC, PropsWithChildren, ReactNode } from "react"
import { setupCn, Classes } from "@utils/BemUtils";
import Accordion from "@components/Accordion";
import './CryptoDetail.scss';
import CryptoDetailSummaryBadge from "crypto/CryptoDetailSummaryBadge";
import { renderIf } from "@utils/RenderUtils";
import { useLocalization } from "@store/localizationStore";
import ButtonLink from "@components/Button/ButtonLink";
import CryptoPart from "crypto/CryptoPart/CryptoPart";
import { MdDraw } from "react-icons/md";
import FlexBox from "@components/FlexBox/FlexBox";
import Note from "@components/Note/Note";

const cn = setupCn('crypto-detail');

export type CryptoDetailProps = PropsWithChildren<{
    className?: Classes;
    id: string;
    cryptoInfo: ICryptographicInfo;
    withModalPaddig?: boolean;
    withoutSummary?: boolean;
    signatureDownloadLink?: string;
    signatureDataDescription?: ReactNode;
}>;

const CryptoDetail: FC<CryptoDetailProps> = ({
    className,
    id,
    cryptoInfo,
    withoutSummary,
    withModalPaddig,
    signatureDownloadLink,
    signatureDataDescription,
}) => {
    const { ts } = useLocalization();
    const { signatureInfo, encryptionInfo } = cryptoInfo;

    const hasSignature = signatureInfo && isSigned(signatureInfo);

    const signatruresDetails: ReactNode[] = [];
    const createSignaturesTree = (actualSignature: ISignatureInfo) => {
        if (actualSignature) {
            signatruresDetails.push(
                <SignatureInfo
                    key={signatruresDetails.length}
                    id={signatruresDetails.length.toString()}
                    signatureInfo={actualSignature} />
            );
            actualSignature.counterSignature?.forEach(createSignaturesTree);
        }
    }
    createSignaturesTree(signatureInfo);

    return (
        <div className={cn.with(className).main()}>
            {renderIf(!withoutSummary, () =>
                <div className={cn('__caption')}>
                    {ts('GUI-462916')}<br />
                    <CryptoDetailSummaryBadge signatureInfo={signatureInfo} />
                </div>
            )}
            <Accordion id={id} withModalPadding={withModalPaddig} noInnerXOffset>
                {renderIf(signatureInfo?.signedData, signedData => <>
                    <CryptoPart id='signature-data' caption={ts('GUI-481348')} captionDescription={signatureDataDescription} captionIcon={MdDraw}>
                        {renderIf(signatureInfo?.signatureInsideDocument,
                            () => <Note isBold>{ts('NEN-808533')}</Note>,
                            () => <Note>{signedData}</Note>
                        )}
                        {renderIf(signatureDownloadLink, signatureDownloadLink =>
                            <FlexBox className={cn('__download-button')} justify='center'>
                                <ButtonLink to={signatureDownloadLink} target='_blank' isFlat>{ts('NEN-808532')}</ButtonLink>
                            </FlexBox>
                        )}
                    </CryptoPart >
                </>)}
                {renderIf(encryptionInfo?.encrypted,
                    <EncryptionInfo encryptionInfo={encryptionInfo!} />
                )}
                {renderIf(hasSignature,
                    signatruresDetails
                )}
            </Accordion>
        </div >
    );
}

CryptoDetail.displayName = 'CryptoDetail';

export default React.memo(CryptoDetail);
