import { AttrMapping, IData } from "@utils/AttrMapping";

export type IPolozkaPredmetuHistModel = IData & {
    cpvPolozkyNazev: string;
    cpvPolozkyKod: string;
    createCas: Date;
    jednotkaKod: string;
    mernaJednotkaNazev: string;
    mernaJednotkaKod: string;
    mnozstvi: number;
    nazevPolozky: string;
    nipezPolozkyNazev: string;
    nipezPolozkyKod: string;
    plniDod: boolean;
    popisPolozky: string;
    popisMistoPlneni: string;
    predpoklHodnota: number;
    predpoklHodnotaMJ: number;
    puvodniID: number;
    zpID: number;
}

export const PolozkaPredmetuHistAttrMap = new AttrMapping<IPolozkaPredmetuHistModel>('Polozka_predm_hist', [
    { clientName: 'cpvPolozkyNazev', serverName: 'CPV_polozky.Nazev', type: 'string' },
    { clientName: 'cpvPolozkyKod', serverName: 'CPV_polozky.Kod', type: 'string' },
    { clientName: 'createCas', serverName: 'create_cas', type: 'dateTime' },
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'jednotkaKod', serverName: 'Vlastni_jednotka', type: 'string' },
    { clientName: 'mernaJednotkaNazev', serverName: 'MJ.Nazev', type: 'string' },
    { clientName: 'mernaJednotkaKod', serverName: 'MJ.Kod', type: 'string' },
    { clientName: 'mnozstvi', serverName: 'Mnozstvi', type: 'number' },
    { clientName: 'nazevPolozky', serverName: 'Nazev_polozky', type: 'string' },
    { clientName: 'nipezPolozkyNazev', serverName: 'NIPEZ_polozky.Nazev', type: 'string' },
    { clientName: 'nipezPolozkyKod', serverName: 'NIPEZ_polozky.Kod', type: 'string' },
    { clientName: 'plniDod', serverName: 'Musi_plnit_dod', type: 'bool' },
    { clientName: 'popisPolozky', serverName: 'Popis_polozky', type: 'text' },
    { clientName: 'popisMistoPlneni', serverName: 'Misto_plneni', type: 'string' },
    { clientName: 'predpoklHodnota', serverName: 'Predpokl_hodnota', type: 'number' },
    { clientName: 'predpoklHodnotaMJ', serverName: 'Predpokl_hodnota_MJ', type: 'number' },
    { clientName: 'puvodniID', serverName: 'Puvodni_ID', type: 'number' },
    { clientName: 'zpID', serverName: 'ZP', type: 'id' },
]);
