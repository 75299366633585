import { createWidgetItemsSlice } from './widgetItemsHelpers';
import { WidgetItem } from './widgetStore';


export const newsStoreName = 'newsStore';

export type NewsItem = WidgetItem & {
    description: string
    description_html: string
}

export const slice = createWidgetItemsSlice<NewsItem>(newsStoreName);

export const { reducer } = slice;