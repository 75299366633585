import { UseLocalizationTextType } from "@store/localizationStore";
import { IData } from "@utils/AttrMapping";
import { useCallback } from "react";
import { ListFrameDetailLinkFactory } from "./Types";

export const getDetailIdLink =
    <TData extends IData = IData>(url: string, titleFactory?: (data: TData, t: UseLocalizationTextType) => string): ListFrameDetailLinkFactory<TData> =>
        ({ data, match, t }) => {
            return {
                url: `${match?.url}/${url}/${data.id}`,
                title: titleFactory?.(data, t) ?? ''
            }
        };

export const useDetailIdLink = <TData extends IData = IData>(url: string, titleFactory?: (data: TData, t: UseLocalizationTextType) => string) =>
    useCallback(getDetailIdLink(url, titleFactory), []);
