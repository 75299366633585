import { ServiceBase, Result } from "./ServiceBase";
import { LocalizationItem } from "@store/localizationStore";
import { CaseInsensitiveMap } from "@utils/CaseInsensitiveMap";
import { setCookie } from "@utils/CookiesUtils";
import { JsEvent } from "@utils/Events";
import { PromisesStacker, PromiseStackerItems } from "@utils/PromisesStacker";
import { flatArray } from "@utils/ArrayHelper";
import { isNode } from "@utils/node-utils";

type GetLocalizationItemsParams = {
    locKeys: string[];
    language: Language;
}

export type Language = 'CZ' | 'EN';

export default class LocalizationService extends ServiceBase {
    public static readonly MISSING_TEXT = '?¿?¿?¿';
    public static get DEFAULT_LANGUAGE(): Language {
        if (isNode) {
            return 'CZ';
        }
        return document?.documentElement?.lang == 'cs' ? 'CZ' : 'EN';
    };
    public static readonly supportedLanguages: Language[] = ['CZ', 'EN'];

    private static _languageStackers = new Map<Language, PromisesStacker<LocalizationItem>>();

    public static localizationsLoaded = new JsEvent<LocalizationService, { language: Language; locItems: LocalizationItem[] }>();

    public async getLocalizationItems({ language, locKeys }: GetLocalizationItemsParams): Promise<LocalizationItem[]> {
        const promises = locKeys.map(locKey => this._getLanguageStacker(language).request(locKey));
        return await Promise.all(promises);
    }

    private async _postRequest(params: GetLocalizationItemsParams): Promise<Result<LocalizationItem[]>> {
        return await this.requestJson<LocalizationItem[]>({
            url: '/api/localization',
            method: 'POST',
            data: params
        });
    }

    private _getLanguageStacker(language: Language): PromisesStacker<LocalizationItem> {
        let stacker = LocalizationService._languageStackers.get(language);
        if (!stacker) {
            stacker = new PromisesStacker({
                timeout: 300
            });
            LocalizationService._languageStackers.set(language, stacker);
        }
        stacker.onTick = (items) => this._getLocalizations(language, items)
        return stacker;
    }

    private async _getLocalizations(language: Language, items: PromiseStackerItems<LocalizationItem>) {
        const request = await this._postRequest({
            language: language,
            locKeys: [...items.keys()]
        });
        if (request.hasErrors) {
            [...items.values()].forEach((itemPromise) => {
                itemPromise.reject(request.errors);
            });
            LocalizationService._languageStackers.delete(language);
            LocalizationService.localizationsLoaded.trigger(this, {
                language,
                locItems: []
            });
            return;
        }

        const result = new CaseInsensitiveMap(request.value.map(locItem => [locItem.code, locItem]));
        const promises = [...items.entries()].map(async ([locKey, itemPromise]) => {
            const resultItem = result.get(locKey);
            if (!resultItem || resultItem.isMissing) {
                console.warn(`[LocalizationService] ${locKey} in language ${language} is missing.`);
            }
            if (resultItem) {
                itemPromise.resolve(resultItem);
                return;
            }
            itemPromise.resolve({
                code: locKey,
                text: LocalizationService.MISSING_TEXT,
                description: LocalizationService.MISSING_TEXT
            });
        });
        await Promise.all(promises);
        LocalizationService.localizationsLoaded.trigger(this, {
            language,
            locItems: request.value
        });
    }

    public getLanguageFromCookie(): Language {
        const cookieLanguage = this._nodeContextHttp.cookies.getCookie('Language')?.toUpperCase();
        if (LocalizationService.supportedLanguages.includes(cookieLanguage as Language)) {
            return cookieLanguage as Language;
        }
        return LocalizationService.DEFAULT_LANGUAGE;
    }

    public setLanguageToCookie(language: Language) {
        setCookie('Language', language, 365);
    }

    public static getAllLocItems(language?: Language): LocalizationItem[] {
        if (language) {
            return this._languageStackers.get(language)?.getAllResolved() || [];
        }
        const allItems = [...this._languageStackers.values()]
            .map(stacker => stacker.getAllResolved());
        return flatArray(allItems);
    }
}