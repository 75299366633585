import React from "react";
import { match, useRouteMatch } from "react-router";
import { DetailFrame, useDetailFrameContext } from "@components/DetailFrame/DetailFrame";
import { urlConstants } from "@constants/urlConstants";
import { UrlParamObject } from "@utils/QueryHook";
import GroupBox from "@components/GroupBox";
import { DataLabelTyped } from "@components/DataLabel";
import { ISystemKvalifikaceModel, SystemKvalifikaceAttrMap } from "@models/ISystemKvalifikaceModel";
import { IUverejnenyDokumModel, UverejnenyDokumAttrMap } from "@models/IUverejnenyDokumModel";
import { downloadButtonOnly } from "@components/Table/Buttons/DownloadAllToolbarButton";
import { columnWidths, DependentListFrameTyped, ListFrameColumn } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";
import { useDetailDescription } from "@utils/DescriptionHook";
import { ParsedUrlCondition } from "@store/breadcrumbTitleStore";

const DataLabel = DataLabelTyped<ISystemKvalifikaceModel>();

const registrSKDetailLoadkey = 'detail-sk';

export const parseSkUrl = (match: match<UrlParamObject> | null) => {
    return (match?.params[urlConstants.registrSKDetail.detailIdParam] || '');
}

export const prepareSkCondition = (id: string): ParsedUrlCondition => {
    return {
        condition: 'ID=@0',
        conditionParams: [id]
    }
}

const DetailSystemKvalifikace: React.FC = () => {
    const match = useRouteMatch<UrlParamObject>();
    const { condition, conditionParams } = prepareSkCondition(parseSkUrl(match));

    return (
        <DetailFrame
            condition={condition}
            conditionParams={conditionParams}
            attrMapping={SystemKvalifikaceAttrMap}
            loadKey={registrSKDetailLoadkey}
        >
            <DetailSK />
        </DetailFrame>
    );
}
const DetailSK: React.FC = () => {
    const { getAttr } = useDetailFrameContext<ISystemKvalifikaceModel>();
    const match = useRouteMatch();

    useDetailDescription<ISystemKvalifikaceModel>([
        {
            lockey: 'NEN-812763',
            attrName: 'skNazev',
        },
        {
            lockey: 'NEN-812764',
            attrName: 'skPredmet',
        },
        {
            lockey: 'NEN-812768',
            attrName: 'skUcel',
        }
    ]);

    return (
        <>
            <GroupBox lockey="NEN-812507">
                <DataLabel lockey="NEN-812763" attrName='skNazev' fillGrid />
                <DataLabel lockey="NEN-812641" attrName='skZadavatelNazev' detailLink={detailLinks.getSubjektDetailUrl(getAttr('skZadavatelID')?.value, match, true)} />
                <DataLabel lockey="NEN-812765" attrName='skKod' />
                <DataLabel lockey="NEN-812766" attrName='skStav' />
                <DataLabel lockey="NEN-812759" attrName='obdobiOd' />
                <DataLabel lockey="NEN-812762" attrName='obdobiDo' />
            </GroupBox>
            <GroupBox lockey="NEN-812597" showIf="Predmet<>null or Ucel<>null">
                <DataLabel lockey="NEN-812764" attrName='skPredmet' fillGrid rawText />
                <DataLabel lockey="NEN-812768" attrName='skUcel' fillGrid rawText />
            </GroupBox>
            <GroupBox lockey="NEN-812832" fillContent>
                <ListFrameDokum />
            </GroupBox>
        </>
    );

}
const dokumModelColumns: ListFrameColumn<IUverejnenyDokumModel>[] = [
    {
        attrName: 'soubor',
        locKey: 'NEN-812591',
    },
    {
        attrName: 'typDokument',
        locKey: 'NEN-812697'
    },
    {
        attrName: 'datumUver',
        locKey: 'NEN-812645',
        width: columnWidths.dateTime
    },
    {
        attrName: 'antivir',
        locKey: 'NEN-812715'
    }
];

const ListFrameDokum: React.FC = () => {
    const DependentListFrame = DependentListFrameTyped<ISystemKvalifikaceModel>();

    return (
        <DependentListFrame
            listFrameKey={registrSKDetailLoadkey + '-dokum-SK'}
            condition="Info_uver.SK=@0 and Uverejnen = true"
            conditionParams={'id'}
            orderBy={useOrderByMemo<IUverejnenyDokumModel>('nazev', 'id')}
            lightVersion
            attrMapping={UverejnenyDokumAttrMap}
            paramNames={useStaticObject({ filterParamName: 'dokSK', routeParamName: urlConstants.registrSKDetail.urlRouteParam })}
            columns={dokumModelColumns}
            detailLinkFactory={detailLinks.getUverDokumLinkFactory()}
            extraButtons={downloadButtonOnly} />
    );
}

export default DetailSystemKvalifikace;
