import FilterInput from "@components/FilterInput/FilterInput";
import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect } from "react"
import { useLocalization } from "@store/localizationStore";
import { setupCn, Classes } from "@utils/BemUtils";
import { FilterManagerChangeEventHandler, useFilterManagerContext } from "../FilterManager";
import { useId } from "@utils/UseId";
import Grid from "@components/Grid";
import FilterHeader from "../FilterHeader";
import { useChangingFilterValue } from "..";

const cn = setupCn('filter-manager-number');

export type FilterManagerNumberProps<TAttr extends string = string> = PropsWithChildren<{
    id?: string;
    headerText?: ReactNode;
    className?: Classes;
    name: TAttr;
}>;

export type FilterManagerNumberType<TAttr extends string = string> = FC<FilterManagerNumberProps<TAttr>>;

const FilterManagerNumber: FilterManagerNumberType = ({
    id,
    className,
    name,
    headerText
}) => {
    const { generateId } = useId({ id: `number-${id ?? name}` });

    const {
        filterValues,
        onFilterValueChange,
        setIsChanged
    } = useFilterManagerContext(name);
    const { ts } = useLocalization();
    const oldValue = (name && filterValues[name]) ?? '';
    const actualRawValue = oldValue.split(',');
    const from = actualRawValue[0];
    const to = actualRawValue[1] ?? '';

    const { 
        isChanged: isFromChanged,
        setValue: setFromValue,
    } = useChangingFilterValue(from);

    const { 
        isChanged: isToChanged,
        setValue: setToValue,
    } = useChangingFilterValue(to);

    const isChanged = isFromChanged || isToChanged;
    useEffect(() => {
        setIsChanged(name, isChanged);
    }, [name, isChanged, setIsChanged])

    const handleChangeDone = useCallback<FilterManagerChangeEventHandler>((inputName, value) => {
        const newValue = value.replace(/,/g, '.');
        let resultValue = oldValue;

        switch (inputName) {
            case 'from':
                resultValue = [newValue, oldValue.split(',')[1]].join(',');
                break;
            case 'to':
                resultValue = [oldValue.split(',')[0], newValue].join(',');
                break;
        }

        const applyValue = ['', ',', null, undefined].includes(resultValue) ? '' : resultValue;
        if (applyValue != oldValue) {
            onFilterValueChange(name, applyValue);
        }
    }, [oldValue, name, onFilterValueChange]);

    return (
        <div className={cn.with(className).main()}>
            <FilterHeader>{headerText}</FilterHeader>
            <Grid columns='3' isPlain>
                <FilterInput
                    id={generateId('__from')}
                    headerText={ts('NEN-742323')}
                    className={cn('__input')}
                    onChangeDone={handleChangeDone}
                    onChange={setFromValue}
                    isInline
                    value={from}
                    name='from'
                    type='number' />
                <FilterInput
                    headerText={ts('NEN-742324')}
                    id={generateId('__to')}
                    className={cn('__input')}
                    onChangeDone={handleChangeDone}
                    onChange={setToValue}
                    isInline
                    value={to}
                    name='to'
                    type='number' />
            </Grid>
        </div>
    );
};

FilterManagerNumber.displayName = 'FilterManagerNumber';

export default React.memo(FilterManagerNumber);
