import React, { ReactNode } from "react";
import { Footer } from "@components/Layout/Footer";
import Header from "@components/Layout/Header";
import { Breadcrumb } from "@components/Breadcrumb/Breadcrumb";
import { ErrorMessage } from "@components/ErrorMessage/ErrorMessage";
import Container from "@components/Container/Container";
import PageHeading from "@components/Layout/PageHeading";
import { useLocation } from "react-router";
import { useAppSettings } from "@store/nodeContextStore";
import { Alert } from "@components/Alert/Alert";
import CookieBar from "@components/Cookies";
import CookieModal from "@components/Cookies/CookieModal";
import Shortcuts from "@components/Accessibility/Shortcuts/Shortcuts";
import { pageIds } from "@components/Accessibility/pageIds";
import { useLocalization } from "@store/localizationStore";
import { useConstants } from "@constants/urlConstants";

const Layout: React.FC<{ children?: ReactNode }> = ({ children }) => {
    const { appSettings, getOutageVersion } = useAppSettings();
    const { t } = useLocalization();
    const { getUrl } = useConstants();
    const location = useLocation();
    const isHome = [
        getUrl('home'),
        getUrl('skoleniZzvz'),
        getUrl('videa'),
        getUrl('prirucky')    
    ].some(url => location.pathname == url);

    let className = '';
    if (appSettings?.isTestEnvironment)
        className = 'test-env';
    else if (getOutageVersion().enabled)
        className = 'out-ver';

    return (
        <div className={className}>
            <Shortcuts />
            <ErrorMessage />
            <Header />
            {
                getOutageVersion().enabled ? <Alert type='warning'>{getOutageVersion().text}</Alert> : null
            }
            <main id={pageIds.content} aria-label={t('NEN-786858')}>
                <Breadcrumb />
                {
                    isHome ? <>{children}</> :
                        <Container>
                            <PageHeading />
                            {children}
                        </Container>
                }
            </main>
            <Footer />
            <CookieBar />
            <CookieModal />
        </div>
    );
}

export default React.memo(Layout);
