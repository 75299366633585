import { FC, useRef } from "react";
import { useLocalization } from "@store/localizationStore";
import { usePageTitle } from "@utils/PageTitleHook";
import { urlConstants } from "@constants/urlConstants";
import RegistrZadavateluFilter from "./ProfilyZadavateluFilter";
import { IProfilZadavateleModel, ProfilZadavateleAttrMap } from "@models/IProfilZadavateleModel";
import { AttrNames } from "@utils/AttrMapping";
import { ListFrame, ListFrameColumns } from "@components/ListFrame";
import ContentBlock from "@components/ContentBlock";
import Paginator from "@components/Pagination";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";
import { useDescription } from "@utils/DescriptionHook";

export const ON_PAGE_ROWS = 50;

const columns: ListFrameColumns<IProfilZadavateleModel> = [
    {
        attrName: 'nazevOrganizace',
        locKey: 'NEN-812769',
        width: '200%'
    },
    {
        attrName: 'multiprofil',
        locKey: 'NEN-812726',
        width: '50%'
    },
    {
        attrName: 'nazevProfilu',
        locKey: 'NEN-812770',
    },
    {
        attrName: 'neaktivniAktivni',
        locKey: 'NEN-812876',
        width: '50%'
    }
];

const paramNames = urlConstants.profilyZadavateluPlatne.paramNames;
const profilyZadavateluLoadKey = 'profily-zadavatelu-platne-seznam';
const fulltextAttributes: AttrNames<IProfilZadavateleModel> = ['nazevOrganizace', 'nazevProfilu'];

const ProfilyZadavateluPlatne: FC = () => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-812617'));
    useDescription(t('NEN-789441'));

    const frameContainer = useRef<HTMLDivElement>(null);

    return (
        <>
            <h2 className='u-sr-only'>{t('NEN-602383')}</h2>
            <ContentBlock>
                <RegistrZadavateluFilter paramNames={paramNames} />
                <ListFrame
                    listFrameKey={profilyZadavateluLoadKey}
                    condition="Platny = true"
                    paramNames={paramNames}
                    onPageCount={ON_PAGE_ROWS}
                    skeletonCount={ON_PAGE_ROWS}
                    attrMapping={ProfilZadavateleAttrMap}
                    fulltextAttributes={fulltextAttributes}
                    orderBy={useOrderByMemo<IProfilZadavateleModel>("nazevOrganizace", { desc: true, column: "id" })}
                    detailLinkFactory={detailLinks.getProfilZadavateleDetailLinkFactory()}
                    columns={columns} />
            </ContentBlock>
            <Paginator
                loadMoreButton
                scrollToRef={frameContainer}
                collectionKey={profilyZadavateluLoadKey} />
        </>
    );
}

export default ProfilyZadavateluPlatne;
