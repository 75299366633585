import React, { FC } from "react"
import { setupCn } from "@utils/BemUtils";
import Container, { ContainerProps } from "./Container";

const cn = setupCn('gov-header');

const ContainerElement: React.FC<any> = (props) => <header tabIndex={-1} {...props} />;

const HeaderContainer: FC<ContainerProps & {
    isNavigationActive?: boolean,
}> = ({ isNavigationActive, ...restProps }) => {
    return (
        <Container
            className={cn.with({
                'is-fixed': isNavigationActive
            }).main()}
            element={ContainerElement}
            {...restProps} />
    );
};

export default React.memo(HeaderContainer);
