import { AttrMapping, IData } from "@utils/AttrMapping";

export type IDNSystemiModel = IData & {
    cpvKod: string;
    datumZavedeni: Date;
    delkaMesic: number;
    delkaRok: number;
    nazev: string;
    pocetDod: number;
    podaniLhuta: Date;
    predmet: string;
    stav: number;
    terminUkonceni: Date;
    vz: number;
    zadavatelNazev: string;
    zadavatelICO: string;
    zadavatelID: number;
    zakazka: string;
    zakazkaKod: string;
    
}

export const DNSystemAttrMap = new AttrMapping<IDNSystemiModel>('DNSystem', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'cpvKod', serverName: 'VZ.CPV_predmetu.Kod', type: 'string' },
    { clientName: 'datumZavedeni', serverName: 'Datum_zavedeni', type: 'dateTime' },
    { clientName: 'delkaMesic', serverName: 'Delka_trvani_mesice', type: 'number' },
    { clientName: 'delkaRok', serverName: 'Delka_trvani_roky', type: 'number' },
    { clientName: 'nazev', serverName: 'Nazev_DNS', type: 'string' },
    { clientName: 'pocetDod', serverName: 'Pocet_dodavatelu', type: 'number' },
    { clientName: 'podaniLhuta', serverName: 'VZ.Podani_nabidka.Lhuta', type: 'dateTime' },
    { clientName: 'predmet', serverName: 'Predmet', type: 'text' },
    { clientName: 'stav', serverName: 'Stav', type: 'enum', typeParam: 'Stav_DNS' },
    { clientName: 'terminUkonceni', serverName: 'Termin_ukonceni', type: 'date' },
    { clientName: 'vz', serverName: 'VZ', type: 'id' },
    { clientName: 'zadavatelNazev', serverName: 'VZ.Zadavatel.Nazev', type: 'string' },
    { clientName: 'zadavatelICO', serverName: 'VZ.Zadavatel.ICO', type: 'string' },
    { clientName: 'zadavatelID', serverName: 'VZ.Zadavatel', type: 'id' },
    { clientName: 'zakazka', serverName: 'VZ.Kod_Nazev', type: 'string' },
    { clientName: 'zakazkaKod', serverName: 'VZ.Kod', type: 'string' },
    
]);
