import React from 'react';
import { useLocalization } from 'store/localizationStore';
import UniversalLink from '@components/UniversalLink/UniversalLink';
import { useConstants } from '@constants/urlConstants';
import ContentBlock from '@components/ContentBlock';
import { useScrollToTop } from '@utils/ReactUtils';
import { useDescription } from '@utils/DescriptionHook';
import { useAppSettings } from '@store/nodeContextStore';

const ProhlaseniOPristupnosti = () => {
    const { ts, t } = useLocalization();
    const { getUrl } = useConstants();
    useScrollToTop();
    useDescription(t('NEN-789460'));
    const { appSettings } = useAppSettings();
    return (
        <>
            <ContentBlock>
                <p>{ts('NEN-645253')}</p>
                <p>
                    {ts('NEN-754432')}
                    <UniversalLink to="https://nen.nipez.cz" title="https://nen.nipez.cz" isExternal sameTarget>
                        https://nen.nipez.cz
                    </UniversalLink>
                </p>
                <p>{ts('NEN-645254')}</p>
            </ContentBlock>

            <ContentBlock lockey='NEN-645255'>
                <p className="u-mb--unset">
                    {ts('NEN-645256')}
                </p>
                <ol>
                    <li>pdf (Portable Document Format),</li>
                    <li>PDF/A (Portable Document Format for the Long-term Archiving),</li>
                    <li>xml (Extensible Markup Language Document), odpovídá-li veřejně dostupnému XSD schématu,</li>
                    <li>fo/zfo (602XML Filler dokument),</li>
                    <li>html/htm (Hypertext Markup Language Document),</li>
                    <li>odt (Open Document Text),</li>
                    <li>ods (Open Document Spreadsheet),</li>
                    <li>odp (Open Document Presentation),</li>
                    <li>txt (prostý text),</li>
                    <li>rtf (Rich Text Format),</li>
                    <li>doc/docx (MS Word Document),</li>
                    <li>xls/xlsx (MS Excel Spreadsheet),</li>
                    <li>ppt/pptx (MS PowerPoint Presentation),</li>
                    <li>jpg/jpeg/jfif (Joint Photographic Experts Group File Interchange Format),</li>
                    <li>png (Portable Network Graphics),</li>
                    <li>tif/tiff (Tagged Image File Format),</li>
                    <li>gif (Graphics Interchange Format).</li>
                    <li>zip, rar, 7z</li>
                </ol>
            </ContentBlock>


            <ContentBlock lockey='NEN-645257'>
                <p>
                    {ts("NEN-645258")}
                    <br />
                    {ts('NEN-645259')}
                    {" "}
                    <UniversalLink to={getUrl('compatibilityTest')} title={t('NEN-746935')}>
                        {ts('NEN-746935')}
                    </UniversalLink>
                </p>
            </ContentBlock>

            <ContentBlock lockey='NEN-645260'>
                <p>
                    {ts('NEN-645261')}
                    {" "}
                    <UniversalLink to="mailto:Hotline@nipez.cz" title="Hotline@nipez.cz" isExternal sameTarget>
                        Hotline@nipez.cz
                    </UniversalLink>
                </p>
            </ContentBlock>

            <ContentBlock lockey='NEN-645262'>
                <p>
                    {ts('NEN-754476')}
                    {" "}
                    <UniversalLink to="mailto:Hotline@nipez.cz" title="Hotline@nipez.cz" isExternal sameTarget>
                        Hotline@nipez.cz
                    </UniversalLink>
                    {ts('NEN-754478')}
                    <UniversalLink to={appSettings.serviceDesk.url} title="ServiceDesk" isExternal>
                        ServiceDesk
                    </UniversalLink>
                    <br />
                    {ts('NEN-645264')}

                </p>
            </ContentBlock>

            <ContentBlock lockey='NEN-719696'>
                <p>
                    {ts('NEN-719697')}
                    <br />
                    {ts('NEN-754484')}
                    {" "}
                    <UniversalLink to="mailto:pristupnost@dia.gov.cz" title="pristupnost@dia.gov.cz" isExternal sameTarget>
                        pristupnost@dia.gov.cz
                    </UniversalLink>
                </p>
            </ContentBlock>

            <ContentBlock lockey='NEN-719699'>
                <p>
                    {ts('NEN-719700')}
                </p>
            </ContentBlock>
        </>
    )
};

export default React.memo(ProhlaseniOPristupnosti);
