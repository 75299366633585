import { FilterManagerChangeEventHandler } from "@components/FilterManager";
import { FormControlBaseProps } from "@components/FormControl";
import { WhispererItem } from "@components/WhispererInput";
import { useDataRowsService } from "@store/nodeContextStore";
import { MaybeArray, notEmpty, notNull, toArray, toMap } from "@utils/ArrayHelper";
import { AttrMapping, IBasicData } from "@utils/AttrMapping";
import { Classes } from "@utils/BemUtils";
import { joinConditionsByAnd } from "@utils/FilterCondition";
import { getLovItems, getLovLikeItems, lovToWhispererItem } from "@utils/LovUtils";
import { useArrayMemo } from "@utils/ReactUtils";
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

export type LovBaseProps<T> = FormControlBaseProps & PropsWithChildren<{
    id: string;
    className?: Classes;
    showAttrs: MaybeArray<string>;
    condition?: string;
    attrMapping: AttrMapping;
    onChangeDone?: FilterManagerChangeEventHandler<T>;
    value: T;
    name?: string;
    maxItems?: number | null;
    clearRequesting?: number;
}>

export type LovImplementationProps = {
    filterDefaultText: string;
    selectedItemsAreInWhiperer: boolean;
}

export const useLovBase = <T extends MaybeArray<(string | number)>>({
    id,
    className,
    showAttrs,
    attrMapping,
    onChangeDone,
    maxItems,
    hasError,
    condition,
    value,
    name,
    clearRequesting,
    children,
    ...formControlProps
}: LovBaseProps<T>, {
    filterDefaultText,
    selectedItemsAreInWhiperer
}: LovImplementationProps) => {
    const dispatch = useDispatch();
    const showAttrsArray = useArrayMemo(toArray(showAttrs));
    const dataRowsService = useDataRowsService();
    const [filterText, setFilterText] = useState(filterDefaultText);
    const [whispererData, setWhispererData] = useState<{ data: IBasicData[], isAll: boolean }>({
        data: [],
        isAll: false
    });
    const [actualValue, setActualValue] = useState('');
    const [itemsNextCount, setItemsNextCount] = useState(0);
    const [selectedItems, setSelectedItems] = useState<WhispererItem[]>([]);

    useEffect(() => {
        if (clearRequesting != null) {
            setActualValue('');
        }
    }, [clearRequesting]);

    useEffect(() => {
        const controller = new AbortController();

        let cancel = false;
        const timeout = setTimeout(() => {
            if (cancel) {
                return;
            }
            const fetchSelectedCount = selectedItemsAreInWhiperer ? 0 : selectedItems.length;
            const fetchCount = maxItems == null ? undefined : (maxItems + itemsNextCount + fetchSelectedCount);
            getLovLikeItems({
                attrMapping, actualValue, dataRowsService, dispatch, condition,
                showAttrs: showAttrsArray,
                maxCount: fetchCount,
                conditionParams: [],
                abortController: controller
            }).then(result => {
                if (!cancel) {
                    setWhispererData({
                        data: result?.collection ?? [],
                        isAll: fetchCount == null || (result?.count != null && fetchCount >= result?.count)
                    });
                }
            });
        }, 50);
        return () => {
            controller?.abort();
            cancel = true;
            clearTimeout(timeout);
        };
    }, [filterText, condition, maxItems && itemsNextCount, maxItems]);

    useEffect(() => {
        const controller = new AbortController();

        const valueIds = toArray(value).filter(notEmpty).map(Number).filter(id => !isNaN(id));
        if (!valueIds?.length) {
            setSelectedItems([]);
            return;
        }
        if (selectedItems?.length && selectedItems.map(item => Number(item.id)).join(';') == valueIds.join(';')) {
            return;
        }
        let cancel = false;
        const fullCondition = joinConditionsByAnd(
            `(ID in [${valueIds.join(', ')}])`,
            condition
        );
        getLovItems({
            actualValue, attrMapping, dataRowsService, dispatch,
            showAttrs: showAttrsArray,
            condition: fullCondition,
            maxCount: valueIds.length,
            conditionParams: [],
            abortController: controller
        }).then(result => {
            const resultValues = toMap(item => item.id, result?.collection);
            if (!cancel && resultValues) {
                const items = valueIds.map(id => resultValues.get(id)).filter(notNull) as IBasicData[];
                setSelectedItems(items.map(item => lovToWhispererItem(item, showAttrsArray)));
            }
        });

        return () => {
            controller?.abort();
            cancel = true;
        };
    }, [value])



    const handleWhispererScrollDown = useCallback(() => {
        if (!whispererData.isAll && (whispererData.data?.length ?? 0) >= itemsNextCount) {
            setItemsNextCount(prev => prev + 20);
        }
    }, [whispererData, itemsNextCount]);

    const handleFilterChange = useCallback((value: string) => {
        setItemsNextCount(0);
        setFilterText(value ?? '');
    }, []);

    const handleValueChange: FilterManagerChangeEventHandler = useCallback((_, value: string) => {
        setActualValue(value);
        handleFilterChange(value);
    }, [handleFilterChange]);

    const items = useMemo<WhispererItem[]>(() => whispererData?.data?.map(item => lovToWhispererItem(item, showAttrsArray)),
        [whispererData?.data, showAttrsArray.join('|')]);

    const handleChangeDone = useCallback((value: T) => {
        onChangeDone?.(name, value);
    }, [onChangeDone, name])

    return {
        actualValue,
        selectedItems,
        setSelectedItems,
        setFilterText,
        showAttrsArray,
        handleWhispererScrollDown,
        handleValueChange,
        handleFilterChange,
        setActualValue,
        items,
        dataRowsService,
        handleChangeDone,
        controlProps: {
            ...formControlProps,
            id,
            hasError,
            items,
            onWhispererScrollDown: handleWhispererScrollDown,
            onChange: handleValueChange,
            value: actualValue,
        }
    };
}
