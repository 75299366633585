import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, ApplicationState } from ".";

export const name = 'errorStore';

export type ClientError = {
	code?: string;
	errorTitle?: string;
	errorText: string;
	isUserException?: boolean;
	locKey?: string;
	locParams?: string[];
}

export type AnyError = string | ClientError;

const toClientError = (error: AnyError): ClientError => typeof error == 'string'
	? { errorText: error }
	: error;

export type ErrorStoreState = {
	errors: ClientError[];
}

export const slice = createSlice({
	name: name,
	initialState: {
		errors: []
	} as ErrorStoreState,
	reducers: {
		push: (state: ErrorStoreState, action: PayloadAction<AnyError[]>) => {
			return {
				...state, 
				errors: state.errors.concat(action.payload?.map(toClientError) ?? [])
			}
		},
		clear: (state: ErrorStoreState) => {
			return {
				...state,
				errors: []
			}
		}
	}
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getErrorState = (state: ApplicationState) => state[name];
export const getErrorStoreActions = () => slice.actions;
export const getErrors = createSelector(
	getErrorState,
	(state) => state?.errors,
);

//hooks
export const useErrorStore = () => {
	const dispatch: AppDispatch = useDispatch();
	const clear = () => dispatch(getErrorStoreActions().clear());
	const push = (errors: AnyError[]) => dispatch(getErrorStoreActions().push(errors));
	const errors = useSelector(getErrors);
	return { errors, push, clear };
}