import { TableColumn, TableProps } from "@components/Table/Table";
import { ListFrameButton } from "@components/Table/Buttons/Types";
import { match } from "react-router";
import { MaybeArray } from "@utils/ArrayHelper";
import { AttrMapping, AttrName, IData } from "@utils/AttrMapping";
import { UrlParamObject } from "@utils/QueryHook";
import { OrderBy } from "@utils/OrderByUtils";
import { UseLocalizationTextType } from "@store/localizationStore";
import { FulltextAttr } from "@utils/FilterCondition";

export type ListFrameColumn<TData extends IData = IData> = TableColumn<TData>;
export type ListFrameColumns<TData extends IData = IData> = ListFrameColumn<TData>[];

export const columnWidths = {
    dateTime: 140,
    date: 120,
    ico: 80,
    zpCode: 155,
    stat: 100
} as const;

export type DetailLink = {
    url: string | null | undefined;
    title?: string;
}

export type ListFrameDetailLinkFactoryProps<T extends IData = IData> = {
    data: T;
    match: match<UrlParamObject>;
    t: UseLocalizationTextType;
}
export type ListFrameDetailLinkFactory<T extends IData = IData> = {
    (props: ListFrameDetailLinkFactoryProps<T>): DetailLink;
    additionalAttriubtes?: AttrName<T>[];
};

export type AttrModel<Model> = Model extends AttrMapping<infer T> ? T : IData;

export type ListFrameBaseProps<Mapping extends AttrMapping> = Omit<TableProps<Mapping>, 'collectionInfo' | 'detailLinkFactory' | 'toolbarButtons'> & {
    condition?: string;
    listFrameKey: string;
    detailLinkFactory?: Mapping extends AttrMapping<infer T> ? ListFrameDetailLinkFactory<T> : ListFrameDetailLinkFactory<any>;
    extraButtons?: ListFrameButton[];
    orderBy?: MaybeArray<OrderBy<AttrName<AttrModel<Mapping>>>>;
    fulltextAttributes?: FulltextAttr[];
}
