import { ActionCreatorWithPayload, combineReducers, createSelector, Reducer, Slice } from "@reduxjs/toolkit";
import { AppDispatch, ApplicationState, ApplicationStateSelector } from "../";
import { useCallback, useEffect } from "react";
import {  useDispatch, useSelector } from "react-redux";
import * as NewsWidgetStore from './newsWidgetStore';
import * as TrainingWidgetStore from './trainingWidgetStore';
import { getWidgetService, useTaskManager } from "@store/nodeContextStore";
import { getErrorStoreActions } from "@store/errorStore";
import { WidgetLanguage } from "@services/WidgetService";
import { useSwitchByLanguage } from "@utils/LanguageUtils";

export const name = 'widgetStore'; 

export type WidgetStoreState<TItem extends WidgetItem> = {
    items: TItem[];
    errors: string[];
}

export type WidgetItem = {
    id: string,
    short_description: string
    updated: string
    url: string
}

const slices = {
    [NewsWidgetStore.newsStoreName]: NewsWidgetStore.slice,
    [TrainingWidgetStore.trainingStoreName]: TrainingWidgetStore.slice
};

type ReducerKeys = keyof typeof slices;

const widgetRootReducer = combineReducers(
    Object.entries(slices)
        .map(([key, slice]) => [key, slice] as [ReducerKeys, Slice<WidgetStoreState<WidgetItem>>])
        .reduce((obj, [key, slice]) => {
            obj[key] = slice.reducer;
            return obj
        }, {} as { [x in ReducerKeys]: Reducer<WidgetStoreState<WidgetItem>> }
    )
);

export type WidgetStore = ReturnType<typeof widgetRootReducer>

export { widgetRootReducer };

export const getWidgetStoreState = (state: ApplicationState) => state.widgetStore;

export const getWitgetItems = createSelector(
    getWidgetStoreState,
    (_: unknown, sliceKey: keyof WidgetStore) => sliceKey,
    (widgetStore, sliceKey) => widgetStore?.[sliceKey]?.items
);

export type APIEndPoints = "news" | "trainings";

export const useWidgetItems = <TItem extends WidgetItem>(
    keyOfSlice: Extract<keyof WidgetStore, string>,
    queryFilter: string = "") => {

    const dispatch: AppDispatch = useDispatch();
    const state = useSelector(getWidgetStoreState);
    const items = useSelector((state: ApplicationState) => getWitgetItems(state, keyOfSlice)) as TItem[];
    const { switchByLanguage } = useSwitchByLanguage();
    const taskManager = useTaskManager();

    const slice = slices[keyOfSlice];

    if (slice == null) {
        throw new Error(`There is no corresponding slice key - ${keyOfSlice} in WidgetStore.`);
    }

    let controllerEndPoint: APIEndPoints;

    switch (keyOfSlice) {
        case "newsStore":
            controllerEndPoint = "news";
            break;
        case "trainingStore":
            controllerEndPoint = "trainings";
            break;
    }

    const loadData = useCallback(async (controller?: AbortController) => {
        await dispatch(loadItems<TItem>(
            slice.actions.setItems as any as ActionCreatorWithPayload<TItem[]>,
            controllerEndPoint,
            switchByLanguage({ cz: 'cs', en: 'en' }),
            queryFilter,
            controller
        ));
    }, [controllerEndPoint, slice, dispatch, queryFilter, switchByLanguage]);

    useEffect(() => {
        const controller = new AbortController();

        if (taskManager.isTaskCompleted(keyOfSlice)) {
            taskManager.removeTaskFromCompleted(keyOfSlice);
        }
        else {
            loadData(controller);
        }

        return () => {
            controller?.abort();
        }
    }, [switchByLanguage, dispatch, keyOfSlice, queryFilter, taskManager, slice.actions.setItems, controllerEndPoint, loadData]);

    taskManager.add(keyOfSlice, loadData);

    return { items, state };
}


export const loadItems = <TItem extends WidgetItem>(
    setItems: ActionCreatorWithPayload<TItem[]>,
    endPoint: APIEndPoints,
    language: WidgetLanguage = "cs",
    queryFilter: string = "",
    controller?: AbortController) =>

    async (dispatch: AppDispatch, getState: ApplicationStateSelector): Promise<any> => {
        const service = getWidgetService(getState());
        const result = await service.loadData<TItem[]>(endPoint, queryFilter, language, controller);

        dispatch(result.hasErrors
            ? getErrorStoreActions().push(result.errors)
            : setItems(result.value));

        return result;
    }
