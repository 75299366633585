import GovLink from "@components/GovLink";
import { urlConstants, useConstants } from "@constants/urlConstants";
import { useWidgetItems} from "@store/WidgetStore/widgetStore";
import { setupCn } from "@utils/BemUtils";
import Grid from '@components/Grid';
import { NewsItem } from "@store/WidgetStore/newsWidgetStore";

import './News.scss'

const cn = setupCn("gov-grid-tile");
const cnGrid = setupCn("gov-grid--reversed");
const cnPrev = setupCn("gov-preview-tile");

const NewsWidget = () => {

    const { items } = useWidgetItems<NewsItem>('newsStore');
    const { getUrl } = useConstants();
    const urlBase = getUrl("provozniInformace");

    return (
        <Grid className={cnGrid()} columns="3">
            {
                items.map(item =>
                    <GovLink className={cn()} key={item.id} to={`${urlBase}${urlConstants.detailInformace.pureUrl}?${item.url.split('?')[1]}`}>
                        <div className={cnPrev()}>
                            <h3 className={cnPrev("__headline")}>{item.short_description}</h3>
                            <p>{item.updated}</p>
                            <p>{item.description}</p>
                        </div>
                    </GovLink>
                )
            }
        </Grid>
    )
};

NewsWidget.displayName = 'NewsWidget';

export default NewsWidget;