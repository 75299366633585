import { AttrMapping, IData } from "@utils/AttrMapping";

export type IAkceptaceZmenyModel = IData & {
    username: string;
    kod: string;
    osJmeno: string;
    osPrijmeni: string;
    osEmail: string;
    patriDoNazev: string;
}

export const AkceptaceZmenyAttrMapping = new AttrMapping<IAkceptaceZmenyModel>('Akceptace_zmeny', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'username', serverName: 'Username', type: 'string' },
    { clientName: 'kod', serverName: 'Kod', type: 'string' },
    { clientName: 'osJmeno', serverName: 'Os.Jmeno', type: 'string' },
    { clientName: 'osPrijmeni', serverName: 'Os.Prijmeni', type: 'string' },
    { clientName: 'osEmail', serverName: 'Os.eMail_adresa', type: 'string' },
    { clientName: 'patriDoNazev', serverName: 'Os.Patri_do.Nazev', type: 'string' }
]);