import { setupCn } from '@utils/BemUtils';
import Button from '@components/Button/Button';
import React from 'react';
import { useUserCookiesSetings } from '@store/userCookieSettingsStore';
import { useLocalization } from '@store/localizationStore';
import Note from '@components/Note';

const cn = setupCn('gov-cookie-bar');

const CookieBar: React.FC = () => {
    const { ts } = useLocalization();

    const { userCookieSettings, acceptAllCookies, rejectAllCookies, openModal } = useUserCookiesSetings();

    if (userCookieSettings.settings.userSet)
        return null;

    return (
        <div className={cn()}>
            <div className={cn('__content')}>
                <div className={cn('__content-inner')}>
                    <h3>{ts('NEN-770169')}</h3>
                    <Note className='u-color--grey-mid'>
                        {ts('NEN-770170')}
                    </Note>
                    <div className={cn('__actions')}>
                        <div className={cn('__actions-primary')}>
                            <Button onClick={acceptAllCookies}>
                                {ts('NEN-770171')}
                            </Button>
                            <Button isOutlined onClick={rejectAllCookies}>
                                {ts('NEN-770172')}
                            </Button>
                        </div>
                        <Button onClick={openModal} isSecondary>
                            {ts('NEN-770173')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookieBar;