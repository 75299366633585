import { ServiceBase } from "./ServiceBase";

type CreateObjectParams = {
    objectName: string;
    data: { [attrName: string]: unknown };
    saveObject?: boolean;
}

export default class InstanceService extends ServiceBase {
    public async createObject(params: CreateObjectParams): Promise<string> {
        return (await this.requestJson<string>({
            url: `/api/instance/createObject`,
            method: 'POST',
            data: params
        })).value;
    }
}
