import React, { FC } from "react"
import { useConstants } from "@constants/urlConstants";
import { useLocalization } from "@store/localizationStore";
import ContentBlock from "@components/ContentBlock";
import FlexBox from "@components/FlexBox/FlexBox";
import ButtonLink from "@components/Button/ButtonLink";
import { setupCn } from "@utils/BemUtils";
import { useAppSettings } from "@store/nodeContextStore";
import UniversalLink from "@components/UniversalLink/UniversalLink";
import { joinPath } from "@utils/PathUtils";

import './LoginCard.scss';

const cn = setupCn("login-card");

const LoginCard: FC = () => {
    const { ts } = useLocalization();
    const { appSettings, getOutageVersion } = useAppSettings();
    const { anotherEnvironment, loginCaption } = appSettings;
    const { getUrl } = useConstants();    
    return (
        <ContentBlock lockey={loginCaption} className={cn()} height='100%'>
            <FlexBox justify='center'>
                {
                    getOutageVersion().enabled ? <p className="gov-perex">{ts('NEN-770209')}</p> : <ButtonLink to={getUrl('prihlaseni')} style={{ flex: 1 }} className="u-mb--25">{ts('NEN-749113')}</ButtonLink>
                }                
            </FlexBox>
            <FlexBox className="u-mb--25">
                <ButtonLink isOutlined to={getUrl('registrace')} style={{ flex: '0 0 45%' }}>{ts('NEN-749116')}</ButtonLink>
                <ButtonLink isOutlined to={getUrl('compatibilityTest')} style={{ flex: '0 0 45%' }}>{ts('NEN-746935')}</ButtonLink>
            </FlexBox>
            {
                anotherEnvironment?.loginLocKey && anotherEnvironment.url &&
                <p>
                    {ts('NEN-749117', {
                        locParams: [
                            <UniversalLink isExternal to={joinPath(anotherEnvironment.url, getUrl('prihlaseni'))}>
                                {ts(anotherEnvironment.loginLocKey)}
                            </UniversalLink>
                        ]
                    })}
                </p>
            }

        </ContentBlock>
    );
};

LoginCard.displayName = 'LoginCard';

export default React.memo(LoginCard);
