import { AttrMapping, IData } from "@utils/AttrMapping";

export type IProfZadavModel = IData & {
    nazev: string;
    platiDo: Date;
    platiOd: Date;
}

export const IProfZadavAttrMap = new AttrMapping<IProfZadavModel>('Profily_zadav', [
    { clientName: 'id', serverName: 'ID', type: 'id' },
    { clientName: 'nazev', serverName: 'Nazev', type: 'string' },
    { clientName: 'platiDo', serverName: 'Plati_DO', type: 'date' },
    { clientName: 'platiOd', serverName: 'Plati_OD', type: 'date' },
]);
