import React, { useEffect, useRef } from "react";
import { useLocalization } from "@store/localizationStore";
import ButtonLink from "@components/Button/ButtonLink";
import { useLoadState } from "@utils/StateUtils";
import Loader from "@components/Loader";
import RedirectToDetail from "@components/Widgets/RedirectToDetail";

const SmallWidget: React.FC<{
    src: string,
    allLink?: { href: string, titleLockey?: string },
    height: string,
    withPadding?: boolean,
    withoutLoader?: boolean
    baseDetailUrl?: string;
    detailUrl?: string;
    label?: string;
}> = ({ src, allLink, height, withPadding, withoutLoader, baseDetailUrl, detailUrl, label }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { isLoaded, onLoad, setLoaded } = useLoadState();
    const { ts, t } = useLocalization();

    useEffect(() => { setLoaded(false); }, [src]);

    if (!src)
        return null;

    return (
        <>
            <RedirectToDetail baseDetailUrl={baseDetailUrl} detailUrl={detailUrl} iframeRef={iframeRef} />
            <div style={{ width: '100%' }}>
                <div style={{ position: 'relative', padding: withPadding ? '15px 0' : '' }}>
                    {isLoaded || withoutLoader ? null : <Loader withOverlay />}
                    <iframe
                        ref={iframeRef}
                        src={src}
                        frameBorder={0}
                        onLoad={onLoad}
                        height={height}
                        style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
                        title={label}
                        role={label ? '' : 'presentation'}
                        width="100%" />
                </div>
                {
                    allLink &&
                    <ButtonLink isOutlined to={allLink.href} aria-label={t(allLink.titleLockey)}>{ts("NEN-645393")}</ButtonLink>
                }
            </div>
        </>
    );
}

SmallWidget.displayName = 'SmallWidget';

export default SmallWidget;