import { isNode } from "./node-utils";

export function setCookie(cname: string, cvalue: string, exdays: number, sameSite?: string) {
    if (isNode) {
        return;
    }
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    let sameSiteSettings = '';
    if (!/(CPU iPhone OS 12)|(iPad; CPU OS 12)|(Chrome\/5)|(Chrome\/6)|(Macintosh; Intel Mac OS X 10_14.*Version\/.*Safari)/
        .test(navigator.userAgent)) {
        sameSiteSettings = `SameSite=${sameSite ?? 'Lax'};`;
    }
    const cookie = cname + '=' + cvalue + ';' + expires + `;secure;${sameSiteSettings}path=/`;
    if (document) {
        document.cookie = cookie;
    }
}

export function getCookieFromString(cname: string, cookies?: string) {
    if (!cookies) {
        return '';
    }
    const ca = cookies.split(';');
    for (let i = 0; i < ca.length; i++) {
        const c = ca[i].trim().split('=');
        if (c[0] == cname) {
            return c[1];
        }
    }
    return '';
}

export class CookieManager {
    protected _cookies: string;
    protected _setCookies: string;

    public get setCookies(): string { return this._setCookies; }
    public get cookies(): string { return isNode ? this._cookies : document.cookie; }

    constructor(cookies?: string, setCookies?: string) {
        this._cookies = cookies || '';
        this._setCookies = setCookies || '';
    }

    public updateCookies(setCookies: string[]) {
        this._setCookies = setCookies.join(';,');
        this._cookies = this.cookies.concat('; ', setCookies.map(cookie => cookie.split(';')[0]).join('; '));
    }

    public getCookie(cookieName: string) {
        return getCookieFromString(cookieName, this.cookies);
    }
}
