import { WidgetItem } from "@store/WidgetStore/widgetStore";
import { createWidgetItemsSlice } from './widgetItemsHelpers';

export const trainingStoreName = 'trainingStore';

export type TrainingItem = WidgetItem & {
    u_training_date: Date,
    u_training_time_from: string,
    u_training_time_to: string,
    u_training_address: string,
    u_training_for: string
}

export const slice = createWidgetItemsSlice<TrainingItem>(trainingStoreName);

export const { reducer } = slice;
