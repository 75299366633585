import { FC } from "react"
import { useAppSettings } from "@store/nodeContextStore";
import LargeWidget from "@components/Widgets/LargeWidget";

const Videa: FC = () => {
    const { getAllWidgetUrl } = useAppSettings();

    return <LargeWidget src={getAllWidgetUrl('videa')} />
};

Videa.displayName = 'Videa';

export default Videa;