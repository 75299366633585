import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPasswordResetService } from "@store/nodeContextStore";
import CustomForm, { CustomFormState } from "@components/CustomForm/CustomForm";
import ContentBlock from "@components/ContentBlock";
import { useLocalization } from "@store/localizationStore";
import { getErrorStoreActions } from "@store/errorStore";
import { emailPattern } from "@utils/EmailUtils";

const ObnoveniUctu: React.FC = () => {
    const { ts } = useLocalization();
    const dispatch = useDispatch();
    const passwordService = useSelector(getPasswordResetService);
    const errorStoreActions = useSelector(getErrorStoreActions);
    const [requested, setRequested] = useState(false);

    const onSubmit = useCallback(async (_, state: CustomFormState) => {
        const result = await passwordService.resetRequest({
            email: state.email?.value?.trim(),
            username: state.username?.value?.trim()
        });

        if (!result.hasErrors) {
            setRequested(true);
        } else {
            dispatch(errorStoreActions.push(result.errors || ['Form error']));
        }
    }, [passwordService, setRequested, dispatch, errorStoreActions]);

    if (requested) {
        return (
            <ContentBlock>
                <h3>{ts('NEN-665702')}</h3>
            </ContentBlock>
        );
    }

    return <CustomForm lockey="NEN-665604"
        state={{
            username: {
                value: '',
                headerText: 'NEN-749300', //Uživatelské jméno
                validation: {
                    required: {
                        rules: [
                            {
                                regex: /[^\s]+/,
                                lockey: 'NEN-775855' //Uživatelské jméno nesmí být prázdné
                            }
                        ]
                    }
                }
            },
            email: {
                value: '',
                headerText: 'NEN-537477', //Emailová adresa
                validation: {
                    required: {
                        rules: [
                            {
                                regex: /[^\s]+/,
                                lockey: 'NEN-775856' //Emailová adresa nesmí být prázdná
                            },
                            {
                                regex: emailPattern,
                                lockey: 'NEN-775857' //Emailová adresa musí být ve správném tvaru
                            }
                        ]
                    }
                }
            }
        }}
        onSubmit={onSubmit} />;
}

export default ObnoveniUctu;