import React from "react";
import GroupBox from "@components/GroupBox";
import { CinnostSubjektuHistAttrMap, ICinnostSubjektuHist } from "@models/ICinnostSubjektuHist";
import { IJinaAdresaSubjektuHist, JinaAdresaSubjektuHistAttrMap } from "@models/IJinaAdresaSubjektuHist";
import { IOsobaHist, OsobaHistAttrMap } from "@models/IOsobaModelHist";
import { IProfilZadavateleModel, ProfilZadavateleAttrMap } from "@models/IProfilZadavateleModel";
import { IRelevantniCinnostSubjektuHist, RelevantniCinnostSubjektuHistAttrMap } from "@models/IRelevantniCinnostSubjektuHist";
import { urlConstants } from "@constants/urlConstants";
import { columnWidths, DependentListFrameTyped, ListFrameColumns } from "@components/ListFrame";
import { useStaticObject } from "@utils/ReactUtils";
import { useOrderByMemo } from "@utils/OrderByUtils";
import { detailLinks } from "@constants/detailLinks";

const DependentListFrame = DependentListFrameTyped<IProfilZadavateleModel>();

const DalsiInformacePage: React.FC = () => {
    return (
        <>
            <GroupBox lockey="NEN-812588" fillContent showIf='Exists(Cinnost_subj_hist, aktualni = true and Prof = &prof and Subj = &subj)'>
                <ListFrameHlavniPredmetCinnosti />
            </GroupBox>
            <GroupBox lockey="NEN-812648" fillContent showIf='Exists(Rel_cinnost_sub_hist, aktualni = true and Prof = &prof and Subj = &subj)'>
                <ListFrameDruhRelevantniCinnosti />
            </GroupBox>
            <GroupBox lockey="NEN-609792" fillContent showIf='Prof.Multiprofil=true'>
                <ListFrameZadavateleMultiprofilu />
            </GroupBox>
            <GroupBox lockey="NEN-812856" fillContent showIf='Exists(Jina_adresa_sub_hist, aktualni = true and Prof = &prof and Subj = &subj)'>
                <ListFrameJinaAdresa />
            </GroupBox>
            <GroupBox lockey="NEN-603539" fillContent>
                <ListFrameKontaktniOsoby />
            </GroupBox>
        </>
    );
}

const jinaAdresaModelColumns: ListFrameColumns<IJinaAdresaSubjektuHist> = [
    {
        attrName: 'nazev',
        locKey: 'NEN-812600',
        width: 250
    },
    {
        attrName: 'obec',
        locKey: 'NEN-812517'
    },
    {
        attrName: 'castObce',
        locKey: 'NEN-812518'
    },
    {
        attrName: 'ulice',
        locKey: 'NEN-812523'
    },
    {
        attrName: 'cisloPopisne',
        locKey: 'NEN-812524'
    },
    {
        attrName: 'cisloOrientacni',
        locKey: 'NEN-812525'
    },
    {
        attrName: 'psc',
        locKey: 'NEN-812526',
        width: 50
    },
];
const detailProfilZadavateleLoadKey = 'detail-profil-zadavatele';

const ListFrameJinaAdresa: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-jina-adresa'}
            condition='aktualni = true and Prof = @0 and Subj = @1'
            conditionParams={['profID', 'subjID']}
            orderBy={useOrderByMemo<IJinaAdresaSubjektuHist>('cisloPopisne', 'obec')}
            lightVersion
            attrMapping={JinaAdresaSubjektuHistAttrMap}
            paramNames={useStaticObject({ filterParamName: 'padr', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={jinaAdresaModelColumns} />
    );
}


const osobaModelColumns: ListFrameColumns<IOsobaHist> = [
    {
        attrName: 'titulPred',
        locKey: 'NEN-812568',
        width: 75
    },
    {
        attrName: 'jmeno',
        locKey: 'NEN-812567',
    },
    {
        attrName: 'prijmeni',
        locKey: 'NEN-812566',
        width: 130
    },
    {
        attrName: 'titulZa',
        locKey: 'NEN-812569',
        width: 75
    },
    {
        attrName: 'email',
        locKey: 'NEN-812580',
        width: 250
    },
    {
        attrName: 'telefonZamestnani',
        locKey: 'NEN-812550'
    },
    {
        attrName: 'mobil',
        locKey: 'NEN-812551',
        width: 130
    }
];

const ListFrameKontaktniOsoby: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-kontaktni-osoba'}
            condition='aktualni = true and Prof = @0 and Subj = @1'
            conditionParams={['profID', 'subjID']}
            orderBy={useOrderByMemo<IOsobaHist>('prijmeni', 'jmeno')}
            lightVersion
            attrMapping={OsobaHistAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pkoo', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={osobaModelColumns} />
    );
}

const hlavniPredmetCinnostiModelColumns: ListFrameColumns<ICinnostSubjektuHist> = [
    {
        attrName: 'hlavniPredmetCinnosti',
        locKey: 'NEN-812505',
        width: 800
    },
    {
        attrName: 'jinaCinnost',
        locKey: 'NEN-812530'
    }
];

const ListFrameHlavniPredmetCinnosti: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-hlavni-predmet-cinnosti-profil'}
            condition='aktualni = true and Prof = @0 and Subj = @1'
            conditionParams={['profID', 'subjID']}
            orderBy='hlavniPredmetCinnosti'
            lightVersion
            attrMapping={CinnostSubjektuHistAttrMap}
            paramNames={useStaticObject({ filterParamName: 'phpc', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={hlavniPredmetCinnostiModelColumns} />
    );
}



const druhRelevantniCinnostiModelColumns: ListFrameColumns<IRelevantniCinnostSubjektuHist> = [
    {
        attrName: 'druhRelevantniCinnosti',
        locKey: 'NEN-812650',
        width: 800
    },
    {
        attrName: 'jinaCinnost',
        locKey: 'NEN-812651'
    }
];

const ListFrameDruhRelevantniCinnosti: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-druh-relevantni-cinnosti-profil'}
            condition='aktualni = true and Prof = @0 and Subj = @1'
            conditionParams={['profID', 'subjID']}
            lightVersion
            attrMapping={RelevantniCinnostSubjektuHistAttrMap}
            paramNames={useStaticObject({ filterParamName: 'prc', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={druhRelevantniCinnostiModelColumns} />
    );
}


const zadavateleMultiprofiluModelColumns: ListFrameColumns<IProfilZadavateleModel> = [
    {
        attrName: 'platnyZruseny',
        locKey: 'NEN-770732',
        width: 100
    },
    {
        attrName: 'nazevOrganizace',
        locKey: 'NEN-812769'

    },
    {
        attrName: 'ico',
        locKey: 'NEN-812508',
        width: columnWidths.ico
    },
    {
        attrName: 'nazevProfilu',
        locKey: 'NEN-812725',
        width: 200
    },
];

const ListFrameZadavateleMultiprofilu: React.FC = () => {
    return (
        <DependentListFrame
            listFrameKey={detailProfilZadavateleLoadKey + '-zadavatele-multiprofilu-profil'}
            condition='Prof = @0'
            conditionParams={['profID']}
            orderBy={useOrderByMemo<IProfilZadavateleModel>('platnyZruseny', { desc: true, column: 'datumVytvoreni' })}
            lightVersion
            detailLinkFactory={detailLinks.getProfilZadavateleDetailLinkFactory(false, true)}
            attrMapping={ProfilZadavateleAttrMap}
            paramNames={useStaticObject({ filterParamName: 'pmulti', routeParamName: urlConstants.profilZadavateleDetail.urlRouteParam })}
            columns={zadavateleMultiprofiluModelColumns} />
    );
}

export default DalsiInformacePage;