import { FC, useRef } from "react";
import { ZpAttrMap, IZpModel } from "@models/IZpModelHist";
import { useLocalization } from "@store/localizationStore";
import { usePageTitle } from "@utils/PageTitleHook";
import { urlConstants } from "@constants/urlConstants";
import VerejneZakazkyFilter from "./VerejneZakazkyFilter";
import { columnWidths, ListFrame, ListFrameProps } from "@components/ListFrame";
import { inferredPartial } from "@utils/TypeUtils";
import { ListFrameColumns } from "@components/ListFrame";
import Paginator from "@components/Pagination";
import ContentBlock from "@components/ContentBlock";
import { detailLinks } from "@constants/detailLinks";
import { useDescription } from "@utils/DescriptionHook";

export const ON_PAGE_ROWS = 50;

const columns: ListFrameColumns<IZpModel> = [
    {
        attrName: 'kod',
        locKey: 'NEN-812594',
        width: columnWidths.zpCode
    },
    {
        attrName: 'nazev',
        locKey: 'NEN-812694',
        width: '200%'
    },
    {
        attrName: 'stavZP',
        locKey: 'NEN-738558',
        width: 120
    },
    {
        attrName: 'zadavatelNazev',
        locKey: 'NEN-812618',
        width: 200
    },
    {
        attrName: 'podaniLhuta',
        locKey: 'NEN-738562',
        width: columnWidths.dateTime
    }
];

const listFrameProps = inferredPartial<ListFrameProps<typeof ZpAttrMap>>()({
    attributes: ['kod', 'nazev', 'stavZP', 'zadavatelNazev', 'zadavatelICO', 'cpvPredmetuKod', 'podaniLhuta'],
    fulltextAttributes: [{ attrName: 'fullTextFiltr', type: 'contains' }],
    orderBy: { desc: true, column: 'createCas' }  
});

const vzLoadKey = 'verejne-zakazky-seznam';
const paramNames = urlConstants.verejneZakazky.paramNames;

const VerejneZakazky: FC = () => {
    const { t } = useLocalization();
    usePageTitle(t('NEN-744118'));
    useDescription(t('NEN-622252'));

    const listFrameContainer = useRef<HTMLDivElement>(null);

    return (
        <>
            <h2 className='u-sr-only'>{t('NEN-744019')}</h2>
            <ContentBlock>
                <VerejneZakazkyFilter id={vzLoadKey} paramNames={paramNames} />
                <ListFrame
                    {...listFrameProps}
                    containerRef={listFrameContainer}
                    listFrameKey={vzLoadKey}
                    paramNames={paramNames}
                    condition='Hierarchie = null and Posledni_uverejneni = true'
                    onPageCount={ON_PAGE_ROWS}
                    skeletonCount={ON_PAGE_ROWS}
                    attrMapping={ZpAttrMap}
                    detailLinkFactory={detailLinks.getLfZpDetailLinkFactory()}
                    columns={columns} />
            </ContentBlock>
            <Paginator
                loadMoreButton
                scrollToRef={listFrameContainer}
                collectionKey={vzLoadKey} />
        </>
    );
}

VerejneZakazky.displayName = 'VerejneZakazky';

export default VerejneZakazky;
