import React, { RefObject, useEffect, useState } from "react";
import { useWidgetDetailRedirect } from "@store/nodeContextStore";
import { Redirect, useLocation } from "react-router";
import { urlConstants } from "@constants/urlConstants";

const RedirectToDetail: React.FC<{
    baseDetailUrl: string | undefined;
    detailUrl: string | undefined;
    iframeRef: RefObject<HTMLIFrameElement>;
}> = ({ baseDetailUrl, detailUrl, iframeRef }) => {
    const { url, reset } = useWidgetDetailRedirect();
    const [redirect, setRedirect] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (url?.source == iframeRef?.current?.contentWindow) {
            if (/^https?/.test(url?.url)) {
                const newWindow = window.open(url.url, '_blank', 'noopener,noreferrer');
                if (newWindow)
                    newWindow.opener = null;

                reset();
            } else if (/^\?id=/.test(url?.url)) {
                setRedirect(true);
            }
        }
    }, [url, setRedirect, baseDetailUrl, reset, iframeRef]);

    let base = baseDetailUrl ?? location.pathname;
    const detail = detailUrl ?? urlConstants.detailInformace.pureUrl;

    if (base.endsWith('/')) {
        base = base.slice(0, -1);
    }

    if (detail && base.includes(detail)) {
        base = base.substring(0, base.indexOf(detail));
    }

    if (redirect && (base || detail))
        return <Redirect push to={`${base}${detail}${url?.url}`} />

    return null;
}

RedirectToDetail.displayName = 'RedirectToDetail';

export default RedirectToDetail;