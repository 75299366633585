import React, { FC, HTMLProps, PropsWithChildren } from "react"
import { setupCn, Classes } from "utils/BemUtils";
import './FlexBox.scss';

const cn = setupCn('gov-flexbox');

export type FlexBoxJustify = 'start' | 'center' | 'end' | 'space-between' | 'space-evenly';
export type FlexBoxAlign = 'start' | 'center' | 'end';
export type FlexDirection = 'row' | 'column';

export type FlexBoxProps = PropsWithChildren<{
    className?: Classes;
    align?: FlexBoxAlign;
    justify?: FlexBoxJustify;
    direction?: FlexDirection;
    wrap?: boolean;
    gap?: string;
} & Omit<HTMLProps<HTMLDivElement>, 'wrap'>>;

const FlexBox: FC<FlexBoxProps> = ({
    className,
    align,
    justify,
    wrap,
    direction,
    children,
    gap,
    ...rest
}) => {
    const justifyModifier = (!justify || justify == 'space-between') ? null : `--justify-${justify}`;
    const alignModifier = (!align || align == 'center') ? null : `--align-${align}`;
    const directionModifier = (!direction || direction == 'row') ? null : `--direction-${direction}`;

    return (
        <div
            className={cn.with(className).main(justifyModifier, alignModifier, directionModifier, {
                '--wrap': wrap
            })}
            style={{ gap, ...rest.style ?? {} }}
            {...rest}>
            {children}
        </div>
    );
};

FlexBox.displayName = 'FlexBox';

export default React.memo(FlexBox);
