import ButtonLink from "@components/Button/ButtonLink";
import Icon from "@components/Icon/Icon";
import { useLocalization } from "@store/localizationStore";
import React, { useMemo } from "react";
import { FC } from "react"
import { PaginatorHistoryState } from "./Paginator";
import { LocationDescriptorObject } from "history";

type GetPageRangePath = (params: {
    from: number;
    to: number;
    historyState?: PaginatorHistoryState | undefined;
}) => LocationDescriptorObject<PaginatorHistoryState>;

export type PaginatorLoadMoreProps = {
    getPageRangePath: GetPageRangePath;
    rangeFrom: number;
    actualPage: number;
    onPageCount: number;
}

const PaginatorLoadMore: FC<PaginatorLoadMoreProps> = ({ getPageRangePath, actualPage, rangeFrom, onPageCount }) => {
    const { ts } = useLocalization();

    const text = ts('NEN-659782', { locParams: [onPageCount ?? ''], isPrimaryTheme: true });
    return useMemo(() => (
        <ButtonLink isOutlined to={getPageRangePath({
            from: rangeFrom,
            to: actualPage + 1,
            historyState: { isLoadMore: true }
        })}>
            <Icon iconName='plus-small' />
            {text}
        </ButtonLink>
    ), [text, getPageRangePath, actualPage, rangeFrom]);
}

export default React.memo(PaginatorLoadMore);
