import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, ApplicationState } from '.';
import { useLocalization } from './localizationStore';
import { useConstants } from '@constants/urlConstants';

export const name = 'breadcrumb';

export type BreadcrumbItem = {
    title: string,
    href: string
}

export type BreadcrumbStoreState = {
    items: BreadcrumbItem[];
}

export const slice = createSlice({
    name,
    initialState: {
        items: [] as BreadcrumbItem[]
    } as BreadcrumbStoreState,
    reducers: {
        setItems: (state, action: PayloadAction<BreadcrumbItem[]>) => {
            return {
                ...state,
                items: action.payload
            }
        },
        push: (state, action: PayloadAction<{ item: BreadcrumbItem }>) => {
            return {
                ...state,
                items: [...state.items].concat(action.payload.item)
            }
        },
        pop: (state) => {
            return {
                ...state,
                items: state.items.slice(0, -1)
            }
        }
    }
});

// Export reducer from the slice.
export const { reducer } = slice;

// Selectors
export const getBreadcrumbState = (state: ApplicationState) => state[name];
export const getBreadcrumbItems = createSelector([getBreadcrumbState],
    (breadcrumbStore) => breadcrumbStore?.items
);
export const getLastBreadcrumbItem = createSelector([getBreadcrumbItems],
    (items) => [...items].pop()
);

export const useBreadcrumb = () => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useLocalization();
    const { getUrl } = useConstants();
    const items = useSelector(getBreadcrumbItems);
    const lastItem = useSelector(getLastBreadcrumbItem);
    const setItems = (items: BreadcrumbItem[]) => dispatch(slice.actions.setItems(items));
    const appendItemsToHome = (items: BreadcrumbItem[]) => setItems([{ href: getUrl('home'), title: t("NEN-738416") }].concat(items));

    return [appendItemsToHome, setItems, items, lastItem] as const;
}
