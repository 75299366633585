import { ArrayValues, EnumFromKeys } from "@utils/TypeUtils";


export type BottomSpace = 'unset'  | '25' | 25 | '32' | 32;

export const bottomSpaceClass = (space?: BottomSpace) => space == null ? '' : `u-mb--${space}`;

const offsetsClasses = [
    'u-mt--unset',
    'u-mb--unset',
    'u-mb--25',
    'u-mb--32',
    'u-pt--unset',
    'u-pt--20',
    'u-pt--40',
    'u-pt--60',
    'u-pb--unset',
    'u-pb--20',
    'u-pb--40',
    'u-pb--60',
] as const;

type OffsetsClassesEnum = EnumFromKeys<ArrayValues<typeof offsetsClasses>>;

const offsets: Record<string, string> = {};
offsetsClasses.forEach(offsetClass => offsets[offsetClass] = offsetClass);

export default offsets as OffsetsClassesEnum;
