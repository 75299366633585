import React, { FC } from "react";
import { Classes, setupCn } from "@utils/BemUtils";

const cn = setupCn('gov-link');

export type LinkBaseSize = 'small' | 'normal' | 'large';

export type GovLinkBaseProps = {
    className?: Classes;
    linkRef?: React.Ref<HTMLAnchorElement>;
    size?: LinkBaseSize;
    disabled?: boolean;
    isInversed?: boolean;
    isExternal?: boolean;
    hasArrow?: boolean;
    isStandalone?: boolean;
    title?: string;
}

const GovLinkBase: FC<GovLinkBaseProps & {
    element: React.FC<any>;
}> = ({ className, size, linkRef, isInversed, isExternal, hasArrow, isStandalone, disabled, element: Element, ...restProps }) => {
    const sizeModifier = (!size || size == 'normal') ? null : `--${size}`;

    return (
        <Element
            ref={linkRef}
            className={cn.with(className).main(sizeModifier, {
                '--disabled': disabled,
                '--inversed': isInversed,
                '--external': isExternal,
                '--has-arrow': hasArrow,
                '--standalone': isStandalone,
            })}
            {...restProps} />
    )
}

export default React.memo(GovLinkBase);
