import React, { FC, PropsWithChildren, ReactNode, useCallback, useMemo } from "react"
import { AttrMapping } from "@utils/AttrMapping";
import { setupCn, Classes } from "@utils/BemUtils";
import { UrlParamObject } from "@utils/QueryHook";
import { FilterManagerChangeEventHandler, useFilterManagerContext } from "../FilterManager";
import { useId } from "@utils/UseId";
import { LovMultiselect } from "@components/LOV";

const cn = setupCn('filter-manager-lov');

export type FilterManagerLovConditionFactory = (conditionParams?: UrlParamObject) => string;
export type FilterManagerLovCondition = string | FilterManagerLovConditionFactory;

export type FilterManagerLovProps<TAttr extends string = string> = PropsWithChildren<{
    id?: string;
    className?: Classes;
    headerText?: ReactNode;
    attrMapping: AttrMapping;
    title?: string;
    showAttrs: string[] | string;
    maxItems?: number | null;
    condition?: FilterManagerLovCondition;
    name: TAttr;
}>;

export type FilterManagerLovType<TAttr extends string = string> = FC<FilterManagerLovProps<TAttr>>;

const FilterManagerLov: FC<FilterManagerLovProps> = ({
    id,
    headerText,
    className,
    attrMapping,
    maxItems,
    condition,
    showAttrs,
    title,
    name
}) => {
    const { controlId } = useId({ id: `lov-${id ?? name}` });

    const {
        filterValues,
        onFilterValueChange,
        clearRequesting,
    } = useFilterManagerContext(name);

    const filterValue = (name && filterValues[name]) ?? '';

    const actualCondition = useMemo(() => {
        if (condition == null) {
            return undefined;
        }
        if (typeof condition == 'string') {
            return condition;
        }
        if (typeof condition == 'function') {
            return (condition as FilterManagerLovConditionFactory)?.(filterValues);
        }
        return undefined;
    }, [condition, filterValues]);

    const handleChangeDone = useCallback<FilterManagerChangeEventHandler<(string | number)[]>>((_, value) => {
        onFilterValueChange(name, value?.join(','));
    }, [onFilterValueChange, name]);

    return (
        <LovMultiselect
            id={controlId}
            headerText={headerText}
            title={title}
            clearRequesting={clearRequesting}
            className={cn.with(className).main()}
            maxItems={maxItems}
            value={filterValue?.split(',')}
            name={name}
            onChangeDone={handleChangeDone}
            attrMapping={attrMapping}
            showAttrs={showAttrs}
            condition={actualCondition} />
    );
};

FilterManagerLov.displayName = 'FilterManagerLov';

export default React.memo(FilterManagerLov);
