// implementace podle rozhraní https://github.com/tc39/proposal-cancellation


type cancellationTokenInternal = { cancellationRequested: boolean, canBeCanceled: boolean, registeredMethods?: Set<() => void>, id?: number };

/**
 * An error thrown when an operation is canceled.
 * @see {@link https://github.com/tc39/proposal-cancellation#class-cancelerror}
 */
export class CancelError extends Error {
    /**
     * Initializes a new instance of the CancelError class.
     * @param message An optional message for the error.
     * @see {@link https://github.com/tc39/proposal-cancellation#new-cancelerrormessage}
     */
    constructor(message?: string, id?: number) {
        super(message || `Operation ${id !== undefined ? `id ${id}`: ''} has been cancelled`); //`
    }
}

/**
 * Signals a CancellationToken that it should be canceled.
 * @see {@link https://github.com/tc39/proposal-cancellation#class-cancellationtokensource}
 */
export class CancellationTokenSource {
    private _toUnregister: Array<{ unregister(): void; }> = [];

    /**
     * Initializes a new instance of a CancellationTokenSource.
     * By supplying a set of linked tokens, you can model a complex cancellation graph that allows you to signal cancellation to various subsets of a more complex asynchronous operation. For example, you can create a cancellation hierarchy where a root  CancellationTokenSource  can be used to signal cancellation for all asynchronous operations (such as when signing out of an application), with linked  CancellationTokenSource  children used to signal cancellation for subsets such as fetching pages of asynchronous data or stopping long-running background operations in a Web Worker. You can also create a  CancellationTokenSource  that is attached to multiple existing tokens, allowing you to aggregate multiple cancellation signals into a single token.
     * @param linkedTokens An optional iterable of tokens to which to link this source.
     * @see {@link https://github.com/tc39/proposal-cancellation#new-cancellationtokensourcelinkedtokens}
     */
    constructor(linkedTokens?: Iterable<CancellationToken>) {
        if (linkedTokens)
            for (let lt of linkedTokens)
                try {
                    //if (lt.id && lt.id > 0)
                    //    logger.log("Linking existing token " + lt.id + " to new " + this.token.id);
                    this._toUnregister.push(lt.register(() => this.cancel()));
                } catch (e) {
                    console.error(e);
                }
    }

    private _tokenInternal?: cancellationTokenInternal;
    private _token: CancellationToken | undefined;

    /**
     * Gets the CancellationToken linked to this source.
     * @see {@link https://github.com/tc39/proposal-cancellation#sourcetoken}
     */
    public get token(): CancellationToken {
        return this._token || (this._token = _newCTokenAndSetInternals(this._tokenInternal = { cancellationRequested: false, canBeCanceled: true, registeredMethods: new Set() }));
    }

    /**
        * Cancels the source, evaluating any registered callbacks. If any callback raises an exception, the exception is propagated to a host specific unhandled exception mechansim (e.g.  window.onerror  or  process.on("uncaughtException") ).
        * @see {@link https://github.com/tc39/proposal-cancellation#sourcecancel}
        */
    public cancel(): void {
        if (!this.token || !this._tokenInternal || this._tokenInternal.cancellationRequested)
            return;

        //console.log("[CTS] Cancelled token " + this.token.id);
        this._tokenInternal.cancellationRequested = true;
        if (!this._tokenInternal.registeredMethods)
            return;

        for (let rm of this._tokenInternal.registeredMethods.values())
            try {
                rm();
            } catch (e) {
                console.error(e);
            }

        this._tokenInternal.registeredMethods.clear(); // odstrani vsechy registrovane metody, jelikoz uz je neni potreba volat a na zrusenem objektu uz se tato kolekce nevyuziva
    }

    /**
     * Closes the source, preventing the possibility of future cancellation. If the source is linked to any existing tokens, the links are unregistered.
     * @see {@link https://github.com/tc39/proposal-cancellation#sourceclose}
     */
    public close(): void {
        for (let tu of this._toUnregister)
            tu.unregister();
        if (this._tokenInternal && this._tokenInternal.registeredMethods)
            this._tokenInternal.registeredMethods.clear();
        this._tokenInternal = undefined;
    }
}

var _cancellationTokenInternals: WeakMap<CancellationToken, cancellationTokenInternal> = new WeakMap();

function _newCTokenAndSetInternals(internals: cancellationTokenInternal): CancellationToken {
    let ct = new (<any>CancellationToken)(true);
    if (internals.id)
        ct.id = internals.id;
    _cancellationTokenInternals.set(ct, internals);
    return ct;
}

var _getCTI = (key: CancellationToken) => _cancellationTokenInternals.get(key)!;

/**
 * Propagates notifications that operations should be canceled.
 * @see {@link https://github.com/tc39/proposal-cancellation#class-cancellationtoken}
 */
export class CancellationToken {
    /**
     * Zapíná generování unikátního id pro každý token, které se poté předává i na CancelError
     */
    public static debugging: boolean = false;
    private static _debugId: number = 0;

    constructor() {
        if (!arguments[0])
            throw Error("To create CancellationToken use CancellationTokenSource!");

        if (CancellationToken.debugging)
            this.id = ++CancellationToken._debugId;
    }

    /**
     * Gets a value indicating whether cancellation has been requested.
     * @see {@link https://github.com/tc39/proposal-cancellation#tokencancellationrequested}
     */
    public get cancellationRequested() { return _getCTI(this).cancellationRequested; }

    /**
     * Gets a value indicating whether the underlying source can be canceled.
     * @see {@link https://github.com/tc39/proposal-cancellation#tokencanbecanceled}
     */
    public get canBeCanceled() { return _getCTI(this).canBeCanceled; }

    public readonly id?: number;

    /**
     * Throws a CancelError if cancellation has been requested.
     * @see {@link https://github.com/tc39/proposal-cancellation#tokenthrowifcancellationrequested}
     */
    public throwIfCancellationRequested(): void {
        if (this.cancellationRequested) {
            //console.log("[CancellationToken] throwIfCancellationRequested: ", this.id);
            throw this.createCancelError();
        }
    }

    /**
     * Vytvoří CancelError z tohoto tokenu
     */
    public createCancelError(message?: string): CancelError {
        return new CancelError(message, this.id);
    }

    /**
     * Registers a callback to execute when cancellation is requested.
     * @param callback The callback to register.
     * @returns An object that can be used to unregister the callback.
     * @see {@link https://github.com/tc39/proposal-cancellation#tokenregistercallback}
     */
    public register(callback: () => void): { unregister(): void; } {
        if (this.cancellationRequested)
            callback();
        else {
            let cti = _getCTI(this);
            if (cti.registeredMethods) {
                cti.registeredMethods.add(callback);
                return {
                    unregister: () => {
                        if (cti && cti.registeredMethods)
                            cti.registeredMethods.delete(callback);
                    }
                };
            }
        }
        return { unregister: () => {} };
    }

    /**
     * Gets a token which will never be canceled.
     * @see {@link https://github.com/tc39/proposal-cancellation#cancellationtokennone}
     */
    public static get none() { return _none || (_none = _newCTokenAndSetInternals({ cancellationRequested: false, canBeCanceled: false, id: -1 })); }

    /**
     * Gets a token that is already canceled.
     * @see {@link https://github.com/tc39/proposal-cancellation#cancellationtokencanceled}
     */
    public static get canceled() { return _canceled || (_canceled = _newCTokenAndSetInternals({ cancellationRequested: true, canBeCanceled: false, id: -2 })); }
}

var _none: CancellationToken;
var _canceled: CancellationToken;

/*
TC 1:
(async () => {
    const csx = new TescoSW.CancellationTokenSource();
    const cs = new TescoSW.CancellationTokenSource([csx.token]);
    const d = TescoSW.PromiseUtils.delay(5000, cs.token).then(()=>false).catch((e)=>e instanceof TescoSW.CancelError);
    TescoSW.PromiseUtils.delay(2000).then(()=>csx.cancel());
    console.log(await d);
})();

TC 2:
(async () => { console.log((await TescoSW.PromiseUtils.delay(5000, TescoSW.CancellationToken.canceled).then(()=>false).catch((e)=>e instanceof TescoSW.CancelError))); })();

TC 3:
(async () => { console.log((await TescoSW.PromiseUtils.delay(5000, TescoSW.CancellationToken.none).then(()=>true).catch((e)=>false))); })();
*/
